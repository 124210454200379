import moment from 'moment';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { getCurrentUser, getDefaultEmailAddress } from 'data/entity/user';
import { openProfileSettings } from 'utils/profile';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function YourProfile() {
  const currentUser = getCurrentUser();
  if (!currentUser) return null;
  return _jsxs("section", {
    className: "user-profile",
    children: [_jsx("div", {
      className: "header",
      children: _jsx("h2", {
        children: "My Profile"
      })
    }), _jsxs("div", {
      className: "details",
      children: [_jsx("a", {
        href: "/organizations/settings/account",
        onClick: e => {
          e.preventDefault();
          openProfileSettings();
        },
        children: _jsx(Avatar, {
          profile: currentUser,
          size: Avatar.SIZE.XXL
        })
      }), _jsxs("div", {
        className: "info-card no-image",
        children: [_jsx("div", {
          className: "title",
          children: currentUser.name
        }), _jsx("div", {
          className: "subtitle",
          children: getDefaultEmailAddress(currentUser)
        }), _jsxs("div", {
          className: "info-item",
          children: ["Joined on ", moment(currentUser.created_at).format(DATE_FORMAT.SHORT_DATE)]
        })]
      }), _jsx("a", {
        href: "/organizations/settings/account",
        className: "action micro flat-white",
        onClick: e => {
          e.preventDefault();
          openProfileSettings();
        },
        children: "Edit your profile"
      })]
    })]
  });
}
YourProfile.displayName = "YourProfile";