import { logEvent } from 'utils/monitoring';
/**
 * @deprecated: Please use `logEvent` from the `utils/monitoring` module
 **/
export const trackEvent = data => {
  const {
    event,
    properties
  } = data;
  return logEvent(event, {
    ...(properties || {})
  });
};