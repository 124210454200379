import * as EstimateScaleData from 'data/entity/estimateScale';
import { isNew } from 'data/entity/story';
import * as AddNew from 'utils/addNew';
import * as StoryUtils from 'utils/story';
import { formatEstimate, formatEstimates } from '../../formatters/estimate';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryEstimate = story => {
  lazilyLoadArgumentChoices('currentEstimateForStory', () => getCurrentEstimateForStory(story));
  lazilyLoadArgumentChoices('estimatesForStory', () => getEstimatesForStory());
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryEstimate', updateStoryEstimate(story));
  }
};
const getEstimatesForStory = () => {
  const estimates = EstimateScaleData.getPointScale();
  return formatEstimates([null, ...estimates]);
};
const getCurrentEstimateForStory = story => {
  if (!story) return null;
  return formatEstimate(story.estimate);
};
const updateStoryEstimate = story => _ref => {
  let {
    storyEstimate
  } = _ref;
  const newEstimate = story.estimate !== storyEstimate.value ? storyEstimate.value : null;
  if (isNew(story)) {
    AddNew.updateStoryEstimate(newEstimate);
  } else {
    StoryUtils.updateStoryEstimate(story, newEstimate);
  }
};