import { MilestoneState } from '@clubhouse/shared/types';
export function convertToGQL(state) {
  if (state === 'toDo' || state === MilestoneState.ToDo) {
    return 'toDo';
  } else if (state === 'inProgress' || state === MilestoneState.InProgress) {
    return 'inProgress';
  } else if (state === 'done' || state === MilestoneState.Done) {
    return 'done';
  }
  throw new RangeError(`Milestone State ${state} not recognized`);
}
export function convertToRest(state) {
  if (state === 'toDo' || state === MilestoneState.ToDo) {
    return MilestoneState.ToDo;
  } else if (state === 'inProgress' || state === MilestoneState.InProgress) {
    return MilestoneState.InProgress;
  } else if (state === 'done' || state === MilestoneState.Done) {
    return MilestoneState.Done;
  }
  throw new RangeError(`Milestone State ${state} not recognized`);
}