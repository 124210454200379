import { QueryEpicsColumnDataDocument } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import merge from 'lodash/merge';
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { insertIf } from '@clubhouse/shared/utils/object';
import { useQueryWithPolling } from 'gql';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
import { useFetchMore } from 'gql/hooks/useFetchMore';
import { useWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
import { useFilters } from 'pages/epics-apollo/components/filters/useFilters';
import { useWorkspaceSlug } from 'utils/navigation';
import { useTablePaginationState } from 'utils/useTablePaginationState';
import { useCurrentColumnPageIndex } from '../EpicsColumn/components/ColumnPagination';
export const QUERY_EPICS_COLUMN_DATA = QueryEpicsColumnDataDocument;
const useEpicsQueryWithInfiniteScroll = (currentUserId, stateType, _ref) => {
  let {
    limit,
    pollInterval
  } = _ref;
  const slug = useWorkspaceSlug();
  const filters = useFilters();
  const skip = !currentUserId;
  const where = {
    ...insertIf(filters.query.trim().length > 0, {
      name: filters.query.trim()
    }),
    ...insertIf(filters.stateIds.length > 0, {
      epicStates: filters.stateIds
    }),
    ...insertIf(filters.projectIds.length > 0, {
      projects: filters.projectIds
    }),
    ...insertIf(filters.ownerIds.length > 0, {
      owners: filters.ownerIds
    }),
    ...insertIf(filters.milestoneIds.length > 0, {
      objectives: filters.milestoneIds
    }),
    ...insertIf(filters.objectiveIds.length > 0, {
      objectives: filters.objectiveIds
    }),
    ...insertIf(filters.groupIds.length > 0, {
      teams: filters.groupIds
    }),
    ...insertIf(filters.labelIds.length > 0, {
      labels: filters.labelIds
    }),
    archived: filters.showArchived,
    epicState_types: [stateType]
  };
  const orderBy = [{
    value: 'position',
    direction: 'ascending'
  }];
  const [pagination, setPollingRange] = useTablePaginationState({
    defaultLimit: limit
  });
  const inputRef = useLatestRef({
    pagination,
    orderBy,
    where
  });
  const {
    data,
    error,
    refetch,
    fetchMore: _fetchMore,
    updateQuery,
    previousData,
    startPolling,
    stopPolling,
    isInitialDataFromCache
  } = useQueryWithPolling(QUERY_EPICS_COLUMN_DATA, {
    variables: {
      input: inputRef.current,
      currentUserId: currentUserId,
      slug
    },
    skip,
    pollInterval
  });
  const {
    fetchMore,
    isFetchingMore
  } = useFetchMore(_fetchMore);
  const dataToRender = skip ? null : data || previousData;
  const epics = useMemo(() => {
    return (dataToRender?.workspace2?.epics?.edges || emptyArray).map(edge => edge.node);
  }, [dataToRender?.workspace2?.epics?.edges]);
  const isLoading = !skip && !error && !dataToRender;
  const isStale = !skip && !dataToRender && !!previousData;
  const estimates = useMemo(() => ({
    enabled: dataToRender?.workspace2?.canPointStories || false,
    unit: dataToRender?.workspace2?.progressUnit || 'story'
  }), [dataToRender?.workspace2?.canPointStories, dataToRender?.workspace2?.progressUnit]);
  const pageInfo = dataToRender?.workspace2?.epics?.pageInfo ?? null;
  const docs = useMemo(() => ({
    enabled: !!dataToRender?.workspace2?.features?.docs?.enabled
  }), [dataToRender?.workspace2?.features?.docs?.enabled]);
  const hasMoreData = useLatestRef(pagination.offset + pagination.limit < (pageInfo?.totalSize ?? 0));
  const numEpics = useLatestRef(epics.length);
  const setPollingRangeWithFetchMore = useCallback((range, _ref2) => {
    let {
      isLastItemVisible
    } = _ref2;
    if (!isFetchingMore && isLastItemVisible && hasMoreData.current) {
      fetchMore({
        input: merge({}, inputRef.current, {
          pagination: {
            offset: numEpics.current,
            limit
          }
        })
      });
    }
    setPollingRange(range);
  }, [fetchMore, hasMoreData, inputRef, isFetchingMore, limit, numEpics, setPollingRange]);
  return {
    epics,
    currentUserId,
    docs,
    estimates,
    pageInfo,
    isLoading,
    isStale,
    error,
    refetch,
    updateQuery,
    startPolling,
    stopPolling,
    isInitialDataFromCache,
    setPollingRange: setPollingRangeWithFetchMore
  };
};
const useEpicsQuery = (currentUserId, stateType, _ref3) => {
  let {
    limit,
    pollInterval
  } = _ref3;
  const slug = useWorkspaceSlug();
  const filters = useFilters();
  const page = useCurrentColumnPageIndex(stateType);
  const skip = !currentUserId;
  const {
    data,
    error,
    refetch,
    updateQuery,
    previousData,
    startPolling,
    stopPolling,
    isInitialDataFromCache
  } = useQueryWithPolling(QUERY_EPICS_COLUMN_DATA, {
    variables: {
      input: {
        pagination: {
          limit,
          offset: page * limit
        },
        orderBy: [{
          value: 'position',
          direction: 'ascending'
        }],
        where: {
          ...insertIf(filters.query.trim().length > 0, {
            name: filters.query.trim()
          }),
          ...insertIf(filters.stateIds.length > 0, {
            epicStates: filters.stateIds
          }),
          ...insertIf(filters.projectIds.length > 0, {
            projects: filters.projectIds
          }),
          ...insertIf(filters.ownerIds.length > 0, {
            owners: filters.ownerIds
          }),
          ...insertIf(filters.milestoneIds.length > 0, {
            objectives: filters.milestoneIds
          }),
          ...insertIf(filters.objectiveIds.length > 0, {
            objectives: filters.objectiveIds
          }),
          ...insertIf(filters.groupIds.length > 0, {
            teams: filters.groupIds
          }),
          ...insertIf(filters.labelIds.length > 0, {
            labels: filters.labelIds
          }),
          archived: filters.showArchived,
          epicState_types: [stateType]
        }
      },
      currentUserId: currentUserId,
      slug
    },
    skip,
    pollInterval
  });
  useDataLayerUpdateEvent(() => refetch(), {
    filter: ['Epic']
  });
  const dataToRender = skip ? null : data || previousData;
  const epics = useMemo(() => {
    return (dataToRender?.workspace2?.epics?.edges || emptyArray).map(edge => edge.node);
  }, [dataToRender?.workspace2?.epics?.edges]);
  const isLoading = !skip && !error && !dataToRender;
  const isStale = !skip && !dataToRender && !!previousData;
  const estimates = useMemo(() => ({
    enabled: dataToRender?.workspace2?.canPointStories || false,
    unit: dataToRender?.workspace2?.progressUnit || 'story'
  }), [dataToRender?.workspace2?.canPointStories, dataToRender?.workspace2?.progressUnit]);
  const docs = useMemo(() => ({
    enabled: !!dataToRender?.workspace2?.features?.docs?.enabled
  }), [dataToRender?.workspace2?.features?.docs?.enabled]);
  return {
    epics,
    currentUserId,
    docs,
    estimates,
    pageInfo: dataToRender?.workspace2?.epics?.pageInfo,
    isLoading,
    isStale,
    error,
    refetch,
    updateQuery,
    startPolling,
    stopPolling,
    isInitialDataFromCache
  };
};
export function useEpicsByStateWithInfiniteScroll(stateType, options) {
  const currentPermissionId = useWorkspaceCurrentPermissionIdQuery();
  return useEpicsQueryWithInfiniteScroll(currentPermissionId, stateType, options);
}
export function useEpicsByState(stateType, options) {
  const currentPermissionId = useWorkspaceCurrentPermissionIdQuery();
  return useEpicsQuery(currentPermissionId, stateType, options);
}