import { TableStateProvider } from '@clubhouse/shared/components/Table/TableStateProvider';
import { emptyArray } from '@clubhouse/shared/utils';
import { useAppState } from 'gql/hooks/application-state/useAppState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function TableStateProviderWithAppState(_ref) {
  let {
    children,
    appStateKeyPrefix,
    defaultDisabledColumns = emptyArray
  } = _ref;
  const [disabledColumns, onChangeDisabledColumns] = useAppState(`${appStateKeyPrefix}.disabledColumns`, defaultDisabledColumns, {
    emptyArrayIsUndefined: false
  });
  const [orderedColumns, onChangeOrderedColumns] = useAppState(`${appStateKeyPrefix}.columnOrder`, []);
  return _jsx(TableStateProvider, {
    disabledColumns: disabledColumns,
    onChangeDisabledColumns: onChangeDisabledColumns,
    orderedColumns: orderedColumns,
    onChangeOrderedColumns: onChangeOrderedColumns,
    children: children
  });
}
TableStateProviderWithAppState.displayName = "TableStateProviderWithAppState";