import * as AddNewExternalLinkTemplate from 'app/client/core/views/templates/addNewExternalLink.html';
import DropdownController from './dropdown';
import * as Event from '../_frontloader/event';
import ExternalLinkModel from '../models/externalLink';
import IntegrationsController from '../../../integrations/js/controllers/integrations';
import MessageController from './message';
import StoryModel from '../models/story';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
import View from '../modules/view';
const exports = {};
const DROPDOWN_ID = 'add-new-external-link';
const PARENT_SELECTOR = '#dropdown-' + DROPDOWN_ID;
const URL_FIELD_SELECTOR = '#external-link-url';
let onApply = null;
exports.open = function (callback) {
  const story = Utils.getModelFromContext(this, 'Story') || {};
  const data = _decorateWithPlaceholder({
    type: 'Story',
    id: story.id,
    story
  });
  if (_.isFunction(callback)) {
    onApply = callback;
  }
  DropdownController.open({
    header: AddNewExternalLinkTemplate.render(data),
    showCloseButton: true,
    id: DROPDOWN_ID,
    target: this,
    width: 380
  });
  $(URL_FIELD_SELECTOR).focus();
  return false;
};
exports.close = () => {
  onApply = null;
  DropdownController.closeById(DROPDOWN_ID);
};
exports.linkStoryToExternalLink = () => {
  if (!Validator.isValid($(PARENT_SELECTOR))) {
    return false;
  }
  const button = $('button', PARENT_SELECTOR);
  const story = Utils.getModelFromContext(button, 'Story');
  const externalLinkData = ExternalLinkModel.generateIdWithUrl($(URL_FIELD_SELECTOR).val());
  if (_.isFunction(onApply)) {
    onApply(externalLinkData);
    exports.close();
  } else {
    View.changeButtonToSaving(button);
    StoryModel.addExternalLink(story, externalLinkData.url, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to save External Link.'
        });
        View.revertButtonState(button, 'Link to Story');
      } else {
        Event.trigger('ExternalLinkAssociated');
        exports.close();
        exports.highlightNewExternalLink(externalLinkData);
      }
    });
  }
  return false;
};
exports.highlightNewExternalLink = externalLink => {
  const newExternalLinkElement = $(`[data-id=${externalLink.id}]`);
  if (newExternalLinkElement.length) {
    newExternalLinkElement.addClass('highlighted-anchor');
  }
};
function _decorateWithPlaceholder(data) {
  let placeholder = 'https://example.com/ticket/123';
  if (IntegrationsController.isZendeskEnabled()) {
    placeholder = 'https://yourcompany.zendesk.com/agent/tickets/123';
  }
  data.placeholder = placeholder;
  return data;
}
export { exports as default };