import { professionInputLabel, professions } from '@clubhouse/shared/constants';
import { Select } from 'components/shared/Select';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ProfessionSelect = () => _jsx("div", {
  "data-validate": "notEmpty",
  className: "form-input profession",
  children: _jsx(Select, {
    id: "signup-profession",
    name: "profession",
    label: professionInputLabel,
    options: professions,
    required: true
  })
});
ProfessionSelect.displayName = "ProfessionSelect";