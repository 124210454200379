import { useEffect, useRef } from 'react';
export const useScrollIntoView = _ref => {
  let {
    shouldScroll
  } = _ref;
  const ref = useRef(null);
  useEffect(() => {
    if (shouldScroll && ref.current) {
      ref.current?.scrollIntoView();
    }
  }, [shouldScroll]);
  return {
    ref
  };
};