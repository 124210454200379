import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Constants from '../modules/constants';
import EstimateModel from './estimate';
import Iterate from '../modules/iterate';
const exports = {};

/*

Example EstimateScale entity:

{
  estimates: [Estimate, Estimate, ...]
  id: 1234
  name: 'Default'
}

*/

Collection.create('EstimateScale', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.name;
};
exports.normalize = obj => {
  obj.estimates = _.sortBy(obj.estimates, 'value');
  Iterate.each(obj.estimates, estimate => {
    EstimateModel.updateIfValid(estimate);
  });
};

// Only storing the active estimate for the current org in the model.
exports.getCurrent = () => {
  return exports.first();
};
exports.isUsingPoints = () => {
  return _.get(exports.getCurrent(), 'using_estimates', false);
};
exports.isDisabled = () => {
  return exports.getPointScale().length === 0;
};
exports.getPointScale = () => {
  const estimateScale = exports.getCurrent();
  return estimateScale && estimateScale.estimates ? _.map(estimateScale.estimates, 'value') : Constants.DEFAULT_POINT_SCALE.split(',');
};
exports.getActiveScaleID = () => {
  let id = 'custom';
  const currentScale = _.map(exports.getCurrent().estimates, 'value').join(', ');
  if (currentScale === '') {
    return 'disabled';
  }
  Iterate.each(Constants.READYMADE_ESTIMATE_SCALES, readymade => {
    if (currentScale === readymade.values.join(', ')) {
      id = readymade.id;
    }
  });
  return id;
};
exports.fetchCurrent = callback => {
  Backend.get('/api/private/estimate-scales/default', {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.updateEstimateScale = (estimateScale, updates, callback) => {
  Backend.put('/api/private/estimate-scales/' + estimateScale.id, {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'estimateScaleUpdated');
    }
  });
};
export { exports as default };