import "core-js/modules/es.array.push.js";
import { useCallback, useEffect } from 'react';
import { isIsoCalendarDate } from '@clubhouse/shared/utils/date';
import { useSearchParams } from 'utils/navigation';
const ValueTypes = {
  string: val => val[0],
  number: val => Number.parseInt(val[0]),
  boolean: (val, defaultValue) => {
    if (val[0] === 'false') {
      return false;
    }
    if (val[0] === 'true' || val[0] === '') {
      return true;
    }
    return defaultValue ?? false;
  },
  IsoCalendarDate: val => {
    if (!val[0] || !isIsoCalendarDate(val[0])) return undefined;
    return val[0];
  },
  'string[]': val => val,
  'number[]': val => val.reduce((acc, str) => {
    const num = Number.parseInt(str);
    if (!isNaN(num)) {
      acc.push(num);
    }
    return acc;
  }, [])
};
export function mapSearchParamValue(rawValue, type, defaultValue, validValues) {
  const mapper = ValueTypes[type];
  let mappedValue = type === 'boolean' ? mapper(rawValue, defaultValue) : mapper(rawValue);
  if (Number.isNaN(mappedValue)) mappedValue = undefined;
  const mappedOrDefaultValue = mappedValue ?? defaultValue;
  return {
    value: !Array.isArray(validValues) || validValues.includes(mappedOrDefaultValue) ? mappedOrDefaultValue : defaultValue,
    userValue: mappedValue,
    rawValue
  };
}
export function useSearchParam(_ref) {
  let {
    paramName,
    paramType,
    defaultValue,
    validValues,
    shouldSetDefaultValueInUrl = false
  } = _ref;
  const {
    params,
    setSearchParams
  } = useSearchParams([paramName]);
  const rawValue = params[paramName];
  const {
    value,
    userValue
  } = mapSearchParamValue(rawValue, paramType, defaultValue, validValues);
  const setSearchParam = useCallback(newValue => {
    if (newValue === userValue) return;
    setSearchParams({
      [paramName]: (!shouldSetDefaultValueInUrl && newValue === defaultValue ? null : newValue) ?? null
    });
  }, [userValue, defaultValue, paramName, setSearchParams, shouldSetDefaultValueInUrl]);
  const clearSearchParam = useCallback(() => {
    setSearchParam(null);
  }, [setSearchParam]);
  useEffect(() => {
    if (Array.isArray(validValues) && userValue !== undefined && !validValues.includes(userValue)) {
      clearSearchParam();
    }
  }, [clearSearchParam, validValues, userValue]);
  return {
    param: value,
    setSearchParam,
    clearSearchParam
  };
}