import { removeValue, setValue, useValue } from 'utils/localStorage';
const LS_KEY = 'datalayer-db-time';
let lastSeenDbTime = 0;
export const getDbTime = () => {
  // TODO: Add this back when we don't have to worry about service worker
  // if (!lastSeenDbTime) lastSeenDbTime = Number(getValue(LS_KEY, { defaultValue: 0, prefix: true }));
  return lastSeenDbTime;
};
export const setDbTime = dbTime => {
  const numDbTime = dbTime !== null ? Number(dbTime) : null;
  if (typeof numDbTime === 'number') {
    const previousTime = lastSeenDbTime;
    lastSeenDbTime = numDbTime;
    if (!previousTime || previousTime > numDbTime) setValue(LS_KEY, numDbTime, {
      prefix: true
    });
  }
};
export const useDbTime = () => {
  useValue(LS_KEY, {
    prefix: true,
    defaultValue: 0
  });
  return lastSeenDbTime;
};
export function resetDatabaseTime() {
  lastSeenDbTime = 0;
  removeValue(LS_KEY, {
    prefix: true
  });
}