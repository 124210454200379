export const mergeBacklogConnections = (existing, incoming) => {
  if (!existing?.edges) return incoming;
  if (!incoming?.edges) return existing;
  const updatedEdges = [...existing.edges];
  updatedEdges.splice(incoming.pageInfo.offset, incoming.edges.length, ...incoming.edges);
  const slicedEdges = updatedEdges.slice(0, incoming.pageInfo.totalSize);
  const updated = {
    ...incoming,
    edges: slicedEdges,
    pageInfo: {
      ...incoming.pageInfo,
      offset: Math.min(incoming.pageInfo.offset, existing.pageInfo.offset),
      limit: Math.max(slicedEdges.length, incoming.pageInfo.limit)
    }
  };
  return updated;
};