import { Problem } from 'components/shared/Problem';
import LocalStorage from '../../../core/js/modules/localStorage';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import Log from '../../../core/js/modules/log';
import UserModel from '../../../core/js/models/user';
import OrganizationModel from '../../../core/js/models/organization';
import Router from '../../../core/js/_frontloader/router';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import User from '../../../core/js/modules/user';
import View from '../../../core/js/modules/view';
import ProblemController from '../../../core/js/controllers/problem';
const exports = {};
exports.NS = 'GoogleContacts';
exports.init = () => {
  UserModel.fetchCurrentUserWithoutRedirect(async () => {
    const {
      state,
      access_token: accessToken
    } = Url.parseLocationHash();
    const {
      workspace
    } = JSON.parse(state);
    try {
      const me = await _getMe(accessToken);
      const user = User.getCurrentUser();
      const {
        email_address: email
      } = UserModel.getDefaultEmail(user);
      if (me.email !== email) {
        _openInvite(workspace.slug, {
          tabType: InviteUsersController.INVITE_GOOGLE_CONTACTS_TAB_TYPE,
          errorType: InviteUsersController.INVALID_EMAIL_ERROR_TYPE
        });
        return;
      }
      let contacts = await _getDirectoryUsers(accessToken);
      if (contacts.length === 0) {
        contacts = await _getContacts(accessToken);
      }
      if (contacts.length === 0) {
        _openInvite(workspace.slug, {
          tabType: InviteUsersController.INVITE_GOOGLE_CONTACTS_TAB_TYPE,
          errorType: InviteUsersController.NO_CONTACTS_ERROR_TYPE
        });
        return;
      }
      exports.setContacts(workspace.id, contacts);
      _openInvite(workspace.slug, {
        tabType: InviteUsersController.INVITE_GOOGLE_CONTACTS_TAB_TYPE
      });
    } catch (e) {
      Log.error(e);
      _renderError({
        title: 'Something went wrong'
      });
    }
  });
};
const _openInvite = (workspaceSlug, _ref) => {
  let {
    tabType,
    errorType
  } = _ref;
  Router.replaceState(`/${workspaceSlug}`);
  Utils.redirect(`${InviteUsersController.getParametrizedRoute({
    tabType,
    errorType
  })}`);
};
const _renderError = _ref2 => {
  let {
    title,
    description
  } = _ref2;
  return View.renderComponent({
    mountNode: document.getElementById('content'),
    component: Problem,
    props: {
      height: ProblemController.getContentHeight(ProblemController.EXTRA_OFFSETS.M),
      title,
      icon: React.createElement('span', {
        className: 'fa fa-exclamation-triangle'
      }),
      description
    }
  });
};
const _getMe = accessToken => new Promise((resolve, reject) => {
  $.ajax({
    url: `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${accessToken}`,
    type: 'GET',
    crossDomain: true,
    dataType: 'jsonp',
    success: resolve,
    error: reject
  });
});
const contactFields = 'names,emailAddresses,photos';
const _getContacts = accessToken => new Promise((resolve, reject) => {
  $.ajax({
    url: `https://people.googleapis.com/v1/people/me/connections?personFields=${contactFields}&access_token=${accessToken}&pageSize=500`,
    type: 'GET',
    crossDomain: true,
    dataType: 'jsonp',
    success: function () {
      let {
        connections = []
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return resolve(connections);
    },
    error: reject
  });
});
const _getDirectoryUsers = accessToken => new Promise(resolve => {
  $.ajax({
    url: `https://people.googleapis.com/v1/people:listDirectoryPeople?readMask=${contactFields}&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE&access_token=${accessToken}&pageSize=500`,
    type: 'GET',
    crossDomain: true,
    dataType: 'jsonp',
    success: function () {
      let {
        people = []
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return resolve(people);
    },
    error: () => resolve([])
  });
});
exports.getCurrentOrgContacts = () => {
  const org = OrganizationModel.getCurrent();
  const user = User.getCurrentUser();
  return JSON.parse(LocalStorage.get(`${exports.NS}.${org.id}.${user.id}`) || '[]');
};
exports.getCurrentOrgContactsWithEmail = () => exports.getCurrentOrgContacts().filter(contact => contact.emailAddresses);
exports.setContacts = (orgID, contacts) => {
  const user = User.getCurrentUser();
  LocalStorage.set(`${exports.NS}.${orgID}.${user.id}`, JSON.stringify(contacts));
};
exports.clearContacts = () => {
  const org = OrganizationModel.getCurrent();
  exports.setContacts(org.id, []);
};
export { exports as default };