import slackLogo from '@clubhouse/assets/png/third-party-logos/slack_32x32.png';
import FlashController from 'app/client/core/js/controllers/flash';
import RadioListController from 'app/client/core/js/controllers/radioList';
import ToggleController from 'app/client/core/js/controllers/toggle';
import IntegrationModel from 'app/client/core/js/models/integration';
import OrganizationModel from 'app/client/core/js/models/organization';
import PermissionModel from 'app/client/core/js/models/permission';
import UserModel from 'app/client/core/js/models/user';
import Url from 'app/client/core/js/modules/url';
import User from 'app/client/core/js/modules/user';
import View from 'app/client/core/js/modules/view';
import SlackIntegrationController from 'app/client/integrations/js/controllers/slackIntegration';
import SettingsPage from 'app/client/settings/js/controllers/settingsPage';
import * as ManageNotificationsTemplate from 'app/client/settingsShared/views/templates/manageNotifications.html';
const exports = {};
const EMAIL_PARENT_SELECTOR = '#manage-email-notifications';
exports.SLACK_PARENT_SELECTOR = '#manage-slack-notifications';
exports.isSaving = false;
exports.title = `Notifications | ${BRAND.NAME}`;
exports.route = () => {
  const slug = Url.getSlugPath() || '/organizations';
  return `${slug}/settings/account/notifications`;
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  const slug = Url.getCurrentSlug();
  const slack_config = OrganizationModel.getSlackConfiguration();
  const url_params = $.param({
    user_scope: 'identity.basic',
    state: slug,
    client_id: slack_config.client_id,
    redirect_uri: IntegrationModel.getSlackRedirectUrl()
  });
  const slackIntegration = IntegrationModel.getSlackIntegration();
  const slackParams = {
    slackIntegrationUrl: `/${slug}/settings/integrations/slack`,
    slackIntegration,
    slackNotificationEnabled: slackIntegration ? slackIntegration.version === 'v3' : false,
    userSlackNotificationEnabled: exports.getUserSlackNotificationEnabled(),
    hasSlackIdentity: exports.hasSlackIdentity(),
    slackSignInUrl: `https://slack.com/oauth/v2/authorize?${url_params}`,
    slackLogo
  };
  const html = ManageNotificationsTemplate.render({
    active: 'manageNotifications',
    me: User.getCurrentUser(),
    emailNotification: exports.getEmailPreference(),
    ...slackParams
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  FlashController.showSavedMessage();
};
exports.getEmailPreference = () => {
  return UserModel.getAllLoggedInProfileDetails().email_notification_level;
};
exports.getUserSlackNotificationEnabled = () => {
  return UserModel.getLoggedInUserPermission().slack_notification_enabled;
};
exports.hasSlackIdentity = () => {
  return PermissionModel.hasSlackIdentity(UserModel.getLoggedInUserPermission());
};
exports.toggleEmail = function () {
  RadioListController.toggle.call(this);
};
exports.toggleSlackNotification = () => {
  if (exports.isSaving) {
    return false;
  }
  exports.isSaving = true;
  ToggleController.markAsSaving(exports.SLACK_PARENT_SELECTOR);
  const slackNotificationEnabled = !exports.getUserSlackNotificationEnabled();
  PermissionModel.updateSlackNotificationEnabled(slackNotificationEnabled).then(() => {
    $(exports.SLACK_PARENT_SELECTOR).find('.notification-toggle .status').toggleClass('status-active').text(slackNotificationEnabled ? 'enabled' : 'disabled');
    ToggleController.markAsSaved(exports.SLACK_PARENT_SELECTOR);
  }).catch(err => {
    FlashController.error(exports.SLACK_PARENT_SELECTOR, 'Unable to update slack notification setting.', err);
    ToggleController.revert(exports.SLACK_PARENT_SELECTOR);
  }).finally(() => {
    exports.isSaving = false;
  });
  return false;
};
exports.openSlackIntegrationDialog = () => {
  SlackIntegrationController.open();
  return false;
};
exports.saveEmailPreferences = () => {
  const parent = $(EMAIL_PARENT_SELECTOR);
  const level = parent.find('.radio-list-item.active').data('type');
  if (exports.isSaving || parent.find('.save-changes').hasClass('disabled')) {
    return false;
  }
  exports.isSaving = true;
  View.changeButtonToSaving(EMAIL_PARENT_SELECTOR + ' .save-changes');
  UserModel.updateEmailPreference(level, err => {
    exports.render();
    exports.isSaving = false;
    if (err) {
      FlashController.error(EMAIL_PARENT_SELECTOR, 'Unable to update email preference.', err);
    } else {
      FlashController.success(EMAIL_PARENT_SELECTOR, 'Email preference updated.');
    }
  });
  return false;
};

// exports.toggleBrowserNotifications = function () {
//   ToggleController.markAsSaving('.browser-notification');

//   if (!BrowserNotifications.isEnabled()) {
//     BrowserNotifications.requestPermission();
//   }

//   ToggleController.markAsSaved('.browser-notification', function () {
//     exports.isSaving = false;
//   });

//   return false;
// };

export { exports as default };