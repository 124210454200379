import Logo from '@clubhouse/assets/png/third-party-logos/notion_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { NotionIntegration } from 'components/settings/integrations/NotionIntegration';
import { isReadOnly } from 'data/entity/user';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Notion() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.NOTION
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Notion"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Notion Logo",
        invertOnDarkMode: true
      }), _jsx(NotionIntegration, {
        readOnly: readOnly
      })]
    })]
  });
}
Notion.displayName = "Notion";