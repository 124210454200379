import { useEffect, useState } from 'react';
import { useLatestRef } from './useLatestRef';
export function useMutationObserver(parent, callback, options) {
  const callbackRef = useLatestRef(callback);
  const enable = options?.enable !== false;
  useEffect(() => {
    if (!enable || !parent) return;
    const observer = new MutationObserver(() => {
      callbackRef.current();
    });
    observer.observe(parent, options);
    return () => {
      observer.disconnect();
    };
  }, [options, parent, enable, callbackRef]);
}
const USE_CHILD_LIST_OBSERVER_OPTIONS = {
  childList: true,
  subtree: true
};
export function useChildListObserver(callback) {
  const [parent, setParent] = useState(null);
  useMutationObserver(parent, callback, USE_CHILD_LIST_OBSERVER_OPTIONS);
  return {
    setRef: setParent
  };
}