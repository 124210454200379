import { useCallback } from 'react';
import { getTeamScopedURL } from 'components/team-navigation';
import { createBackgroundLocationState, getCurrentBackgroundLocationState } from './backgroundLocationState';
import { useReplaceState } from './useReplaceState';
export const useSetBackgroundPageTeamId = () => {
  const replaceState = useReplaceState();
  return useCallback(_ref => {
    let {
      teamId
    } = _ref;
    return replaceState(_ref2 => {
      let {
        state
      } = _ref2;
      const {
        backgroundLocation
      } = getCurrentBackgroundLocationState(state);
      if (!backgroundLocation) {
        return {};
      }
      return {
        state: createBackgroundLocationState(getTeamScopedURL(backgroundLocation.href, teamId))
      };
    });
  }, [replaceState]);
};