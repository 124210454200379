import OrganizationModel from './organization';
import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
const exports = {};

/*

Example Companies Summary entity:

{
  "entity_type":"user_workspace2_summary",
  "id":"5d239bc2-c51b-49a1-bdf5-cb760f102c26",
  "user_default_workspace2":{
    "id":"5390b4a4-5b91-4e27-b80b-879e2762bf69",
    "name":"Shortcut",
    "url_slug":"internal",
    "entity_type":"workspace2",
    "active_user_count":54
  },
  "user_other_organizations2":[
    {
      "id":"5978ce55-1936-4997-b2df-3a619ab529af",
      "name":"Flying Robots",
      "payment_plan":{
        "id":"58c2d488-e3eb-47bf-b941-a2c2209f7fe3",
        "type":"complimentary-plan",
        "entity_type":"payment-plan"
      },
      "entity_type":"organization2",
      "workspaces2":[
        {
          "id":"57b49711-375d-4fc6-a290-514f8f9ddb04",
          "name":"Flying Robots",
          "url_slug":"flyingrobots",
          "entity_type":"workspace2",
          "active_user_count":29
          "display_icon": {"entity_type":"org-icon",
                                      "id":"5f2abaa5-38fb-41d0-89b3-51bc94008f79",
                                      "url":"https://media.clubhouse.io/api/attachments/organization-icons/clubhouse-assets/.../foo.jpg"}
        }
      ]
    }
  ]
}

*/

Collection.create('CompaniesSummary', exports);
exports.isValid = workspacesSummary => {
  return workspacesSummary && workspacesSummary.id;
};
exports.normalize = summary => {
  if (summary.user_default_workspace2) {
    _normalizeOrg(summary.user_default_workspace2);
  }
  summary.user_other_organizations2.forEach(org => {
    org.workspaces2.forEach(_normalizeOrg);
  });
};
const _normalizeOrg = org => {
  const shortcutIndex = _getShortcutIndex(org);
  if (shortcutIndex > -1) {
    org.shortcut_index = shortcutIndex;
  }
};
const _getShortcutIndex = org => {
  const allOrgs = OrganizationModel.getAllActive();
  return allOrgs.slice(0, 9).findIndex(org_ => org_.id === org.id);
};
exports.fetch = callback => Backend.get('/api/private/user/workspaces2/summary', {
  onComplete: res => {
    if (exports.isValid(res)) {
      exports.update(res);
      callback(res);
    } else {
      exports.defaultErrorHandler(res, callback);
    }
  }
});
export { exports as default };