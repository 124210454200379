import { isLoggedInPage } from 'utils/is';
import { notRouteException, usePagePathname } from 'utils/navigation';
import { ComponentErrorBoundary } from '../../ComponentErrorBoundary';
import { DockComponentV2 } from './DockComponentV2';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Dock = () => {
  const pathname = usePagePathname();
  return _jsx(ComponentErrorBoundary, {
    componentName: "Dock",
    children: pathname && isLoggedInPage() && notRouteException(pathname) ? _jsx(DockComponentV2, {}) : null
  });
};
Dock.displayName = "Dock";