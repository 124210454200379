import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useEffect } from 'react';
import { getCommandBar } from '../getCommandBar';
const listeners = new Map();

/**
 * Adds a callback function to CommandBar.
 * The callback function provided is mapped to callbackKey, and can be attached to commands by referencing callbackKey in a command config.
 */
export function useCommandBarCallback(callbackKey, callbackFn, options) {
  const skip = options?.skip || false;
  useEffect(() => {
    if (skip) return;
    if (!listeners.has(callbackKey)) {
      getCommandBar()?.addCallback?.(callbackKey, (args, context) => {
        (listeners.get(callbackKey) || []).forEach(fn => fn(args, context));
      });
      listeners.set(callbackKey, new Set());
    }
    listeners.get(callbackKey)?.add(callbackFn);
    return () => {
      const fns = listeners.get(callbackKey);
      fns?.delete(callbackFn);
      if (!fns?.size) {
        getCommandBar()?.removeCallback?.(callbackKey);
        listeners.delete(callbackKey);
      }
    };
  }, [callbackFn, callbackKey, skip]);
}