import { getHref } from 'components/gql/objective/links';
import { QuickSearchPopoverWithContext } from 'components/quickSearch/QuickSearch';
import DropdownController from '../../../core/js/controllers/dropdown';
import QuickSearchController from '../../../core/js/controllers/quickSearch';
import View from '../../../core/js/modules/view';
const exports = {};
const deactivateRelationMentionMarker = _ref => {
  let {
    unmountComponent,
    textToInsert
  } = _ref;
  window.Write.deactivateRelationMentionMarker({
    closeDropdown: DropdownController.closeAll,
    textToInsert
  });
  unmountComponent();
};
exports.open = _ref2 => {
  let {
    elementAtPosition,
    containerId,
    onClose
  } = _ref2;
  const mountNode = document.getElementById(containerId);
  View.renderComponent({
    component: QuickSearchPopoverWithContext,
    mountNode,
    getProps: _ref3 => {
      let {
        unmountComponent
      } = _ref3;
      return {
        contextProps: QuickSearchController.getContextProps(),
        elementAtPosition: elementAtPosition,
        popperOptions: {
          // NOTE: The other placements can be problematic with styling, specifically with fixed width
          //  on resize events. Some placements cause issues if the cursor is at the start of a line
          //  while other places cause issues when the cursor is wrapped to a new line. `bottom-start`
          //  seems to be the best without causing too many bugs.
          placement: 'bottom-start'
        },
        onClose: () => {
          onClose();
          unmountComponent();
        },
        searchProps: {
          autofocus: true,
          onResultClick: entity => {
            if (entity.__typename === 'Objective') {
              window.Write.insertAutocompleteItem({
                name: entity.objectiveName || entity.name || entity.title,
                url: getHref(entity.publicId)
              });
            } else {
              window.Write.insertAutocompleteItem({
                name: entity.name || entity.title,
                url: entity.app_url || entity.url
              });
            }
            unmountComponent();
          },
          onKeyDown: e => {
            if (e.target.value === '' && (e.key === 'Backspace' || e.key === 'ArrowLeft')) {
              e.preventDefault();
              deactivateRelationMentionMarker({
                unmountComponent
              });
            }
          },
          onKeyUp: e => {
            if (e.target.value === ' ') {
              deactivateRelationMentionMarker({
                unmountComponent,
                textToInsert: ' '
              });
            } else if (e.key === 'Escape') {
              deactivateRelationMentionMarker({
                unmountComponent
              });
            }
          },
          usePreviousSearch: false,
          showClearSearchBtn: false,
          showSearchOutsideBtn: false,
          openModalOnResultClick: false,
          kind: QuickSearchPopoverWithContext.KIND.DROPDOWN
        }
      };
    }
  });
};
export { exports as default };