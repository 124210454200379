import { find as findGroup, getById as getGroupById } from 'data/entity/group';
import { getProfileById, getProfileByUsername } from 'data/entity/profile';
import { MentionMenu } from '../MentionMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MENTION_PREFIX = '@';
const MENTION_FRAGMENT_REGEX = new RegExp(`^${MENTION_PREFIX}[a-zA-Z0-9\\-\\_\\.]*[a-zA-Z0-9\\-\\_]$`);
const MENTION_LINK_MARKDOWN_PATTERN = /\[@([^\]]+)\]\((?:shortcutapp|clubhouse)\:\/\/(?:members|groups)\/([^)]+)\)/gi;
export const convertBackToRawText = text => (text || '').replace(MENTION_LINK_MARKDOWN_PATTERN, (_wholeMatch, mentionName) => {
  return '@' + mentionName;
});
export const buildMentionMappingFromId = (id, stateRef) => {
  let mentionName = '';
  const member = getProfileById(id);
  if (member) {
    mentionName = `@${member.profile.mention_name}`;
    if (stateRef.current) stateRef.current.mappings[mentionName] = `[${mentionName}](shortcutapp://members/${id})`;
    return mentionName;
  }
  const group = getGroupById(id);
  if (group) {
    mentionName = `@${group.mention_name}`;
    if (stateRef.current) stateRef.current.mappings[mentionName] = `[${mentionName}](shortcutapp://groups/${id})`;
  }
  return mentionName;
};
export const buildAllMappings = (text, stateRef) => {
  if (!stateRef.current) return;
  if (!stateRef.current.mappings) stateRef.current.mappings = {};
  const mentions = text.match(new RegExp(`\\B${MENTION_PREFIX}[a-zA-Z0-9\\-\\_\\.]*[a-zA-Z0-9\\-\\_]\\b`, 'g'));
  if (mentions) {
    mentions.forEach(mention => {
      if (!stateRef.current?.mappings?.[mention]) {
        buildMentionMappingFromMentionName(mention, stateRef);
      }
    });
  }
};
export const buildMentionMappingFromMentionName = (input, stateRef) => {
  const onlyMentionName = input.startsWith('@') ? input.slice(1) : input; // Remove the @
  const mentionName = `@${onlyMentionName}`;
  const member = getProfileByUsername(onlyMentionName);
  if (member) {
    if (stateRef.current) stateRef.current.mappings[mentionName] = `[${mentionName}](shortcutapp://members/${member.id})`;
    return mentionName;
  }
  const group = findGroup(_ref => {
    let {
      mention_name,
      archived
    } = _ref;
    return !archived && mention_name === onlyMentionName;
  });
  if (group) {
    if (stateRef.current) stateRef.current.mappings[mentionName] = `[${mentionName}](shortcutapp://groups/${group.id})`;
  }
  return mentionName;
};
export const MentionAutocomplete = {
  hasPrefix: true,
  createMapping: buildMentionMappingFromId,
  test: text => !!text && MENTION_FRAGMENT_REGEX.test(text),
  Component: _ref2 => {
    let {
      fragment,
      triggerRef
    } = _ref2;
    return _jsx(MentionMenu, {
      triggerRef: triggerRef,
      filter: fragment,
      isSearchable: false
    });
  }
};