import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DocCountChip = () => {
  const story = useContext(StoryContext);
  if (!story || !story.stats?.num_related_documents) return null;
  const count = story.stats.num_related_documents;
  return _jsx(StoryCardInternal.Chips.DocCountChip, {
    count: count
  });
};
DocCountChip.displayName = "DocCountChip";