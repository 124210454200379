import "core-js/modules/es.array.push.js";
import xor from 'lodash/xor';
import * as ProfileData from 'data/entity/profile';
import * as StoryData from 'data/entity/story';
import { toggleOwnerState } from 'utils/addNew';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryOwners = story => {
  lazilyLoadArgumentChoices('currentOwnersForStory', () => getCurrentOwnersForStory(story));
  lazilyLoadArgumentChoices('membersForCurrentStory', () => getMembersForCurrentStory(story));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryOwners', updateStoryOwners(story));
    getCommandBar().addCallback('toggleCurrentUserAsOwner', toggleCurrentUserAsOwner(story));
  }
};
const getMembersForCurrentStory = story => {
  if (!story) return null;
  const allMembers = ProfileData.getAllActiveProfileDetails();
  if (story.owner_ids && story.owner_ids.length) {
    const members = [];
    allMembers.forEach(member => {
      if (story.owner_ids?.includes(member.id)) {
        members.unshift(member);
      } else {
        members.push(member);
      }
    });
    return members;
  }
  return allMembers;
};
const getCurrentOwnersForStory = story => {
  if (!story) return null;
  const allMembers = ProfileData.getAllActiveProfileDetails();
  if (story.owner_ids && story.owner_ids.length) {
    return allMembers.filter(member => story.owner_ids.includes(member.id));
  }
  return [];
};
const updateStoryOwners = story => _ref => {
  let {
    owners
  } = _ref;
  if (!story) return;
  const newOwners = owners.map(u => u.id);
  if (StoryData.isNew(story)) {
    const ownersToToggle = xor(story.owners, owners);
    ownersToToggle.forEach(owner => toggleOwnerState(owner));
  } else {
    StoryData.save({
      id: story.id,
      ...{
        owner_ids: newOwners
      }
    });
  }
};
const toggleCurrentUserAsOwner = story => () => {
  if (!story) return;
  const profile = ProfileData.getCurrentUserProfileDetails();
  if (StoryData.isNew(story)) {
    toggleOwnerState(profile);
  } else {
    StoryData.toggleOwner(story, profile);
  }
};