import { RadioButtons } from '@clubhouse/shared/components/RadioButtons';
import { FilterLabel } from './ReportSelectUtils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryPointsToggle = _ref => {
  let {
    onChange,
    checkedValue = 'stories'
  } = _ref;
  return _jsxs("div", {
    children: [_jsx(FilterLabel, {
      children: "Sum Using"
    }), _jsxs(RadioButtons, {
      selected: checkedValue,
      onChange: onChange,
      children: [_jsx(RadioButtons.TextLabel, {
        value: "stories",
        children: "Stories"
      }), _jsx(RadioButtons.TextLabel, {
        value: "points",
        children: "Points"
      })]
    })]
  });
};
StoryPointsToggle.displayName = "StoryPointsToggle";