import { useSearchParam } from 'utils/navigation';
import { createSearchParamConfig } from '../../gql/utils/createSearchParamConfigs';
const teamScopeSearchParamConfig = createSearchParamConfig({
  paramName: 'team_scope_id',
  paramType: 'string'
});
export const useTeamScopeIdForListPage = () => {
  const {
    param,
    clearSearchParam
  } = useSearchParam(teamScopeSearchParamConfig);
  return {
    param,
    clearSearchParam
  };
};