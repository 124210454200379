export const reorderByPosition = (items, oldIndex, newIndex) => {
  const newPos = items[newIndex].position;
  if (oldIndex < newIndex) {
    for (let i = newIndex; i > oldIndex; --i) {
      items[i].position = items[i - 1].position;
    }
  } else {
    for (let i = newIndex; i < oldIndex; ++i) {
      items[i].position = items[i + 1].position;
    }
  }
  items[oldIndex].position = newPos;
  return items;
};
export const moveWithinArray = (items, oldIndex, newIndex) => {
  // translate negative indices into real indices
  const oldI = (oldIndex + items.length) % items.length;
  const newI = (newIndex + items.length) % items.length;
  const obj = items[oldI];
  if (oldI > newI) {
    for (let i = oldI; i > newI; --i) {
      items[i] = items[i - 1];
    }
  } else {
    for (let i = oldI; i < newI; ++i) {
      items[i] = items[i + 1];
    }
  }
  items[newI] = obj;
  return items;
};