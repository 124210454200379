import { MutateStoryWorkflowStateDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { WorkflowStatesFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryWorkflowStateSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { WorkflowStatesSelectTarget } from 'components/gql/workflowState/WorkflowStatesSelectTarget';
import { getSection } from 'components/gql/workflowState/utils/sections';
import { useMutation } from 'gql';
import { useMutationContext } from './FieldContextProvider';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_STORY_WORKFLOW_STATE_SELECT = QueryStoryWorkflowStateSelectDocument;
export const WorkflowStatesFieldFragment = WorkflowStatesFieldFragmentFragmentDoc;
const MUTATE_WORKFLOW_STATE_FIELD = MutateStoryWorkflowStateDocument;
export const WorkflowStatesFieldComponent = _ref => {
  let {
    entity: {
      id,
      workflowState
    },
    onChangeCompleted
  } = _ref;
  const mutationContext = useMutationContext();
  const fetchStoryWorkflowStates = useFieldOptionsQuery({
    query: QUERY_STORY_WORKFLOW_STATE_SELECT,
    storyId: id,
    includeCurrentPermissionId: false
  });
  const [updateStoryState] = useMutation(MUTATE_WORKFLOW_STATE_FIELD);
  const handleChange = useCallback(async (selectedWorkflowStateId, selectedItem) => {
    await updateStoryState({
      variables: {
        storyId: id,
        input: {
          workflowState: selectedWorkflowStateId
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(id, {
        workflowState: {
          __typename: 'WorkflowState',
          id: selectedWorkflowStateId,
          type: selectedItem?.additionalData?.type,
          name: selectedItem?.additionalData?.name
        }
      }),
      onCompleted(mutationResponse) {
        onChangeCompleted?.(mutationResponse);
      },
      ...mutationContext
    });
  }, [updateStoryState, id, mutationContext, onChangeCompleted]);
  return _jsx(GroupedOptionsFieldSingleSelection, {
    selectedEntity: workflowState,
    fetchOptions: fetchStoryWorkflowStates,
    getSection: getSection,
    TargetComponent: WorkflowStatesSelectTarget,
    onChange: handleChange,
    unit: Nouns.State,
    required: true
  });
};
WorkflowStatesFieldComponent.displayName = "WorkflowStatesFieldComponent";
export const WorkflowStatesFieldConfig = {
  name: 'workflowState',
  displayName: 'State',
  sort: 'workflowState_workflow_name_workflowState_position',
  width: 200
};
export const WorkflowStatesField = () => ({
  ...WorkflowStatesFieldConfig,
  Component: WorkflowStatesFieldComponent
});