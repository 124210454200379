import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import EventDefinitionModel from './eventDefinition';
const exports = {};

/*

Event entities:

{
  "id": 4058,
  "name": "github/pull-request/open",
  "created_at": "2015-11-03T16:33:10Z",
  "updated_at": "2015-11-13T16:49:59Z",
  "disabled": false,
  "definition": [{
    "match_name": "master",
    "workflow_state_id": 2199
  }]
},
{
  "id": 4059,
  "name": "github/branch/merge",
  "created_at": "2015-11-03T16:42:16Z",
  "updated_at": "2015-11-13T16:47:55Z",
  "disabled": false,
  "definition": [{
    "match_name": "master",
    "workflow_state_id": 1495
  }]
}

*/

Collection.create('Event', exports);
exports.on('fetched.collection eventUpdated.collection eventSaved.collection', () => {
  EventDefinitionModel.transformAll();
});
exports.isValid = obj => {
  return obj && obj.id && obj.definition;
};
exports.fetchAll = callback => {
  Backend.get('/api/private/events', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.fetchByID = (id, callback) => {
  Backend.get('/api/private/events/' + id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'eventUpdated');
    }
  });
};
exports.saveNew = (data, callback) => {
  // 'version' is schema version.
  // Ref: https://github.com/useshortcut/backend/blob/main/modules/server/src/clubhouse/controllers/events.clj
  data.version = 1;
  Backend.post('/api/private/events', {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'eventSaved');
    }
  });
};
exports.updateEvent = (evt, updates, callback) => {
  // 'version' is schema version.
  // Ref: https://github.com/useshortcut/backend/blob/main/modules/server/src/clubhouse/controllers/events.clj
  updates.version = 1;
  Backend.put('/api/private/events/' + evt.id, {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'eventUpdated');
    }
  });
};
exports.deleteEvent = (evt, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/events/' + evt.id + '/disable', {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        exports.update({
          id: evt.id,
          disabled: true
        });
        EventDefinitionModel.transformAll();
        callback(false, exports.getById(evt.id));
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.restoreEvent = (evt, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/events/' + evt.id + '/enable', {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        exports.update({
          id: evt.id,
          disabled: false
        });
        EventDefinitionModel.transformAll();
        callback(false, exports.getById(evt.id));
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
export { exports as default };