import Logo from '@clubhouse/assets/png/third-party-logos/slab_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Slab() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.SLAB
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Slab"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Slab Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Stay on top of Shortcut stories from within ", _jsx(ExternalLink, {
          href: "https://www.slab.com/",
          children: "Slab"
        }), ' ', "with link unfurling. Hovering over Shortcut links in Slab will show more details, including the due date, description, and labels."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://slab.com/integrations/shortcut/",
          children: "Learn more"
        }), " on how the Slab integration works in Shortcut."]
      })]
    })]
  });
}
Slab.displayName = "Slab";