import { useMemo } from 'react';
import { getCurrentBackgroundLocationState } from './backgroundLocationState';
/**
 * @private
 */
export const usePageLocation = _ref => {
  let {
    pathname,
    href,
    search,
    state
  } = _ref;
  const {
    backgroundLocation
  } = getCurrentBackgroundLocationState(state);
  return useMemo(() => backgroundLocation ?? {
    pathname,
    href,
    search,
    state
  }, [backgroundLocation, href, pathname, search, state]);
};