import { NavigationLink } from './NavigationLink';
import { useCurrentStoryDialogNavigation } from './utils';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryDialogNavigation = _ref => {
  let {
    storyId
  } = _ref;
  const {
    prev,
    next,
    goNext,
    goPrevious
  } = useCurrentStoryDialogNavigation(storyId);
  return _jsxs(_Fragment, {
    children: [prev ? _jsx(NavigationLink, {
      type: "prev",
      storyId: prev,
      onNavigate: goPrevious
    }, prev) : null, next ? _jsx(NavigationLink, {
      type: "next",
      storyId: next,
      onNavigate: goNext
    }, next) : null]
  });
};