import ClipboardJS from 'clipboard';
import Globals from '../_frontloader/globals';
import Tooltip from './tooltip';
const exports = {};
const CLIPBOARD_SELECTOR = '.clipboard';
exports.init = () => {
  if (Globals.get('clipboardInitialized')) {
    return false;
  }
  const clipboards = new ClipboardJS(CLIPBOARD_SELECTOR);
  clipboards.on('success', e => {
    e.clearSelection();
    showTooltip(e.trigger, 'Copied!');
  });
  clipboards.on('error', e => {
    showTooltip(e.trigger, fallbackMessage(e.action));
  });

  // Can I hook into FastBinder's existing event binding here?
  $('body').on('click.Clipboard', CLIPBOARD_SELECTOR, e => {
    e.preventDefault();
  });
  Globals.set('clipboardInitialized', true);
};
function showTooltip(elem, msg) {
  const cachedTooltipAttribute = $(elem).data('tooltip');
  Tooltip.close();
  $(elem).attr({
    'data-tooltip': msg,
    'data-tooltip-delay': 1,
    'data-tooltip-fixed': true
  }).on('mouseout.Clipboard', function () {
    $(this).removeAttr('data-tooltip data-tooltip-fixed').off('mouseout.Clipboard');
    if (cachedTooltipAttribute) {
      $(this).attr('data-tooltip', cachedTooltipAttribute);
    }
  }).trigger('mousemove');
}
function fallbackMessage(action) {
  const key = action === 'cut' ? 'X' : 'C';
  let msg;
  if (/iPhone|iPad/i.test(navigator.userAgent)) {
    msg = 'Unable to copy on touch devices.';
  } else if (/Mac/i.test(navigator.userAgent)) {
    msg = 'Press ⌘-' + key + ' to ' + action;
  } else {
    msg = 'Press Ctrl-' + key + ' to ' + action;
  }
  return msg;
}
export { exports as default };