import { EventNamesEnum } from '@useshortcut/events';
import { useEffect, useRef } from 'react';
import { useTableStateOnChange } from '@clubhouse/shared/components/Table/TableStateProvider';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { useOnFilterChange } from 'components/gql/filters/FilterStateProvider';
import { logNamedEvent } from 'utils/monitoring';
import { useCurrentView } from '../components/ViewSelector';
import { useGroupByParam } from './groupBy';
export const logObjectivesEvent = data => {
  logNamedEvent(EventNamesEnum.Objectives_Page_Interaction, {
    ...data
  });
};
const filterToFilterName = {
  category_ids: 'categories',
  group_ids: 'teams',
  health_status: 'objective-health',
  objective_state: 'objective-states',
  objective_type: 'objective-type',
  owner_ids: 'owners',
  query: 'text-search',
  show_archived: 'show-archived'
};
const getFilterChangeEventProps = (name, value) => {
  const interaction_type = value !== undefined && typeof value === 'string' || Array.isArray(value) && value.length > 0 || name === 'show-archived' && !!value ? 'filter-applied' : 'filter-removed';
  return {
    action_context: `objectives table ${name} filter updated`,
    interaction_type,
    filter_type: name,
    category_public_ids: name === 'categories' ? value : undefined,
    objective_health_filters: name === 'objective-health' ? value : undefined,
    objective_states: name === 'objective-states' ? value : undefined,
    objective_type: name === 'objective-type' ? value : undefined,
    owner_public_ids: name === 'owners' ? value : undefined,
    teams: name === 'teams' ? value : undefined
  };
};
export const ObjectivesPageFiltersLogger = _ref => {
  let {
    children
  } = _ref;
  const view = useCurrentView();
  const [groupBy] = useGroupByParam();
  const group_by = groupBy !== null ? groupBy : 'none';
  const isMounted = useRef(false);
  const logGroupByEvent = useLatestRef(() => {
    logObjectivesEvent({
      action_context: 'group by select values updated',
      interaction_type: 'group-by',
      group_by,
      view
    });
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useEffect(() => {
    if (isMounted.current) {
      logGroupByEvent.current();
    } else {
      isMounted.current = true;
    }
  }, [group_by, logGroupByEvent]);
  useOnFilterChange((key, value) => {
    const name = filterToFilterName[key];
    if (!name) {
      return;
    }
    const eventProps = getFilterChangeEventProps(name, value);
    logObjectivesEvent({
      ...eventProps,
      group_by,
      view
    });
  });
  useTableStateOnChange(_ref2 => {
    let {
      display_columns
    } = _ref2;
    logObjectivesEvent({
      action_context: 'objectives table state updated',
      display_columns,
      group_by,
      interaction_type: 'display-columns',
      view
    });
  });
  return children;
};