import Backend from 'app/client/core/js/modules/backend'; // eslint-disable-line import/no-restricted-paths
import { fetchConsolidatedDataBeforeRedirectFromSignup } from 'utils/createOrgOrWorkspaceController';
import { log } from 'utils/log';
export async function submitSignupToAPI(props) {
  const {
    requirePassword,
    user_password,
    ...data
  } = props.signupData;
  const userPassword = !props.isOauth ? {
    user_password
  } : {};
  await new Promise((resolve, reject) => {
    Backend.put('/api/private/signups/' + props.signupId + '/create', {
      data: {
        ...data,
        ...userPassword
      },
      onComplete: (res, xhr) => {
        if (xhr.status === 204) {
          log('New workspace created', {
            organization: props.signupData.organization_name,
            userName: props.signupData.user_name,
            userEmail: props.userEmail
          });
          resolve();
        } else if (xhr.status === 409) {
          // This is a special case where the user has already signed up and is trying to sign up again
          // This resolves the promise so that the user can be redirected to the workspace or to the login page
          resolve();
        } else {
          reject(res);
        }
      },
      ...(props.autoJoinChecked ? {
        actionContext: 'signup'
      } : {})
    });
  });
  await fetchConsolidatedDataBeforeRedirectFromSignup();
}