import IntegrationModel from '../../../core/js/models/integration';
const exports = {};
exports.createAndAuthorize = _ref => {
  let {
    code,
    redirect_uri
  } = _ref;
  return new Promise((resolve, reject) => {
    return IntegrationModel.createNew({
      type: 'slack'
    }, (err, integration) => {
      if (err) {
        return reject(err);
      }
      return resolve(exports.authorize({
        integration,
        code,
        redirect_uri
      }));
    });
  });
};
exports.authorize = _ref2 => {
  let {
    integration,
    code,
    redirect_uri
  } = _ref2;
  return IntegrationModel.authorizeSlackIntegrationV2({
    integration,
    code,
    redirect_uri
  });
};
export { exports as default };