import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { WithFullStory } from 'components/shared/ContextualStoryCard';
import { isDoneState } from 'data/entity/story';
import * as Is from 'utils/is';
import { StoryListItem } from '../../shared/StoryListItem';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { StorySearchCardAdapter } from './StorySearchCard';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryResults = _ref => {
  let {
    showCards,
    fetchProps: {
      loading,
      data: allStories,
      previousData,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick,
    excludeStories = []
  } = _ref;
  if (loading && !hasFetched) {
    return _jsxs(_Fragment, {
      children: [_jsx(SkeletonSearchCard, {
        icon: "Feature",
        kind: "yellow"
      }), _jsx(SkeletonSearchCard, {
        icon: "Bug",
        kind: "red"
      }), _jsx(SkeletonSearchCard, {
        icon: "Chore",
        kind: "blue"
      })]
    });
  }
  const stories = (loading ? previousData : allStories)?.filter(story => story && !excludeStories.some(s => s.id === story.id)) || [];
  if (hasFetched && !stories?.length) {
    return _jsx(NoResults, {
      entityType: "Stories"
    });
  }
  const StoryComponent = showCards ? StorySearchCardAdapter : StoryListItem;
  return _jsxs(_Fragment, {
    children: [(stories || []).map((story, index) => _jsx(WithFullStory, {
      component: StoryComponent,
      story: story,
      onClick: entity => onClick?.(entity, index),
      openModalOnClick: openModalOnClick,
      showReveal: !story.archived && Is.storiesPage(),
      isDone: isDoneState(story),
      level: "3"
    }, story.id)), next && _jsx(LoadMore, {
      loading: loading,
      loadMore: loadMore
    })]
  });
};