import StoriesView from 'app/client/stories/js/modules/storiesView';
export const setStoriesGroupBy = value => StoriesView.setTableGroupBy(value);
export const getStoriesGroupBy = () => StoriesView.getTableGroupBy();
export const setStoriesViewType = type => StoriesView.setViewType(type);
export const getStoriesViewType = () => StoriesView.getViewType();
export const getVisibleStoriesInColumn = column => StoriesView.getVisibleStoriesInColumn(column);
export const drawStories = () => StoriesView.drawStories();
export const removeColumn = column => StoriesView.removeColumn(column);
export const addColumn = (column, width) => StoriesView.addColumn(column, width);
export const VIEW_TYPE = {
  TABLE: 'table',
  KANBAN: 'kanban'
};