import { useCallback } from 'react';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxInteractions } from './ComboboxInteractions';
import { ComboboxMenu } from './ComboboxMenu';
import { ComboboxProvider } from './ComboboxProvider';
import { ComboboxCreateItemFromInput, ComboboxItem, ComboboxLabel, ComboboxLabelItem, ComboboxTitleItem, ContentContainer, Divider, ListBox, ListLoading, LoadingItem, UnstyledComboboxItem } from './components';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Combobox = _ref => {
  let {
    children,
    onSelect,
    onClose
  } = _ref;
  const handleEvent = useCallback(_ref2 => {
    let {
      type,
      payload
    } = _ref2;
    switch (type) {
      case 'select':
        onSelect(payload);
        break;
      case 'close':
        onClose();
        break;
    }
  }, [onSelect, onClose]);
  return _jsx(ComboboxProvider, {
    onEvent: handleEvent,
    children: _jsx(ComboboxInteractions, {
      children: children
    })
  });
};
Combobox.displayName = "Combobox";
Combobox.Menu = ComboboxMenu;
Combobox.Label = ComboboxLabel;
Combobox.Input = ComboboxInput;
Combobox.List = ListBox;
Combobox.TitleItem = ComboboxTitleItem;
Combobox.LabelItem = ComboboxLabelItem;
Combobox.SelectableItem = ComboboxItem;
Combobox.SelectableItemUnstyled = UnstyledComboboxItem;
Combobox.CreateItemFromInput = ComboboxCreateItemFromInput;
Combobox.ListLoading = ListLoading;
Combobox.LoadingItem = LoadingItem;
Combobox.ContentContainer = ContentContainer;
Combobox.Divider = Divider;