import { useEffect, useState } from 'react';
import { setGlobal } from 'utils/globals';
import { hideOfflineAlert, showOfflineAlert } from 'utils/offline';
export const isOnline = () => navigator.onLine;
export function useOnlineStatus() {
  const [online, setOnline] = useState(navigator.onLine);
  useEffect(() => {
    const onStateChange = () => {
      const isOnline = navigator.onLine;
      setGlobal('UserIsOnline', isOnline);
      setOnline(isOnline);
      if (isOnline) hideOfflineAlert();else showOfflineAlert();
    };
    window.addEventListener('online', onStateChange);
    window.addEventListener('offline', onStateChange);
    onStateChange();
    return () => {
      window.removeEventListener('online', onStateChange);
      window.removeEventListener('offline', onStateChange);
    };
  }, []);
  return online;
}