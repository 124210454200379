import Logo from '@clubhouse/assets/png/third-party-logos/airfocus-logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Airfocus() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.AIRFOCUS
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Airfocus"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Airfocus Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Use ", _jsx(ExternalLink, {
          href: "https://www.airfocus.com",
          children: "airfocus'"
        }), " flexible ", BRAND.NAME, ' ', "integration to prioritize your stories and epics to build more effective roadmaps. The integration keeps high-level strategy in sync with day-to-day development.", _jsxs("ul", {
          children: [_jsxs("li", {
            children: ["Import ", BRAND.NAME, " epics and stories (as items) into airfocus."]
          }), _jsxs("li", {
            children: ["Push prioritized items into ", BRAND.NAME, " for development and track their ", BRAND.NAME, " status right from within airfocus."]
          }), _jsxs("li", {
            children: ["Create a flexible filtering and mapping workflow, e.g., map multiple ", BRAND.NAME, " states to the same airfocus status."]
          }), _jsx("li", {
            children: "Keep fields synced (two-way) between both systems, including title, description, status, and custom fields."
          })]
        })]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Visit airfocus'", ' ', _jsx(ExternalLink, {
          href: "https://help.airfocus.com/en/articles/4107035-integrate-with-shortcut",
          children: "help center"
        }), ' ', "to learn more about configuring this integration in your airfocus account."]
      })]
    })]
  });
}
Airfocus.displayName = "Airfocus";