import { DisplayableIconFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { DisplayIcon } from '../DisplayIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DisplayableIconFragment = DisplayableIconFragmentDoc;
export const DisplayableIcon = _ref => {
  let {
    displayable,
    size
  } = _ref;
  if (!displayable.displayableIcon) {
    return null;
  }
  return _jsx(DisplayIcon, {
    ...displayable.displayableIcon,
    size: size
  });
};
DisplayableIcon.displayName = "DisplayableIcon";