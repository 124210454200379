import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useState } from 'react';
import { Divider } from './Divider';
import { getNoItemsFoundConfig } from './NoItemsFound';
const getDividerConfig = _ref => {
  let {
    key
  } = _ref;
  return {
    key: `group-${key}-divider`,
    component: Divider
  };
};
export const useItemGroups = _ref2 => {
  let {
    items,
    itemGroups,
    shouldShowRest
  } = _ref2;
  const [initialItemGroups, setInitialItemGroups] = useState(itemGroups);
  const {
    items: groupedItems
  } = toItemGroups({
    items,
    itemGroups: initialItemGroups,
    shouldShowRest
  });
  return {
    items: groupedItems,
    setInitialItemGroups
  };
};
export const toItemGroups = _ref3 => {
  let {
    items,
    itemGroups,
    shouldShowRest = false
  } = _ref3;
  const itemsLookup = new Map();
  items.forEach(item => {
    itemsLookup.set(item.value, item);
  });
  const markedValues = new Set();
  const orderedItems = itemGroups.reduce((acc, _ref4) => {
    let {
      key,
      itemValues,
      headerItem,
      showNoItemsFound = false
    } = _ref4;
    const items = itemValues.reduce((acc, value) => {
      if (markedValues.has(value)) {
        return acc;
      }
      const item = itemsLookup.get(value);
      if (!item) {
        return acc;
      }
      markedValues.add(value);
      return [...acc, {
        ...item
      }];
    }, []);
    const headerItems = [...(acc.length ? [getDividerConfig({
      key
    })] : []), ...(headerItem ? [headerItem] : [])];
    if (items.length === 0) {
      if (showNoItemsFound) {
        return [...acc, ...headerItems, getNoItemsFoundConfig({
          key: `no-items-found-${key}`
        })];
      }
      return [...acc];
    }
    return [...acc, ...headerItems, ...items];
  }, []);
  if (items.length === 0 || markedValues.size === 0 && !shouldShowRest) {
    return {
      items: [getNoItemsFoundConfig({
        key: 'no-items-found'
      })]
    };
  }
  const rest = items.reduce((acc, item) => {
    if (markedValues.has(item.value)) {
      return acc;
    }
    return [...acc, item];
  }, []);
  return {
    items: [...orderedItems, ...(shouldShowRest && rest.length > 0 ? [...(orderedItems.length > 0 ? [{
      key: 'rest-items',
      component: Divider
    }] : []), ...rest] : [])]
  };
};