import { TitleBar } from '../TitleBar';
import { ItemList } from './ItemList';
import { SIZE } from './enums';
import { Input, SelectList, TitleContainer } from './layout';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DropdownList = _ref => {
  let {
    options,
    inputValue,
    highlightedIndex,
    getItemProps,
    getMenuProps,
    title,
    closeMenu,
    getLabelProps,
    getInputProps,
    isSearchable,
    isPositionReady,
    size,
    kind,
    isDisabled,
    width,
    itemReducer,
    FooterComponent
  } = _ref;
  return _jsxs(SelectList, {
    className: "react-multiselect-list",
    ...getMenuProps(),
    "aria-multiselectable": true,
    children: [title && _jsx(TitleContainer, {
      children: _jsx(TitleBar, {
        title,
        onClickClose: closeMenu
      })
    }), isSearchable ? _jsxs(_Fragment, {
      children: [_jsx("label", {
        ...getLabelProps({
          style: {
            display: 'none'
          }
        }),
        children: "Search for item"
      }), _jsx(Input, {
        ...getInputProps(),
        onBlur: e => {
          e.preventDefault();
          e.stopPropagation();
        },
        autoFocus: isPositionReady
      }, isPositionReady ? 0 : 1)]
    }) : null, _jsx(ItemList, {
      options,
      inputValue,
      highlightedIndex,
      getItemProps,
      size,
      kind,
      isDisabled,
      width,
      itemReducer
    }), FooterComponent]
  });
};
DropdownList.displayName = "DropdownList";
DropdownList.SIZE = SIZE;