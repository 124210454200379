import { useInit } from 'utils/boot';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export function BootPage(_ref) {
  let {
    children,
    pageName,
    renderId
  } = _ref;
  useInit({
    page: pageName,
    pageTitle: pageName,
    renderId
  }); // TODO: sc-183047 extract this into a separate boot sequence

  return _jsx(_Fragment, {
    children: children
  });
}