import InviteModel from './invite';
import OrganizationProfileModel from './organizationProfile';
import ProfileModel from './profile';
import UserModel from './user';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import Router from '../_frontloader/router';
import Backend from '../modules/backend';
import Is from '../modules/is';
import Iterate from '../modules/iterate';
import Url from '../modules/url';
import Utils from '../modules/utils';
const exports = {};

/*

Company model:

{
  disabled: true
  entity_type: "company"
  id: "5978ce2e-e9a1-4a87-8bf7-adced640a483"
  locked_out: false
  name: "Shortcut"
  owners: [
    { name: "Kurt Schrader" }
    { name: "Andrew Childs" }
    { name: "Ivan Willig" }
  ]
  restricted: false
  slug: "internal"
}

*/

Collection.create('Company', exports);
Router.addPageTitle('company', url => {
  const path = Url.parsePathSkipCache(url);
  const company = exports.getById(path.organizations);
  const fallback = `Organization | ${BRAND.NAME}`;
  return company ? company.name + ' | ' + fallback : fallback;
});
exports.normalize = company => {
  if ('previous_pm_tools' in company && typeof company.previous_pm_tools === 'string') {
    company.previous_pm_tools = company.previous_pm_tools.split(',');
  }
  Iterate.each(company.profiles, ProfileModel.updateIfValid);

  // This isn't done in the org model, as the mapping is
  // different when directly fetching a company as an owner.
  Iterate.each(company.organizations, org => {
    Iterate.each(org.invites, invite => {
      invite.org_id = org.id;
      InviteModel.updateIfValid(invite);
    });
  });
};
exports.isValid = company => {
  return Utils.hasKeys(company, ['id', 'name']);
};
exports.getFromOrg = org => {
  return exports.find(company => {
    return _.some(company.organizations, {
      id: org.id
    });
  });
};
exports.getFromUrl = url => {
  const path = Url.parsePath(url);
  return exports.get({
    slug: path.organizations
  });
};
exports.getCurrent = () => {
  return exports.getFromUrl() || exports.getById(_.get(OrganizationProfileModel.getOrgProfileForLoggedInUser(), 'company.id'));
};
exports.getCurrentID = () => {
  return _.get(exports.getCurrent(), 'id');
};
exports.getDashboardUrl = company => {
  return `/organizations/${company.slug}/manage`;
};
exports.hasMultipleOrgs = company => {
  return _.get(company, 'organizations', []).length > 1;
};
exports.canReferOthers = function () {
  let company = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.getCurrent();
  return _.get(company, 'can_refer');
};
exports.isOwner = () => {
  // It doens't matter which org we check for the owner role, as
  // owners are set to an owner of every org within a company.
  return Is.ownerOnly(UserModel.getLoggedInUserPermission());
};
exports.isAdmin = company => {
  return _.some(company.organizations, org => {
    return Is.adminOnly(UserModel.getLoggedInUserPermission(org.id));
  });
};
exports.fetch = (id, callback) => {
  Backend.get('/api/private/' + _getRoleForRequest() + '/organization2', {
    company_id: id,
    excludeOrgHeader: true,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.enable = (company, callback) => {
  Backend.put('/api/private/' + _getRoleForRequest() + '/organization2/enable', {
    company_id: company.id,
    excludeOrgHeader: true,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.disable = (company, params, callback) => {
  Backend.put('/api/private/' + _getRoleForRequest() + '/organization2/disable', {
    company_id: company.id,
    data: params,
    excludeOrgHeader: true,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.delete = (company, callback) => {
  Backend.delete('/api/private/' + _getRoleForRequest() + '/organization2', {
    company_id: company.id,
    excludeOrgHeader: true,
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(company, res, xhr, callback);
    }
  });
};
exports.saveChanges = (company, changes, callback) => {
  Backend.put('/api/private/' + _getRoleForRequest() + '/organization2', {
    company_id: company.id,
    data: changes,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
function _getRoleForRequest() {
  // We default to owner, as they're the only users that will be adding orgs
  // to a company and only have the ID to reference without a company entity.
  return Is.ownerOnly(UserModel.getLoggedInUserPermission()) ? 'owner' : 'admin';
}
exports.Promises = {};
exports.Promises.fetch = BaseUtils.promisify(exports.fetch);
export { exports as default };