import { QueryWorkspaceEpicStateSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicStateOptionFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { All, Divider } from '@clubhouse/shared/components/Select';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQuery } from 'components/gql/filters/hooks/useFilterOptionsQuery';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { getSection } from './utils/sections';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const STATIC_OPTIONS = [All(Nouns.State), Divider('after-all')];
export const EpicStateOptionFragment = EpicStateOptionFragmentDoc;
const QUERY_WORKSPACE_EPIC_STATE_SELECT = QueryWorkspaceEpicStateSelectDocument;
export function EpicStatesFilter(_ref) {
  let {
    entityType,
    filterHeading
  } = _ref;
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'state_ids',
    paramType: 'string[]'
  });
  const fetchEpicStates = useFilterOptionsQuery({
    query: QUERY_WORKSPACE_EPIC_STATE_SELECT,
    selectedIds: selectedValues
  });
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchEpicStates,
    getSection,
    selectedValues,
    filterType: Nouns.State,
    staticOptions: STATIC_OPTIONS
  });
  return _jsx(GroupedOptionsFilter, {
    items: items,
    fetchSections: fetchSections,
    totalItemCount: totalItemCount,
    selectedValues: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    filterType: Nouns.State,
    icon: "Kanban",
    filterHeading: filterHeading
  });
}
EpicStatesFilter.displayName = "EpicStatesFilter";