import { Button } from '@clubhouse/shared/components/Button';
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { addToast, ToastText } from '@clubhouse/shared/components/Toast';
import { deleteIteration } from 'data/entity/iteration';
import { loadURL } from 'utils/hotReload';
import { generatePathForPageWithSlug, getCurrentPage, PAGE_NAMES } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function DeleteIterationAction(_ref) {
  let {
    id
  } = _ref;
  const {
    openDialog,
    closeDialog
  } = useConfirmationDialogState();
  return _jsx(MoreActions.Item, {
    icon: "Trash",
    onClick: () => {
      openDialog({
        title: 'Delete Iteration',
        body: _jsx("div", {
          children: "Are you sure you want to delete this iteration?"
        }),
        cta: 'Delete Iteration',
        buttonKind: Button.KIND.WARNING,
        onClick: async () => {
          try {
            await deleteIteration(id);
            addToast({
              kind: 'success',
              timeout: 5000,
              Content: () => _jsx(ToastText, {
                children: "The iteration has been deleted!"
              })
            });
            if (getCurrentPage() !== PAGE_NAMES.ITERATIONS) loadURL(generatePathForPageWithSlug(PAGE_NAMES.ITERATIONS), 'iterations');
          } catch (err) {
            addToast({
              kind: 'alert',
              timeout: 5000,
              Content: () => _jsx(ToastText, {
                children: "The iteration could not be deleted."
              })
            });
          } finally {
            closeDialog();
          }
        }
      });
    },
    children: "Delete Iteration"
  });
}
DeleteIterationAction.displayName = "DeleteIterationAction";