import "core-js/modules/es.array.push.js";
import Globals from '../_frontloader/globals';
import Iterate from './iterate';
const exports = {};
const NS = 'ClubhouseFloatingElement'; // Event namespace.
const LIBRARY_CLASSNAME = '.theiaStickySidebar';
exports.init = (element, options) => {
  const padding = 10;
  const defaults = {
    additionalMarginTop: $('#header-raven').outerHeight() + padding,
    defaultPosition: 'absolute',
    storeFn: o => {
      const elements = Globals.setOnlyIfMissing('FloatingElements', []);
      elements.push(o);
    },
    namespace: options.namespace || NS
  };
  return $(element).theiaStickySidebar(_.assign({}, defaults, options));
};
exports.destroy = (element, namespace) => {
  namespace = namespace || NS;

  // Unbind scroll / resize event handlers (which are bound by the library):
  $(document).off('scroll.' + namespace);
  $(window).off('resize.' + namespace);
  const el = $(element);
  if (el.length === 0) {
    return;
  }

  // Remove wrapper element:
  el.removeAttr('style').html(el.find(LIBRARY_CLASSNAME).html());
};
exports.destroyAll = () => {
  $(LIBRARY_CLASSNAME).parent().each((i, element) => {
    exports.destroy(element);
  });
  const floatingElements = Globals.get('FloatingElements');
  Iterate.each(floatingElements, obj => {
    Iterate.each(_.keys(obj), key => {
      obj[key] = null;
    });
  });
  Globals.set('FloatingElements', []);
};
export { exports as default };