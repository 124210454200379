import noop from 'lodash/noop';
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
const DIALOGS = {
  archive: {
    body: 'Archiving this Epic will exclude it and all of its Stories from reports and charts, as well as from the Stories, Status, and My Work pages.',
    buttonKind: Button.KIND.WARNING,
    cta: 'Archive Epic',
    onClick: noop,
    title: 'Are You Sure You Want To Archive This Epic?'
  },
  delete: {
    body: 'Deleting this Epic will not affect any Stories contained in this Epic.',
    buttonKind: Button.KIND.WARNING,
    cta: 'Delete Epic',
    onClick: noop,
    title: 'Are You Sure You Want To Delete This Epic?'
  }
};
export function useEpicConfirmationDialog() {
  const {
    openDialog: open,
    closeDialog
  } = useConfirmationDialogState();
  const openDialog = useCallback((type, props) => {
    open({
      ...DIALOGS[type],
      ...props
    });
  }, [open]);
  return {
    openDialog,
    closeDialog
  };
}