import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { SecondaryActionLink } from 'components/shared/DetailPageSidebar';
import { IconContainer } from 'components/shared/DetailPageSidebar/DetailPageSidebarRow';
import { navigateTo } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function GotoUrl(_ref) {
  let {
    url,
    tooltipText
  } = _ref;
  return _jsx(Tooltip, {
    content: tooltipText,
    children: _jsx(SecondaryActionLink, {
      href: url,
      onClick: e => {
        if (isClickedWithModifier(e)) {
          e.stopPropagation();
        } else {
          e.preventDefault();
          navigateTo({
            url
          });
        }
      },
      "aria-label": tooltipText,
      children: _jsx(IconContainer, {
        children: _jsx(SizedIcon, {
          icon: "NewTab",
          fill: "main",
          size: 12
        })
      })
    })
  });
}
GotoUrl.displayName = "GotoUrl";