import { MutateApplicationStateDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { dispatchApplicationStateChanged } from 'utils/collectionizeToApolloMessageBus';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
import { parseCurrentUserPermissionSpaceData } from '../../utils/applicationState';
import { useApplicationStateContext } from './ApplicationStateProvider';
import { QUERY_APPLICATION_STATE } from './useApplicationState';
export const MUTATE_APPLICATION_STATE = MutateApplicationStateDocument;
export const useUpdateApplicationState = props => {
  const slug = useWorkspaceSlug();
  const {
    isSyncInProgress,
    getCurrentSync,
    setCurrentSync,
    addUpdatedKey
  } = useApplicationStateContext();
  const sync = useSync();
  const updateApolloCache = useUpdateApolloCache({
    ...props,
    slug: slug ?? ''
  });
  const rollbackToCurrentServerState = useRollbackToCurrentServerState({
    slug: slug ?? ''
  });
  const update = useCallback(async getUpdatedData => {
    if (!slug) {
      return;
    }
    updateApolloCache({
      getUpdatedData
    });
    addUpdatedKey(props.applicationStateKey);
    if (isSyncInProgress()) {
      return getCurrentSync();
    }
    try {
      const currentSync = sync({
        count: 1,
        slug
      });
      setCurrentSync(currentSync);
      const {
        spaceData
      } = await currentSync;
      updateApolloCache({
        currentUserPermissionSpaceData: spaceData
      });
      dispatchApplicationStateChanged(spaceData);
      return currentSync;
    } catch (e) {
      rollbackToCurrentServerState();
    } finally {
      setCurrentSync(undefined);
    }
  }, [addUpdatedKey, getCurrentSync, isSyncInProgress, props.applicationStateKey, rollbackToCurrentServerState, setCurrentSync, slug, sync, updateApolloCache]);
  return {
    update
  };
};
const useRollbackToCurrentServerState = _ref => {
  let {
    slug
  } = _ref;
  const {
    query
  } = useApolloClient();
  return useCallback(() => query({
    query: QUERY_APPLICATION_STATE,
    variables: {
      slug
    },
    fetchPolicy: 'network-only'
  }), [query, slug]);
};
const useUpdateApolloCache = _ref2 => {
  let {
    applicationStateKey,
    toExpectedType,
    updateQuery,
    slug
  } = _ref2;
  const {
    cache
  } = useApolloClient();
  return useCallback(props => {
    const updater = previousResult => {
      return {
        ...previousResult,
        ...(previousResult?.workspace2 ? {
          workspace2: {
            ...previousResult.workspace2,
            currentUserPermissionSpaceData: 'currentUserPermissionSpaceData' in props ? props.currentUserPermissionSpaceData : getUpdatedSpaceData({
              data: previousResult,
              getUpdatedData: props.getUpdatedData,
              toExpectedType,
              applicationStateKey
            })
          }
        } : {})
      };
    };
    if (updateQuery) {
      updateQuery(updater);
    } else {
      cache.updateQuery({
        query: QUERY_APPLICATION_STATE,
        variables: {
          slug
        }
      }, previousResult => {
        if (!previousResult?.workspace2) {
          return null;
        }
        return updater(previousResult);
      });
    }
  }, [applicationStateKey, cache, slug, toExpectedType, updateQuery]);
};
const useSync = () => {
  const {
    cache,
    query,
    mutate
  } = useApolloClient();
  const {
    getUpdatedKeys,
    clearUpdatedKeys,
    hasUpdatesInCache
  } = useApplicationStateContext();
  return useCallback(async function sync(_ref3) {
    let {
      slug,
      count
    } = _ref3;
    if (count > 5) {
      const error = new Error(`[useUpdateApplicationState.sync]: Guarding against loops. More than ${count} recursive calls to sync.`);
      logError(error);
      throw error;
    }
    const updatedKeys = getUpdatedKeys();
    clearUpdatedKeys();
    const updatesInCache = getUpdatesInCache({
      cache,
      slug,
      updatedKeys
    });
    try {
      const {
        data,
        error: fetchError
      } = await query({
        query: QUERY_APPLICATION_STATE,
        variables: {
          slug
        },
        fetchPolicy: 'no-cache'
      });
      if (hasUpdatesInCache()) {
        return sync({
          slug,
          count: count + 1
        });
      }
      const {
        currentUserPermission,
        currentUserPermissionSpaceData
      } = data?.workspace2 ?? {};
      if (fetchError || !currentUserPermission || currentUserPermissionSpaceData === undefined) {
        throw new Error('[useUpdateApplicationState.sync] Query error');
      }

      // call parseCurrentUserPermissionSpaceData because the no-cache fetch policy will not read @client fields
      const newData = parseCurrentUserPermissionSpaceData(currentUserPermissionSpaceData);
      const spaceData = JSON.stringify({
        ...newData,
        ...updatesInCache
      });
      const {
        errors,
        data: mutationResponseData
      } = await mutate({
        mutation: MUTATE_APPLICATION_STATE,
        variables: {
          id: currentUserPermission.id,
          input: {
            spaceData
          }
        }
      });
      if (hasUpdatesInCache()) {
        return sync({
          slug,
          count: count + 1
        });
      }
      if (errors) {
        throw new Error('[useUpdateApplicationState.sync] Mutation error');
      }
      return {
        data: mutationResponseData,
        spaceData
      };
    } catch (e) {
      if (hasUpdatesInCache()) {
        return sync({
          slug,
          count: count + 1
        });
      }
      throw e;
    }
  }, [cache, clearUpdatedKeys, getUpdatedKeys, hasUpdatesInCache, mutate, query]);
};
const getUpdatesInCache = _ref4 => {
  let {
    cache,
    slug,
    updatedKeys
  } = _ref4;
  const cachedData = cache.readQuery({
    query: QUERY_APPLICATION_STATE,
    variables: {
      slug
    }
  });
  const cachedSpaceData = cachedData?.workspace2?.parsedCurrentUserPermissionSpaceData || {};
  return updatedKeys.reduce((acc, key) => {
    acc[key] = cachedSpaceData[key];
    return acc;
  }, {});
};
const getUpdatedSpaceData = _ref5 => {
  let {
    data,
    getUpdatedData,
    toExpectedType,
    applicationStateKey
  } = _ref5;
  const existingApplicationStateData = data.workspace2?.parsedCurrentUserPermissionSpaceData;
  const applicationStateKeyData = getUpdatedData(toExpectedType(existingApplicationStateData?.[applicationStateKey]));
  const updatedData = {
    ...existingApplicationStateData,
    [applicationStateKey]: applicationStateKeyData
  };
  return JSON.stringify(updatedData);
};