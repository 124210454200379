import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/epics/links';
import { useEpicWithFetch } from 'data/entity/epic';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CollectionizeEpicContextMenu = _ref => {
  let {
    id
  } = _ref;
  const {
    epic,
    loading
  } = useEpicWithFetch({
    id
  });
  if (loading) return _jsx(ContextMenuLoading, {});
  if (!epic) return null;
  return _jsxs(ContextMenu, {
    children: [_jsx(OpenInNewTabContextMenuItem, {
      url: getHref(id)
    }), _jsx(ContextMenuDivider, {}), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getHref(id, true),
      label: "Link"
    }), _jsx(CopyContextMenuItem, {
      value: String(id),
      label: "ID"
    }), _jsx(ContextMenuDivider, {}), _jsx(PinContextMenuItem, {
      id: epic.global_id,
      entityType: Nouns.Epic,
      loggingContext: {
        typename: 'Epic'
      }
    })]
  });
};
CollectionizeEpicContextMenu.displayName = "CollectionizeEpicContextMenu";
export const useCollectionizeEpicContextMenu = epicId => {
  const {
    props
  } = useContextMenuWithLogging(() => _jsx(CollectionizeEpicContextMenu, {
    id: epicId
  }), {
    entityType: 'Epic'
  });
  return props;
};
export const useCollectionizeEpicContextMenuWithQuickSearchSessionLogging = (epicId, logQuickSearchInteractions) => {
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(() => _jsx(CollectionizeEpicContextMenu, {
    id: epicId
  }), {
    entityType: 'Epic'
  }, logQuickSearchInteractions);
  return props;
};