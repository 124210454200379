import * as ChangePasswordTemplate from 'app/client/settingsShared/views/templates/changePassword.html';
import Backend from '../../../core/js/modules/backend';
import FlashController from '../../../core/js/controllers/flash';
import { destroy as destoryPasswordStrength, init as initPasswordStrength } from 'app/client/core/js/controllers/passwordStrength';
import User from '../../../core/js/modules/user';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
const exports = {};
const PARENT_SELECTOR = '#change-password-form';
const PASSWORD_STRENGTH_SELECTOR = '#change-password-form .new-password input';
const BUTTON_SELECTOR = PARENT_SELECTOR + ' button.action';
exports.render = () => {
  const html = ChangePasswordTemplate.render({
    passwordAuthDisabled: User.passwordAuthDisabled(),
    hasSetPassword: UserModel.hasSetPassword(User.getCurrentUser())
  });
  $(PARENT_SELECTOR).html(html);
  initPasswordStrength(PASSWORD_STRENGTH_SELECTOR);
  Validator.init(PARENT_SELECTOR);
  SettingsPage.onDestroy('EditPassword', () => {
    Validator.destroy(PARENT_SELECTOR);
    destoryPasswordStrength(PASSWORD_STRENGTH_SELECTOR);
  });
};
exports.submit = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    data.new_password_confirm = data.new_password;
    Backend.put('/api/private/user/password', {
      data,
      onError: error => {
        const title = 'Unable to save new password.';
        View.revertButtonState(BUTTON_SELECTOR, 'Change Password');
        FlashController.error(context, title, error.message, true);
      },
      onSuccess: () => {
        UserModel.fetchCurrentUserWithoutRedirect(() => {
          exports.render();
          View.changeButtonToSuccess(BUTTON_SELECTOR);
        });
      }
    });
  }
  return false;
};
export { exports as default };