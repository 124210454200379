import { development as envDevelopment } from 'app/client/core/js/modules/env';
import { logError } from 'utils/monitoring';
export { processError } from 'utils/monitoring';
export function debug() {
  if (envDevelopment()) {
    window.console.debug(...arguments);
  }
}
export function log() {
  if (envDevelopment()) {
    window.console.log(...arguments);
  }
}
export function warn() {
  if (envDevelopment()) {
    window.console.warn(...arguments);
  }
}
export const error = logError;