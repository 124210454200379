import { useCallback } from 'react';
import { useFormState } from 'pages/signup/FormState';
import { logSignupFormCompleted } from 'pages/signup/utils/tracking';
import { logError } from 'utils/monitoring';
export const useTrackedOnComplete = onComplete => {
  const {
    getFormState
  } = useFormState();
  return useCallback(async () => {
    try {
      const done = logSignupFormCompleted(getFormState().organization_name);
      const timeout = new Promise(resolve => setTimeout(resolve, 1000));
      await Promise.race([done, timeout]);
    } catch (e) {
      logError(new Error('Tracking during Signup failed'));
    } finally {
      onComplete();
    }
  }, [getFormState, onComplete]);
};