import { useCallback, useMemo } from 'react';
import { FIELD_ID } from 'components/shared/table/types';
import { useAppState } from 'utils/appState';
import { GROUP_BY } from 'utils/groupBy';
import { useSearchParams } from 'utils/navigation';
import { GROUP_BY_KEY, SORT_BY_KEY, SORT_DIR_KEY, URL_KEYS, createInitialState, removeNoOpFilters } from './storyTableSettingsConfig';
export function useStoryTableSavedState(_ref) {
  let {
    id,
    entityType
  } = _ref;
  const {
    params: urlState,
    setSearchParams
  } = useSearchParams(URL_KEYS);
  const [filterAppState, setFilterAppState] = useAppState({
    appStateKey: `${entityType.singular}Page.${id}.StoryFilter`,
    default: {}
  });
  const [groupByAppState, setGroupByAppState] = useAppState({
    appStateKey: `${entityType.singular}Page.${id}.groupedBy`,
    default: GROUP_BY.NONE
  });
  const [sortByAppState, setSortByAppState] = useAppState({
    appStateKey: `${entityType.singular}.SortBy`,
    default: FIELD_ID.LAST_UPDATED_DATE
  });
  const [sortDirAppState, setSortDirAppState] = useAppState({
    appStateKey: `${entityType.singular}.SortDirection`,
    default: 'ascending'
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only want initial value
  const initialState = useMemo(() => createInitialState({
    urlState,
    appState: {
      ...filterAppState,
      [GROUP_BY_KEY]: groupByAppState,
      [SORT_BY_KEY]: sortByAppState,
      [SORT_DIR_KEY]: sortDirAppState
    }
  }), []);
  const setSavedState = useCallback(settings => {
    setSearchParams(settings);
    const {
      group_by,
      sort_by,
      sort_dir,
      ...filters
    } = settings;
    setFilterAppState(removeNoOpFilters(filters));
    setGroupByAppState(group_by);
    setSortByAppState(sort_by);
    setSortDirAppState(sort_dir);
  }, [setSearchParams, setFilterAppState, setGroupByAppState, setSortByAppState, setSortDirAppState]);
  return [initialState, setSavedState];
}