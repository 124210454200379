import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import BaseUtils from '../_frontloader/baseUtils';
import Constants from './constants';
import Globals from '../_frontloader/globals';
import Iterate from './iterate';
import { replaceState } from '../_frontloader/detangledRouter';
const exports = {};
exports.getPath = url => {
  const origin = exports.getCurrentOrigin();
  if (_.includes(url, origin)) {
    url = url.split(origin)[1];
  }
  if (exports.isStoryURL(url)) {
    url = exports.cleanStoryURL(url);
  }
  return url;
};

// moved from utils
// Pathname is /path/to/page
exports.getCurrentPathname = () => {
  return window.location.pathname;
};
exports.getCurrentSlug = () => {
  const pathname = exports.getCurrentPathname();
  const pathNameParts = _.compact(pathname.split('/'));
  let slug;

  // FIXME (ivan) On the company management dashboard, the first part
  // of the url is not the org slug. On that page, `/organizations` is
  // the first part of the url. I check and no company has a slug of
  // `organizations` and that is now a reserved slug so no one can
  // create a company with that slug. This solution feels hacky but
  // works.
  if (pathname.startsWith('/organizations')) {
    slug = pathNameParts[1];
  } else {
    slug = pathNameParts[0];
  }
  return _.includes(Constants.RESERVED_SLUGS, slug) ? '' : slug;
};
exports.getCurrentPathnameWithHash = () => {
  return exports.getCurrentPathname() + (window.location.hash || '');
};
exports.getCurrentPathnameWithSearchAndHash = () => {
  return exports.getCurrentPathname() + (window.location.search || '') + (window.location.hash || '');
};
exports.isCurrentPath = pathName => {
  return exports.getCurrentPathnameWithHash() === pathName;
};

// Returns the first directory above the org.
// Could be 'login', 'reset', 'stories', 'story', etc.
exports.getCurrentPage = () => {
  // We save this because when the story dialog is opened,
  // the URL changes, but the underlying page does not change.
  if (Globals.get('initialPage')) {
    return Globals.get('initialPage');
  }
  const page = exports.getPageFromCurrentPathname();
  return Globals.set('initialPage', page);
};
exports.getPageFromPathname = pathname => {
  // We need to account for some routes having an org prefix, though not all!
  const pathnameParts = _.compact(pathname.split('/'));

  // This is an exception where the settings page is embedded inside /organizations, therefore
  // the current page should be settings even if we're inside organizations.
  if (exports.isOrganizationsSettingsPage(pathname)) {
    return 'settings';
  }
  if (Constants.RESERVED_SLUGS.includes(pathnameParts[0])) {
    return pathnameParts[0];
  }
  return pathnameParts[1];
};
exports.getPageFromCurrentPathname = () => exports.getPageFromPathname(exports.getCurrentPathname());
exports.isCurrentPage = pageName => {
  return exports.getCurrentPage() === pageName;
};
exports.parsePath = (pathname, options) => {
  options = options || {};
  const KEY = 'initialPath';

  // Used to save initial path on page load, since opening a story in a dialog
  // will change the path as long as the dialog is open.
  // The force option skips this to get a fresh path, needed when modifying the history API
  if (_.isPlainObject(Globals.get(KEY)) && !options.force) {
    return Globals.get(KEY);
  }
  const parsed = exports.parsePathSkipCache(pathname);
  Globals.set(KEY, parsed);
  return parsed;
};
exports.parsePathSkipCache = pathname => {
  pathname = pathname || exports.getCurrentPathname();

  // Handle cases where pathname is an entire URL
  if (pathname.indexOf(`${window.location.origin}/`) !== -1) {
    pathname = pathname.split(window.location.origin)[1];
  }

  // This function assumes a "/:resource/:id/:resource/:id" URL convention.
  const parsed = {};
  let resource = null;
  const segments = _.compact(pathname.split('/'));

  // Remove org slug from path
  if (!_.includes(Constants.RESERVED_SLUGS, segments[0])) {
    segments.shift();
  }
  Iterate.each(segments, segment => {
    if (segment) {
      if (!resource) {
        resource = segment;
        // We need to guard against an Epic named "Epic" here:
        parsed[resource] = parsed[resource] || null;
      } else {
        parsed[resource] = BaseUtils.toNumber(segment);
        resource = null;
      }
    }
  });
  return parsed;
};
exports.getCurrentOrigin = () => {
  return window.location.origin;
};
exports.isStoryURL = function () {
  let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.getCurrentPathname();
  return url.includes('/story/');
};
exports.cleanStoryURL = url => {
  const parts = _.compact(url.split('/'));
  return '/' + _.take(parts, 3).join('/');
};

// For when we can't use exports.parsePath due to its state management.
exports.getStoryIdFromPath = function () {
  let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.getCurrentPathname();
  let id = '';
  if (exports.isStoryURL(path)) {
    id = _.last(exports.cleanStoryURL(path).split('/'));
  }
  return id;
};

// This guards against paths like "//stories", which is treated like "http://stories".
exports.getSlugPath = _.throttle(() => {
  const slug = exports.getCurrentSlug();
  return slug ? '/' + slug : '';
}, 100);
exports.sanitizeRoute = routeName => {
  // if routeName is '/:slug/settings', this causes an infinite loop,
  // so let's default to the account settings dialog in that case.
  if (routeName === exports.getSlugPath() + '/settings' || routeName === '/organizations/settings') {
    routeName += '/account';
  }
  return routeName;
};
exports.getEntityPath = function () {
  let {
    entity_type,
    id
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return entity_type && id ? `/${entity_type}/${id}` : '/';
};

/** @deprecated Use model/Commment.getCommentURL instead */
exports.getThreadedCommentURL = comment => {
  const path = exports.parsePath();
  const entity = _.head(_.keys(path));
  return `${exports.getCurrentOrigin()}${exports.getSlugPath()}/${entity}/${path[entity]}#activity-${comment.id}`;
};
exports.isSlackAuth = () => {
  const pathname = exports.getCurrentPathname();
  const query = exports.parseLocationSearch();
  return _.includes(pathname, '/settings/integrations/slack') && query.state && (query.code || query.error);
};
exports.isSlackIdentify = () => {
  const pathname = exports.getCurrentPathname();
  const query = exports.parseLocationSearch();
  return _.includes(pathname, '/settings/account/notifications') && query.state && (query.code || query.error);
};
exports.isGsheetsAuth = () => {
  const pathname = exports.getCurrentPathname();
  const query = exports.parseLocationSearch();
  return _.includes(pathname, '/settings/integrations/gsheets') && (query.code || query.error);
};
exports.isBitbucket = () => {
  return _.includes(exports.getCurrentPathname(), '/settings/integrations/bitbucket');
};
exports.isGithubAuth = () => {
  const pathname = exports.getCurrentPathname();
  const query = exports.parseLocationSearch();
  return _.includes(pathname, '/settings/integrations/github') && query.setup_status;
};
exports.parseLocationSearch = function () {
  let search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.search;
  return _parseQueryString(search);
};
exports.getUrlSearchParams = () => {
  return new URLSearchParams(window.location.search);
};
exports.parseLocationHash = () => {
  return _parseQueryString(window.location.hash);
};

// Verbatim copy of parse function from:
// https://github.com/sindresorhus/query-string/blob/master/index.js
function _parseQueryString(str) {
  if (typeof str !== 'string') {
    return {};
  }
  str = str.trim().replace(/^(\?|#|&)/, '');
  if (!str) {
    return {};
  }
  return str.split('&').reduce((ret, param) => {
    const parts = param.replace(/\+/g, ' ').split('=');
    // Firefox (pre 40) decodes `%3D` to `=`
    // https://github.com/sindresorhus/query-string/pull/37
    let key = parts.shift();
    let val = parts.length > 0 ? parts.join('=') : undefined;
    key = decodeURIComponent(key);

    // missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    val = val === undefined ? null : decodeURIComponent(val);
    if (!Object.prototype.hasOwnProperty.call(ret, key)) {
      ret[key] = val;
    } else if (Array.isArray(ret[key])) {
      // IE 9+ support for Array.isArray
      ret[key].push(val);
    } else {
      ret[key] = [ret[key], val];
    }
    return ret;
  }, {});
}
function _encodeQueryString(obj) {
  return $.param(obj, true);
}
exports.hasQueryParams = () => {
  return _.keys(exports.parseLocationSearch()).length > 0;
};
exports.formatQueryParams = obj => {
  const queryString = _encodeQueryString(obj);
  return queryString ? '?' + queryString : '';
};
exports.notRouteException = path => {
  if (exports.isSlackAuth() || exports.isOrganizationsSettingsPage()) {
    return false;
  }
  if (_.startsWith(path, '/')) {
    path = path.substr(1);
  }
  const page = _.isString(path) ? path : exports.getCurrentPage();
  const routeExceptions = ['create-organization', 'login', 'signup', 'company', 'organizations', 'appauth', 'oauth', 'auth', 'recovery', 'confirm-email', 'sso', 'invite-link', 'reset', 'verify-email'];
  return _.every(routeExceptions, route => {
    return !_.startsWith(page, route);
  });
};
exports.currentUrlWithoutQueryParams = params => {
  const queryParams = _.omit(exports.parseLocationSearch(), params);
  return exports.getCurrentPathname() + exports.formatQueryParams(queryParams);
};
exports.generateSlug = text => text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
exports.updateParamInUrl = (id, value) => {
  if (id) {
    const urlParams = exports.parseLocationSearch();
    delete urlParams[id];
    if (value) {
      urlParams[id] = value;
    }
    replaceState(window.location.pathname + exports.formatQueryParams(urlParams), document.title);
    emitQueryChange({
      [id]: value
    });
  }
};
exports.updateParamsInUrl = params => {
  const urlParams = exports.parseLocationSearch();
  Object.entries(params).forEach(_ref => {
    let [id, value] = _ref;
    delete urlParams[id];
    if (value != null) {
      urlParams[id] = value;
    }
  });
  replaceState(window.location.pathname + exports.formatQueryParams(urlParams), document.title);
  emitQueryChange(params);
};
const listeners = [];
exports.addQueryChangeListener = (keys, handler) => {
  const listener = listeners.find(listener => listener.handler === handler);
  if (listener) {
    keys.forEach(key => listener.keys.add(key));
    return;
  }
  listeners.push({
    keys: new Set(keys),
    handler
  });
};
exports.removeQueryChangeListener = handler => {
  const i = listeners.findIndex(listener => listener.handler === handler);
  listeners.splice(i, 1);
};
const emitQueryChange = params => {
  const changedKeys = Object.keys(params);
  listeners.forEach(_ref2 => {
    let {
      keys,
      handler
    } = _ref2;
    const changes = {};
    keys.forEach(key => {
      if (changedKeys.includes(key)) changes[key] = params[key];
    });
    if (Object.keys(changes).length > 0) {
      handler(changes);
    }
  });
};
exports.getParamFromUrl = id => {
  if (id) {
    const urlParams = exports.parseLocationSearch();
    return urlParams[id];
  }
};
exports.isOrganizationsSettingsPage = pathname => {
  pathname = pathname || exports.getCurrentPathname();
  return pathname?.startsWith('/organizations/settings');
};
export { exports as default };