import { OrganizationsDashboardHeader } from './OrganizationsDashboardHeader';
import { Invites } from './invites/Invites';
import { OrganizationsList } from './organizations/OrganizationsList';
import { YourProfile } from './profile/YourProfile';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function OrganizationsDashboardPage() {
  return _jsx("div", {
    className: "org-dashboard",
    children: _jsxs("article", {
      children: [_jsxs("section", {
        className: "organizations",
        children: [_jsx(OrganizationsDashboardHeader, {}), _jsx(Invites, {}), _jsx(OrganizationsList, {
          kind: "active"
        }), _jsx(OrganizationsList, {
          kind: "inactive"
        })]
      }), _jsx(YourProfile, {})]
    })
  });
}
OrganizationsDashboardPage.displayName = "OrganizationsDashboardPage";