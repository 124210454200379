import { useCallback, useRef } from 'react';
import { bootSequence } from 'utils/bootStateMachine';
export const useHasRendered = () => {
  const headerHasRendered = useRef(false);
  const navHasRendered = useRef(false);
  const checkIfContainerHasRendered = useCallback(() => {
    if (headerHasRendered.current === true && navHasRendered.current === true) {
      bootSequence.containerRendered();
    }
  }, []);
  const handleHeaderHasRendered = useCallback(() => {
    headerHasRendered.current = true;
    checkIfContainerHasRendered();
  }, [checkIfContainerHasRendered]);
  const handleNavHasRendered = useCallback(() => {
    navHasRendered.current = true;
    checkIfContainerHasRendered();
  }, [checkIfContainerHasRendered]);
  return {
    handleHeaderHasRendered,
    handleNavHasRendered
  };
};