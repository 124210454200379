import MessageController from 'app/client/core/js/controllers/message';
export const openErrorMessage = function () {
  return MessageController.error(...arguments);
};
export const success = function () {
  return MessageController.success(...arguments);
};
export const error = function () {
  return MessageController.error(...arguments);
};
export const info = function () {
  return MessageController.info(...arguments);
};
export const closeAll = () => MessageController.closeAll();