import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportStories = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsxs("svg", {
    width: width,
    height: width,
    viewBox: "0 0 46 46",
    fill: "none",
    children: [_jsx("path", {
      d: "M35.8801 30.4367H10.5034C9.27672 30.4367 8.28005 29.44 8.28005 28.2133V14.4133C8.28005 13.1867 9.27672 12.19 10.5034 12.19H35.8801C37.1067 12.19 38.1034 13.1867 38.1034 14.4133V28.2133C38.1034 29.44 37.1067 30.4367 35.8801 30.4367Z",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M19.3967 16.4833H31.5062",
      stroke: "#ADC2DF",
      strokeWidth: "1.6",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M19.3967 21.39H31.5062",
      stroke: "#ADC2DF",
      strokeWidth: "1.6",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M19.3967 26.22H24.6101",
      stroke: "#ADC2DF",
      strokeWidth: "1.6",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M33.5799 36.34C36.7979 36.34 39.4066 33.7313 39.4066 30.5133C39.4066 27.2954 36.7979 24.6867 33.5799 24.6867C30.362 24.6867 27.7533 27.2954 27.7533 30.5133C27.7533 33.7313 30.362 36.34 33.5799 36.34Z",
      fill: "#BFDEC7"
    }), _jsx("path", {
      d: "M30.6438 31.1636L31.9925 32.5833L35.8259 28.75",
      stroke: "white",
      strokeWidth: "1.48148",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M9.58339 30.2833H14.6434V12.1133H10.5034C9.27672 12.1133 8.28005 13.11 8.28005 14.3367V28.1367C8.28005 29.1333 8.81672 29.9 9.58339 30.2833Z",
      fill: "#ADC2DF"
    })]
  });
};
ReportStories.displayName = "ReportStories";