export const toMenuId = id => `menu-${id}`;
export const toMenuItemId = (id, itemId) => `${toMenuId(id)}-${itemId.replace(/[^a-z0-9]+/gi, '-')}`;
export const updateActiveElement = (menuId, direction) => {
  return activeElementId => {
    const activeEl = (activeElementId && document.getElementById(activeElementId)) ?? null;
    const items = [...document.querySelectorAll(`#${toMenuId(menuId)} [role="listitem"]`)];
    let nextIndex = direction === 'next' ? 0 : -1;
    if (activeEl) {
      const currentIndex = items.indexOf(activeEl);
      if (currentIndex >= 0) nextIndex = currentIndex + (direction === 'next' ? 1 : -1);
    }
    const newItem = items[Math.min(Math.max(0, nextIndex), items.length - 1)] ?? null;
    return newItem?.getAttribute('id') ?? null;
  };
};
export const focusItem = elementId => {
  const el = document.getElementById(elementId);
  el?.scrollIntoView({
    block: 'center'
  });
  return !!el;
};
export const getActiveValue = activeElementId => {
  if (!activeElementId) return null;
  const activeEl = document.getElementById(activeElementId);
  if (!activeEl) return null;
  return activeEl.dataset.value ?? null;
};
export const getActiveOrFirstElementId = (elementId, menuId) => {
  let el = elementId ? document.getElementById(elementId) : null;
  if (!el) el = document.querySelector(`#${toMenuId(menuId)} [role="listitem"]`);
  return el?.getAttribute('id') ?? null;
};