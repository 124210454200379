import { Emojify } from '@clubhouse/shared/components/Emojify';
import { EpicAutoLink } from 'components/shared/EpicAutoLink';
import { MarkdownDisplay } from 'components/shared/MarkdownDisplay';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const EpicDescription = _ref => {
  let {
    name,
    epic,
    isGroupMention,
    isMention,
    description
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "value",
      children: [_jsx("strong", {
        children: _jsx(Emojify, {
          children: name
        })
      }), isMention ? ' mentioned you in ' : isGroupMention ? ' mentioned your group in ' : ' updated ', ' the description of the Epic ', _jsx(EpicAutoLink, {
        epic: epic,
        showTooltip: true
      })]
    }), (isMention || isGroupMention) && _jsx(MarkdownDisplay, {
      className: "comment",
      children: description
    })]
  });
};
export const EpicName = _ref2 => {
  let {
    name,
    oldName,
    epic
  } = _ref2;
  return _jsxs("div", {
    className: "value",
    children: [_jsx("strong", {
      children: _jsx(Emojify, {
        children: name
      })
    }), ' renamed the Epic ', _jsx("em", {
      children: _jsx(Emojify, {
        children: oldName
      })
    }), ' to ', _jsx(EpicAutoLink, {
      epic: epic,
      showTooltip: true
    })]
  });
};
EpicName.displayName = "EpicName";
export const EpicCreateDelete = _ref3 => {
  let {
    name,
    created,
    isMention,
    isGroupMention,
    description,
    epic
  } = _ref3;
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "value",
      children: [_jsx("strong", {
        children: _jsx(Emojify, {
          children: name
        })
      }), created ? _jsx(_Fragment, {
        children: isMention ? ' mentioned you while creating ' : isGroupMention ? ' mentioned your group while creating ' : ' created '
      }) : ' deleted ', "the Epic ", _jsx(EpicAutoLink, {
        epic: epic,
        showTooltip: true
      })]
    }), Boolean(created && (isMention || isGroupMention)) && _jsx(MarkdownDisplay, {
      className: "comment",
      children: description
    })]
  });
};
export const EpicCommentCreateDelete = _ref4 => {
  let {
    added,
    comment,
    epic,
    isMention,
    isGroupMention,
    name
  } = _ref4;
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "value",
      children: [_jsx("strong", {
        children: _jsx(Emojify, {
          children: name
        })
      }), added ? _jsx(_Fragment, {
        children: isMention ? ' mentioned you in a comment on ' : isGroupMention ? ' mentioned your group in ' : ' commented on '
      }) : ' deleted a comment from ', "the Epic ", _jsx(EpicAutoLink, {
        epic: epic,
        showTooltip: true
      })]
    }), added && !!comment && _jsx(MarkdownDisplay, {
      className: "comment",
      children: comment
    })]
  });
};