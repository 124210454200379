import "core-js/modules/es.array.push.js";
import { useMemo } from 'react';
import { SelectOptionChecked, SelectOptionDivider } from '@clubhouse/shared/components/Select';
import { GROUP_BY_NONE } from './utils';
export const GROUP_BY = {
  NONE: GROUP_BY_NONE,
  MILESTONE: 'milestone',
  STATE: 'epicState',
  TEAM: 'groupId',
  OBJECTIVE: 'objective'
};
const DIVIDER = {
  key: 'divider',
  Component: SelectOptionDivider
};
const OBJECTIVE = {
  value: GROUP_BY.OBJECTIVE,
  name: 'Objective',
  Component: SelectOptionChecked
};
const STATE = {
  value: GROUP_BY.STATE,
  name: 'State',
  Component: SelectOptionChecked
};
const TEAM = {
  value: GROUP_BY.TEAM,
  name: 'Team',
  Component: SelectOptionChecked
};
const NONE = {
  value: GROUP_BY.NONE,
  name: 'None'
};
export const useGroupByOptions = _ref => {
  let {
    hasTeamScope,
    usesObjectives
  } = _ref;
  return useMemo(() => {
    const options = [NONE, DIVIDER];
    if (usesObjectives) options.push(OBJECTIVE);
    options.push(STATE);
    if (!hasTeamScope) options.push(TEAM);
    return options;
  }, [hasTeamScope, usesObjectives]);
};