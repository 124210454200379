import { StoryPublicIdFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableText } from '@clubhouse/shared/components/Table';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoryPublicIdFieldFragment = StoryPublicIdFieldFragmentFragmentDoc;
const PublicIdFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  return _jsx(TableText, {
    text: entity.publicId
  });
};
PublicIdFieldComponent.displayName = "PublicIdFieldComponent";
export const PublicIdField = () => ({
  name: 'publicId',
  displayName: 'ID',
  headerProps: {
    centered: true
  },
  Component: PublicIdFieldComponent,
  width: 70,
  maxWidth: 90,
  sort: 'id'
});