import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useState } from 'react';
export const useOptions = _ref => {
  let {
    initialOptions
  } = _ref;
  const [options, setOptions] = useState(initialOptions);
  return {
    options,
    setOptions
  };
};
const update = _ref2 => {
  let {
    options,
    updater
  } = _ref2;
  return options.reduce(function (acc) {
    let option = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return [...acc, {
      ...option,
      ...updater(option)
    }];
  }, []);
};
export const getId = option => option.id;
export const getIsSelected = option => option.isSelected;
export const getValue = option => option.value;
function createOption(_ref3) {
  let {
    item,
    getItemId,
    selectedItemIds
  } = _ref3;
  const id = getItemId(item);
  return {
    value: item,
    id,
    isSelected: selectedItemIds.has(id)
  };
}
const defaultGetItemId = item => item ? item.id ? item.id : item.value : undefined;
export function createOptions(_ref4) {
  let {
    items,
    selectedItemIds = [],
    getItemId = defaultGetItemId
  } = _ref4;
  const set = new Set(selectedItemIds);
  return items.map(item => createOption({
    item,
    getItemId,
    selectedItemIds: set
  }));
}
export function recreateOptions(_ref5) {
  let {
    items,
    previousOptions
  } = _ref5;
  return createOptions({
    items,
    selectedItemIds: previousOptions.filter(getIsSelected).map(getId)
  });
}
export const toggleOptions = _ref6 => {
  let {
    options,
    selectedOptionIds
  } = _ref6;
  selectedOptionIds = new Set(selectedOptionIds);
  return update({
    options,
    updater: option => {
      const isSelected = getIsSelected(option);
      return {
        isSelected: selectedOptionIds.has(getId(option)) ? !isSelected : isSelected
      };
    }
  });
};
export const setIsSelectedForIds = _ref7 => {
  let {
    options,
    selectedOptionIds
  } = _ref7;
  selectedOptionIds = new Set(selectedOptionIds);
  return update({
    options,
    updater: option => {
      return {
        isSelected: selectedOptionIds.has(getId(option))
      };
    }
  });
};