import { default as lodashGroupBy } from 'lodash/groupBy';
import { getGroupSort } from './getGroupSort';
export function createGroups(_ref) {
  let {
    entities,
    groupBy
  } = _ref;
  const entitiesByKey = lodashGroupBy(entities, groupBy.getKey);
  const groupsByKey = Object.entries(entitiesByKey).reduce((groupsByKey, _ref2) => {
    let [groupKey, items] = _ref2;
    const group = {
      items,
      Component: groupBy.getDisplay(items[0], items, entities)
    };
    groupsByKey.set(groupKey, group);
    return groupsByKey;
  }, new Map());
  const groupSort = getGroupSort(groupBy);
  return [...groupsByKey.entries()].sort((_ref3, _ref4) => {
    let [, group1] = _ref3;
    let [, group2] = _ref4;
    const firstItem1 = group1.items[0];
    const firstItem2 = group2.items[0];
    return groupSort(firstItem1, firstItem2);
  });
}