import { useCallback } from 'react';
import { useOnUnmount } from '../hooks';
const KEYSTROKE_DELAY = 250;

/**
 * This file acts as a bridge between React and Caveman for the purposes of
 * communicating whether a React select is open to Caveman
 *
 * This is in order to fix [sc-158265],
 * which is an issue because Jquery events get triggered before React events
 * so we can't stop propagation from React into Caveman.
 *
 */
let _isSelectOpen = false;
let timeoutId;

// This object is used for non-react stuff
const SelectState = {
  get isSelectOpen() {
    return _isSelectOpen;
  },
  setIsSelectOpen: value => {
    _isSelectOpen = value;
  }
};

// This hook is used for react stuff
const useIsSelectOpen = () => {
  const setIsSelectOpen = useCallback(value => {
    /**
     * We need a timeout as this function will get called
     * before the dialog attempts to close
     * the duration should suffice for a keystroke.
     */
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => SelectState.setIsSelectOpen(value), KEYSTROKE_DELAY);
  }, []);

  // When a component using this hook unmounts we want to reset state
  useOnUnmount(() => setIsSelectOpen(false));
  return [SelectState.isSelectOpen, setIsSelectOpen];
};
export { useIsSelectOpen, SelectState };