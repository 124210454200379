// eslint-disable-next-line no-restricted-imports -- Required as we are wrapping the useMutation hook here
import { useMutation as _useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useQueryFunctions } from './UseQueryContext';
export const useMutation = function (mutation) {
  let {
    cancelQueries: _cancelQueries,
    ...options
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [execute, ...rest] = _useMutation(mutation, options);
  const {
    cancel,
    restart
  } = useQueryFunctions();
  const mutationWithCancelAndRestart = useCallback(_ref => {
    let {
      cancelQueries,
      ...options
    } = _ref;
    const finalCancelQueries = cancelQueries || _cancelQueries;
    if (finalCancelQueries) {
      cancel(finalCancelQueries);
      return execute(options).finally(() => restart(finalCancelQueries));
    }
    return execute(options);
  }, [_cancelQueries, cancel, execute, restart]);
  return [mutationWithCancelAndRestart, ...rest];
};