import classNames from 'classnames';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export default function SVG(_ref) {
  let {
    rawSVG,
    url,
    className = undefined,
    ...props
  } = _ref;
  const computedClassName = classNames(className, 'svg-container');
  const rawSVGIsURL = rawSVG != null && ['/static/', 'https:', 'http:', 'data:'].some(protocol => rawSVG.startsWith(protocol));
  if (rawSVGIsURL) {
    console.error('Must use `url` prop for remote SVG instead of `rawSVG`. We gracefully fallback, but please refactor this usage.');
  }
  if (url || rawSVGIsURL) {
    return _jsx("img", {
      className: computedClassName,
      src: url ?? rawSVG,
      alt: "",
      ...props
    });
  }
  return _jsx("div", {
    dangerouslySetInnerHTML: {
      __html: rawSVG || ''
    },
    className: computedClassName,
    ...props
  });
}
SVG.displayName = "SVG";