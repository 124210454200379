import Format from './format';
import LocalStorage from './localStorage';
const exports = {};
const API = window.Notification;

// Do we want to ask upfront at some stage?
// exports.init = function () {
//   if (!exports.isEnabled()) {
//     API.requestPermission(function (status) {
//       if (API.permission !== status) {
//         API.permission = status;
//       }
//     });
//   }
// };

const canUseNotifications = (() => {
  let cachedValue = null;
  const checkSupport = () => {
    if (!API || !API.requestPermission) return false;

    // If we already have permission, we don't have to check anything else
    if (API.permission === 'granted') return true;

    /*
     * `window.Notification` exists on Android, but will always throw an error when calling constructor.
     *  More information: https://bugs.chromium.org/p/chromium/issues/detail?id=481856
     */
    try {
      new API('');
    } catch (e) {
      if (e.name === 'TypeError') return false;
    }
    return true;
  };
  return () => {
    if (typeof cachedValue !== 'boolean') {
      cachedValue = checkSupport();
    }
    return cachedValue;
  };
})();
exports.isEnabled = () => {
  return canUseNotifications() && API.permission === 'granted';
};
exports.userUndecided = () => {
  return canUseNotifications() && API.permission !== 'denied';
};
exports.isDenied = () => {
  return !canUseNotifications() || API.permission === 'denied';
};
exports.notDisabled = () => {
  return exports.isEnabled() || exports.userUndecided();
};
const defaultOnClick = () => {
  window.focus();
};
exports.message = options => {
  const title = options.title;
  const body = Format.convertCustomMarkdownMentionsToNaked(options.body);
  const icon = options.user && options.user.display_icon ? options.user.display_icon.url : null;
  const apiOptions = {
    body,
    // 'tag' is default browser support to avoid dupes, not widely supported yet.
    tag: 'CLUBHOUSE: ' + body.substring(0, 50),
    icon
  };
  function open(title, apiOptions, fallback) {
    try {
      const notification = new API(title, apiOptions);
      notification.onclick = options.onClick || defaultOnClick;
    } catch {
      if (typeof fallback === 'function') fallback();
    }
  }
  if (!exports.hasMessage(apiOptions.tag)) {
    exports.storeMessage(apiOptions.tag);
    if (exports.isEnabled()) open(title, apiOptions, options.fallback);else if (exports.userUndecided()) {
      API.requestPermission(status => {
        if (!('permission' in API)) API.permission = status;
        if (exports.isDenied()) _.isFunction(options.fallback) && options.fallback();else open(title, apiOptions, options.fallback);
      });
    }
  }
};
exports.requestPermission = () => {
  API.requestPermission(status => {
    if (API.permission !== status) {
      API.permission = status;
    }
  });
};
exports.storeMessage = tag => {
  LocalStorage.set(tag, true, {
    prefix: true
  });
  setTimeout(() => {
    exports.removeMessage(tag);
  }, 1000 * 60);
};
exports.hasMessage = tag => {
  return !!LocalStorage.get(tag, {
    prefix: true
  });
};
exports.removeMessage = tag => {
  LocalStorage.remove(tag, {
    prefix: true
  });
};
export { exports as default };