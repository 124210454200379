import "core-js/modules/es.array.push.js";
import ActivityModel from '../models/activity';
import Async from './async';
import BaseUtils from '../_frontloader/baseUtils';
import CategoryModel from '../models/category';
import EpicModel from '../models/epic';
import EstimateScaleModel from '../models/estimateScale';
import * as Event from '../_frontloader/event';
import InstallationModel from '../models/installation';
import IntegrationModel from '../models/integration';
import InviteModel from '../models/invite';
import IterationModel from '../models/iteration';
import GroupModel from '../models/group';
import LabelModel from '../models/label';
import MilestoneModel from '../models/milestone';
import OrganizationModel from '../models/organization';
import PaymentPlan2Model from '../models/paymentPlan2';
import PermissionModel from '../models/permission';
import ProductModel from '../models/product';
import ProjectModel from '../models/project';
import RepositoryModel from '../models/repository';
import StoryTemplateModel from '../models/storyTemplate';
import SyncModel from '../models/sync';
import TeamModel from '../models/team';
import UserModel from '../models/user';
import WebhookModel from '../models/webhook';
import SpaceModel from '../models/space';
import CustomFieldModel from '../models/customField';
import CompaniesSummaryModel from '../models/companiesSummary';
import Tests from './tests';
import * as PerformanceModule from './performance';
import Globals from '../_frontloader/globals';
const exports = {};
export const USER_ORG_AND_APPLICATION_STATE_FETCHED_KEY = 'userOrgAndApplicationStateFetched';
export const CONSOLIDATED_DATA_FETCHED_KEY = 'consolidatedDataFetched';
exports.fetchUserOrgAndApplicationState = callback => {
  PerformanceModule.start(PerformanceModule.MEASURES.consolidatedFetch_fetchOrgAndApplicationState);
  callback = _.isFunction(callback) ? callback : _.noop;
  UserModel.fetchCurrentUser(() => {
    Async.eachInParallelThen([
    // We have a slim map of the current org now,
    // but we need to fetch the full map to see additional
    // properties like loading icons:
    OrganizationModel.fetchCurrent, PermissionModel.fetchCurrentApplicationState, CompaniesSummaryModel.fetch, PaymentPlan2Model.fetch, ProductModel.fetchAll], function () {
      PerformanceModule.end(PerformanceModule.MEASURES.consolidatedFetch_fetchOrgAndApplicationState);
      Globals.set(USER_ORG_AND_APPLICATION_STATE_FETCHED_KEY, true);
      callback(...arguments);
    });
  });
};
let fetchPageSpecificData;
const fallbackPageSpecificData = fn => {
  fn();
};
exports.resetFetchPageSpecificData = () => {
  fetchPageSpecificData = fallbackPageSpecificData;
};
exports.setFetchPageSpecificData = fnWithCallback => {
  fetchPageSpecificData = fnWithCallback;
};
exports.fetchConsolidatedDataAndActivity = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  PerformanceModule.start(PerformanceModule.MEASURES.consolidatedFetch_fetchConsolidatedDataAndActivity);
  _fetchConsolidatedData(() => {
    PerformanceModule.end(PerformanceModule.MEASURES.consolidatedFetch_fetchConsolidatedDataAndActivity);
    Globals.set(CONSOLIDATED_DATA_FETCHED_KEY, true);
    Event.trigger('orgDataFetched');
    callback();
    if (ActivityModel.size() === 0) {
      setTimeout(ActivityModel.fetchLastDay, 3000);
    }
  });
};
export const fetchConsolidatedDataAndActivityPromise = BaseUtils.promisify(exports.fetchConsolidatedDataAndActivity);
function _fetchConsolidatedData(callback) {
  const firstPass = [UserModel.fetchAll, EpicModel.fetchAll, ProjectModel.fetchAll, TeamModel.fetchAll];
  if (Tests.usesIterations()) {
    firstPass.push(IterationModel.fetchAll);
  }
  const secondPass = [GroupModel.fetchAll, CategoryModel.fetchAll, EstimateScaleModel.fetchCurrent, InstallationModel.fetchAll, IntegrationModel.fetchAll, InviteModel.fetchAll, LabelModel.fetchAllSlim, MilestoneModel.fetchAll, RepositoryModel.fetchAll, WebhookModel.fetchAll, SpaceModel.fetchAll, CustomFieldModel.fetchAll, fetchPageSpecificData ?? fallbackPageSpecificData];
  if (OrganizationModel.templatesAreEnabled()) {
    secondPass.push(StoryTemplateModel.fetchAll);
  }
  secondPass.push(SyncModel.fetchAll);
  Async.eachInParallelThen(firstPass, () => {
    Async.eachInParallelThen(secondPass, callback);
  });
}
export { exports as default };