import { useTheme } from '@emotion/react';
import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Trash" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Trash = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill
  } = _ref;
  const theme = useTheme();
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill || theme.iconRedColor,
    children: _jsx(Icon, {
      icon: "Trash"
    })
  });
};
Trash.displayName = "Trash";