import Tests from 'app/client/core/js/modules/tests';
export const FEATURES = Tests.FEATURES;
export const usesWrite = () => Tests.usesWrite();
export const usesTrueCustomFields = () => Tests.usesTrueCustomFields();
export const usesIterations = () => Boolean(Tests.usesIterations());
export const usesOverlappingPrs = () => Tests.usesOverlappingPrs();
export const isTesting = () => Tests.isTesting();
export const usesFeature = feature => Tests.usesFeature(feature);
export function findParentWith(element, fn) {
  let parent = element.parentElement;
  while (parent) {
    if (fn(parent)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return parent;
}
export function findParentWithAttribute(element, attribute) {
  return findParentWith(element, el => el.hasAttribute(attribute));
}