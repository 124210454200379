import View from 'app/client/core/js/modules/view';
import { Testlodge } from 'pages/integrations/Testlodge';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
const exports = {};
exports.title = `Testlodge Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/testlodge';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Testlodge, 'testlodge-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };