import "core-js/modules/es.array.push.js";
import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/appauth/less/app.less';
import ApplicationModel from '../../../core/js/models/application';
import Async from '../../../core/js/modules/async';
import Boot from '../../../core/js/modules/boot';
import * as Event from '../../../core/js/_frontloader/event';
import InstallationModel from '../../../core/js/models/installation';
import AppAuthPageController from '../controllers/appauthPage';
import OrganizationModel from '../../../core/js/models/organization';
import UserModel from '../../../core/js/models/user';
import View from '../../../core/js/modules/view';
import Layout from '../../../core/js/modules/layout';
const exports = {};
const _init = () => {
  Layout.renderContent('<div id="auth"></div>');
  const dependencies = [ApplicationModel.fetchAll, UserModel.fetchAll];
  if (OrganizationModel.getAllActive().length === 1) {
    // If there's only 1 org, we don't need to ask which org before fetching.
    dependencies.push(InstallationModel.fetchAll);
  }
  Async.eachInParallelThen(dependencies, () => {
    AppAuthPageController.render();
  });
  Event.onlyOn('resize.AppAuth', View.genericResizeHandler);
};
const _reInit = _init;
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
export { exports as default };