import Constants from './constants';
import { sanitize } from '@clubhouse/shared/utils/format';
const exports = {};
const STILL_IMAGE_FILE_EXTENSIONS = ['png', 'jpg', 'jpeg'];
// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Video_codecs
const VIDEO_FILE_EXTENSIONS = ['3gp', 'avi', 'm4p', 'm4v', 'mov', 'mp4', 'mpeg', 'mpg', 'ogg', 'ogv', 'wav', 'webm'];
const IMAGE_FILE_EXTENSIONS = _.union(['gif'], STILL_IMAGE_FILE_EXTENSIONS);
const _MIME_TYPE_CONFIGURATION = {
  _defaults: {
    icon: 'fa-file-o',
    displayable: false,
    editable: false
  },
  image: {
    _defaults: {
      icon: 'fa-file-image-o',
      displayable: true,
      editable: true,
      type: 'image'
    },
    extensions: {
      gif: {
        editable: false
      }
    }
  },
  application: {
    _defaults: {
      type: 'application'
    },
    extensions: {
      pdf: {
        icon: 'fa-file-pdf-o'
      },
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        icon: 'fa-file-excel-o'
      },
      zip: {
        icon: 'fa-file-archive-o'
      },
      'vnd.openxmlformats-officedocument.wordprocessingml.document': {
        icon: 'fa-file-word-o'
      },
      'vnd.ms-excel': {
        icon: 'fa-file-excel-o'
      },
      json: {
        icon: 'fa-file-code-o'
      },
      'vnd.openxmlformats-officedocument.presentationml.presentation': {
        icon: 'fa-file-powerpoint-o'
      },
      'x-zip-compressed': {
        icon: 'fa-file-archive-o'
      },
      postscript: {
        icon: 'fa-file-pdf-o'
      },
      msword: {
        icon: 'fa-file-word-o'
      },
      'x-gzip': {
        icon: 'fa-file-archive-o'
      },
      gzip: {
        icon: 'fa-file-archive-o'
      },
      xml: {
        icon: 'fa-file-code-o'
      },
      'x-rar-compressed': {
        icon: 'fa-file-archive-o'
      },
      'x-photoshop': {
        icon: 'fa-file-image-o'
      },
      'vnd.ms-powerpoint': {
        icon: 'fa-file-powerpoint-o'
      }
    }
  },
  text: {
    _defaults: {
      icon: 'fa-file-text-o',
      type: 'text'
    },
    extensions: {
      csv: {
        icon: 'fa-file-excel-o'
      },
      html: {
        icon: 'fa-file-code-o'
      },
      css: {
        icon: 'fa-file-code-o'
      },
      js: {
        icon: 'fa-file-code-o'
      },
      xml: {
        icon: 'fa-file-code-o'
      },
      javascript: {
        icon: 'fa-file-code-o'
      },
      'x-python-script': {
        icon: 'fa-file-code-o'
      },
      'tab-separated-value': {
        icon: 'fa-file-excel-o'
      },
      'x-ruby-script': {
        icon: 'fa-file-code-o'
      },
      'x-python': {
        icon: 'fa-file-code-o'
      },
      'x-php': {
        icon: 'fa-file-code-o'
      },
      php: {
        icon: 'fa-file-code-o'
      },
      'x-sh': {
        icon: 'fa-file-code-o'
      }
    }
  },
  video: {
    _defaults: {
      icon: 'fa-file-video-o',
      displayable: true,
      type: 'video'
    }
  },
  audio: {
    _defaults: {
      icon: 'fa-file-audio-o',
      displayable: true,
      type: 'audio'
    }
  }
};
exports.getExtension = filename => {
  return filename.split('.').pop().toLowerCase();
};
exports.mimeTypeConfig = mimeType => {
  const options = Object.assign({}, _MIME_TYPE_CONFIGURATION._defaults);
  if (mimeType) {
    const [type, subtype] = mimeType.split('/');
    if (_MIME_TYPE_CONFIGURATION[type]) {
      Object.assign(options, _MIME_TYPE_CONFIGURATION[type]._defaults);
      if (_MIME_TYPE_CONFIGURATION[type].extensions && _MIME_TYPE_CONFIGURATION[type].extensions[subtype]) {
        Object.assign(options, _MIME_TYPE_CONFIGURATION[type].extensions[subtype]);
      }
    }
  }
  return options;
};
exports.filenameToIcon = filename => {
  const extension = exports.getExtension(filename);
  return Constants.ICONS[extension] || Constants.ICONS.blank;
};
exports.getExtensionFromMimeType = mimetype => {
  return _.includes(mimetype, 'image/') ? '.' + mimetype.replace('image/', '') : '';
};
exports.mapFilesToMarkdown = files => {
  return _.map(files, file => {
    const imagePrefix = exports.isImage(file.url) ? '!' : '';
    return imagePrefix + '[' + file.name + '](' + file.url + ')';
  }).join('\n\n');
};
exports.isImage = filename => {
  const extension = exports.getExtension(filename);
  return _.includes(IMAGE_FILE_EXTENSIONS, extension);
};
exports.isStillImage = filename => {
  const extension = exports.getExtension(filename);
  return _.includes(STILL_IMAGE_FILE_EXTENSIONS, extension);
};
exports.isFile = filename => {
  const types = ['gif', 'png', 'jpg', 'jpeg', 'zip', 'pdf', 'json', 'doc', 'docx', 'xsl', 'xslx'];
  const extension = exports.getExtension(filename);
  return _.includes(types, extension);
};
exports.isVideo = filename => {
  const extension = exports.getExtension(filename);
  return _.includes(VIDEO_FILE_EXTENSIONS, extension);
};
exports.getLightboxTitle = (url, name, fileSize) => {
  return `<span><a href='${url}'><span class='fa fa-cloud-download'></span>
   ${sanitize(name)}</a> (${fileSize})</span>`;
};
export { exports as default };