import { useState } from 'react';
import { useMountedState } from 'react-use';
import { Button } from '@clubhouse/shared/components/Button/';
import { freePlanIneligibleForTrial, pendingTierDowngradeProduct } from 'data/entity/paymentPlan2';
import { isLoggedInUserOwner } from 'data/entity/user';
import { getPlanState } from 'utils/manageBilling/planStateMachine';
import { TIERS, getTier } from 'utils/tiers';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const NeutralButton = props => _jsx("div", {
  className: "plan-selection-button-container",
  children: _jsx(Button, {
    kind: Button.KIND.SECONDARY,
    fullWidth: true,
    ...props
  })
});
NeutralButton.displayName = "NeutralButton";
const SaveButton = props => _jsx("div", {
  className: "plan-selection-button-container",
  children: _jsx(Button, {
    kind: Button.KIND.PRIMARY,
    fullWidth: true,
    ...props
  })
});
SaveButton.displayName = "SaveButton";
export const PlanSelectButton = _ref => {
  let {
    product,
    plan,
    currentProduct,
    onProductSelect,
    allowNonOwnerInteraction
  } = _ref;
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    const def = onProductSelect(product.display_key);
    if (def) {
      isMounted() && setIsLoading(true);
      // Don't try update state if component has been unmounted
      def.then(() => isMounted() && setIsLoading(false));
    }
  };
  const isTrialing = 'trialing' in plan.statuses;
  const pendingTier = pendingTierDowngradeProduct(plan)?.display_key;
  const eligibleForFree = !isTrialing || !freePlanIneligibleForTrial(plan);
  const planStatus = getPlanState(plan, currentProduct);
  const isOwner = isLoggedInUserOwner();
  const tier = product.display_key;
  const isEnterprise = TIERS.ENTERPRISE === tier;
  const isFree = TIERS.FREE_LIMITED === tier;
  const tierIsPendingProduct = pendingTier === tier;
  const tierIsCurrentProduct = currentProduct?.display_key === tier;
  const isDisabled = isFree && !eligibleForFree || !allowNonOwnerInteraction && !isOwner;
  const {
    title
  } = getTier(product);
  const ButtonComponent = isFree ? NeutralButton : SaveButton;
  if (planStatus === 'PLAN_IS_CHANGING_SELECTION' && tierIsPendingProduct) {
    return _jsx(NeutralButton, {
      isDisabled: true,
      children: "Pending"
    });
  }
  if (planStatus === 'PLAN_IS_CHANGING_SELECTION' && tierIsCurrentProduct) {
    return _jsxs(ButtonComponent, {
      isDisabled: isDisabled,
      onClick: handleClick,
      showProgress: isLoading,
      children: ["Stay on ", title]
    });
  }
  if ((planStatus === 'PLAN_HAS_SELECTION' || planStatus === 'PLAN_TRIALING_AND_HAS_SELECTION') && tierIsCurrentProduct) {
    return _jsx(ButtonComponent, {
      isDisabled: true,
      children: "Current Plan"
    });
  }
  if (isEnterprise) {
    return _jsx(SaveButton, {
      isDisabled: isDisabled,
      onClick: handleClick,
      showProgress: isLoading,
      children: "Contact Us"
    });
  }
  if (isDisabled) {
    return _jsxs(ButtonComponent, {
      isDisabled: true,
      children: [title, " Plan"]
    });
  }
  return _jsx(ButtonComponent, {
    isDisabled: isDisabled,
    onClick: handleClick,
    showProgress: isLoading,
    children: "Select"
  });
};
PlanSelectButton.displayName = "PlanSelectButton";