import keyBy from 'lodash/keyBy';
import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { CustomFieldChip } from './CustomFieldChip';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const CustomFieldChips = _ref => {
  let {
    customFields = []
  } = _ref;
  const story = useContext(StoryContext);
  if (!story || !story.custom_fields?.length) return null;
  const fieldsById = keyBy(customFields, 'id');
  const valuesById = keyBy(customFields.flatMap(field => field.values), 'id');
  return _jsx(_Fragment, {
    children: [...story.custom_fields].sort(
    // Sort the story's custom_fields attribute to match the customFields prop order
    (a, b) => customFields.findIndex(field => field.id === a.field_id) - customFields.findIndex(field => field.id === b.field_id)).map(storyField => {
      const field = fieldsById[storyField.field_id];
      const value = valuesById[storyField.value_id];
      if (!field || !value) return undefined;
      return _jsx(CustomFieldChip, {
        colorKey: value.color_key,
        fieldCanonicalName: field.canonical_name,
        fieldIconSetIdentifier: field.icon_set_identifier,
        fieldPublicName: field.name,
        fieldValues: field.values,
        valueId: value.id,
        valueStringValue: value.value
      }, field.id);
    })
  });
};