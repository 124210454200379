import Logo from '@clubhouse/assets/png/third-party-logos/plecto_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Plecto() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.PLECTO
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Plecto"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Plecto Logo",
        invertOnDarkMode: true
      }), _jsx(Layout.Blurb, {
        children: "Build customized Shortcut dashboards that visualize your business metrics and KPIs in real-time."
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.plecto.com/integrations/shortcut/",
          children: "Learn more"
        }), " on how Plecto integrates with Shortcut."]
      })]
    })]
  });
}
Plecto.displayName = "Plecto";