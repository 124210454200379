import { ButtonText } from '@clubhouse/shared/components/Button';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ObjectiveTypeIcon } from './ObjectiveTypeIcon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ObjectiveWithIcon(_ref) {
  let {
    name,
    type
  } = _ref;
  return _jsxs(Spaced, {
    horizontally: true,
    amount: "space1",
    align: "center",
    children: [_jsx(ObjectiveTypeIcon, {
      type: type
    }), _jsx(ButtonText, {
      children: _jsx(Emojify, {
        children: name
      })
    })]
  });
}
ObjectiveWithIcon.displayName = "ObjectiveWithIcon";