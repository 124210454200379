import "core-js/modules/es.array.push.js";
import AutocompleteController from 'app/client/core/js/controllers/autocomplete.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Autocomplete'], AutocompleteController], [['Controller', 'Autocomplete'], AutocompleteController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Format from '../modules/format';
import Utils from '../modules/utils';
const exports = {};

/*

Example Category:

{
  archived: false
  color: null
  created_at: "2017-06-26T12:24:50Z"
  entity_type: "milestone-category"
  id: 21008
  name: "sprint1"
  type: "milestone"
  updated_at: "2016-10-29T13:01:29Z"
}

*/

Collection.create('Category', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.name;
};
exports.normalize = category => {
  category.lowercase_name = category.name.toLowerCase();
  category.formatted_name = exports.formatName(category.name);
};
exports.denormalize = category => {
  return {
    name: category.name
  };
};
exports.denormalizeMultiple = categories => {
  return _.map(categories, exports.denormalize);
};
exports.formatName = name => {
  return Format.sanitizeAndEmojify(name);
};
exports.sortByLowercaseName = categories => {
  return _.sortBy(categories, category => {
    return category.name.toLowerCase();
  });
};
exports.allSortedByName = () => {
  return exports.sortBy('lowercase_name');
};
exports.fetchAll = callback => {
  Backend.get('/api/private/categories', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.create = (data, callback) => {
  Backend.post('/api/private/categories', {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'categoryCreated');
    }
  });
};
exports.updateCategory = (category, updates, callback) => {
  Backend.put('/api/private/categories/' + category.id, {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'categoryUpdated');
    }
  });
};
exports.deleteCategory = (category, callback) => {
  Backend.delete('/api/private/categories/' + category.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(category, res, xhr, callback);
    }
  });
};
exports.addExistingCategory = (existingCategories, category) => {
  const categories = existingCategories.slice(0);
  if (_.some(categories, {
    name: category.name
  })) {
    _.remove(categories, {
      name: category.name
    });
  } else {
    categories.push(category);
  }
  return categories;
};
exports.addCategoryAsString = (existingCategories, categoryString) => {
  const foundCategory = exports.get({
    lowercase_name: categoryString.toLowerCase()
  });
  if (foundCategory) {
    existingCategories = exports.addExistingCategory(existingCategories, foundCategory);
  } else {
    Utils.tokenizeQuery(categoryString).forEach(category => {
      existingCategories = exports.addExistingCategory(existingCategories, {
        name: category
      });
    });
  }
  return existingCategories;
};
exports.createNewCategoryText = value => {
  const categories = Utils.tokenizeQuery(value);
  if (categories.length === 1) {
    return 'Enter to create new category:<div class="tags"><span class="tag" data-controller="Autocomplete" ' + 'data-on-click="applyValueFromInput">' + exports.formatName(categories[0]) + '</span></div>';
  } else if (categories.length > 1) {
    return 'Enter to create ' + categories.length + ' new categories (use quotes to combine):<div class="tags" ' + 'data-controller="Autocomplete" data-on-click="applyValueFromInput"><span class="tag">' + _.map(categories, exports.formatName).join('</span> <span class="tag">') + '</span></div>';
  }
  return '';
};
exports.getItemsForAutocomplete = entity => {
  const items = [];
  if (_.get(entity, 'categories', []).length > 0) {
    _.sortBy(entity.categories, 'lowercase_name').forEach(category => {
      const categoryFull = exports.getById(category.id) || category;
      items.push({
        name: categoryFull.formatted_name || exports.formatName(categoryFull.name),
        value: category,
        className: category.className || 'selected',
        iconRight: category.iconRight || 'fa-check'
      });
    });
    items.push({
      hr: true
    });
  }
  exports.allSortedByName().forEach(category => {
    if (!exports.hasCategory(entity, category)) {
      items.push({
        name: category.formatted_name,
        value: category,
        iconRight: 'fa-check'
      });
    }
  });
  return items;
};
exports.hasCategory = (entity, category) => {
  const categories = entity.categories || [];
  const name = _.isString(category) ? category : _.get(category, 'name');
  if (categories.length === 0 || !name) {
    return false;
  }
  return _.some(categories, {
    name
  });
};
exports.getReadableCategoryNames = entity => {
  const categories = _.get(entity, 'categories', []);
  return Utils.grammaticalJoin(_.map(categories, category => {
    return exports.formatName(category.name);
  }));
};
export { exports as default };