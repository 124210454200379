import { Combobox } from '@clubhouse/shared/components/Combobox';
import { useIsOpen, useTriggerRef } from '../ComboboxSelectContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Menu = Object.assign(props => {
  const triggerRef = useTriggerRef();
  return useIsOpen() ? _jsx(Combobox.Menu, {
    triggerRef: triggerRef,
    ...props
  }) : null;
}, {
  Z_INDEX: Combobox.Menu.Z_INDEX
});