import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController], [['Controller', 'Story'], StoryController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import classnames from 'classnames';
import moment from 'moment';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { DATE_FORMAT } from '@clubhouse/shared/constants/dates';
import { copyToClipboard } from '@clubhouse/shared/utils';
import { EstimateSelect, StoryDialogTarget } from 'components/shared/EstimateSelect/EstimateSelect';
import { GroupMismatchIndicatorForOwners } from 'components/shared/GroupMismatchIndicator';
import { StoryTypeIcon } from 'components/shared/StoryTypeIcon';
import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';
import { TYPE as SELECT_USERS_DROPDOWN_TYPE, SelectUsersDropdown } from 'components/shared/dropdowns/select-users';
import { useOptimizedEpic } from 'data/entity/epic';
import * as EstimateScale from 'data/entity/estimateScale';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { useOptimizedIteration } from 'data/entity/iteration';
import { getAllActiveProfileDetailsAndSpeciallyInclude, getCurrentUserProfileDetails } from 'data/entity/profile';
import { currentUserIsFollower, describeFollowersOrNull, getDeadlineClass, saveChanges, toBranchName, toggleOwner, useOptimizedStory } from 'data/entity/story';
import { isReadOnly, isSampleObserver } from 'data/entity/user';
import { getById as getWorkflowById } from 'data/entity/workflow';
import { getById as getWorkflowStateById } from 'data/entity/workflowState';
import * as Integrations from 'utils/integrations';
import { PAGE_NAMES, getSlugPath, useCurrentPage } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { isValidUrl } from 'utils/validator';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { StoryDialogActionsMenu } from 'components/gql/stories/StoryDialogActionsMenu';
import { ArchiveStoryAction } from 'components/gql/stories/actions/ArchiveStoryAction';
import { DeleteStoryAction } from 'components/gql/stories/actions/DeleteStoryAction';
import { MoveStoryActions } from 'components/gql/stories/actions/MoveStoryActions';
import { convertStoryToEpic, createTemplateFromStory, openCreateBranchDialog } from 'utils/story';
import { CustomFieldAttributes } from './CustomFieldAttributes';
import { HotReload } from './HotReload';
import { StoryEpic } from './StoryEpic';
import { StoryId } from './StoryId';
import { StoryIteration } from './StoryIteration';
import { StoryJiraSync } from './StoryJiraSync';
import { StoryLabels } from './StoryLabels';
import { StoryPermalink } from './StoryPermalink';
import { TeamAndWorkflowSelector } from './TeamAndWorkflowSelector';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const useCopyBranchNameKeyboardShortcut = story => {
  useCommandBarCallback('copyStoryBranchName', () => {
    if (!story) return;
    const branchName = toBranchName(story);
    if (branchName) {
      copyToClipboard(branchName);
      addToast({
        kind: 'success',
        Content: () => _jsx(ToastText, {
          children: "Branch name copied to clipboard!"
        }),
        timeout: 2500
      });
    }
  });
};
const useCopyBranchNameAndMoveToStartedKeyboardShortcut = story => {
  useCommandBarCallback('copyStoryBranchNameAndMoveToStarted', async () => {
    if (!story) return;
    let branchCopied = false;
    let workflowStateChanged = false;
    const workflow = getWorkflowById(story.workflow_id);
    const workflowState = getWorkflowStateById(story.workflow_state_id);
    if (workflow && (!workflowState || workflowState.type !== 'started')) {
      const newWorkflowState = workflow.states.find(s => s.type === 'started');
      try {
        if (newWorkflowState) {
          saveChanges(story.id, {
            workflow_state_id: newWorkflowState.id
          });
          workflowStateChanged = true;
        }
      } catch {}
    }
    const branchName = toBranchName(story);
    if (branchName) {
      copyToClipboard(branchName);
      branchCopied = true;
    }
    if (!branchCopied && !workflowStateChanged) return;
    addToast({
      kind: 'success',
      Content: () => branchCopied && workflowStateChanged ? _jsx(ToastText, {
        children: "Branch name copied to clipboard and story moved to Started."
      }) : workflowStateChanged ? _jsx(ToastText, {
        children: "Story moved to Started."
      }) : _jsx(ToastText, {
        children: "Branch name copied to clipboard!"
      }),
      timeout: 2500
    });
  }, {
    skip: isReadOnly()
  });
};
const useToggleCurrentUserAsOwner = story => {
  useCommandBarCallback('toggleCurrentUserAsOwner', () => {
    if (!story) return;
    if (isReadOnly()) return;
    const currentUser = getCurrentUserProfileDetails();
    if (!currentUser) return;
    toggleOwner(story, currentUser);
  }, {
    skip: isReadOnly()
  });
};
export const StoryAttributes = _ref2 => {
  let {
    storyId,
    onInviteRequester,
    onRequesterChange,
    onInviteOwner,
    onOwnerChange
  } = _ref2;
  // Note: Using `?? 0` below to avoid TS error without having to update all the useOptimized* hooks.
  // The result is the same since neither would return an entity.
  const story = useOptimizedStory(storyId);
  const iteration = useOptimizedIteration(story?.iteration_id ?? 0);
  const epic = useOptimizedEpic(story?.epic_id ?? 0);
  const currentPage = useCurrentPage();
  const isStoriesPage = currentPage === PAGE_NAMES.STORIES;
  useCopyBranchNameKeyboardShortcut(story);
  useCopyBranchNameAndMoveToStartedKeyboardShortcut(story);
  useToggleCurrentUserAsOwner(story);
  if (!story) return null;
  const isVCSEnabled = Integrations.isVCSEnabled();
  const requester = story.requested_by_id ? [story.requested_by_id] : [];
  const isDoneStory = story.stateObject.type === WORKFLOW_STATE_TYPES.DONE;
  return _jsxs("div", {
    className: classnames('story-attributes', {
      'github-enabled': isVCSEnabled
    }),
    children: [_jsx(StoryId, {
      story: story
    }), _jsx(StoryPermalink, {
      story: story
    }), _jsx(StoryJiraSync, {
      story: story
    }), _jsx("div", {
      className: "attribute-spacer"
    }), _jsx(TeamAndWorkflowSelector, {
      story: story
    }), isProjectsFeatureEnabled() && _jsxs("div", {
      id: "story-project-dropdown-container",
      className: "attribute editable-attribute story-project",
      "data-tabindex": true,
      "data-controller": "Story",
      "data-on-click": "openStoryProjectUpdater",
      "data-value": story.project?.name,
      children: [story.project?.color ? _jsx("span", {
        className: "color-box",
        style: {
          background: story.project.color
        }
      }) : _jsx("span", {
        className: "color-box color-box--no-project"
      }), _jsx("span", {
        "data-testid": "attribute-name",
        className: "name",
        children: "Project"
      }), _jsx("span", {
        "data-testid": "attribute-value",
        className: "value",
        children: story.project?.raw_name ? _jsx("em", {
          children: "story.project.raw_name"
        }) : story.project?.name ? story.project.name : _jsx("em", {
          children: "None"
        })
      })]
    }), _jsx("div", {
      className: "attribute-spacer"
    }), !!story.epic?.url && _jsx(HotReload, {
      page: "epics",
      url: story.epic.url,
      tooltip: "View Epic Page"
    }), _jsx(StoryEpic, {
      story: story,
      epic: epic
    }), usesIterations() && _jsxs(_Fragment, {
      children: [!!iteration && _jsx(HotReload, {
        page: "iterations",
        url: `${getSlugPath()}/iteration/${iteration.id}`,
        tooltip: "View Iteration Page"
      }), _jsx(StoryIteration, {
        story: story,
        iteration: iteration
      })]
    }), _jsxs("div", {
      className: "attribute editable-attribute story-type",
      id: "story-dialog-story-type-dropdown",
      "data-tabindex": true,
      "data-controller": "Story",
      "data-on-click": "openStoryTypeDropdown",
      "data-value": story.story_type,
      children: [_jsx("span", {
        className: "custom-icon",
        children: _jsx(StoryTypeIcon, {
          storyType: story.story_type,
          size: 20
        })
      }), _jsx("span", {
        "data-testid": "attribute-name",
        className: "name",
        children: "Type"
      }), _jsx("span", {
        className: "value",
        children: story.story_type
      })]
    }), _jsx("div", {
      className: "attribute-spacer"
    }), _jsxs("div", {
      id: "updateStoryRequesterOwnerFields",
      children: [_jsx("div", {
        className: "stacked-attribute",
        "data-component-property-key": "RequesterField",
        "data-component-key": "RequesterField",
        children: _jsx(SelectUsersDropdown, {
          type: SELECT_USERS_DROPDOWN_TYPE.REQUESTER,
          title: "Update Story Requester",
          initialSelectedUserIds: requester,
          users: getAllActiveProfileDetailsAndSpeciallyInclude(requester),
          currentUser: getCurrentUserProfileDetails(),
          onSelectAndSave: onRequesterChange,
          isRequired: true,
          groupIds: story.group_id ? [story.group_id] : [],
          onInvite: onInviteRequester
        })
      }), _jsx("div", {
        className: "stacked-attribute",
        "data-component-property-key": "OwnerField",
        "data-component-key": "OwnerField",
        children: _jsx(SelectUsersDropdown, {
          type: SELECT_USERS_DROPDOWN_TYPE.OWNER,
          title: "Update Story Owner",
          initialSelectedUserIds: story.owner_ids,
          users: getAllActiveProfileDetailsAndSpeciallyInclude(story.owner_ids),
          currentUser: getCurrentUserProfileDetails(),
          onSelectAndSave: onOwnerChange,
          isRequired: false,
          groupIds: story.group_id ? [story.group_id] : [],
          SelectedUserIndicator: _ref3 => {
            let {
              selectedUsers,
              index
            } = _ref3;
            if (index > 0) {
              return null;
            }
            return _jsx(GroupMismatchIndicatorForOwners, {
              story: story,
              selectedMembers: selectedUsers,
              memberIndex: index
            });
          },
          onInvite: onInviteOwner
        })
      })]
    }), _jsx("div", {
      className: "attribute-spacer"
    }), !EstimateScale.isDisabled() && _jsx(EstimateSelect, {
      value: story.estimate,
      onChange: newEstimate => {
        saveChanges(storyId, {
          estimate: newEstimate
        });
      },
      Target: StoryDialogTarget
    }), _jsxs("div", {
      className: "story-deadline-container",
      children: [story.formatted_deadline && _jsx("div", {
        className: "attribute-toggle attribute-action",
        children: _jsx("button", {
          "data-controller": "Story",
          "data-on-click": "clearDeadline",
          "data-tabindex": true,
          "data-tooltip": "Clear Due Date",
          children: _jsx("span", {
            className: "fa fa-times"
          })
        })
      }), _jsxs("div", {
        id: "story-dialog-deadline-dropdown",
        className: classnames('attribute', 'editable-attribute', 'attribute-has-toggle', 'story-deadline', 'condensed', getDeadlineClass(story)),
        "data-tabindex": true,
        "data-controller": "Story",
        "data-on-click": "openDeadlineDatepicker",
        "data-value": story.deadline,
        children: [_jsx("span", {
          "data-testid": "attribute-name",
          className: "name",
          children: "Due"
        }), ' ', _jsx("span", {
          className: "value",
          children: story.formatted_deadline || _jsx("em", {
            children: "No date"
          })
        })]
      })]
    }), _jsx("div", {
      className: "story-followers-container",
      children: _jsxs("div", {
        className: "attribute-toggle",
        children: [isSampleObserver() ? _jsx("div", {
          className: "placeholder",
          children: _jsx("span", {
            className: "fa fa-plus"
          })
        }) : currentUserIsFollower(story) ? _jsx("button", {
          "data-controller": "Story",
          "data-on-click": "removeMeAsFollower",
          "data-tooltip": "Remove Yourself as Follower",
          "data-tabindex": true,
          children: _jsx("span", {
            className: "fa fa-times"
          })
        }) : _jsx("button", {
          "data-controller": "Story",
          "data-on-click": "addMeAsFollower",
          "data-tooltip": "Follow this Story",
          "data-tabindex": true,
          children: _jsx("span", {
            className: "fa fa-plus"
          })
        }), _jsxs("div", {
          id: "story-dialog-follower-dropdown",
          className: "attribute editable-attribute attribute-has-toggle story-followers condensed",
          "data-tabindex": true,
          "data-controller": "Story",
          "data-on-click": "openStoryFollowerUpdater",
          children: [_jsx("span", {
            "data-testid": "attribute-name",
            className: "name",
            children: "Followers"
          }), ' ', _jsx("span", {
            className: "value",
            children: describeFollowersOrNull(story) || _jsx("em", {
              children: "Nobody"
            })
          })]
        })]
      })
    }), _jsx("div", {
      className: "attribute-spacer"
    }), _jsx(CustomFieldAttributes, {
      storyId: story.id
    }), _jsx(StoryLabels, {
      story: story
    }), _jsxs("div", {
      className: "story-actions",
      "data-controller": "Story",
      children: [isVCSEnabled && _jsx("button", {
        className: "story-actions-button",
        id: "open-git-helpers-dropdown",
        "data-tabindex": true,
        "data-on-click": "openGitHelpers",
        "data-tooltip": "Git Helpers",
        children: _jsx("span", {
          className: "fa fa-code-fork"
        })
      }), _jsx(Tooltip, {
        content: "Story Actions",
        children: _jsxs(StoryDialogActionsMenu
        // Needed by the Git Helpers popover until it's migrated to React
        , {
          className: "story-actions-button",
          children: [isVCSEnabled ? null : _jsxs(_Fragment, {
            children: [_jsx(MoreActions.Item, {
              icon: "Branch",
              onClick: () => {
                openCreateBranchDialog(story);
              },
              children: "Git Helpers"
            }), _jsx(MoreActions.Separator, {})]
          }), isDoneStory || !isStoriesPage ? null : _jsxs(_Fragment, {
            children: [_jsx(MoveStoryActions, {
              storyId: story.global_id
            }), _jsx(MoreActions.Separator, {})]
          }), _jsx(MoreActions.Item, {
            icon: "Copy",
            onClick: () => {
              const {
                stateObject,
                workflow_state_id: _,
                ...storyWithoutWorkflowState
              } = story;

              // We don't want to duplicate the workflow state as we always want to
              // create a story in the left-most state (workflow's default workflow state),
              // unless it's a backlog story.
              createTemplateFromStory(stateObject.type !== WORKFLOW_STATE_TYPES.BACKLOG ? storyWithoutWorkflowState : story);
            },
            children: "Duplicate Story..."
          }), _jsx(MoreActions.Item, {
            onClick: () => {
              convertStoryToEpic(story);
            },
            icon: "Epic",
            children: "Convert Story to Epic"
          }), _jsx(MoreActions.Separator, {}), _jsx(ArchiveStoryAction, {
            story: {
              id: story.global_id,
              archived: story.archived,
              name: story.name
            },
            aside: "shift A"
          }), story.archived ? _jsx(DeleteStoryAction, {
            storyId: story.id,
            name: story.name
          }) : null]
        })
      })]
    }), _jsxs("div", {
      className: "attribute story-date-created",
      children: [_jsx("span", {
        className: "name",
        children: "Created"
      }), _jsx("span", {
        className: "value",
        children: moment(story.created_at).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT)
      })]
    }), story.completed_at && _jsxs("div", {
      className: "attribute story-date-completed",
      "data-updated-at": story.completed_at,
      children: [_jsx("span", {
        className: "name",
        children: "Completed"
      }), _jsx("span", {
        className: "value",
        children: moment(story.completed_at).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT)
      })]
    }), _jsxs("div", {
      className: "attribute story-date-updated",
      "data-updated-at": story.updated_at,
      children: [_jsx("span", {
        className: "name",
        children: "Last updated"
      }), _jsx("span", {
        className: "value",
        children: moment(story.updated_at).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT)
      })]
    }), story.external_id && _jsxs("div", {
      className: "attribute external-id",
      children: [_jsxs("span", {
        className: "name",
        children: ["External ID", ' ', _jsx("span", {
          className: "fa fa-question-circle",
          "data-tooltip": `An External ID is set using the ${BRAND.NAME} API only, and is often used during an import to link the Story to an external resource in another tool.`
        })]
      }), _jsx("span", {
        className: "value",
        children: isValidUrl(story.external_id) ? _jsx("a", {
          href: story.external_id,
          target: "_blank",
          rel: "noopener noreferrer",
          children: story.external_id
        }) : story.external_id
      })]
    })]
  });
};
StoryAttributes.displayName = "StoryAttributes";