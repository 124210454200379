import SyncModel from 'app/client/core/js/models/sync';
import { useAsync } from 'react-use';
import { useEntities } from './collection';
export const useSyncEntities = () => {
  const {
    entities
  } = useEntities({
    model: SyncModel
  });
  return entities;
};
export const useActiveSync = () => {
  const syncEntities = useSyncEntities();
  return syncEntities.find(sync => sync.enabled);
};
export const useSyncEligibleImports = () => {
  const {
    loading,
    error,
    value
  } = useAsync(() => SyncModel.Promises.fetchEligibleImports());
  return {
    loading,
    error,
    imports: value
  };
};
export const createSync = (importId, groupId) => SyncModel.Promises.post({
  import_id: importId,
  group_id: groupId
});
export const disableSync = syncId => SyncModel.Promises.update(syncId, {
  enabled: false
});
export const enableSync = syncId => SyncModel.Promises.update(syncId, {
  enabled: true
});
export const deleteSync = syncId => SyncModel.Promises.delete(syncId);