import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { BILLING_TYPES } from '@clubhouse/shared/types';
import { useCurrentCompany } from 'data/entity/company';
import { getPlanBillingType, isMissingCreditCard, useCurrentPlan } from 'data/entity/paymentPlan2';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReferralMessage = () => {
  const company = useCurrentCompany();
  const {
    paymentPlan
  } = useCurrentPlan();
  const showReferralMessage = company.referred && isMissingCreditCard(paymentPlan) && getPlanBillingType(paymentPlan) !== BILLING_TYPES.NON_PROFIT;
  return showReferralMessage ? _jsx(AlertMessage, {
    kind: AlertMessage.KIND.INFO,
    children: _jsxs("span", {
      children: ["Reminder: You are eligible for two free months thanks to your Referral Code! Please enter a Credit Card to redeem.", _jsx("br", {}), _jsx("em", {
        children: "Note: Your card will not be charged until you complete your additional two free months."
      })]
    })
  }) : null;
};