import { useCallback } from 'react';
import { getCurrentBackgroundLocationState } from './backgroundLocationState';
import { useReplaceState } from './useReplaceState';
export const useReplaceWithCurrentBackgroundState = () => {
  const replaceState = useReplaceState();
  return useCallback(() => {
    replaceState(obj => {
      const backgroundLocation = getCurrentBackgroundLocationState(obj.state).backgroundLocation;
      return backgroundLocation ? {
        state: null,
        ...backgroundLocation
      } : obj;
    });
  }, [replaceState]);
};