import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BidirectionalArrows = _ref => {
  let {
    fill = 'none',
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsx("svg", {
    width: width,
    height: width,
    viewBox: "0 0 16 14",
    fill: fill,
    children: _jsx("path", {
      d: "M3.00001 9C3.00001 9.26522 3.10537 9.51957 3.2929 9.70711C3.48044 9.89464 3.73479 10 4.00001 10C4.26523 10 4.51958 9.89464 4.70712 9.70711C4.89465 9.51957 5.00001 9.26522 5.00001 9V3.414L6.29301 4.707C6.48161 4.88916 6.73421 4.98995 6.99641 4.98767C7.25861 4.9854 7.50942 4.88023 7.69483 4.69482C7.88024 4.50941 7.98541 4.2586 7.98768 3.9964C7.98996 3.7342 7.88917 3.4816 7.70701 3.293L4.70701 0.293C4.51948 0.105529 4.26517 0.000213623 4.00001 0.000213623C3.73485 0.000213623 3.48054 0.105529 3.29301 0.293L0.29301 3.293C0.110852 3.4816 0.010058 3.7342 0.0123364 3.9964C0.0146148 4.2586 0.119784 4.50941 0.305192 4.69482C0.4906 4.88023 0.741412 4.9854 1.00361 4.98767C1.26581 4.98995 1.51841 4.88916 1.70701 4.707L3.00001 3.414V9ZM13 5C13 4.73478 12.8947 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V10.586L9.70701 9.293C9.51841 9.11084 9.26581 9.01005 9.00361 9.01233C8.74141 9.0146 8.4906 9.11977 8.30519 9.30518C8.11978 9.49059 8.01462 9.7414 8.01234 10.0036C8.01006 10.2658 8.11085 10.5184 8.29301 10.707L11.293 13.707C11.4805 13.8945 11.7348 13.9998 12 13.9998C12.2652 13.9998 12.5195 13.8945 12.707 13.707L15.707 10.707C15.8892 10.5184 15.99 10.2658 15.9877 10.0036C15.9854 9.7414 15.8802 9.49059 15.6948 9.30518C15.5094 9.11977 15.2586 9.0146 14.9964 9.01233C14.7342 9.01005 14.4816 9.11084 14.293 9.293L13 10.586V5Z",
      fill: "#808080"
    })
  });
};
BidirectionalArrows.displayName = "BidirectionalArrows";