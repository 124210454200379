import Constants from './constants';
import Globals from '../_frontloader/globals';
import * as Event from '../_frontloader/event';
import Log from './log';
import OrganizationModel from '../models/organization';
import UserModel from '../models/user';
import Utils from './utils';
const exports = {};
const CURRENT_USER_KEY = 'currentUser';
exports.getCurrentUser = () => {
  let user = Globals.get(CURRENT_USER_KEY);

  // Not sure why, but sometimes currentUser can be:
  // { error: 'The server response was invalid.' }
  if (user && user.error) {
    Log.log('Resetting currentUser!', {
      user
    });
    Globals.set(CURRENT_USER_KEY, null);
    user = null;
  }
  return user;
};
exports.passwordAuthDisabled = () => {
  const currentUser = exports.getCurrentUser();
  return currentUser && currentUser.password_auth_allowed === false;
};
exports.getCurrentUserPrefix = () => {
  const prefix = Constants.LOCALSTORAGE_PREFIX;
  const user = exports.getCurrentUser();
  const org = OrganizationModel.getCurrent();
  let hash = '';
  if (user && org) {
    hash = Utils.simpleHash(user.id + org.id) + '.';
  }
  return prefix + hash;
};
exports.setCurrentUser = user => {
  Globals.set(CURRENT_USER_KEY, user);
  if (user) {
    Event.trigger('currentUserSet', user);
  }
};
exports.isCurrentUser = user => {
  const currentUser = exports.getCurrentUser();
  return currentUser && user && currentUser.id === user.id;
};
exports.isLoggedIn = () => {
  return !!exports.getCurrentUser();
};
exports.isImpersonationMode = () => {
  const currentUser = exports.getCurrentUser();
  return currentUser && currentUser.impersonation_mode === true;
};
exports.isStoryDialogExpanded = () => {
  return _.get(exports.getCurrentUser(), 'story_dialog_expanded', false);
};
exports.setStoryDialogExpanded = story_dialog_expanded => {
  const id = exports.getCurrentUser().id;
  UserModel.update({
    id,
    story_dialog_expanded
  }); //avoid waiting for server response
  UserModel.updateUser({
    story_dialog_expanded
  });
};
export { exports as default };