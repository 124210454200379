import IntegrationsDialogHeaderImg from '@clubhouse/assets/png/integrations-dialog-header.png';
import { isAProfitablePlan, useCurrentPlan } from 'data/entity/paymentPlan2';
import { getIntegrationsForPlan } from 'utils/integrations';
import { FreeIntegrations } from './FreeIntegrations';
import { PaidIntegrations } from './PaidIntegrations';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const MARKETING_INTEGRATIONS_URL = `https://${BRAND.DOMAIN_CORP_WEBSITE}/integrations`;
export const IntegrationSettings = () => {
  const {
    paymentPlan
  } = useCurrentPlan();
  const isProfitable = isAProfitablePlan(paymentPlan);
  const integrations = getIntegrationsForPlan();
  return _jsxs("div", {
    id: "integration-settings",
    children: [_jsxs("div", {
      className: "dialog-header",
      children: [_jsx("img", {
        src: IntegrationsDialogHeaderImg,
        alt: "Shortcut Integrations",
        style: {
          position: 'absolute',
          top: 0,
          right: '36px',
          width: '500px'
        }
      }), _jsx("h2", {
        children: "Integrations"
      })]
    }), isProfitable ? _jsxs(_Fragment, {
      children: [_jsx("div", {
        className: "blurb",
        children: " Connect Shortcut with your favorite apps and tools."
      }), _jsx(PaidIntegrations, {
        integrations: integrations
      })]
    }) : _jsxs(_Fragment, {
      children: [_jsxs("div", {
        className: "blurb",
        children: ["Check out our full list of integrations ", _jsx("a", {
          href: MARKETING_INTEGRATIONS_URL,
          children: "here"
        }), "."]
      }), _jsx(FreeIntegrations, {
        integrations: integrations
      })]
    })]
  });
};
IntegrationSettings.displayName = "IntegrationSettings";