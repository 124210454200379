import EpicModel from '../../../core/js/models/epic';
import StoriesView from '../modules/storiesView';
import StoryModel from '../../../core/js/models/story';
const exports = {};

// Extends core/js/models/story.

exports.initEventBindings = () => {
  StoryModel.on('newStorySaved storyMoved storyStateChanged bulkStoriesUpdated', () => {
    StoriesView.drawStories();
  });
};
exports.hasActiveEpic = story => {
  const epic = EpicModel.getById(story.epic_id);
  return EpicModel.isActive(epic);
};
export { exports as default };