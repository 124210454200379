import { DISPLAY_CONFIG } from 'app/client/core/js/modules/constants';
import { PRODUCT_FEATURES as _PRODUCT_FEATURES } from './productFeatures';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
const exports = {};
Collection.create('Product', exports);
export const PRODUCT_FEATURES = _PRODUCT_FEATURES;
const addDisplayAttributes = product => {
  return Object.assign({}, product, DISPLAY_CONFIG[product.display_key]);
};
const handleFetchAll = (res, callback) => {
  if (Array.isArray(res)) {
    const products = res.map(addDisplayAttributes);
    exports.defaultFetchAllHandler(products, callback);
  } else {
    // it is possible to get an error result when the organization is disabled (unauthorized)
    // in which case the product is unknown, but login should proceed.
    exports.defaultFetchAllHandler([], callback);
  }
};
exports.fetchAll = callback => {
  Backend.get(`/api/private/products`, {
    excludeOrgHeader: true,
    onComplete: res => handleFetchAll(res, callback)
  });
};
exports.hasEntitlement = (product, entitlement) => {
  return product.accessible_entitlements.includes(entitlement);
};
exports.isTier = (product, tier) => product?.display_key === tier;
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
export { exports as default };