import EventEmitter from 'eventemitter3';
import { THEME_NAMES, isThemeName, isThemePreference } from './types';
/**
 * Theme Helpers
 *
 * The vast majority of theme functions are here in this folder, except the actual toggleDarkMode
 * function, which persists to the user model and therefore lives in core/js/modules/userTheme,
 * and the preloadTheme function, which is in static/lib/preloadTheme.js, because it needs to run
 * before the JavaScript bundle is available.
 */

const eventEmitter = new EventEmitter();
const CHANGE_EVENT = 'change';
const LS_KEY = '_CLUBHOUSE_APP_THEME'; // TODO(rename) Shared package needs access to BRAND
const ANIMATION_CLASSNAME = 'theme-animation';
const DEFAULT_THEME_NAME = THEME_NAMES.LIGHT;

/**
 * This variable is generally the source of truth on the theme name, although it is loaded from
 * various sources below.
 */
let themeName;
export const getTheme = () => {
  return themeName;
};
export const addChangeListener = listener => {
  eventEmitter.addListener(CHANGE_EVENT, listener);
};
export const removeChangeListener = listener => {
  eventEmitter.removeListener(CHANGE_EVENT, listener);
};

/**
 * This function will set the theme for any components that use descendant CSS styles to theme
 * stuff. Notably this triggers all the var(--brightTextColor) etc CSS variables to change.
 */
export const addThemeToHTML = themeName => {
  // Note that this data-theme attribute is also preloaded in preload-theme.js
  document.querySelector('html')?.setAttribute('data-theme', themeName.replace(/[^a-z-_]/gi, ''));
};

/**
 * This function is called at the bottom of this file. So it runs at some point during the loading
 * of the JavaScript bundle. However, whatever setting we decide on in here will be overwritten when
 * the user theme preference comes down from the server and _setAppTheme() is called by
 * core/js/modules/boot.js
 */
const init = () => {
  let themeNameFromStorage = null;
  try {
    // eslint-disable-next-line no-restricted-properties
    themeNameFromStorage = window.localStorage.getItem(LS_KEY);
  } catch (e) {}
  const themeName = isThemeName(themeNameFromStorage) ? themeNameFromStorage : DEFAULT_THEME_NAME;
  addThemeToHTML(themeName);
  _setTheme(themeName);
};

/**
 *
 *      WARNING: If you want to change the theme, you probably want setThemePreference, not this
 *      function. That's why it has an underscore. It's only really exported for Storybook
 *      to use.
 *
 * This function changes the theme state in memory, including emitting an event to all listeners
 * (Emotion and all of the React components).
 *
 * It does not set the theme in the DOM, _setAppTheme does that. Nor does it persist the user's
 * theme settings on the server, which would be required for the theme change to persist across page
 * loads. That's done in userTheme, or useThemePreference exported from data/entity/user.
 */
export const _setTheme = newThemeName => {
  if (newThemeName === getTheme()) return;
  themeName = newThemeName;
  try {
    // eslint-disable-next-line no-restricted-properties
    window.localStorage.setItem(LS_KEY, newThemeName);
  } catch (e) {}
  eventEmitter.emit(CHANGE_EVENT, newThemeName);
};

/**
 * This function sets the theme across the app.
 *
 * It updates localStorage, emits the events, adds the necessary classes to the HTML, and triggers a
 * fancy animation that causes the theme to fade in smoothly.
 *
 * It does everything but persist the theme setting to the server. (That's done in userTheme)
 */
export const _setAppTheme = newThemeName => {
  if (!isThemeName(newThemeName) || newThemeName === getTheme()) return;
  document.querySelector('html')?.classList.add(ANIMATION_CLASSNAME);
  addThemeToHTML(newThemeName);
  _setTheme(newThemeName);
  setTimeout(() => {
    document.querySelector('html')?.classList.remove(ANIMATION_CLASSNAME);
  }, 600);
};

/**
 * Sets the app theme preference, either to LIGHT or DARK, or to follow the system theme. If
 * 'system' it sets up a listener which will change the theme if the system theme changes.
 *
 * Note that this preference is _not_ persisted directly to localStorage. We do not store the
 * preference to follow the system theme in local storage, we just store the theme name (dark or
 * light). The localStorage preference is just used to quickly bootstrap dark or light mode, and if
 * the system theme changed since the last page load, we'll notice that when the user preference is
 * officially synced from the server and this function is called.
 */
export const setThemePreference = preference => {
  if (!isThemePreference(preference) || preference === 'system') {
    followSystemTheme();
  } else {
    unfollowSystemTheme();
    _setAppTheme(preference);
  }
};

/**
 * System theme stuff
 */

// Note that window.matchMedia won't exist during tests
const darkMediaQuery = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
const handleSystemThemeChange = event => {
  _setAppTheme(event.matches ? THEME_NAMES.DARK : THEME_NAMES.LIGHT);
};
export const followSystemTheme = () => {
  _setAppTheme(darkMediaQuery.matches ? THEME_NAMES.DARK : THEME_NAMES.LIGHT);
  try {
    // Chrome & Firefox
    darkMediaQuery.addEventListener('change', handleSystemThemeChange);
  } catch (e1) {
    // Safari
    darkMediaQuery.addListener(handleSystemThemeChange);
  }
};
export const unfollowSystemTheme = () => {
  try {
    // Chrome & Firefox
    darkMediaQuery.removeEventListener('change', handleSystemThemeChange);
  } catch (e1) {
    // Safari
    darkMediaQuery.removeListener(handleSystemThemeChange);
  }
};
init();