import { useCallback, useState } from 'react';
import { addToRecentSearches, getNonEmptyRecentSearches, removeRecentSearch } from 'utils/searchTips';
export function useSavedSearches() {
  const [savedSearches, setSavedSearches] = useState(getNonEmptyRecentSearches());
  const onAdd = useCallback(query => {
    addToRecentSearches(query);
    setSavedSearches(getNonEmptyRecentSearches());
  }, []);
  const onRemove = useCallback(query => {
    removeRecentSearch(query);
    setSavedSearches(getNonEmptyRecentSearches());
  }, []);

  /* Warning: In certain scenarios the returned "savedSearches" & "hasSavedSearches"
   * values may be stale due to the use of "getNonEmptRecentSearches".
   */
  return {
    hasSavedSearches: Boolean(savedSearches.length),
    savedSearches,
    onAdd,
    onRemove
  };
}