import { NoEpicsInWorkspaceEmptyStateQueryDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useLayoutEffect } from 'react';
import EpicsPng from '@clubhouse/assets/png/Epics-illo-light.png';
import { gql } from '@clubhouse/datalayer';
import { ButtonWithSampleLink } from 'components/shared/ButtonWithSampleLink';
import { CompositeImage } from 'components/shared/CompositeImage';
import { Button as DeprecatedButton } from 'components/shared/DeprecatedButton';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { Problem } from 'components/shared/Problem';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import { useTeamScopeId } from 'components/team-navigation';
import { isSampleObserver } from 'data/entity/user';
import { useNodeQuery } from 'gql/hooks/useNodeQuery';
import { renderAddNewEpic } from 'utils/addNew';
import { join } from 'utils/sampleWorkspace';
import { useIsEmptyWorkspace } from '../../../components/useIsEmptyWorkspace';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Image = _jsx(EmptyStateImage, {
  wrapperWidth: 72,
  children: _jsx(CompositeImage, {
    primaryImage: {
      src: EpicsPng,
      alt: '',
      darkModeOpacity: 1
    }
  })
});
const getLearnAboutEpicsButtonProps = () => ({
  text: 'Learn about Epics',
  onClick: () => toggleHelpHub()
});
const sampleButtonProps = {
  text: 'View Sample Epics',
  onClick: () => join('epics')
};
const NoEpicsInWorkspaceEmptyStateQuery = NoEpicsInWorkspaceEmptyStateQueryDocument;
export function NoEpicsInWorkspaceEmptyState() {
  const [, setIsEmpty] = useIsEmptyWorkspace();
  const {
    value: teamId
  } = useTeamScopeId();
  const learnAboutEpicsButtonProps = getLearnAboutEpicsButtonProps();
  const {
    node
  } = useNodeQuery(NoEpicsInWorkspaceEmptyStateQuery, {
    id: teamId ?? '',
    pollInterval: 0,
    fetchPolicy: 'cache-first',
    skip: !teamId
  });
  useLayoutEffect(() => {
    setIsEmpty(true);
    return () => {
      setIsEmpty(false);
    };
  }, [setIsEmpty]);
  return _jsx("div", {
    children: _jsx(Problem, {
      minHeight: "480px",
      title: "Track work with an Epic",
      description: 'An Epic is a large body of work, made up of smaller Stories.',
      media: Image,
      svgHeight: "180px",
      buttons: _jsx(ButtonWithSampleLink, {
        buttonProps: {
          text: 'Create Epic',
          color: DeprecatedButton.colors.green,
          onClick: () => renderAddNewEpic(teamId ? {
            group_id: node?.publicId
          } : undefined)
        },
        learnButtonProps: learnAboutEpicsButtonProps,
        sampleButtonProps: isSampleObserver() ? undefined : sampleButtonProps
      })
    })
  });
}
NoEpicsInWorkspaceEmptyState.displayName = "NoEpicsInWorkspaceEmptyState";