import ManageBillingController from 'app/client/settingsShared/js/controllers/manageBilling';
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard';
import { trackEvent } from 'components/shared/utils/trackEvent';
import { getPlanPriceTotal, updateTier } from 'data/entity/paymentPlan2';
import { EVENTS } from './monitoring';
export const openPaymentPlanTab = () => {
  ManageBillingController.openPaymentPlanTab();
};
export const openUpgradeDialog = function () {
  ManageBillingController.openUpgradeDialog(...arguments);
};
export const commitPlanChange = async _ref => {
  let {
    tier,
    period,
    creditCardDetails,
    couponCode,
    plan
  } = _ref;
  if (creditCardDetails) {
    await ManageCardController.updateCardFromData(creditCardDetails);
  }
  const updatedPlan = await updateTier({
    tier: tier,
    period,
    couponCode
  });
  trackEvent({
    event: EVENTS.PaymentPlan_Updated,
    properties: {
      total: getPlanPriceTotal({
        prices: plan.prices,
        tier,
        frequency: period
      }),
      ...(couponCode ? {
        coupon_code: couponCode
      } : {})
    }
  });
  return updatedPlan;
};
export const openManageBilling = () => ManageBillingController.open();