import { ReportFilterDropdown } from '../../shared/reports/ReportFilterDropdown';
import { getTimeSpentInWFStateConfig } from 'data/entity/report';
import { getParamFromUrl, updateParamInUrl } from 'utils/navigation';
import { sendTimeSpentInWFStateEvent } from 'utils/segment';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const Filters = _ref => {
  let {
    onChange
  } = _ref;
  const filters = ['wf_state_workflow', 'wf_state_type', 'wf_state_calculation'].map(key => {
    const {
      defaultValue,
      title,
      values
    } = getTimeSpentInWFStateConfig(key);
    return _jsx(ReportFilterDropdown, {
      configId: key,
      value: getParamFromUrl(key) || defaultValue,
      title: title,
      onChange: (configId, selection) => {
        sendTimeSpentInWFStateEvent({
          configId,
          selection
        });
        updateParamInUrl(configId, selection);
        onChange();
      },
      items: values
    }, key);
  });
  return _jsx(_Fragment, {
    children: filters
  });
};