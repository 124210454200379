import { UseSetTeamScopeForStoryPageOnFirstRenderQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useLazyQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { matchPath } from 'react-router-dom';
import { gql } from '@clubhouse/datalayer';
import { getPathForPage, useSetBackgroundPageTeamId, useWorkspaceSlug } from 'utils/navigation';
import { PAGE_NAMES } from 'utils/navigation/paths';
export const UseSetTeamScopeForStoryPageOnFirstRenderQuery = UseSetTeamScopeForStoryPageOnFirstRenderQueryDocument;
export const useSetTeamScopeForStoryPageOnFirstRender = _ref => {
  let {
    pathname,
    loading
  } = _ref;
  const [query] = useLazyQuery(UseSetTeamScopeForStoryPageOnFirstRenderQuery);
  const slug = useWorkspaceSlug();
  const setTeamId = useSetBackgroundPageTeamId();
  const hasRun = useRef(false);
  useEffect(() => {
    (async () => {
      if (hasRun.current || loading) {
        return;
      }
      hasRun.current = true;
      const match = matchPath(pathname, {
        path: getPathForPage(PAGE_NAMES.STORY)
      });
      if (!match) {
        return;
      }
      const {
        data
      } = await query({
        variables: {
          slug: slug ?? '',
          publicId: Number(match.params.id)
        },
        fetchPolicy: 'network-only'
      });
      const teamId = data?.workspace2?.story?.team?.id ?? null;
      if (teamId) {
        setTeamId({
          teamId
        });
      }
    })();
  }, [loading, pathname, query, setTeamId, slug]);
};