import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Swatch } from 'components/shared/Swatch';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getSection = (_, options) => ({
  items: options.map(_ref => {
    let {
      node: {
        id,
        name,
        color
      }
    } = _ref;
    return {
      value: id,
      name: _jsx(Emojify, {
        children: name
      }),
      Icon: () => _jsx(Swatch, {
        backgroundColor: color
      })
    };
  })
});