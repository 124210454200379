import { useCallback, useEffect, useReducer } from 'react';
import { insertIf } from '@clubhouse/shared/utils/object';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { GROUP_BY } from 'utils/groupBy';
import { EVENTS, logEvent } from 'utils/monitoring';
import { CUSTOM_FIELD_VALUE_IDS_KEY, GROUP_BY_KEY, GROUP_IDS_KEY, LABEL_IDS_KEY, OWNER_IDS_KEY, PROJECT_IDS_KEY, QUERY_KEY, SORT_BY_KEY, SORT_DIR_KEY, WORKFLOW_STATE_IDS_KEY } from './storyTableSettingsConfig';
import { useStoryTableSavedState } from './useStoryTableSavedState';
export function useStoryTableState(_ref) {
  let {
    id,
    entityType
  } = _ref;
  const [initialState, setSavedState] = useStoryTableSavedState({
    id,
    entityType
  });
  const [settings, setSettings] = useReducer((currentSettings, changedValues) => ({
    ...currentSettings,
    ...changedValues
  }), initialState);
  useEffect(() => {
    setSavedState(settings);
  }, [settings, setSavedState]);
  const {
    [QUERY_KEY]: query,
    [PROJECT_IDS_KEY]: projects,
    [GROUP_IDS_KEY]: teams,
    [OWNER_IDS_KEY]: owners,
    [LABEL_IDS_KEY]: labels,
    [WORKFLOW_STATE_IDS_KEY]: workflowStates,
    [CUSTOM_FIELD_VALUE_IDS_KEY]: customFields,
    [GROUP_BY_KEY]: groupBy,
    [SORT_BY_KEY]: sortBy,
    [SORT_DIR_KEY]: sortDir
  } = settings;
  const setValueByKey = key => value => {
    setSettings({
      [key]: value
    });
    if (Array.isArray(value)) {
      logEvent(EVENTS.Interaction_FilterInitiated, {
        source: `${entityType.singular.toLowerCase()} detail page`,
        filter_name: key,
        filter_type: 'drop down',
        filter_search_used: !!query,
        filter_selection: value,
        number_of_selections: value.length
      });
    }
  };
  const setSortBy = useCallback(_ref2 => {
    let {
      newSort,
      oldSort
    } = _ref2;
    setSettings({
      [SORT_BY_KEY]: newSort,
      [SORT_DIR_KEY]: newSort !== oldSort ? 'ascending' : sortDir === 'ascending' ? 'descending' : 'ascending',
      ...insertIf(newSort === 'priority', {
        [GROUP_BY_KEY]: GROUP_BY.STATE
      })
    });
  }, [sortDir]);
  return {
    values: {
      query,
      projects,
      teams,
      owners,
      labels,
      workflowStates,
      customFields,
      groupBy,
      sortBy,
      sortDir
    },
    setValues: {
      setQuery: setValueByKey(QUERY_KEY),
      setProjects: setValueByKey(PROJECT_IDS_KEY),
      setTeams: setValueByKey(GROUP_IDS_KEY),
      setOwners: setValueByKey(OWNER_IDS_KEY),
      setLabels: setValueByKey(LABEL_IDS_KEY),
      setWorkflowStates: setValueByKey(WORKFLOW_STATE_IDS_KEY),
      setCustomFields: setValueByKey(CUSTOM_FIELD_VALUE_IDS_KEY),
      setGroupBy: value => {
        setSettings({
          [GROUP_BY_KEY]: value,
          ...insertIf(value !== GROUP_BY.STATE && sortBy === 'priority', {
            [SORT_BY_KEY]: 'id',
            [SORT_DIR_KEY]: 'ascending'
          })
        });
      },
      setSortBy
    },
    filters: {
      hasFilters: !!(query || teams.length || owners.length || labels.length || workflowStates.length || isProjectsFeatureEnabled() && projects.length || areCustomFieldsEnabled() && customFields.length),
      clearFilters: () => {
        setSettings({
          [QUERY_KEY]: '',
          [PROJECT_IDS_KEY]: [],
          [GROUP_IDS_KEY]: [],
          [OWNER_IDS_KEY]: [],
          [LABEL_IDS_KEY]: [],
          [WORKFLOW_STATE_IDS_KEY]: [],
          [CUSTOM_FIELD_VALUE_IDS_KEY]: []
        });
      }
    }
  };
}