import SlackAuthV2 from './slackAuthV2';
import Message from '../../../core/js/controllers/message';
import CustomFieldModel from '../../../core/js/models/customField';
import GroupModel from '../../../core/js/models/group';
import IntegrationModel from '../../../core/js/models/integration';
import ProjectModel from '../../../core/js/models/project';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
const exports = {};
exports.handleAuth = () => {
  return new Promise(resolve => {
    const query = Url.parseLocationSearch();
    const state = query.state.split('|'); // State format is workspace_slug|project_id.
    const origin = Url.getCurrentOrigin();
    if (state[0] && state[0] !== Url.getCurrentSlug()) {
      // https://integrations.shortcut.com/settings/integrations/slack
      Utils.redirect(origin + '/' + state[0] + '/settings/integrations/slack' + window.location.search);
      return resolve();
    }
    return resolve(_setupIntegration().then(result => {
      return saveSuccessMessage(result);
    }).catch(err => {
      return saveErrorMessage(err);
    }));
  });
};
const _setupIntegration = () => {
  return new Promise((resolve, reject) => {
    const query = Url.parseLocationSearch();
    const {
      code,
      error,
      state
    } = query;
    if (error) {
      return reject(error);
    }
    IntegrationModel.fetchAll(() => {
      const slack = IntegrationModel.getSlackIntegration();
      const [, id, type] = state.split('|');
      if (!slack && id !== undefined) {
        return reject();
      }
      const redirect_uri = IntegrationModel.getSlackRedirectUrl();
      if (!slack) {
        return resolve(SlackAuthV2.createAndAuthorize({
          code,
          redirect_uri
        }));
      }
      if (slack && !id) {
        return resolve(SlackAuthV2.authorize({
          integration: slack,
          code,
          redirect_uri
        }));
      }
      IntegrationModel.updateIntegration(slack, {
        webhook_url: null
      }, err => {
        if (err) {
          return reject(err);
        }
        return IntegrationModel.createIntegration({
          integration: slack,
          code,
          id,
          redirect_uri,
          type
        }, (err, response) => {
          if (err) {
            return reject(err);
          }
          return resolve(response);
        });
      });
    });
  });
};
const saveErrorMessage = err => {
  const title = 'There was an error installing the slack integration.';
  let message;
  if (err) message = `The message we got back was "${err}".`;
  Message.error(title, {
    secondary: message,
    timeout: 10_000
  });
};
const saveSuccessMessage = result => {
  const {
    channel,
    group_id,
    project_id,
    field_id,
    field_value_id
  } = result;
  let name;
  if (group_id) {
    name = GroupModel.getById(group_id)?.name ?? 'Team';
  } else if (project_id) {
    name = ProjectModel.getById(project_id)?.name ?? 'Project';
  } else if (field_id && field_value_id) {
    const field = CustomFieldModel.getById(field_id, field_value_id);
    name = field?.values?.find(value => value.id === field_value_id)?.value ?? 'Field';
  } else {
    name = 'Item';
  }
  Message.success(`${name} has been linked to ${channel || 'channel'}`);
};
export { exports as default };