import { AvatarWithoutProfile, SIZE } from '@clubhouse/shared/components/Avatar';
import { OwnerSelectOption } from '../OwnerSelectOption';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getItemConfigForPermission = node => {
  const {
    id,
    displayName,
    displayIcon,
    email,
    state
  } = node;
  return {
    value: id,
    name: _jsx(OwnerSelectOption, {
      displayName: displayName,
      isDisabled: state === 'disabled'
    }),
    Icon: () => _jsx(AvatarWithoutProfile, {
      id: id,
      email: email,
      size: SIZE.XS,
      fullName: displayName,
      imageUrl: displayIcon?.thumbnailUrl
    }),
    additionalData: {
      permission: node
    }
  };
};