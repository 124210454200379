import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * EstimateChip
 *
 * Do not use if estimate scale is disabled.
 */
export const EstimateChip = _ref => {
  let {
    estimate
  } = _ref;
  if (typeof estimate !== 'number') return null;
  return _jsx(ChipTooltip, {
    content: _jsxs(ChipTooltip.Text, {
      children: ["This Story has an estimate of", ' ', pluralize({
        count: estimate,
        singular: 'point',
        plural: 'points'
      }), "."]
    }),
    children: _jsxs(Chip, {
      children: [_jsx(DeprecatedIconAdapter, {
        width: 18,
        children: _jsx(Chip.Icon, {
          icon: "Estimate",
          label: "Estimate"
        })
      }), String(estimate)]
    })
  });
};
EstimateChip.displayName = "EstimateChip";