import { useMemo } from 'react';
export const mergeRefs = refs => {
  return element => {
    for (const ref of refs) {
      if (!ref) {
        continue;
      }
      if (typeof ref === 'function') {
        ref(element);
      } else {
        ref.current = element;
      }
    }
  };
};

// biome-ignore lint/correctness/useExhaustiveDependencies: refs is an array
export const useMergeRefs = refs => useMemo(() => mergeRefs(refs), refs);