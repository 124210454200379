import BacklogPng from '@clubhouse/assets/png/Backlog-illo-light.png';
import { Center } from '@clubhouse/shared/components/Center';
import { EmptyState } from '@clubhouse/shared/components/EmptyState';
import { useClearableCurrentFilters } from 'components/gql/filters/useClearableCurrentFilters';
import { CompositeImage } from 'components/shared/CompositeImage';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Image = _jsx(EmptyStateImage, {
  wrapperWidth: 72,
  children: _jsx(CompositeImage, {
    primaryImage: {
      src: BacklogPng,
      alt: '',
      darkModeOpacity: 1
    }
  })
});
export function BacklogEmptyState() {
  const {
    hasClearableFilters,
    clearFilters
  } = useClearableCurrentFilters();
  return _jsx(Center, {
    children: _jsx(EmptyState, {
      body: `There are no Stories${hasClearableFilters ? ' matching your filters' : ''}.`,
      onClickCTA: hasClearableFilters ? clearFilters : undefined,
      image: Image
    })
  });
}
BacklogEmptyState.displayName = "BacklogEmptyState";