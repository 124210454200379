import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { useSearchParam } from 'utils/navigation';
import { COLUMNS } from '../columns';
export const EpicsSelectedColumnConfig = createSearchParamConfig({
  paramName: 'column',
  paramType: 'string',
  validValues: COLUMNS,
  defaultValue: 'started'
});
export const useSelectedColumn = () => {
  const {
    param: selectedColumn,
    setSearchParam: setSelectedColumn
  } = useSearchParam(EpicsSelectedColumnConfig);
  return [selectedColumn, setSelectedColumn];
};