import { useCallback } from 'react';
import { useAssistiveNotification, useLatestRef } from '@clubhouse/shared/hooks';
export function useTableReorder(_ref) {
  let {
    onChange,
    onStart,
    onCancel,
    numItems
  } = _ref;
  const notifyScreenreaders = useAssistiveNotification('assertive');
  const numItemsRef = useLatestRef(numItems ?? -1);
  const onChangeRef = useLatestRef(onChange);
  const onStartRef = useLatestRef(onStart);
  const onCancelRef = useLatestRef(onCancel);
  const onReorderEnd = useCallback(_ref2 => {
    let {
      draggableId,
      source,
      destination,
      mode,
      reason
    } = _ref2;
    const newIndex = destination?.index;
    const oldIndex = source.index;
    const isKeySorting = mode === 'SNAP';
    if (reason === 'CANCEL' || !destination || typeof newIndex !== 'number' || newIndex === oldIndex && source.droppableId === destination.droppableId) {
      if (isKeySorting) {
        notifyScreenreaders(`Cancelled reordering. Row returned to number ${oldIndex + 1}${numItemsRef.current >= 0 ? ` of ${numItemsRef.current}` : ''}.`);
      }
      onCancelRef.current();
      return;
    }
    if (isKeySorting) {
      notifyScreenreaders(`Row is now number ${newIndex + 1}${numItemsRef.current >= 0 ? ` of ${numItemsRef.current}` : ''}.`);
    }
    onChangeRef.current({
      oldIndex,
      newIndex,
      draggableId,
      oldDroppableId: source.droppableId,
      newDroppableId: destination.droppableId
    });
  }, [notifyScreenreaders, numItemsRef, onChangeRef, onCancelRef]);
  const onReorderStart = useCallback(_ref3 => {
    let {
      draggableId,
      source: {
        droppableId,
        index
      },
      mode
    } = _ref3;
    const isKeySorting = mode === 'SNAP';
    if (isKeySorting) {
      notifyScreenreaders(`Row is currently number ${index + 1}${numItemsRef.current >= 0 ? ` of ${numItemsRef.current}` : ''}. Use arrow keys to change order. Press spacebar to save. Press escape to cancel.`);
    }
    onStartRef.current({
      draggableId,
      droppableId,
      index
    });
  }, [notifyScreenreaders, numItemsRef, onStartRef]);
  const onReorderOver = useCallback(_ref4 => {
    let {
      destination,
      mode
    } = _ref4;
    const isKeySorting = mode === 'SNAP';
    if (isKeySorting && destination) {
      const newIndex = destination.index;
      notifyScreenreaders(`Moved row to number ${newIndex + 1}${numItemsRef.current >= 0 ? ` of ${numItemsRef.current}` : ''}.`);
    }
  }, [notifyScreenreaders, numItemsRef]);
  return {
    onReorderStart,
    onReorderOver,
    onReorderEnd
  };
}