import { useCallback, useEffect, useRef } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
import { MarkdownTextArea } from 'components/shared/MarkdownTextArea';
import { logError } from 'utils/monitoring';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HealthCommentInput = _ref => {
  let {
    canSave = true,
    snapshotKey,
    onChange,
    onSave,
    onCancel
  } = _ref;
  const containerRef = useRef(null);
  const [isSaving, {
    on: startSaving,
    off: stopSaving
  }] = useToggleState();
  useEffect(() => {
    if (!containerRef.current) return;
    const parentEl = containerRef.current.parentElement;
    if (!parentEl) return;
    if (parentEl.scrollHeight > parentEl.offsetHeight) {
      containerRef.current.style.overflow = 'hidden';
      containerRef.current.style.maxHeight = `${parentEl.offsetHeight}px`;
    }
  }, []);
  const handleSave = useCallback(async () => {
    try {
      if (isSaving || !canSave) return;
      startSaving();
      await onSave();
    } catch (err) {
      if (err instanceof Error) {
        logError(err);
      } else {
        logError(new Error(String(err)));
      }
    } finally {
      stopSaving();
    }
  }, [canSave, isSaving, onSave, startSaving, stopSaving]);
  const onResize = useCallback(() => {
    if (!containerRef.current) return;
    const parentEl = containerRef.current.parentElement;
    if (!parentEl) return;
    if (parentEl.scrollHeight > parentEl.offsetHeight) {
      const style = window.getComputedStyle(parentEl, null);
      let offset = 0;
      if (style.boxSizing === 'content-box') {
        offset = -(Number.parseFloat(style.paddingTop) + Number.parseFloat(style.paddingBottom));
      } else {
        offset = Number.parseFloat(style.borderTopWidth) + Number.parseFloat(style.borderBottomWidth);
      }
      containerRef.current.style.overflow = 'hidden';
      containerRef.current.style.maxHeight = `${parentEl.offsetHeight + offset}px`;
    }
  }, []);
  return _jsx(MarkdownTextArea, {
    snapshotKey: snapshotKey,
    isReadOnly: isSaving,
    onChange: onChange,
    onSave: handleSave,
    onCancel: onCancel,
    onResize: onResize
  });
};
HealthCommentInput.displayName = "HealthCommentInput";