import { useCallAll } from './useCallAll';
import { useEpicsByState } from './useEpicsByState';
const getColumnProps = _ref => {
  let {
    startPolling,
    stopPolling,
    pageInfo,
    error,
    isStale,
    isLoading,
    epics,
    docs,
    estimates,
    updateQuery,
    currentUserId,
    isInitialDataFromCache
  } = _ref;
  const totalEpicCount = pageInfo?.totalSize || 0;
  const pageOffset = pageInfo?.offset || 0;
  const isEmpty = !isLoading && !epics.length && !totalEpicCount;
  return {
    startPolling,
    stopPolling,
    epics,
    currentUserId,
    docs,
    estimates,
    pageOffset,
    totalEpicCount,
    isEmpty,
    isLoading,
    isStale,
    hasError: !!error,
    updateQuery,
    isInitialDataFromCache
  };
};
const ACTIVE_COLUMN_POLLING_INTERVAL = 5_000;
const INACTIVE_COLUMN_POLLING_INTERVAL = 10_000;
export const PAGE_SIZE = 40;
const getQueryOptions = (column, selectedColumn) => ({
  limit: PAGE_SIZE,
  pollInterval: selectedColumn === column ? ACTIVE_COLUMN_POLLING_INTERVAL : INACTIVE_COLUMN_POLLING_INTERVAL
});
export function useEpicsColumnData(selectedColumn) {
  const unstarted = useEpicsByState('unstarted', getQueryOptions('unstarted', selectedColumn));
  const started = useEpicsByState('started', getQueryOptions('started', selectedColumn));
  const done = useEpicsByState('done', getQueryOptions('done', selectedColumn));
  const refetchAll = useCallAll('refetch', [unstarted, started, done]);
  const stopPollingAll = useCallAll('stopPolling', [unstarted, started, done]);
  const startPollingAll = useCallAll('startPolling', [unstarted, started, done]);
  return {
    columnData: {
      unstarted: getColumnProps(unstarted),
      started: getColumnProps(started),
      done: getColumnProps(done)
    },
    refetchAll,
    startPollingAll,
    stopPollingAll
  };
}