const ByOwnerSection = {
  ownerDone: 'Done',
  ownerStarted: 'Started',
  ownerUnstarted: 'Unstarted'
};
const ByTeamSection = {
  teamDone: 'Done',
  teamStarted: 'Started',
  teamUnstarted: 'Unstarted'
};
const ByObjectiveTeamSection = {
  objectiveTeamEpicDone: 'Done',
  objectiveTeamEpicStarted: 'Started',
  objectiveTeamEpicUnstarted: 'Unstarted'
};
const ByOtherSection = {
  done: 'Done',
  started: 'Started',
  unstarted: 'Unstarted'
};
function buildSectionAndGroupTitle(groupKey) {
  if (groupKey in ByOtherSection) {
    return [{
      kind: 'section-title',
      name: 'Other Epics',
      id: 'other-epics'
    }, {
      kind: 'group-title',
      name: ByOtherSection[groupKey],
      id: groupKey
    }];
  } else if (groupKey in ByTeamSection) {
    return [{
      kind: 'section-title',
      name: 'Team Epics',
      id: 'team-epics'
    }, {
      kind: 'group-title',
      name: ByTeamSection[groupKey],
      id: groupKey
    }];
  } else if (groupKey in ByOwnerSection) {
    return [{
      kind: 'section-title',
      name: 'Your Epics',
      id: 'your-epics'
    }, {
      kind: 'group-title',
      name: ByOwnerSection[groupKey],
      id: groupKey
    }];
  } else if (groupKey in ByObjectiveTeamSection) {
    return [{
      kind: 'section-title',
      name: "Objective Team's Epics",
      id: 'objective-team-epics'
    }, {
      kind: 'group-title',
      name: ByObjectiveTeamSection[groupKey],
      id: groupKey
    }];
  }
  throw new Error(`Invalid Group Key: '${groupKey}'`);
}

/* Follows the logic:
 *   * If the previous groupKey is the same as the current groupKey, they are in the same group and
 *       need no dividers.
 *   * If the groupKey is 'selectedEpic', then render out the "Selected Epics" section title.
 *   * If the previous groupKey is null, then we should render out the Section title as well as the
 *       Group title.
 *   * If the previous groupKey is in the same SECTION as the current groupKey, they are in the same
 *       SECTION and require no Section title, but do require Group titles.
 *   * If we have reached this point, we are at a boundary between SECTIONs and need to serve Section
 *       titles as well as Group titles.
 * */
export function buildSectionTitlesBasedOnGroupKeys(groupKey, previousGroupKey) {
  if (groupKey === previousGroupKey) {
    return [];
  } else if (groupKey === 'selectedEpic') {
    return [{
      kind: 'section-title',
      name: 'Selected Epics',
      id: groupKey
    }];
  }
  const [sectionTitle, groupTitle] = buildSectionAndGroupTitle(groupKey);
  if (!previousGroupKey) {
    return [sectionTitle, groupTitle];
  } else if (groupKey in ByOwnerSection && previousGroupKey in ByOwnerSection || groupKey in ByObjectiveTeamSection && previousGroupKey in ByObjectiveTeamSection || groupKey in ByTeamSection && previousGroupKey in ByTeamSection || groupKey in ByOtherSection && previousGroupKey in ByOtherSection) {
    return [groupTitle];
  }
  return [sectionTitle, groupTitle];
}