import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/epics/less/app.less';
import Mousetrap from 'mousetrap';
import ApplicationState from '../../../core/js/modules/applicationState';
import Boot from '../../../core/js/modules/boot';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import EpicModel from '../../../core/js/models/epic';
import EpicPageController from '../controllers/epicPage';
import * as Event from '../../../core/js/_frontloader/event';
import Is from '../../../core/js/modules/is';
import Layout from '../../../core/js/modules/layout';
import Lightbox from '../../../core/js/modules/lightbox';
import Updates from '../../../core/js/modules/updates';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
const exports = {};
const initialContentHTML = '<div id="epics" class="page-content"></div>';
const _init = () => {
  Layout.renderContent(initialContentHTML);
  exports.initEpicPage();
};
const _reInit = () => {
  Layout.renderContent(initialContentHTML);
  exports.reInitEpicPage();
};

// Individual Epic Page

exports.initEpicPage = () => {
  _initEpicPageFirstRun();
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
    _fetchEpicUpdates();
  });
};
exports.reInitEpicPage = () => {
  _initEpicPageFirstRun();
  _handleEpicUpdates();
};
function _initEpicPageFirstRun() {
  ApplicationState.setLastView();
  Updates.init(_handleEpicUpdates);
  Lightbox.loadLibraryIfMissing();
  EpicPageController.init();
  _initEpicPageShortcuts();
  Event.onlyOn('resize.EpicPage', _onEpicResize);
  Event.trigger('resize');
}
function _handleEpicUpdates() {
  if (Url.getCurrentPage() !== 'epic') {
    return false;
  }
  const path = Url.parsePath();
  const epic = EpicModel.get({
    id: path.epic
  });

  // Epic may be deleted.
  if (!epic) {
    return _renderIfStillOnEpicPage();
  }
  EpicModel.fetchLatestVersion(epic, () => {
    if (Url.getCurrentPage() !== 'epic') {
      return false;
    }
    EpicModel.fetchStoriesForEpicIncludingArchived(epic, () => {
      _renderIfStillOnEpicPage();
    });
  });
}
function _fetchEpicUpdates() {
  if (Url.getCurrentPage() !== 'epic') {
    return false;
  }
  const path = Url.parsePath();
  const epic = EpicModel.get({
    id: path.epic
  });

  // Epic may be deleted.
  if (!epic) {
    return _renderIfStillOnEpicPage();
  }
  EpicModel.fetchLatestVersion(epic, () => {
    if (Url.getCurrentPage() !== 'epic') {
      return false;
    }
    EpicModel.fetchStoriesForEpicIncludingArchived(epic, () => {
      _renderIfStillOnEpicPage();
    });
  });
}
function _onEpicResize() {
  if (!Is.mobile()) {
    Utils.switchMobileDesktopView();
  }
}
function _initEpicPageShortcuts() {
  if (!Is.readOnly(UserModel.getLoggedInUserPermission())) {
    Mousetrap.bind('n', () => {
      // Need to delay this so "n" doesn't show up in the title textarea.
      setTimeout(() => {
        const context = $('.epic-page');
        EpicPageController.createStoryInEpic.call(context);
      }, 0);
    });
  }
}
function _renderIfStillOnEpicPage() {
  // We might be on a different page now!
  if (Url.getCurrentPage() === 'epic') {
    EpicPageController.reRender();
  }
}
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
export { exports as default };