import { InternalSelect } from '../InternalSelect';
import { LoadingOptionDefault } from '../SelectOption';
import { WIDTH } from '../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LoadingItems = [{
  Component: LoadingOptionDefault,
  key: 'loading 1'
}, {
  Component: LoadingOptionDefault,
  key: 'loading 2'
}, {
  Component: LoadingOptionDefault,
  key: 'loading 3'
}];
export function AsyncSelect(_ref) {
  let {
    items,
    onOpen,
    onFetch,
    totalItemCount,
    unit,
    isSearchable = true,
    FooterComponent,
    targetComponentProps,
    ...props
  } = _ref;
  const isLoading = items === null;
  return _jsx(InternalSelect, {
    isSearchable: isSearchable,
    items: isLoading ? LoadingItems : items,
    isSelectionDisabled: isLoading,
    totalItemCount: totalItemCount || 0,
    unit: unit?.singular,
    "aria-busy": isLoading ? true : false,
    onOpen: onOpen,
    onBeforeOpen: onFetch,
    onFilter: onFetch,
    inputDebounceMs: 300,
    FooterComponent: FooterComponent,
    targetComponentProps: targetComponentProps,
    ...props
  });
}
AsyncSelect.displayName = "AsyncSelect";
AsyncSelect.WIDTH = WIDTH;