import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
import StoryModel from 'app/client/core/js/models/story.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController], [['Model', 'Story'], StoryModel], [['Tooltip'], Tooltip], [['Controller', 'Story'], StoryController], [['Model', 'Story'], StoryModel], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import isPlainObject from 'lodash/isPlainObject';
import { generateStoryUrl, getFormattedName, isValidForAutoLink, normalize } from 'data/entity/story';
import { useCollectionizeStoryContextMenu } from 'utils/contextMenus/useCollectionizeStoryContextMenu';
import { openStory } from 'utils/storyDialog';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Name = _ref2 => {
  let {
    story
  } = _ref2;
  const contextMenuProps = useCollectionizeStoryContextMenu(story.id);
  return _jsxs(_Fragment, {
    children: [_jsx("a", {
      href: generateStoryUrl(story),
      className: "entity-title-link",
      "data-model": "Story",
      "data-tooltip": true,
      "data-tooltip-fn": "App.Controller.Story.renderStoryTooltip",
      onClick: e => {
        e.preventDefault();
        openStory(story);
      },
      "data-id": story.id,
      ...contextMenuProps,
      dangerouslySetInnerHTML: {
        __html: getFormattedName(story) /* getFormattedName returns sanitized HTML */
      }
    }), ' ', story.archived ? _jsxs("span", {
      className: "story-is-archived",
      ...contextMenuProps,
      children: ["(archived)", ' ']
    }) : null]
  });
};
export const StoryLinkName = _ref3 => {
  let {
    story
  } = _ref3;
  if (isValidForAutoLink(story)) {
    normalize(story);
    return _jsx(Name, {
      story: story
    });
  } else if (story && !isPlainObject(story)) {
    return _jsx("span", {
      className: "auto-story-link story-was-deleted",
      "data-tooltip": `Story ${story} has been deleted.`,
      children: story
    });
  } else {
    return _jsx("em", {
      children: "Unknown Story"
    });
  }
};