import { useEffect, useRef } from 'react';
import { isReinit } from 'utils/bootStateMachine';
import { isStoryURL } from 'utils/navigation';
import * as StoryDialog from 'utils/storyDialog';
import { usePartialRouteEffects } from './partialRouteEffects';
export const RouteEffects = () => {
  const isInitialRenderRef = useRef(true);
  useEffect(() => {
    isInitialRenderRef.current = false;
  }, []);

  // This is something unique (a Symbol) that won't return the same value/reference for the same route
  const partialRouteSymbol = usePartialRouteEffects();

  // This is getting called on every route changes, be sure this couldn't cause a loop.
  // biome-ignore lint/correctness/useExhaustiveDependencies: ?
  useEffect(() => {
    if (isInitialRenderRef.current === false && isReinit() === true) {
      // We have to check `isOpening` in addition to `isOpen` as the `StoryDialog` may not be ready yet
      // or the opening could have failed.
      if (isStoryURL() && !StoryDialog.isOpen() && !StoryDialog.isOpening()) {
        // We're bypassing `StoryDialog.open()` because it's expecting
        // to be called from a click event on a DOM element
        StoryDialog.openFromRouter();
      }
    }
  }, [partialRouteSymbol]);
  return null;
};