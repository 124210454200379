import Logo from '@clubhouse/assets/png/third-party-logos/testlodge_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Testlodge() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.TESTLODGE
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Testlodge"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Testlodge Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Automatically create a Shortcut Story when a test fails in", ' ', _jsx(ExternalLink, {
          href: "https://www.testlodge.com/",
          children: "Testlodge"
        }), "."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://blog.testlodge.com/shortcut-test-management/",
          children: "Learn more"
        }), " on how the Testlodge integration works in Shortcut."]
      })]
    })]
  });
}
Testlodge.displayName = "Testlodge";