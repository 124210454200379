import { CreateEpicDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql/hooks/useMutation';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
const CreateEpicMutation = CreateEpicDocument;
export const useCreateEpicInCurrentWorkspace = () => {
  const workspace2Slug = useWorkspaceSlug();
  const [execute] = useMutation(CreateEpicMutation);
  return async input => {
    const {
      data,
      errors
    } = await execute({
      variables: {
        workspace2Slug,
        input
      }
    });
    if (data?.epicCreate?.epic?.__typename !== 'Epic') {
      logError(new Error(`Error creating an Epic`), {
        errors: JSON.stringify(errors)
      });
      throw new Error(`Could not create Epic`);
    }
    return data.epicCreate.epic;
  };
};