import View from 'app/client/core/js/modules/view';
import { simpleCompleteTask } from 'utils/quickstart';
import * as Event from 'app/client/core/js/_frontloader/event';
import { IterationsPage } from 'pages/iterations/IterationsPage';
const exports = {};
exports.render = () => {
  View.renderComponentDelayed({
    mountNode: document.body.querySelector('#iterations'),
    componentKey: 'iterations-page',
    component: IterationsPage
  });
  View.unmountDetachedComponents();
  Event.trigger('pageRendered', 'Iterations');
};
exports.init = () => {
  simpleCompleteTask('visit-iterations');
};
export { exports as default };