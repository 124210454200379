import { useTheme } from '@emotion/react';
import { ExclamationTriangle } from '@clubhouse/shared/components/Icons';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const InvalidWorkflowStateIcon = () => {
  const theme = useTheme();
  return _jsx(Tooltip, {
    maxWidth: 370,
    content: "Story must be moved into one of the Team's Workflows",
    children: _jsx("span", {
      "aria-label": "Invalid workflow",
      children: _jsx(ExclamationTriangle, {
        fill: theme.iconRedColor,
        width: 16
      })
    })
  });
};
InvalidWorkflowStateIcon.displayName = "InvalidWorkflowStateIcon";