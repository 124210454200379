import { FilterField } from '@clubhouse/shared/components/FilterField';
import { RadioButtons } from '@clubhouse/shared/components/RadioButtons';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { useSearchParam } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ObjectivesViewConfig = createSearchParamConfig({
  paramName: 'view',
  paramType: 'string',
  defaultValue: 'column',
  validValues: ['column', 'table']
});
export const useCurrentView = () => useSearchParam(ObjectivesViewConfig).param;
export function ViewSelector() {
  const {
    param: value,
    setSearchParam: setValue
  } = useSearchParam(ObjectivesViewConfig);
  return _jsx(FilterField, {
    label: "View",
    children: _jsxs(RadioButtons, {
      selected: value,
      onChange: setValue,
      children: [_jsx(RadioButtons.IconLabel, {
        icon: "Column",
        tooltip: "Column view",
        value: "column"
      }), _jsx(RadioButtons.IconLabel, {
        icon: "Table",
        tooltip: "Table view",
        value: "table"
      })]
    })
  });
}
ViewSelector.displayName = "ViewSelector";