export const PADDING = {
  xsmall: '0px 4px',
  small: '2px 4px',
  medium: '3px 6px',
  large: '3px 6px'
};
export const RADIUS = {
  xsmall: 6,
  small: 6,
  medium: 12,
  large: 12
};
export const LINE_HEIGHT = {
  xsmall: '14px',
  small: '16px',
  medium: '18px',
  large: '20px'
};
export const ICON_SIZE = {
  xsmall: '14',
  small: '16',
  medium: '18',
  large: '20'
};