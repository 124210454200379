import { closestAncestor } from '@clubhouse/shared/utils/dom';
import { getFocusableChildren } from '@clubhouse/shared/utils/focus';
export const getFirstFocusTarget = table => {
  const targets = getFocusableChildren(table);
  if (!targets.length) return null;
  return targets[0];
};
export const getLastFocusTarget = table => {
  const targets = getFocusableChildren(table);
  if (!targets.length) return null;
  return targets[targets.length - 1];
};
export const getCell = el => {
  if (!el) return null;
  if (el.matches('td,th')) return el;
  return closestAncestor(el, 'td,th');
};
export const getChildrenBySelector = (parent, selector) => parent ? Array.from(parent.querySelectorAll(selector)) : [];
export const getRows = table => getChildrenBySelector(table, 'tr');
export const getCells = row => getChildrenBySelector(row, 'th,td');
export const clamp = (n, min, max) => Math.min(Math.max(n, min), max);
export const getMovementDirection = key => {
  const dir = {
    x: 0,
    y: 0
  };
  if (key === 'ArrowUp') dir.y = -1;else if (key === 'ArrowDown') dir.y = 1;else if (key === 'ArrowLeft' || key === 'Home') dir.x = -1;else if (key === 'ArrowRight' || key === 'End') dir.x = 1;else if (key === 'PageUp') dir.y = -5;else if (key === 'PageDown') dir.y = 5;
  return dir;
};
export const getNewActiveCell = table => {
  let cell = null;
  if (table.contains(document.activeElement)) {
    cell = getCell(document.activeElement);
  }
  return cell || getCell(getFirstFocusTarget(table));
};
export const focusCell = cell => {
  if (!cell) return;
  const focusableChildren = getFocusableChildren(cell);
  let activeCell = cell;
  if (focusableChildren.length) {
    activeCell = focusableChildren[0];
  }
  if (activeCell) activeCell.focus();
};
export const moveToBeginning = table => {
  const activeCell = getCell(getFirstFocusTarget(table));
  focusCell(activeCell);
  return activeCell;
};
export const moveToEnd = table => {
  const activeCell = getCell(getLastFocusTarget(table));
  focusCell(activeCell);
  return activeCell;
};
export const getElementByMoveDirection = (table, activeEl, dir, moveFullLength) => {
  let currentCell;
  if (!table.contains(activeEl)) {
    currentCell = getNewActiveCell(table);
  } else {
    currentCell = getCell(activeEl);
  }
  const rows = getRows(table);
  if (!rows.length) return null;
  const currentRow = closestAncestor(currentCell, 'tr');
  if (!currentRow) return null;
  let newRowIndex = rows.indexOf(currentRow) + dir.y;
  let newColIndex = getCells(currentRow).indexOf(currentCell) + dir.x;
  if (moveFullLength) {
    if (dir.y < 0) newRowIndex = 0;else if (dir.y > 0) newRowIndex = rows.length - 1;
  }
  const newRow = rows[clamp(newRowIndex, 0, rows.length - 1)];
  const cells = getCells(newRow);
  if (!cells.length) return null;
  if (moveFullLength) {
    if (dir.x < 0) newColIndex = 0;else if (dir.x > 0) newColIndex = cells.length - 1;
  }
  const newCell = cells[clamp(newColIndex, 0, cells.length - 1)];
  return newCell || null;
};
export const move = (table, activeEl, dir, moveFullLength) => {
  const newActiveCell = getElementByMoveDirection(table, activeEl, dir, moveFullLength);
  focusCell(newActiveCell);
  return newActiveCell;
};