import { useDraggableItemState } from '@clubhouse/shared/components/DragAndDrop';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function DragHandle(_ref) {
  let {
    isWorkspace
  } = _ref;
  const {
    dragHandleProps
  } = useDraggableItemState();
  return _jsx("a", {
    href: "#",
    className: `drag ${isWorkspace ? 'drag-org-within-company' : 'drag-org-or-company'}`,
    ...dragHandleProps,
    children: _jsx("span", {
      className: "fa fa-arrows"
    })
  });
}
DragHandle.displayName = "DragHandle";