import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import OrganizationModel from '../models/organization';
const exports = {};
exports.isTesting = () => {
  // This only gets turned to true when stubbed.
  return false;
};
exports.usesWrite = () => {
  return OrganizationModel.writeIsEnabled();
};
exports.usesTrueCustomFields = () => {
  return OrganizationModel.trueCustomFieldsIsEnabled();
};
exports.usesIterations = () => {
  return OrganizationModel.iterationsAreEnabled();
};
exports.usesOverlappingPrs = () => {
  return OrganizationModel.overlappingPRsIsEnabled();
};
exports.FEATURES = FEATURE_TOGGLES;
exports.usesFeature = feature => {
  return getFeatureToggle(feature);
};
export { exports as default };