import { getTeamScopedApplicationStateKey } from 'components/team-navigation';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { useAppState } from 'utils/appState';
const NS = 'iterationsPage';
const FILTER_KEY = `${NS}.iterationsFilter`;
export const useFilters = () => {
  const [filters, _setFilters] = useAppState({
    appStateKey: getTeamScopedApplicationStateKey(getTeamScopedCollectionizeId(), FILTER_KEY)
  });
  const query = filters?.query?.[0] || '';
  const statuses = filters?.statuses || [];
  const groupIds = filters?.group_ids || [];
  const showAssociated = filters?.show_associated !== false;
  return {
    filters: {
      query,
      statuses,
      groupIds,
      showAssociated
    },
    setFilters: newState => {
      _setFilters({
        ...filters,
        ...(typeof newState.query === 'string' ? {
          query: newState.query ? [newState.query] : undefined
        } : {}),
        ...(newState.statuses !== undefined ? {
          statuses: newState.statuses?.length ? newState.statuses : undefined
        } : {}),
        ...(newState.groupIds !== undefined ? {
          group_ids: newState.groupIds?.length ? newState.groupIds : undefined
        } : {}),
        ...(typeof newState.showAssociated === 'boolean' ? {
          show_associated: newState.showAssociated === true ? undefined : false
        } : {})
      });
    },
    clearFilters: () => {
      _setFilters({
        query: undefined,
        statuses: undefined,
        group_ids: undefined
      });
    }
  };
};