import { TableStateProvider, useUpdateTableConfiguration } from '@clubhouse/shared/components/Table/TableStateProvider';
import { useCavemanColumnConfig, useCavemanDisabledColumns, useCavemanOrderedColumns } from '../CavemanTableColumnSelect';
import { FIELD_ID } from '../table/types';
import { StoriesTable } from './StoriesTable';

// This file exists to glue the TableStateProvider to the hackier caveman column select.
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoriesTableWrapper = props => {
  const {
    allColumns
  } = useUpdateTableConfiguration();
  useCavemanColumnConfig('StoriesPage', allColumns);
  return _jsx(StoriesTable, {
    ...props
  });
};
StoriesTableWrapper.displayName = "StoriesTableWrapper";
const DEFAULT_DISABLED_COLUMNS = [FIELD_ID.REQUESTER];
export function StoriesPageTable(props) {
  const [disabledColumns, onChangeDisabledColumns] = useCavemanDisabledColumns('StoriesPage', DEFAULT_DISABLED_COLUMNS);
  const [orderedColumns, onChangeOrderedColumns] = useCavemanOrderedColumns('StoriesPage');
  return _jsx(TableStateProvider, {
    disabledColumns: disabledColumns,
    onChangeDisabledColumns: onChangeDisabledColumns,
    orderedColumns: orderedColumns,
    onChangeOrderedColumns: onChangeOrderedColumns,
    children: _jsx(StoriesTableWrapper, {
      ...props
    })
  });
}
StoriesPageTable.displayName = "StoriesPageTable";