import { useMemo } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
export const useLayoutState = () => {
  const [isReady, {
    on: setReadyState
  }] = useToggleState();
  return useMemo(() => ({
    isReady,
    setReadyState
  }), [isReady, setReadyState]);
};