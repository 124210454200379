import { useOnDocumentVisibilityChange } from '@clubhouse/shared/hooks';
import { useCallback } from 'react';
export const useRefetchWhenTabIsActive = _ref => {
  let {
    refetch,
    skip
  } = _ref;
  useOnDocumentVisibilityChange(useCallback(visibilityState => {
    if (skip) return;
    if (visibilityState === 'visible') refetch();
  }, [refetch, skip]));
};