import { useCallback } from 'react';
import { useDocumentVisibility } from '@clubhouse/shared/hooks/useDocumentVisibility';
import { trigger } from 'utils/event';
import { useBroadcastChannel } from './useBroadcastChannel';
import { useDataLayerLegacyUpdateEvent } from './useDataLayerLegacyUpdateEvent';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LegacyUpdatesBroadcaster = _ref => {
  let {
    children
  } = _ref;
  const isActive = useDocumentVisibility() === 'visible';
  const onMessage = useCallback(_ref2 => {
    let {
      type,
      data,
      dbTime
    } = _ref2;
    if (type === 'update') trigger('datalayer-update', {
      data,
      dbTime
    });else if (type === 'reset') trigger('datalayer-reset');
  }, []);
  const sendToBackgroundTabs = useBroadcastChannel({
    name: 'shortcut-sse-legacy',
    isBroadcaster: isActive,
    onMessage
  });
  useDataLayerLegacyUpdateEvent(data => {
    sendToBackgroundTabs(data);
  });
  return _jsx(_Fragment, {
    children: children
  });
};