import { Nouns } from '@clubhouse/shared/constants';
import { EntityPage } from 'pages/_components/EntityPage';
import { ArchivedMessageBar } from './components/ArchivedMessageBar';
import { Description } from './components/Description';
import { MoreActions } from './components/MoreActions';
import { Name } from './components/Name';
import { PinButton } from './components/PinButton';
import { ShareButton } from './components/ShareButton';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicEntityPage = _ref => {
  let {
    children,
    isLoading
  } = _ref;
  return _jsx(EntityPage, {
    isLoading: isLoading,
    type: Nouns.Epic.singular,
    children: children
  });
};
EpicEntityPage.displayName = "EpicEntityPage";
EpicEntityPage.ArchivedMessageBar = ArchivedMessageBar;
EpicEntityPage.Description = Description;
EpicEntityPage.MoreActions = MoreActions;
EpicEntityPage.Name = Name;
EpicEntityPage.PinButton = PinButton;
EpicEntityPage.ShareButton = ShareButton;