import noop from 'lodash/noop';
import { useCallback, useRef } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { useToggleState } from '@clubhouse/shared/hooks';
const DIALOGS = {
  archive: {
    body: 'Archiving this Story will exclude it from all reports and charts, as well as from the Stories page.',
    buttonKind: Button.KIND.PRIMARY,
    cta: 'Archive Story',
    onClick: noop,
    title: 'Are You Sure You Want To Archive This Story?'
  }
};
export function useConfirmationDialog() {
  const content = useRef(null);
  const [state, {
    on: toggleOn,
    off: toggleOff
  }] = useToggleState();
  const open = useCallback((type, props) => {
    content.current = {
      ...DIALOGS[type],
      ...props
    };
    toggleOn();
  }, [toggleOn]);
  return {
    content: content.current,
    isOpen: state,
    open,
    close: toggleOff
  };
}