import { useCallback, useEffect } from 'react';
import { useFilterContext } from 'components/gql/filters/FilterStateProvider';
import { useSearchParam } from 'utils/navigation';
export function useFilterState(config) {
  let {
    clearable = true
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    paramName
  } = config;
  const {
    param,
    setSearchParam
  } = useSearchParam(config);
  const {
    registerFilter,
    onChange
  } = useFilterContext();
  const handleChange = useCallback(newParam => {
    setSearchParam(newParam);
    onChange(paramName, newParam);
  }, [paramName, onChange, setSearchParam]);
  useEffect(() => registerFilter(config.paramName, {
    clearable,
    paramType: config.paramType
  }), [registerFilter, clearable, config.paramName, config.paramType]);
  return [param, handleChange];
}
export function useNonClearableFilterState(config) {
  return useFilterState(config, {
    clearable: false
  });
}