import { getTokens } from '@useshortcut/shapes-ds';
import { default as Color } from 'color';
import { COLORS } from './colors';
const tokens = getTokens('light');
export const lightTokens = tokens;
const textColors = {
  brightTextColor: COLORS.GRAY7,
  headerTextColor: COLORS.GRAY20,
  paragraphTextColor: COLORS.GRAY27,
  lightFadedTextColor: COLORS.GRAY49,
  disabledBrightTextColor: Color(COLORS.GRAY7).alpha(0.2).hsl().string(),
  fadedTextColor: COLORS.GRAY50,
  slightyFadedTextColor: COLORS.GRAY33,
  fadedTextHoverColor: COLORS.GRAY20,
  extremelyFadedTextColor: tokens.color.semantic.text.secondary,
  successTextColor: COLORS.GREEN38,
  errorTextColor: COLORS.RED23,
  alertTextColor: COLORS.YELLOW45,
  purpleTextColor: COLORS.PURPLE29,
  emphasisTextColor: COLORS.GRAY47,
  anchorColor: tokens.color.semantic.interactive.dark,
  anchorHoverColor: tokens.color.semantic.interactive.main,
  anchorPurpleColor: COLORS.PURPLE47,
  anchorPurpleHoverColor: Color(COLORS.PURPLE29).darken(0.15).hex(),
  anchorLightBlueColor: `#a3c5eb`,
  anchorLightBlueHoverColor: Color(`#a3c5eb`).darken(0.15).hex(),
  whiteTextColor: COLORS.WHITE
};
const iconColors = {
  disabledIconColor: COLORS.GRAY93,
  iconFadedColor: COLORS.GRAY87,
  iconGrayColor: COLORS.GRAY70,
  iconGrayMediumColor: COLORS.GRAY50,
  iconGrayDarkColor: COLORS.GRAY27,
  iconGrayHoverColor: COLORS.GRAY33,
  iconBlueColor: tokens.color.semantic.primary.main,
  iconFadedBlue: COLORS.BLUE82,
  iconOrangeColor: COLORS.ORANGE47,
  iconRedColor: COLORS.RED33,
  iconGreenColor: tokens.color.semantic.success.main,
  iconPurpleColor: COLORS.PURPLE29,
  iconYellowColor: COLORS.YELLOW45,
  iconWhiteColor: COLORS.WHITE,
  alertIconColor: COLORS.YELLOW82,
  labelYellow: COLORS.YELLOW45,
  iconFlagColor: COLORS.PURPLE47,
  iconFlagOutlined: COLORS.PURPLE75,
  iconFlagCheckered: COLORS.GRAY7,
  iconFlagCheckeredDarkBackground: COLORS.GRAY87,
  iconFlagColorDarkBackground: COLORS.PURPLE75
};
const borderColors = {
  hoverBorderColor: COLORS.GRAY60,
  mediumBorderColor: COLORS.GRAY87,
  strongerBorderColor: COLORS.GRAY70,
  bitStrongerBorderColor: COLORS.GRAY80,
  borderColor: COLORS.GRAY87,
  lightBorderColor: Color(COLORS.GRAY87).alpha(0.5).hsl().string(),
  focusedElementBorderColor: tokens.color.semantic.border.focus,
  errorBorderColor: COLORS.RED50,
  borderHorizontalRule: COLORS.GRAY50
};
const backgroundColors = {
  pageBackground: tokens.color.semantic.background.canvas,
  detailsPageBlockBackground: tokens.color.semantic.background.content,
  blockBackground: tokens.color.semantic.background.content,
  // content blocks on dashboard, status, epics, etc.
  blockHoverBackground: COLORS.GRAY95,
  accentBlueBackground: COLORS.BLUE93,
  accentBlueBackgroundBorder: Color(COLORS.BLUE93).darken(0.03).hex(),
  bluishBlockBackground: tokens.color.semantic.background.info,
  // milestone/iteration unstarted backgrounds
  greenishBlockBackground: tokens.color.semantic.background.success,
  // milestone/iteration completed backgrounds
  infoBackground: COLORS.BLUE97,
  errorBackground: COLORS.RED95,
  highlightBackground: COLORS.YELLOW92
};
const dropDown = {
  dropdownBackground: COLORS.WHITE,
  dropdownSecondaryContentBackground: COLORS.GRAY95,
  dropdownDisabledItemColor: Color(COLORS.BLACK).alpha(0.12).hsl().string()
};
const storiesPage = {
  storiesPageBackground: COLORS.WHITE,
  storiesPageSidebarBackground: COLORS.GRAY98,
  newStoriesPageBackground: COLORS.GRAY95,
  newStoriesPageSidebarBackground: COLORS.GRAY95,
  storiesPageSidebarHoverBackground: COLORS.GRAY95,
  storiesPageMobileSidebarBackground: COLORS.WHITE,
  columnStoryCountBackgroundColor: COLORS.GRAY95,
  completedColumnDateBackgroundColor: tokens.color.semantic.background.tertiary,
  completedColumnDateTextColor: tokens.color.semantic.text.main,
  storiesPageSidebarHeaderHoverBackgroundColor: COLORS.GRAY93,
  storiesPageSidebarListItemTextColor: COLORS.GRAY20,
  storiesPageSidebarListItemHoverBackgroundColor: COLORS.GRAY93,
  storiesPageSidebarListItemHoverTextColor: COLORS.GRAY27,
  storiesPageSidebarActiveSpaceTextColor: COLORS.PURPLE29,
  storiesPageSidebarActiveFilterBackgroundColor: COLORS.PURPLE93,
  storiesPageScrollbar: COLORS.GRAY98,
  storiesPageScrollbarThumb: COLORS.GRAY70,
  storiesPageColumnBucketScrollbarThumb: COLORS.GRAY87,
  storiesPageColumnBucketScrollbarThumbHover: COLORS.GRAY70,
  storiesPageColumnBucketScrollbarThumbBorder: COLORS.WHITE
};
const pageLayout = {
  blockQuoteBackgroundColor: COLORS.YELLOW92,
  searchBackgroundColor: tokens.color.semantic.background.content,
  searchActiveBackgroundColor: tokens.color.semantic.background.elevatedSecondary,
  topNavBackgroundColor: COLORS.PURPLE17,
  topNavLogoBackgroundColor: COLORS.PURPLE17,
  topNavLinkBackgroundColor: Color(COLORS.PURPLE17).lighten(0.12).hex(),
  topNavLinkHoverBackgroundColor: Color(COLORS.PURPLE17).lighten(0.25).hex(),
  topNavBorderColor: Color(COLORS.PURPLE17).lighten(0.25).hex(),
  leftNavActiveBackgroundColor: tokens.color.semantic.navigation.interactive,
  headerLinkColor: `#7e7b8a`,
  navBackgroundColor: tokens.color.semantic.navigation.background,
  navTrialBackgroundColor: COLORS.PURPLE29,
  navLinkColor: Color(COLORS.WHITE).alpha(0.6).hsl().string(),
  navLinkIconColor: Color(COLORS.WHITE).alpha(0.3).hsl().string(),
  navBadgeBackgroundColor: `#ff5555`,
  profilePopoverBackground: tokens.color.semantic.navigation.tertiaryBackground
};
const progressSteps = {
  progressStepBackgroundColor: COLORS.DURPLE,
  progressStepFinishedIconColor: COLORS.WHITE,
  progressStepFinishedIconBackgroundColor: COLORS.DUSTYBLUE62,
  progressStepTextColor: COLORS.WHITE
};
const notifications = {
  notificationCompletedBackgroundColorPulse: tokens.color.semantic.info.light
};
const inline = {
  inlineValidationErrorBackground: COLORS.RED95,
  inlineValidationErrorBoxShadowColor: Color(COLORS.RED95).darken(0.05).hex(),
  inlineValidationErrorTextColor: COLORS.RED23,
  inlineValidationErrorPlainTextColor: COLORS.RED50
};
const progressBars = {
  progressBarBackground: tokens.color.raw.gray40,
  progressBarCompletedColor: tokens.color.raw.green80,
  progressBarStartedColor: tokens.color.raw.green60,
  progressBarUnstartedColor: tokens.color.raw.gray40,
  progressBarLabelColor: COLORS.GREEN23,
  progressBarArchivedColor: tokens.color.raw.gray40
};
const forms = {
  formFieldBackgroundColor: COLORS.WHITE,
  formFieldDisabledBackgroundColor: COLORS.GRAY95,
  formFieldNoteBackgroundColor: COLORS.GRAY98,
  formFieldReadOnlyBackgroundColor: COLORS.GRAY95
};
const entityForm = {
  createEditorRightSideBackgroundColor: COLORS.GRAY95,
  createEditorLeftSideBackgroundColor: COLORS.GRAY98
};
const messages = {
  messageIconColor: Color(COLORS.WHITE).alpha(0.8).hsl().string(),
  formHelpMessageBackground: tokens.color.semantic.info.light,
  formHelpMessageBorder: null,
  formHelpSuccessColor: Color(COLORS.GREEN23).darken(0.03).hex(),
  formHelpSuccessBackgroundColor: Color(COLORS.GREEN97).darken(0.03).hex(),
  formHelpSuccessBorder: null,
  formHelpWarningBackgroundColor: COLORS.YELLOW92,
  formHelpWarningBorder: null,
  formHelpPraiseBackground: COLORS.RED95,
  formHelpPraiseBorder: null,
  formHelpReferralBackground: Color(COLORS.PURPLE75).alpha(0.3).hsl().string(),
  formHelpReferralBorder: null,
  formHelpTrainingBackground: null,
  formHelpTrainingBorder: null
};
const buttons = {
  brightButtonTextColor: COLORS.WHITE,
  activeButtonTextColor: COLORS.WHITE,
  greenButtonBackgroundColor: COLORS.GREEN38,
  lightGrayButtonBackgroundColor: tokens.color.semantic.background.content,
  lightGrayButtonTextColor: COLORS.GRAY27,
  lightGrayButtonHoverBackgroundColor: tokens.color.semantic.background.canvas,
  lightGrayButtonHoverTextColor: COLORS.GRAY13,
  lightGrayButtonActiveBackgroundColor: tokens.color.semantic.background.tertiary,
  whiteButtonBackgroundColor: COLORS.GRAY95,
  flatWhiteButtonHoverBackgroundColor: COLORS.WHITE,
  flatButtonActiveBackgroundColor: COLORS.GRAY50,
  borderlessButtonBackgroundColor: COLORS.GRAY95,
  disabledButtonBackgroundColor: Color(COLORS.BLACK).alpha(0.12).hsl().string(),
  brightWhiteButtonBackgroundColor: tokens.color.semantic.background.content,
  brightWhiteButtonHoverBackgroundColor: COLORS.WHITE,
  googleLoginBackground: COLORS.WHITE,
  googleLoginHoverBackground: COLORS.GRAY98,
  googleLoginActiveBackground: COLORS.GRAY95,
  blueButtonBackground: COLORS.BLUE39,
  dustyBlueButtonBackground: COLORS.DUSTYBLUE51,
  dustyBlueButtonHoverBackground: COLORS.DUSTYBLUE41,
  grayBlueButtonColor: COLORS.BLUE39,
  grayBlueButtonBackground: COLORS.BLUE88,
  grayBlueButtonHoverBackground: Color(COLORS.BLUE88).darken(0.05).hex(),
  grayBlueButtonActiveBackground: Color(COLORS.BLUE88).darken(0.1).hex(),
  lightBlueButtonBackground: COLORS.BLUE64,
  lightBlueButtonHoverBackground: Color(COLORS.BLUE64).darken(0.05).hex(),
  lightBlueButtonActiveBackground: Color(COLORS.BLUE64).darken(0.1).hex(),
  redButtonBackground: COLORS.RED33,
  redButtonHoverBackground: COLORS.RED23,
  redButtonActiveBackground: Color(COLORS.RED23).darken(0.1).hex(),
  toggleButtonGutterBackground: COLORS.GRAY95,
  toggleButtonGutterActiveBackground: COLORS.GREEN67
};
const signup = {
  collaborateIconEnabledColor: COLORS.EMERALDGREEN60,
  collaborateIconDisabledColor: COLORS.WHITE
};
const scrollBars = {
  scrollbarColor: COLORS.GRAY93,
  scrollbarThumbColor: COLORS.GRAY80,
  scrollbarThumbColorElevated: COLORS.GRAY80,
  scrollbarThumbHoverColor: COLORS.GRAY60
};
const stories = {
  storyAutoLinkBackgroundColor: tokens.color.semantic.background.tertiary,
  storyAutoLinkArchivedBackgroundColor: tokens.color.semantic.background.tertiary,
  storyAutoLinkFeatureTextColor: tokens.color.raw.yellow110,
  storyAutoLinkFeatureBackgroundColor: tokens.color.raw.yellow40,
  storyAutoLinkChoreTextColor: tokens.color.raw.slate40,
  storyAutoLinkChoreBackgroundColor: tokens.color.raw.gray30,
  storyAutoLinkBugTextColor: tokens.color.raw.red100,
  storyAutoLinkBugBackgroundColor: tokens.color.raw.red20,
  storyAutoLinkCompleteColor: tokens.color.semantic.success.main,
  storyTextColor: COLORS.GRAY20,
  storyDialogSidebarBackground: COLORS.GRAY95,
  storyDialogArchiveBannerBackground: COLORS.WHITE,
  storyBadgeBackgroundColor: Color(COLORS.BLACK).alpha(0.05).hsl().string(),
  storyBadgeAnnotationBackgroundColor: Color(COLORS.BLACK).alpha(0.1).hsl().string(),
  projectBadgeBackground: COLORS.WHITE,
  projectBadgeBorder: COLORS.GRAY93,
  projectBadgeText: COLORS.GRAY50,
  commentBoxBackgroundColor: COLORS.WHITE,
  commentBoxBorder: null,
  //1px solid #ddd;
  commentBoxGhostTextColor: COLORS.GRAY50,
  highlightedAnchorBackgroundColor: COLORS.YELLOW92,
  storyAttributeHoverBackgroundColor: COLORS.WHITE,
  //  const storyBorderLeftWidth = 5px;

  storyEntityTitleLinkColor: COLORS.GRAY20,
  storyEntityTitleLinkHoverColor: COLORS.GRAY7,
  blockerBackgroundColor: COLORS.GRAY70,
  storyBackground: tokens.color.semantic.background.content,
  storyHover: tokens.color.semantic.background.content
};
const miniKanbanGrid = {
  miniKanbanColor: COLORS.WHITE,
  miniKanbanHoverColor: COLORS.GRAY98
};
const tabs = {
  tabBackgroundColor: COLORS.GRAY95,
  tabHoverBackgroundColor: COLORS.GRAY98,
  tabActiveBackgroundColor: COLORS.GRAY87,
  tabCountBackgroundColor: COLORS.GRAY87,
  tabActiveHoverBackgroundColor: COLORS.GRAY80
};
const tags = {
  tagBackgroundColor: COLORS.WHITE,
  tagTextColor: COLORS.GRAY20
};
const table = {
  tableCellBackgroundColor: COLORS.GRAY98,
  tableCellHoverBackgroundColor: COLORS.WHITE,
  tableCellHeaderBackgroundColor: tokens.color.semantic.background.canvas,
  tableCellHeaderHoverBackgroundColor: COLORS.GRAY80,
  tableCellActiveHeaderBackgroundColor: COLORS.GRAY70,
  tableCellActiveHoverHeaderBackgroundColor: Color(COLORS.GRAY70).darken(0.08).hex(),
  tableCellActiveBackgroundColor: COLORS.WHITE,
  tableCellSelectedBackgroundColor: COLORS.YELLOW92,
  tableCellSelectedActiveBackgroundColor: Color(COLORS.YELLOW92).lighten(0.05).hex(),
  tableCellSelectedHoverBackgroundColor: Color(COLORS.YELLOW92).lighten(0.05).hex(),
  tableCellSelectedActiveHoverBackgroundColor: COLORS.WHITE,
  tableCellPlaceholderBackgroundColor: COLORS.GRAY95 // placeholder during drag-and-drop
};
const listView = {
  listViewItemBackgroundHover: COLORS.GRAY98,
  listViewArchivedItemBackground: COLORS.GRAY95
};
const modalDialog = {
  modalDialogOverlayBackground: Color(COLORS.BLACK).alpha(0.5).hsl().string(),
  modalDialogBackgroundColor: tokens.color.semantic.background.canvas,
  modalDialogContentBackgroundAccent: COLORS.WHITE
};
const overlay = {
  overlayText: COLORS.WHITE,
  overlayHover: Color(COLORS.BLACK).alpha(0.5).hsl().string()
};
const multiSelect = {
  multiSelectDropdownSelectedItemBackground: COLORS.GREEN38,
  multiSelectDropdownSelectedItemBackgroundActive: COLORS.GREEN23,
  multiSelectDropdownSelectedItemColor: COLORS.WHITE
};
const search = {
  navSearchActiveTextColor: COLORS.PURPLE29,
  navSearchResultCountBackground: COLORS.GRAY95
};
const epics = {
  epicGuidanceBackgroundColor: COLORS.YELLOW92
};
const datepicker = {
  datepickerPaginationBackgroundColor: COLORS.GRAY98,
  datepickerPaginationHoverBackgroundColor: tokens.color.semantic.interactive.hover,
  datepickerCellBackgroundColor: COLORS.GRAY95
};
const tooltip = {
  tooltipFadedContentsColor: COLORS.GRAY93,
  tooltipContentsColor: COLORS.GRAY87,
  tooltipBrightText: COLORS.WHITE
};
const textDiff = {
  textDiffDelBackground: Color(COLORS.RED95).lighten(0.04).hex(),
  textDiffDelBorder: COLORS.RED77,
  textDiffDelSpanBackground: Color(COLORS.RED95).darken(0.04).hex(),
  textDiffDelSpanText: COLORS.RED50,
  textDiffInsBackground: COLORS.GREEN97,
  textDiffInsBorder: COLORS.GREEN67,
  textDiffInsSpanBackground: COLORS.GREEN88,
  textDiffInsSpanText: COLORS.GREEN23
};
const avatars = {
  avatarInitials: COLORS.WHITE,
  avatarBackground: COLORS.WHITE,
  avatarRed33: Color(COLORS.RED33).alpha(0.7).hsl().string(),
  avatarOrange47: Color(COLORS.ORANGE47).alpha(0.7).hsl().string(),
  avatarYellow45: Color(COLORS.YELLOW45).alpha(0.7).hsl().string(),
  avatarGreen23: Color(COLORS.GREEN23).alpha(0.7).hsl().string(),
  avatarGreen38: Color(COLORS.GREEN38).alpha(0.7).hsl().string(),
  avatarBlue39: Color(COLORS.BLUE39).alpha(0.7).hsl().string(),
  avatarBlue64: Color(COLORS.BLUE64).alpha(0.7).hsl().string(),
  avatarPurple47: Color(COLORS.PURPLE47).alpha(0.7).hsl().string(),
  avatarPurple75: Color(COLORS.PURPLE75).alpha(0.7).hsl().string(),
  avatarGray50: Color(COLORS.GRAY50).alpha(0.7).hsl().string(),
  avatarGray7: Color(COLORS.GRAY7).alpha(0.7).hsl().string()
};
const loginPage = {
  loginPageBackground: COLORS.WHITE
};
const shimmer = {
  shimmerBackgroundOne: COLORS.GRAY98,
  shimmerBackgroundTwo: COLORS.GRAY93
};
const addReaction = {
  addReactionBackgroundColor: COLORS.WHITE,
  addReactionHoverBackgroundColor: COLORS.WHITE
};
const images = {
  invertedImageFilter: null
};
const reports = {
  unestimatedStoriesWarning: COLORS.YELLOW92,
  reportFeature: tokens.color.raw.yellow80,
  reportBug: tokens.color.raw.red70,
  reportChore: tokens.color.raw.blue60
};
const tagged = {
  taggedTextColor: COLORS.WHITE,
  taggedBackground: COLORS.GRAY70,
  taggedBlue: COLORS.BLUE64,
  taggedGreen: COLORS.GREEN38,
  taggedOrange: COLORS.ORANGE47,
  taggedGray: COLORS.GRAY60,
  taggedLightGray: COLORS.GRAY80,
  taggedPurple: COLORS.PURPLE29,
  taggedLightPurple: COLORS.PURPLE75,
  taggedPurpleDefault: COLORS.PURPLE47,
  taggedDarkDirtyPurple: '#48485C'
};
const badges = {
  mentionNameBadgeBackground: COLORS.GREEN30,
  mentionNameBadgeColor: COLORS.GREEN67
};
const write = {
  writeBackgroundColor: tokens.color.semantic.background.content,
  writeBorderColor: COLORS.GRAY93,
  writeMediumBorderColor: COLORS.GRAY87,
  writeStrongBorderColor: borderColors.strongerBorderColor,
  writeHeading: COLORS.GRAY20,
  writeSubText: COLORS.GRAY60,
  writePurpleSubText: COLORS.GRAY50,
  writeDocSidebarBackground: COLORS.GRAY97,
  writeActiveColor: COLORS.PURPLE47,
  writeActiveBackgroundColor: COLORS.PURPLE47,
  writeActiveTextColor: COLORS.WHITE,
  writePrimaryIconFill: COLORS.GRAY50,
  writeSecondaryIconFill: COLORS.GRAY70,
  writeGrayInfoBackgroundColor: COLORS.GRAY95,
  writeCaptionBackgroundColor: COLORS.WHITE,
  writeCaptionTextColor: COLORS.GRAY50,
  writeCommentBackgroundColor: COLORS.WHITE,
  writeCommentRemoveBackgroundColor: COLORS.GRAY95,
  writeCommentMetaData: COLORS.GRAY40,
  writeCommentHighlightBackground: 'transparent',
  writeCommentHighlightBackgroundHover: COLORS.YELLOW92,
  writeCommentHighlightBackgroundActive: COLORS.YELLOW82,
  writeCommentHighlightBorder: COLORS.YELLOW68,
  writeCommentHighlightBorderActive: COLORS.YELLOW68,
  writeCommentHighlightText: textColors.paragraphTextColor,
  writeCommentHighlightTextActive: textColors.paragraphTextColor,
  writeCommentActiveBorder: COLORS.GRAY87,
  writeCommentActiveSideBorder: COLORS.YELLOW68,
  writeGrayButtonBackgroundColor: buttons.lightGrayButtonBackgroundColor,
  writeGrayButtonTextColor: COLORS.GRAY50,
  writePurpleButtonBackgroundColor: COLORS.PURPLE97,
  writePurpleButtonTextColor: COLORS.PURPLE47,
  writeBuildLinkTextColor: COLORS.PURPLE47,
  writeAvatarBackground: COLORS.WHITE,
  writeGreen: COLORS.GREEN30,
  writeDelete: COLORS.RED95,
  writeCopyUrlColor: COLORS.PURPLE47
};
const registration = {
  registrationPrimaryTextColor: COLORS.GRAY26,
  registrationSecondaryTextColor: COLORS.GRAY49,
  registrationPrimaryBackground: COLORS.WHITE,
  registrationSecondaryBackground: COLORS.WHITE
};
export const LIGHT = {
  ...avatars,
  ...textColors,
  ...iconColors,
  ...badges,
  ...borderColors,
  ...backgroundColors,
  ...pageLayout,
  ...notifications,
  ...progressSteps,
  ...inline,
  ...progressBars,
  ...forms,
  ...entityForm,
  ...messages,
  ...buttons,
  ...signup,
  ...scrollBars,
  ...storiesPage,
  ...stories,
  ...miniKanbanGrid,
  ...tabs,
  ...tags,
  ...table,
  ...modalDialog,
  ...dropDown,
  ...overlay,
  ...multiSelect,
  ...search,
  ...epics,
  ...datepicker,
  ...tooltip,
  ...textDiff,
  ...loginPage,
  ...listView,
  ...shimmer,
  ...addReaction,
  ...images,
  ...reports,
  ...tagged,
  ...write,
  ...registration
};