import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useToggleState } from '@clubhouse/shared/hooks';
import { fetchUserWithoutRedirect } from 'data/entity/user';
import { logError } from 'utils/monitoring';
import { redirect, usePageParams } from 'utils/navigation';
import { PAGE_NAMES, getPathForPage } from 'utils/navigation/paths';
export const useSignupBoot = () => {
  const params = usePageParams();
  const [ready, {
    on: moveToNextStep
  }] = useToggleState(false);
  useEffect(() => {
    const fn = async () => {
      try {
        const currentUser = await fetchUserWithoutRedirect();
        if (params.signupId) {
          moveToNextStep();
        } else if (currentUser) {
          // Redirect so that this can be handled by CreateOrgOrWorkspaceController
          redirect(generatePath(getPathForPage(PAGE_NAMES.CREATE_ORGANIZATION)));
        } else {
          // You have to be logged in to use signup without a signup id.
          const {
            pathname,
            search,
            hash
          } = window.location;
          redirect(`/login?dest=${encodeURIComponent(pathname + search + hash)}`);
        }
      } catch (err) {
        if (err instanceof Error) {
          logError(err, params.signupId ? {
            signupId: params.signupId
          } : {});
        }
        moveToNextStep();
      }
    };
    if (!ready) fn();
  }, [moveToNextStep, params.signupId, ready]);
  return {
    ready
  };
};