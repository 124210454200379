import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TableText = _ref => {
  let {
    text
  } = _ref;
  return _jsx(Text, {
    textAlign: "center",
    size: Text.SIZE.SMALL,
    lineClamp: 2,
    children: text || '-'
  });
};
TableText.displayName = "TableText";