import { StyledMenu } from './styledComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DefaultMenuComponent = _ref => {
  let {
    getMenuProps,
    children
  } = _ref;
  const {
    menuRef,
    ...menuProps
  } = getMenuProps({
    refKey: 'menuRef'
  });
  return _jsx(StyledMenu, {
    ref: menuRef,
    ...menuProps,
    children: children
  });
};
DefaultMenuComponent.displayName = "DefaultMenuComponent";