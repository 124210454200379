import Backend from './backend';
import EpicModel from '../models/epic';
import StoryModel from '../models/story';
import IterationModel from '../models/iteration';
import MilestoneModel from '../models/milestone';
const exports = {};
exports.elasticSearch = (baseURL, query, options) => new Promise((resolve, reject) => {
  const defaults = {
    pageSize: 25,
    maxPages: 4 // 100 results
  };
  options = _.assignIn({}, defaults, options);
  options.url = options.url || `${baseURL}?detail=slim&page_size=${options.pageSize}&query=${encodeURIComponent(query)}`;
  options.pageCount = options.pageCount || 0;
  Backend.get(options.url, {
    onComplete: (res, xhr) => {
      const next = xhr.status === 200 ? resolve : reject;
      next(res);
    }
  });
});
exports.elasticSearchStories = function (query) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  let options = arguments.length > 2 ? arguments[2] : undefined;
  return exports.elasticSearch('/api/private/search/stories', query, options).then(res => {
    if (_isValidResponse(res)) {
      StoryModel.updateMany(res.data);
    }
    callback(null, res);
    return res;
  }).catch(res => {
    StoryModel.defaultErrorHandler(res, callback);
    return res;
  });
};
exports.elasticSearchEpics = function (query) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  let options = arguments.length > 2 ? arguments[2] : undefined;
  return exports.elasticSearch('/api/private/search/epics', query, options).then(res => {
    callback(null, res);
    return res;
  }).catch(res => {
    EpicModel.defaultErrorHandler(res, callback);
    return res;
  });
};
exports.elasticSearchIterations = function (query) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  let options = arguments.length > 2 ? arguments[2] : undefined;
  return exports.elasticSearch('/api/private/search/iterations', query, options).then(res => {
    callback(null, res);
    return res;
  }).catch(res => {
    IterationModel.defaultErrorHandler(res, callback);
    return res;
  });
};
exports.elasticSearchObjectives = function (query) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  let options = arguments.length > 2 ? arguments[2] : undefined;
  return exports.elasticSearch('/api/private/search/milestones', query, options).then(res => {
    callback(null, res);
    return res;
  }).catch(res => {
    MilestoneModel.defaultErrorHandler(res, callback);
    return res;
  });
};
const _isValidResponse = res => _.isPlainObject(res) && _.isArray(res.data) && _.isNumber(res.total);
exports.getLoadMoreButton = function (nextUrl) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!nextUrl) {
    return;
  }
  const clickHandler = _getClickHandlerName(options);
  return `
      <div class="load-more-search-results-button">
        <a
          href="#"
          class="action mini flat-white"
          ${options.attributes || ''}
          data-on-click="${clickHandler}"
          data-next-url="${nextUrl}">Load more...</a>
      </div>`;
};
const _getClickHandlerName = options => {
  if (options.isDropdown) {
    return 'loadMoreSearchResultsIntoDropdown';
  }
  if (options.isQuickSearch) {
    return 'loadMoreSearchResultsIntoQuickSearch';
  }
  return 'loadMoreSearchResultsIntoAutocomplete';
};
export default exports;