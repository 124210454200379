const NS = '.PasswordStrength'; // Event namespace.

export const init = async context => {
  const {
    default: zxcvbn
  } = await import(/* webpackChunkName: "zxcvbn" */'zxcvbn');
  const checker = () => {
    const password = $(context).val();
    const results = zxcvbn(`${password}`);
    $(context).closest('.password-strength-container').find('.password-strength').removeClass('score-0 score-1 score-2 score-3 score-4').addClass('score-' + results.score);
  };
  $(context).on('keyup' + NS + ' blur' + NS, checker);
  return checker;
};
export const destroy = context => {
  $(context).off(NS);
};