import Utils from 'app/client/core/js/modules/utils';

/**
 * Zendesk => Shortcut
 */

export const allowZendeskAuthorization = params => {
  Utils.redirect('/backend/oauth-authorization-code-flow/code' + params);
};
export const denyZendeskAuthorization = redirectUri => {
  Utils.redirect(`${redirectUri}?error=access_denied`);
};