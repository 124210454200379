import { useCallback } from 'react';
import { QueryEpicsTableRowsDocument } from '@clubhouse/datalayer/types';
import { TableRow, TableRowForInfiniteScroll } from '@clubhouse/shared/components/Table';
import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { useQueryFunctions } from 'gql/hooks/UseQueryContext';
import { useEpicReordering } from 'pages/epics-apollo/entities/epics/hooks/useEpicReordering'; // eslint-disable-line import/no-restricted-paths
import { useEpicContextMenu } from 'utils/contextMenus/useEpicContextMenu';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicRow = props => {
  const contextMenuProps = useEpicContextMenu(props.item.id);
  return _jsx(TableRow, {
    ...props,
    contextMenuProps: contextMenuProps
  });
};
EpicRow.displayName = "EpicRow";
export const EpicRowForInfiniteScroll = props => {
  const contextMenuProps = useEpicContextMenu(props.item.id);
  return _jsx(TableRowForInfiniteScroll, {
    ...props,
    contextMenuProps: contextMenuProps
  });
};
EpicRowForInfiniteScroll.displayName = "EpicRowForInfiniteScroll";
export function EpicsTable(_ref) {
  let {
    epics,
    columns,
    isLoading,
    isStale,
    footer,
    sortColumn,
    sortDirection,
    fixedBottomRow,
    infiniteScroll,
    onSort,
    updateQuery
  } = _ref;
  const {
    cancel,
    restart
  } = useQueryFunctions();
  const onReorder = useEpicReordering({
    epics,
    sortDirection,
    updateQuery
  });
  const cancelQuery = useCallback(() => {
    cancel([QueryEpicsTableRowsDocument]);
  }, [cancel]);
  const restartQuery = useCallback(() => {
    restart([QueryEpicsTableRowsDocument]);
  }, [restart]);
  const handleReorder = useCallback(async change => {
    await onReorder(change);
    restartQuery();
  }, [onReorder, restartQuery]);
  return _jsxs(_Fragment, {
    children: [_jsx(StatelessTable, {
      allItems: epics,
      columns: columns,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      onSort: onSort,
      onReorder: handleReorder,
      onStartReorder: cancelQuery,
      onCancelReorder: restartQuery,
      items: epics,
      disableHeader: false,
      footer: footer,
      RowComponent: infiniteScroll ? EpicRowForInfiniteScroll : EpicRow,
      fixedBottomRow: fixedBottomRow,
      ...(isLoading && LOADING_STATE)
    }), isStale && _jsx(StaleLoading, {})]
  });
}