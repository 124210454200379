import { MutateEpicDeleteDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { DeleteEpicFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
export const DeleteEpicFragment = DeleteEpicFragmentFragmentDoc;
const MUTATE_EPIC_DELETE = MutateEpicDeleteDocument;
export function useDeleteEpicMutation() {
  const [deleteEpic] = useMutation(MUTATE_EPIC_DELETE);
  return useCallback(epic => deleteEpic({
    variables: {
      id: epic.id
    }
  }), [deleteEpic]);
}