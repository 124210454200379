import { QueryMiniKanbanStoriesDataDocument } from "../../../../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQueryWithPolling } from 'gql';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
const QUERY_MINI_KANBAN_STORIES_DATA = QueryMiniKanbanStoriesDataDocument;
export const useMiniKanbanStoriesQuery = (epicId, options) => {
  const {
    error,
    loading,
    data,
    previousData,
    refetch
  } = useQueryWithPolling(QUERY_MINI_KANBAN_STORIES_DATA, {
    variables: {
      epicId
    },
    skip: options?.skip || false,
    pollInterval: 1e4
  });
  useDataLayerUpdateEvent(() => refetch(), {
    filter: ['Story']
  });
  const epic = (data || previousData)?.node;
  const stories = (epic?.stories?.edges || []).map(_ref => {
    let {
      node
    } = _ref;
    return node;
  });
  return {
    error,
    loading,
    stories,
    pageInfo: epic?.stories?.pageInfo || null,
    refetch
  };
};