import Mousetrap from 'mousetrap';
import { DashboardPage } from 'components/dashboard/DashboardPage';
import * as Event from '../../../core/js/_frontloader/event';
import Router from '../../../core/js/_frontloader/router';
import AddNewStoryController from '../../../core/js/controllers/addNewStory';
import NotificationsController from '../../../core/js/controllers/notifications';
import StoryModel from '../../../core/js/models/story';
import UserModel from '../../../core/js/models/user';
import Is from '../../../core/js/modules/is';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
const exports = {};
exports.title = `My Work | ${BRAND.NAME}`;
Router.addPageTitle('dashboard', exports.title);
exports.route = () => {
  return Url.getSlugPath() + '/dashboard';
};
exports.init = () => {
  StoryModel.on('storySaved latestVersionFetched newStorySaved bulkStoriesUpdated', () => renderActivityFeed());
  Event.on('storyDeleted', () => renderActivityFeed());
  if (!Is.readOnly(UserModel.getLoggedInUserPermission())) {
    Mousetrap.bind('n', AddNewStoryController.render);
  }
};
exports.render = () => {
  View.renderComponentDelayed({
    componentKey: 'dashboard-page',
    mountNode: document.getElementById('dashboard'),
    component: DashboardPage,
    props: {
      onRenderActivityFeed: el => renderActivityFeed(el)
    }
  });
  Event.trigger('pageRendered', 'Dashboard');
};
let el = null;
const renderActivityFeed = _el => {
  if (_el) el = _el;
  if (el) {
    let feedEl = el.querySelector('[data-notification-feed]');
    const {
      scrollTop,
      scrollHeight: scrollHeightBefore
    } = feedEl || {};
    el.innerHTML = NotificationsController.renderFeedTemplate({
      showButton: true
    });
    feedEl = el.querySelector('[data-notification-feed]');
    if (feedEl) {
      const {
        scrollHeight
      } = feedEl;
      const diff = scrollHeight - (scrollHeightBefore ?? scrollHeight);
      feedEl.scrollTop = scrollTop + diff;
    }
  }
};
Router.add(exports.route, exports.title, exports.render);
export { exports as default };