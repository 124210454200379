import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
const exports = {};
Collection.create('Feature', exports);
exports.fetchAll = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  // Handle when wrapped with promisify and receive arguments
  const callback = args.pop();
  Backend.get('/api/private/features', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
const update = (options, callback) => {
  const {
    id,
    verb,
    actionContext
  } = options;
  Backend.put(`/api/private/features/${id}/${verb}`, {
    actionContext,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.defaultGetHandler(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.enable = (options, callback) => {
  update({
    ...options,
    verb: 'enable'
  }, callback);
};
exports.disable = (options, callback) => {
  update({
    ...options,
    verb: 'disable'
  }, callback);
};
exports.isFeatureEnabled = id => Boolean(exports.get({
  id,
  on: true
}));
exports.isProjectsFeatureEnabled = () => exports.isFeatureEnabled('projects');
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.enable = BaseUtils.promisify(exports.enable);
exports.Promises.disable = BaseUtils.promisify(exports.disable);
export { exports as default };