import { FilterField } from '@clubhouse/shared/components/FilterField';
import { useNonClearableFilterState } from 'components/gql/filters/hooks/useFilterState';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { GroupBySelect } from 'components/shared/table/GroupBySelect';
import { GROUP_BY, useGroupByOptions } from 'components/shared/table/GroupBySelect/epics';
import { useTeamScopeId } from 'components/team-navigation';
import { useObjectivesEnabledForCurrentOrg } from 'data/entity/organization';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicsGroupByConfig = createSearchParamConfig({
  paramName: 'grouped_by',
  paramType: 'string',
  defaultValue: GROUP_BY.NONE,
  validValues: ['groupId', 'epicState', 'milestone', 'objective']
});
export function EpicsGroupBySelect() {
  const [groupBy, setGroupBy] = useNonClearableFilterState(EpicsGroupByConfig);
  const {
    value: teamId
  } = useTeamScopeId();
  const hasTeamScope = Boolean(teamId);
  const usesObjectives = useObjectivesEnabledForCurrentOrg();
  const groupByOptions = useGroupByOptions({
    hasTeamScope,
    usesObjectives
  });
  return _jsx(FilterField, {
    label: "Group By",
    children: _jsx(GroupBySelect, {
      items: groupByOptions,
      value: groupBy,
      onChange: setGroupBy
    })
  });
}
EpicsGroupBySelect.displayName = "EpicsGroupBySelect";