import { CustomFieldsSelect } from '@clubhouse/shared/components/CustomFieldsSelect';
import { useCustomFields } from 'data/entity/customField';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CustomFieldsFilter = _ref => {
  let {
    selectedValues = [],
    onChange
  } = _ref;
  const {
    fields
  } = useCustomFields({
    filter: _ref2 => {
      let {
        enabled
      } = _ref2;
      return enabled;
    }
  });
  return _jsx(CustomFieldsSelect, {
    selectedValues,
    onChange,
    fields
  });
};
CustomFieldsFilter.displayName = "CustomFieldsFilter";