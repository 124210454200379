import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ObjectiveStateDisplay } from './ObjectiveStateDisplay';
import { ObjectiveStateIcon } from './ObjectiveStateIcon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ObjectiveStateIconDisplay = _ref => {
  let {
    state
  } = _ref;
  return _jsxs(Spaced, {
    horizontally: true,
    amount: "space1",
    align: "center",
    inline: true,
    children: [_jsx(ObjectiveStateIcon, {
      state: state
    }), _jsx("strong", {
      children: _jsx(ObjectiveStateDisplay, {
        state: state
      })
    })]
  });
};
ObjectiveStateIconDisplay.displayName = "ObjectiveStateIconDisplay";