import { Field as FinalField } from 'react-final-form';
import { Form } from 'components/shared/Form';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FieldWrapper = _ref => {
  let {
    /** When true, the label appears in the DOM after the field component. */
    labelFollowsField = false,
    shouldForceErrors,
    labelProps,
    Component,
    ...finalFieldProps
  } = _ref;
  return _jsx(FinalField, {
    ...finalFieldProps,
    children: _ref2 => {
      let {
        input,
        meta
      } = _ref2;
      const label = labelProps ? _jsx(Form.Label, {
        helpText: labelProps.helpText,
        htmlFor: input.name,
        children: labelProps.children
      }) : null;
      const formComponent = _jsx(Component, {
        input,
        meta,
        id: input.name
      });
      const [first, second] = labelFollowsField ? [formComponent, label] : [label, formComponent];
      let shouldShowError = Boolean(meta.error);
      if (meta.error?.includes('required')) {
        shouldShowError = Boolean(meta.dirty || meta.touched);
      }
      return _jsxs(Form.Field, {
        children: [first, second, shouldShowError && _jsx(Form.Errors, {
          children: _jsx(Form.Error, {
            children: meta.error
          })
        })]
      });
    }
  });
};
FieldWrapper.displayName = "FieldWrapper";