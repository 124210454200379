import { Text } from '@clubhouse/shared/components/Typography/Text';
import { HelpMessage } from 'components/shared/HelpMessage';
import { DisabledUser } from './DisabledUser';
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function DisabledUsersDirectory(_ref) {
  let {
    users,
    scimEnabled,
    onRestoreUser
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      children: _jsxs(HelpMessage, {
        kind: "info",
        children: ["Disabled users remain in the system, but can't log in and aren't included in your active user count.", !scimEnabled && _jsx(_Fragment, {
          children: "Disabled users can be restored at any time."
        })]
      })
    }), users.length ? _jsx("div", {
      className: "people-list",
      children: users.map(profile => _jsx(DisabledUser, {
        profile: profile,
        scimEnabled: scimEnabled,
        onRestoreUser: onRestoreUser
      }, profile.id))
    }) : _jsx(Text, {
      size: Text.SIZE.SMALL,
      color: Text.COLOR.LIGHT,
      children: "There are no disabled users."
    })]
  });
}