import Logo from '@clubhouse/assets/png/third-party-logos/instabug-full.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Instabug() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.INSTABUG
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Instabug"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Instabug Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://instabug.com",
          children: "Instabug"
        }), " provides in-app feedback and bug reporting for mobile apps."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_INSTABUG,
          children: "our instructions"
        }), " for how to configure this integration in your Instabug account."]
      })]
    })]
  });
}
Instabug.displayName = "Instabug";