import "core-js/modules/es.array.push.js";
import * as ServiceWorkerUtils from 'components/shared/utils/serviceWorker';
import Backend from './backend';
import CompanyModel from '../models/company';
import Constants from './constants';
import Globals from '../_frontloader/globals';
import Iterate from './iterate';
import Log from './log';
import OrganizationModel from '../models/organization';
import Utils from './utils';
import { EVENTS, logEvent } from 'utils/monitoring';
import * as FetchAndCache from '@clubhouse/fetch-and-cache';
import { readOnce } from 'utils/readOnce';
import { getCurrentPage } from 'utils/navigation';
import { getDbTime, setDbTime } from 'gql/components/updates/dbTime';
const exports = {};
const XHR_TIMEOUT_MILLI = 60 * 1000;
const TOO_MANY_REQUESTS = 50;
const MAX_AJAX_REQUESTS_LOGGED = 50;
exports.readOnceFromFetchAndCache = readOnce(FetchAndCache.read);
const middlewares = {};
exports.parseErrorResponse = (xhr, response, options, url) => {
  if (_.includes([0, 406], xhr.status)) {
    if (xhr.statusText === 'abort') {
      return {
        error: Constants.USER_ABORTED
      };
    } else {
      return {
        error: Constants.REQUEST_FAILED
      };
    }
  }
  if (_.includes([500, 503], xhr.status)) {
    return {
      error: _.get(xhr.responseJSON, 'message') || Constants.SERVER_DOWN_ERROR
    };
  }

  // 501 (Not Implemented) is a 404 for PUTs
  if (_.includes([404, 501], xhr.status)) {
    return {
      error: `${url}: ${Constants.RESOURCE_NOT_FOUND_ERROR}`
    };
  }
  if (xhr.responseJSON) {
    response = xhr.responseJSON;
  } else {
    // TODO: Why do we need to fallback to xhr.responseText?
    response = Utils.parseJSON(xhr.responseText, {
      fallback: {
        error: Constants.UNABLE_TO_PARSE_RESPONSE_ERROR
      }
    });
  }
  if (response.message && !options.doNotDuplicateErrorMessage) {
    response.error = response.message;
  }
  return response;
};
exports.parseCompleteResponse = (xhr, response) => {
  if (_.includes([200, 201, 204], xhr.status) && !response) {
    response = {
      success: true
    };
  } else {
    if (_.isString(response)) {
      response = Utils.parseJSON(response, {
        fallback: {
          originalResponse: response,
          error: Constants.UNABLE_TO_PARSE_RESPONSE_ERROR
        }
      });
    }
  }
  if (!_.isPlainObject(response) && !_.isArray(response)) {
    response = {
      originalResponse: response,
      error: Constants.UNABLE_TO_PARSE_RESPONSE_ERROR
    };
  }
  return response;
};
function _isSavingMinusOne() {
  Globals.set('isSaving', Math.max(0, Globals.get('isSaving') - 1));
  Log.debug('Ajax: isSaving--, count: ' + Globals.get('isSaving'));
}
function _isSavingPlusOne() {
  Globals.set('isSaving', Globals.get('isSaving') + 1);
  Log.debug('Ajax: isSaving++, count: ' + Globals.get('isSaving'));
}
function _increaseAjaxRequestCount() {
  Globals.set('ajaxRequestCount', _getAjaxRequestCount() + 1);
}
function _decreaseAjaxRequestCount() {
  Globals.set('ajaxRequestCount', Math.max(_getAjaxRequestCount() - 1, 0));
}
function _getAjaxRequestCount() {
  const count = Globals.get('ajaxRequestCount');
  return _.isNumber(count) ? count : 0;
}
function _logAjaxRequest(type, url) {
  const ajaxRequests = _getAjaxRequests();
  ajaxRequests.push(Date.now() + ' ' + type + ' ' + url);
  Globals.set('ajaxRequests', _.takeRight(ajaxRequests, MAX_AJAX_REQUESTS_LOGGED));
}
function _getAjaxRequests() {
  const requests = Globals.get('ajaxRequests');
  return _.isArray(requests) ? requests : [];
}
function _logIfTooManyAjaxRequests() {
  const ajaxRequestCount = _getAjaxRequestCount();

  // Let's only log when we hit the threshold, not on every request beyond it.
  if (ajaxRequestCount === TOO_MANY_REQUESTS) {
    Log.log('Lots of requests detected!', {
      ajaxRequestCount,
      ajaxRequests: _getAjaxRequests()
    });
  }
}
function _isActuallySaving(url) {
  const notSavingUrls = ['/history', '/reports', '/stories/lookup', '/stories/search', '/permission/views', '/log', '/permission/activity'];
  return !notSavingUrls.some(postUrl => url.indexOf(postUrl) > -1);
}
const getDatabaseTimeFromXhr = xhr => {
  if (xhr && xhr.getResponseHeader) {
    return Number(xhr.getResponseHeader('Database-Time'));
  }
};
const SERVICE_WORKER_HEADER_NAME = 'Use-Service-Worker';
const isResponsePossiblyFromCache = headers => {
  return Boolean(headers[SERVICE_WORKER_HEADER_NAME]);
};
const APP_ENV = process.env.ENV_NAME ?? null;
const APP_DEPLOY_ID = process.env.DEPLOY_ID ?? 'UNKNOWN';
exports.ajax = function (type, url) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const xhrTimeoutMs = Utils.exists(options.timeout) ? options.timeout : XHR_TIMEOUT_MILLI;
  // "ExternalAbort" is a setTimeout that calls xhr.abort().
  // It's scheduled to fire 30 seconds after the native xhr timeout.
  const xhrExternalAbortTimeoutMs = xhrTimeoutMs + 30000;
  const startTime = Date.now();
  const defaultContentType = 'application/json; charset=utf-8';
  const withCredentials = _.isBoolean(options.withCredentials) ? options.withCredentials : true;
  const page = getCurrentPage();
  const headers = {
    'Shortcut-App-Build-Id': APP_ENV === 'debug' ? 'localhost' : APP_DEPLOY_ID,
    'Tenant-Organization2': options.company_id || CompanyModel.getCurrentID(),
    ...(ServiceWorkerUtils.isServiceWorkerEnabled() && !Utils.isInitialPageRenderComplete() ? {
      [SERVICE_WORKER_HEADER_NAME]: 'true'
    } : {})
  };
  let xhr;
  let response = '';
  if (!options.excludeOrgHeader) {
    headers['Tenant-Workspace2'] = options.organization_id || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  }
  if (options.actionContext) {
    if (Array.isArray(options.actionContext)) {
      headers['shortcut-action-context'] = options.actionContext.toString();
    } else {
      headers['shortcut-action-context'] = options.actionContext;
    }
  } else {
    const isValidPage = /^[a-zA-Z0-9_ :-]{1,100}$/.test(page);
    if (isValidPage) {
      headers['shortcut-action-context'] = page;
    }
  }
  let logStalledResponse;
  if (type === 'GET' && !url.includes('api/private/updates')) {
    logStalledResponse = setTimeout(() => {
      Log.log('[ajax]: slow-request', {
        url,
        isPossiblyFromCache: isResponsePossiblyFromCache(headers),
        isServiceWorkerEnabled: ServiceWorkerUtils.isServiceWorkerEnabled()
      });
    }, 15 * 1000);
  }
  const timeout = setTimeout(() => {
    Log.debug('Ajax: Request is taking a long time: ' + type + ': ' + url);
    _decreaseAjaxRequestCount();
    if (xhr && _.isFunction(xhr.abort)) {
      xhr.abort();
    }
    if (type !== 'GET') {
      if (Globals.get('windowHasFocus')) {
        logEvent(EVENTS.Request_TimedOut, {
          url,
          type
        });
      }
      _isSavingMinusOne();
    }
  }, xhrExternalAbortTimeoutMs);
  _logAjaxRequest(type, url);
  _logIfTooManyAjaxRequests();
  let _headers = options.headers || headers;
  if (url.indexOf(Backend.getServerURL()) === 0) {
    // Used to track the origin of API requests
    _headers['Clubhouse-Page'] = page;
  }

  // Sending the org and workspace as params instead of headers (along
  // with having a application/x-www-form-urlencoded content-type)
  // causes update requests to be considered "Simple Requests", which
  // prevents a CORS preflight OPTIONS request for each GET.
  // Ref: https://app.shortcut.com/internal/story/56758/eliminate-pre-flight-requests-for-get-updates
  if (options.shouldPreventPreflight) {
    const headersToPutInData = ['Tenant-Organization2', 'Tenant-Workspace2'];
    const {
      data = {}
    } = options;
    headersToPutInData.forEach(name => {
      const value = _headers[name];
      if (!value) {
        return;
      }
      data[name.toLowerCase()] = value;
      delete _headers[name];
    });
    options.contentType = 'application/x-www-form-urlencoded';
    options.processData = false;
    // explicitly convert to a param string here since the default
    // behavior below is to convert to json
    options.data = $.param(data);
    // We cannot set any other headers or this won't be a simple request.
    _headers = {};
  }
  const ajaxOptions = {
    url,
    type,
    contentType: Utils.exists(options.contentType) ? options.contentType : defaultContentType,
    headers: _headers,
    timeout: xhrTimeoutMs,
    xhrFields: {
      withCredentials
    },
    success: data => {
      response = data;
    },
    error: jqXHR => {
      if (!(options.contentType === 'text/plain' && options.rawResponse)) {
        response = exports.parseErrorResponse(jqXHR, response, options, url);
      }
    },
    complete: jqXHR => {
      // complete is called no matter what, after success and error callbacks.
      clearTimeout(timeout);
      clearTimeout(logStalledResponse);
      _decreaseAjaxRequestCount();
      const roundTripTime = Date.now() - startTime;
      if (jqXHR.status === 500) {
        Log.error(new Error('Got a 500 (Internal Server Error) response code!'), {
          url,
          roundTripTime
        });
      }
      if (type === 'POST' || type === 'PUT') {
        if (_isActuallySaving(url)) {
          _isSavingMinusOne();
        }
      } else if (type !== 'GET') {
        _isSavingMinusOne();
      }
      if (options.contentType === 'text/plain' && options.rawResponse) {
        response = jqXHR.responseText;
      } else {
        response = exports.parseCompleteResponse(jqXHR, response);
      }
      const databaseTime = getDatabaseTimeFromXhr(jqXHR);
      const currentUpdatesTime = getDbTime();
      if (databaseTime && (!currentUpdatesTime || databaseTime < currentUpdatesTime)) {
        setDbTime(databaseTime);
      }
      const returnValues = _invokeMiddlewares({
        type,
        url,
        contentType: Utils.exists(options.contentType) ? options.contentType : defaultContentType,
        headers: _headers,
        response: {
          status: jqXHR.status,
          data: response
        }
      });
      if (returnValues.every(v => v === true)) {
        _handleResponse(options, response, jqXHR);
      } else {
        _handleResponse(options, {
          status: 410,
          responseIntercepted: true,
          message: response.message
        }, jqXHR);
      }
    }
  };
  if (options.data) {
    if (options.processData === false) {
      ajaxOptions.data = options.data;
    } else {
      ajaxOptions.data = JSON.stringify(options.data);
    }
  }
  Iterate.each(['xhr', 'cache', 'processData'], key => {
    if (Utils.exists(options[key])) {
      ajaxOptions[key] = options[key];
    }
  });
  _increaseAjaxRequestCount(url);
  if (type === 'GET' && !Utils.isInitialPageRenderComplete()) {
    const promise = exports.readOnceFromFetchAndCache(url);
    if (promise) {
      const {
        success,
        complete
      } = ajaxOptions;
      return promise.then(_ref => {
        let {
          response,
          json
        } = _ref;
        try {
          success(json);
          complete(response);
        } catch {}
      }).catch(() => {
        xhr = $.ajax(ajaxOptions);
        return xhr;
      });
    }
  }
  xhr = $.ajax(ajaxOptions);
  return xhr;
};
function _invokeMiddlewares(args) {
  return Object.values(middlewares).map(_ref2 => {
    let {
      handler
    } = _ref2;
    return handler(args);
  });
}
function _handleResponse(options, response, jqXHR) {
  if (response.error) {
    if (_.isFunction(options.onError)) {
      options.onError(response, jqXHR);
    }
  } else if (_.isFunction(options.onSuccess)) {
    options.onSuccess(response);
  }
  if (_.isFunction(options.onComplete)) {
    options.onComplete(response, jqXHR);
  }
}
exports.get = (url, options // Log.debug('Ajax: GET ' + url);
) => {
  return exports.ajax('GET', url, options);
};
exports.post = (url, options) => {
  if (_isActuallySaving(url)) {
    _isSavingPlusOne(url);
  }
  return exports.ajax('POST', url, options);
};
exports.put = (url, options) => {
  if (_isActuallySaving(url)) {
    _isSavingPlusOne(url);
  }
  return exports.ajax('PUT', url, options);
};
exports.delete = (url, options) => {
  _isSavingPlusOne(url);
  return exports.ajax('DELETE', url, options);
};
exports.use = middleware => {
  middlewares[middleware.id] = middleware;
};
exports.remove = middleware => {
  delete middleware[middleware.id];
};
export { exports as default };