import { MutateEpicArchiveDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { ArchiveEpicFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
export const ArchiveEpicFragment = ArchiveEpicFragmentFragmentDoc;
const MUTATE_EPIC_ARCHIVE = MutateEpicArchiveDocument;
export function useArchiveEpicMutation() {
  const [setArchived] = useMutation(MUTATE_EPIC_ARCHIVE);
  return useCallback(epic => setArchived({
    variables: {
      id: epic.id
    },
    optimisticResponse: {
      __typename: 'Mutation',
      epicUpdate: {
        __typename: 'EpicPayload',
        epic: {
          __typename: 'Epic',
          id: epic.id,
          archived: true
        }
      }
    }
  }), [setArchived]);
}