import { EpicsTable } from 'components/gql/epics/table/EpicsTable';
import { TableFooter } from 'components/gql/pagination/TableFooter';
import { TablePagination } from 'components/gql/pagination/TablePagination';
import { TableResults } from 'components/gql/pagination/TableResults';
import { PaddingScroll } from 'components/layout/ListPageLayout';
import { StaleLoading } from 'components/shared/StaleLoading';
import { useTableSortUrlState } from 'pages/epics-apollo/hooks/useTableSortUrlState';
import { EVENT_TYPES, useEventListener } from 'utils/collectionizeToApolloMessageBus';
import { useGqlActiveTransaction, useRenderSpans } from 'utils/profiler/sentry';
import { EpicsEmptyState } from '../EpicsEmptyState';
import { NoEpicsInWorkspaceEmptyState } from '../NoEpicsInWorkspaceEmptyState';
import { PAGE_SIZE } from '../columns/hooks/useEpicsColumnData';
import { EpicsErrorState } from './EpicsErrorState';
import { GroupedTables } from './GroupedTables';
import { ALL_SORTABLE_VALUES, useEpicsTableData } from './useEpicsTableData';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const EpicsTableViewWithPagination = () => {
  const {
    sortColumn,
    sortDirection,
    onSortChange
  } = useTableSortUrlState({
    initialColumn: 'position',
    initialDirection: 'ascending',
    validValues: ALL_SORTABLE_VALUES
  });
  const {
    columns,
    epics,
    pageInfo,
    isLoading,
    isStale,
    error,
    groupBy,
    updateQuery,
    refetch,
    hasNoEpicsInScope,
    isInitialDataFromCache
  } = useEpicsTableData({
    sortColumn,
    sortDirection
  });
  useEventListener(EVENT_TYPES.EPIC_CREATED, refetch);
  useRenderSpans(`EpicsTable`);
  useGqlActiveTransaction({
    isInitialDataFromCache,
    isLoading
  });
  const hasEmptyState = !isLoading && !epics.length && !pageInfo?.totalSize;
  if (error && hasEmptyState) return _jsx(EpicsErrorState, {
    error: error
  });else if (hasNoEpicsInScope) return _jsx(NoEpicsInWorkspaceEmptyState, {});else if (hasEmptyState) return _jsxs(_Fragment, {
    children: [_jsx(EpicsEmptyState, {}), isStale && _jsx(StaleLoading, {})]
  });
  return _jsx(PaddingScroll, {
    restoreScroll: !isLoading && 'epics-table',
    children: groupBy ? _jsx(GroupedTables, {
      groupBy: groupBy,
      items: epics,
      columns: columns,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      onSort: onSortChange,
      isLoading: isLoading,
      isStale: isStale,
      updateQuery: updateQuery,
      footer: _jsx(TableFooter, {
        results: _jsx(TableResults, {
          offset: pageInfo?.offset,
          pageSize: PAGE_SIZE,
          totalItemCount: pageInfo?.totalSize
        }),
        pagination: _jsx(TablePagination, {
          pageCount: Math.ceil((pageInfo?.totalSize || 0) / PAGE_SIZE)
        })
      })
    }) : _jsx(EpicsTable, {
      epics: epics,
      columns: columns,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      onSort: onSortChange,
      updateQuery: updateQuery,
      isLoading: isLoading,
      isStale: isStale,
      footer: _jsx(TableFooter, {
        results: _jsx(TableResults, {
          offset: pageInfo?.offset,
          pageSize: PAGE_SIZE,
          totalItemCount: pageInfo?.totalSize
        }),
        pagination: _jsx(TablePagination, {
          pageCount: Math.ceil((pageInfo?.totalSize || 0) / PAGE_SIZE)
        })
      })
    })
  });
};
EpicsTableViewWithPagination.displayName = "EpicsTableViewWithPagination";
export const EpicsTableView = () => {
  return _jsx(EpicsTableViewWithPagination, {});
};
EpicsTableView.displayName = "EpicsTableView";