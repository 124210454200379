import * as RangedDatepickerTemplate from 'app/client/core/views/templates/rangedDatepicker.html';
import BaseUtils from '../_frontloader/baseUtils';
import DropdownController from './dropdown';
import Is from '../modules/is';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
const exports = {};
const DEFAULT_PRESETS = [{
  name: 'Custom'
}, {
  name: 'Last week',
  startDate: moment().subtract(7, 'd'),
  endDate: moment().startOf('day')
}, {
  name: 'Last 2 weeks',
  startDate: moment().subtract(14, 'd'),
  endDate: moment().startOf('day')
}, {
  name: 'Last 3 weeks',
  startDate: moment().subtract(21, 'd'),
  endDate: moment().startOf('day')
}, {
  name: 'Last 4 weeks',
  startDate: moment().subtract(28, 'd'),
  endDate: moment().startOf('day')
}, {
  name: 'Last 8 weeks',
  startDate: moment().subtract(56, 'd'),
  endDate: moment().startOf('day')
}];
const CLASS_HIGHLIGHT = 'ui-datepicker-current-range';
const CLASS_HIGHLIGHT_TIP = 'ui-datepicker-current-range-start-end';
const PARENT_SELECTOR = '#dropdown-ranged-datepicker .controls';
const SELECTOR_DATEPICKER = '.daterangepicker';
const SELECTOR_TDS = '.ui-datepicker-calendar td';
const START_DATE_SELECTOR = '#start-date';
const END_DATE_SELECTOR = '#end-date';
const DATEPICKER_FORMAT = 'MM/DD/YYYY';
const SELECTING_START_DATE = 'SELECTING_START_DATE';
const SELECTING_END_DATE = 'SELECTING_END_DATE';
let selectingDate = SELECTING_START_DATE; // SELECTING_START_DATE || SELECTING_END_DATE

let selectedStartDate = null; // a moment object
let selectedEndDate = null; // a moment object

const defaultOptions = {
  autoApply: false,
  showPresets: true,
  presets: DEFAULT_PRESETS
};
let options = {};

/**
 * options
 *   startDate: String | Date | Moment
 *   endDate: String | Date | Moment
 *   autoApply: Boolean
 *   presets: Object{ name: String, value: Number, startDate: Moment, endDate: Moment}
 *   showPresets: Boolean
 *   onApply: Function (start: Moment, end: Moment, duration: Number) => void
 *   onStartDateChange: Function (date: Moment)
 *   onEndDateChange: Function (date: Moment)
 *   isSelectingEndDate: Boolean Opens picker but tries to pick end date at once
 *   maxDate: String | Date | Moment,
 *   minDate: String | Date | Moment,
 *   rangeLimit: Number The max range of days that can be selected
 */
exports.open = (context, _options) => {
  options = _.extend({}, defaultOptions, _options);
  selectedStartDate = toMoment(options.startDate);
  selectedEndDate = toMoment(options.endDate);
  const dropdown = DropdownController.open({
    id: 'ranged-datepicker',
    className: 'ranged-datepicker',
    footer: RangedDatepickerTemplate.render({
      presets: options.presets,
      duration: options.presets[0],
      start: selectedStartDate ? selectedStartDate.format(DATEPICKER_FORMAT) : '',
      end: selectedEndDate ? selectedEndDate.format(DATEPICKER_FORMAT) : '',
      dateFormat: DATEPICKER_FORMAT,
      showPresets: options.showPresets,
      rangeLimit: options.rangeLimit
    }),
    beforeOutsideClick: DropdownController.isClickOnDatepicker,
    onClose: () => {
      removeMouseOverListener();
      Utils.destroyDatepicker($(SELECTOR_DATEPICKER));
    },
    target: context,
    width: Is.mobile() ? 229 : options.showPresets ? 620 : 410
  });
  if (!dropdown) {
    return false;
  }
  dropdown.dropdownElement.find(SELECTOR_DATEPICKER).datepicker({
    // dateFormat: 'yy-mm-dd',
    defaultDate: selectedStartDate ? selectedStartDate.toDate() : new Date(),
    nextText: 'Next',
    prevText: 'Previous',
    numberOfMonths: Is.mobile() ? 1 : 2,
    minDate: options.minDate ? toMoment(options.minDate).toDate() : null,
    maxDate: options.maxDate ? toMoment(options.maxDate).toDate() : null,
    showAnim: '',
    onSelect: dateText => {
      if (selectingDate === SELECTING_START_DATE) {
        setStartDate(moment(dateText, 'MM/DD/YYYY'));
      } else if (selectingDate === SELECTING_END_DATE) {
        const date = moment(dateText, 'MM/DD/YYYY');
        if (date < selectedStartDate) {
          setStartDate(date);
        } else {
          setEndDate(date);
        }
      }
    },
    beforeShowDay: nativeDate => {
      const date = moment(nativeDate);
      if (selectedStartDate && selectedEndDate) {
        if (date.isBetween(selectedStartDate, selectedEndDate)) {
          return [true, CLASS_HIGHLIGHT];
        } else if (date.isSame(selectedStartDate, 'day') || date.isSame(selectedEndDate, 'day')) {
          return [true, CLASS_HIGHLIGHT_TIP];
        }
      }
      return [true, ''];
    }
  });
  if (selectedStartDate && options.isSelectingEndDate) {
    selectingDate = SELECTING_END_DATE;
  } else {
    selectingDate = SELECTING_START_DATE;
  }
};
exports.onPresetChange = function () {
  const duration = BaseUtils.toNumber($(this).val());
  const preset = options.presets.find(p => p.name === duration);
  if (preset && preset.startDate && preset.endDate) {
    updateDatePickerDates(preset.startDate, preset.endDate);
    $(START_DATE_SELECTOR).val(preset.startDate.format(DATEPICKER_FORMAT));
    $(END_DATE_SELECTOR).val(preset.endDate.format(DATEPICKER_FORMAT));
  }
};
exports.validateDates = () => {
  if (Validator.isValid($(PARENT_SELECTOR))) {
    const startDate = moment($(START_DATE_SELECTOR).val());
    const endDate = moment($(END_DATE_SELECTOR).val());
    updateDatePickerDates(startDate, endDate);
  }
};
exports.onApply = () => {
  if (!Validator.isValid($(PARENT_SELECTOR))) {
    return false;
  }
  const duration = Math.abs(selectedEndDate.endOf('day').diff(selectedStartDate.startOf('day'), 'days'));
  if (options.rangeLimit && duration >= options.rangeLimit) {
    return false;
  }
  if (_.isFunction(options.onApply)) {
    options.onApply(selectedStartDate, selectedEndDate, duration);
    exports.closeRangedDatepicker();
  }
};
exports.closeRangedDatepicker = () => {
  DropdownController.closeById('ranged-datepicker');
  return false;
};
const updateDatePickerDates = (startDate, endDate) => {
  selectingDate === SELECTING_START_DATE;
  selectedStartDate = startDate;
  selectedEndDate = endDate;
  $(SELECTOR_DATEPICKER).datepicker('setDate', startDate.toDate());
  const allTds = $(SELECTOR_TDS);
  allTds.each((idx, td) => {
    const tdDate = getDateFromTd(td);
    if (tdDate) {
      if (tdDate.isBetween(selectedStartDate, selectedEndDate) || tdDate.isSame(selectedStartDate, 'day') || tdDate.isSame(selectedEndDate, 'day')) {
        $(td).addClass(CLASS_HIGHLIGHT);
      } else {
        $(td).removeClass(CLASS_HIGHLIGHT);
      }
    }
  });
};
const setStartDate = date => {
  selectedStartDate = date;
  selectingDate = SELECTING_END_DATE;
  $(START_DATE_SELECTOR).val(selectedStartDate.format(DATEPICKER_FORMAT));
  if (_.isFunction(options.onStartDateChange)) options.onStartDateChange(selectedStartDate);
  initMouseoverListener();
};
const setEndDate = date => {
  selectedEndDate = date;
  selectingDate = SELECTING_START_DATE;
  $(END_DATE_SELECTOR).val(selectedEndDate.format(DATEPICKER_FORMAT));
  if (_.isFunction(options.onEndDateChange)) options.onEndDateChange(selectedEndDate);
  if (options.autoApply) exports.onApply();
  removeMouseOverListener();
};

/**
 * Returns a moment object from String Date or Moment
 * @param {String | Moment | Date } => Moment
 */
const toMoment = date => {
  if (date) {
    if (typeof date === 'string' || date instanceof Date) {
      return moment(date);
    } else if (moment.isMoment(date)) {
      return date;
    }
  }
  return null;
};
const initMouseoverListener = () => {
  $(SELECTOR_DATEPICKER).on('mouseover', SELECTOR_TDS, function () {
    if (selectingDate === SELECTING_END_DATE) {
      const date = getDateFromTd(this);
      const allTds = $(SELECTOR_TDS);
      allTds.each((idx, td) => {
        const tdDate = getDateFromTd(td);
        if (tdDate && date) {
          if (tdDate.isBetween(selectedStartDate, date)) {
            $(td).addClass(CLASS_HIGHLIGHT);
          } else {
            $(td).removeClass(CLASS_HIGHLIGHT);
          }
        }
      });
    }
  });
};
const removeMouseOverListener = () => {
  $(SELECTOR_DATEPICKER).off('mouseover', SELECTOR_TDS);
};
const getDateFromTd = elem => {
  const day = Number(elem.textContent);
  const month = Number(elem.dataset.month) + 1;
  const year = elem.dataset.year;
  if (day && month && year) {
    return moment(`${year}-${('0' + month).slice(-2)}-${day}`);
  }
  return null;
};
export { exports as default };