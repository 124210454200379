export async function copyToClipboard(text) {
  // const tempInput = document.createElement('input');

  // tempInput.value = text;
  // tempInput.style.position = 'fixed';
  // tempInput.style.left = '-1000px';
  // document.body.appendChild(tempInput);
  // tempInput.focus();
  // tempInput.select();

  // const success = document.execCommand('copy');

  // document.body.removeChild(tempInput);

  // return success;

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch {
    return false;
  }
}