import "core-js/modules/es.array.push.js";
import debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { useCallback, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
const MAX_HISTORY_ENTRIES = 50;
export const useStateWithHistory = function (initialState) {
  let {
    debounceMs = 500,
    maxHistoryEntries = MAX_HISTORY_ENTRIES,
    isEqual = _isEqual
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [state, _setState] = useState(initialState);
  const history = useRef([state]);
  const historyIndex = useRef(0);
  const addHistoryEntry = useMemo(() => debounce(value => {
    if (historyIndex.current < history.current.length - 1) {
      history.current = history.current.slice(0, historyIndex.current + 1);
    }
    if (history.current.length === maxHistoryEntries) {
      history.current.shift();
    }
    historyIndex.current = history.current.push(value) - 1;
  }, debounceMs), [debounceMs, maxHistoryEntries]);
  const setState = useCallback((arg, options) => {
    _setState(curr => {
      const next = typeof arg === 'function' ? arg(curr) : arg;
      if (options?.skipHistoryEntry !== true && !isEqual(next, curr)) {
        addHistoryEntry(next);
      }
      return next;
    });
  }, [addHistoryEntry, isEqual]);
  return [state, setState, useMemo(() => ({
    back: fn => {
      if (historyIndex.current === 0) return;
      historyIndex.current -= 1;
      const value = history.current[historyIndex.current];
      flushSync(() => _setState(value));
      fn?.(value);
    },
    forward: fn => {
      if (historyIndex.current === history.current.length - 1) return;
      historyIndex.current += 1;
      const value = history.current[historyIndex.current];
      flushSync(() => _setState(value));
      fn?.(value);
    }
  }), [])];
};