import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
export const useTableLoadMorePaginationState = _ref => {
  let {
    loadedItems,
    pageSize = 40
  } = _ref;
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: pageSize
  });
  const debouncedSetPagination = useMemo(() => debounce(setPagination, 1000), []);
  const setRangeByList = useCallback(range => {
    let offset = range?.at(0) ?? 0;
    if (offset + pageSize >= loadedItems.current) offset = Math.max(loadedItems.current - pageSize, 0);
    debouncedSetPagination({
      offset,
      limit: pageSize
    });
  }, [debouncedSetPagination, loadedItems, pageSize]);
  return [pagination, setRangeByList];
};