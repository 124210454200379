import { Children } from 'react';
import { hasProps } from './utils';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const useSlot = options => {
  const children = Children.toArray(options.children);
  const child = children.filter(hasProps).find(child => child.props.name === options.name);
  if (child) {
    return () => _jsx(_Fragment, {
      children: child.props.children
    });
  }
  if (options.fallback) {
    return () => _jsx(_Fragment, {
      children: options.fallback
    });
  }
  throw new Error(`Slot ${options.name} not found`);
};