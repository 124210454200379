import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
// https://2ality.com/2017/04/conditional-literal-entries.html#variations-of-the-original-approach
export const insertIf = (condition, items) => condition ? items : [];

// A JS-only implementation of _.sample, which returns a random element of an array:
// Ref: https://stackoverflow.com/questions/5915096/get-random-item-from-javascript-array
export const sample = array => {
  return array[Math.floor(Math.random() * array.length)];
};

/**
 * @description If `arr` contains `val`, returns a new copy of `arr` with `val` removed. If `arr`
 * does not contain `val`, returns a new copy of `arr` with `val` added.
 * NOTE: Internally this method converts `arr` to a Set, which means that any recurring elements
 * of `arr` will be removed from the output.
 * @argument {array<T>} arr An array of **non-recurring** values of the same type.
 * @argument {T} val A single value of the same type as the values of `arr`.
 */
export function toggleValueInArray(arr, val) {
  const valueSet = new Set(arr);
  if (!valueSet.has(val)) {
    valueSet.add(val);
  } else {
    valueSet.delete(val);
  }
  return [...valueSet];
}
export const replaceValueInArray = (arr, item, matcher) => {
  const index = arr.findIndex(matcher);
  if (index !== -1) {
    arr.splice(index, 1, item);
  }
};