import { useEffect } from 'react';
import BulkEditorController from 'app/client/core/js/controllers/bulkEditor';
import BulkSelectionModel from 'app/client/core/js/models/bulkSelection';
import { useEntities, useEntity } from './collection';
export const isSelected = story => BulkSelectionModel.isSelected(story);
export const areAllSelected = stories => BulkSelectionModel.allSelected(stories);
export const getSelectionCount = () => BulkSelectionModel.getSelectionCount();
export const getRemainingLimit = () => BulkSelectionModel.getRemainingLimit();
export const getBulkSelectLimit = () => BulkSelectionModel.getBulkSelectLimit();
export const getSelectedStories = () => BulkSelectionModel.getAllIds();
export const selectAll = ids => BulkSelectionModel.selectAll(ids);
export const removeAll = ids => BulkSelectionModel.removeAll(ids);
export const removeEverything = () => BulkSelectionModel.removeEverything();
export const selectMultiple = ids => {
  BulkSelectionModel.selectMultiple(ids);
};
export const updateIfValid = story => BulkSelectionModel.updateIfValid(story);
export const remove = _ref => {
  let {
    id
  } = _ref;
  return BulkSelectionModel.remove({
    id
  });
};
export const flush = () => BulkSelectionModel.flush();
export const useBulkSelection = _ref2 => {
  let {
    id
  } = _ref2;
  return useEntity({
    id,
    model: BulkSelectionModel
  });
};
export const useBulkSelections = () => useEntities({
  model: BulkSelectionModel
});
export const useBulkSelectionCount = () => useEntities({
  model: BulkSelectionModel
})?.entities?.length || 0;
export const toggleSelection = story => BulkEditorController.toggleSelection(story);
export const toggleTableSelection = (event, story) => BulkEditorController.toggleTableSelection.call(event.target, event, story);
const onCacheClear = fn => {
  BulkSelectionModel.on('removeEverything', fn);
  return () => BulkSelectionModel.off('removeEverything', fn);
};
export const openBulkEditor = (target, tracking) => BulkEditorController.openOnTarget(target, tracking);
export const useCollectionizeBulkSelectSyncItems = _ref3 => {
  let {
    publicIds,
    onBulkSelectRemoveEverythingEvent
  } = _ref3;
  useEffect(() => {
    const unsubscribe = onCacheClear(onBulkSelectRemoveEverythingEvent);
    return () => unsubscribe();
  }, [onBulkSelectRemoveEverythingEvent]);
  useEffect(() => {
    selectMultiple(publicIds);
    return () => removeAll(publicIds);
  }, [publicIds]);
};