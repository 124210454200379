import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
export const readOnce = fn => {
  const args = new Set();
  const wrappedFn = arg0 => {
    if (args.has(arg0)) {
      return undefined;
    }
    args.add(arg0);
    return fn(arg0);
  };
  wrappedFn.clear = () => args.clear();
  return wrappedFn;
};