import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useAsyncFn, useUpdateEffect } from 'react-use';
export const useInlineEditing = (initialValue, onSave) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  useUpdateEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);
  const [state, saveChange] = useAsyncFn(async newValue => {
    setSelectedValue(newValue);
    try {
      return await onSave(newValue);
    } catch (_error) {
      setSelectedValue(initialValue);
    }
  }, [onSave, initialValue]);
  return [selectedValue, saveChange, state];
};
export const useMultiInlineEditing = (initialValues, onSave) => {
  const [selectedValues, setSelectedValues] = useState(initialValues);
  useUpdateEffect(() => {
    setSelectedValues(initialValues);
  }, [String(initialValues)]);
  const [state, triggerSave] = useAsyncFn(async newValues => {
    setSelectedValues(newValues);
    try {
      return await onSave(newValues);
    } catch (_error) {
      setSelectedValues(initialValues);
    }
  }, [onSave, initialValues]);
  const saveChange = () => {
    if (!isEqual(selectedValues, initialValues)) {
      triggerSave(selectedValues);
    }
  };
  return [selectedValues, setSelectedValues, saveChange, state];
};