import noop from 'lodash/noop';
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
const DIALOGS = {
  archive: {
    body: 'Archiving this Story will exclude it from all reports and charts, as well as from the Stories page.',
    buttonKind: Button.KIND.WARNING,
    cta: 'Archive Story',
    onClick: noop,
    title: 'Are you sure you want to archive this Story?'
  },
  delete: {
    body: `Deleting this Story will permanently remove it from ${BRAND.NAME}, and you will not be able to recover it.`,
    buttonKind: Button.KIND.WARNING,
    cta: 'Delete Story',
    onClick: noop,
    title: 'Are you sure you want to delete this Story?'
  }
};
export function useStoryConfirmationDialog() {
  const {
    openDialog: open,
    closeDialog
  } = useConfirmationDialogState();
  const openDialog = useCallback((type, props) => {
    open({
      ...DIALOGS[type],
      ...props
    });
  }, [open]);
  return {
    openDialog,
    closeDialog
  };
}