import * as InvalidConfirmCodeTemplate from 'app/client/login/views/templates/invalidConfirmCode.html';
import * as ConfirmEmailTemplate from 'app/client/login/views/templates/confirmEmail.html';
import { Button } from 'components/shared/DeprecatedButton';
import { Problem } from 'components/shared/Problem';
import EmailModel from '../../../core/js/models/email';
import * as Event from '../../../core/js/_frontloader/event';
import LoginController from './login';
import Url from '../../../core/js/modules/url';
import User from '../../../core/js/modules/user';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import { setPageTitle } from 'utils/page';
const exports = {};
exports.init = () => {
  const path = Url.getCurrentPathname().split('/');
  const confirm_code = path.pop();
  const email_id = path.pop();
  EmailModel.confirmEmail(email_id, confirm_code, err => {
    if (err) {
      exports.renderError(err);
    } else {
      exports.renderSuccess();
      setTimeout(() => {
        Utils.fadePageOut();
        UserModel.fetchCurrentUser(exports.redirect);
      }, 3000);
    }
  });
};
exports.render = html => {
  $('#form-container').html(html);
  Utils.fadePageIn();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'ConfirmEmail');
};
exports.renderError = err => {
  setPageTitle(`Invalid Confirmation Code | ${BRAND.NAME}`);
  const html = InvalidConfirmCodeTemplate.render();
  exports.render(html);
  View.renderComponentConditionally({
    containerSelector: '#invalid-confirm-code',
    component: Problem,
    props: {
      height: '40vh',
      icon: React.createElement('span', {
        className: 'fa fa-exclamation-triangle'
      }),
      title: "We couldn't confirm your email",
      description: typeof err === 'string' ? err : 'This confirmation code has either expired or was deleted.',
      buttons: React.createElement(Button, {
        text: 'Go to home page',
        href: '/',
        size: Button.sizes.xsmall
      })
    }
  });
};
exports.renderSuccess = () => {
  setPageTitle(`Email Confirmed | ${BRAND.NAME}`);
  const html = ConfirmEmailTemplate.render();
  exports.render(html);
  View.renderComponentConditionally({
    containerSelector: '#confirm-successful',
    component: Problem,
    props: {
      height: '100vh',
      icon: React.createElement('span', {
        className: 'fa fa-check-circle'
      }),
      title: 'Thanks, email confirmed!',
      description: 'Redirecting you to the web app...'
    }
  });
};
exports.redirect = () => {
  const user = User.getCurrentUser();
  if (user) {
    LoginController.handleLoginSuccess();
  }
};
export { exports as default };