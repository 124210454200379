import { useCallback } from 'react';
import { NONE_VALUE } from '@clubhouse/shared/constants';
import { useLazyQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
export function useFilterOptionsQuery(_ref) {
  let {
    query,
    selectedIds
  } = _ref;
  const slug = useWorkspaceSlug();
  const [executeQuery] = useLazyQuery(query);
  return useCallback(async _ref2 => {
    let {
      inputValue
    } = _ref2;
    const {
      data
    } = await executeQuery({
      variables: {
        workspaceSlug: slug,
        input: {
          selectedIds: selectedIds.filter(value => value !== NONE_VALUE),
          ...(inputValue ? {
            fuzzyName: inputValue.trim()
          } : {})
        }
      }
    });
    return data?.workspace2?.options;
  }, [executeQuery, selectedIds, slug]);
}