import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserNotFound = _ref => {
  let {
    fill,
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsx("svg", {
    fill: fill,
    width: width,
    height: width,
    viewBox: "0 0 12 10",
    children: _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.897 4.25a2.047 2.047 0 01-1.503-.622 2.047 2.047 0 01-.623-1.503c0-.586.208-1.087.623-1.502A2.047 2.047 0 013.897 0c.586 0 1.087.208 1.502.623.415.415.623.916.623 1.502 0 .587-.208 1.088-.623 1.503a2.047 2.047 0 01-1.502.622zm5.96 1.771L11.235 7.4c.033.033.05.075.05.127a.165.165 0 01-.05.122l-.752.753a.165.165 0 01-.122.05.174.174 0 01-.128-.05L8.856 7.023 7.478 8.4a.173.173 0 01-.128.05.166.166 0 01-.122-.05l-.752-.753a.166.166 0 01-.05-.122c0-.051.017-.094.05-.127L7.854 6.02 6.476 4.643a.173.173 0 01-.05-.127c0-.048.017-.089.05-.122l.752-.752a.166.166 0 01.122-.05c.052 0 .094.017.128.05l1.378 1.377 1.377-1.377a.172.172 0 01.128-.05c.048 0 .089.017.122.05l.752.752c.033.033.05.074.05.122a.174.174 0 01-.05.127L9.857 6.021zM6.1 7.023l1.002-1.002L6.098 5.02a.685.685 0 01-.205-.504.63.63 0 01.072-.31c.048-.085.124-.177.227-.277a1.545 1.545 0 00-.315-.033.337.337 0 00-.216.094c-.568.45-1.157.675-1.766.675-.608 0-1.197-.225-1.765-.675a.34.34 0 00-.216-.094c-.225 0-.43.037-.617.11a1.435 1.435 0 00-.473.296 1.956 1.956 0 00-.343.449c-.1.175-.179.355-.238.54a5.593 5.593 0 00-.224 1.204C.006 6.68 0 6.87 0 7.067c0 .446.134.797.404 1.051.27.255.627.382 1.074.382h4.837c.085 0 .166-.005.243-.016l-.459-.46a.678.678 0 01-.204-.497c0-.2.068-.368.204-.504z"
    })
  });
};
UserNotFound.displayName = "UserNotFound";