import "core-js/modules/es.array.push.js";
import { useCallback, useEffect, useRef } from 'react';

// https://github.com/facebook/react/issues/5465#issuecomment-157888325

export class CanceledError extends Error {
  constructor() {
    let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Promise was canceled';
    super(message);
    this.isCanceled = true;
  }
}
export function createCancelablePromise(promise) {
  let hasCanceled_ = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => hasCanceled_ ? reject(new CanceledError()) : resolve(val), error => hasCanceled_ ? reject(new CanceledError()) : reject(error));
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
}
export function useCancelablePromises() {
  const cancelablePromises = useRef([]);
  const cancelAll = useCallback(() => {
    cancelablePromises.current.forEach(p => p.cancel());
    cancelablePromises.current = [];
  }, []);
  useEffect(() => cancelAll, [cancelAll]);
  const makeCancelable = useCallback(promise => {
    const cancelablePromise = createCancelablePromise(promise);
    cancelablePromises.current.push(cancelablePromise);
    return cancelablePromise.promise;
  }, []);
  return {
    makeCancelable,
    cancelAll
  };
}
export const isCanceled = e => e?.isCanceled === true;