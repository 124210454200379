import { Bugsee } from 'pages/integrations/Bugsee';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
const exports = {};
exports.title = `Bugsee Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/bugsee';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Bugsee, 'bugsee-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };