import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { DocCardAdapter } from '../shared/DocCard';
import { DocListItem } from '../shared/DocListItem';
import { LoadMore } from './LoadMore';
import { Loading } from './Loading';
import { NoResults } from './NoResults';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DocResults = _ref => {
  let {
    showCards,
    fetchProps,
    actions: {
      fetchMoreDocs
    },
    onClick
  } = _ref;
  const {
    loading,
    called,
    data
  } = fetchProps;
  const edges = data?.docsSearch?.edges ?? emptyArray;
  const hasNextPage = data?.docsSearch?.pageInfo?.hasNextPage || false;
  if (loading && !called) {
    return _jsx(Loading, {});
  }
  if (called && !loading && edges && !edges.length) {
    return _jsx(NoResults, {
      entityType: "Docs"
    });
  }
  const DocComponent = showCards ? DocCardAdapter : DocListItem;
  const docs = edges.map(edge => edge?.node).filter(Boolean);
  return _jsxs(_Fragment, {
    children: [docs.map(doc => _jsx(DocComponent, {
      doc: doc,
      onClick: onClick
    }, doc.id)), hasNextPage && _jsx(LoadMore, {
      loading: loading,
      loadMore: fetchMoreDocs
    })]
  });
};