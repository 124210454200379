import { useCallback, useEffect, useRef, useState } from 'react';
export function useRafState(initialState) {
  const frameId = useRef(0);
  const [state, setState] = useState(initialState);
  const setRafState = useCallback(newValue => {
    if (newValue === state) {
      return;
    }
    cancelAnimationFrame(frameId.current);
    frameId.current = requestAnimationFrame(() => {
      setState(newValue);
    });
  }, [state]);
  useEffect(() => {
    return () => cancelAnimationFrame(frameId.current);
  }, []);
  return [state, setRafState];
}