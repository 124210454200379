import Logo from '@clubhouse/assets/png/third-party-logos/gcal_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function GoogleCalendar() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.GOOGLE_CALENDAR
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Google Calendar"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Google Calendar Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Visualize the due dates of your Shortcut Stories in your", ' ', _jsx(ExternalLink, {
          href: "https://calendar.google.com/",
          children: "Google Calendar"
        }), "."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_GOOGLE_CALENDAR,
          children: "Learn more"
        }), " on how the Google Calendar integration works in Shortcut."]
      })]
    })]
  });
}
GoogleCalendar.displayName = "GoogleCalendar";