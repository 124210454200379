import { useScrollTarget } from './shared';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const makeScrollTarget = Component => {
  return function ScrollTarget(_ref) {
    let {
      id,
      ...rest
    } = _ref;
    const props = useScrollTarget(id);
    return _jsx(Component, {
      ...rest,
      ...props
    });
  };
};