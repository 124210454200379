import { Select, SelectOptionSubtitle, TruncatedTarget } from '@clubhouse/shared/components/Select';
import { useActiveTeam, useTeamScopedWorkflows } from 'data/entity/team';
import { WorkflowFooter } from '../WorkflowFooter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const toItem = team => {
  return {
    value: String(team.id),
    name: team.name,
    additionalSearchTerms: [team.name],
    additionalData: `${team.workflow.states.length} Workflow States`,
    Component: SelectOptionSubtitle
  };
};
export const ActiveWorkflowSelect = _ref => {
  let {
    onChange
  } = _ref;
  const workflows = useTeamScopedWorkflows();
  const [activeTeam, setActiveTeam] = useActiveTeam();
  const handleChange = _ref2 => {
    let [selectedValue] = _ref2;
    setActiveTeam(Number.parseInt(selectedValue, 10));
    onChange?.();
  };
  return _jsx(Select, {
    maxSelected: 1,
    TargetComponent: TruncatedTarget,
    FooterComponent: _jsx(WorkflowFooter, {}),
    items: workflows.map(toItem),
    selectedValues: [String(activeTeam)],
    onChange: handleChange,
    hideClose: true,
    isSearchable: true,
    showSearchPlaceholder: false
  });
};
ActiveWorkflowSelect.displayName = "ActiveWorkflowSelect";