import { useCallback } from 'react';
import { useAutoResetToggle } from './useAutoResetToggle';
import { useLatestRef } from './useLatestRef';
export function useCopy() {
  const copyValue = useCallback(async value => {
    try {
      await navigator.clipboard.writeText(value);
    } catch {}
  }, []);
  return copyValue;
}
export const useCopyWithTooltip = value => {
  const valueRef = useLatestRef(value);
  const _copyValue = useCopy();
  const [tooltipVisible, showTooltip] = useAutoResetToggle();
  const copyValue = useCallback(async e => {
    e?.preventDefault();
    await _copyValue(valueRef.current);
    showTooltip();
  }, [_copyValue, showTooltip, valueRef]);
  return {
    tooltipVisible,
    copyValue
  };
};