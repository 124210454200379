import { QueryWorkspacePrioritySelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { PriorityIcon } from '@useshortcut/shapes-ds';
import difference from 'lodash/difference';
import { gql } from '@clubhouse/datalayer';
import { Any, Divider, None } from '@clubhouse/shared/components/Select';
import { NONE_VALUE, Nouns, SOME_VALUE } from '@clubhouse/shared/constants';
import { PRIORITY_ICONS } from '@clubhouse/shared/utils/customFields';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQuery } from 'components/gql/filters/hooks/useFilterOptionsQuery';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_WORKSPACE_PRIORITY_SELECT = QueryWorkspacePrioritySelectDocument;
const STATIC_OPTIONS = [Any(Nouns.Priority), None(Nouns.Priority), Divider('after-static')];
const getSection = (_, options) => ({
  sectionId: 'priority',
  items: options.map(opt => ({
    name: opt?.node?.stringValue,
    key: `${opt?.node?.id}-priorities`,
    value: opt?.node?.id,
    Icon: () => _jsx(PriorityIcon, {
      icon: PRIORITY_ICONS[opt?.node?.position ?? 1]
    })
  }))
});
export function PriorityFilter(_ref) {
  let {
    entityType
  } = _ref;
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'priority',
    paramType: 'string[]'
  });
  const _fetchPriorities = useFilterOptionsQuery({
    query: QUERY_WORKSPACE_PRIORITY_SELECT,
    selectedIds: selectedValues.filter(str => ![SOME_VALUE, NONE_VALUE].includes(str))
  });
  const fetchPriorities = async input => {
    const data = await _fetchPriorities(input);
    return {
      ...data,
      edges: data?.edges.map(_ref2 => {
        let {
          node: {
            id,
            stringValue,
            position
          }
        } = _ref2;
        return {
          groupKey: selectedValues.includes(id) ? 'selected' : 'priority',
          node: {
            id,
            stringValue,
            position
          }
        };
      })
    };
  };
  const {
    fetchSections,
    totalItemCount,
    items
  } = useFetchGroupedOptions({
    staticOptions: STATIC_OPTIONS,
    fetchOptions: fetchPriorities,
    getSection: getSection,
    filterType: Nouns.Priority,
    selectedValues
  });
  return _jsx(GroupedOptionsFilter, {
    selectedValues: selectedValues,
    fetchSections: fetchSections,
    totalItemCount: totalItemCount,
    items: items,
    onChange: newValues => {
      const removeValuesIfTheyExist = difference(newValues, selectedValues).filter(s => [SOME_VALUE, NONE_VALUE].includes(s)).map(someOrNone => someOrNone === SOME_VALUE ? NONE_VALUE : SOME_VALUE);
      setSelectedValues(newValues.filter(val => val !== SOME_VALUE && val !== NONE_VALUE || !removeValuesIfTheyExist.includes(val)));
    },
    entityType: entityType,
    filterType: Nouns.Priority,
    icon: "PriorityCircle"
  });
}
PriorityFilter.displayName = "PriorityFilter";