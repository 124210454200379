export const intersperse = _ref => {
  let {
    items,
    separator
  } = _ref;
  return items.reduce((acc, item, index) => {
    if (index === items.length - 1) {
      return [...acc, item];
    }
    return [...acc, item, separator({
      index: acc.length + 1,
      isLast: index === items.length - 2
    })];
  }, []);
};