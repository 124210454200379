import AddNewStoryController from '../controllers/addNewStory';
import * as Event from '../_frontloader/event';
import LocalStorage from './localStorage';
import Log from './log';
import ReferralsController from '../controllers/referrals';
const exports = {};
const ACTION_KEY = 'savedPageLoadAction';
const CALLBACKS = {
  openNewStoryDialog: () => {
    AddNewStoryController.render();
  },
  openReferralDialog: () => {
    Log.log('Referrals dialog opened on page load');
    ReferralsController.open();
  }
};
exports.init = () => {
  const eventName = 'orgDataFetched.PageLoadAction';
  Event.off(eventName);
  Event.once(eventName, () => {
    const actionName = _getAction();
    const actionFn = CALLBACKS[actionName];
    if (_.isFunction(actionFn)) {
      actionFn();
      _clearAction();
    }
  });
};
exports.save = action => {
  LocalStorage.set(ACTION_KEY, action, {
    prefix: true
  });
};
function _getAction() {
  return LocalStorage.get(ACTION_KEY, {
    prefix: true
  });
}
function _clearAction() {
  LocalStorage.remove(ACTION_KEY, {
    prefix: true
  });
}
export { exports as default };