import ExternalLinkModel from 'app/client/core/js/models/externalLink';
export const getExternalLinks = () => ExternalLinkModel.getExternalLinks();
export const isDesk = externalLink => ExternalLinkModel.isDesk(externalLink);
export const isFigma = externalLink => ExternalLinkModel.isFigma(externalLink);
export const isFreshdesk = externalLink => ExternalLinkModel.isFreshdesk(externalLink);
export const isFront = externalLink => ExternalLinkModel.isFront(externalLink);
export const isGitHub = externalLink => ExternalLinkModel.isGitHub(externalLink);
export const isHelpScout = externalLink => ExternalLinkModel.isHelpScout(externalLink);
export const isIntercom = externalLink => ExternalLinkModel.isIntercom(externalLink);
export const isJiraServiceDesk = externalLink => ExternalLinkModel.isJiraServiceDesk(externalLink);
export const isKustomer = externalLink => ExternalLinkModel.isKustomer(externalLink);
export const isRollbar = externalLink => ExternalLinkModel.isRollbar(externalLink);
export const isSentry = externalLink => ExternalLinkModel.isSentry(externalLink);
export const isZendesk = externalLink => ExternalLinkModel.isZendesk(externalLink);
export const groupExternalLinksByType = externalLink => ExternalLinkModel.groupExternalLinksByType(externalLink);