import "core-js/modules/es.array.push.js";
import Async from '../modules/async';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Files from '../modules/files';
import Iterate from '../modules/iterate';
import LinkedFileModel from './linkedFile';
import ProfileModel from './profile';
import StoryLookupController from '../controllers/storyLookup';
import StoryModel from './story';
import UploaderModel from './uploader';
const exports = {};

/*

Example File entity:

{
  content_type: "image/png"
  created_at: "2014-10-20T22:48:20Z"
  filename: "hamachi.png"
  id: 521
  size: 584566
  thumbnail_url: "https://clubhouse-assets.s3.amazonaws.com/thumb_hamachi.png"
  uploader_id: "abc-def-ghi"
  url: "https://clubhouse-assets.s3.amazonaws.com/5390b4a4-5b91-4e27-b80b-879e2762bf69/521/hamachi.png"
}

*/

Collection.create('File', exports);
exports.DEFAULT_TYPE = 'internal';
exports.on('beforeAdd.collection beforeUpdate.collection', file => {
  exports.normalize(file);
  Iterate.each(file.story_ids, story_id => {
    const story = StoryModel.getById(story_id);
    if (story) {
      story.files = story.files || [];
      if (!_.some(story.files, {
        id: file.id
      })) {
        story.files.push(file);
      }
    }
  });
});
exports.normalize = file => {
  file.name = file.name || file.filename; // Backwards compatability
  file.type = file.type || exports.DEFAULT_TYPE;
  file.uploader = ProfileModel.getAllDetailsById(file.uploader_id);
};
exports.isValid = file => {
  return file && file.id && file.url;
};
exports.mapIDsToFiles = ids => {
  return _.compact(_.map(ids, id => {
    return exports.getById(id);
  }));
};
exports.fetch = (id, callback) => {
  Backend.get('/api/private/files/' + id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.fetchAll = callback => {
  Backend.get('/api/private/files', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.updateMultipleFilesFromUploaderModel = (files, callback) => {
  const fns = _.map(files, function (file) {
    return exports.updateFileFromUploaderModel.bind(this, file);
  });
  callback = _.isFunction(callback) ? callback : _.noop;
  Async.eachInParallelThen(fns, callback);
};
exports.updateFileFromUploaderModel = (file, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const data = UploaderModel.getUpdated(file.filename);
  if (data) {
    const changes = UploaderModel.getEditableProperties(data.id);
    UploaderModel.remove({
      id: data.id
    });
    exports.updateFile(file, changes, callback);
  } else {
    exports.defaultGetHandler(file, callback);
  }
};
exports.updateFile = (file, changes, callback) => {
  Backend.put('/api/private/files/' + file.id, {
    data: changes,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.deleteFile = (file, callback) => {
  Backend.delete('/api/private/files/' + file.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(file, res, xhr, callback);
    }
  });
};
exports.getSource = file => {
  let source = 'Internal';
  if (LinkedFileModel.isBox(file)) {
    source = 'Box';
  } else if (LinkedFileModel.isDropbox(file)) {
    source = 'Dropbox';
  } else if (LinkedFileModel.isGoogle(file)) {
    source = 'Google Drive';
  } else if (LinkedFileModel.isURL(file)) {
    source = 'URL';
  }
  return source;
};
exports.lookupStories = () => {
  exports.each(file => {
    Iterate.each(file.story_ids, id => {
      StoryLookupController.add(id);
    });
  });
};
exports.getThumbnailURL = file => {
  if (Files.getExtension(file.url) === 'gif') {
    return file.url;
  }
  return file.thumbnail_url || file.url;
};
exports.getRelatedStoryId = file => {
  return _.head(file.story_ids);
};
export { exports as default };