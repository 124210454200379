import { StoryLastUpdatedFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableDate } from 'components/shared/table/base/TableDate';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryLastUpdatedFieldFragment = StoryLastUpdatedFieldFragmentDoc;
const StoryUpdatedDate = _ref => {
  let {
    entity: {
      updatedAt,
      createdAt
    }
  } = _ref;
  return _jsx(TableDate, {
    value: updatedAt || createdAt
  });
};
StoryUpdatedDate.displayName = "StoryUpdatedDate";
export const StoryLastUpdatedField = () => ({
  name: 'updatedAt',
  displayName: 'Last Updated',
  Component: StoryUpdatedDate,
  sort: true,
  width: 110
});