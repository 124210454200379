import moment from 'moment';
import { DATE_FORMAT } from '@clubhouse/shared/components/DatePicker';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TextField = _ref => {
  let {
    text
  } = _ref;
  return _jsx(Text, {
    textAlign: "left",
    size: Text.SIZE.XSMALL,
    lineClamp: 2,
    children: text
  });
};
TextField.displayName = "TextField";
export const TableDate = _ref2 => {
  let {
    value
  } = _ref2;
  const text = value ? moment(value).format(DATE_FORMAT) : 'No Date';
  return _jsx(TextField, {
    text: text
  });
};
TableDate.displayName = "TableDate";