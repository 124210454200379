/** Minimal Caveman Runtime
 *
 * This is the *minimum* amount of Caveman necessary to run precompiled
 * caveman templates with the stock Caveman macros.
 *
 * Assumptions (currently held by the node-app codebase):
 *
 * - You desire all compiled templates to render as if options.escapeByDefault
 *   is true. (This is not the default setting in Caveman, but the CH codebase
 *   assumes it is on everywhere.)
 *   Normally this option is checked at template render time, not compile time.
 * - You have not added any Caveman macros.
 * - You do not use Caveman's render directly.
 *   This restriction applies to "normal" js code, not Caveman `render` macros.
 *   The caveman-dependencies-loader webpack module rule rewrites render macros
 *   in caveman templates to direct fooTemplate.render calls, where fooTemplate
 *   is a JS module generated by the caveman-loader webpack module rule.
 * - You do not use or inspect the caveman registry
 *   (Caveman.partials or Caveman.register()).
 * */

/** Private */

const htmlEscapeable = /[&<>'"]/g;
function htmlEscaped(c) {
  switch (c) {
    case '&':
      return '&amp;';
    case '<':
      return '&lt;';
    case '>':
      return '&gt;';
    case "'":
      return '&#39;';
    case '"':
      return '&quot;';
    default:
      return c;
  }
}
function escapeHTMLChecked(str) {
  return str.replace(htmlEscapeable, htmlEscaped);
}
function toString(x) {
  switch (typeof x) {
    case 'string':
      return x;
    case 'undefined':
      return '';
    case 'object':
      return x === null ? '' : '' + x;
    default:
      return '' + x;
  }
}

/** Public */

function each(obj, fn) {
  if (obj && obj.forEach) {
    return obj.forEach(fn);
  } else {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        fn(obj[prop], prop);
      }
    }
  }
}
function render(partialName) {
  console.error(`Illegal call to Caveman.render('${partialName}', ...)`);
  return '';
}
function forceStr(str) {
  str = toString(str);
  return str === '' ? str : escapeHTMLChecked(str);
}
export default {
  each,
  escapeHTML: forceStr,
  forceStr,
  render
};