import { FooterAnchorButton, useCloseSelect } from '@clubhouse/shared/components/Select';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateOnClick } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TeamsFooter = () => {
  const href = generatePathWithSlug(getPathForPage(PAGE_NAMES.SETTINGS_TEAMS));
  const closeSelect = useCloseSelect();
  const handleClick = event => {
    navigateOnClick(href)(event);
    closeSelect();
  };
  return _jsx(FooterAnchorButton, {
    onClick: handleClick,
    href: href,
    text: "Manage Teams",
    icon: "Team"
  });
};
TeamsFooter.displayName = "TeamsFooter";