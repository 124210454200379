import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
const exports = {};
Collection.create('Invoices', exports);
const fetch = callback => {
  const id = CompanyModel.getCurrentID();
  Backend.get('/api/private/owner/invoices', {
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.defaultGetHandler({
          id,
          company_id: id,
          invoices: res
        }, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.Promises = {};
exports.Promises.fetch = BaseUtils.promisify(fetch);
export { exports as default };