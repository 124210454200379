import { QueryWorkspaceTeamSelectDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useFilterOptionsQuery } from '../../filters/useFilterOptionsQuery';
const QUERY_WORKSPACE_TEAM_SELECT = QueryWorkspaceTeamSelectDocument;
export const useTeamSelectOptionsQuery = _ref => {
  let {
    selectedIds
  } = _ref;
  return useFilterOptionsQuery({
    query: QUERY_WORKSPACE_TEAM_SELECT,
    selectedIds: selectedIds,
    includeCurrentPermissionId: true
  });
};