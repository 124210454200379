import { CompositeImage } from 'components/shared/CompositeImage';
import { Problem } from 'components/shared/Problem';
import IterationPngLight from '@clubhouse/assets/png/Iterations-illo-light.png';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { ButtonWithSampleLink } from 'components/shared/ButtonWithSampleLink';
import { Button } from 'components/shared/DeprecatedButton';
import { openHelpHub } from 'components/shared/command-bar/CommandBar';
import { join } from 'utils/sampleWorkspace';
import { renderAddNewIteration } from 'utils/addNew';
import { getTeamByTeamScopedId } from 'data/entity/group';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NoIterations = () => _jsx(Problem, {
  title: "Break it down with Iterations",
  description: "Use iterations to complete work in a set period of time.",
  media: _jsx(EmptyStateImage, {
    wrapperWidth: 72,
    children: _jsx(CompositeImage, {
      primaryImage: {
        src: IterationPngLight,
        alt: '',
        darkModeOpacity: 1
      },
      width: "100%"
    })
  }),
  buttons: _jsx(ButtonWithSampleLink, {
    buttonProps: {
      text: 'Create Iteration',
      onClick: () => {
        const team = getTeamByTeamScopedId();
        renderAddNewIteration(team ? {
          group: team
        } : undefined);
      },
      color: Button.colors.blue,
      size: Button.sizes.small
    },
    sampleButtonProps: {
      text: 'View Sample Iterations',
      onClick: () => {
        join('iterations');
      }
    },
    learnButtonProps: {
      text: 'Learn about Iterations',
      onClick: () => openHelpHub({
        articleId: 28792
      })
    }
  })
});
NoIterations.displayName = "NoIterations";