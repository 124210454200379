import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const createEmojiItem = _ref => {
  let {
    IconLeft,
    text,
    value
  } = _ref;
  return {
    IconLeft,
    textComponent: _jsx(Emojify, {
      children: text
    }),
    text,
    value
  };
};