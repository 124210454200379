import Url from 'app/client/core/js/modules/url';

/**
 * @deprecated Use navigation.ts
 */
export const getPageFromCurrentPathname = Url.getPageFromCurrentPathname;

/**
 * @deprecated Use navigation.ts
 */
export const getSlugPath = Url.getSlugPath;

/**
 * @deprecated Use navigation.ts
 */
export const getCurrentPathname = Url.getCurrentPathname;

/**
 * @deprecated Use navigation.ts
 */
export const getCurrentPage = Url.getCurrentPage;

/**
 * @deprecated Use navigation.ts
 */
export const isStoryURL = Url.isStoryURL;

/**
 * @deprecated Use navigation.ts
 */
export const getCurrentSlug = Url.getCurrentSlug;