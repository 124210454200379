const IS_NOT_PROD = process.env.ENV_NAME !== 'production';
export const assertNonNull = (value, message) => {
  if (IS_NOT_PROD) {
    if (value === null) throw new Error(message || 'Value must not be null');
  }
};

/**
 * Helper function to make TypeScript error at buildtime if we forget to handle a case in a switch statement.
 * Use like so:
 *
 * enum EnumType = {
 *   A = "A",
 *   B = "B"
 * }
 *
 * switch(someEnum) {
 *   case EnumType.Foo:
 *     return "foo";
 *   case EnumType.Bar:
 *     return "bar";
 *   default:
 *     assertUnreachable(someEnum)
 * }
 *
 * Now if we add a new enum value to EnumType and forget to handle it in the switch statement, TypeScript will yell at us.
 * See https://dev.to/ddiprose/exhaustive-switch-statement-with-typescript-26dh for the source.
 */
export const assertUnreachable = _ => {
  throw new Error("Didn't expect to get here");
};