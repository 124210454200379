import { useAsync } from 'react-use';
import { TableNameText, TableNameWithLabelsAndBlockers, TableNameWithLabelsAndBlockersLabels } from 'components/shared/table/base';
import { NameField as SharedNameField } from 'components/shared/table/fields';
import { getBlockedCount, getBlocksCount } from 'data/entity/story';
import { storeGroupByForDialogs } from 'utils/groupBy';
import { navigateOnClick } from 'utils/navigation';
import { TableBlockers } from '../../table/base/TableName/TableBlockers';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const NameFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const {
    loading,
    value
  } = useAsync(async () => {
    const [blocksCount, blockedCount] = await Promise.all([getBlocksCount(entity), getBlockedCount(entity)]);
    return {
      blocksCount,
      blockedCount
    };
  }, [entity]);
  const blocksCount = value?.blocksCount ?? 0;
  const blockedCount = value?.blockedCount ?? 0;
  const showBlockers = !loading && !!(blocksCount || blockedCount);
  const showLabels = entity.labels.length > 0;
  return _jsxs(TableNameWithLabelsAndBlockers, {
    onClick: e => {
      storeGroupByForDialogs();
      navigateOnClick(entity.app_url)(e);
    },
    url: entity.app_url,
    archived: Boolean(entity.archived),
    navigation: {
      className: 'story',
      id: entity.id
    },
    children: [_jsx(TableNameText, {
      text: entity.name
    }), (showBlockers || showLabels) && _jsx(TableBlockers, {
      blockedCount: blockedCount,
      blocksCount: blocksCount,
      children: showLabels && _jsx(TableNameWithLabelsAndBlockersLabels, {
        labels: entity.labels
      })
    })]
  });
};
NameFieldComponent.displayName = "NameFieldComponent";
export const NameField = () => SharedNameField({
  Component: NameFieldComponent,
  width: 400
});