import { useCurrentPage } from 'utils/navigation';
import { shouldShowCustomLayoutForPage, useShouldShowLoadingSpinnerForPage } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LoadingScreen = () => {
  const currentPage = useCurrentPage();
  const isCustomLayoutPage = shouldShowCustomLayoutForPage(currentPage);
  const isLoadingSpinnerPage = useShouldShowLoadingSpinnerForPage(currentPage);
  const showLoading = !isCustomLayoutPage && isLoadingSpinnerPage;
  return showLoading ? _jsx("div", {
    id: "loading-screen"
  }) : null;
};