import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import * as CustomFieldData from 'data/entity/customField';
import * as GroupData from 'data/entity/group';
import * as ProjectData from 'data/entity/project';
export const NO_ENTITY_ID = null;
const getInitialState = () => ({
  filteredGroupsById: null,
  filteredProjectsById: null,
  filteredCustomFieldValuesById: null
});
let state = getInitialState();
export const resetState = () => {
  state = getInitialState();
};
function setState(key, _ref) {
  let {
    id,
    value
  } = _ref;
  if (state[key] !== null) {
    state[key].set(id, value);
  }
}
const getAllFilterableIds = items => new Set([NO_ENTITY_ID, ...items.map(_ref2 => {
  let {
    id
  } = _ref2;
  return id;
})]);

// PROJECT FILTERS

const setFilteredProjectsById = function () {
  let selectedProductIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Set();
  state.filteredProjectsById = new Map();
  getAllFilterableIds(ProjectData.allByTeam()).forEach(id => {
    setState('filteredProjectsById', {
      id,
      value: selectedProductIds.has(id)
    });
  });
};
const getProjectFilterValues = () => {
  if (state.filteredProjectsById === null) {
    // Set the default list of projects, which should be all projects for current
    //  team (project and a workflow relationship) and also the NO ENTITY ID as well
    setFilteredProjectsById(getAllFilterableIds(ProjectData.allByTeam()));
  }
  return state.filteredProjectsById;
};
export const getProjectsAsSpaceData = () => {
  const ids = [...getProjectFilterValues().keys()];
  const selectedIds = ids.filter(id => isProjectSelected({
    id
  }));
  if (selectedIds.length === ids.length) {
    return '*';
  }
  return selectedIds;
};
export const setProjectIdsFromSpaceData = function () {
  let spaceData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    Project = '*'
  } = spaceData;
  const selectedProjectIds = Project === '*' ? getAllFilterableIds(ProjectData.all()) : new Set(Project);
  setFilteredProjectsById(selectedProjectIds);
};
export const toggleIsProjectSelected = _ref3 => {
  let {
    id
  } = _ref3;
  const value = !getProjectFilterValues().get(id);
  setState('filteredProjectsById', {
    id,
    value
  });
};
export const isProjectSelected = _ref4 => {
  let {
    id
  } = _ref4;
  return getProjectFilterValues().get(id);
};
export const selectAllProjects = () => setFilteredProjectsById(getAllFilterableIds(ProjectData.allByTeam()));
export const selectNoProject = () => setFilteredProjectsById(new Set([NO_ENTITY_ID]));
export const setOnlyProjectIsSelected = _ref5 => {
  let {
    id
  } = _ref5;
  return setFilteredProjectsById(new Set([id]));
};

// GROUP FILTER

const setIsGroupSelected = group => setState('filteredGroupsById', group);
const setFilteredGroupsById = function () {
  let selectedGroupIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Set();
  state.filteredGroupsById = new Map();
  getAllFilterableIds(GroupData.all()).forEach(id => {
    setIsGroupSelected({
      id,
      value: selectedGroupIds.has(id)
    });
  });
};
const getGroupFilterValues = () => {
  if (state.filteredGroupsById === null) {
    setFilteredGroupsById(getAllFilterableIds(GroupData.all()));
  }
  return state.filteredGroupsById;
};
export const setOnlyGroupIsSelected = _ref6 => {
  let {
    id
  } = _ref6;
  setFilteredGroupsById(new Set([id]));
};
export const selectAllGroups = () => {
  setFilteredGroupsById(getAllFilterableIds(GroupData.all()));
};
export const selectNoGroup = () => {
  setFilteredGroupsById(new Set([NO_ENTITY_ID]));
};
export const getIsGroupSelected = _ref7 => {
  let {
    id
  } = _ref7;
  return getGroupFilterValues().get(id);
};
export const toggleIsGroupSelected = _ref8 => {
  let {
    id
  } = _ref8;
  setIsGroupSelected({
    id,
    value: !getIsGroupSelected({
      id
    })
  });
};
export const getGroupsAsSpaceData = () => {
  const groupIds = [...getGroupFilterValues().keys()];
  const selectedGroupIds = groupIds.filter(id => getIsGroupSelected({
    id
  }));
  if (selectedGroupIds.length === groupIds.length) {
    return '*';
  }
  return selectedGroupIds;
};
export const setGroupIdsFromSpaceData = function () {
  let {
    Group = '*'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    Group: []
  };
  const selectedGroupIds = Group === '*' ? getAllFilterableIds(GroupData.all()) : new Set(Group);
  setFilteredGroupsById(selectedGroupIds);
};
export const hasSelectedAllGroups = function () {
  let {
    Group = '*'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    Group: []
  };
  return Group === '*';
};
export const toQuery = function () {
  let spaceData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    Group = '*'
  } = spaceData;
  if (Group === '*') {
    return [...getAllFilterableIds(GroupData.all())];
  }
  return [...new Set(Group)];
};
export const getSelectedGroups = () => {
  const groups = GroupData.all().filter(g => !g.archived);
  return groups.filter(g => getIsGroupSelected({
    id: g.id
  })).map(g => g.id);
};
export const isNoGroupSelected = () => getIsGroupSelected({
  id: NO_ENTITY_ID
});
export const isGroupSelected = groupId => {
  const groupsFilter = getGroupsAsSpaceData();
  return groupsFilter === '*' || groupsFilter.includes(groupId);
};

// CUSTOM FIELD FILTERS

const setFilteredCustomFieldValuesById = (fieldId, selectedValues) => {
  state.filteredCustomFieldValuesById = state.filteredCustomFieldValuesById ?? new Map();
  setState('filteredCustomFieldValuesById', {
    id: fieldId,
    value: selectedValues
  });
};
const getCustomFieldFilterValues = fieldId => {
  if (state.filteredCustomFieldValuesById === null || !state.filteredCustomFieldValuesById.has(fieldId)) {
    const field = CustomFieldData.getById(fieldId);
    if (field) {
      setFilteredCustomFieldValuesById(field.id, getAllFilterableIds(field.values));
    }
  }
  return state.filteredCustomFieldValuesById?.get(fieldId);
};
export const isCustomFieldValueSelected = (fieldId, valueId) => {
  const filterValues = getCustomFieldFilterValues(fieldId);
  return filterValues && filterValues.has(valueId);
};
export const selectAllCustomFieldValues = field => {
  setFilteredCustomFieldValuesById(field.id, getAllFilterableIds(field.values));
};
export const toggleCustomFieldValueSelection = (fieldId, valueId) => {
  const selectedValues = getCustomFieldFilterValues(fieldId);
  if (selectedValues) {
    selectedValues.has(valueId) ? selectedValues.delete(valueId) : selectedValues.add(valueId);
    setFilteredCustomFieldValuesById(fieldId, selectedValues);
  } else {
    setFilteredCustomFieldValuesById(fieldId, new Set([valueId]));
  }
};
export const selectSingleCustomFieldValue = (fieldId, valueId) => {
  setFilteredCustomFieldValuesById(fieldId, new Set([valueId]));
};
export const selectNoCustomFieldValue = fieldId => {
  setFilteredCustomFieldValuesById(fieldId, new Set([NO_ENTITY_ID]));
};
export function getCustomFieldsAsSpaceData() {
  const customFields = CustomFieldData.getEnabledFields();
  return customFields.reduce((data, field) => {
    const selectedValues = getCustomFieldFilterValues(field.id);
    if (selectedValues && selectedValues.has(null) && field.values.every(value => selectedValues.has(value.id))) {
      data.push({
        fieldId: field.id,
        valueIds: '*'
      });
    } else if (selectedValues) {
      data.push({
        fieldId: field.id,
        valueIds: Array.from(selectedValues)
      });
    }
    return data;
  }, []);
}
export const setCustomFieldValuesFromSpaceData = _ref9 => {
  let {
    CustomField = []
  } = _ref9;
  const customFields = CustomFieldData.getEnabledFields();
  customFields.forEach(field => {
    const spaceDataField = CustomField.find(_ref10 => {
      let {
        fieldId
      } = _ref10;
      return fieldId === field.id;
    });
    const spaceData = spaceDataField?.valueIds ?? '*';
    const selectedCustomFieldValues = spaceData === '*' ? getAllFilterableIds(field.values) : new Set(spaceData);
    setFilteredCustomFieldValuesById(field.id, selectedCustomFieldValues);
  });
};
export function matchesCustomFieldFilters() {
  let storyCustomFields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return CustomFieldData.fieldsMatchFilterWithAND(Object.fromEntries(state.filteredCustomFieldValuesById), storyCustomFields);
}
export function resetFieldFilters() {
  CustomFieldData.getEnabledFields().forEach(selectAllCustomFieldValues);
}