import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';
import { isReadOnly } from 'data/entity/user';

/**
 * @deprecated
 * This component is needed for "Create Story" dialog because it doesn't use React. This should be removed once it is in React.
 * (See StoryAttributes used for Story dialog for an alternative implementation)
 */
export function NewStoryKeyboardShortcuts(_ref) {
  let {
    toggleCurrentUserAsOwner,
    openIterationDropdown,
    openStateDropdown,
    openEstimateDropdown
  } = _ref;
  useCommandBarCallback('toggleCurrentUserAsOwner', toggleCurrentUserAsOwner, {
    skip: isReadOnly()
  });
  useCommandBarCallback('openIterationSelector', openIterationDropdown, {
    skip: isReadOnly()
  });
  useCommandBarCallback('openWorkflowStateSelector', openStateDropdown, {
    skip: isReadOnly()
  });
  useCommandBarCallback('openEstimateSelector', openEstimateDropdown, {
    skip: isReadOnly()
  });
  return null;
}