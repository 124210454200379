import { QueryWorkspace2IdDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useWorkspaceSlug } from 'utils/navigation';
import { useQuery } from './useQuery';
export const QueryWorkspace2Id = QueryWorkspace2IdDocument;
export const useWorkspace2PublicId = () => {
  const slug = useWorkspaceSlug();
  const {
    data
  } = useQuery(QueryWorkspace2Id, {
    fetchPolicy: 'cache-only',
    variables: {
      slug
    }
  });
  return data?.workspace2?.publicId ?? null;
};