import Logo from '@clubhouse/assets/png/third-party-logos/loom-logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Loom() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.LOOM
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Loom"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Loom Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Users can paste videos from ", _jsx(ExternalLink, {
          href: "https://www.loom.com",
          children: "Loom"
        }), " to see real-time previews in markdown editors, including comments, to help explain complex ideas with pre-recorded video messages. The Loom Integration requires no set up - it should work out of the box with any shareable link from Loom."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_LOOM,
          children: "Learn more"
        }), " on how Loom embeds work in Shortcut."]
      })]
    })]
  });
}
Loom.displayName = "Loom";