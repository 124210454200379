import ConsolidatedFetch, { CONSOLIDATED_DATA_FETCHED_KEY, USER_ORG_AND_APPLICATION_STATE_FETCHED_KEY } from 'app/client/core/js/modules/consolidatedFetch';
import { getGlobal, useGlobal } from './globals';
export const useUserOrgAndApplicationStateFetched = () => useGlobal(USER_ORG_AND_APPLICATION_STATE_FETCHED_KEY);
export const useConsolidatedDataFetched = () => useGlobal(CONSOLIDATED_DATA_FETCHED_KEY);
export const fetchUserOrgAndApplicationState = function () {
  return ConsolidatedFetch.fetchUserOrgAndApplicationState(...arguments);
};
export const fetchConsolidatedDataAndActivity = function () {
  return ConsolidatedFetch.fetchConsolidatedDataAndActivity(...arguments);
};
export const isConsolidatedDataFetched = () => getGlobal(CONSOLIDATED_DATA_FETCHED_KEY);