import "core-js/modules/es.array.push.js";
import * as AboutTheBurndownChartTemplate from 'app/client/core/views/templates/reports/aboutTheBurndownChart.html';
import moment from 'moment';
import { capitalize } from '@clubhouse/shared/utils';
import { getOrgWorkingDays } from 'data/entity/organization';
import ReportModel from '../models/report';
import ReportsController from './reports';
import Url from '../modules/url';
import Utils from '../modules/utils';
import View from '../modules/view';
import { NON_WORKING_DAYS_LABEL, formatIterationDateRange, importChartingLibraries, mergeNonWorkingData, renderChartMetrics } from 'utils/charts';
import { isWorkingDay } from 'utils/date';
import { BurndownReportCard } from 'components/reports/burndown/Report';
import BurndownV2Screenshot from '@clubhouse/assets/png/screenshots/example-burndown-chart-v2.png';
const exports = {};
const BURNDOWN_CHART_ID = 'burndown';
const VELOCITY_TRAILING_AVG_COUNT_FOR_INTERVAL = {
  day: 7,
  week: 4,
  month: 3
};
exports.renderChartComponent = _ref => {
  let {
    chartData,
    errorMsg,
    options
  } = _ref;
  View.renderComponent({
    mountNode: $('#burndown-chart')[0],
    component: BurndownReportCard,
    props: {
      chartId: BURNDOWN_CHART_ID,
      chartData,
      errorMsg,
      renderChart: () => exports.renderBurndownChart({
        chartData,
        options
      })
    }
  });
};
const formatData = (burndownData, options) => {
  options = options || {};
  const {
    categoryAxisFormatter = momentDate => momentDate.format('YYYY-MM-DD'),
    shouldUseNullAfterToday = true
  } = options;
  const reportType = ReportModel.getReportingType();
  const groups = [['x'], ['Actual ' + reportType + ' Remaining']];
  let data = burndownData.data;
  const endDate = exports.getEndDate(options);
  const projectionInDays = exports.calculateProjectionInDays(data);
  const finiteProjection = isFinite(projectionInDays);
  const shouldUseProjection = !endDate && options.useProjection;
  const showProjection = shouldUseProjection && finiteProjection;
  const showIdealBurndown = endDate || !shouldUseProjection;
  let totalCompletedRangeInDays = null;
  if (showIdealBurndown) {
    groups.push(['Ideal ' + reportType + ' Remaining']);
    totalCompletedRangeInDays = exports.calculateCompletedRangeInDays(data, endDate || moment.utc());
    if (!options.lockRange) {
      data = exports.extendDayRange(burndownData, totalCompletedRangeInDays);
    }
  }
  if (showProjection) {
    groups.push(['Projected ' + reportType + ' Remaining']);
    data = exports.extendDayRange(burndownData, projectionInDays);
  }
  $('#burndown-projection-unavailable')[shouldUseProjection && !finiteProjection ? 'show' : 'hide']();
  const total = burndownData.total;
  const days = _.keys(showProjection ? burndownData.data : data).length;
  let currentDay = 0;
  const today = Utils.utcDay(exports.getToday());
  return _.reduce(data, (groups, storiesRemaining, date) => {
    const momentDate = moment.utc(date);
    const isAfterToday = momentDate.isAfter(today, 'day');
    const formattedDate = categoryAxisFormatter(momentDate);
    groups[0].push(formattedDate);
    groups[1].push(shouldUseNullAfterToday && isAfterToday ? null : storiesRemaining);
    if (showIdealBurndown) {
      const dayAsPercent = currentDay / (days - 1) * 100;
      const burndownRate = Math.max(Utils.roundToDecimal(total - total * dayAsPercent / 100, 2), 0);
      groups[2].push(burndownRate);
    }
    if (showProjection) {
      const projectedDayAsPercent = currentDay / projectionInDays * 100;
      const projectedBurndownRate = Math.max(Utils.roundToDecimal(total - total * projectedDayAsPercent / 100, 2), 0);
      groups[groups.length - 1].push(projectedBurndownRate);
    }
    currentDay++;
    return groups;
  }, groups);
};
exports.getEndDate = options => {
  const dueDay = options.dueDate && moment.utc(options.dueDate);
  const endDay = options.completedDate && moment.utc(options.completedDate);
  if (dueDay && endDay) {
    return moment.max(dueDay, endDay);
  } else {
    return dueDay || endDay;
  }
};
exports.calculateCompletedRangeInDays = (data, endDate) => {
  const firstDate = moment.utc(_.head(_.keys(data)));
  const diffBetweenStartAndCompleted = Math.abs(firstDate.diff(endDate, 'days'));
  return diffBetweenStartAndCompleted + 1;
};
exports.calculateProjectionInDays = data => {
  const dates = _.keys(data);
  const values = _.values(data);
  const lastDate = moment.utc(_.last(dates));
  const firstDate = moment.utc(_.head(dates));
  const firstValue = _.head(values);
  const lastValue = _.last(values);
  const diffBetweenStartAndLast = lastDate.diff(firstDate, 'days');
  const donePerDay = (firstValue - lastValue) / diffBetweenStartAndLast;
  const projectionInDays = firstValue / donePerDay;
  return Math.ceil(projectionInDays);
};
exports.calculateProjectionForNewBurndown = (data, dates) => {
  const values = Object.values(data);
  const lastDate = moment.max(dates);
  const lastIndex = dates.indexOf(lastDate);
  const lastValue = getValueOf(values[lastIndex].burndown);
  const firstDate = moment.min(dates);
  const firstIndex = dates.indexOf(firstDate);
  const firstValue = getValueOf(values[firstIndex].burndown);
  const diffBetweenStartAndLast = lastDate.diff(firstDate, 'days');
  const donePerDay = (firstValue - lastValue) / diffBetweenStartAndLast;
  const projectionInDays = firstValue / donePerDay;
  return Math.ceil(projectionInDays);
};
exports.extendDayRange = (burndownData, totalDays) => {
  const data = _.assign({}, burndownData.data);
  const dates = _.keys(data);
  if (dates.length < totalDays) {
    const lastDate = moment.utc(_.last(dates));
    _.times(totalDays - dates.length, () => {
      data[lastDate.add(1, 'day').format()] = null;
    });
  }
  return data;
};
exports.extendDayRangeForNewBurndown = (chartData, dates, totalDays, interval) => {
  const data = {
    ...chartData
  };
  const firstDay = moment.min(dates);
  const lastDay = firstDay.clone().add(totalDays, 'day');
  const totalIntervals = lastDay.diff(firstDay, `${interval}s`);
  const currentDate = firstDay;
  _.times(totalIntervals, () => {
    const formattedDate = currentDate.format('YYYY-MM-DD');
    data[formattedDate] = data[formattedDate] || undefined;
    currentDate.add(1, interval);
  });
  return data;
};
exports.getToday = () => {
  return moment();
};
exports.getImgSrc = () => {
  return BurndownV2Screenshot;
};
exports.openHelp = () => {
  // Epic, Milestone
  const entity = capitalize(Url.getCurrentPage());
  const imgSrc = exports.getImgSrc();
  ReportsController.openAboutThisChart({
    template: AboutTheBurndownChartTemplate
  }, {
    entity,
    imgSrc
  });
  return false;
};
exports.render = async (burndownData, options) => {
  const {
    c3
  } = await importChartingLibraries();
  options = options || {};
  const data = formatData(burndownData, options);
  const today = exports.getToday();
  const lastDay = moment.utc(_.last(_.head(data)));
  const dueDay = options.dueDate && moment.utc(options.dueDate);
  const endDay = options.completedDate && moment.utc(options.completedDate);
  const showEndDay = endDay && endDay.isBefore(lastDay, 'day');
  const showFuture = lastDay.isAfter(today, 'day');
  const lines = [];
  if (showFuture) {
    lines.push({
      value: today.format('YYYY-MM-DD'),
      text: 'Today'
    });
  }
  if (showEndDay) {
    lines.push({
      value: endDay.format('YYYY-MM-DD'),
      text: 'End Date'
    });
  }
  if (dueDay) {
    lines.push({
      value: dueDay.format('YYYY-MM-DD'),
      text: 'Target Date'
    });
  }
  const colors = {};
  const reportType = ReportModel.getReportingType();
  colors['Actual ' + reportType + ' Remaining'] = '#598fee'; // blue64
  colors['Ideal ' + reportType + ' Remaining'] = '#0db350'; // add new button green
  colors['Projected ' + reportType + ' Remaining'] = '#fbb81b'; // sunrise

  const chartOptions = {
    bindto: options.element || document.getElementById('burndown'),
    data: {
      x: 'x',
      xFormat: '%Y-%m-%d',
      columns: data,
      type: 'line',
      colors
    },
    point: {
      show: false
    },
    grid: {
      focus: {
        show: false
      },
      x: {
        lines
      }
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: '%b %e, %Y',
          values: _getDatesForAxis(data)
        }
      },
      y: {
        min: 0,
        padding: {
          bottom: 0
        }
      }
    },
    tooltip: {
      format: {
        title: data => ReportsController.formatDate({
          x: data[0].x
        }),
        value: value => _.isNumber(value) ? Number.parseInt(value, 10) : value
      }
    }
  };
  if (options.height) {
    chartOptions.size = {
      height: options.height
    };
  }
  return c3.generate(chartOptions);
};
const getAggregationType = () => {
  const currentAggregationType = ReportModel.getAggregationType().id;
  const urlParams = Url.parseLocationSearch();

  // Check in order of: burndown-specific param, generic reports param, global param
  return urlParams.burndown_aggregate_id || urlParams.reports_aggregate_id || currentAggregationType;
};
const getAggregationTypeLabel = () => {
  const AGGREGATION_TYPE_TO_LABEL = {
    stories: 'Stories',
    story: 'Stories',
    points: 'Points',
    point: 'Points'
  };
  return AGGREGATION_TYPE_TO_LABEL[getAggregationType()];
};
const AGGREGATION_TYPE_TO_OBJECT_KEY = {
  stories: 'count',
  story: 'count',
  points: 'points',
  point: 'points'
};
const getValueOf = valueData => {
  const key = AGGREGATION_TYPE_TO_OBJECT_KEY[getAggregationType()];
  return valueData[key];
};
const isAddedBarTallerThanBurndown = _ref2 => {
  let {
    added,
    burndown
  } = _ref2;
  return getValueOf(added) > getValueOf(burndown);
};

// Required velocity used to generate ideal line.
const getRequiredVelocity = (currentDate, dueDate, remainingCount, interval) => {
  let workingDaysUntilDueDate = 0;
  while (dueDate.isAfter(currentDate)) {
    currentDate = currentDate.add(1, interval);
    if (interval !== 'day' || isWorkingDay(currentDate.format('YYYY-MM-DD'))) {
      workingDaysUntilDueDate++;
    }
  }
  return remainingCount / workingDaysUntilDueDate;
};

// Calculated velocity used to generate projections. Velocity average uses the same
// interval counts as the Velocity chart's trailing average: 7 days, 4 weeks, 3 months.
// For 'day', averages are divided by the number of Working Days only.
const getCalculatedVelocity = (velocities, interval) => {
  const trailingCount = VELOCITY_TRAILING_AVG_COUNT_FOR_INTERVAL[interval] ?? 0;
  const trailingIntervals = velocities.slice(-trailingCount);
  const workingCount = interval !== 'day' ? trailingCount : trailingIntervals.filter(v => v.isWorkingInterval).length;
  return workingCount > 0 ? trailingIntervals.map(v => v.velocity).reduce((a, b) => a + b, 0) / workingCount : 0;
};
exports.renderBurndownChart = async _ref3 => {
  let {
    chartData,
    options = {}
  } = _ref3;
  const {
    c3,
    d3
  } = await importChartingLibraries();
  const momentFormatString = 'MMM DD, YYYY';
  const aggregationTypeLabel = getAggregationTypeLabel();
  const labels = {
    actual: `Actual ${aggregationTypeLabel} Remaining`,
    ideal: `Ideal ${aggregationTypeLabel} Remaining`,
    projected: `Projected ${aggregationTypeLabel} Remaining`,
    unchanged: `Unchanged ${aggregationTypeLabel}`,
    added: `${aggregationTypeLabel} Added`,
    removed: `${aggregationTypeLabel} Removed`,
    completed: `${aggregationTypeLabel} Completed`
  };
  const data = {
    ...chartData
  };
  const today = Utils.utcDay(exports.getToday());
  const interval = options.interval && options.interval.id !== 'none' ? options.interval.id : 'day';
  const isGroupByIteration = interval === 'iteration';
  let dates = Object.keys(data).sort((a, b) => {
    return ReportModel.reportStringToMoment(a).valueOf() - ReportModel.reportStringToMoment(b).valueOf();
  });

  // Calculate Ideal and Projection Lines start date
  const firstDateInRange = Utils.utcDay(ReportModel.reportStringToMoment(dates[0]));
  const startDateOfLastInterval = Utils.utcDay(ReportModel.reportStringToMoment(dates[dates.length - 1]));

  // Get last date of last interval
  let lastDateInRange = startDateOfLastInterval;
  if (interval === 'week') {
    lastDateInRange = startDateOfLastInterval.clone().add(6, 'days');
  } else if (interval === 'month') {
    const day = startDateOfLastInterval.date();
    const month = startDateOfLastInterval.month();
    const year = startDateOfLastInterval.year();

    // Order of operations here is important, do not change.
    lastDateInRange = moment().date(day).year(year).month(month + 1).subtract(1, 'day');
  }

  // Determine if today is in entire range
  const todayIsInRange = today.isBetween(firstDateInRange, lastDateInRange, null, '[]');

  // Get the line start date (The interval containing Today or the first interval)
  let lineStartDate;
  if (today.isBetween(startDateOfLastInterval, lastDateInRange, null, '[]')) {
    // Today is within last interval. Checking for this first since its the most likely to occur.
    lineStartDate = startDateOfLastInterval;
  } else if (today.isBetween(firstDateInRange, startDateOfLastInterval, null, '[)')) {
    // Find the interval containing Today if it exists.
    if (interval === 'day') {
      lineStartDate = today;
    } else {
      for (let i = 0; i < dates.length - 1; i++) {
        if (today.isBetween(dates[i], dates[i + 1], null, '[)')) {
          lineStartDate = Utils.utcDay(ReportModel.reportStringToMoment(dates[i]));
          break;
        }
      }
    }
  } else {
    // If Today is not within range, use the first date
    lineStartDate = firstDateInRange;
  }
  const velocities = []; // Velocity is completed count per interval
  let requiredVelocity = undefined; // Required velocity to finish by due date
  let calculatedVelocity = undefined; // Calculated velocity for projection
  let idealRemaining = 0; // Count of stories/points remaining for ideal line
  let idealLineCompleted = false; // Track if ideal line has reached 0
  let projectedRemaining = 0; // Count of stories/points remaining for projection line
  let projectedEndDate = undefined; // N/A for infinite projections, date if valid projection
  let numProjectionsDisplayed = 0; // Number of intervals displayed for projection

  const numDatesInRange = dates.length;
  const hasCompletedDate = !!options.completedDate;
  const hasDueDate = !!options.dueDate;
  const dueDateInterval = hasDueDate ? dates[dates.length - 1] : undefined;
  /** If we do not have a `lineStartDate` value then we cannot determine whether the due date comes before the line start,
   *  therefore we want to fallback to `false`.
   **/
  const dueDateIsBeforeLineStartDate = dueDateInterval && lineStartDate ? moment(dueDateInterval).startOf('day').isBefore(lineStartDate.startOf('day')) : false;
  const showProjectionLine = !hasCompletedDate && !hasDueDate && Url.getCurrentPage() !== 'reports';
  // If `lineStartDate` is undefined we don't want to show the ideal line.
  const showIdealLine = Boolean(lineStartDate) && !hasCompletedDate && hasDueDate && !dueDateIsBeforeLineStartDate;
  let formattedDates = [];
  let iterationFormattedDates = [];
  const addedData = [];
  const actuals = [];
  const ideals = [];
  const projecteds = [];
  const unchanged = [];
  const added = [];
  const removed = [];
  const completed = [];

  // We must iterate over dates as such because we'll be adding to 'dates' array from within the loop
  for (const date of dates) {
    const isWorkingInterval = interval === 'day' && isWorkingDay(date);
    const index = dates.indexOf(date);
    /** If `lineStartDate` is undefined, we are not able to determine whether the current date is the same as the line start date,
     * therefore we want to fallback to `false` for dateIsLineStartDate value.
     **/
    const dateIsLineStartDate = lineStartDate?.isSame(moment(date), 'day') ?? false;

    /** If `lineStarteDate` is undefined, we are not able to determine whether the current date comes after the line start date,
     * therefore we want to fallback to `false` for dateIsAfterLineStartDate value.
     **/
    const dateIsAfterLineStartDate = Boolean(lineStartDate) && !dateIsLineStartDate ? moment(date).isAfter(lineStartDate) : false;
    formattedDates.push(ReportModel.reportStringToMoment(date).format(momentFormatString));
    if (data[date]) {
      const {
        burndown: burndownOnDate,
        added: addedOnDate,
        removed: removedOnDate,
        completed: completedOnDate
      } = data[date];
      const burndownValue = getValueOf(burndownOnDate);
      const addedValue = getValueOf(addedOnDate);
      const removedValue = getValueOf(removedOnDate);
      const completedValue = getValueOf(completedOnDate);
      const addedBarIsTallerThanBurndown = isAddedBarTallerThanBurndown({
        added: addedOnDate,
        burndown: burndownOnDate
      });

      // Update data for chart bars
      actuals.push(burndownValue);
      unchanged.push(addedBarIsTallerThanBurndown ? 0 : burndownValue - addedValue);
      added.push(addedBarIsTallerThanBurndown ? burndownValue : addedValue);
      removed.push(removedValue);
      completed.push(completedValue);
      addedData.push(addedValue);
      if (!showIdealLine && !showProjectionLine) continue;

      // Calculated velocity (for projections) includes dates prior to the line start date
      if (!dateIsLineStartDate && !dateIsAfterLineStartDate) {
        velocities.push({
          isWorkingInterval,
          velocity: completedValue
        });
      }
      if (idealRemaining === 0 || !hasDueDate || !dateIsAfterLineStartDate) {
        idealRemaining = burndownValue;
        projectedRemaining = burndownValue;
      }

      // Prior to the line start date, no ideal or projection line is displayed
      if (!dateIsAfterLineStartDate && !dateIsLineStartDate) {
        ideals.push(null);
        projecteds.push(null);
      }

      // Ideal line begins on the line start date and is calculated using required velocity
      if (hasDueDate && dateIsLineStartDate) {
        requiredVelocity = getRequiredVelocity(moment(date), moment(dueDateInterval), idealRemaining, interval);
        ideals.push(idealRemaining);
      }

      // Ideal remaining decreases by required velocity on working intervals
      if (hasDueDate && dateIsAfterLineStartDate) {
        idealRemaining = idealLineCompleted ? 0 : idealRemaining - (isWorkingInterval ? requiredVelocity : 0);
        ideals.push(idealRemaining);
      }
      if ((dateIsLineStartDate || dateIsAfterLineStartDate) && idealRemaining <= 0) idealLineCompleted = true;
    }

    // Projection line begins on the line start date
    if (dateIsLineStartDate) calculatedVelocity = getCalculatedVelocity(velocities, interval);
    if (!hasCompletedDate && !projectedEndDate && (dateIsLineStartDate || dateIsAfterLineStartDate)) {
      // Cancel infinite projections after 3 intervals
      if (calculatedVelocity <= 0 && numProjectionsDisplayed > 3) {
        projectedEndDate = 'N/A';
        continue;
      }
      projecteds.push(projectedRemaining);
      numProjectionsDisplayed++;
      if (projectedRemaining <= 0) {
        projectedEndDate = moment(date).format('MMM D, YYYY');
        continue;
      }

      // Calculate next projection
      projectedRemaining = projectedRemaining - (isWorkingInterval ? calculatedVelocity : 0);

      // More dates are required to complete a projection. Cap at 100 just in case.
      if (index === dates.length - 1 && numProjectionsDisplayed < 100) dates.push(moment(date).add(1, `${interval}s`).format('YYYY-MM-DD'));
    }
  }
  if (numProjectionsDisplayed === 100 && projectedRemaining > 0) {
    // We still calculate the end date for the projection metric, even though we capped the line at 100 intervals
    let lastDate = moment(dates[dates.length - 1]);
    while (projectedRemaining > 0) {
      lastDate = lastDate.add(1, `${interval}s`);
      const isWorkingInterval = interval !== 'day' || isWorkingDay(lastDate.format('YYYY-MM-DD'));
      projectedRemaining = projectedRemaining - (isWorkingInterval ? calculatedVelocity : 0);
    }
    projectedEndDate = moment(lastDate).format('MMM D, YYYY');
  }
  if (showIdealLine) {
    // Truncate date arrays so that potential projection dates beyond the due date are not displayed
    dates = dates.slice(0, numDatesInRange);
    formattedDates = formattedDates.slice(0, numDatesInRange);
  }
  const lines = [];
  if (interval === 'day') {
    if (todayIsInRange) {
      lines.push({
        value: today.format(momentFormatString),
        text: 'Today'
      });
    }
    if (options.useProjection && options.dueDate) {
      lines.push({
        value: moment.utc(options.dueDate).format(momentFormatString),
        text: 'Target Date'
      });
    }
  }
  if (isGroupByIteration) {
    iterationFormattedDates = dates.map(date => {
      const {
        iteration_start,
        iteration_end
      } = data[date];
      return formatIterationDateRange(iteration_start, iteration_end);
    });
  }
  const workingDays = getOrgWorkingDays();
  const c3Input = {
    bindto: options.element || document.getElementById('burndown'),
    data: {
      x: 'x',
      columns: [['x', ...(isGroupByIteration ? iterationFormattedDates : formattedDates)], [labels.actual, ...actuals], showProjectionLine ? [labels.projected, ...projecteds] : [labels.ideal, ...ideals], [labels.unchanged, ...unchanged], [labels.added, ...added], [labels.removed, ...removed], [labels.completed, ...completed]],
      types: {
        [labels.actual]: 'area-step',
        [showProjectionLine ? labels.projected : labels.ideal]: 'line',
        [labels.added]: 'bar',
        [labels.removed]: 'bar',
        [labels.completed]: 'bar',
        [labels.unchanged]: 'bar'
      },
      groups: [[labels.unchanged, labels.added, labels.removed, labels.completed], [labels.actual]],
      colors: {
        [labels.actual]: '#3469B2',
        [showProjectionLine ? labels.projected : labels.ideal]: '#999',
        [labels.added]: '#B390E3',
        [labels.removed]: '#E9C15B',
        [labels.completed]: '#72d090'
      },
      order: () => {
        return 0;
      }
    },
    bar: {
      width: {
        ratio: 1
      }
    },
    line: {
      step: {
        type: 'step'
      }
    },
    point: {
      show: false
    },
    grid: {
      focus: {
        show: false
      },
      x: {
        lines: lines
      }
    },
    axis: {
      x: {
        type: 'category',
        tick: {
          multiline: false,
          ...(!isGroupByIteration && {
            format: index => {
              const MAX_LABEL_COUNT = 5;
              const mod = Math.floor(dates.length / MAX_LABEL_COUNT);
              if (dates.length <= MAX_LABEL_COUNT || index % mod === 0) {
                return moment.utc(dates[index]).format('MMM D, YYYY');
              }
            }
          })
        }
      },
      y: {
        min: 0,
        padding: {
          bottom: 0
        },
        tick: {
          format: d3.format('d')
        }
      }
    },
    legend: {
      hide: [labels.unchanged]
    },
    tooltip: {
      format: {
        title: index => {
          const dateString = dates[index];
          if (interval === 'iteration') {
            return chartData[dateString]?.iteration_name;
          }
          return ReportModel.reportStringToMoment(dateString).format(`dddd, ${momentFormatString}`);
        },
        value: (value, ratio, id, index) => {
          if (id === labels.unchanged || id === NON_WORKING_DAYS_LABEL) {
            return undefined;
          }
          if (id === labels.added) {
            return addedData[index];
          }
          const rounded = Math.round(value, 10);
          return rounded < 0 ? 0 : rounded;
        }
      }
    }
  };
  const workingDaysTooltips = {
    tooltip: {
      format: {
        title: index => {
          const title = ReportModel.reportStringToMoment(dates[index]).format(`dddd, ${momentFormatString}`);
          if (!workingDays.includes(moment(dates[index]).day())) {
            // `workingDays` will include all days of the week when the feature
            // flag is disabled, so this block will never be used.
            return `${title} (No Work)`;
          }
          return title;
        }
      }
    }
  };
  const c3InputWithNonWorkingDays = mergeNonWorkingData(workingDays, options.interval.id, c3Input, workingDaysTooltips);
  const burndownChart = c3.generate(c3InputWithNonWorkingDays);
  const metrics = {};
  const intervalLabel = capitalize(interval);
  const velocityLabel = `${aggregationTypeLabel} Per ${intervalLabel}`;
  if (showProjectionLine || showIdealLine) {
    const trailingCount = VELOCITY_TRAILING_AVG_COUNT_FOR_INTERVAL[interval] ?? 0;
    const trailingVelocityLabel = `${trailingCount} ${intervalLabel} Trailing Average Velocity`;
    metrics['Projected End Date'] = projectedEndDate;
    metrics[trailingVelocityLabel] = `${Number.parseFloat(calculatedVelocity?.toFixed(2))} ${velocityLabel}`;
  }
  if (showIdealLine) {
    metrics['Required Velocity to Finish Work by Target Date'] = `${requiredVelocity === Number.POSITIVE_INFINITY ? idealRemaining : Number.parseFloat(requiredVelocity?.toFixed(2))} ${velocityLabel}`;
  }
  renderChartMetrics({
    chartId: BURNDOWN_CHART_ID,
    metrics
  });
  return burndownChart;
};
function _getDatesForAxis(data) {
  const dates = _.tail(_.head(data));
  const COUNT = 5;
  if (dates.length <= COUNT * 2) {
    return dates;
  }
  const mod = Math.floor(dates.length / COUNT);
  return _.filter(dates, (date, index) => {
    return index % mod === 0;
  });
}
export { exports as default };