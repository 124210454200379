import { QueryApplicationStateDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
export const QUERY_APPLICATION_STATE = QueryApplicationStateDocument;

/**
 * @description Prefer `useApplicationStateByKey`.
 */
export const useApplicationState = function () {
  let {
    onCompleted,
    fetchPolicy
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const slug = useWorkspaceSlug();
  const {
    data,
    updateQuery,
    startPolling,
    stopPolling,
    refetch,
    loading,
    error
  } = useQuery(QUERY_APPLICATION_STATE, {
    variables: {
      slug: slug
    },
    onCompleted,
    fetchPolicy,
    skip: !slug
  });
  return {
    parsedCurrentUserPermissionSpaceData: data?.workspace2?.parsedCurrentUserPermissionSpaceData,
    updateQuery,
    loading,
    error,
    startPolling,
    stopPolling,
    refetch
  };
};