import { getSelection, hasOpenCodeBlock, hasOpenInlineCode } from '../../utils';
import { parseHtmlToMarkdown } from './html';
const getDataTransferValue = item => new Promise(resolve => {
  if (item) item.getAsString(resolve);else resolve(null);
});
export const pasteCommand = function () {
  let {
    disablePasteWithFormatting
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'Paste',
    fn() {
      /* Do nothing */
    },
    events: {
      paste: async (e, textarea, setValue, pressedKeys) => {
        if (disablePasteWithFormatting) return;
        if (e.defaultPrevented) return;
        if (!e.clipboardData?.items?.length) return;
        if (e.clipboardData.types.includes('Files')) return;
        if (pressedKeys.Shift) return;
        e.preventDefault();
        const {
          before,
          after
        } = getSelection(textarea);
        const items = [...e.clipboardData.items];
        const [markdown, text] = await Promise.all([getDataTransferValue(items.find(item => item.type === 'text/html')).then(parseHtmlToMarkdown), getDataTransferValue(items.find(item => item.type === 'text/plain'))]);
        if (markdown && !hasOpenCodeBlock(before) && !hasOpenInlineCode(before)) {
          setValue(`${before}${markdown}${after}`, {
            selectionStart: before.length + markdown.length,
            selectionEnd: before.length + markdown.length
          });
        } else if (text) {
          setValue(`${before}${text}${after}`, {
            selectionStart: before.length + text.length,
            selectionEnd: before.length + text.length
          });
        }
      }
    }
  };
};