import ZendeskLogo from '@clubhouse/assets/png/third-party-logos/zendesk_128x128.png';
import { useSingleClick } from '@clubhouse/shared/hooks';
import { getLoggedInUserProfiles } from 'data/entity/user';
import { usePageSearch, useSearchParams } from 'utils/navigation';
import { getPermissionsCopy } from 'utils/oauth';
import { allowZendeskAuthorization, denyZendeskAuthorization } from 'utils/zendeskAuthorization';
import { IntegrationInterstitial } from './IntegrationInterstitial';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ZendeskIntegrationInterstitial = () => {
  const search = usePageSearch();
  // We grab the query params from the URI to help validate and populate the UI
  const {
    params
  } = useSearchParams(['response_type', 'client_id', 'redirect_uri', 'scope', 'state', 'code']);
  const {
    response_type,
    client_id,
    redirect_uri,
    scope,
    state
  } = params;
  const permissions = getPermissionsCopy(scope);
  const paramsExist = params => params.every(param => param.length);

  // We need the redirect_uri in order to redirect if user denies the auth
  const canDeny = paramsExist([redirect_uri]);

  // We need all other uri params in order to allow, as the backend requires them to be there
  const canAllow = canDeny && paramsExist([response_type, client_id, scope, state]);
  const {
    fn: onAllow
  } = useSingleClick(currentWorkspace => {
    const params = search + '&workspace_slug=' + currentWorkspace;
    return allowZendeskAuthorization(params);
  });
  const {
    fn: onDeny
  } = useSingleClick(() => denyZendeskAuthorization(params.redirect_uri));
  const workspaces = getLoggedInUserProfiles().flatMap(profile => profile.permissions.filter(permission => permission.role === 'admin' && !permission.workspace2.disabled && !permission.workspace2.is_public_demo)).map(permission => permission.workspace2);
  return _jsx(IntegrationInterstitial, {
    name: "Zendesk",
    logo: _jsx("img", {
      alt: "",
      src: ZendeskLogo,
      className: "invert-for-dark-mode"
    }),
    canDeny: canDeny,
    canAllow: canAllow,
    onDeny: onDeny,
    permissions: permissions,
    onAllow: onAllow,
    workspaces: workspaces
  });
};
ZendeskIntegrationInterstitial.displayName = "ZendeskIntegrationInterstitial";