import { capitalize } from '@clubhouse/shared/utils';
import ApplicationState from '../modules/applicationState';
import DropdownController from './dropdown';
import * as Event from '../_frontloader/event';
import Tooltip from '../modules/tooltip';
import { sendStoriesViewSettingsEvent } from 'utils/segment';
import { applyCardDensity, applyCollapseState, getDefaultViewSettings } from 'utils/viewSettings';
const exports = {};
exports.LOCALSTORAGE_STORY_DENSITY_KEY = 'StoryDensity';
exports.LOCALSTORAGE_CARD_COLOR_KEY = 'CardColor';
const LOCALSTORAGE_COLLAPSE_STATE_KEY = 'CollapseEmptyColumns';
exports.PARENT_SELECTOR = '#story-density';
exports.DENSITY_OPTIONS = {
  TITLE_ONLY: 'title-only',
  MINIMAL: 'minimal',
  STANDARD: 'standard',
  JUMBO: 'jumbo'
};
const supportedTypes = Object.values(exports.DENSITY_OPTIONS);
const DEFAULT_CARD_COLOR = 'story-type';
const getCardDensityFromAppState = () => ApplicationState.get(exports.LOCALSTORAGE_STORY_DENSITY_KEY) ?? getDefaultViewSettings().cardDensity;
const getCardDensity = () => {
  return getCardDensityFromAppState();
};
const getCardColor = () => {
  return ApplicationState.get(exports.LOCALSTORAGE_CARD_COLOR_KEY) ?? DEFAULT_CARD_COLOR;
};
const getCollapseStateFromAppState = () => ApplicationState.get(LOCALSTORAGE_COLLAPSE_STATE_KEY) ?? false;
const getCollapseState = () => {
  return getCollapseStateFromAppState();
};
exports.updateCardDensity = cardDensity => {
  ApplicationState.set(exports.LOCALSTORAGE_STORY_DENSITY_KEY, cardDensity);
  applyCardDensity(cardDensity);
  return;
};
const setCardDensityType = type => {
  if (supportedTypes.includes(type)) {
    exports.updateCardDensity(type);
    Event.trigger('StoryDensity.Change');
    sendStoriesViewSettingsEvent({
      setting_type: 'view_density',
      selection: type
    });
  }
  return;
};
const toggleCollapseEmptyColumns = forceToggle => {
  const collapseState = applyCollapseState(forceToggle);
  ApplicationState.set(LOCALSTORAGE_COLLAPSE_STATE_KEY, collapseState);
  Event.trigger('CollapseEmptyColumns.Change');
};
const setCardColorType = cardColor => {
  ApplicationState.set(exports.LOCALSTORAGE_CARD_COLOR_KEY, cardColor);
  sendStoriesViewSettingsEvent({
    setting_type: 'card_color',
    selection: cardColor
  });
  return;
};
const items = (currentDensity, currentCardColor) => {
  const storyDensityItems = Object.values(exports.DENSITY_OPTIONS).map(type => {
    return {
      name: capitalize(type.replace(/-/g, ' ')),
      className: currentDensity === type ? 'active always-show-right-icon' : '',
      value: () => exports.density.set(type),
      iconRight: currentDensity === type ? 'fa-check' : undefined,
      iconRightColor: 'white'
    };
  });
  const cardColorItems = Object.values(exports.SUPPORTED_CARD_COLOR).map(color => ({
    name: capitalize(color.replace(/-/g, ' ')),
    className: currentCardColor === color ? 'active always-show-right-icon' : '',
    value: () => exports.cardColor.set(color),
    iconRight: currentCardColor === color ? 'fa-check' : undefined,
    iconRightColor: 'white'
  }));
  return [{
    html: `<div class='view-settings-sub-title'>VIEW DENSITY</div>`
  }, ...storyDensityItems, {
    html: `<div class='view-settings-sub-title'>CARD COLOR</div>`
  }, ...cardColorItems];
};
exports.openViewSettingsDropdown = function () {
  Tooltip.close();
  const currentDensity = exports.density.get();
  const currentCardColor = exports.cardColor.get();
  DropdownController.open({
    items: items(currentDensity, currentCardColor),
    title: 'View Settings',
    target: this,
    topOffset: 0,
    width: 232
  });
};
const applyCardDensityFromAppState = () => {
  applyCardDensity(getCardDensityFromAppState());
};
const applyCollapseStateFromAppState = () => {
  applyCollapseState(getCollapseStateFromAppState());
};
exports.applyViewSettingsFromAppState = () => {
  applyCardDensityFromAppState();
  applyCollapseStateFromAppState();
};
const MINIMUM_CARD_HEIGHTS = {
  [exports.DENSITY_OPTIONS.TITLE_ONLY]: 38,
  [exports.DENSITY_OPTIONS.MINIMAL]: 74,
  [exports.DENSITY_OPTIONS.STANDARD]: 74,
  [exports.DENSITY_OPTIONS.JUMBO]: 84
};
exports.getMinimumCardHeight = () => {
  return MINIMUM_CARD_HEIGHTS[exports.density.get()];
};
exports.density = {
  // I'm not sure if putting the constants in the same place as the other functions is the right
  // approach, but figured I'd do it here just for the demo.
  options: exports.DENSITY_OPTIONS,
  getMinimumCardHeight: exports.getMinimumCardHeight,
  get: getCardDensity,
  isTitleOnly: () => exports.density.get() === exports.DENSITY_OPTIONS.TITLE_ONLY,
  isMinimal: () => exports.density.get() === exports.DENSITY_OPTIONS.MINIMAL,
  isStandard: () => exports.density.get() === exports.DENSITY_OPTIONS.STANDARD,
  isJumbo: () => exports.density.get() === exports.DENSITY_OPTIONS.JUMBO,
  set: setCardDensityType,
  setTitleOnly: () => exports.density.set(exports.DENSITY_OPTIONS.TITLE_ONLY),
  setMinimal: () => exports.density.set(exports.DENSITY_OPTIONS.MINIMAL),
  setStandard: () => exports.density.set(exports.DENSITY_OPTIONS.STANDARD),
  setJumbo: () => exports.density.set(exports.DENSITY_OPTIONS.JUMBO)
};
exports.cardColor = {
  get: getCardColor,
  set: setCardColorType
};
exports.collapseState = {
  toggle: toggleCollapseEmptyColumns,
  get: getCollapseState
};
export { exports as default };