import { useEffect, useState } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/gitlab_logo.png';
import { Button } from '@clubhouse/shared/components/Button';
import { FixedSpacer, Spaced } from '@clubhouse/shared/components/Spaced';
import { TextInput } from '@clubhouse/shared/components/TextInput';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { fetchAll as fetchAllEvents } from 'data/entity/event';
import { ApplicationIds, disableByApplicationId, disableInstallation, enableInstallation, fetchByApplicationId, getInstallation, installByApplicationId, update, useInstallationByApplicationId } from 'data/entity/installation';
import { isLoggedInUserAdmin, isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { EVENTS } from 'utils/monitoring';
import { PAGE_NAMES, generatePathForPage, getCurrentSlug, navigateOnClick } from 'utils/navigation';
import { sendEvent } from 'utils/segment';
import { CommentOnPrToggle } from './components/CommentOnPrToggle';
import { CopyWebhookUrl } from './components/CopyWebhookUrl';
import { CreateEventDefinition } from './components/CreateEventDefinition';
import { EventDefinitionsTable } from './components/EventDefinitionsTable';
import { EventHandlerToggle } from './components/EventHandlerToggle';
import { ExternalLink } from './components/ExternalLink';
import { GitBranchFormatting } from './components/GitBranchFormatting';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const ApiAccessTokenForm = _ref => {
  let {
    installation
  } = _ref;
  const [token, setToken] = useState('');
  const [isSaving, toggleSaving] = useToggleState();
  return _jsx("form", {
    style: {
      width: '100%'
    },
    onSubmit: async e => {
      e.preventDefault();
      if (!token.trim()) return;
      try {
        toggleSaving.on();
        await update(installation, {
          token: token.trim()
        });
        setToken('');
        addToast({
          kind: 'success',
          timeout: 5_000,
          Content: () => _jsx(ToastText, {
            children: "Access token updated!"
          })
        });
      } catch (err) {
        addToast({
          kind: 'alert',
          timeout: 10_000,
          Content: () => _jsx(ToastText, {
            children: "Access token could not be updated."
          })
        });
      } finally {
        toggleSaving.off();
      }
    },
    children: _jsxs(Spaced, {
      vertically: true,
      amount: "space1",
      children: [_jsx(Layout.SectionTitle, {
        children: "API Access Token"
      }), _jsx(TextInput, {
        isDisabled: isSaving,
        placeholder: installation.has_token ? '•••••••' : '',
        value: token,
        onChange: setToken
      }), _jsx(Button, {
        type: "submit",
        children: "Update API Access Token"
      })]
    })
  });
};
ApiAccessTokenForm.displayName = "ApiAccessTokenForm";
export function GitLab() {
  const admin = isLoggedInUserAdmin();
  const readOnly = isReadOnly();
  const [isReady, toggleReady] = useToggleState();
  const manageWorkflowUrl = generatePathForPage(PAGE_NAMES.SETTINGS_WORKFLOW, {
    slug: getCurrentSlug()
  });
  const installation = useInstallationByApplicationId(ApplicationIds.Gitlab);
  const isEnabled = !!installation && !installation.disabled;
  useEffect(() => {
    fetchByApplicationId(ApplicationIds.Gitlab).then(() => {
      const installation = getInstallation(ApplicationIds.Gitlab);
      if (!installation) return installByApplicationId(ApplicationIds.Gitlab).then(() => disableByApplicationId(ApplicationIds.Gitlab));
    }).then(fetchAllEvents).finally(toggleReady.on);
  }, [toggleReady.on]);
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.GITLAB
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "GitLab"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "GitLab Logo",
        invertOnDarkMode: true
      }), !admin && _jsx(Layout.ReadOnlyMessage, {}), _jsxs(Layout.Blurb, {
        children: ["With our GitLab integration, you can link stories to commits, branches and merge requests, and use your GitLab workflow to move stories across your ", BRAND.NAME, " workflow."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_GITLAB,
          children: "our instructions"
        }), " for how to configure this integration in your GitLab account, and", ' ', _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.GUIDE_VCS,
          children: "our guide"
        }), " for how to use our GitLab integration."]
      }), _jsx(Layout.SectionTitle, {
        children: "Integration Status"
      }), isReady ? _jsx(IntegrationToggle, {
        isReadOnly: !admin,
        isEnabled: isEnabled,
        onChange: async newValue => {
          if (!installation) return;
          sendEvent(EVENTS.Interaction_IntegrationSettings, {
            integration_action: newValue ? 'on' : 'off',
            integration_type: 'gitlab'
          });
          try {
            if (newValue) await enableInstallation(installation);else await disableInstallation(installation);
            addToast({
              kind: 'success',
              timeout: 5_000,
              Content: () => _jsxs(ToastText, {
                children: ["Integration ", newValue ? 'enabled' : 'disabled', "!"]
              })
            });
          } catch (e) {
            addToast({
              kind: 'alert',
              timeout: 10_000,
              Content: () => _jsxs(ToastText, {
                children: ["Unable to ", newValue ? 'enable' : 'disable', " integration."]
              })
            });
          }
        }
      }) : _jsx(IntegrationToggle.Loading, {}), _jsx(FixedSpacer, {
        size: "space3"
      }), admin && _jsxs(Spaced, {
        vertically: true,
        amount: "space6",
        overflow: "hidden",
        children: [installation && installation.gitlab_api_access_disabled && _jsxs(Layout.WarningMessage, {
          children: [_jsx("strong", {
            children: "Your GitLab integration cannot access the GitLab API"
          }), ", resulting in the following limitations:", _jsxs("ul", {
            style: {
              fontSize: 14
            },
            children: [_jsx("li", {
              children: "Push events that have more than 20 commits won't be fully processed; commits beyond the first 20 won't be visible to our integration"
            }), _jsx("li", {
              children: "Shortcut Stories linked to GitLab Merge Requests will not show diff stats (lines added/removed) and may be missing Merge Request labels"
            }), _jsx("li", {
              children: "Shortcut Stories will not be able to highlight overlapping Merge Requests"
            })]
          }), "Your Shortcut Workspace's administrators should have received an email with more details about the issue we encountered. You can re-enable GitLab API access by disabling and then re-enabling this integration by using the toggle above. If we are still unable to access the GitLab API, that access will be automatically disabled again and your Workspace's administrators will receive a new email with details regarding the issue."]
        }), _jsx(GitBranchFormatting, {}), isEnabled && _jsxs(_Fragment, {
          children: [_jsx(ApiAccessTokenForm, {
            installation: installation
          }), _jsx(CopyWebhookUrl, {
            label: "GitLab Payload URL",
            installation: installation
          }), _jsx(CommentOnPrToggle, {
            type: "gitlab"
          }), _jsxs(Spaced, {
            vertically: true,
            amount: 0,
            children: [_jsx(Layout.SectionTitle, {
              children: "GitLab Event Handlers"
            }), _jsxs(Layout.HelpMessage, {
              noMargin: true,
              children: ["If you already use Merge Requests and Branches in GitLab, you can streamline your process by letting your GitLab workflow drive your", ' ', _jsxs("a", {
                href: manageWorkflowUrl,
                onClick: navigateOnClick(manageWorkflowUrl),
                children: [BRAND.NAME, " workflow"]
              }), "."]
            }), _jsx(FixedSpacer, {
              size: "space3"
            }), _jsx(EventHandlerToggle, {
              type: "gitlab"
            })]
          }), _jsxs(Spaced, {
            vertically: true,
            amount: "space2",
            children: [_jsx(EventDefinitionsTable, {
              type: "gitlab"
            }), _jsx(CreateEventDefinition, {
              type: "gitlab"
            })]
          })]
        })]
      })]
    })]
  });
}
GitLab.displayName = "GitLab";