import { ColorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { getTint } from '@clubhouse/shared/color';
const ColorFragment = ColorFragmentDoc;
const semanticColorLookup = {
  storyType_bug: 'var(--shapes-errorMain)',
  storyType_chore: 'var(--shapes-iconMain)',
  storyType_feature: 'var(--iconYellowColor)',
  priority_highest: '#A32C3C',
  priority_high: '#CC374B',
  priority_medium: '#F69752',
  priority_low: '#3A95C9',
  priority_lowest: '#2D78A4',
  severity_0: '#A32C3C',
  severity_1: '#CC374B',
  severity_2: '#F69752',
  severity_3: '#3A95C9',
  severity_4: '#2D78A4',
  severity_5: '#8F809D',
  severity_6: '#452B5B'
};
export const getColor = color => {
  if (!color) {
    return;
  }
  if (color.colorKey) {
    return getTint(color.colorKey);
  }
  if (color.hexadecimalColor) {
    return color.hexadecimalColor;
  }
  if (color.semanticColor) {
    return semanticColorLookup[color.semanticColor];
  }
};