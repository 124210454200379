import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import Constants from './constants';
import Globals from '../_frontloader/globals';
import Log from './log';
import User from './user';
import Utils from './utils';
const exports = {};
const DISABLED_KEY = 'storageIsDisabled';
const INITIALIZED_KEY = 'storageIsInitialized';
const PSEUDO_STORAGE_KEY = 'PseudoLocalStorage';
const LISTENERS_KEY = 'localStorageListeners';
function _getPseudoLocalStorage() {
  Globals.setOnlyIfMissing(PSEUDO_STORAGE_KEY, {});
  return Globals.get(PSEUDO_STORAGE_KEY);
}
function _setItem(key, value) {
  let retryCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (exports.isDisabled()) {
    const store = _getPseudoLocalStorage();
    store[key] = value;
  } else {
    try {
      // eslint-disable-next-line no-restricted-properties
      window.localStorage.setItem(key, value);
    } catch (e) {
      if (String(e).toLowerCase().includes('quota')) {
        // eslint-disable-next-line no-restricted-properties
        window.localStorage.clear();
        if (retryCount < 1) {
          return _setItem(key, value, retryCount + 1);
        }
      }
      throw e;
    }
  }
  _notifyListeners(key, value);
}
function _getItem(key) {
  if (exports.isDisabled()) {
    const store = _getPseudoLocalStorage();
    return store[key];
  } else {
    // eslint-disable-next-line no-restricted-properties
    return window.localStorage.getItem(key);
  }
}
function _removeItem(key) {
  if (exports.isDisabled()) {
    const store = _getPseudoLocalStorage();
    store[key] = null; // Just in case this prevents a memory leak.
    delete store[key];
  } else {
    // eslint-disable-next-line no-restricted-properties
    return window.localStorage.removeItem(key);
  }
  _notifyListeners(key, null);
}
exports.set = (key, value, options) => {
  options = options || {};
  if (!exports.isInitialized()) {
    exports.init();
  }
  if (options.prefix === true) {
    key = User.getCurrentUserPrefix() + key;
  }
  if (Utils.exists(value)) {
    if (!_.isString(value)) {
      value = JSON.stringify(value);
    }
    _setItem(key, value);
  }
  if (value === null) {
    _removeItem(key);
  }
  return _getItem(key);
};
const _notifyListeners = (key, value) => {
  const _listeners = Globals.get(LISTENERS_KEY);
  _listeners.forEach(listener => {
    if (listener.key === key) {
      listener.fn(value);
    }
  });
};
exports.get = (key, options) => {
  return exports.set(key, undefined, options);
};
const _modifyListeners = modify => {
  const _listeners = Globals.get(LISTENERS_KEY);
  modify(_listeners);
  Globals.set(LISTENERS_KEY, _listeners);
};
exports.listen = (key, fn) => {
  const listener = {
    key,
    fn
  };
  _modifyListeners(listeners => listeners.add(listener));
  return () => {
    _modifyListeners(listeners => listeners.delete(listener));
  };
};
exports.remove = (key, options) => {
  return exports.set(key, null, options);
};
exports.clear = () => {
  if (exports.isDisabled()) {
    Globals.set(PSEUDO_STORAGE_KEY, {});
    return false;
  }

  // We need to iterate in reverse since window.localStorage.length
  // changes after removing an item.
  // eslint-disable-next-line no-restricted-properties
  for (let i = window.localStorage.length - 1; i >= 0; i--) {
    // eslint-disable-next-line no-restricted-properties
    const key = window.localStorage.key(i);
    if (key.indexOf(Constants.LOCALSTORAGE_PREFIX) === 0) {
      // eslint-disable-next-line no-restricted-properties
      window.localStorage.removeItem(key);
    }
  }
};
exports.getKeys = () => {
  const keys = [];
  if (exports.isDisabled()) {
    return _.keys(_getPseudoLocalStorage());
  }
  const prefix = User.getCurrentUserPrefix();

  // eslint-disable-next-line no-restricted-properties
  for (let i = 0; i < window.localStorage.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    const key = window.localStorage.key(i);
    if (key.indexOf(prefix) === 0) {
      keys.push(key);
    }
  }
  return keys;
};
exports.isDisabled = () => {
  return Globals.get(DISABLED_KEY);
};
exports.isInitialized = () => {
  return Globals.get(INITIALIZED_KEY);
};
exports.init = () => {
  if (exports.isInitialized()) {
    return false;
  }
  try {
    const key = 'storageTest';

    // eslint-disable-next-line no-restricted-properties
    window.localStorage.setItem(key, key);

    // eslint-disable-next-line no-restricted-properties
    if (window.localStorage.getItem(key) !== key) {
      Globals.set(DISABLED_KEY, true);
    }

    // eslint-disable-next-line no-restricted-properties
    window.localStorage.removeItem(key);
  } catch (e) {
    Globals.set(DISABLED_KEY, true);
    Log.error(e, {
      reason: 'LocalStorage test failed.'
    });
  }
  Globals.setOnlyIfMissing(LISTENERS_KEY, new Set());
  Globals.set(INITIALIZED_KEY, true);
};
export { exports as default };