import { MilestoneState as State } from '@clubhouse/shared/types';
export const TitleByState = {
  toDo: 'Unstarted',
  inProgress: 'Started',
  done: 'Done'
};
export const stateEnumMap = {
  toDo: State.ToDo,
  inProgress: State.InProgress,
  done: State.Done
};