import { LaunchDarklyContext, getFeatureToggle } from '@clubhouse/feature-toggles';
import { read } from '@clubhouse/fetch-and-cache';
import { isCanceled, useCancelablePromises } from '@clubhouse/shared/hooks';
import { useEffect, useMemo, useState } from 'react';
import { getServerURL } from 'utils/backend';
import { error } from '../log';
import { initLaunchDarkly } from './initLaunchDarkly';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
let hasAnyInstanceStartedBootstrap = false;
let hasAnyInstanceFinishedBootstrap = false;
let variations = {};
let resolveHasInitialized;
let hasInitializedPromise = new Promise(res => {
  resolveHasInitialized = res;
});
export const reset = () => {
  variations = {};
  hasAnyInstanceStartedBootstrap = false;
  hasAnyInstanceFinishedBootstrap = false;
  hasInitializedPromise = new Promise(res => {
    resolveHasInitialized = res;
  });
};
export const hasInitialized = () => hasInitializedPromise;

/*
We keep an in memory copy of the feature toggle values so that every consumer gets the same value until the next page refresh.
If we don't keep a copy, then it's possible for the first reader to read from the launch darkly local storage cache (this is a feature of the Launch Darkly JS Client),
and then the second reader will read from the updated cache (after the feature toggles are returned from the Launch Darkly API).
Ideally, consumers of our hook would also subscribe to the Launch Darkly changes, and then the hooks become reactive: https://docs.launchdarkly.com/sdk/features/flag-changes#subscribing-to-flag-change-notifications.
Due to our legacy boot sequence, reactive hooks that trigger a page reload will require some more thought and implementation.
*/
const getVariation = key => {
  if (key in variations) {
    return variations[key];
  }
  const value = getFeatureToggle(key);
  variations[key] = value;
  return value;
};
export const LaunchDarklyProvider = _ref => {
  let {
    children
  } = _ref;
  const [isBootstrapped, setIsBootstrapped] = useState(hasAnyInstanceFinishedBootstrap);
  const {
    makeCancelable
  } = useCancelablePromises();
  useEffect(() => {
    (async () => {
      if (!hasAnyInstanceStartedBootstrap) {
        hasAnyInstanceStartedBootstrap = true;
        try {
          await makeCancelable(initLaunchDarkly({
            getUser: () => makeCancelable(fetchUser())
          }));
        } catch (e) {
          if (isCanceled(e)) return;else error(e);
        }
        resolveHasInitialized();
        hasAnyInstanceFinishedBootstrap = true;
      }
      if (!isBootstrapped) {
        await makeCancelable(hasInitialized());
        setIsBootstrapped(true);
      }
    })();
  }, [isBootstrapped, makeCancelable]);
  const value = useMemo(() => ({
    isBootstrapped,
    getVariation
  }), [isBootstrapped]);
  if (!isBootstrapped) {
    return null;
  }
  return _jsx(LaunchDarklyContext.Provider, {
    value: value,
    children: children
  });
};
LaunchDarklyProvider.displayName = "LaunchDarklyProvider";
const fetchUser = async () => {
  const url = `${getServerURL()}/api/private/user`;
  const cachedUser = await read(url);
  if (cachedUser) {
    return cachedUser.json;
  }
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.ok ? await response.json() : undefined;
};