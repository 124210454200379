import Collection from '../_frontloader/collection';
const exports = {};

/*

Example PullRequest entity:

{
  closed: true
  created_at: "2015-11-05T03:52:12Z"
  id: 4162
  num_added: 365
  num_commits: 8
  num_modified: 18
  num_removed: 146
  number: 82
  repository_name: "server"
  repository_full_name: "org/server"
  title: "Allow free plan users to upgrade to paid and vice versa"
  updated_at: "2015-11-05T04:15:07Z"
  url: "https://github.com/useshortcut/app-frontend/pull/82"
}

*/

Collection.create('PullRequest', exports);
export { exports as default };