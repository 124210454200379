import Globals from '../../../core/js/_frontloader/globals';
import IterationModel from '../../../core/js/models/iteration';
const exports = {};
exports.toggle = iteration => {
  iteration.active = !iteration.active;
};
exports.setAllActive = () => {
  IterationModel.each(x => {
    x.active = x.archived !== true;
  });
};
exports.enableAll = () => {
  exports.setAllActive();
  IterationModel.trigger('updated');
};
exports.disableAll = () => {
  IterationModel.each(iteration => {
    iteration.active = false;
  });
  IterationModel.trigger('updated');
};
exports.onlyCurrentIterationActive = iteration => {
  const activeIterations = IterationModel.filter({
    active: true
  });
  return activeIterations.length === 1 && activeIterations[0].id === iteration.id && !exports.shouldDisplayStoriesNotInIterations();
};
exports.displayStoriesNotInIterations = () => {
  Globals.set('displayStoriesNotInIterations', true);
};
exports.hideStoriesNotInIterations = () => {
  Globals.set('displayStoriesNotInIterations', false);
};
exports.shouldDisplayStoriesNotInIterations = () => {
  return !Globals.get('displayStoriesNotInIterations') === false;
};
export { exports as default };