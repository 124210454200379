import { DuplicateKeyResultMutationDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import merge from 'lodash/merge';
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
const DuplicateKeyResultActionMutation = DuplicateKeyResultMutationDocument;
export const useDuplicateKeyResult = id => {
  const [duplicateKeyResult] = useMutation(DuplicateKeyResultActionMutation);
  return useCallback(options => {
    return duplicateKeyResult(merge({
      variables: {
        id
      }
    }, options || {}));
  }, [duplicateKeyResult, id]);
};