import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CheckSquare = _ref => {
  let {
    fill,
    width = ICON_DEFAULT_WIDTH,
    className
  } = _ref;
  return _jsx("svg", {
    fill: fill,
    width: width,
    height: width,
    viewBox: "0 0 12 12",
    className: className,
    children: _jsx("path", {
      d: "M5.4,9.1l4.8-4.8c0.1-0.1,0.1-0.2,0.1-0.3s0-0.3-0.1-0.3L9.4,2.9C9.3,2.8,9.1,2.7,9,2.7c-0.1,0-0.3,0-0.3,0.1L5.1,6.5 L3.4,4.9C3.3,4.8,3.2,4.7,3.1,4.7c-0.2,0-0.3,0-0.3,0.1L2,5.7C1.8,5.8,1.8,5.9,1.8,6c0,0.1,0,0.3,0.2,0.3l2.8,2.8 C4.9,9.3,5,9.3,5.1,9.3C5.2,9.3,5.2,9.3,5.4,9.1z M12,2.2v7.5c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-0.9,0.7-1.6,0.7H2.2 c-0.6,0-1.2-0.2-1.6-0.7C0.3,10.9,0,10.4,0,9.8V2.2c0-0.6,0.2-1.2,0.7-1.6S1.6,0,2.2,0h7.5c0.6,0,1.2,0.2,1.6,0.7 C11.7,1.1,12,1.6,12,2.2z"
    })
  });
};
CheckSquare.displayName = "CheckSquare";