import { StoryGroupBySeverityFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { SeverityWithIcon } from 'components/gql/severity/SeverityWithIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupBySeverityFragment = StoryGroupBySeverityFragmentFragmentDoc;
export const SeverityGroupHeader = _ref => {
  let {
    severity
  } = _ref;
  return _jsx(GroupByHeader, {
    entityType: Nouns.Severity,
    children: typeof severity?.stringValue === 'string' && typeof severity?.position === 'number' ? _jsx(SeverityWithIcon, {
      spacing: 12,
      stringValue: severity.stringValue,
      position: severity.position
    }) : 'No Severity'
  });
};
SeverityGroupHeader.displayName = "SeverityGroupHeader";