import { GROUP_BY } from 'components/shared/table/GroupBySelect/stories_graphql';
export const GROUP_BY_VALUES = {
  [GROUP_BY.STATE]: 'workflowState_workflow_name_workflowState_position',
  [GROUP_BY.EPIC]: 'epics',
  [GROUP_BY.OWNER]: 'owners',
  [GROUP_BY.TEAM]: 'team_name',
  [GROUP_BY.TYPE]: 'type',
  [GROUP_BY.WORKFLOW]: 'workflowState_workflow_name',
  [GROUP_BY.ITERATION]: 'iteration',
  [GROUP_BY.SKILLSET]: 'skillSet',
  [GROUP_BY.TECHNICAL_AREA]: 'technicalArea',
  [GROUP_BY.PRIORITY]: 'priority',
  [GROUP_BY.SEVERITY]: 'severity',
  [GROUP_BY.PRODUCT_AREA]: 'productArea'
};
export const SORT_COLUMN_VALUES = ['position', 'id', 'type', 'name', 'team_name', 'owners', 'iteration', 'epics', 'workflowState_workflow_name', 'workflowState_workflow_name_workflowState_position', 'estimate', 'deadline', 'requester', 'updatedAt', 'priority', 'skillSet', 'productArea', 'severity', 'technicalArea', 'labels', 'createdAt', 'customFieldValue_position'];
export function hasDuplicateGroupWorkflowStateName(allWorkflowStateGroupsExcludingCurrent, currentGroupWorkflowStateName) {
  return allWorkflowStateGroupsExcludingCurrent.some(group => {
    const groupWorkflowStateName = group[0].workflowState?.name;
    if (!groupWorkflowStateName === undefined) return false;
    return groupWorkflowStateName === currentGroupWorkflowStateName;
  });
}