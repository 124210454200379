import { logError } from 'utils/monitoring';
export const createErrorHandler = (operation, options) => {
  const reportError = (name, message, error) => {
    logError(new Error(`GraphQL Error - ${name || 'Unknown'}: ${message || 'Unknown'}`), {
      errorInfo: JSON.stringify(error)
    }, {
      graphql: {
        operation,
        options: JSON.stringify(options ?? null)
      }
    });
  };
  return _ref => {
    let {
      name,
      message,
      graphQLErrors
    } = _ref;
    if (graphQLErrors) {
      graphQLErrors.forEach(error => reportError(name, message, error));
    }
  };
};