import { oneOf } from '@charpeni/one-of';
import { getTokens } from '@useshortcut/shapes-ds';
import { v3 as uuidv3 } from 'uuid';
import { getDefaultUserEmail } from '../user';
const {
  color: {
    raw: rawTokens
  }
} = getTokens();
const profileColors = [rawTokens.red110, rawTokens.orange90, rawTokens.yellow100, rawTokens.green100, rawTokens.green80, rawTokens.blue100, rawTokens.blue80, rawTokens.purple80, rawTokens.purple100, rawTokens.slate90];
export const getProfileColor = profile => {
  if (!profile) return rawTokens.slate90;
  const email = profile.email_address || (getDefaultUserEmail(profile) || {}).email_address;
  if (email) {
    return getProfileColorFromEmail(email);
  } else if (profile.id) {
    return getProfileColorFromId(String(profile.id));
  } else {
    return oneOf(profileColors);
  }
};
export const getProfileColorFromEmail = email => {
  return getProfileColorFromString(uuidv3(email, uuidv3.URL));
};
export const getProfileColorFromId = id => {
  return getProfileColorFromString(id);
};
const getProfileColorFromString = str => {
  const shortId = str.substring(str.length - 8);
  const shortIdAsInt = Number.parseInt(shortId, 16);
  const partition = shortIdAsInt % profileColors.length;
  return profileColors[partition];
};
export const getProfileNameInitials = profile => initialsFromString(profile.name || profile.mention_name);
export const initialsFromString = fullName => {
  return fullName.replace(/\(.*?\)/g, '').trim().split(' ').map(s => s.charAt(0)).reduce((acc, s, index, array) => {
    const isFirstLetter = index === 0;
    const isSecondAndLastLetter = index === 1 && array.length === 2;
    const isThirdLetter = index === 2;
    if (isFirstLetter || isSecondAndLastLetter || isThirdLetter) {
      return acc + s;
    }
    return acc;
  }, '').toUpperCase();
};