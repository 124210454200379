import memoize from 'lodash/memoize';
export const getCommandBar = memoize(() => withEventHandler(window.CommandBar));
window.getCommandBar = getCommandBar;
function withEventHandler(CommandBar) {
  let eventHandlers = [];
  const removeEventHandler = fn => {
    if (!eventHandlers.includes(fn)) return;
    eventHandlers = eventHandlers.filter(handler => fn !== handler);
  };
  const addEventHandler = async fn => {
    if (eventHandlers.includes(fn)) return;
    eventHandlers = [...eventHandlers, fn];
  };
  CommandBar.addEventHandler(function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    eventHandlers.forEach(handler => handler.call(null, ...args));
  });
  return new Proxy({
    addEventHandler,
    removeEventHandler,
    addEventHandlers: fns => fns.forEach(addEventHandler),
    removeEventHandlers: fns => fns.forEach(removeEventHandler)
  }, {
    get: (target, prop) => (prop in target ? target : CommandBar)[prop]
  });
}