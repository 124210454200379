const exports = {};

// Sometimes moment.js is too slow, so we've rewritten some
// functions that get used very often for better performance.

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
exports.now = () => {
  return new Date().getTime();
};
exports.valueOf = dateString => {
  return new Date(dateString).getTime();
};
exports.daysOld = dateString => {
  const diff = exports.diff(dateString);
  return Math.ceil(diff / ONE_DAY);
};
exports.hoursOld = dateString => {
  const diff = exports.diff(dateString);
  return Math.ceil(diff / ONE_HOUR);
};
exports.diff = dateString => {
  const now = exports.now();
  const d = exports.valueOf(dateString);
  return now - d;
};
exports.isOlderInDays = (dateString, thresholdInDays) => {
  const ageInDays = exports.daysOld(dateString);
  return ageInDays > thresholdInDays;
};
exports.isWithinDayRange = (dateString, startThresholdInDays, endThresholdInDays) => {
  const ageInDays = exports.daysOld(dateString);
  return ageInDays >= startThresholdInDays && ageInDays <= endThresholdInDays;
};
exports.isMoreRecentThan = (dateString, dateStringToCheck) => {
  return exports.valueOf(dateString) < exports.valueOf(dateStringToCheck);
};

/*
exports.isNextMonth = function (lastDate, nextDate) {
  var d1 = new Date(lastDate);
  var d2 = new Date(nextDate);

  return d1.getFullYear() !== d2.getFullYear() || d1.getMonth() !== d2.getMonth();
};
*/

exports.isNextWeek = (lastDate, nextDate) => {
  const d1 = exports.getPrevSunday(lastDate);
  const d2 = exports.getPrevSunday(nextDate);
  return !_isSameDate(d1, d2);
};
exports.getPrevSunday = dateString => {
  const d = new Date(dateString);
  return _getPrevSunday(d);
};
function _isSameDate(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}
function _getPrevSunday(d) {
  const dayOfWeek = d.getDay();
  d.setDate(d.getDate() + (0 - dayOfWeek));
  return d;
}
exports.isThisWeek = dateString => {
  const d1 = exports.getPrevSunday(dateString);
  const d2 = _getPrevSunday(new Date());
  return _isSameDate(d1, d2);
};
export { exports as default };