const exports = {};
exports.getSelectionStart = context => {
  // Standards:
  if (context.selectionStart !== undefined) {
    return context.selectionStart;
  }

  // IE:
  if (document.selection) {
    context.focus();
    const range = document.selection.createRange();
    const length = document.selection.createRange().text.length;
    range.moveStart('character', -context.value.length);
    return range.text.length - length;
  }
};
exports.setPosition = (context, position) => {
  if (context.selectionStart) {
    context.focus();
    context.setSelectionRange(position, position);
  } else if (context.createTextRange) {
    const range = context.createTextRange();
    range.move('character', position);
    range.select();
  } else {
    context.focus();
  }
};
export { exports as default };