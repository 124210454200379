import { isNew, setWorkflowState } from 'data/entity/story';
import { getStatesForWorkflow } from 'data/entity/workflow';
import * as WorkflowStateData from 'data/entity/workflowState';
import { updateStoryWorkflowState } from 'utils/addNew';
import { formatWorkflowState, formatWorkflowStates } from '../../formatters/workflowState';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryState = story => {
  lazilyLoadArgumentChoices('currentWorkflowStateForStory', () => getCurrentWorkflowStateForStory(story));
  lazilyLoadArgumentChoices('workflowStatesForStory', () => getWorkflowStatesForCurrentStory(story));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryState', updateStoryState(story));
  }
};
const getWorkflowStatesForCurrentStory = story => {
  if (!story) return null;
  const workflowStates = getStatesForWorkflow(story.workflow_id);
  return formatWorkflowStates(workflowStates);
};
const getCurrentWorkflowStateForStory = story => {
  if (!story) return null;
  const workflowState = WorkflowStateData.getById(story.workflow_state_id);
  return formatWorkflowState(workflowState);
};
const updateStoryState = story => _ref => {
  let {
    workflowState
  } = _ref;
  if (isNew(story)) {
    updateStoryWorkflowState(workflowState.id);
  } else {
    setWorkflowState(story, workflowState.id);
  }
};