import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import EpicsController from 'app/client/core/js/controllers/epics.js';
import EpicModel from 'app/client/core/js/models/epic.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const EpicModel = exports;
  [[['Controller', 'Epics'], EpicsController], [['Model', 'Epic'], EpicModel], [['Tooltip'], Tooltip], [['Controller', 'Epics'], EpicsController], [['Model', 'Epic'], EpicModel], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import ApplicationState from '../modules/applicationState';
import AutocompleteController from '../controllers/autocomplete';
import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import ColumnModel from './column';
import Constants from '../modules/constants';
import EpicStateModel from './epicState';
import Format from '../modules/format';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import Iterate from '../modules/iterate';
import LabelModel from './label';
import MilestoneModel from './milestone';
import ProfileModel from './profile';
import Router from '../_frontloader/router';
import SortableTableController from '../controllers/sortableTable';
import StoryModel from './story';
import TeamModel from './team';
import Url from '../modules/url';
import Utils from '../modules/utils';
import WebhookModel from './webhook';
import EstimateScaleModel from './estimateScale';
import * as EpicData from 'data/entity/epic';
import * as sort from 'utils/sort';
import { getSelectedGroups, isNoGroupSelected } from 'data/page/stories';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
const exports = {
  Promises: {}
};

/*

Example Epic:

{
  archived: false
  comments: [{id: 8825,…}, {id: 7430, text: "@adrian: hey does this work?", created_at: "2016-03-17T16:17:18Z",…}]
  created_at: "2016-02-25T15:06:53Z"
  deadline: null
  description: ""
  epic_state_id: 500042485
  follower_ids: ["55198a6d-3038-47b7-8b3e-58e42d7fd17f", "53ba2bf6-58ba-4403-9c8d-d23a8eb04adf",…]
  id: 6888
  labels: []
  last_story_update: "2016-06-29T16:55:48Z"
  name: "Org wide positioning "
  num_points: 0
  num_stories_done: 1
  num_stories_started: 1
  num_stories_unstarted: 3
  owner_ids: ["55198a6d-3038-47b7-8b3e-58e42d7fd17f", "54fdc28f-c703-4042-9cd3-845751a04402"]
  position: 21
  project_ids: [274, 10001053886]
  updated_at: "2016-06-29T18:54:52Z"
}

*/

const SHOW_SELETED_GROUPS_EPICS = 'Stories.ShowOnlyEpicsFromSelectedGroups';
const SHOW_SELETED_PROJECTS_EPICS = 'Stories.ShowOnlyEpicsFromSelectedProjects';
Collection.create('Epic', exports);
Router.addPageTitle('epic', url => {
  const path = Url.parsePathSkipCache(url);
  const epic = exports.getById(path.epic);
  const fallback = `Epics | ${BRAND.NAME}`;
  return epic ? epic.name + ' | ' + fallback : fallback;
});
const withResults = (resolve, reject) => (error, result) => {
  error ? reject(error) : resolve(result);
};
exports.isValid = epic => {
  return epic && epic.id && epic.epic_state_id;
};
exports.isValidComment = comment => {
  return comment && comment.id && comment.text;
};
exports.isDone = function () {
  let epic = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return EpicStateModel.getStateType(epic) === Constants.WORKFLOW_STATE_TYPES.DONE;
};
exports.isInProgress = function () {
  let epic = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return EpicStateModel.getStateType(epic) === Constants.WORKFLOW_STATE_TYPES.STARTED;
};
exports.isToDo = function () {
  let epic = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return EpicStateModel.getStateType(epic) === Constants.WORKFLOW_STATE_TYPES.UNSTARTED;
};
exports.isArchived = function () {
  let epic = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return epic.archived === true;
};
exports.isNotArchived = function () {
  let epic = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return epic.archived !== true;
};
exports.isActive = epic => {
  return !!(epic && epic.active);
};
exports.isFollowedByUser = (epic, profile) => {
  if (profile && epic.follower_ids) {
    return _.includes(epic.follower_ids, profile.id);
  }
  return false;
};
const insertCommentDepth = function (comments) {
  let depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!comments) return;
  comments.forEach(comment => {
    comment.depth = depth;
    if (comment.comments) insertCommentDepth(comment.comments, depth + 1);
  });
};
exports.normalize = epic => {
  _updatePropsFromOverrides(epic);
  _relateOwnersAndFollowers(epic);
  epic.requested_by = ProfileModel.getAllDetailsById(epic.requested_by_id);
  epic.formatted_name = Format.sanitizeAndEmojify(epic.name);
  epic.type = 'epic';
  exports.relateCommentsToUsers(epic.comments);
  insertCommentDepth(epic.comments);

  // TODO: Do we still need the fallback?
  epic.stateObject = EpicStateModel.getState(epic) || EpicStateModel.getFirstUnstarted();
  Utils.attachFormattedDeadlines(epic);
  Utils.attachFormattedPlannedStartDate(epic);
  epic.deadline_icon = exports.isDone(epic) ? 'fa-calendar-check-o' : 'fa-calendar';
  epic.url = exports.generateURL(epic);
  epic.milestone = epic.milestone_id ? MilestoneModel.getById(epic.milestone_id) : null;
  epic.objectives = (epic.objective_ids || []).map(id => MilestoneModel.getById(id)).filter(Boolean);
  exports.normalizeExtraProperties(epic);
  return epic;
};
exports.hasExtraProperties = epic => {
  return epic.stats || _.isNumber(epic.num_stories_unstarted);
};
exports.normalizeStats = epic => {
  // Temporary until we remove top-level properties.
  if (epic.stats) {
    _.keys(epic.stats).forEach(key => {
      epic[key] = epic.stats[key];
    });
  }
};
exports.hasDeadline = epic => Boolean(epic.deadline);
exports.hasPlannedStartDate = epic => Boolean(epic.planned_start_date);
exports.setDefaultCounts = epic => {
  ['num_points', 'num_points_backlog', 'num_points_unstarted', 'num_points_started', 'num_points_done', 'num_stories_unestimated', 'num_stories_backlog', 'num_stories_unstarted', 'num_stories_started', 'num_stories_done'].forEach(key => {
    epic[key] = epic[key] || 0;
  });
};
exports.normalizeExtraProperties = epic => {
  exports.normalizeStats(epic);
  exports.setDefaultCounts(epic);
  epic.totalStories = epic.num_stories_backlog + epic.num_stories_unstarted + epic.num_stories_started + epic.num_stories_done;
  epic.percentCompleted = epic.num_stories_done / epic.totalStories * 100 || 0;
  epic.percentActive = epic.num_stories_started / epic.totalStories * 100 || 0;
  epic.percentUnstarted = epic.num_stories_unstarted / epic.totalStories * 100 || 0;
  epic.percentBacklog = epic.num_stories_backlog / epic.totalStories * 100 || 0;
  epic.percentCompletedPoints = epic.num_points_done / epic.num_points * 100 || 0;
  epic.percentActivePoints = epic.num_points_started / epic.num_points * 100 || 0;
  epic.percentUnstartedPoints = epic.num_points_unstarted / epic.num_points * 100 || 0;
  epic.percentBacklogPoints = epic.num_points_backlog / epic.num_points * 100 || 0;
};
exports.getProgressPercentages = epic => {
  if (EstimateScaleModel.isUsingPoints()) {
    const {
      percentBacklogPoints,
      percentCompletedPoints,
      percentActivePoints,
      percentUnstartedPoints
    } = epic;
    return {
      [Constants.WORKFLOW_STATE_TYPES.DONE]: percentCompletedPoints,
      [Constants.WORKFLOW_STATE_TYPES.STARTED]: percentActivePoints,
      [Constants.WORKFLOW_STATE_TYPES.UNSTARTED]: percentUnstartedPoints,
      [Constants.WORKFLOW_STATE_TYPES.BACKLOG]: percentBacklogPoints
    };
  }
  const {
    percentBacklog,
    percentCompleted,
    percentActive,
    percentUnstarted
  } = epic;
  return {
    [Constants.WORKFLOW_STATE_TYPES.DONE]: percentCompleted,
    [Constants.WORKFLOW_STATE_TYPES.STARTED]: percentActive,
    [Constants.WORKFLOW_STATE_TYPES.UNSTARTED]: percentUnstarted,
    [Constants.WORKFLOW_STATE_TYPES.BACKLOG]: percentBacklog
  };
};
exports.generateURL = (epic, commentID) => {
  let url = Url.getSlugPath() + '/epic/' + epic.id + '/' + Utils.slugify(epic.name, {
    limit: 120
  });
  if (commentID) {
    url += '#activity-' + commentID;
  }
  return url;
};
exports.getCalendarLink = epic => {
  return WebhookModel.getCalendarURL() + '/calendars/epics/' + epic.id;
};
function _updatePropsFromOverrides(epic) {
  if (epic.created_at_override) {
    epic.created_at = epic.created_at_override;
  }
  if (epic.completed_at_override) {
    epic.completed_at = epic.completed_at_override;
  }
}
function _relateOwnersAndFollowers(epic) {
  epic.owners = ProfileModel.mapIDsToProfileDetails(epic.owner_ids);
  epic.followers = ProfileModel.mapIDsToProfileDetails(epic.follower_ids);
}
function isEpicRelatedToGroup(epic) {
  const teamScopeId = getTeamScopedCollectionizeId();
  const groups = teamScopeId ? [teamScopeId] : getSelectedGroups();
  const showEpicsRegardlessOfGroupSelection = !exports.showEpicsFromSelectedGroups();
  return showEpicsRegardlessOfGroupSelection || _isRelatedToGroups(epic, groups);
}
exports.allNotArchived = () => {
  return exports.filter(exports.isNotArchived);
};
exports.allActive = () => {
  return exports.filter({
    active: true
  });
};
exports.noActive = () => {
  return exports.allActive().length === 0;
};
exports.singleActive = () => {
  const epics = exports.allActive();
  return epics.length === 1 ? epics[0] : null;
};
exports.getFromUrl = url => {
  const path = Url.parsePath(url);
  return exports.getById(path.epic);
};
exports.getVisibleEpics = () => {
  const listType = exports.getListDisplayType();
  let epics = [];
  if (listType === 'ALL' || listType === 'PROJECTS') {
    epics = exports.getVisibleEpicsWithoutDone();
  } else if (listType === 'STARTED') {
    epics = exports.getVisibleStartedEpics();
  } else if (listType === 'FOLLOWED') {
    epics = exports.getVisibleFollowedEpics();
  } else if (listType === 'INCLUDE_DONE') {
    epics = exports.showEpicsFromSelectedGroups() ? exports.all().filter(isEpicRelatedToGroup) : exports.all();
  }
  return _.sortBy(epics, 'position');
};
function _isRelatedToGroups(epic, groupIds) {
  const teamScopeId = getTeamScopedCollectionizeId();
  if (teamScopeId) {
    return groupIds && groupIds.includes(epic.group_id) || epic.associated_groups && epic.associated_groups.some(_ref2 => {
      let {
        group_id
      } = _ref2;
      return groupIds.includes(group_id);
    });
  }
  return isNoGroupSelected() && !epic.group_id || epic.group_id && groupIds.includes(epic.group_id);
}
function _getVisibleEpicsInState(stateType) {
  const epicStateIDs = _.map(EpicStateModel.filter({
    type: stateType
  }), 'id');
  return exports.filter(epic => exports.isNotArchived(epic) && _.includes(epicStateIDs, epic.epic_state_id) && isEpicRelatedToGroup(epic));
}
exports.getVisibleEpicsWithoutDone = () => {
  const {
    UNSTARTED,
    STARTED
  } = Constants.WORKFLOW_STATE_TYPES;
  return [STARTED, UNSTARTED].reduce((epics, state) => {
    epics = epics.concat(_getVisibleEpicsInState(state));
    return epics;
  }, []);
};
exports.getVisibleStartedEpics = () => {
  return _getVisibleEpicsInState(Constants.WORKFLOW_STATE_TYPES.STARTED);
};
exports.getVisibleFollowedEpics = () => {
  const profileDetails = ProfileModel.getCurrentUserProfileDetails();
  return exports.filter(epic => isEpicRelatedToGroup(epic) && exports.isFollowedByUser(epic, profileDetails));
};
exports.getVisibleAssociatedEpicsForTeam = teamId => {
  const epics = exports.getVisibleEpics();
  return epics.filter(epic => {
    const epicAssociatedTeamIds = epic.associated_groups?.map(_ref3 => {
      let {
        group_id
      } = _ref3;
      return group_id;
    }) || [];
    if (epicAssociatedTeamIds.includes(teamId)) {
      return true;
    }
  });
};
exports.getMoreEpics = visibleEpics => {
  const moreEpics = [];
  const moreEpicIds = new Set();
  const visibleEpicIds = new Set((visibleEpics || exports.getVisibleEpics()).map(epic => epic.id));
  exports.each(epic => {
    if (!visibleEpicIds.has(epic.id) && !moreEpicIds.has(epic.id) && exports.isNotArchived(epic) && isEpicRelatedToGroup(epic)) {
      moreEpicIds.add(epic.id);
      moreEpics.push(epic);
    }
  });
  return _.sortBy(moreEpics, epic => EpicStateModel.getPosition(epic));
};
exports.getEpicCountForWorkflowState = function () {
  let epicState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return exports.filter({
    epic_state_id: epicState.id
  }).length;
};
exports.hasEpicsInWorkflowState = function () {
  let epicState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return !!exports.getEpicCountForWorkflowState(epicState);
};
exports.getAllActiveInWorkflowStateType = stateType => {
  const stateIDs = EpicStateModel.getAllIdsOfType(stateType);
  return exports.filter(epic => {
    return _.includes(stateIDs, epic.epic_state_id) && epic.archived === false;
  });
};
exports.getOrFetchEpic = id => {
  const epic = exports.getById(id);
  return epic ? Promise.resolve(epic) : exports.fetchEpicPromise(id);
};
exports.fetchEpicPromise = id => new Promise((resolve, reject) => {
  Backend.get('/api/private/epics/' + id, {
    onComplete: res => {
      if (exports.isValid(res)) {
        exports.update(res);
        resolve(exports.getById(res.id));
      } else {
        reject('fetched invalid Epic data');
      }
    }
  });
});
exports.fetchAll = callback => {
  exports.fetchAllByUrl('/api/private/epics', callback);
};
exports.fetchAllByUrl = (url, callback) => {
  Backend.get(url, {
    onComplete: res => {
      exports.defaultFetchSomeHandler(res, callback);
    }
  });
};
exports.fetchLatestVersion = (epic, callback) => {
  Backend.get('/api/private/epics/' + epic.id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.fetchStoriesForEpicIncludingArchived = (epic, callback) => {
  StoryModel.searchStories({
    epic_ids: [epic.id]
  }, callback);
};
exports.fetchStoriesForEpics = (epics, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (epics.length === 0) {
    callback();
    return false;
  }
  StoryModel.searchStories({
    archived: false,
    epic_ids: _.map(epics, 'id')
  }, callback);
};
exports.fetchStoriesForInProgressEpics = callback => {
  const epics = exports.filter(epic => {
    return exports.isInProgress(epic);
  });
  exports.fetchStoriesForEpics(epics, callback);
};
exports.saveNew = (epic, callback) => {
  epic = {
    ...epic
  };
  if (epic.labels) {
    epic.labels = LabelModel.denormalizeLabels(epic.labels);
  }
  Utils.detachFormattedPlannedStartDate(epic);
  Utils.detachFormattedDeadlines(epic);
  Backend.post('/api/private/epics', {
    data: epic,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'epicCreated');
    }
  });
};
exports.showEpicsFromSelectedGroups = () => ApplicationState.get(SHOW_SELETED_GROUPS_EPICS) || false;
exports.setShowEpicsFromSelectedGroups = value => ApplicationState.set(SHOW_SELETED_GROUPS_EPICS, value);
exports.showEpicsFromSelectedProjects = () => ApplicationState.get(SHOW_SELETED_PROJECTS_EPICS) || false;
exports.setShowEpicsFromSelectedProjects = value => ApplicationState.set(SHOW_SELETED_PROJECTS_EPICS, value);
exports.getListDisplayType = () => {
  let type = ApplicationState.get('Stories.EpicListDisplayType') || 'ALL';

  // Backwards compatibility now that the PROJECTS option is gone.
  if (type === 'PROJECTS') {
    type = 'ALL';
  }
  return type;
};
exports.setListDisplayType = type => {
  return ApplicationState.set('Stories.EpicListDisplayType', type);
};
exports.getListDisplayTypeHeader = () => {
  const headers = {
    ALL: 'Unfinished Epics',
    STARTED: 'In Progress Epics',
    FOLLOWED: 'Followed Epics'
  };
  return headers[exports.getListDisplayType()];
};
exports.getStories = (epic, column, stories) => {
  stories = stories || StoryModel.all();
  return _.filter(stories, story => {
    if (column && !StoryModel.isInColumn(story, column)) {
      return false;
    }
    return story.epic_id === epic.id;
  });
};
exports.getActiveStories = (epic, stories) => {
  stories = stories || StoryModel.all();
  return _.filter(stories, story => {
    if (!StoryModel.isActiveState(story)) {
      return false;
    }
    return story.epic_id === epic.id;
  });
};
exports.getDoneStories = (epic, stories) => {
  stories = stories || StoryModel.all();
  return _.filter(stories, story => {
    if (!StoryModel.isDoneState(story)) {
      return false;
    }
    return story.epic_id === epic.id;
  });
};
exports.saveChanges = (epic, changes, callback) => {
  Backend.put('/api/private/epics/' + epic.id, {
    data: changes,
    onComplete: res => {
      let eventName = 'epicChangesSaved';
      if (Object.values(changes).length === 1 && ['before_id', 'after_id'].find(name => name in changes)) {
        eventName = null;
      }
      exports.defaultGetHandler(res, callback, eventName);
    }
  });
};
exports.unarchiveEpic = (epic, callback) => {
  exports.saveChanges(epic, {
    archived: false
  }, callback);
};
exports.archiveEpic = (epic, callback) => {
  exports.saveChanges(epic, {
    archived: true
  }, callback);
};
exports.deleteEpic = (epic, callback) => {
  Backend.delete('/api/private/epics/' + epic.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(epic, res, xhr, callback);
    }
  });
};
exports.removeLabel = (epic, label, callback) => {
  exports.saveLabels(epic, _.reject(_.get(epic, 'labels'), {
    name: _.get(label, 'name')
  }), callback);
};
exports.saveLabels = (epic, labels, callback) => {
  const updates = {
    labels: LabelModel.denormalizeLabels(labels)
  };
  exports.saveChanges(epic, updates, () => {
    LabelModel.fetchAllSlim(callback);
  });
};
exports.getAllWithLabel = label => {
  return exports.filter(epic => {
    return _.find(epic.labels, {
      id: label.id
    });
  });
};
exports.displayStoriesNotInEpics = () => {
  Globals.set('displayStoriesNotInEpics', true);
};
exports.hideStoriesNotInEpics = () => {
  Globals.set('displayStoriesNotInEpics', false);
};
exports.shouldDisplayStoriesNotInEpics = () => {
  return !!Globals.get('displayStoriesNotInEpics');
};
exports.isFollower = (epic, profile) => {
  const profileId = profile?.permissions?.[0]?.id || profile?.id || null;
  if (!profileId) {
    return false;
  }
  return _.includes(epic.follower_ids, profileId);
};
exports.addFollower = (epic, profile, callback) => {
  const ids = _.isArray(epic.follower_ids) ? epic.follower_ids.slice(0) : [];
  ids.push(profile.id);
  exports.saveFollowerIds(_.assign({}, epic, {
    follower_ids: ids
  }), callback);
};
exports.removeFollower = (epic, profile, callback) => {
  exports.saveFollowerIds(_.assign({}, epic, {
    follower_ids: _.without(epic.follower_ids, profile.id)
  }), callback);
};
exports.saveFollowerIds = (epic, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/epics/' + epic.id, {
    data: {
      follower_ids: epic.follower_ids
    },
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'followersUpdated');
    }
  });
};
exports.addMeAsFollower = (epic, callback) => {
  _addOrRemoveMeAsFollower(epic, 'follow', callback);
};
exports.removeMeAsFollower = (epic, callback) => {
  _addOrRemoveMeAsFollower(epic, 'unfollow', callback);
};

// This is a seperate from saveFollowerIds, as it's open to observers as well
function _addOrRemoveMeAsFollower(epic, type, callback) {
  type = type === 'unfollow' ? 'unfollow' : 'follow';
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/epics/' + epic.id + '/' + type, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'followersUpdated');
    }
  });
}
exports.currentUserIsFollower = epic => {
  const profile = ProfileModel.getCurrentUserProfile();
  return exports.isFollower(epic, profile);
};
exports.describeFollowers = epic => {
  const followers = epic.follower_ids || [];
  if (followers.length === 0) {
    return '<em>Nobody</em>';
  }
  if (exports.currentUserIsFollower(epic)) {
    if (followers.length === 1) {
      return 'Just You';
    } else {
      return 'You + ' + (followers.length - 1);
    }
  } else {
    return followers.length;
  }
};
exports.isOwner = (epic, profile) => {
  return _.includes(epic.owner_ids, profile.id);
};
exports.removeOwner = (epic, profile, callback) => {
  exports.saveOwnerChange(_.assign({}, epic, {
    owner_ids: _.without(epic.owner_ids, profile.id)
  }), callback);
};
exports.addOwner = (epic, profile, callback) => {
  const ids = _.isArray(epic.owner_ids) ? epic.owner_ids.slice(0) : [];
  ids.push(profile.id);
  exports.saveOwnerChange(_.assign({}, epic, {
    owner_ids: ids
  }), callback);
};
exports.removeAllOwners = (epic, callback) => {
  exports.saveOwnerChange(_.assign({}, epic, {
    owner_ids: []
  }), callback);
};
exports.saveOwnerChange = (epic, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/epics/' + epic.id, {
    data: {
      owner_ids: epic.owner_ids
    },
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'ownersUpdated');
    }
  });
};
exports.Promises.saveOwners = epic => new Promise((resolve, reject) => {
  Backend.put('/api/private/epics/' + epic.id, {
    data: {
      owner_ids: epic.owner_ids
    },
    onComplete: res => {
      exports.defaultGetHandler(res, withResults(resolve, reject));
    }
  });
});
exports.fuzzyMatch = str => {
  const matches = [];
  str = (str || '').toLowerCase();
  exports.each(epic => {
    const {
      name
    } = EpicStateModel.getState(epic);
    if (epic.id === Number.parseInt(str, 10)) {
      matches.push(epic);
    } else if ((epic.name || '').toLowerCase().indexOf(str) !== -1) {
      matches.push(epic);
    } else if ((name || '').toLowerCase().indexOf(str) === 0) {
      matches.push(epic);
    }
  });
  return matches;
};
exports.sortByPosition = epics => {
  if (!_.isArray(epics)) {
    epics = exports.db;
  }
  epics.sort(SortableTableController.EPIC_COLUMNS.Priority);
};
exports.sortedByPositionAndState = epics => {
  epics = epics || exports.allNotArchived();
  return epics.slice(0).sort(sort.name).sort(sort.epicState);
};
exports.findComment = (epic, id) => {
  let match;
  Iterate.each(epic.comments, comment => {
    let result;
    if (match) {
      return false;
    }
    if (comment.id === id) {
      match = comment;
    } else if (comment.comments && comment.comments.length > 0) {
      result = exports.findComment(comment, id);
      if (result) {
        match = result;
      }
    }
  });
  return match;
};
exports.getTotalCommentCount = comments => {
  let sum = 0;
  comments = comments || [];
  if (comments.length > 0) {
    sum += comments.length;
    Iterate.each(comments, comment => {
      if (comment.comments && comment.comments.length > 0) {
        sum += exports.getTotalCommentCount(comment.comments);
      }
    });
  }
  return sum;
};
exports.relateCommentsToUsers = comments => {
  Iterate.each(comments, comment => {
    comment.author = ProfileModel.getAllDetailsById(comment.author_id);
    if (comment.comments) {
      exports.relateCommentsToUsers(comment.comments);
    }
  });
};
exports.saveComment = (epic, comment, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/epics/' + epic.id + '/comments', {
    data: {
      text: comment
    },
    onComplete: res => {
      if (exports.isValidComment(res)) {
        dispatchEvent(EVENT_TYPES.EPIC_COMMENT_CREATED);
        exports.fetchLatestVersion(epic, callback);
      } else {
        callback(_.get(res, 'error'));
      }
    }
  });
};
exports.saveReply = (epic, commentID, comment, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/epics/' + epic.id + '/comments/' + commentID, {
    data: {
      text: comment
    },
    onComplete: res => {
      if (exports.isValidComment(res)) {
        dispatchEvent(EVENT_TYPES.EPIC_COMMENT_CREATED);
        exports.fetchLatestVersion(epic, callback);
      } else {
        callback(_.get(res, 'error'));
      }
    }
  });
};
exports.updateComment = (epic, commentID, comment, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/epics/' + epic.id + '/comments/' + commentID, {
    data: {
      text: comment
    },
    onComplete: res => {
      if (exports.isValidComment(res)) {
        dispatchEvent(EVENT_TYPES.EPIC_COMMENT_UPDATED);
        exports.fetchLatestVersion(epic, callback);
      } else {
        callback(_.get(res, 'error'));
      }
    }
  });
};
exports.deleteComment = (epic, commentID, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.delete('/api/private/epics/' + epic.id + '/comments/' + commentID, {
    onComplete: res => {
      if (res.success) {
        dispatchEvent(EVENT_TYPES.EPIC_COMMENT_DELETED);
        exports.fetchLatestVersion(epic, callback);
      }
    }
  });
};
exports.unlinkProductboard = (epic, callback) => {
  Backend.post(`/api/private/epics/${epic.id}/unlink-productboard`, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'epicChangesSaved');
    }
  });
};
exports.addStoryDataToEpics = () => {
  exports.each(exports.addStoryDataToEpic);
};
exports.addStoryDataToEpic = epic => {
  epic.allStories = _.reject(exports.getStories(epic), {
    archived: true
  });
  if (exports.isInProgress(epic) || Is.epicPage()) {
    epic.stories = {};
    ColumnModel.each(column => {
      epic.stories[column.id] = exports.getStories(epic, column, epic.allStories);
    });
  }
};
exports.addUserDataToEpic = epic => {
  epic.users = [];
  Iterate.each(epic.allStories, story => {
    Iterate.each(story.owners, owner => {
      if (!_.some(epic.users, {
        id: owner.id
      })) {
        epic.users.push({
          id: owner.id,
          user: owner,
          storyCount: 0
        });
      }
      const user = _.find(epic.users, {
        id: owner.id
      });
      user.storyCount++;
    });
  });
  epic.users.sort((a, b) => {
    return b.storyCount - a.storyCount;
  });
};
exports.toAutocompleteItem = _ref4 => {
  let {
    epic,
    epicState
  } = _ref4;
  return {
    className: Utils.cssify('epic-' + epicState.type),
    customIconLeft: EpicStateModel.getRenderedCustomIcon(epicState.customIcon),
    order: epicState.position,
    name: '<span data-model="Epic" data-id="' + epic.id + '" data-tooltip data-tooltip-delay="800" ' + 'data-tooltip-fn="App.Controller.Epics.renderEpicTooltip">' + Format.sanitize(epic.name) + '</span>',
    value: epic.id
  };
};
const toEpicItemGroup = groupedEpics => {
  if (!groupedEpics.reduce((acc, _ref5) => {
    let {
      epics
    } = _ref5;
    return acc + epics.length;
  }, 0)) {
    return [{
      html: AutocompleteController.getNoItemsFoundHtml()
    }];
  }
  const items = [];
  groupedEpics.forEach(_ref6 => {
    let {
      state,
      epics
    } = _ref6;
    if (!epics.length) {
      return;
    }
    items.push({
      html: '<div class="caption aligned-caption">' + state.name + '</div>'
    });
    epics.forEach(epic => {
      items.push(exports.toAutocompleteItem({
        epic,
        epicState: state
      }));
    });
  });
  return items;
};
exports.getItemsForAutocomplete = function () {
  let {
    selectedGroupId,
    filterByTeamScope
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let items = [{
    name: '<em>None</em>',
    value: null
  }];
  if (selectedGroupId) {
    const groupedEpics = EpicData.getEpicsByGroupIds({
      groupIds: selectedGroupId ? [selectedGroupId] : [],
      epics: exports.allNotArchived()
    });
    if (groupedEpics.set.length === 0 && groupedEpics.none.length === 0) {
      return items;
    }
    items.push({
      hr: true
    });
    items.push({
      html: `<div class="caption group-caption">Selected Team's Epics</div>`
    });
    items = [...items, ...toEpicItemGroup(groupedEpics.set)];
    items.push({
      hr: true
    });
    items.push({
      html: '<div class="caption group-caption">Other Epics</div>'
    });
    items = [...items, ...toEpicItemGroup(groupedEpics.none)];
    return items;
  }
  const epics = filterByTeamScope ? filterEpicsByTeamScope(exports.sortedByPositionAndState()) : exports.sortedByPositionAndState();
  if (epics.length) {
    EpicStateModel.getStateTypes().forEach(stateType => {
      EpicStateModel.getAllOfType(stateType).forEach(epicState => {
        const filteredEpics = epics.filter(epic => {
          return epicState.id === epic.epic_state_id;
        });
        if (filteredEpics.length) {
          items.push({
            hr: true
          });
          items.push({
            html: '<div class="caption">' + epicState.name + '</div>'
          });
          filteredEpics.forEach(epic => {
            const item = exports.toAutocompleteItem({
              epic,
              epicState
            });
            items.push(item);
          });
        }
      });
    });
  }
  return items;
};
const filterEpicsByTeamScope = epics => {
  const teamScopeId = getTeamScopedCollectionizeId();
  if (teamScopeId) {
    return epics.filter(epic => epic.group_id === teamScopeId);
  }
  return epics;
};
exports.findTeamForEpic = epic => {
  const epicStories = exports.getStories(epic);
  const teamNames = _.map(epicStories, story => {
    return StoryModel.getTeamName(story);
  });
  const mostCommonTeamName = _.chain(teamNames).countBy().entries().maxBy(_.last).head().value();
  return TeamModel.get({
    name: mostCommonTeamName
  });
};
exports.setActiveEpicsForSpace = spaceData => {
  const includeAll = spaceData.Epic === '*';
  const includeById = Array.isArray(spaceData.Epic) ? new Set(spaceData.Epic) : null;
  exports.each(epic => {
    let active = false;
    if (includeAll) {
      active = exports.isNotArchived(epic);
    } else if (includeById && includeById.has(epic.id)) {
      active = true;
    }
    epic.active = active;
  });
};
exports.updatePosition = _ref7 => {
  let {
    id,
    position
  } = _ref7;
  const epic = exports.getById(id);
  if (epic) {
    epic.position = position;
  }
};
exports.Promises.saveChanges = BaseUtils.promisify(exports.saveChanges);
exports.Promises.saveNew = BaseUtils.promisify(exports.saveNew);
exports.Promises.unlinkProductboard = BaseUtils.promisify(exports.unlinkProductboard);
exports.Promises.deleteEpic = BaseUtils.promisify(exports.deleteEpic);
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.removeLabel = BaseUtils.promisify(exports.removeLabel);
exports.Promises.saveComment = BaseUtils.promisify(exports.saveComment);
exports.Promises.saveReply = BaseUtils.promisify(exports.saveReply);
export { exports as default };