import { Airfocus } from 'pages/integrations/Airfocus';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
const exports = {};
exports.title = `Airfocus Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/airfocus';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Airfocus, 'integrations-airfocus'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };