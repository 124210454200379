import { makeVar, useReactiveVar } from '@apollo/client';
export const activeContextMenu = makeVar({
  Menu: null
});
export const pendingCloseContextMenu = makeVar(false);
export const closeActiveContextMenu = () => {
  if (pendingCloseContextMenu()) return;
  activeContextMenu({
    Menu: null
  });
};
const hasMenu = Menu => !!Menu;
export const useActiveContextMenu = () => {
  const {
    Menu,
    ...rest
  } = useReactiveVar(activeContextMenu);
  return {
    ...rest,
    Menu,
    isOpen: hasMenu(Menu),
    close: closeActiveContextMenu
  };
};