import "core-js/modules/es.array.push.js";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Url'], Url]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as EpicNotFoundTemplate from 'app/client/epics/views/templates/epicNotFound.html';
import * as EpicPageTemplate from 'app/client/epics/views/templates/epicPage.html';
import ArchiveWarningController from '../../../core/js/controllers/archiveWarning';
import { Button } from 'components/shared/DeprecatedButton';
import { Problem } from 'components/shared/Problem';
import { EpicPage } from 'pages/epic/EpicPage';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import AutocompleteController from 'app/client/core/js/controllers/autocomplete';
import EpicModel from 'app/client/core/js/models/epic';
import EpicsController from 'app/client/core/js/controllers/epics';
import * as Event from 'app/client/core/js/_frontloader/event';
import Format from 'app/client/core/js/modules/format';
import Globals from 'app/client/core/js/_frontloader/globals';
import InPlaceEditorController from 'app/client/core/js/controllers/inPlaceEditor';
import Is from 'app/client/core/js/modules/is';
import InviteUsersController from 'app/client/settingsShared/js/controllers/inviteUsers';
import LabelModel from 'app/client/core/js/models/label';
import MessageController from 'app/client/core/js/controllers/message';
import MilestoneModel from 'app/client/core/js/models/milestone';
import ProblemController from 'app/client/core/js/controllers/problem';
import ProfileModel from 'app/client/core/js/models/profile';
import Router from 'app/client/core/js/_frontloader/router';
import Settings from 'app/client/core/js/modules/settings';
import StoryDialog from 'app/client/core/js/controllers/storyDialog';
import Url from 'app/client/core/js/modules/url';
import UserModel from 'app/client/core/js/models/user';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import { isJoyEnabled } from 'data/entity/user';
import { celebrateFall } from 'utils/celebrate';
import * as Page from 'utils/page';
import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
import Hash from 'app/client/core/js/modules/hash';
const exports = {};
exports.title = url => {
  return Router.PAGE_TITLES.epic(url);
};
exports.route = url => {
  return url.indexOf(Url.getSlugPath() + '/epic/') === 0;
};
const formatPageTitle = epic => epic.name + ` | Epics | ${BRAND.NAME}`;
const setPageTitle = epic => {
  Page.setPageTitle(formatPageTitle(epic));
};
const renderEpicNotFound = () => {
  const html = EpicNotFoundTemplate.render();
  $('#epics').html(html);
  View.renderComponentConditionally({
    containerSelector: '#no-epic-found',
    component: Problem,
    props: {
      height: ProblemController.getContentHeight(ProblemController.EXTRA_OFFSETS.M),
      icon: React.createElement('span', {
        className: 'fa fa-exclamation-triangle'
      }),
      title: 'Epic Not Found.',
      description: "The epic was either deleted, or you don't have access to it.",
      buttons: React.createElement(Button, {
        text: 'Go to the Epics page',
        href: `${App.Url.getSlugPath()}/epics`,
        size: Button.sizes.xsmall
      })
    }
  });
  Event.trigger('pageRendered', 'Epic');
};
const CONSOLIDATED_DATA_FETCHED_KEY = 'consolidatedDataFetched';
const waitForConsolidatedFetch = () => new Promise(resolve => {
  const listener = () => {
    const value = Globals.get(CONSOLIDATED_DATA_FETCHED_KEY);
    if (value) {
      Globals.removeChangeListener(CONSOLIDATED_DATA_FETCHED_KEY, listener);
      resolve(true);
    }
  };
  Globals.addChangeListener(CONSOLIDATED_DATA_FETCHED_KEY, listener);
  listener();
});
const loadEpic = async (onFound, onNotFound) => {
  await waitForConsolidatedFetch();
  if (Url.getCurrentPage() !== 'epic') return;
  let epic = null;
  try {
    const epicId = Url.parsePath().epic;
    epic = await EpicModel.getOrFetchEpic(epicId);
  } catch {}
  if (Url.getCurrentPage() === 'epic') {
    if (epic) onFound(epic);else onNotFound();
  }
};
exports.render = () => {
  if (!Is.epicPage()) {
    return false;
  }
  loadEpic(epic => {
    const activityId = BaseUtils.toNumber(Hash.get().replace('activity-', ''));
    EpicModel.addStoryDataToEpic(epic);
    EpicModel.addUserDataToEpic(epic);
    setPageTitle(epic);
    const permission = UserModel.getLoggedInUserPermission();
    View.renderWithComponents({
      template: EpicPageTemplate,
      templateMountNode: document.querySelector('#epics'),
      templateData: {
        id: epic.id,
        readOnly: Is.readOnly(permission),
        activityId
      },
      components: {
        epicPage: {
          component: EpicPage
        }
      }
    });
    Event.trigger('resize');
    Event.trigger('pageRendered', 'Epic');
  }, renderEpicNotFound);
};
exports.reRender = () => {
  if (StoryDialog.isOpen()) return;
  const element = $('.epic-page');
  if (element.length === 0) {
    return exports.render();
  }
  loadEpic(epic => {
    setPageTitle(epic);
    Event.trigger('resize');
  }, renderEpicNotFound);
};
exports.destroyPage = () => {};
function _cameFromMilestoneSection() {
  const lastURL = Globals.get('HotReload.lastPage') || '';
  return lastURL.indexOf('/milestone') !== -1;
}
exports.changeState = (epic, epic_state_id) => {
  const changes = {
    epic_state_id
  };
  EpicModel.saveChanges(epic, changes, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update epic state.'
      });
    } else if (epic.state === 'done' && isJoyEnabled()) {
      celebrateFall();
    }
  });
};
exports.archiveEpic = function () {
  const epic = EpicModel.getFromUrl();
  return ArchiveWarningController.epic.call(this, epic, {
    onApprove: () => {
      EpicModel.archiveEpic(epic, err => {
        if (err) {
          MessageController.error(err, {
            secondary: `Unable to archive <strong>${Format.sanitize(epic.name)}</strong>.`
          });
        } else {
          MessageController.success(`Epic <strong>${Format.sanitize(epic.name)}</strong> archived.`);
        }
      });
    }
  });
};
exports.unarchiveEpic = epic => {
  EpicModel.unarchiveEpic(epic, err => {
    if (err) {
      MessageController.error(err, {
        secondary: `Unable to unarchive <strong>${Format.sanitize(epic.name)}</strong>.`
      });
    } else {
      MessageController.success(`Epic <strong>${Format.sanitize(epic.name)}</strong> unarchived.`);
    }
  });
};
exports.deleteEpic = async epic => {
  try {
    await EpicModel.Promises.deleteEpic(epic);
  } catch (error) {
    MessageController.error(error, {
      secondary: `Unable to delete <strong>${Format.sanitize(epic.name)}</strong>.`
    });
  }
};
exports.updateNameComponent = (epic, element) => {
  const name = Format.sanitizeAndEmojify(epic.name);
  const html = name + '<span class="fa fa-pencil"></span>';
  element.find('.details-name').removeClass('is-updating').find('h2').html(html);
};
exports.init = () => {
  const NS = '.EpicPageController';
  Event.onlyOn('pageDestroy' + NS, () => {
    exports.destroyPage();
  });
  EpicModel.on('epicChangesSaved', () => {
    exports.reRender();
  });
  EpicModel.on('removed', epic => {
    const path = Url.parsePath();
    let redirectPath = Url.getSlugPath() + '/epics';
    if (epic.id === path.epic) {
      const milestone = MilestoneModel.getById(epic.milestone_id);
      if (_cameFromMilestoneSection() && milestone) {
        redirectPath = milestone.url;
      }
      Utils.redirect(redirectPath);
    }
  });
};
Router.add(exports.route, exports.title, exports.render);
exports.saveName = function () {
  const epic = Utils.getModelFromContext(this);
  const editorElement = $(this).closest('.in-place-editor');
  const newName = editorElement.find('textarea').val().trim();
  if (!newName) {
    MessageController.error('A name is required.');
    return false;
  }
  if (epic.name !== newName) {
    $('.epic-page').find('.details-name').addClass('is-updating').find('h2').append('<span class="fa fa-star fa-spin"></span>');
    EpicModel.saveChanges(epic, {
      name: newName
    }, err => {
      if (err) {
        $('.epic-page').find('.details-name').removeClass('is-updating').find('.fa-star').remove();
        MessageController.error(err, {
          secondary: 'The original name has been restored.'
        });
      }
    });
  }
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.createStoryInEpic = () => {
  const isReadOnly = Is.readOnly(UserModel.getLoggedInUserPermission());
  if (isReadOnly || Settings.isOpen()) {
    return false;
  }
  AddNewStoryController.render({
    context: 'epic'
  });
  return false;
};
exports.addMeAsFollower = function (epic) {
  const _epic = epic?.entity_type === 'epic' ? epic : Utils.getModelFromContext(this);
  const profile = ProfileModel.getCurrentUserProfileDetails();
  $(this).removeAttr('data-on-click').find('.fa').removeClass('fa-plus fa-times').addClass('fa-spin fa-star');
  if (!EpicModel.isFollower(_epic, profile)) {
    EpicModel.addMeAsFollower(_epic, err => {
      _handleFollowerShortcutResponse(err);
    });
  }
  return false;
};
exports.removeMeAsFollower = function (epic) {
  const _epic = epic?.entity_type === 'epic' ? epic : Utils.getModelFromContext(this);
  const profile = ProfileModel.getCurrentUserProfileDetails();
  $(this).removeAttr('data-on-click').find('.fa').removeClass('fa-plus fa-times').addClass('fa-spin fa-star');
  if (EpicModel.isFollower(_epic, profile)) {
    EpicModel.removeMeAsFollower(_epic, err => {
      _handleFollowerShortcutResponse(err);
    });
  }
  return false;
};
function _handleFollowerShortcutResponse(err) {
  _updateEpicFollower(err);
}
exports.openEpicFollowerUpdater = function (epic) {
  const _epic = epic?.entity_type === 'epic' ? epic : Utils.getModelFromContext(this);
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  AutocompleteController.open({
    items: ProfileModel.getItemsForFollowerAutocomplete(_epic),
    footer: (InviteUsersController.getInviteUsersFooter() || '') + '<div class="autocomplete-footer-tip">' + 'Followers are notified of all story activity in this epic.</div>',
    onResultsUpdate: InviteUsersController.getAutocompleteWithInviteUpdateHandler,
    multiSelect: true,
    title: 'Update Epic Followers',
    noActive: false,
    onApply: profile => {
      if (profile && _.isString(profile)) {
        profile = ProfileModel.getDetailsByName(profile);
      }
      if (profile && ProfileModel.isValid(profile)) {
        AutocompleteController.disable();
        if (EpicModel.isFollower(_epic, profile)) {
          EpicModel.removeFollower(_epic, profile, _updateEpicFollower);
        } else {
          EpicModel.addFollower(_epic, profile, _updateEpicFollower);
        }
      }
    },
    showInput: true,
    target: document.querySelector('#cid-followers-field'),
    targetSelector: '#cid-followers-field',
    topOffset: 4,
    width: 240
  });
  return false;
};
function _updateEpicFollower(err) {
  if (err) {
    AutocompleteController.close();
    MessageController.error(err, {
      secondary: 'Unable to update followers.'
    });
  } else {
    AutocompleteController.enable();
  }
}
exports.openEpicStateUpdater = function () {
  const epic = Utils.getModelFromContext(this);
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  function onApply() {}
  function onSave(err, epic) {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update epic state.'
      });
    } else {
      if (epic.state === 'done' && isJoyEnabled()) {
        celebrateFall();
      }
    }
  }
  EpicsController.epicStateControl.call(this, epic, onApply, onSave);
  return false;
};
exports.openEpicObjectiveUpdater = function () {
  const epic = Utils.getModelFromContext(this);
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  function onApply() {}
  function onSave(err) {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update epic objective.'
      });
    }
  }
  EpicsController.epicMilestoneControl.call(this, epic, onApply, onSave);
  return false;
};
exports.openDateControl = function () {
  const epic = Utils.getModelFromContext(this);
  const context = this;
  const fieldName = Utils.data(context, 'field-name');
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  EpicsController.openDeadlineDatepicker({
    context,
    epic,
    fieldName,
    callback: value => {
      if (epic[fieldName] === value) {
        return;
      }
      EpicModel.saveChanges(epic, {
        [fieldName]: value
      }, err => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to update date.'
          });
        }
      });
    }
  });
  return false;
};
exports.clearDate = function () {
  const epic = Utils.getModelFromContext(this);
  const fieldName = Utils.data(this, 'field-name');
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  EpicModel.saveChanges(epic, {
    [fieldName]: null
  }, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to clear date.'
      });
    }
  });
  return false;
};
exports.addLabel = function () {
  const epic = Utils.getModelFromContext(this);
  let existingLabels = epic.labels || [];
  AutocompleteController.open({
    items: LabelModel.getItemsForLabelsAutocomplete(epic),
    footer: '<div class="autocomplete-footer-tip">' + 'You can use quotes to create labels with spaces, e.g. "label with spaces". ' + 'Labels can be edited on the Labels page.</div>',
    target: this,
    targetSelector: '#epic-page-add-label-dropdown',
    onResultsUpdate: _ref2 => {
      let {
        hasResults,
        setNoResults,
        value
      } = _ref2;
      if (!hasResults) {
        setNoResults(LabelModel.createNewLabelText(value));
      }
    },
    onPartialMatch: LabelModel.createNewLabelText,
    multiSelect: true,
    noActive: true,
    placeholder: 'mockup, "tech debt"',
    title: 'Add or Remove Labels',
    showInput: true,
    onApply: label => {
      AutocompleteController.disable();
      if (_.isString(label)) {
        existingLabels = LabelModel.addLabelAsString(existingLabels, label);
      } else {
        existingLabels = LabelModel.addExistingLabel(existingLabels, label);
      }
      if (_.isString(label)) {
        _saveLabelsToEpic(epic, existingLabels);
        AutocompleteController.close();
      } else {
        _saveLabelsToEpic(epic, existingLabels, true);
      }
    },
    topOffset: 4,
    width: 280
  });
  return false;
};
exports.removeLabel = function () {
  const tagElement = $(this).closest('.tag');
  if (tagElement.hasClass('is-being-deleted')) {
    return false;
  }
  const epic = Utils.getModelFromContext(this, 'Epic');
  const label = Utils.getModelFromContext(this);
  tagElement.addClass('is-being-deleted').css({
    opacity: 0.5
  }).find('.delete.fa').removeClass('fa-times').addClass('fa-spinner fa-spin');
  EpicModel.removeLabel(epic, label, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to remove <strong>' + Format.sanitizeAndEmojify(label.name) + '</strong>.'
      });
    }
    exports.reRender();
  });
  return false;
};
function _saveLabelsToEpic(epic, existingLabels, reEnableAutocomplete) {
  EpicModel.saveLabels(epic, existingLabels, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to add label.'
      });
      AutocompleteController.close();
    } else {
      if (reEnableAutocomplete) {
        AutocompleteController.enable();
      }
      epic.labels = LabelModel.sortByLowercaseName(existingLabels);
    }
    exports.reRender();
  });
}
export { exports as default };