import remove from 'lodash/remove';
import { useMemo } from 'react';
import { isDesk, isFigma, isFreshdesk, isFront, isGitHub, isHelpScout, isIntercom, isJiraServiceDesk, isKustomer, isRollbar, isSentry, isZendesk } from 'data/entity/externalLink';
export const useNamedExternalLinks = externalLinks => useMemo(() => {
  if (!externalLinks?.length) return {
    deskExternalLinks: [],
    freshdeskExternalLinks: [],
    frontExternalLinks: [],
    figmaExternalLinks: [],
    gitHubExternalLinks: [],
    helpScoutExternalLinks: [],
    intercomExternalLinks: [],
    jiraServiceDeskExternalLinks: [],
    kustomerExternalLinks: [],
    rollbarExternalLinks: [],
    sentryExternalLinks: [],
    zendeskExternalLinks: []
  };
  const allExternalLinks = [...externalLinks];
  return {
    deskExternalLinks: remove(allExternalLinks, isDesk),
    freshdeskExternalLinks: remove(allExternalLinks, isFreshdesk),
    frontExternalLinks: remove(allExternalLinks, isFront),
    figmaExternalLinks: remove(allExternalLinks, isFigma),
    gitHubExternalLinks: remove(allExternalLinks, isGitHub),
    helpScoutExternalLinks: remove(allExternalLinks, isHelpScout),
    intercomExternalLinks: remove(allExternalLinks, isIntercom),
    jiraServiceDeskExternalLinks: remove(allExternalLinks, isJiraServiceDesk),
    kustomerExternalLinks: remove(allExternalLinks, isKustomer),
    rollbarExternalLinks: remove(allExternalLinks, isRollbar),
    sentryExternalLinks: remove(allExternalLinks, isSentry),
    zendeskExternalLinks: remove(allExternalLinks, isZendesk)
  };
}, [externalLinks]);