import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { err, ok } from '@clubhouse/shared/utils/result';
import StoryDialog from 'app/client/core/js/controllers/storyDialog';
import { setStoryDialogNavigation } from 'components/shared/StoryDialogNavigation/utils';
import { getById, getOrFetchStory } from '../data/entity/story';
import { useConsolidatedDataFetched } from './consolidatedFetch';
import { navigateTo } from './navigation';
import { getHref } from './navigation/story';
const isRenderingVar = makeVar(false);
export const isRendering = () => isRenderingVar();
export const startRendering = () => isRenderingVar(true);
export const stopRendering = () => isRenderingVar(false);
export const useIsRendering = () => useReactiveVar(isRenderingVar);
export const showLoadingStoryDialog = function () {
  let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return StoryDialog.showLoading(args);
};
export const openStory = (story, activityId) => StoryDialog.loadStory(story, activityId);
export const openStoryById = storyId => StoryDialog.loadStoryById(storyId);
export const getActivityId = hash => hash?.split('#activity-')?.[1];
export const getCurrentlyDisplayedStory = () => StoryDialog.getCurrentlyDisplayedStory();
export const openOrFetchAndOpen = async (id, activityId) => {
  let story = getById(id);
  let isCancelled = false;
  if (!story) {
    showLoadingStoryDialog({
      onClose: () => {
        isCancelled = true;
      }
    });
    try {
      story = await getOrFetchStory(id);
    } catch (e) {
      return err('notFound');
    }
  }
  if (!isCancelled) {
    openStory(story, activityId);
  }
  return ok(story);
};
export const isOpen = () => StoryDialog.isOpen();
export const isOpening = () => StoryDialog.isOpening();
export const openFromRouter = () => StoryDialog.openFromRouter();
export const closeCurrentStoryDialog = () => StoryDialog.closeCurrentStoryDialog();
export const useNavigateToStory = slug => {
  const navigateToStoryUrl = useNavigateToStoryUrl();
  return useCallback(_ref => {
    let {
      publicId,
      name
    } = _ref;
    const href = getHref(slug, {
      publicId,
      name
    });
    navigateToStoryUrl(href);
  }, [navigateToStoryUrl, slug]);
};
export const useNavigateToStoryUrl = () => {
  const isFetched = useConsolidatedDataFetched();
  return useCallback(url => {
    if (isFetched) navigateTo({
      url
    });else window.location.assign(url);
  }, [isFetched]);
};
export const useOpenStoryWithNavigation = (slug, defaultNavigation) => {
  const navigateToStory = useNavigateToStory(slug);
  return useCallback((story, navigation) => {
    const nav = navigation || defaultNavigation;
    if (nav) setStoryDialogNavigation(nav);
    navigateToStory(story);
  }, [navigateToStory, defaultNavigation]);
};