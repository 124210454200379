import { DeleteEpicActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ContextMenuItem } from '@clubhouse/shared/components/ContextMenu';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useDeleteEpicMutation } from '../useDeleteEpicMutation';
import { useEpicConfirmationDialog } from '../useEpicConfirmationDialog';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DeleteEpicFragment = DeleteEpicActionFragmentFragmentDoc;
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: message
    })
  });
};
const useDeleteEpic = epic => {
  const {
    openDialog,
    closeDialog
  } = useEpicConfirmationDialog();
  const deleteEpic = useDeleteEpicMutation();
  const onAction = useCallback(() => {
    return new Promise(resolve => {
      openDialog('delete', {
        onCancel: resolve,
        onClick: async () => {
          try {
            closeDialog();
            await deleteEpic(epic);
            showToast(true, _jsxs(_Fragment, {
              children: ["Epic", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: epic.name
                })
              }), ' ', "deleted."]
            }));
          } catch {
            showToast(false, _jsxs(_Fragment, {
              children: ["Unable to delete", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: epic.name
                })
              }), "."]
            }));
          } finally {
            resolve();
          }
        }
      });
    });
  }, [closeDialog, deleteEpic, epic, openDialog]);
  return {
    onAction
  };
};
export const DeleteEpicAction = _ref => {
  let {
    epic
  } = _ref;
  const {
    onAction
  } = useDeleteEpic(epic);
  return _jsxs(MoreActions.Item, {
    icon: "Trash",
    onClick: onAction,
    isDisabled: isLoggedInUserObserver(),
    children: ["Delete ", Nouns.Epic.singular]
  });
};
DeleteEpicAction.displayName = "DeleteEpicAction";
export const DeleteEpicContextMenuItem = _ref2 => {
  let {
    epic
  } = _ref2;
  const {
    onAction
  } = useDeleteEpic(epic);
  return _jsx(ContextMenuItem, {
    icon: "Trash",
    onClick: onAction,
    label: `Delete ${Nouns.Epic.singular}`,
    isDisabled: isLoggedInUserObserver()
  });
};
DeleteEpicContextMenuItem.displayName = "DeleteEpicContextMenuItem";