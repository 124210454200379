import { Component } from 'react';
import * as Log from 'utils/log';
export class ViewPortalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, reactErrorInfo) {
    Log.error(error, {
      component: this.props.componentName
    }, {
      react: reactErrorInfo
    });
  }
  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
ViewPortalErrorBoundary.displayName = "ViewPortalErrorBoundary";