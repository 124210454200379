import { THEME_NAMES, getTheme, isThemePreference, setThemePreference } from '@clubhouse/shared/utils/theme';
import UserModel from 'app/client/core/js/models/user';
import { getCurrentUser, useCurrentUser } from 'data/entity/user';
import { useEffect, useState } from 'react';

/**
 * Toggles the current user's Dark Mode setting from whatever it's currently on
 */
export const toggleDarkMode = () => {
  setUserThemePreference(getTheme() === THEME_NAMES.DARK ? THEME_NAMES.LIGHT : THEME_NAMES.DARK);
};

/**
 * Sets the app theme preference and also persists that new preference the user model. The vast
 * majority of theme functions are in @clubhouse/shared/utils/theme, except this one which persists
 * to the user model and therefore doesn't want to live in shared.
 */
export const setUserThemePreference = preference => {
  const currentUser = getCurrentUser();

  // This persists the theme preference to LocalStorage and also sets the app theme
  setThemePreference(preference);

  // This persists the change to the DB but doesn't trigger any kind of collectionize update.
  UserModel.updateUser({
    app_theme: preference
  });

  // This causes collectionize to know that the user changed, which triggers an event so the
  // useEntity in useThemePreference below will catch the update.
  if (currentUser) {
    UserModel.update({
      id: currentUser.id,
      app_theme: preference
    });
  }
};

/**
 * Makes the user's theme preference and a preference setter available in component state
 */
export const useThemePreference = () => {
  const currentUser = useCurrentUser();
  const initial = isThemePreference(currentUser.app_theme) ? currentUser.app_theme : THEME_NAMES.LIGHT;
  const [preference, setPreferenceState] = useState(initial);
  const setPreference = newPreference => {
    setPreferenceState(newPreference);
    setUserThemePreference(newPreference);
  };
  useEffect(() => {
    if (!isThemePreference(currentUser.app_theme)) return;
    setPreferenceState(currentUser.app_theme);
  }, [currentUser]);
  return [preference, setPreference];
};