import { v4 as uuidv4 } from 'uuid';
import BulkEditorController from './bulkEditor';
import Charts from '../modules/charts';
import ContextMenuController from './contextMenu';
import Dialog from '../modules/dialog';
import DropdownModel from '../models/dropdown';
import * as Event from '../_frontloader/event';
import Collection from '../_frontloader/collection';
import FloatingElement from '../modules/floatingElement';
import Globals from '../_frontloader/globals';
import PanelController from './panel';
import Router from '../_frontloader/router';
import ScrollTabsController from './scrollTabs';
import AutocompleteController from './autocomplete';
import Segment from '../modules/segment';
import Url from '../modules/url';
import Utils from '../modules/utils';
import View from '../modules/view';
import SideDrawer from '../modules/sideDrawer';
import { EVENTS } from 'utils/monitoring';
import { isScopedToSameTeam } from 'components/team-navigation/utils/teamScope';
import { PAGE_NAMES } from 'utils/navigation/paths';
import { navigateToDocs } from 'pages/write/lib/navigateToDocs';
const exports = {};
const $ = window.$;
const EVENT_NS = '.HotReloadController';
const IS_READY_KEY = 'HotReloadIsReady';
const CONTENT_SELECTORS = '#content > .page-content:not([data-react]), ' + '#content > .sidebar-open-container, ' + '#content > #columns, ' + '.react-stories-table-content, ' + '#sidebar, body > #messages, ' + '#content > #no-stories-found';
const SUPPORTED_PAGES = {
  dashboard: 'dashboard',
  epic: 'epics',
  epics: 'epics',
  iteration: 'iterations',
  iterations: 'iterations',
  keyresult: 'keyresult',
  label: 'labels',
  labels: 'labels',
  milestone: 'milestones',
  milestones: 'milestones',
  objective: 'objective',
  [PAGE_NAMES.OBJECTIVES]: PAGE_NAMES.OBJECTIVES,
  project: 'projects',
  projects: 'projects',
  report: 'reports',
  search: 'search',
  status: 'status',
  stories: 'stories',
  story: 'stories',
  write: 'write',
  roadmap: 'roadmap',
  teams: 'teams',
  [PAGE_NAMES.SETTINGS]: PAGE_NAMES.SETTINGS
};
exports.init = () => {
  Event.onlyOn('orgDataFetched' + EVENT_NS, () => {
    exports.isReady(true);
  });
  Event.onlyOn('poppingStateWithRouteName' + EVENT_NS, routeName => {
    // Question: should we verify this is a proper URL first?

    if (Router.isPartial()) {
      Router.partialRouteChanged();
      return;
    }

    // We can't use Utils.redirect here because the URL has already changed!
    const page = exports.guessPageFromURL(routeName);
    if (page && exports.isReady()) {
      exports.loadURL(routeName, page);
    } else {
      exports.setLocation(Url.sanitizeRoute(routeName));
    }
  });
};
exports.setLocation = href => {
  window.location.href = href;
};
exports.isReady = value => {
  if (value) {
    return Globals.set(IS_READY_KEY, value);
  }
  return Globals.get(IS_READY_KEY);
};

// Right now, this is only used to manage breadcrumbs on the Epic page.
exports.setLastPage = () => {
  Globals.set('HotReload.lastPage', Url.getCurrentPathname());
};
exports.guessPageFromURL = url => {
  let match = false;
  if (url.indexOf(window.location.origin) !== -1) {
    url = url.split(`${window.location.origin}/`)[1];
  }
  if (url.indexOf('#') !== -1) {
    url = url.split('#')[0];
  }
  url = url.split('/');
  Object.entries(SUPPORTED_PAGES).forEach(_ref => {
    let [route, page] = _ref;
    if (url[1] === route || url[2] === route || url[2] === 'objective' && url[4] === route || url[2]?.startsWith('objectives') && route === 'objectives') {
      match = page;
      return false;
    }
  });
  return match;
};
const beforePushState = () => {
  Event.trigger('pageDestroy');
  Router.clearAllRoutes();
  _destroyAllSortableElements();
  _destroyAllDatepickers();
  AutocompleteController.forceClose();
  BulkEditorController.destroy();
  Charts.destroyAll();
  ContextMenuController.close();
  View.unfreezeBodyOverflow();
  Dialog.closeIfOpen();
  SideDrawer.closeIfOpen();
  PanelController.closeAll();
  ScrollTabsController.destroy();
  FloatingElement.destroyAll();
  DropdownModel.destroyAll();
  Utils.clearArchivedClass();

  // This needs to happen after the above so that destroyAll can execute.
  Event.clearListeners();
};
const afterPushState = () => {
  Collection.clearAllListeners();
  $(CONTENT_SELECTORS).remove();
};
exports.resetPage = () => {
  beforePushState();
  afterPushState();
};
exports.loadURL = (url, page) => {
  beforePushState();
  _pushState(url, page);
  afterPushState();
  Globals.set('HotReload.renderId', uuidv4());
};
function _destroyAllSortableElements() {
  if ($('.ui-sortable').length > 0) {
    Utils.destroySortable($('.ui-sortable'));
  }
}
function _destroyAllDatepickers() {
  $('.datepicker, .iteration-datepicker').each(function () {
    Utils.destroyDatepicker($(this));
  });
}
function _pushState(url, page) {
  // TODO: fix underlying bug here, title will always be the right side of or statement
  const title = Router.PAGE_TITLES[page] || `${_.upperFirst(page)} | ${BRAND.NAME}`;
  Router.pushState(url, _.isFunction(title) ? title(url) : title);
}

// ----- Actions -----

exports.load = function (e) {
  const page = Utils.data(this, 'page');
  const element = $(this);
  if (element.closest('#menu').length) {
    Segment.sendEvent(EVENTS.Interaction_LeftNavigation, {
      source: Url.getCurrentPage(),
      selection: page
    });
  }
  if (element.hasClass('search-result') && page === 'epics') {
    Segment.sendEvent(EVENTS.Interaction_Search, {
      source: 'navigation',
      selection: 'epic card'
    });
  }
  if (!exports.isReady()) {
    return; // bubble to anchor tag event
  }

  // Handle ctrl/shift/etc clicks.
  if (e && Utils.modifiedClick(e)) {
    return; // bubble to anchor tag event
  }
  const url = element.attr('href');
  exports.setLastPage();
  exports.loadURL(url, page);
  return false;
};
exports.loadPageFromMenu = (page, relativeHref, getLoggingProps) => {
  Segment.sendEvent(EVENTS.Interaction_LeftNavigation, {
    source: Url.getCurrentPage(),
    selection: page,
    ...getLoggingProps?.()
  });
  if (!exports.isReady()) {
    // We initially thought returning nothing would bubble up to the anchor tag event, but it doesn't.
    // It seems like we're calling `e.preventDefault()`, and multiple call sites exist.
    // So we have to manually redirect if the hot reload controller isn't ready yet to improve the UX.
    window.location.href = relativeHref;
    return;
  }

  // Because we have multiple instances of history, we can't simply use `loadURL` from the Hot Reload Controller.
  // If we do so, both states will be de-synced and even though it seems like a feature because it reminds the
  // last doc you saw, in multiple other cases, it could lead to "404" dead end state. To prevent that, we need
  // to navigate via `navigateToDocs` to make sure we sync both histories: Main Shortcut & Docs.
  if (page === 'write') {
    navigateToDocs();
    return;
  }
  if (page !== Url.getCurrentPage() || page === Url.getCurrentPage() && !isScopedToSameTeam(window.location.href, `${window.location.origin}${relativeHref}`)) {
    exports.setLastPage();
    exports.loadURL(relativeHref, page);
  }
  return false;
};
export { exports as default };