import { useCallback } from 'react';
import { useLatestRef } from '@clubhouse/shared/hooks/useLatestRef';
import { useToggleState } from '@clubhouse/shared/hooks/useToggleState';
export function useFetchMore(fetchMore) {
  const [isFetchingMore, {
    on: startFetching,
    off: stopFetching
  }] = useToggleState();
  const isFetchingRef = useLatestRef(isFetchingMore); // Avoid `fetchMoreOneAtATime` being re-created on every toggle

  const fetchMoreOneAtATime = useCallback(async variables => {
    if (!isFetchingRef.current) {
      startFetching();
      await fetchMore({
        variables
      });
      stopFetching();
    }
  }, [fetchMore, isFetchingRef, startFetching, stopFetching]);
  return {
    isFetchingMore,
    fetchMore: fetchMoreOneAtATime
  };
}