import Logo from '@clubhouse/assets/png/third-party-logos/rollbar_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Rollbar() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.ROLLBAR
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Rollbar"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Rollbar Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://rollbar.com/",
          children: "Rollbar"
        }), " provides real-time error monitoring, alerting, and analytics for developers."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_ROLLBAR,
          children: "our instructions"
        }), " for how to configure this integration in your Rollbar account."]
      })]
    })]
  });
}
Rollbar.displayName = "Rollbar";