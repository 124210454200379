import "core-js/modules/es.array.push.js";
/**
 * Formats the selected invites into two separate arrays based on their grouping by organization.
 *
 * This function takes a list of selected invite IDs and a record of invite groups organized by organization.
 * It then filters and separates these invites into two arrays: one for individual invites and another for workspace invites.
 * Individual invites are identified by the presence of an `inviteId`, while workspace invites are identified by the absence of an `inviteId`.
 *
 * This is essentially a group by depending on whether you received an explicit invite or "invited" by domain matching.
 */
export const formatInvites = (selectedValues, groupedByOrgInvites) => {
  const inviteIds = [];
  const workspaceIds = [];
  Object.values(groupedByOrgInvites).forEach(invites => {
    invites.forEach(_ref => {
      let {
        id,
        inviteId
      } = _ref;
      if (inviteId && selectedValues.includes(inviteId)) {
        inviteIds.push(inviteId);
      } else if (!inviteId && selectedValues.includes(id)) {
        workspaceIds.push(id);
      }
    });
  });
  return [inviteIds, workspaceIds];
};
export class ValidationError extends Error {
  type = 'ShortcutValidationError';
}
export class EntitlementError extends Error {
  type = 'ShortcutEntitlementError';
}