import { MAP_SCALE_VALUE_TO_DISPLAYABLE_NAME } from '../reports-v2/constants';
// Convert floating point days value to floating point hours equivalent.
export const convertFloatingPointDaysToFloatingPointHours = daysFloat => {
  const hoursFloat = daysFloat * 24;
  return hoursFloat.toFixed(1);
};

/** Returns a scale selector object that is compatible with the return type of `ReportSelectFilterReport_SelectorFragment`,
 *  this selector can be consumed by the reports-v2 <ReportSelectFilter /> component via it's `selector` prop.
 */
export const generateScaleSelectorObject = currentScaleValue => {
  const scaleSelector = {
    __typename: 'Report_Selector',
    value: currentScaleValue,
    valueDisplayable: {
      __typename: 'DisplayableItem',
      displayableName: MAP_SCALE_VALUE_TO_DISPLAYABLE_NAME[currentScaleValue]
    },
    valueOptions: {
      __typename: 'SelectItemsConnection',
      edges: [{
        __typename: 'SelectItemEdgeOption',
        value: 'linear',
        displayable: {
          __typename: 'CustomField',
          id: 'linear',
          displayableName: MAP_SCALE_VALUE_TO_DISPLAYABLE_NAME.linear
        },
        isSelected: currentScaleValue === 'linear'
      }, {
        __typename: 'SelectItemEdgeOption',
        value: 'log',
        displayable: {
          __typename: 'CustomField',
          id: 'log',
          displayableName: MAP_SCALE_VALUE_TO_DISPLAYABLE_NAME.log
        },
        isSelected: currentScaleValue === 'log'
      }]
    }
  };
  return scaleSelector;
};
export const filterStoriesByScatterDataPointCoordinates = (scatterDataPoints, targetScatterDataPointProps) => {
  // Grab the target data point's cx and cy values
  const {
    cx: targetDataPointCx,
    cy: targetDataPointCy,
    width
  } = targetScatterDataPointProps;

  /** ReCharts reports the width(diameter) of each fruit loop as ~9 pixels,
   *  if we include the border around the tooltip it comes up to ~10 pixels and that seems to work as good threshold at the moment.
   **/
  const cxThreshold = Math.round(width) + 1; // ~10 pixels with our current fruit loop width
  const cyThreshold = Math.round(width) + 1; // ~10 pixels with our current fruit loop width
  // Filter the stories that are within the cx and cy pixel thresholds.
  const filteredStories = scatterDataPoints.filter(dataPoint => {
    if (!dataPoint.cx || !dataPoint.cy) return false;
    return Math.abs(dataPoint.cx - targetDataPointCx) <= cxThreshold && Math.abs(dataPoint.cy - targetDataPointCy) <= cyThreshold;
  }).map(filteredDataPoint => ({
    story: filteredDataPoint.story,
    timeSpent: filteredDataPoint.timeSpent,
    sampledAtEpoch: filteredDataPoint.sampledAtEpoch
  }));
  const targetStoryIdx = filteredStories.findIndex(story => story.story?.publicId === targetScatterDataPointProps.story?.publicId);
  const targetStory = filteredStories.splice(targetStoryIdx, 1);

  // Ensure that the currently hovered over story in a cluster is always rendered at the top of the tooltip list.
  filteredStories.unshift(...targetStory);
  return filteredStories;
};