import Collection from 'app/client/core/js/_frontloader/collection';
import Backend from 'app/client/core/js/modules/backend';
import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
export const collection = Collection.create('Sync', {});
export const fetchById = (id, cb) => {
  Backend.get(`/api/private/syncs/${id}`, {
    onComplete(res) {
      collection.defaultGetHandler(res, cb);
    }
  });
};
export const fetchAll = cb => {
  Backend.get(`/api/private/syncs`, {
    onComplete(res) {
      collection.defaultFetchAllHandler(res, cb);
    }
  });
};
export const fetchEligibleImports = cb => {
  Backend.get(`/api/private/syncs/eligible-imports`, {
    onSuccess: response => {
      cb(null, response);
    },
    onError: err => {
      cb(err.error);
    }
  });
};
export const update = (id, data, cb) => {
  Backend.put(`/api/private/syncs/${id}`, {
    data,
    onComplete(res) {
      collection.defaultGetHandler(res, cb);
    }
  });
};
export const post = (data, cb) => {
  Backend.post(`/api/private/syncs`, {
    data,
    onComplete(res) {
      collection.defaultGetHandler(res, cb);
    }
  });
};
export const deleteSync = (id, cb) => {
  Backend.delete(`/api/private/syncs/${id}`, {
    onComplete: (res, xhr) => {
      collection.defaultDeleteHandler(collection.getById(id), res, xhr, cb);
    }
  });
};
export const hasActiveSync = () => {
  return collection.some(sync => sync.enabled);
};
export default Object.assign(collection, {
  fetchAll,
  fetchById,
  deleteSync,
  post,
  update,
  fetchEligibleImports,
  Promises: {
    fetchAll: BaseUtils.promisify(fetchAll),
    fetchById: BaseUtils.promisify(fetchById),
    delete: BaseUtils.promisify(deleteSync),
    post: BaseUtils.promisify(post),
    update: BaseUtils.promisify(update),
    fetchEligibleImports: BaseUtils.promisify(fetchEligibleImports)
  }
});