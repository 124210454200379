import { SelectOptionChecked, SelectOptionDivider } from '@clubhouse/shared/components/Select';
import { NONE_VALUE, SOME_VALUE } from '@clubhouse/shared/constants';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const All = type => ({
  selectedValues: [],
  key: 'all',
  name: _jsxs("span", {
    children: ["All ", type.plural]
  })
});
export const Nothing = () => ({
  key: 'nothing',
  selectedValues: [],
  name: _jsx("span", {
    children: "None"
  })
});
export const Any = type => ({
  value: SOME_VALUE,
  name: `Any ${type.singular}`,
  Component: SelectOptionChecked
});
export const None = type => ({
  value: NONE_VALUE,
  name: `No ${type.singular}`,
  Component: SelectOptionChecked
});
export const Divider = name => ({
  key: `divider-${name}`,
  Component: SelectOptionDivider
});