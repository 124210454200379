import "core-js/modules/es.array.push.js";
import { useCallback, useMemo, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
export const useQueue = () => {
  const queue = useRef([]);
  const flush = useCallback(() => {
    unstable_batchedUpdates(() => {
      queue.current.forEach(fn => fn());
      queue.current = [];
    });
  }, []);
  const enqueue = useCallback(fn => {
    queue.current.push(fn);
  }, []);
  return useMemo(() => ({
    enqueue,
    flush,
    queue
  }), [enqueue, flush]);
};