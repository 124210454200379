import ExampleImg from '@clubhouse/assets/png/screenshots/example-velocity-chart-v3.png';
import { capitalize } from '@clubhouse/shared/utils';
import { getCurrentPage, getSlugPath } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Description = _ref => {
  let {
    entity,
    showWorkingDaysData
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx("p", {
      children: "The Velocity Chart measures work completed for each interval."
    }), _jsxs("p", {
      children: ["The x-axis represents time.\xA0", entity === 'Reports' ? _jsx(_Fragment, {
        children: "The intervals can be changed by using the Group By filter at the top of the page. See Group By help for more details.\xA0"
      }) : _jsxs(_Fragment, {
        children: ["The Date Range of the axis is automatically set to encompass all data for the ", entity, " (and cannot be manually adjusted). The intervals can be changed by using the Group By filter within the chart.\xA0"]
      }), showWorkingDaysData && _jsxs(_Fragment, {
        children: ["When Group By is set to Day, non-working days will be highlighted on the chart. Working days can be adjusted in your ", _jsx("a", {
          href: `${getSlugPath()}/settings/workspace`,
          children: "Workspace Settings"
        }), "."]
      })]
    }), entity !== 'Reports' && _jsxs("ul", {
      children: [_jsxs("li", {
        children: ["The Date Range of the ", entity, " must be at least the length of the set Group By value."]
      }), _jsx("li", {
        children: "Group by Day: Only available for date ranges of 6 months or less."
      }), _jsx("li", {
        children: "Group by Week: Only Available for date ranges of 2 years of less."
      }), usesIterations() && _jsx("li", {
        children: "Group by Iteration: Only available for date ranges of 2 years or less. Only Iterations that start and end within the date range are included."
      })]
    }), _jsx("p", {
      children: "The y-axis can represent either points or Story count, and can be changed by using the Sum Using filter:"
    }), _jsxs("ul", {
      children: [_jsxs("li", {
        children: ["When using ", _jsx("strong", {
          children: "Story Count,"
        }), " the height of the bar represents total number of Stories completed in that interval."]
      }), _jsxs("li", {
        children: ["When using ", _jsx("strong", {
          children: "Story Points,"
        }), " the height of the bar represents the amount of completed points associated with completed stories in that Interval. Unestimated Stories, and Stories estimated at 0 points, are excluded from the calculation of Story Points."]
      })]
    }), _jsx("p", {
      children: "The bars are color coded to represent different Story types: Feature Stories (yellow), Bug Stories (red), and Chore Stories (blue)."
    }), _jsx("p", {
      children: "Hovering your mouse over a single bar will produce a pop up with a quantitative breakdown of the Story types, as well as the total number of Stories or Story Points completed within the interval."
    }), _jsxs("p", {
      children: ["The dashed green line shows you the overall average of velocity. This is the total number of points or stories across the entire date range, divided by the total number of", showWorkingDaysData && _jsx(_Fragment, {
        children: " working "
      }), "days in that span, based on user settings."]
    }), _jsx("p", {
      children: "The solid green line shows you the trailing average of velocity. The number of intervals used to calculate this changes depending on the Group By filter at the top of the page:"
    }), _jsxs("ul", {
      children: [usesIterations() && _jsxs("li", {
        children: [_jsx("strong", {
          children: "Iteration"
        }), " uses a 4 iteration trailing average, exclusive of current iteration."]
      }), _jsxs("li", {
        children: [_jsx("strong", {
          children: "Month"
        }), " uses a 3 month trailing average, exclusive of current month."]
      }), _jsxs("li", {
        children: [_jsx("strong", {
          children: "Week"
        }), " uses a 4 week trailing average, exclusive of current week."]
      }), _jsxs("li", {
        children: [_jsx("strong", {
          children: "Day"
        }), " uses a 7 ", showWorkingDaysData && _jsx(_Fragment, {
          children: "working "
        }), "day trailing average, exclusive of that day.\xA0", showWorkingDaysData && _jsx(_Fragment, {
          children: "The numerator of this calculation is the number of stories/points completed within the past 7 days; the denominator is the number of working days within the past 7 days."
        })]
      })]
    }), _jsx("p", {
      children: "Note: The trailing average uses data outside of the selected date range to show an accurate metric at the start of the graph. For example, if the chart is Grouped By Day, the first Trailing Average calculation uses Stories completed that day, and the previous 6 days (which are not on the chart)."
    })]
  });
};
export const Explanation = _ref2 => {
  let {
    isEmbedded = false
  } = _ref2;
  const entity = capitalize(getCurrentPage());
  return _jsx(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''} Velocity Chart`,
    imgSrc: ExampleImg,
    description: _jsx(Description, {
      entity: entity,
      showWorkingDaysData: true
    }),
    footer: _jsx(ReportsHelpFooter, {})
  });
};
Explanation.displayName = "Explanation";