import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CreatedVsCompletedReportCard = props => _jsx(ReportCard, {
  ...props,
  title: "Created vs. Completed",
  description: "Work created and completed per interval over time.",
  Explanation: Explanation,
  Filters: Filters
});
CreatedVsCompletedReportCard.displayName = "CreatedVsCompletedReportCard";