import * as ImportDataTemplate from 'app/client/settingsShared/views/templates/importData.html';
import { ImportDataPage } from 'components/importData/ImportDataPage';
import View from '../../../core/js/modules/view';
import Url from '../../../core/js/modules/url';
import Router from '../../../core/js/_frontloader/router';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import ProfileModel from '../../../core/js/models/profile';
import ImportModel from '../../../core/js/models/import';
import SyncModel from '../../../core/js/models/sync';
import ProjectModel from '../../../core/js/models/project';
import WorkflowModel from '../../../core/js/models/workflow';
const exports = {};
exports.title = () => 'Import / Sync';
exports.route = url => {
  if (_.startsWith(url, Url.getSlugPath() + '/settings/import')) {
    return url;
  }
  return `${Url.getSlugPath()}/settings/import`;
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.initData = () => {
  return Promise.all([ImportModel.Promises.get(), SyncModel.Promises.fetchAll()]);
};
const pushRouterState = importId => {
  Router.pushState(`${exports.route()}/${importId}`, exports.title());
};
const resetRouterState = () => {
  Router.pushState(exports.route(), exports.title());
};
exports.render = () => {
  const importModel = ImportModel.getFromUrl(Url.getCurrentPathname());
  if (importModel) View.unmountComponents({
    templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR)
  });
  View.renderWithComponents({
    template: ImportDataTemplate,
    templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR),
    templateData: {
      active: 'importData'
    },
    components: {
      importData: {
        component: ImportDataPage,
        props: {
          projects: ProjectModel.all(),
          workflows: WorkflowModel.all(),
          clubhouseUsers: ProfileModel.getAllActiveProfileDetails(),
          importModel,
          loadImportDetails: importId => {
            pushRouterState(importId);
            exports.render();
          },
          pushRouterState,
          resetRouterState
        }
      }
    }
  });
};
export { exports as default };