const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
export const getIsOverdue = deadline => {
  if (!deadline) return false;
  const deadlineDate = new Date(deadline);
  const now = new Date();
  const diff = deadlineDate.getTime() - now.getTime();
  const diffInDays = Math.floor(diff / ONE_DAY);
  return diffInDays < 0;
};
export const getIsDueSoon = deadline => {
  if (!deadline) return false;
  const deadlineDate = new Date(deadline);
  const now = new Date();
  const diff = deadlineDate.getTime() - now.getTime();
  const diffInDays = Math.floor(diff / ONE_DAY);
  return diffInDays < 5;
};