/*

Example credential entity

{
  created_at: "2017-07-26T11:06:02Z"
  entity_type: "credential"
  expires_in: 36000
  id: "5978779a-8c84-4c20-ad53-ec41910c4b29"
  installation_id: "5976e1d5-5d7a-4229-a39a-b17aa88f586f"
  member_id: "56d8a845-0c9e-47f8-8356-9635cf7030ec"
  token: "PNEUDYWGWJ46CGX6UUHRL227YJHKE3D2"
  updated_at: "2017-07-26T11:06:02Z"
}

*/

import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
import Constants from '../modules/constants';
const exports = {};
Collection.create('OAuth', exports);
exports.isValid = entity => {
  return !!(entity && entity.id && entity.installation_id && entity.expires_in);
};
exports.normalize = entity => {
  entity.formatted_expiry = moment().add(entity.expires_in, 's').format(Constants.SHORT_DATE_FORMAT);
};
exports.getByInstallationId = id => {
  return exports.get({
    installation_id: id
  });
};
exports.fetchAll = (callback, options) => {
  _fetchAll(callback, options);
};

// Wrapper for async functions to keep the callback as the final argument
exports.fetchAllByOrg = (orgId, callback) => {
  _fetchAll(callback, {
    company_id: _.get(CompanyModel.getFromOrg({
      id: orgId
    }), 'id'),
    organization_id: orgId
  });
};
exports.authorizeInstallation = (installationId, callback, options) => {
  Backend.post('/api/private/applications/authorize', _.assign({}, options, {
    data: {
      client_id: installationId
    },
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  }));
};
function _fetchAll(callback, options) {
  Backend.get('/api/private/credentials', _.assign({}, options, {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  }));
}
export { exports as default };