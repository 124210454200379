import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useMountedState } from 'react-use';
const defaultOptions = {
  trailing: true
};
export const useDebounce = function (fn, timeout) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;
  const isMounted = useMountedState();
  const debouncedFunction = useMemo(() => debounce(fn, timeout, options), [fn, options, timeout]);
  useEffect(() => debouncedFunction.cancel, [debouncedFunction]);
  const wrappedFn = useCallback(function () {
    if (isMounted()) {
      return debouncedFunction(...arguments);
    }
    return undefined;
  }, [debouncedFunction, isMounted]);
  return wrappedFn;
};