import { getTokens } from '@useshortcut/shapes-ds';
import { default as Color } from 'color';
import { COLORS } from './colors';
const tokens = getTokens('dark');
export const darkTokens = tokens;
const textColors = {
  brightTextColor: COLORS.WHITE,
  headerTextColor: COLORS.GRAY95,
  paragraphTextColor: tokens.color.semantic.text.main,
  lightFadedTextColor: COLORS.GRAY49,
  disabledBrightTextColor: Color(COLORS.WHITE).alpha(0.2).hsl().string(),
  fadedTextColor: tokens.color.semantic.text.secondary,
  slightyFadedTextColor: COLORS.GRAY93,
  fadedTextHoverColor: COLORS.GRAY80,
  extremelyFadedTextColor: tokens.color.semantic.text.secondary,
  successTextColor: COLORS.GREEN67,
  errorTextColor: COLORS.RED77,
  alertTextColor: COLORS.YELLOW45,
  purpleTextColor: COLORS.PURPLE75,
  emphasisTextColor: COLORS.GRAY47,
  anchorColor: tokens.color.semantic.interactive.main,
  anchorHoverColor: tokens.color.semantic.interactive.dark,
  anchorPurpleColor: COLORS.GRAY60,
  anchorPurpleHoverColor: COLORS.GRAY95,
  anchorLightBlueColor: `#a3c5eb`,
  anchorLightBlueHoverColor: Color('#a3c5eb').darken(0.15).hex(),
  whiteTextColor: COLORS.WHITE
};
const iconColors = {
  disabledIconColor: COLORS.GRAY27,
  iconFadedColor: COLORS.GRAY40,
  iconGrayColor: tokens.color.semantic.icon.main,
  iconGrayMediumColor: COLORS.GRAY60,
  iconGrayDarkColor: COLORS.GRAY70,
  iconGrayHoverColor: COLORS.GRAY80,
  iconBlueColor: tokens.color.semantic.primary.main,
  iconFadedBlue: COLORS.BLUE82,
  iconOrangeColor: COLORS.ORANGE47,
  iconRedColor: COLORS.RED50,
  iconGreenColor: tokens.color.semantic.success.main,
  iconPurpleColor: COLORS.PURPLE75,
  iconYellowColor: COLORS.YELLOW68,
  iconWhiteColor: COLORS.WHITE,
  alertIconColor: COLORS.YELLOW68,
  labelYellow: COLORS.YELLOW45,
  iconFlagColor: COLORS.PURPLE75,
  iconFlagOutlined: COLORS.PURPLE93,
  iconFlagCheckered: COLORS.GRAY87,
  iconFlagCheckeredDarkBackground: COLORS.GRAY87,
  iconFlagColorDarkBackground: COLORS.PURPLE75
};
const borderColors = {
  hoverBorderColor: tokens.color.raw.slate50,
  //replace with hover border token to be created in shapes
  mediumBorderColor: COLORS.GRAY87,
  strongerBorderColor: tokens.color.semantic.border.main,
  bitStrongerBorderColor: COLORS.GRAY40,
  borderColor: tokens.color.semantic.border.main,
  lightBorderColor: tokens.color.semantic.border.secondary,
  focusedElementBorderColor: tokens.color.semantic.border.focus,
  errorBorderColor: COLORS.RED77,
  borderHorizontalRule: COLORS.GRAY50
};
const backgroundColors = {
  pageBackground: tokens.color.semantic.background.canvas,
  detailsPageBlockBackground: tokens.color.semantic.background.content,
  blockBackground: tokens.color.semantic.background.tertiary,
  // content blocks on dashboard, status, epics, etc.
  blockHoverBackground: tokens.color.semantic.background.tertiary,
  accentBlueBackground: COLORS.BLUE93,
  accentBlueBackgroundBorder: Color(COLORS.BLUE93).darken(0.03).hex(),
  bluishBlockBackground: tokens.color.semantic.background.tertiary,
  // milestone/iteration unstarted backgrounds
  greenishBlockBackground: tokens.color.semantic.background.tertiary,
  // milestone/iteration completed backgrounds
  infoBackground: COLORS.GRAY20,
  errorBackground: COLORS.RED77,
  highlightBackground: COLORS.YELLOW92
};
const dropdown = {
  dropdownBackground: tokens.color.semantic.background.elevated,
  dropdownSecondaryContentBackground: COLORS.GRAY33,
  dropdownDisabledItemColor: Color.rgb(0, 0, 0).alpha(0.12).hsl().string()
};
const storiesPage = {
  storiesPageBackground: COLORS.GRAY13,
  storiesPageSidebarBackground: Color(COLORS.GRAY7).lighten(0.04).hex(),
  newStoriesPageBackground: COLORS.GRAY95,
  newStoriesPageSidebarBackground: COLORS.GRAY95,
  storiesPageSidebarHoverBackground: COLORS.GRAY13,
  storiesPageMobileSidebarBackground: COLORS.GRAY13,
  columnStoryCountBackgroundColor: tokens.color.semantic.background.content,
  completedColumnDateBackgroundColor: tokens.color.semantic.background.tertiary,
  completedColumnDateTextColor: tokens.color.semantic.text.main,
  storiesPageSidebarHeaderHoverBackgroundColor: COLORS.GRAY27,
  storiesPageSidebarListItemTextColor: COLORS.GRAY70,
  storiesPageSidebarListItemHoverBackgroundColor: COLORS.GRAY27,
  storiesPageSidebarListItemHoverTextColor: COLORS.GRAY95,
  storiesPageSidebarActiveSpaceTextColor: COLORS.GRAY95,
  storiesPageSidebarActiveFilterBackgroundColor: COLORS.PURPLE29,
  storiesPageScrollbar: tokens.color.raw.slate90,
  storiesPageScrollbarThumb: tokens.color.raw.slate70,
  storiesPageColumnBucketScrollbarThumb: tokens.color.semantic.background.canvas,
  storiesPageColumnBucketScrollbarThumbHover: tokens.color.raw.slate70,
  storiesPageColumnBucketScrollbarThumbBorder: tokens.color.semantic.background.canvas
};
const pageLayout = {
  blockQuoteBackgroundColor: COLORS.YELLOW92,
  searchBackgroundColor: tokens.color.semantic.background.tertiary,
  searchActiveBackgroundColor: tokens.color.semantic.background.content,
  topNavBackgroundColor: Color(COLORS.GRAY7).darken(0.03).hex(),
  topNavLogoBackgroundColor: null,
  topNavLinkBackgroundColor: COLORS.GRAY13,
  topNavLinkHoverBackgroundColor: COLORS.GRAY27,
  topNavBorderColor: COLORS.GRAY33,
  leftNavActiveBackgroundColor: tokens.color.semantic.navigation.interactive,
  navBackgroundColor: tokens.color.semantic.navigation.background,
  navTrialBackgroundColor: COLORS.BLACK,
  navLinkColor: Color(COLORS.WHITE).alpha(0.3).hsl().string(),
  navLinkIconColor: Color(COLORS.WHITE).alpha(0.6).hsl().string(),
  navBadgeBackgroundColor: `#ff5555`,
  headerLinkColor: Color(COLORS.WHITE).alpha(0.6).hsl().string(),
  profilePopoverBackground: tokens.color.semantic.background.elevated
};
const progressSteps = {
  progressStepBackgroundColor: tokens.color.semantic.background.canvas,
  progressStepFinishedIconColor: COLORS.WHITE,
  progressStepFinishedIconBackgroundColor: COLORS.DUSTYBLUE62,
  progressStepTextColor: COLORS.WHITE
};
const notifications = {
  notificationCompletedBackgroundColorPulse: COLORS.GRAY33
};
const inline = {
  inlineValidationErrorBackground: COLORS.RED23,
  inlineValidationErrorBoxShadowColor: COLORS.RED23,
  inlineValidationErrorTextColor: COLORS.RED95,
  inlineValidationErrorPlainTextColor: COLORS.RED77
};
const progressBar = {
  progressBarBackground: tokens.color.raw.slate40,
  progressBarLabelColor: COLORS.GREEN67,
  progressBarCompletedColor: tokens.color.raw.green80,
  progressBarStartedColor: tokens.color.raw.green60,
  progressBarUnstartedColor: tokens.color.raw.slate40,
  progressBarArchivedColor: tokens.color.raw.slate50
};
const forms = {
  formFieldBackgroundColor: tokens.color.semantic.background.elevated,
  formFieldDisabledBackgroundColor: tokens.color.semantic.background.tertiary,
  formFieldNoteBackgroundColor: tokens.color.semantic.background.elevated,
  formFieldReadOnlyBackgroundColor: tokens.color.semantic.background.tertiary
};
const entityForm = {
  createEditorRightSideBackgroundColor: Color(tokens.color.semantic.background.tertiary).darken(0.03).hex(),
  createEditorLeftSideBackgroundColor: tokens.color.semantic.background.tertiary
};
const messages = {
  messageIconColor: Color.rgb(255, 255, 255).alpha(0.8).hsl().string(),
  formHelpMessageBackground: Color(COLORS.WHITE).alpha(0).hsl().string(),
  formHelpMessageBorder: tokens.color.semantic.primary.main,
  //1px solid
  formHelpSuccessColor: Color(COLORS.GREEN67).alpha(0.8).hsl().string(),
  formHelpSuccessBackgroundColor: Color(COLORS.GREEN67).alpha(0.2).hsl().string(),
  formHelpSuccessBorder: COLORS.GREEN67,
  //1px solid
  formHelpWarningBackgroundColor: Color(COLORS.WHITE).alpha(0).hsl().string(),
  formHelpWarningBorder: COLORS.YELLOW45,
  //1px solid
  formHelpPraiseBackground: Color(COLORS.WHITE).alpha(0).hsl().string(),
  formHelpPraiseBorder: COLORS.RED77,
  // 1px solid
  formHelpReferralBackground: Color(COLORS.WHITE).alpha(0).hsl().string(),
  formHelpReferralBorder: COLORS.PURPLE47,
  //1px solid
  formHelpTrainingBackground: Color(COLORS.WHITE).alpha(0).hsl().string(),
  formHelpTrainingBorder: COLORS.GREEN88 //1px solid
};
const buttons = {
  brightButtonTextColor: COLORS.GRAY7,
  activeButtonTextColor: COLORS.WHITE,
  greenButtonBackgroundColor: COLORS.GREEN38,
  lightGrayButtonBackgroundColor: tokens.color.semantic.background.tertiary,
  lightGrayButtonTextColor: COLORS.GRAY87,
  lightGrayButtonHoverBackgroundColor: tokens.color.semantic.background.canvas,
  lightGrayButtonHoverTextColor: COLORS.GRAY95,
  lightGrayButtonActiveBackgroundColor: tokens.color.semantic.background.tertiary,
  whiteButtonBackgroundColor: tokens.color.semantic.background.elevated,
  flatWhiteButtonHoverBackgroundColor: tokens.color.semantic.interactive.hover,
  flatButtonActiveBackgroundColor: tokens.color.semantic.background.tertiary,
  borderlessButtonBackgroundColor: tokens.color.semantic.background.elevated,
  disabledButtonBackgroundColor: tokens.color.semantic.background.tertiary,
  brightWhiteButtonBackgroundColor: tokens.color.semantic.background.content,
  brightWhiteButtonHoverBackgroundColor: COLORS.GRAY60,
  googleLoginBackground: tokens.color.semantic.background.tertiary,
  googleLoginHoverBackground: tokens.color.semantic.interactive.hover,
  googleLoginActiveBackground: COLORS.GRAY20,
  blueButtonBackground: COLORS.BLUE39,
  dustyBlueButtonBackground: COLORS.DUSTYBLUE51,
  dustyBlueButtonHoverBackground: COLORS.DUSTYBLUE41,
  grayBlueButtonColor: COLORS.BLUE39,
  grayBlueButtonBackground: COLORS.BLUE88,
  grayBlueButtonHoverBackground: Color(COLORS.BLUE88).darken(0.05).hex(),
  grayBlueButtonActiveBackground: Color(COLORS.BLUE88).darken(0.1).hex(),
  lightBlueButtonBackground: COLORS.BLUE39,
  lightBlueButtonHoverBackground: Color(COLORS.BLUE39).lighten(0.05).hex(),
  lightBlueButtonActiveBackground: Color(COLORS.BLUE39).darken(0.1).hex(),
  redButtonBackground: COLORS.RED33,
  redButtonHoverBackground: COLORS.RED50,
  redButtonActiveBackground: COLORS.RED23,
  toggleButtonGutterBackground: COLORS.GRAY40,
  toggleButtonGutterActiveBackground: COLORS.GREEN38
};
const scrollBars = {
  scrollbarColor: tokens.color.raw.slate90,
  scrollbarThumbColor: tokens.color.raw.slate60,
  scrollbarThumbColorElevated: tokens.color.raw.slate40,
  scrollbarThumbHoverColor: tokens.color.raw.slate50
};
const signup = {
  collaborateIconEnabledColor: COLORS.EMERALDGREEN60,
  collaborateIconDisabledColor: COLORS.WHITE
};
const stories = {
  storyAutoLinkBackgroundColor: tokens.color.semantic.background.tertiary,
  storyAutoLinkArchivedBackgroundColor: tokens.color.raw.slate90,
  storyAutoLinkFeatureTextColor: tokens.color.raw.yellow20,
  storyAutoLinkFeatureBackgroundColor: tokens.color.raw.slate90,
  storyAutoLinkChoreTextColor: tokens.color.raw.slate20,
  storyAutoLinkChoreBackgroundColor: tokens.color.raw.slate90,
  storyAutoLinkBugTextColor: tokens.color.raw.red40,
  storyAutoLinkBugBackgroundColor: tokens.color.raw.slate90,
  storyAutoLinkCompleteColor: tokens.color.semantic.success.main,
  storyTextColor: COLORS.GRAY95,
  storyDialogSidebarBackground: tokens.color.semantic.background.tertiary,
  storyDialogArchiveBannerBackground: COLORS.GRAY33,
  storyBadgeBackgroundColor: Color(COLORS.WHITE).alpha(0.05).hsl().string(),
  storyBadgeAnnotationBackgroundColor: Color(COLORS.WHITE).alpha(0.1).hsl().string(),
  projectBadgeBackground: COLORS.GRAY33,
  projectBadgeBorder: COLORS.GRAY47,
  projectBadgeText: COLORS.WHITE,
  commentBoxBackgroundColor: tokens.color.semantic.background.tertiary,
  commentBoxBorder: null,
  commentBoxGhostTextColor: COLORS.GRAY60,
  highlightedAnchorBackgroundColor: tokens.color.semantic.background.tertiary,
  storyAttributeHoverBackgroundColor: tokens.color.semantic.background.elevated,
  // storyBorderLeftWidth: 2px;

  storyEntityTitleLinkColor: COLORS.GRAY70,
  storyEntityTitleLinkHoverColor: COLORS.GRAY80,
  blockerBackgroundColor: Color(COLORS.YELLOW45).alpha(0.1).hsl().string(),
  storyBackground: tokens.color.semantic.background.tertiary,
  storyHover: tokens.color.semantic.interactive.hover
};
const miniKanbanGrid = {
  miniKanbanColor: COLORS.GRAY27,
  miniKanbanHoverColor: COLORS.GRAY33
};
const tabs = {
  tabBackgroundColor: tokens.color.semantic.background.tertiary,
  tabHoverBackgroundColor: tokens.color.semantic.interactive.hoverElevated,
  tabActiveBackgroundColor: tokens.color.semantic.background.transient,
  tabCountBackgroundColor: COLORS.GRAY27,
  tabActiveHoverBackgroundColor: tokens.color.semantic.interactive.selectedElevated
};
const tags = {
  tagBackgroundColor: tokens.color.raw.slate90,
  tagTextColor: COLORS.GRAY80
};
const tables = {
  tableCellBackgroundColor: COLORS.GRAY13,
  tableCellHoverBackgroundColor: Color(COLORS.GRAY13).lighten(0.04).hex(),
  tableCellHeaderBackgroundColor: tokens.color.semantic.background.transient,
  tableCellHeaderHoverBackgroundColor: COLORS.GRAY40,
  tableCellActiveHeaderBackgroundColor: COLORS.GRAY60,
  tableCellActiveHoverHeaderBackgroundColor: COLORS.GRAY70,
  tableCellActiveBackgroundColor: COLORS.GRAY20,
  tableCellSelectedBackgroundColor: COLORS.GRAY27,
  tableCellSelectedActiveBackgroundColor: Color(COLORS.GRAY27).lighten(0.04).hex(),
  tableCellSelectedHoverBackgroundColor: Color(COLORS.GRAY27).lighten(0.04).hex(),
  tableCellSelectedActiveHoverBackgroundColor: COLORS.GRAY33,
  tableCellPlaceholderBackgroundColor: COLORS.GRAY7 // placeholder during drag-and-drop
};
const listView = {
  listViewItemBackgroundHover: COLORS.GRAY20,
  listViewArchivedItemBackground: Color(COLORS.WHITE).alpha(0).hsl().string()
};
const modalDialog = {
  modalDialogOverlayBackground: Color.rgb(0, 0, 0).alpha(0.75).hsl().string(),
  modalDialogBackgroundColor: tokens.color.semantic.background.tertiary,
  modalDialogContentBackgroundAccent: COLORS.GRAY33
};
const overlay = {
  overlayText: COLORS.WHITE,
  overlayHover: Color.rgb(0, 0, 0).alpha(0.5).hsl().string()
};
const multiselect = {
  multiSelectDropdownSelectedItemBackground: COLORS.GREEN38,
  multiSelectDropdownSelectedItemBackgroundActive: COLORS.GREEN23,
  multiSelectDropdownSelectedItemColor: COLORS.WHITE
};
const search = {
  navSearchActiveTextColor: COLORS.PURPLE75,
  navSearchResultCountBackground: COLORS.GRAY33
};
const epics = {
  epicGuidanceBackgroundColor: COLORS.GRAY33
};
const datepicker = {
  datepickerPaginationBackgroundColor: tokens.color.raw.slate90,
  datepickerPaginationHoverBackgroundColor: tokens.color.raw.slate70,
  datepickerCellBackgroundColor: tokens.color.raw.slate60
};
const tooltip = {
  tooltipFadedContentsColor: COLORS.GRAY93,
  tooltipContentsColor: COLORS.GRAY87,
  tooltipBrightText: COLORS.WHITE
};
const textDiffdialog = {
  textDiffDelBackground: COLORS.GRAY20,
  textDiffDelBorder: COLORS.RED33,
  textDiffDelSpanBackground: Color(COLORS.RED33).alpha(0.4).hsl().string(),
  textDiffDelSpanText: COLORS.RED95,
  textDiffInsBackground: COLORS.GRAY20,
  textDiffInsBorder: COLORS.GREEN38,
  textDiffInsSpanBackground: Color(COLORS.GREEN38).alpha(0.25).hsl().string(),
  textDiffInsSpanText: COLORS.GREEN97
};
const avatars = {
  avatarInitials: null,
  avatarBackground: tokens.color.semantic.background.tertiary,
  avatarRed33: Color(COLORS.RED33).alpha(0.7).hsl().string(),
  avatarOrange47: Color(COLORS.ORANGE47).alpha(0.7).hsl().string(),
  avatarYellow45: Color(COLORS.YELLOW45).alpha(0.7).hsl().string(),
  avatarGreen23: Color(COLORS.GREEN23).alpha(0.7).hsl().string(),
  avatarGreen38: Color(COLORS.GREEN38).alpha(0.7).hsl().string(),
  avatarBlue39: Color(COLORS.BLUE39).alpha(0.7).hsl().string(),
  avatarBlue64: Color(COLORS.BLUE64).alpha(0.7).hsl().string(),
  avatarPurple47: Color(COLORS.PURPLE47).alpha(0.7).hsl().string(),
  avatarPurple75: Color(COLORS.PURPLE75).alpha(0.7).hsl().string(),
  avatarGray50: Color(COLORS.GRAY50).alpha(0.7).hsl().string(),
  avatarGray7: Color(COLORS.GRAY7).alpha(0.7).hsl().string()
};
const loginPage = {
  loginPageBackground: tokens.color.semantic.background.canvas
};
const shimmer = {
  shimmerBackgroundOne: COLORS.GRAY20,
  shimmerBackgroundTwo: COLORS.GRAY13
};
const addReactionButtons = {
  addReactionBackgroundColor: tokens.color.semantic.background.elevated,
  addReactionHoverBackgroundColor: tokens.color.semantic.background.tertiary
};
const images = {
  invertedImageFilter: null //invert(1) grayscale(1);
};
const reports = {
  unestimatedStoriesWarning: COLORS.PURPLE29,
  reportFeature: tokens.color.raw.yellow80,
  reportBug: tokens.color.raw.red70,
  reportChore: tokens.color.raw.blue60
};
const tagged = {
  taggedTextColor: COLORS.GRAY95,
  taggedBackground: COLORS.GRAY33,
  taggedBlue: Color(COLORS.BLUE64).alpha(0.6).hsl().string(),
  taggedGreen: Color(COLORS.GREEN38).alpha(0.6).hsl().string(),
  taggedOrange: Color(COLORS.ORANGE47).alpha(0.6).hsl().string(),
  taggedGray: COLORS.GRAY33,
  taggedLightGray: Color(COLORS.GRAY80).alpha(0.6).hsl().string(),
  taggedPurple: Color(COLORS.PURPLE29).alpha(0.6).hsl().string(),
  taggedLightPurple: Color(COLORS.PURPLE75).alpha(0.6).hsl().string(),
  taggedPurpleDefault: Color(COLORS.PURPLE47).alpha(0.6).hsl().string(),
  taggedDarkDirtyPurple: Color('#48485C').alpha(0.6).hsl().string()
};
const badges = {
  mentionNameBadgeBackground: COLORS.GREEN30,
  mentionNameBadgeColor: COLORS.GREEN67
};
const write = {
  writeBackgroundColor: tokens.color.semantic.background.canvas,
  writeBorderColor: tokens.color.semantic.border.inverted,
  writeMediumBorderColor: COLORS.GRAY27,
  writeStrongBorderColor: tokens.color.semantic.border.inverted,
  writeHeading: tokens.color.semantic.text.main,
  writeSubText: COLORS.GRAY60,
  writePurpleSubText: Color.rgb(255, 255, 255).alpha(0.7).hsl().string(),
  writeDocSidebarBackground: COLORS.GRAY13,
  writeActiveColor: null,
  writeActiveBackgroundColor: null,
  writeActiveTextColor: null,
  writePrimaryIconFill: null,
  writeSecondaryIconFill: null,
  writeGrayInfoBackgroundColor: tokens.color.semantic.background.tertiary,
  writeCaptionBackgroundColor: 'transparent',
  writeCaptionTextColor: textColors.fadedTextColor,
  writeCommentBackgroundColor: Color(tokens.color.semantic.background.tertiary).darken(0.2).hex(),
  writeCommentRemoveBackgroundColor: COLORS.GRAY13,
  writeCommentMetaData: COLORS.GRAY65,
  writeCommentHighlightBackground: Color(COLORS.GRAY13).darken(0.2).hex(),
  writeCommentHighlightBackgroundHover: Color(COLORS.YELLOW45).darken(0.6).hex(),
  writeCommentHighlightBackgroundActive: Color(COLORS.YELLOW45).darken(0.4).hex(),
  writeCommentHighlightBorder: COLORS.YELLOW45,
  writeCommentHighlightBorderActive: COLORS.YELLOW45,
  writeCommentHighlightText: textColors.paragraphTextColor,
  writeCommentHighlightTextActive: textColors.paragraphTextColor,
  writeCommentActiveBorder: COLORS.GRAY33,
  writeCommentActiveSideBorder: COLORS.YELLOW45,
  writeGrayButtonBackgroundColor: buttons.lightGrayButtonBackgroundColor,
  writeGrayButtonTextColor: COLORS.WHITE,
  writePurpleButtonBackgroundColor: COLORS.PURPLE47,
  writePurpleButtonTextColor: COLORS.WHITE,
  writeBuildLinkTextColor: COLORS.PURPLE47,
  writeAvatarBackground: COLORS.GRAY13,
  writeGreen: COLORS.GREEN23,
  writeDelete: COLORS.RED50,
  writeCopyUrlColor: COLORS.WHITE
};
const registration = {
  registrationPrimaryTextColor: COLORS.WHITE,
  registrationSecondaryTextColor: COLORS.GRAY70,
  registrationPrimaryBackground: COLORS.BLACK,
  registrationSecondaryBackground: COLORS.GRAY13
};
export const DARK = {
  ...datepicker,
  ...overlay,
  ...dropdown,
  ...search,
  ...epics,
  ...textColors,
  ...iconColors,
  ...modalDialog,
  ...signup,
  ...listView,
  ...borderColors,
  ...backgroundColors,
  ...pageLayout,
  ...notifications,
  ...progressSteps,
  ...inline,
  ...progressBar,
  ...forms,
  ...entityForm,
  ...messages,
  ...buttons,
  ...scrollBars,
  ...tabs,
  ...miniKanbanGrid,
  ...avatars,
  ...tagged,
  ...images,
  ...badges,
  ...write,
  ...reports,
  ...addReactionButtons,
  ...textDiffdialog,
  ...tooltip,
  ...loginPage,
  ...shimmer,
  ...multiselect,
  ...tables,
  ...tags,
  ...stories,
  ...storiesPage,
  ...registration
};