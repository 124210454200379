import { ThemeProvider } from '@emotion/react';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { LOCAL_THEME } from '../theme';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DatePickerThemeProvider = _ref => {
  let {
    children
  } = _ref;
  const themeName = useThemeName();
  return _jsx(ThemeProvider, {
    theme: LOCAL_THEME[themeName],
    children: children
  });
};
DatePickerThemeProvider.displayName = "DatePickerThemeProvider";