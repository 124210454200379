import { CopyEpicLinkActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { copyToClipboard } from '@clubhouse/shared/utils';
import { getHref } from 'components/gql/epics/links';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CopyEpicLinkActionFragment = CopyEpicLinkActionFragmentFragmentDoc;
export const CopyEpicLinkAction = _ref => {
  let {
    epic
  } = _ref;
  const {
    addToast
  } = useToast();
  return _jsx(MoreActions.Item, {
    icon: "Link",
    onClick: async () => {
      const success = await copyToClipboard(getHref(epic.publicId, true));
      if (success) addToast({
        Content: () => _jsx(ToastText, {
          children: "Link Copied!"
        }),
        timeout: 2000
      });
    },
    children: "Copy Link"
  });
};
CopyEpicLinkAction.displayName = "CopyEpicLinkAction";