import "core-js/modules/es.array.push.js";
import ApplicationState from '../modules/applicationState';
import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import LocalStorage from '../modules/localStorage';
import Log from '../modules/log';
import PermissionModel from './permission';
import ProfileModel from './profile';
import StoryLookupController from '../controllers/storyLookup';
import StoryModel from './story';
import Utils from '../modules/utils';
import Format from '../modules/format';
const exports = {};

/*

Example Activity Object:

{
  data: [
    {
      actions: [
        {
          action: "create"
          author_id: "56d8a845-0c9e-47f8-8356-9635cf7030ec"
          entity_type: "story-comment"
          id: 22682
          mention_ids: [
            "56d8a82b-b408-4803-9e0c-ceb26d900a62"
          ]
          text: "@ac no problem"
        }...
      ]
      changed_at: "2017-07-26T09:37:49.341Z"
      id: "597862ed-483a-4bec-a8d5-1eb7d5fc41d3"
      member_id: "56d8a845-0c9e-47f8-8356-9635cf7030ec"
      primary_id: 22682
      version: "beta"
    }...
  ]
  end: "2017-07-31T11:01:25.999Z"
  start: "2017-07-24T11:01:25.999Z"
}

*/

const WHITELISTS = {
  ACTIVITY_FEED: {
    story: {
      create: true,
      delete: false,
      update: ['archived', 'blocked', 'comment_ids', 'custom_field_value_ids', 'deadline', 'description', 'epic_id', 'estimate', 'file_ids', 'group_id', 'iteration_id', 'name', 'owner_ids', 'project_id', 'task_ids', 'workflow_state_id']
    },
    'story-link': {
      create: true,
      delete: true
    },
    'story-task': {
      create: false,
      delete: false,
      update: ['complete', 'description', 'owner_ids']
    },
    'story-comment': {
      create: true,
      delete: false,
      update: ['id', 'story_id', 'text']
    },
    epic: {
      create: true,
      delete: true,
      update: ['name', 'description', 'archived', 'epic_state_id', 'state',
      // Temporary to cover older transactions before epic_state_id
      'comment_ids', 'requested_by_id', 'group_id']
    },
    'epic-comment': {
      create: true,
      delete: false,
      update: ['id', 'epic_id', 'text']
    },
    health: {
      create: true,
      delete: false,
      update: ['status', 'text']
    },
    iteration: {
      update: ['group_ids']
    },
    group: {
      update: ['group_member_ids']
    },
    reaction: {
      create: true
    },
    workspace2: {
      'join-via-domain': true
    }
  }
};
const PREVIOUS_DAYS_ACTIVITY = 2;
const DEFAULT_PAGE_SIZE = 20;
Collection.create('Activity', exports);
exports.LIMIT = '2016-01-01';
exports.on('beforeAdd.collection beforeUpdate.collection', change => {
  exports.fetchStoriesInActivity(change);
  exports.normalize(change);
});

// START: Bridges while in transition
exports.getChanges = (change, action) => action.changes;
exports.getPropDiff = function (change, prop) {
  const action = _.find(change.actions, {
    id: Utils.data(this, 'action-id')
  });
  return action.changes ? action.changes[prop] : action[prop];
};
// END: Bridges while in transition

exports.fetchStoriesInActivity = change => {
  const ids = _.reduce(change.actions, (ids, action) => {
    if (exports.isStory({
      actions: [action]
    })) ids.push(action.id);
    if (exports.isStoryLink({
      actions: [action]
    })) ids.push(action.object_id, action.subject_id);
    return ids;
  }, []);
  ids.forEach(id => {
    if (id && !StoryModel.getById(id)) StoryLookupController.add(id);
  });
};
exports.normalize = change => {
  const changed = moment(change.changed_at);
  change.timestamp = changed.valueOf();
  change.day = changed.format('YYYYMMDD');
  change.isComment = exports.isComment(change);
  change.isMention = exports.isMention(change);
  change.userJoined = exports.userJoined(change);
  change.isImport = exports.isImport(change);
  change.isGroupMention = exports.isGroupMention(change);
  _normalizeComment(change);
};
const _normalizeComment = change => {
  if (exports.isComment(change)) {
    change.actions.forEach(action => {
      if (action.emoji) {
        action.formattedEmoji = Format.sanitizeAndEmojify(action.emoji);
      }
    });
  }
};
exports.isValid = obj => Utils.hasKeys(obj, ['id', 'actions']);
exports.getUnfilteredData = () => exports.sortBy('timestamp').reverse();
exports.getReference = (change, id, model) => {
  const filter = {
    id
  };
  model = model || exports;
  // Request a fresh entity in case we've filtered out actions that contain a required entity
  change = model.getById(change.id) || change;
  return _.find(change.references, filter) || _.find(change.actions, filter) || {};
};
exports.actionIsWhitelisted = (whitelist, action, change) => {
  const ref = exports.getReference(change, action.id);
  const type = action.action;
  const entity = (ref.entity_type ? whitelist[ref.entity_type] : whitelist.story) || {};
  return type === 'update' ? exports._includesWhitelistedUpdate(entity.update, exports.getChanges(change, action)) : entity[type];
};
exports._includesWhitelistedUpdate = (updateWhitelist, changes) => !!_.intersection(_.keys(changes), updateWhitelist).length;
exports.filterByWhitelist = whitelist => {
  return _.sortBy(_.map(exports.all(), change => {
    const actions = _.filter(change.actions, action => exports.actionIsWhitelisted(whitelist, action, change));
    return _.assign({}, change, {
      actions
    });
  }), 'timestamp');
};
exports.getActivityFeedData = () => exports.filterByWhitelist(WHITELISTS.ACTIVITY_FEED).reverse();
exports.getTimeRange = () => {
  const today = moment();
  const todayFormatted = today.format('YYYYMMDD');
  const oldest = moment(exports.getOldestDateChecked());
  const oldestFormatted = oldest.format('YYYYMMDD');
  const yesterdayFormatted = today.clone().subtract(1, 'day').format('YYYYMMDD');
  let timeRange = [{
    name: 'Today',
    day: todayFormatted
  }];
  function addToRange(d) {
    const todayFormatted = d.format('YYYYMMDD');
    timeRange.push({
      name: todayFormatted === yesterdayFormatted ? 'Yesterday' : d.format('dddd, MMM D'),
      day: todayFormatted
    });
  }
  if (oldestFormatted !== todayFormatted) {
    today.subtract(1, 'day');
    while (moment(today).isAfter(oldest, 'day') || today.format('YYYYMMDD') === oldestFormatted) {
      addToRange(today);
      today.subtract(1, 'day');
    }
  }
  timeRange = _.sortBy(timeRange, 'day').reverse();
  Log.debug('ActivityFeed: Setting timeRange: ' + _.last(timeRange).name + ' - ' + _.head(timeRange).name);
  return timeRange;
};

// Fetching forward -->
exports.fetchSinceLastCheck = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const newest = exports.getLatestDateChecked();
  if (newest && moment(newest).isBefore(new Date())) _fetch({
    after: exports.capDate(newest)
  }, callback);else callback();
};

// <-- Fetching backward
exports.fetchMoreActivity = callback => {
  _fetch({
    before: exports.capDate(exports.getOldestDateChecked())
  }, callback);
};
exports.fetchLastDay = callback => {
  // Not sending a `before` or an `after` is like setting `before` to `now`.
  _fetch({}, callback);
};
exports.capDate = date => moment(date).isBefore(exports.LIMIT) ? exports.LIMIT : date;
function _fetch(data, callback) {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (data.before === exports.LIMIT) {
    callback({
      limit: true
    });
    return;
  }
  const paginationQuery = '';
  Backend.post('/api/private/permission/activity' + paginationQuery, {
    data,
    onComplete: res => {
      if (!res) return callback({
        noResults: true
      });
      const activityStart = res.start;
      const activityEnd = res.end;
      if (activityEnd && exports._isNewerThanNewestDateChecked(activityEnd)) {
        exports.setLatestDateChecked(activityEnd);

        // res.end is newer, so we can safely request again here.
        if (data.after && res.data && res.data.length === DEFAULT_PAGE_SIZE) {
          exports.fetchSinceLastCheck(callback);
        }
      }
      if (activityStart && exports._isOlderThanOldestDateChecked(activityStart)) {
        exports.setOldestDateChecked(activityStart);
      }
      exports._onActivityFeedFetch(res, callback);
    }
  });
}
exports._onActivityFeedFetch = (res, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const data = _.get(res, 'data', []);
  data.forEach(exports.updateIfValid);
  exports._triggerFetchEvents();
  if (data.length) callback();else callback({
    noResults: true
  });
};
exports._triggerFetchEvents = () => {
  const timestamp = exports.getViewedActivityTimestamp();
  const newActivity = exports.getNewActivity(timestamp);
  const newComments = _.filter(newActivity, 'isComment');
  const newMentions = _.filter(newActivity, 'isMention');
  const importUpdates = _.filter(newActivity, 'isImport');
  Event.trigger('activityFetched');
  if (newActivity.length === 0) {
    Event.trigger('noNewActivity');
  } else {
    Event.trigger('newActivityFetched', newActivity);
    if (newComments.length > 0) Event.trigger('newComments', newComments);
    if (newMentions.length > 0) Event.trigger('newMentions', newMentions);
    if (importUpdates.length > 0) Event.trigger('importUpdates', importUpdates);
  }
};
exports.getLatestDateChecked = () => Globals.get('activityFeedLatestDateChecked');
exports.setLatestDateChecked = dateString => {
  Globals.set('activityFeedLatestDateChecked', dateString);
};
exports._isNewerThanNewestDateChecked = dateString => {
  const newest = exports.getLatestDateChecked();
  if (!newest) return true;
  return moment(dateString).valueOf() > moment(newest).valueOf();
};
exports._isOlderThanOldestDateChecked = dateString => {
  const oldest = exports.getOldestDateChecked();
  if (!oldest) return true;
  return moment(dateString).valueOf() < moment(oldest).valueOf();
};
exports.setOldestDateChecked = dateString => {
  Globals.set('activityFeedOldestDateChecked', dateString);
};
exports.getOldestDateChecked = () => {
  return Globals.get('activityFeedOldestDateChecked') || moment().format();
};
exports.updateLastViewedMention = () => {
  ApplicationState.set('lastViewedMention', Date.now());
};
exports.getLastViewedMention = () => ApplicationState.get('lastViewedMention') || 0;
exports.alreadyViewedMentionNotification = mention => {
  const lastViewedMention = exports.getLastViewedMention();
  const mentionDate = moment(mention.changed_at).valueOf();
  return lastViewedMention > mentionDate;
};
exports.getViewedImport = (importId, status) => ApplicationState.get(`ViewedImport.${importId}.${status}`) || false;
exports.updateViewedImport = (importId, status) => ApplicationState.set(`ViewedImport.${importId}.${status}`, true);
exports.updateViewedActivityTimestamp = () => {
  LocalStorage.set('viewedActivityTimestamp', Date.now(), {
    prefix: true
  });
};
exports.getViewedActivityTimestamp = () => {
  const storedValue = LocalStorage.get('viewedActivityTimestamp', {
    prefix: true
  });
  if (!storedValue) exports.updateViewedActivityTimestamp();
  return storedValue ? BaseUtils.toNumber(storedValue) : Date.now();
};
exports.getNewActivity = lastViewedActivityTimestamp => exports.filter(activity => activity.timestamp > lastViewedActivityTimestamp).reverse();
function _includesEntityTypes(change, types) {
  types = _.isArray(types) ? types : [types];
  return _.some(types, _.includes.bind(this, _.map(change.actions, 'entity_type')));
}
function _containsMentionId(change, id) {
  if (!change || !id) return false;
  const topLevelMentions = _.compact(_.map(change.actions, 'mention_ids'));
  const additionalMentions = _.compact(_.map(change.actions, 'changes.mention_ids.adds'));
  return _.includes(_.flatten(topLevelMentions.concat(additionalMentions)), id);
}
function _containsGroupMentionIds(change, group_ids) {
  if (!change || !group_ids) return false;
  const topLevelMentions = _.compact(_.map(change.actions, 'group_mention_ids'));
  const additionalMentions = _.compact(_.map(change.actions, 'changes.group_mention_ids.adds'));
  return !_.isEmpty(_.intersection(_.flatten(topLevelMentions.concat(additionalMentions)), group_ids));
}
function _isCommentMention(change, profile) {
  return exports.isComment(change) && _containsMentionId(change, profile?.id);
}
exports.isHealthMention = (change, profile) => exports.isHealth(change) && _containsMentionId(change, profile?.id);
function _isCommentGroupMention(change, profile) {
  return exports.isComment(change) && _containsGroupMentionIds(change, profile?.group_ids);
}
function _isTaskMention(change, profile) {
  return exports.isTask(change) && _containsMentionId(change, profile?.id);
}
function _isTaskGroupMention(change, profile) {
  return exports.isTask(change) && _containsGroupMentionIds(change, profile?.group_ids);
}
exports.isEpicMention = (change, profile) => {
  profile = profile || ProfileModel.getCurrentUserProfileDetails();
  return exports.isEpic(change) && _containsMentionId(change, profile?.id);
};
exports.isEpicGroupMention = (change, profile) => {
  profile = profile || ProfileModel.getCurrentUserProfileDetails();
  return exports.isEpic(change) && _containsGroupMentionIds(change, profile?.group_ids);
};
exports.isStoryMention = (change, profile) => {
  profile = profile || ProfileModel.getCurrentUserProfileDetails();
  return exports.isStory(change) && _containsMentionId(change, profile?.id);
};
exports.isStoryGroupMention = (change, profile) => {
  profile = profile || ProfileModel.getCurrentUserProfileDetails();
  return exports.isStory(change) && _containsGroupMentionIds(change, profile?.group_ids);
};
exports.isMention = change => {
  const me = ProfileModel.getCurrentUserProfileDetails();
  return _isCommentMention(change, me) || _isTaskMention(change, me) || exports.isHealthMention(change, me) || exports.isEpicMention(change, me) || exports.isStoryMention(change, me);
};
exports.isGroupMention = change => {
  const me = ProfileModel.getCurrentUserProfileDetails();
  return _isCommentGroupMention(change, me) || _isTaskGroupMention(change, me) || exports.isEpicGroupMention(change, me) || exports.isStoryGroupMention(change, me);
};
exports.isComment = change => _includesEntityTypes(change, ['epic-comment', 'story-comment']);
exports.isHealth = change => _includesEntityTypes(change, ['health']);
exports.isImport = change => _includesEntityTypes(change, ['import']);
exports.isStoryComment = change => _includesEntityTypes(change, 'story-comment');
exports.isEpicComment = change => _includesEntityTypes(change, 'epic-comment');
exports.userJoined = change => _includesEntityTypes(change, 'workspace2') && change.actions.find(a => a.action === 'join-via-domain');
exports.isStory = change => _includesEntityTypes(change, 'story');
exports.isStoryLink = change => _includesEntityTypes(change, 'story-link');
exports.isTask = change => _includesEntityTypes(change, ['task', 'story-task']);
exports.isEpic = change => _includesEntityTypes(change, 'epic');
exports.isProject = change => _includesEntityTypes(change, 'project');
exports.isFile = change => _includesEntityTypes(change, 'file');
exports.isBranch = change => _includesEntityTypes(change, 'branch');
exports.isPullRequest = change => _includesEntityTypes(change, 'pull-request');
exports.isSpace = change => _includesEntityTypes(change, 'space');
exports.isMilestone = change => exports.isEpicComplete(change);
exports.isEpicComplete = change => exports.isEpic(change) && _.get(change, 'changes.state.new') === 'done';
exports.hasNewActivity = () => {
  const timestamp = exports.getViewedActivityTimestamp();
  return exports.getNewActivity(timestamp).length > 0;
};
exports.resetLookback = () => {
  const newLookback = moment().subtract(PREVIOUS_DAYS_ACTIVITY, 'day').valueOf();
  if (newLookback > exports.getLookback()) {
    Log.debug('ActivityFeed: Resetting lookback to ' + moment(newLookback).calendar());
    exports.setLookback(newLookback);
  }
};
exports.advanceLookback = () => {
  let lookback = exports.getLookback();
  lookback = moment(lookback).subtract(PREVIOUS_DAYS_ACTIVITY, 'day').valueOf();
  Log.debug('ActivityFeed: Advancing lookback to ' + moment(lookback).calendar());
  exports.setLookback(lookback);
};
exports.setLookback = timestamp => {
  Log.debug('ActivityFeed: Setting lookback to ' + moment(timestamp).calendar());
  Globals.set('activityFeedLookback', timestamp);
};
exports.getLookback = () => Globals.get('activityFeedLookback') || moment().subtract(PREVIOUS_DAYS_ACTIVITY, 'day').valueOf();
exports.getOldestActivityTimestamp = () => {
  const oldestActivityBasedOnTimestamp = exports.min('timestamp');
  return oldestActivityBasedOnTimestamp?.timestamp;
};
exports.getPageActivity = (filterName, ownActivity) => {
  const lookback = exports.getLookback();
  const me = ownActivity === 'hide' ? PermissionModel.getCurrentPermissionForUser() : null;
  return _.filter(exports.getActivityFeedData(), change => {
    if (change.timestamp < lookback) return false;
    if (ownActivity === 'hide' && change.member_id === me.id) return false;
    if (filterName === 'comments') return change.isComment;else if (filterName === 'mentions') return change.isMention || change.isGroupMention;
    return true;
  });
};
exports.setFilter = filterName => ApplicationState.set('notificationFeed.filterName', filterName);
exports.getFilter = () => ApplicationState.get('notificationFeed.filterName') || 'all';
export { exports as default };