import { FilterField } from '@clubhouse/shared/components/FilterField';
import { RadioButtons } from '@clubhouse/shared/components/RadioButtons';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { useSearchParam } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicsViewConfig = createSearchParamConfig({
  paramName: 'view',
  paramType: 'string',
  defaultValue: 'table',
  validValues: ['table', 'column']
});
export const useEpicsView = () => useSearchParam(EpicsViewConfig).param;
export function EpicsViewSelector() {
  const {
    param: value,
    setSearchParam: setValue
  } = useSearchParam(EpicsViewConfig);
  return _jsx(FilterField, {
    label: "View",
    children: _jsxs(RadioButtons, {
      selected: value,
      onChange: setValue,
      children: [_jsx(RadioButtons.IconLabel, {
        value: "table",
        tooltip: "Table view",
        icon: "Table"
      }), _jsx(RadioButtons.IconLabel, {
        value: "column",
        tooltip: "Column view",
        icon: "Column"
      })]
    })
  });
}
EpicsViewSelector.displayName = "EpicsViewSelector";