import { QueryWorkspaceLabelSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQuery } from 'components/gql/filters/hooks/useFilterOptionsQuery';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { Swatch } from 'components/shared/Swatch';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getSection = (_, options) => {
  return {
    items: options.map(_ref => {
      let {
        node: {
          id,
          name,
          color
        }
      } = _ref;
      return {
        value: id,
        name: _jsx(Emojify, {
          children: name
        }),
        Icon: () => _jsx(Swatch, {
          backgroundColor: color
        })
      };
    })
  };
};
const QUERY_WORKSPACE_LABEL_SELECT = QueryWorkspaceLabelSelectDocument;
export function LabelsFilter(_ref2) {
  let {
    entityType,
    filterHeading
  } = _ref2;
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'label_ids',
    paramType: 'string[]'
  });
  return _jsx(LabelsSelect, {
    value: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    filterHeading: filterHeading
  });
}
LabelsFilter.displayName = "LabelsFilter";
export const LabelsSelect = _ref3 => {
  let {
    value,
    onChange,
    entityType,
    filterHeading
  } = _ref3;
  const fetchLabels = useFilterOptionsQuery({
    query: QUERY_WORKSPACE_LABEL_SELECT,
    selectedIds: value
  });
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchLabels,
    getSection,
    selectedValues: value,
    filterType: Nouns.Label
  });
  return _jsx(GroupedOptionsFilter, {
    fetchSections: fetchSections,
    totalItemCount: totalItemCount,
    items: items,
    selectedValues: value,
    onChange: onChange,
    filterType: Nouns.Label,
    entityType: entityType,
    icon: "Label",
    filterHeading: filterHeading
  });
};
LabelsSelect.displayName = "LabelsSelect";