import { ArchiveEpicActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { ContextMenuItem } from '@clubhouse/shared/components/ContextMenu';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useArchiveEpicMutation } from '../useArchiveEpicMutation';
import { useEpicConfirmationDialog } from '../useEpicConfirmationDialog';
import { useUnarchiveEpicMutation } from '../useUnarchiveEpicMutation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ArchiveEpicActionFragment = ArchiveEpicActionFragmentFragmentDoc;
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: message
    })
  });
};
const useArchiveAndUnarchiveEpicAction = epic => {
  const {
    openDialog,
    closeDialog
  } = useEpicConfirmationDialog();
  const archiveEpic = useArchiveEpicMutation();
  const unarchiveEpic = useUnarchiveEpicMutation();
  const onArchive = useCallback(() => {
    return new Promise(resolve => {
      openDialog('archive', {
        onCancel: resolve,
        onClick: async () => {
          try {
            closeDialog();
            await archiveEpic(epic);
            showToast(true, _jsxs(_Fragment, {
              children: ["Epic", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: epic.name
                })
              }), ' ', "archived."]
            }));
          } catch {
            showToast(false, _jsxs(_Fragment, {
              children: ["Unable to archive", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: epic.name
                })
              }), "."]
            }));
          } finally {
            resolve();
          }
        }
      });
    });
  }, [archiveEpic, closeDialog, epic, openDialog]);
  const onUnarchive = useCallback(async () => {
    try {
      await unarchiveEpic(epic);
      showToast(true, _jsxs(_Fragment, {
        children: ["Epic", ' ', _jsx("strong", {
          children: _jsx(Emojify, {
            children: epic.name
          })
        }), ' ', "unarchived."]
      }));
    } catch {
      showToast(false, _jsxs(_Fragment, {
        children: ["Unable to unarchive", ' ', _jsx("strong", {
          children: _jsx(Emojify, {
            children: epic.name
          })
        }), "."]
      }));
    }
  }, [epic, unarchiveEpic]);
  const isArchived = epic.archived;
  return {
    verb: isArchived ? 'Unarchive' : 'Archive',
    onAction: isArchived ? onUnarchive : onArchive
  };
};
export const ArchiveEpicAction = _ref => {
  let {
    epic
  } = _ref;
  const {
    verb,
    onAction
  } = useArchiveAndUnarchiveEpicAction(epic);
  return _jsxs(MoreActions.Item, {
    icon: "Archive",
    onClick: onAction,
    isDisabled: isLoggedInUserObserver(),
    children: [verb, " ", Nouns.Epic.singular]
  });
};
ArchiveEpicAction.displayName = "ArchiveEpicAction";
export const ArchiveEpicContextMenuItem = _ref2 => {
  let {
    epic
  } = _ref2;
  const {
    verb,
    onAction
  } = useArchiveAndUnarchiveEpicAction(epic);
  return _jsx(ContextMenuItem, {
    icon: "Archive",
    onClick: onAction,
    label: `${verb} ${Nouns.Epic.singular}`,
    isDisabled: isLoggedInUserObserver()
  });
};
ArchiveEpicContextMenuItem.displayName = "ArchiveEpicContextMenuItem";