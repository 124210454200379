import CreatedVsCompletedController from '../../app/client/core/js/controllers/createdVsCompletedChart';
import IterationVelocityController from '../../app/client/core/js/controllers/iterationVelocityChart';
import { CUMULATIVE_FLOW_CONFIG_OPTIONS } from '../../app/client/core/js/controllers/reports/cumulativeFlow';
import { CONFIG_OPTIONS as CYCLE_TIME_CONFIG_OPTIONS } from '../../app/client/core/js/controllers/reports/cycleTime/config';
import TimeSpentInWorkflowStateController from '../../app/client/core/js/controllers/timeSpentInWorkflowStateChart';
import ReportModel from '../../app/client/core/js/models/report';
export const getAggregateTypes = () => {
  return ReportModel.AGGR_TYPES;
};
export const getCurrentAggregationType = () => ReportModel.getAggregationType().id;
export const getAggregateTypesAsDropdownOptions = () => ReportModel.getAggregateTypesAsDropdownOptions();
export const getTimeSpentInWFStateConfig = key => TimeSpentInWorkflowStateController.CONFIG_OPTIONS[key];
export const getCreatedVsCompletedConfig = key => CreatedVsCompletedController.CONFIG_OPTIONS[key];
export const getVelocityConfig = key => IterationVelocityController.CONFIG_OPTIONS[key];
export const getCycleTimeConfig = key => CYCLE_TIME_CONFIG_OPTIONS[key];
export const getCumulativeFlowConfig = key => CUMULATIVE_FLOW_CONFIG_OPTIONS[key];
export const getMaxDayRange = () => ReportModel.getMaxDayRange();
export const getReportsConfiguration = () => ReportModel.getReportsConfiguration();
export const getReportsConfigurationUrlParam = () => ReportModel.getReportsConfigurationUrlParam();
export const DEFAULT_DATE_FORMAT = ReportModel.DEFAULT_DATE_FORMAT;
export const DEFAULT_REPORT_PERIOD_LENGTH = ReportModel.DEFAULT_REPORT_PERIOD_LENGTH;

/**
 * @description NOTE: ReportModel.fetch chart supports broader parameter types. The current parameter types are only for the label burndown chart.
 */
export const fetchChart = props => ReportModel.Promises.fetchChart(props);