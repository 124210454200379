import { groupBy } from '@clubhouse/shared/utils';
import { GROUP_BY } from 'components/shared/table/GroupBySelect/stories_graphql';
import { EpicGroupHeader } from './epic';
import { IterationGroupHeader } from './iteration';
import { OwnerGroupHeader } from './owner';
import { PriorityGroupHeader } from './priority';
import { ProductAreaGroupHeader } from './productArea';
import { SeverityGroupHeader } from './severity';
import { SkillSetGroupHeader } from './skillSet';
import { StoryTypeGroupHeader } from './storyType';
import { BacklogTableTeamGroupHeader } from './team';
import { TechnicalAreaGroupHeader } from './technicalArea';
import { hasDuplicateGroupWorkflowStateName } from './utils';
import { WorkflowNameGroupHeader } from './workflow';
import { WorkflowStateGroupHeader } from './workflowState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function isGroupByWorkflowState(item) {
  return item instanceof Object && 'workflowState' in item;
}
function isGroupByStoryType(item) {
  return item instanceof Object && 'type' in item;
}
function isGroupByWorkflowName(item) {
  return item instanceof Object && 'workflowState' in item && item.workflowState instanceof Object && 'workflow' in item.workflowState;
}
function isGroupBySkillSet(item) {
  return item instanceof Object && 'skillSet' in item;
}
function isGroupByTechnicalArea(item) {
  return item instanceof Object && 'technicalArea' in item;
}
function isGroupByPriority(item) {
  return item instanceof Object && 'priority' in item;
}
function isGroupBySeverity(item) {
  return item instanceof Object && 'severity' in item;
}
function isGroupByProductArea(item) {
  return item instanceof Object && 'productArea' in item;
}
function isGroupByTeam(item) {
  return item instanceof Object && 'team' in item;
}
function isGroupByEpic(item) {
  return item instanceof Object && 'epic' in item;
}
function isGroupByOwner(item) {
  return item instanceof Object && 'owners' in item;
}
function isGroupByIteration(item) {
  return item instanceof Object && 'iteration' in item;
}
function groupByWorkflowState(items) {
  const groupMap = groupBy(items, item => item.workflowState?.id);
  return [...groupMap.entries()].map((_ref, _, allGroups) => {
    let [key, groupItems] = _ref;
    // Get all the workflow state groups excluding the current one.
    const allWorkflowStateGroups = allGroups.filter(group => group[0] !== key).map(group => group[1]);

    /** If the current groupedBy state name is the same as another state groupBy state name,
     *  but belongs to a different workflow(group) we want to distinguish these 2 identically named groups via their heading text.
     **/
    const shouldAppendWorkflowName = hasDuplicateGroupWorkflowStateName(allWorkflowStateGroups, groupItems[0].workflowState?.name);
    return {
      key,
      items: groupItems,
      header: () => _jsx(WorkflowStateGroupHeader, {
        workflowState: groupItems[0].workflowState,
        shouldAppendWorkflowName: shouldAppendWorkflowName
      })
    };
  });
}
function groupByStoryType(items) {
  const groupMap = groupBy(items, item => item.type);
  return [...groupMap.entries()].map(_ref2 => {
    let [key, groupItems] = _ref2;
    return {
      key,
      items: groupItems,
      header: () => _jsx(StoryTypeGroupHeader, {
        type: groupItems[0].type
      })
    };
  });
}
function groupByWorkflowId(items) {
  const groupMap = groupBy(items, item => item.workflowState?.workflow.id);
  return [...groupMap.entries()].map(_ref3 => {
    let [key, groupItems] = _ref3;
    return {
      key,
      items: groupItems,
      header: () => _jsx(WorkflowNameGroupHeader, {
        workflowState: groupItems[0].workflowState
      })
    };
  });
}
function groupBySkillSet(items) {
  const groupMap = groupBy(items, item => item.skillSet?.id);
  return [...groupMap.entries()].map(_ref4 => {
    let [key, groupItems] = _ref4;
    return {
      key,
      items: groupItems,
      header: () => _jsx(SkillSetGroupHeader, {
        skillSet: groupItems[0].skillSet
      })
    };
  });
}
function groupByTechnicalArea(items) {
  const groupMap = groupBy(items, item => item.technicalArea?.id);
  return [...groupMap.entries()].map(_ref5 => {
    let [key, groupItems] = _ref5;
    return {
      key,
      items: groupItems,
      header: () => _jsx(TechnicalAreaGroupHeader, {
        technicalArea: groupItems[0].technicalArea
      })
    };
  });
}
function groupByPriority(items) {
  const groupMap = groupBy(items, item => item.priority?.id);
  return [...groupMap.entries()].map(_ref6 => {
    let [key, groupItems] = _ref6;
    return {
      key,
      items: groupItems,
      header: () => _jsx(PriorityGroupHeader, {
        priority: groupItems[0].priority
      })
    };
  });
}
function groupBySeverity(items) {
  const groupMap = groupBy(items, item => item.severity?.id);
  return [...groupMap.entries()].map(_ref7 => {
    let [key, groupItems] = _ref7;
    return {
      key,
      items: groupItems,
      header: () => _jsx(SeverityGroupHeader, {
        severity: groupItems[0].severity
      })
    };
  });
}
function groupByProductArea(items) {
  const groupMap = groupBy(items, item => item.productArea?.id);
  return [...groupMap.entries()].map(_ref8 => {
    let [key, groupItems] = _ref8;
    return {
      key,
      items: groupItems,
      header: () => _jsx(ProductAreaGroupHeader, {
        productArea: groupItems[0].productArea
      })
    };
  });
}
function groupByTeam(items) {
  const groupMap = groupBy(items, item => item.team?.id);
  return [...groupMap.entries()].map(_ref9 => {
    let [key, groupItems] = _ref9;
    return {
      key,
      items: groupItems,
      header: () => _jsx(BacklogTableTeamGroupHeader, {
        team: groupItems[0].team
      })
    };
  });
}
function groupByEpic(items) {
  const groupMap = groupBy(items, item => item.epic?.id);
  return [...groupMap.entries()].map(_ref10 => {
    let [key, groupItems] = _ref10;
    return {
      key,
      items: groupItems,
      header: () => _jsx(EpicGroupHeader, {
        epic: groupItems[0].epic
      })
    };
  });
}
function groupByOwner(items) {
  const groupMap = groupBy(items, item => (item.owners?.edges || []).map(edge => edge.node.id).sort().join());
  return [...groupMap.entries()].map(_ref11 => {
    let [key, groupItems] = _ref11;
    return {
      key,
      items: groupItems,
      header: () => _jsx(OwnerGroupHeader, {
        owners: groupItems[0].owners
      })
    };
  });
}
function groupByIteration(items) {
  const groupMap = groupBy(items, item => item.iteration?.id);
  return [...groupMap.entries()].map(_ref12 => {
    let [key, groupItems] = _ref12;
    return {
      key,
      items: groupItems,
      header: () => _jsx(IterationGroupHeader, {
        iteration: groupItems[0].iteration
      })
    };
  });
}
export function createGroups(key, items) {
  if (key === GROUP_BY.WORKFLOW) {
    return groupByWorkflowId(items.filter(isGroupByWorkflowName));
  } else if (key === GROUP_BY.STATE) {
    return groupByWorkflowState(items.filter(isGroupByWorkflowState));
  } else if (key === GROUP_BY.TYPE) {
    return groupByStoryType(items.filter(isGroupByStoryType));
  } else if (key === GROUP_BY.SKILLSET) {
    return groupBySkillSet(items.filter(isGroupBySkillSet));
  } else if (key === GROUP_BY.TECHNICAL_AREA) {
    return groupByTechnicalArea(items.filter(isGroupByTechnicalArea));
  } else if (key === GROUP_BY.PRIORITY) {
    return groupByPriority(items.filter(isGroupByPriority));
  } else if (key === GROUP_BY.SEVERITY) {
    return groupBySeverity(items.filter(isGroupBySeverity));
  } else if (key === GROUP_BY.PRODUCT_AREA) {
    return groupByProductArea(items.filter(isGroupByProductArea));
  } else if (key === GROUP_BY.TEAM) {
    return groupByTeam(items.filter(isGroupByTeam));
  } else if (key === GROUP_BY.EPIC) {
    return groupByEpic(items.filter(isGroupByEpic));
  } else if (key === GROUP_BY.OWNER) {
    return groupByOwner(items.filter(isGroupByOwner));
  } else if (key === GROUP_BY.ITERATION) {
    return groupByIteration(items.filter(isGroupByIteration));
  }
  throw new Error('Unsupported group key: ' + key);
}