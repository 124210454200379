import * as ManageLinkedAccountsTemplate from 'app/client/settingsShared/views/templates/manageLinkedAccounts.html';
import DropdownController from '../../../core/js/controllers/dropdown';
import FlashController from '../../../core/js/controllers/flash';
import Is from '../../../core/js/modules/is';
import PermissionModel from '../../../core/js/models/permission';
import UploaderController from '../../../core/js/controllers/uploader';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import View from 'app/client/core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
import gdriveLogo from '@clubhouse/assets/png/third-party-logos/google_drive_32x32.png';
const exports = {};
const PARENT_SELECTOR = '#manage-linked-accounts';
exports.title = `Linked Accounts | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/account/linked-accounts';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  View.renderWithComponents({
    template: ManageLinkedAccountsTemplate,
    templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR),
    templateData: {
      active: 'manageLinkedAccounts',
      permission: PermissionModel.getCurrentPermissionForUser(),
      readOnly: Is.readOnly(UserModel.getLoggedInUserPermission()),
      gdriveLogo
    },
    components: {
      readOnlyMessage: {
        component: AskAnOwnerAlert,
        props: {
          description: 'Observers do not have access to linking third party accounts to their profile.'
        }
      }
    }
  });

  // TODO: This is sloppy, clean it up so we only draw the button once google libraries are loaded.
  UploaderController.initGooglePicker();
  SettingsPage.onDestroy('ManagedLinkedAccounts', () => {
    View.unmountComponents({
      templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR)
    });
  });
};
exports.getCurrentGoogleToken = () => {
  return PermissionModel.getCurrentUserPermissionItem('google_token');
};
exports.getCurrentGoogleTokenExpiration = () => {
  return PermissionModel.getCurrentUserPermissionItem('google_token_expiration_date');
};
exports.isGoogleTokenValid = () => {
  return exports.getCurrentGoogleToken() && moment().isBefore(exports.getCurrentGoogleTokenExpiration());
};
exports.linkGoogleDrive = function () {
  $('#linkGoogleDrive').html('<span class="fa fa-star fa-spin"></span> Saving');
  exports.requestNewGoogleToken.call(this, {
    onTokenReceived: err => {
      exports.render();
      if (err) {
        FlashController.error(PARENT_SELECTOR, err);
      }
    },
    onComplete: err => {
      exports.render();
      if (err) {
        FlashController.error(PARENT_SELECTOR, err);
      }
    }
  });
  return false;
};
exports.unlinkGoogleDrive = function () {
  $(this).removeAttr('data-on-click').find('.fa').removeClass('fa-trash-o').addClass('fa-spin fa-star');
  $.ajax({
    type: 'GET',
    url: 'https://accounts.google.com/o/oauth2/revoke?token=' + exports.getCurrentGoogleToken(),
    dataType: 'jsonp',
    success: () => {
      PermissionModel.updatePermission({
        google_token: null,
        google_token_expiration_date: null
      }, err => {
        exports.render();
        if (err) {
          FlashController.error(PARENT_SELECTOR, err);
        }
      });
    },
    error: () => {
      exports.render();
      FlashController.error(PARENT_SELECTOR, 'There was a problem revoking access to Google, ' + '<a href="https://security.google.com/settings/security/permissions" target="_blank">' + 'view your authorized apps</a>.');
    }
  });
  return false;
};
exports.requestNewGoogleToken = options => {
  options = options || {};
  options.onTokenReceived = _.isFunction(options.onTokenReceived) ? options.onTokenReceived : _.noop;
  options.onComplete = _.isFunction(options.onComplete) ? options.onComplete : _.noop;
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: window._GOOGLE_DRIVE_CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/drive.file',
    callback: result => {
      // If called incorrectly (e.g. missing client_id),
      // the result will not include an error (that only comes from XHR responses),
      // but will include a message. This isn't documented.
      // So check for access_token directly as a signal for success,
      // and everything else is a failure.
      // If we write "undefined" to res.token, we'll get an infinite permission refresh loop.
      if (result && result.access_token) {
        const res = {
          token: result.access_token,
          token_expiration_date: result.expires_in
        };
        exports.handleGoogleAuthResponse(options, null, res);
      } else {
        // result.message is undocumented, but occurs when the call was incorrect
        // and a network request wasn't made.
        exports.handleGoogleAuthResponse(options, result.error || result.message);
      }
    }
  });
  client.requestAccessToken();
};
exports.handleGoogleAuthResponse = (options, err, res) => {
  if (err) {
    DropdownController.closeAll();
    options.onComplete(err);
  } else if (res) {
    DropdownController.closeAll();
    options.onTokenReceived(null, res);
    PermissionModel.updatePermission({
      google_token: res.token,
      google_token_expiration_date: moment().add(Number.parseInt(res.token_expiration_date, 10), 's').format()
    }, options.onComplete);
  }
};
export { exports as default };