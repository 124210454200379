import { useEffect, useState } from 'react';
const useScrollIntoView = shouldScroll => {
  const [element, setElement] = useState();
  useEffect(() => {
    if (shouldScroll && element) {
      element.scrollIntoView({
        block: 'nearest'
      });
    }
  }, [element, shouldScroll]);
  return {
    ref: setElement
  };
};
export { useScrollIntoView };