import { useCallback, useEffect, useState } from 'react';
import jiraCompletePng from '@clubhouse/assets/png/jira_complete.png';
import trelloCompletePng from '@clubhouse/assets/png/trello_complete.png';
import { isCanceled, useRequest, useRequestTimer } from '@clubhouse/shared/hooks';
import { getImportMapping as _getImportMapping, commitMappingByType, createImport, setMappingByType } from 'data/entity/import';
import { invite } from 'data/entity/invite';
import { setAppState } from 'utils/appState';
import { error as showErrorToast } from 'utils/message';
import { getCurrentPathname } from 'utils/navigation';
import { useAutoQuickstartComplete } from 'utils/quickstart';
import { ImportErrorKeys, PossibleImportErrors } from '../shared/utils/errorMap';
import { AuthorizeJiraImport } from './AuthorizeJiraImport';
import { AuthorizeTrelloImport } from './AuthorizeTrelloImport';
import { ImportComplete } from './ImportComplete';
import { IMPORT_STATES } from './ImportStates';
import { JiraSyncPage } from './JiraSyncPage';
import { MapToStoryType } from './MapToStoryType';
import { MapToUsers } from './MapToUsers';
import { MapToWorkflow } from './MapToWorkflow';
import { MapToWorkflowState } from './MapToWorkflowState';
import { SelectImport } from './SelectImport';
import { useListenToImportUpdates } from './importHooks';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const KEY = 'Import.dismissed';
const getInitialFormState = importType => ({
  importType: importType ?? undefined,
  authKey: undefined,
  authToken: undefined,
  boardId: undefined
});
const getInitialImportState = importModel => importModel || {};
const jira = Object.freeze({
  platform: 'Jira',
  completeImgSrc: jiraCompletePng,
  workflowUnit: {
    singular: 'Workflow',
    plural: 'Workflows'
  },
  projectUnit: {
    singular: 'Project',
    plural: 'Projects'
  },
  storyUnit: {
    singular: 'Issue',
    plural: 'Issues'
  }
});
const trello = Object.freeze({
  platform: 'Trello',
  completeImgSrc: trelloCompletePng,
  workflowUnit: {
    singular: 'Board',
    plural: 'Boards'
  },
  projectUnit: {
    singular: 'Project',
    plural: 'Projects'
  },
  storyUnit: {
    singular: 'Card',
    plural: 'Cards'
  }
});
const customExport = Object.freeze({
  platform: 'shortcut-csv-export',
  workflowUnit: {
    singular: 'board',
    plural: 'boards'
  },
  projectUnit: {
    singular: 'project',
    plural: 'projects'
  },
  storyUnit: {
    singular: 'issue',
    plural: 'issues'
  }
});
const platformLabelMap = Object.freeze({
  jira,
  trello,
  customExport
});
const getInitialIndex = function () {
  let initialIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let importModel = arguments.length > 1 ? arguments[1] : undefined;
  if (initialIndex) return initialIndex;
  const checkPoint = {
    jira: {
      [IMPORT_STATES.WAITING_FOR_MAPPING]: 1,
      [IMPORT_STATES.DONE]: 6
    },
    trello: {
      [IMPORT_STATES.WAITING_FOR_MAPPING]: 1,
      [IMPORT_STATES.DONE]: 3
    },
    customExport: {
      [IMPORT_STATES.WAITING_FOR_MAPPING]: 0,
      [IMPORT_STATES.DONE]: 3
    }
  };
  if (importModel && importModel.source && importModel.import_status) {
    if (importModel.source === 'shortcut-csv-export') {
      return checkPoint.customExport[importModel.import_status];
    }
    return checkPoint[importModel.source][importModel.import_status];
  }
  return initialIndex;
};
export const ImportController = props => {
  const {
    index,
    existingWorkflows,
    clubhouseUsers,
    importModel,
    render,
    initialImportMapping = {},
    createSync,
    pushRouterState,
    resetRouterState
  } = props;
  const [shouldSync, setShouldSync] = useState(false);
  const {
    request: bulkInviteRequest
  } = useRequest({
    fn: invite
  });
  const {
    request: createImportModel,
    isRequesting: isAuthenticating
  } = useRequestTimer(createImport);
  const {
    request: getImportMapping
  } = useRequest({
    fn: _getImportMapping
  });
  const {
    request: updateImport
  } = useRequest({
    fn: setMappingByType
  });
  const {
    request: commitImport,
    isRequesting: isImporting
  } = useRequest({
    fn: commitMappingByType
  });
  const [updatingMapping, setUpdatingMapping] = useState(false);
  const [importFormData, setImportFormData] = useState(getInitialFormState(importModel ? importModel.source : null));
  const [currentImport, setCurrentImport] = useState(getInitialImportState(importModel));
  const [currentImportMapping, setImportMapping] = useState(initialImportMapping);
  const [waitingForUpdate, setWaitingForUpdate] = useState(false);
  const [submitting, setIsSubmitting] = useState(false);
  const [committing, setIsCommitting] = useState(false);
  const [invitingUsers, setInvitingUsers] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(getInitialIndex(index, currentImport));
  const [error, setError] = useState({
    title: '',
    message: ''
  });
  const nextPage = () => {
    setCurrentIndex(currentIndex + 1);
  };
  const onUpdate = (newImport, newStatus) => {
    setCurrentImport(newImport);
    setWaitingForUpdate(false);
    if (newStatus !== IMPORT_STATES.UNDONE && newStatus !== IMPORT_STATES.UNDOING && waitingForUpdate) {
      nextPage();
    }
  };
  useListenToImportUpdates({
    listenTo: [IMPORT_STATES.WAITING_FOR_MAPPING, IMPORT_STATES.DONE, IMPORT_STATES.UNDONE, IMPORT_STATES.UNDOING],
    callback: onUpdate,
    currentImport
  });
  const updateImportFormData = formData => {
    const newState = {
      ...importFormData,
      ...formData
    };
    setImportFormData(newState);
  };
  const updateCurrentImportMapping = useCallback(importMap => {
    const newState = {
      ...currentImportMapping,
      ...importMap
    };
    setImportMapping(newState);
  }, [currentImportMapping]);
  const goBack = () => {
    setError({});
    if (currentIndex !== 0) setCurrentIndex(currentIndex - 1);
  };

  /* The import id is pushed to the URL to help troubleshoot import errors
   * (See https://app.shortcut.com/internal/story/124037/self-serve-import-url-should-update-with-importid-after-step-1-of-import-process)
   */
  const pushImportIdToUrl = useCallback(importId => {
    const id = getCurrentPathname().split('/').pop();
    if (importId && id !== importId) {
      pushRouterState(importId);
    }
  }, [pushRouterState]);
  const submitImportFormData = useCallback(async () => {
    const platformLabel = importFormData.importType ? platformLabelMap[importFormData.importType] : null;
    setIsSubmitting(false);
    try {
      const res = await createImportModel(importFormData);
      setCurrentImport(res);
      pushImportIdToUrl(res.id);
    } catch (e) {
      if (!isCanceled(e)) {
        setWaitingForUpdate(false);
        let errorObject = PossibleImportErrors(platformLabel)[ImportErrorKeys.UNKNOWN_CAUSE];
        if (e && typeof e === 'object' && 'possibleErrors' in e && Array.isArray(e.possibleErrors) && e.possibleErrors.length === 1) {
          const error = PossibleImportErrors(platformLabel)[e.possibleErrors[0]];
          errorObject = error ? error : errorObject;
        }
        setError(errorObject);
      }
    }
  }, [importFormData, createImportModel, pushImportIdToUrl]);
  const submitImportForm = formData => {
    updateImportFormData(formData);
    setWaitingForUpdate(true);
    setIsSubmitting(true);
    setError({});
  };
  const fetchImportMap = async _ref => {
    let {
      workflow_id,
      project_id
    } = _ref;
    try {
      const res = await getImportMapping(currentImport.id);
      updateCurrentImportMapping({
        ...res,
        workflow_id,
        project_id
      });
      nextPage();
    } catch (e) {
      if (!isCanceled(e)) {
        showErrorToast(e, {
          secondary: 'Could not fetch import.'
        });
      }
    }
  };
  useEffect(() => {
    if (submitting) {
      submitImportFormData();
    }
  }, [submitting, submitImportFormData]);
  const updateMappingWithCurrentImportMapping = useCallback(async () => {
    try {
      const transformedMembers = (currentImportMapping.members || []).map(m => {
        if (m.invite_id) {
          const transform = {
            ...m
          };
          delete transform.role;
          delete transform.email;
          return transform;
        }
        return m;
      });
      const res = await updateImport(currentImport.id, {
        ...currentImportMapping,
        members: transformedMembers
      }, importFormData.importType);
      if (!res.error) {
        setImportMapping(res);
      } else {
        setError({
          title: res.message,
          message: ''
        });
        setWaitingForUpdate(false);
        return;
      }
    } catch (e) {
      if (!isCanceled(e)) {
        setWaitingForUpdate(false);
        setError({
          title: 'Could not update import with info. Please try again.',
          message: e?.toString()
        });
      }
    }
  }, [currentImport, currentImportMapping, updateImport, importFormData]);
  useEffect(() => {
    if (updatingMapping) {
      updateMappingWithCurrentImportMapping();
      setUpdatingMapping(false);
    }
  }, [updatingMapping, updateMappingWithCurrentImportMapping]);
  const commitImportAndComplete = () => {
    setWaitingForUpdate(true);
    setIsCommitting(true);
    setError({});
  };
  const commitAndCompleteImport = useCallback(async () => {
    setIsCommitting(false);
    await updateMappingWithCurrentImportMapping();
    try {
      const commitRes = await commitImport(currentImport.id, importFormData.importType);
      if (!commitRes.error) {
        setAppState(`${currentImport.source}.${KEY}`, true);
        resetRouterState();
      } else {
        setError({
          title: 'Could not process imported data.',
          message: commitRes.message
        });
        setWaitingForUpdate(false);
      }
    } catch (e) {
      if (!isCanceled(e)) {
        setWaitingForUpdate(false);
        setError({
          title: 'Could not finish import. Please try again later.',
          message: e?.toString()
        });
      }
    }
  }, [updateMappingWithCurrentImportMapping, commitImport, currentImport.id, currentImport.source, importFormData.importType, resetRouterState]);
  useEffect(() => {
    if (committing) {
      commitAndCompleteImport();
    }
  }, [committing, commitAndCompleteImport]);
  const inviteUsers = useCallback(async () => {
    try {
      const invites = (currentImportMapping.members || []).filter(n => n.role && n.email && !n.invite_id).map(n => ({
        role: n.role,
        email: n.email
      }));
      const inviteResponse = await bulkInviteRequest(invites);
      const successfulInvites = inviteResponse.filter(i => i.created);
      const newMembers = (currentImportMapping.members || []).map(c => {
        const invite = inviteResponse.find(data => data.email === c.email);
        if (invite && invite.created) {
          return {
            ...c,
            invite_id: invite.id
          };
        } else if (invite && !invite.created) {
          if (invite.reason_tag === 'invites-exhausted') {
            setError({
              title: 'Invite cannot be sent',
              message: invite.reason
            });
          }
          return {
            ...c,
            error: invite
          };
        }
        return c;
      });
      updateCurrentImportMapping({
        members: newMembers
      });
      if (successfulInvites.length > 0) {
        setUpdatingMapping(true);
      }
    } catch (e) {
      if (isCanceled(e)) return;
    }
  }, [bulkInviteRequest, updateCurrentImportMapping, currentImportMapping]);
  useEffect(() => {
    if (invitingUsers) {
      inviteUsers();
      setInvitingUsers(false);
    }
  }, [invitingUsers, inviteUsers]);
  let pages = [];
  let importMapObj = importFormData.importType ? platformLabelMap[importFormData.importType] : null;
  if (importFormData.importType === 'shortcut-csv-export') {
    importMapObj = platformLabelMap.customExport;
  }
  const MapToFlow = nextStepLabel => _jsx(MapToWorkflow, {
    goBack: goBack,
    onDone: importMap => {
      fetchImportMap(importMap);
    },
    nextStepLabel: nextStepLabel,
    existingWorkflows: existingWorkflows,
    boardName: currentImport.source_descriptor,
    platformLabelObj: importMapObj,
    initialWorkflowId: currentImportMapping.workflow_id
  }, "mapping");
  if (!importFormData.importType) {
    pages = [_jsx(SelectImport, {
      onDone: formData => {
        updateImportFormData(formData);
      },
      setShouldSync: setShouldSync,
      onSyncClick: props.openCreateSyncDialog
    }, "select")];
  } else if (importFormData.importType === 'jira') {
    pages = [_jsx(AuthorizeJiraImport, {
      goBack: () => {
        updateImportFormData({
          importType: undefined
        });
        goBack();
      },
      onDone: submitImportForm,
      initialFormData: importFormData
    }, "authorize"), MapToFlow('Map Story Types'), _jsx(MapToStoryType, {
      goBack: goBack,
      onDone: storyTypes => {
        updateCurrentImportMapping({
          story_types: storyTypes
        });
        nextPage();
      },
      importMap: currentImportMapping
    }, "map-story-types"), _jsx(MapToWorkflowState, {
      workflowId: currentImportMapping.workflow_id,
      workflowTypes: currentImportMapping.workflow_states,
      platformLabelObj: importMapObj,
      goBack: goBack,
      onDone: workflowStates => {
        updateCurrentImportMapping({
          workflow_states: workflowStates
        });
        nextPage();
      }
    }, "map-workflow-state"), _jsx(MapToUsers, {
      goBack: goBack,
      onDone: data => {
        if (data) updateCurrentImportMapping(data);
        nextPage();
      },
      clubhouseUsers: clubhouseUsers,
      platformLabel: importMapObj.platform,
      members: currentImportMapping.members,
      inviteUsers: () => {
        setInvitingUsers(true);
      }
    }, "map-users"), _jsx(JiraSyncPage, {
      shouldSync: shouldSync,
      onClickGoBack: goBack,
      importId: currentImport.id,
      workflowId: currentImportMapping.workflow_id,
      createSync: createSync,
      onClickComplete: async () => {
        if (shouldSync) {
          setWaitingForUpdate(true);
          setError({});
          await commitAndCompleteImport();
        } else {
          commitImportAndComplete();
        }
      }
    }, "jira-sync"), _jsx(ImportComplete, {
      boardName: currentImport.source_descriptor,
      importId: currentImport.id,
      platformLabelObj: importMapObj
    }, "import-complete")];
  } else if (importFormData.importType === 'shortcut-csv-export') {
    pages = [_jsx(MapToWorkflow, {
      goBack: () => {
        updateImportFormData({
          importType: undefined
        });
        goBack();
      },
      onDone: importMap => {
        fetchImportMap(importMap);
      },
      nextStepLabel: 'Map Workflow States',
      existingWorkflows: existingWorkflows,
      boardName: currentImport.source_descriptor,
      platformLabelObj: importMapObj,
      initialWorkflowId: currentImportMapping.workflow_id
    }, "mapping"), _jsx(MapToWorkflowState, {
      workflowId: currentImportMapping.workflow_id,
      workflowTypes: currentImportMapping.workflow_states,
      platformLabelObj: importMapObj,
      goBack: goBack,
      onDone: workflowStates => {
        updateCurrentImportMapping({
          workflow_states: workflowStates
        });
        nextPage();
      }
    }, "map-workflow-state"), _jsx(MapToUsers, {
      goBack: goBack,
      onDone: data => {
        if (data) {
          updateCurrentImportMapping(data);
        } else {
          commitImportAndComplete();
        }
      },
      clubhouseUsers: clubhouseUsers,
      members: currentImportMapping.members,
      platformLabel: importMapObj.platform,
      inviteUsers: () => {
        setInvitingUsers(true);
      }
    }, "map-users"), _jsx(ImportComplete, {
      boardName: currentImport.source_descriptor,
      importId: currentImport.id,
      platformLabelObj: importMapObj
    }, "import-complete")];
  } else {
    pages = [_jsx(AuthorizeTrelloImport, {
      goBack: () => {
        updateImportFormData({
          importType: undefined
        });
        goBack();
      },
      onDone: submitImportForm,
      initialFormData: importFormData
    }, "authorize"), MapToFlow('Map Users'), _jsx(MapToUsers, {
      goBack: goBack,
      onDone: data => {
        if (data) {
          updateCurrentImportMapping(data);
        } else {
          commitImportAndComplete();
        }
      },
      clubhouseUsers: clubhouseUsers,
      members: currentImportMapping.members,
      platformLabel: importMapObj.platform,
      inviteUsers: () => {
        setInvitingUsers(true);
      }
    }, "map-users"), _jsx(ImportComplete, {
      boardName: currentImport.source_descriptor,
      importId: currentImport.id,
      platformLabelObj: importMapObj
    }, "import-complete")];
  }
  useAutoQuickstartComplete('visit-import');
  return render({
    importType: importFormData.importType,
    importStatus: currentImport.import_status,
    importMapObj,
    isAuthenticating,
    isImporting,
    waitingForUpdate,
    pages,
    error,
    currentIndex
  });
};