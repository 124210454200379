import { TeamScopeProviderTeamExistsQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useNodeQuery } from 'gql/hooks/useNodeQuery';
export const TeamScopeProviderTeamExistsQuery = TeamScopeProviderTeamExistsQueryDocument;
export const useDoesTeamExist = id => {
  const {
    node,
    loading
  } = useNodeQuery(TeamScopeProviderTeamExistsQuery, {
    id: id ?? '',
    skip: !id
  });
  return {
    loading,
    exists: !!node?.id
  };
};