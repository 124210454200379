import { MutateEpicDeadlineDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicDeadlineFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableDatePicker } from 'components/shared/table/base';
import { useMutation } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EpicDeadlineFieldFragment = EpicDeadlineFieldFragmentFragmentDoc;
const MUTATE_EPIC_DEADLINE = MutateEpicDeadlineDocument;
function DeadlineFieldComponent(_ref) {
  let {
    entity: {
      id,
      epicState,
      plannedStartDate,
      deadline
    }
  } = _ref;
  const [save] = useMutation(MUTATE_EPIC_DEADLINE);
  const handleChange = useCallback(date => {
    save({
      variables: {
        id,
        input: {
          deadline: date
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        epicUpdate: {
          __typename: 'EpicPayload',
          epic: {
            __typename: 'Epic',
            id,
            deadline: date,
            plannedStartDate,
            epicState
          }
        }
      }
    });
  }, [id, plannedStartDate, epicState, save]);
  return _jsx(TableDatePicker, {
    colorizeDueDate: epicState?.type !== 'done',
    minDate: plannedStartDate,
    onChange: handleChange,
    value: deadline
  });
}
DeadlineFieldComponent.displayName = "DeadlineFieldComponent";
export const DeadlineField = () => ({
  name: 'deadline',
  displayName: 'Target Date',
  headerProps: {
    centered: true
  },
  sort: true,
  Component: DeadlineFieldComponent,
  LoadingComponent: () => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      width: 40
    })
  }),
  width: 130
});