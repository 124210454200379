import * as InlineMessageTemplate from 'app/client/core/views/templates/inlineMessage.html';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};
const PARENT_SELECTOR = '.inline-message';
const DEFAULT_TIMEOUT = 8000; // in milliseconds

let _timeoutId = null;
exports.open = options => {
  const alreadyOpen = !!(options.id && document.getElementById(options.id));
  if (alreadyOpen) {
    $('#' + options.id).remove();
  }
  const timeout = _.isNumber(options.timeout) ? options.timeout : DEFAULT_TIMEOUT;
  const html = InlineMessageTemplate.render({
    html: options.html,
    id: options.id || '',
    icon: options.icon || 'fa-hand-o-left',
    actions: options.actions,
    noCancel: options.noCancel
  });
  const element = View.attach(html, options.target);
  if (!Utils.animationDisabled()) {
    element.addClass('element-is-hiding');
    setTimeout(() => {
      if (element) {
        element.removeClass('element-is-hiding').addClass('element-is-open');
      }
    }, 0);
  }
  clearTimeout(_timeoutId);
  if (timeout) {
    _timeoutId = setTimeout(() => {
      exports.close();
    }, timeout);
  }
  return element;
};
exports.close = () => {
  $(PARENT_SELECTOR).addClass('element-is-closing');
  setTimeout(() => {
    $(PARENT_SELECTOR).removeClass('element-is-open').addClass('element-is-hiding');
  }, 0);
  setTimeout(() => {
    $(PARENT_SELECTOR).remove();
  }, 200);
  return false;
};
export { exports as default };