import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportMetrics = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsxs("svg", {
    width: width,
    height: width,
    viewBox: "0 0 46 46",
    fill: "none",
    children: [_jsx("path", {
      d: "M38.9467 35.0367C40.02 32.7367 40.6333 30.2067 40.6333 27.5234C40.6333 17.7867 32.7367 9.89001 23 9.89001C13.2634 9.89001 5.36668 17.7867 5.36668 27.5234C5.36668 30.2067 5.98002 32.7367 7.05335 35.0367H38.9467Z",
      fill: "#EAEAEA",
      fillOpacity: "0.5",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsxs("g", {
      opacity: "0.6",
      children: [_jsx("path", {
        d: "M23.3067 31.5867C24.9157 31.5867 26.2201 30.2823 26.2201 28.6733C26.2201 27.0644 24.9157 25.76 23.3067 25.76C21.6978 25.76 20.3934 27.0644 20.3934 28.6733C20.3934 30.2823 21.6978 31.5867 23.3067 31.5867Z",
        fill: "#346AB1"
      }), _jsx("path", {
        d: "M23 28.75L34.5767 23.575",
        stroke: "#346AB1",
        strokeWidth: "2",
        strokeMiterlimit: "10",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      })]
    }), _jsx("path", {
      d: "M12.3433 40H34.4999",
      stroke: "#BFDEC7",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M22.77 10.0433V13.57",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M40.02 28.98H36.5699",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M34.8834 15.0267L32.5067 17.7867",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M6.13332 28.98H9.58332",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      d: "M11.8067 15.0267L14.1067 17.7867",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    })]
  });
};
ReportMetrics.displayName = "ReportMetrics";