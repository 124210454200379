import { EpicRelatedDocsFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableText } from '@clubhouse/shared/components/Table';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicRelatedDocsFieldFragment = EpicRelatedDocsFieldFragmentFragmentDoc;
const DocsFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  return _jsx(TableText, {
    text: entity.stats.numRelatedDocuments
  });
};
DocsFieldComponent.displayName = "DocsFieldComponent";
export const RelatedDocsField = () => ({
  name: 'numRelatedDocuments',
  displayName: 'Docs',
  headerProps: {
    centered: true
  },
  Component: DocsFieldComponent,
  LoadingComponent: () => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      width: 20
    })
  }),
  width: 80,
  sort: 'stats_numRelatedDocuments'
});