import BarLoaderReverse from '@clubhouse/assets/gif/bar_loader_reverse.gif';
import { LoadingState } from './shared/LoadingState';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UndoingPage = () => _jsx(LoadingState, {
  title: _jsx("span", {
    style: {
      fontSize: 18
    },
    children: "Oh no! Your import has failed."
  }),
  message: _jsxs(_Fragment, {
    children: ["Please wait. We're undoing the import and removing all imported data.", _jsx("br", {}), " Please contact ", _jsx("a", {
      href: `mailto:${BRAND.SUPPORT_EMAIL}`,
      children: BRAND.SUPPORT_EMAIL
    }), " with any questions."]
  }),
  hero: _jsx("img", {
    "data-perma-id": "undoing-page-hero",
    src: BarLoaderReverse,
    alt: ""
  })
});
UndoingPage.displayName = "UndoingPage";