import { useCallback } from 'react';
import { areAllSelected, getRemainingLimit, removeAll, selectMultiple, useBulkSelections } from 'data/entity/bulkSelection';
import { getSelectedStoriesInColumn } from 'utils/storiesColumn';
import { getVisibleStoriesInColumn } from 'utils/storiesView';
export const useStoryColumnBulkSelect = column => {
  const selectedStories = getSelectedStoriesInColumn(column);
  const visibleStories = getVisibleStoriesInColumn(column);
  const remainingLimit = getRemainingLimit();
  const selectableStories = visibleStories.slice(0, getRemainingLimit());
  const areAllStoriesSelected = areAllSelected(visibleStories) || areAllSelected(selectableStories);
  const canSelect = remainingLimit > 0;
  const hasReachedSelectionLimit = !canSelect;
  const isEmptyColumn = visibleStories.length === 0;
  useBulkSelections();
  const selectAll = useCallback(() => {
    removeAll(selectedStories.map(_ref => {
      let {
        id
      } = _ref;
      return id;
    })); // Clear column selection

    const ids = visibleStories.slice(0, getRemainingLimit()).map(_ref2 => {
      let {
        id
      } = _ref2;
      return id;
    });
    selectMultiple(ids);
  }, [visibleStories, selectedStories]);
  const deselectAll = useCallback(() => {
    removeAll(selectedStories.map(_ref3 => {
      let {
        id
      } = _ref3;
      return id;
    }));
  }, [selectedStories]);
  return {
    visibleStories,
    selectedStories,
    canSelect,
    areAllSelected: areAllStoriesSelected,
    hasReachedSelectionLimit,
    isEmptyColumn,
    selectAll,
    deselectAll
  };
};