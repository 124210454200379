import { Icon } from '@useshortcut/shapes-ds';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DetailPageSidebarAction, DetailPageSidebarRow, SecondaryActionButton } from 'components/shared/DetailPageSidebar';
import { getDeadlineClass } from 'data/entity/epic';
import { EmptyValue } from './EmptyValue';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EpicEndDate(_ref) {
  let {
    epic
  } = _ref;
  const deadlineClass = getDeadlineClass(epic);
  const isOverdue = deadlineClass === 'deadline-overdue';
  const isNearDeadline = deadlineClass === 'deadline-warning';
  return _jsx(DetailPageSidebarRow, {
    label: "Target Date",
    "data-perma-id": "epic-deadline-field",
    children: _jsx(DetailPageSidebarAction, {
      style: {
        color: isOverdue ? 'var(--iconRedColor)' : isNearDeadline ? 'var(--iconOrangeColor)' : undefined
      },
      icon: _jsx(Icon, {
        icon: "Calendar",
        fill: isOverdue || isNearDeadline ? 'currentColor' : 'main'
      }),
      "data-field-name": "deadline",
      "data-on-click": "openDateControl",
      secondarySlot: epic.formatted_deadline ? _jsx(Tooltip, {
        content: "Clear Target Date",
        children: _jsx(SecondaryActionButton, {
          "data-perma-id": "clearDate",
          "data-field-name": "deadline",
          "data-on-click": "clearDate",
          children: _jsx(Icon, {
            icon: "Remove",
            fill: "main",
            label: "Clear Target Date"
          })
        })
      }) : undefined,
      children: epic.formatted_deadline ? epic.formatted_deadline : _jsx(EmptyValue, {
        children: "No Date"
      })
    })
  });
}
EpicEndDate.displayName = "EpicEndDate";