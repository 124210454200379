import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { sum } from 'lodash';
import { SEARCH_ENTITY, WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { capitalize } from '@clubhouse/shared/utils';
import { isUsingPoints } from '../../data/entity/estimateScale';
import { getIterationProgressStats } from '../../data/entity/iteration';
import { getMilestoneProgressStats } from '../../data/entity/milestone';
import { timestampToIsoDate } from '../../utils/date';
const convertIterationStatus = status => {
  const workflowStatus = {
    [WORKFLOW_STATE_TYPES.UNSTARTED]: 'To do',
    [WORKFLOW_STATE_TYPES.STARTED]: 'In progress',
    [WORKFLOW_STATE_TYPES.DONE]: 'Done'
  };
  return workflowStatus[status];
};
export const milestoneToSearchProps = milestone => {
  const {
    name,
    url: href,
    id,
    state,
    started_at_override,
    completed_at_override,
    totalStories,
    epics
  } = milestone;
  const start = timestampToIsoDate(started_at_override) ?? undefined;
  const end = timestampToIsoDate(completed_at_override) ?? undefined;
  const status = capitalize(state);
  const stats = getMilestoneProgressStats(milestone);
  const totalEpics = epics.length;
  return {
    name,
    href,
    id,
    start,
    end,
    status,
    stats,
    totalStories,
    totalEpics,
    entityType: SEARCH_ENTITY.MILESTONE
  };
};
export const iterationToSearchProps = iteration => {
  const {
    name,
    app_url,
    id,
    status: state,
    start_date: start,
    end_date: end,
    team,
    users
  } = iteration;
  const status = convertIterationStatus(state);
  const {
    pointStats,
    storyStats
  } = getIterationProgressStats(iteration);
  const totalStories = sum(Object.values(storyStats));
  const totalPoints = sum(Object.values(pointStats));
  const stats = isUsingPoints() ? pointStats : storyStats;
  // For some reason with iterations, the full URL is sent and that is not compatible with hot reload
  // So we should just grab the pathname
  const href = new URL(app_url).pathname;
  return {
    name,
    href,
    id,
    start,
    end,
    status,
    stats,
    totalStories,
    totalPoints,
    team,
    users,
    entityType: SEARCH_ENTITY.ITERATION
  };
};