import StoryTemplateModel from 'app/client/core/js/models/storyTemplate';
import { useEntities } from './collection';
import { getById as getWorkflowById } from './workflow';
export const getById = id => StoryTemplateModel.getById(id);
export const useStoryTemplates = () => {
  const {
    entities
  } = useEntities({
    model: StoryTemplateModel
  });
  return entities;
};
export const hasAssosciatedStoryTemplate = _ref => {
  let {
    groupId,
    workflowId
  } = _ref;
  const workflow = getWorkflowById(workflowId);
  return StoryTemplateModel.all().some(_ref2 => {
    let {
      story_contents
    } = _ref2;
    if (story_contents.group_id !== groupId) return false;
    if (!workflow) return false;
    if (workflow.states.every(s => s.id !== story_contents.workflow_state_id)) return false;
    return true;
  });
};