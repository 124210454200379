// This is currently used by the "Recently Viewed" feature.

import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import { callThrottledWithLogging } from '../modules/throttleWithLogging';
const exports = {};

/*

Example response as of 2020/02/13:

[
  {
    "entity_type": "epic",
    "id": 93183,
    "last_viewed_at": "2020-02-13T15:01:47Z"
  },
  {
    "entity_type":"story",
    "id": 95224,
    "last_viewed_at": "2020-02-13T14:26:40Z"
  }
]

*/

Collection.create('View', exports);
exports.isValid = obj => {
  return obj && obj.id;
};
exports.fetchAll = callback => {
  Backend.get('/api/private/permission/views', {
    onComplete: res => {
      // Flush the in-memory store, since we don't know if that will be capped.
      exports.flush();
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.addView = (entityType, id, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const data = {
    viewed_item: {
      entity_type: entityType,
      id: id
    }
  };
  callThrottledWithLogging(`AddView.${entityType}.${id}`, () => {
    Backend.put('/api/private/permission/views', {
      data,
      onComplete: (res, xhr) => {
        exports.defaultNoResponseHandler(res, xhr, callback);
      }
    });
  });
};
export { exports as default };