import { StateIcon } from '@clubhouse/shared/components/StateIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WorkflowStateIcon = _ref => {
  let {
    type
  } = _ref;
  return _jsx(StateIcon, {
    type: type
  });
};
WorkflowStateIcon.displayName = "WorkflowStateIcon";