import Layout from 'app/client/core/js/modules/layout'; // eslint-disable-line import/no-restricted-paths
import { loadPageFromMenu } from 'utils/hotReload';
import { EVENTS, logEvent } from 'utils/monitoring';
import { getCurrentPage, usePageName, usePagePathname } from 'utils/navigation';
import { useIsMobileNavOpen } from '../nav/useIsMobileNavOpen';
import { isRelativeMenuItem } from './useMenuItems'; // eslint-disable-line import/no-restricted-paths
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const openSubSettingsPage = fn => fn();
const openPagesFromMenu = (id, route, getLoggingProps) => {
  const {
    openIntegrations,
    openHelp
  } = Layout.getProfileProps();
  const trackOnClick = id => {
    logEvent(EVENTS.Interaction_LeftNavigation, {
      source: getCurrentPage(),
      selection: id
    });
  };
  switch (id) {
    case 'help':
      trackOnClick(id);
      openSubSettingsPage(openHelp);
      break;
    case 'integrations':
      trackOnClick(id);
      openSubSettingsPage(openIntegrations);
      break;
    default:
      loadPageFromMenu(id, route, getLoggingProps);
  }
};
export const useMenuItemAttributesToProps = (features, featureFlags, currentSlug) => {
  const {
    close
  } = useIsMobileNavOpen();
  const pathname = usePagePathname();
  const pageName = usePageName();
  return _ref => {
    let {
      id,
      shouldHide,
      hasTopDivider,
      title,
      renderIcon: Icon,
      badgeText,
      permaId,
      shouldHideIconWhenExpanded,
      getLoggingProps,
      tooltip,
      ...obj
    } = _ref;
    let relativeHref;
    if (isRelativeMenuItem(obj)) {
      relativeHref = obj.getRelativeHref(currentSlug);
    } else {
      relativeHref = obj.getRoute(currentSlug);
    }
    return {
      key: id,
      hide: !features || shouldHide && shouldHide(features, featureFlags),
      hasTopDivider: hasTopDivider,
      href: relativeHref,
      onClick: () => {
        close();
        openPagesFromMenu(id, relativeHref, getLoggingProps);
      },
      active: isRelativeMenuItem(obj) ? obj.isActive({
        pathname,
        pageName
      }) : obj.activeOnPages.includes(pageName ?? ''),
      title,
      icon: _jsx(Icon, {}),
      tooltip,
      badgeText,
      permaId,
      isSmall: false,
      shouldHideIconWhenExpanded
    };
  };
};