export const combineValidators = validators => value => {
  const errors = validators.reduce((acc, fn) => {
    const errorMessage = fn(value);
    if (!errorMessage) {
      return acc;
    }
    return [...acc, errorMessage];
  }, []);
  return errors.length > 0 ? errors.join(' ') : undefined;
};
const trimmedString = data => {
  if (!data) {
    return '';
  }
  return String(data).trim();
};
export const notEmpty = data => {
  data = trimmedString(data);
  if (!data) {
    return 'This field is required.';
  }
  return undefined;
};
export const isUsername = data => {
  data = trimmedString(data);
  if (!data) {
    return undefined;
  }
  const regex = /^[a-z0-9\-\_\.]+$/;
  if (!regex.test(data)) {
    return 'Lowercase letters, numbers, underscores, dashes and periods only.';
  }
};
export const cannotEndWithAPeriod = data => {
  data = trimmedString(data);
  if (!data) {
    return undefined;
  }
  const regex = /\.$/;
  if (regex.test(data)) {
    return 'Cannot end with a period.';
  }
};
export const maxLength = max => data => {
  data = trimmedString(data);
  if (!data) return undefined;
  if (data.length > max) {
    return 'Cannot be more than ' + max + ' characters.';
  }
};