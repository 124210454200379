export function getGroupSort(_ref) {
  let {
    sort,
    getKey
  } = _ref;
  if (sort) return sort;
  return (a, b) => {
    const valA = getKey(a) || '';
    const valB = getKey(b) || '';
    if (valA === valB) return 0;
    return valA > valB ? 1 : -1;
  };
}