import { useIsNavHoverActive } from '../../layout/nav/useIsNavHoverActive';
import { CollapsibleHorizontalHeader } from './CollapsibleHorizontalHeader';
import { TeamSelect } from './TeamSelect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TeamNavigationHeader = _ref => {
  let {
    id,
    isNavExpanded,
    selectedTeamIds,
    setTeamIds
  } = _ref;
  const {
    deactivateOverride,
    activateOverride
  } = useIsNavHoverActive();
  return _jsx(CollapsibleHorizontalHeader, {
    id: id,
    title: "Teams",
    cmpRight: _jsx(TeamSelect, {
      onChange: setTeamIds,
      selectedValues: selectedTeamIds,
      onOpen: activateOverride,
      onClose: deactivateOverride
    }),
    isExpanded: isNavExpanded
  });
};
TeamNavigationHeader.displayName = "TeamNavigationHeader";