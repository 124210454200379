import moment from 'moment';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { getIsDueSoon, getIsOverdue } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DeadlineChip = _ref => {
  let {
    completedAt,
    deadline
  } = _ref;
  if (!deadline || Boolean(completedAt)) return null;
  const isOverdue = getIsOverdue(deadline);
  const isDueSoon = getIsDueSoon(deadline);
  const date = moment(deadline);
  const longDeadline = date.format(DATE_FORMAT.SHORT_DATE);
  const shortDeadline = date.format(DATE_FORMAT.SHORT_DATE_NO_YEAR);
  const tooltipText = isOverdue ? `This Story was due on ${longDeadline}.` : `This Story has a due date of ${longDeadline}.`;
  return _jsx(ChipTooltip, {
    content: _jsx(ChipTooltip.Text, {
      children: tooltipText
    }),
    children: _jsxs(Chip, {
      kind: isOverdue ? 'redInverted' : isDueSoon ? 'orangeInverted' : 'default',
      children: [_jsx(DeprecatedIconAdapter, {
        width: 18,
        children: _jsx(Chip.Icon, {
          icon: "Calendar",
          label: isOverdue ? 'Overdue deadline' : 'Deadline'
        })
      }), shortDeadline]
    })
  });
};
DeadlineChip.displayName = "DeadlineChip";