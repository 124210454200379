import { onKeyboardActivation } from '@clubhouse/shared/utils/keyboard';
export const getColumnSortProps = _ref => {
  let {
    isSortable,
    displayName,
    name,
    sortColumn,
    sortDirection,
    onSort
  } = _ref;
  if (!isSortable || !onSort || !name) return {};
  const isSorted = name === sortColumn;
  return {
    title: `Toggle sort by ${displayName === 'Story Priority' ? 'User Defined' : displayName || 'column'}`,
    'aria-sort': isSorted ? sortDirection : undefined,
    tabIndex: 0,
    onClick: () => {
      onSort(name);
    },
    onKeyDown: onKeyboardActivation(() => {
      onSort(name);
    })
  };
};