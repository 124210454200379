import { makeVar, useReactiveVar } from '@apollo/client';
const isNavHoveredVar = makeVar(false);
const isNavHoveredOverrideVar = makeVar(false);
const deactivateHover = () => isNavHoveredVar(false);
const activateHover = () => isNavHoveredVar(true);
const deactivateOverride = () => isNavHoveredOverrideVar(false);
const activateOverride = () => isNavHoveredOverrideVar(true);
export const useIsNavHoverActive = () => {
  const isNavHovered = useReactiveVar(isNavHoveredVar);
  const isNavHoveredOverride = useReactiveVar(isNavHoveredOverrideVar);
  const isHoverActive = isNavHoveredOverride || isNavHovered;
  return {
    isHoverActive,
    activateHover,
    deactivateHover,
    deactivateOverride,
    activateOverride
  };
};