import Image from '@clubhouse/assets/png/screenshots/example-created-vs-completed-chart-v3.png';
import { getCurrentPage, getSlugPath } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Description = _ref => {
  let {
    page
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx("p", {
      children: "The Created vs. Completed chart displays work created and completed within a period of time. Work is represented by either points or Story count."
    }), _jsxs("p", {
      children: ["The x-axis represents time.\xA0", page === 'reports' ? _jsx(_Fragment, {
        children: "The intervals can be changed by using the Group By filter at the top of the page. See Group By help for more details.\xA0"
      }) : _jsx(_Fragment, {
        children: "The Group By interval (Day, Week, or Month) is calculated based on the number of days in the date range.\xA0"
      }), usesIterations() && _jsx(_Fragment, {
        children: "Note that Group By Iteration is not supported by this chart.\xA0"
      }), "When Group By is set to Day, non-working days will be highlighted on the chart. Working days can be adjusted in your ", _jsx("a", {
        href: `${getSlugPath()}/settings/workspace`,
        children: "Workspace Settings"
      }), ".\xA0"]
    }), _jsx("p", {
      children: "The y-axis represents the number of points or Stories, which can be configured within the chart via the Sum Using dropdown on the top-right."
    }), _jsx("p", {
      children: "While Sum Using is set to Points, unestimated Stories and Stories estimated at 0 points are excluded from the total."
    }), _jsxs("ul", {
      children: [_jsxs("li", {
        children: [_jsx("strong", {
          children: "Stories Created"
        }), " measures points or Story counts for all Stories created during that interval."]
      }), _jsxs("li", {
        children: [_jsx("strong", {
          children: "Stories Completed"
        }), " measures points or Story counts for all Stories that moved to a completed workflow state during that interval."]
      })]
    })]
  });
};
export const Explanation = _ref2 => {
  let {
    isEmbedded = false
  } = _ref2;
  return _jsx(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''} Created vs. Completed Chart`,
    imgSrc: Image,
    description: _jsx(Description, {
      page: getCurrentPage()
    }),
    footer: _jsx(ReportsHelpFooter, {})
  });
};
Explanation.displayName = "Explanation";