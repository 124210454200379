import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useCallback, useEffect, useRef } from 'react';
import { Anchor } from '@clubhouse/shared/components/Anchor';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { EVENTS, logEvent } from '../monitoring';
import { startNavigationTransaction } from '../profiler/sentry';
import { getMatchForPath } from './paths';
import { IsModalRouteProvider } from './useIsModalRoute';
import { usePageLocation } from './usePageLocation';
import { PageNameProvider } from './usePageName';
import { PageParamsProvider } from './usePageParams';
import { PagePathnameProvider } from './usePagePathname';
import { PageSearchProvider } from './usePageSearch';
import { PushStateProvider } from './usePushState';
import { ReplaceStateProvider } from './useReplaceState';
import { RouteNameProvider } from './useRouteName';
import { WorkspaceSlugProvider } from './useWorkspaceSlug';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const NavigationProvider = props => {
  const {
    children,
    location,
    onReplaceState,
    onPushState
  } = props;
  const locationRef = useLatestRef(location);
  const pageLocation = usePageLocation(locationRef.current);
  const {
    addToastMessage
  } = useAddInvalidUrlLengthToast();
  const updateLocationRef = useCallback(getNextUrl => {
    const {
      href: nextHref = locationRef.current.href,
      state = locationRef.current.state
    } = getNextUrl(locationRef.current);
    if (!isValidLength(nextHref)) {
      addToastMessage(nextHref);
      return false;
    }
    const {
      pathname,
      href,
      search
    } = new URL(nextHref);
    locationRef.current = {
      pathname,
      href,
      search,
      state
    };
    return true;
  }, [addToastMessage, locationRef]);
  const replaceState = useCallback(getNextUrl => {
    if (updateLocationRef(getNextUrl)) {
      onReplaceState?.(locationRef.current);
      window.history.replaceState(locationRef.current.state, document.title, locationRef.current.href);
    }
  }, [locationRef, onReplaceState, updateLocationRef]);
  const pushState = useCallback(getNextUrl => {
    if (updateLocationRef(getNextUrl)) {
      startNavigationTransaction({
        pattern: getMatchForPath({
          path: locationRef.current.pathname
        })?.pattern,
        tags: {
          'L.fromHref': window.location.href,
          'L.fromPage': getMatchForPath({
            path: window.location.pathname
          })?.pageName ?? '__PAGE_NAME_NOT_FOUND__'
        }
      });
      onPushState?.(locationRef.current);
      window.history.pushState(locationRef.current.state, document.title, locationRef.current.href);
    }
  }, [locationRef, onPushState, updateLocationRef]);
  useReplaceUrlIfTooLong(location.href, replaceState);
  return _jsx(RouteNameProvider, {
    pathname: location.pathname,
    children: _jsx(PageNameProvider, {
      pathname: pageLocation.pathname,
      children: _jsx(PagePathnameProvider, {
        pathname: pageLocation.pathname,
        children: _jsx(PageSearchProvider, {
          search: pageLocation.search,
          children: _jsx(PageParamsProvider, {
            children: _jsx(IsModalRouteProvider, {
              children: _jsx(WorkspaceSlugProvider, {
                children: _jsx(ReplaceStateProvider, {
                  replaceState: replaceState,
                  children: _jsx(PushStateProvider, {
                    pushState: pushState,
                    children: children
                  })
                })
              })
            })
          })
        })
      })
    })
  });
};
NavigationProvider.displayName = "NavigationProvider";
const MAX_HREF_LENGTH = 8192;
const isValidLength = href => href.length <= MAX_HREF_LENGTH;
const truncateAndRemoveQueryString = href => href.slice(0, MAX_HREF_LENGTH - 1).replace(/\?.*/, '');
const useAddInvalidUrlLengthToast = () => {
  const {
    addToast
  } = useToast();
  const addToastMessage = useCallback(href => {
    const UrlTooLong = () => {
      return _jsxs(Spaced, {
        vertically: true,
        amount: 8,
        children: [_jsxs(ToastText, {
          children: ["URL can not exceed ", MAX_HREF_LENGTH, " characters. URL change reverted."]
        }), _jsx(Anchor, {
          href: href,
          children: "Copy this link to see the attempted change."
        })]
      });
    };
    addToast({
      kind: 'alert',
      Content: UrlTooLong
    });
    logEvent(EVENTS.Url_Too_Long);
  }, [addToast]);
  return {
    addToastMessage
  };
};
const useReplaceUrlIfTooLong = (href, replaceState) => {
  const {
    addToastMessage
  } = useAddInvalidUrlLengthToast();
  const previousHrefRef = useRef(href);
  useEffect(() => {
    if (!isValidLength(href)) {
      addToastMessage(href);
      replaceState(() => {
        const nextUrl = isValidLength(previousHrefRef.current) ? previousHrefRef.current : truncateAndRemoveQueryString(previousHrefRef.current);
        return {
          href: nextUrl,
          state: {}
        };
      });
    }
    previousHrefRef.current = href;
  }, [addToastMessage, href, replaceState]);
  return {
    isValidLength
  };
};