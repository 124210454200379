import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/zapier_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { loadScript } from '@clubhouse/shared/utils';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Zapier() {
  const readOnly = isReadOnly();
  useEffect(() => {
    loadScript('https://zapier.com/apps/embed/widget.js?services=clubhouse&html_id=zap-widget', {
      id: 'zap-js-embed'
    });
    return () => {
      document.querySelector('#zap-js-embed')?.remove();
    };
  }, []);
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.ZAPIER
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Zapier"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Zapier Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://zapier.com/apps/shortcut/integrations",
          children: "Zapier"
        }), " lets you to connect web apps to automate tasks. You can use events that occur in hundreds of apps to automatically create stories in ", BRAND.NAME, "."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_ZAPIER,
          children: "our instructions"
        }), " for how to configure this integration in your Zapier account."]
      }), _jsx("div", {
        id: "zap-widget"
      })]
    })]
  });
}
Zapier.displayName = "Zapier";