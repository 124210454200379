import "core-js/modules/es.array.push.js";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Url'], Url]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});

/**
 * storyStory is the controller used for the story page on mobile (full screen instead of dialog).
 */

import * as StoryPageTemplate from 'app/client/story/views/templates/storyPage.html';
import { Button } from 'components/shared/DeprecatedButton';
import { Problem } from 'components/shared/Problem';
import { StoryAttachments } from 'components/stories/StoryAttachments';
import { withWriteContext } from 'pages/write';
import AddExternalLinkController from '../../../core/js/controllers/addExternalLink';
import Autosize from '../../../core/js/modules/autosize';
import Dialog from '../../../core/js/modules/dialog';
import * as Event from '../../../core/js/_frontloader/event';
import Globals from '../../../core/js/_frontloader/globals';
import InPlaceTextareaController from '../../../core/js/controllers/inPlaceTextarea';
import Is from '../../../core/js/modules/is';
import LinkedFileModel from '../../../core/js/models/linkedFile';
import Log from '../../../core/js/modules/log';
import MessageController from '../../../core/js/controllers/message';
import ProblemController from '../../../core/js/controllers/problem';
import RecentlyViewedController from '../../../core/js/controllers/recentlyViewed';
import StoryController from '../../../core/js/controllers/story';
import StoryDialogController from '../../../core/js/controllers/storyDialog';
import StoryHistoryModel from '../../../core/js/models/storyHistory';
import StoryModel from '../../../core/js/models/story';
import Tests from 'app/client/core/js/modules/tests';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import WritePageController from '../../../write/js/controllers/writePage';
import { setPageTitle } from 'utils/page';
import { StoryDescriptionEditor } from 'components/stories/StoryDescriptionEditor';
const exports = {};
const PARENT_SELECTOR = '.story-page';
exports.bind = () => {
  StoryModel.on('updated ownersSaved storySaved latestVersionFetched newStorySaved storyMoved', () => {
    if (StoryModel.isNotBulk()) {
      exports.updateInPlace({
        force: true
      });
      StoryDialogController.update();
    }
  });
  Event.on('storyDeleted somebodyDeletedStory', story => {
    const path = Url.parsePath();
    if (story.id === path.story) {
      MessageController.alert('This story was just deleted. Redirecting you to the Stories page...');
      setTimeout(() => {
        Utils.redirect(Url.getSlugPath() + '/stories');
      }, 3000);
    } else {
      // We don't want to do updateInPlace here because the parent story wasn't actually updated by this deletion.
      exports.render();
    }
  });
  LinkedFileModel.on('fetched', () => {
    exports.updateInPlace({
      force: true
    });
  });
  Event.onlyOn(`storiesLookedUp.storyStory`, () => {
    exports.updateInPlace();
  });
};
exports.render = () => {
  exports.bind();
  const path = Url.parsePath();
  const story = StoryModel.getById(path.story);
  const permission = UserModel.getLoggedInUserPermission();
  const isReadOnly = Is.readOnly(permission);
  let html = '';
  if (!story) {
    View.renderComponent({
      containerSelector: '#story',
      component: Problem,
      props: {
        height: ProblemController.getContentHeight(ProblemController.EXTRA_OFFSETS.M),
        icon: React.createElement('span', {
          className: 'fa fa-exclamation-triangle'
        }),
        title: 'Story Not Found.',
        description: "This story was either deleted or you don't have access to it.",
        buttons: React.createElement(Button, {
          text: 'Go to Stories page',
          href: `${App.Url.getSlugPath()}/stories`,
          size: Button.sizes.xsmall
        })
      }
    });
    Event.trigger('pageRendered', 'MobileStory');
    return false;
  }
  StoryModel.normalize(story);
  StoryModel.normalizeStoryDetails(story);
  Autosize.destroyAll();
  html = StoryPageTemplate.render(_.assign({
    readOnly: isReadOnly
  }, story));
  $('#story').html(html);
  const element = $('.story');
  const mountNode = document.querySelector('#story-attachments');
  View.renderComponent({
    componentKey: `story-description-${story.id}`,
    component: StoryDescriptionEditor,
    mountNode: document.querySelector('#story-description-v2'),
    props: {
      id: story.id
    }
  });
  View.renderComponent({
    componentKey: 'storyStoryAttachements',
    mountNode,
    component: withWriteContext(StoryAttachments, WritePageController.getProps()),
    props: {
      StoryModel,
      Dialog,
      Tests,
      withRelatedDocs: true,
      story,
      addExternalLink: e => {
        AddExternalLinkController.open.call(e.target);
      },
      addFile: e => {
        StoryController.openFileUploadDropdown.call(e.target);
      },
      addStoryLink: e => {
        StoryController.addStoryLink.call(e.target);
      }
    }
  });
  exports.updateInPlace({
    force: true
  });
  InPlaceTextareaController.restoreSnapshot(element, story);
  Event.trigger('afterStoryDetailsRender', element);
  Utils.autoTabIndex(element);
  Event.trigger('resize');

  // Resize again a couple times since images might be loading.
  _.range(1, 5).forEach(n => {
    setTimeout(() => {
      Event.trigger('resize');
    }, n * 1000);
  });
  const highlighted = $('.highlighted-anchor');
  if (highlighted.length > 0) {
    const top = highlighted.offset().top - 20;

    // We use body for Chrome/Safari, html for Firefox.
    $('html, body').scrollTop(top);
  }
  setPageTitle(story.name + ` | ${BRAND.NAME}`);
  Event.trigger('pageRendered', 'MobileStory');
  RecentlyViewedController.addToRecentlyViewed('story', story.id);
  return false;
};
exports.updateInPlace = options => {
  options = options || {};
  if (Globals.get('isDragging')) {
    Log.debug('StoryPage: not updating, user is dragging.');
  } else if (Globals.get('isSaving') && options.force !== true) {
    Log.debug('StoryPage: not updating, something is mid-save.');
  } else {
    const path = Url.parsePath();
    const story = StoryModel.getById(path.story);
    const element = $(PARENT_SELECTOR);
    if (story && element.length > 0) {
      StoryModel.normalize(story);
      StoryController.updateComponents(story, element, options);
      StoryHistoryModel.fetch(story);
      StoryController.removeLoadingIndicator(element);
      setPageTitle(story.name + ` | ${BRAND.NAME}`);
    }
  }
};

// Overrides core/js/controllers/story.js
exports.showAllCommits = function () {
  $(this).closest('.show-all-commits').hide();
  $(this).closest('.commit').addClass('expanded-commits').find('.commit-link').show();
  Event.trigger('resize');
  return false;
};
export { exports as default };