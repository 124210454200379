import "core-js/modules/es.array.push.js";
import AutocompleteController from './autocomplete';
import Constants from '../modules/constants';
const exports = {};
exports.open = options => {
  const items = [];
  const palette = options.palette || Constants.PROJECT_COLORS;
  palette.forEach(color => {
    items.push({
      name: '<span class="color-box" style="background: ' + color + '"></span>',
      value: color
    });
  });
  AutocompleteController.open({
    className: 'color-picker',
    closeOnClicksOutside: true,
    activeValue: options.activeValue,
    items,
    target: options.context,
    targetSelector: options.targetSelector,
    onApply: options.onApply,
    width: 155
  });
  return false;
};
export { exports as default };