import { CanCreateKeyResultsDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
const CanCreateKeyResultsQuery = CanCreateKeyResultsDocument;
export const useCanCreateKeyResults = () => {
  const slug = useWorkspaceSlug();
  return useQuery(CanCreateKeyResultsQuery, {
    variables: {
      slug
    },
    fetchPolicy: 'cache-first'
  })?.data?.workspace2?.canCreateKeyResults === true;
};