import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { Slack } from 'pages/integrations/Slack';
const exports = {};
exports.INTEGRATION_TYPE = {
  TEAM: 'team',
  PROJECT: 'project',
  FIELD: 'field'
};
exports.title = `Slack Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/slack';
};
exports.open = () => {
  Settings.open({
    template: View.componentToTemplate(Slack, 'slack-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };