import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const renderOption = _ref => {
  let {
    name,
    value
  } = _ref;
  return _jsx("option", {
    value: value,
    children: name
  }, value || name);
};
renderOption.displayName = "renderOption";
export const Select = _ref2 => {
  let {
    value = '',
    label,
    hint,
    placeholder = 'Choose one...',
    options = [],
    validate,
    ...selectProps
  } = _ref2;
  return _jsxs("div", {
    className: "select",
    "data-validate": validate,
    children: [label && _jsxs("label", {
      htmlFor: selectProps.id,
      children: [label, " ", hint && _jsx("span", {
        className: "label-optional",
        children: hint
      })]
    }), _jsxs("div", {
      className: "styled-select",
      children: [_jsxs("select", {
        defaultValue: value,
        ...selectProps,
        children: [_jsx("option", {
          value: "",
          children: placeholder
        }, ""), options.map(renderOption)]
      }), _jsx("span", {
        className: "fa fa-caret-down"
      })]
    })]
  });
};
Select.displayName = "Select";
Select.Divider = {
  name: '----',
  disabled: true
};