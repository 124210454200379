import "core-js/modules/es.array.push.js";
import { render as renderConfetti } from 'app/client/core/js/modules/confetti';
export const celebrateBurst = e => {
  const {
    pageX: x,
    pageY: y
  } = e;
  renderConfetti({
    origin: {
      x: x - window.scrollX,
      y: y - window.scrollY
    }
  });
};
export const celebrateFall = () => {
  renderConfetti({});
};
export const celebrateBurstFromBottom = () => {
  const spread = 90;
  const force = 25;
  renderConfetti([{
    origin: {
      x: 0,
      y: window.innerHeight
    },
    angle: -45,
    spread,
    force
  }, {
    origin: {
      x: window.innerWidth,
      y: window.innerHeight
    },
    angle: -135,
    spread,
    force
  }]);
};
export const celebrateRandomBursts = function () {
  let numBursts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
  const qw = window.innerWidth / 4;
  const qh = window.innerHeight / 4;
  const bursts = [];
  for (let i = 0; i < numBursts; ++i) {
    bursts.push({
      origin: {
        x: Math.floor(Math.random() * qw * 2 + qw),
        y: Math.floor(Math.random() * qh * 2 + qh)
      },
      delay: i * Math.floor(Math.random() * 500)
    });
  }
  renderConfetti(bursts);
};