import * as Event from '../_frontloader/event';
const exports = {};
exports.init = () => {
  const xThreshold = 70;
  const yThreshold = 40;
  let scrollTop;
  $('body').swipe({
    allowPageScroll: 'auto',
    swipeStatus: (event, phase, direction, distance, duration, fingerCount, fingerData) => {
      if (phase === 'start') {
        scrollTop = window.scrollY;
      }
      if (phase === 'move') {
        const scrollOffset = Math.abs(scrollTop - window.scrollY);
        const yDist = Math.abs(fingerData[0].start.y - fingerData[0].end.y) + scrollOffset;

        // window.console.log(distance, fingerData[0].start.y, fingerData[0].end.y, yDist, scrollOffset, direction);
        if (distance > xThreshold && yDist < yThreshold) {
          if (direction === 'left') {
            Event.trigger('swipeLeft');
            return false;
          } else if (direction === 'right') {
            Event.trigger('swipeRight');
            return false;
          }
        }
      }
    },
    excludedElements: 'label, button, input, select, textarea, .noSwipe'
  });
  $(window).on('resize scroll', _.debounce(() => {
    $('body').css('min-height', $('.mobile-visible-column .bucket-content').height());
  }, 100));
};
export { exports as default };