import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
const exports = {
  Promises: {}
};
Collection.create('Signup', exports);
exports.isValid = obj => {
  if (obj && obj.uuid) {
    obj.id = obj.uuid;
  }
  return obj && obj.uuid && obj.allowed === true;
};
exports.fetch = (id, callback) => {
  Backend.get('/api/private/signups/' + id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.fetchSignup = (id, callback) => {
  Backend.get('/api/private/owner/organization2/signup', {
    company_id: id,
    excludeOrgHeader: false,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.updateSignup = (uuid, data, callback) => {
  Backend.put(`/api/private/signups/${uuid}/update`, {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.Promises.fetchPendingInvites = id => new Promise(resolve => {
  Backend.get(`/api/private/signups/${id}/invites`, {
    onComplete: res => {
      resolve(res);
    }
  });
});
exports.Promises.fetchAnyMatchingDomains = signupId => {
  return new Promise(resolve => {
    Backend.get(`/api/private/signups/${signupId}/auto-join-workspaces`, {
      onComplete: resolve
    });
  });
};
exports.Promises.deleteSignup = id => new Promise(resolve => Backend.delete(`/api/private/signups/${id}`, {
  onComplete: resolve
}));
exports.Promises.registerUser = function (signupId) {
  let endpoint = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'join';
  let data = arguments.length > 2 ? arguments[2] : undefined;
  return new Promise(resolve => Backend.put(`/api/private/signups/${signupId}/${endpoint}`, {
    data,
    onComplete: resolve
  }));
};
export { exports as default };