import { useCallback } from 'react';
import { useIsMobile, useToggleState } from '@clubhouse/shared/hooks';
import { useApplicationStateByKey, useUpdateApplicationState } from 'gql';
import { logDockVisibilityChanged } from '../logging/logging';
import { applicationStateKey, createPinnedState } from './pinnedState';
const useUpdatePinnedSettings = () => {
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState
  });
  return updates => {
    update(data => {
      return {
        ...data,
        pinnedSettings: {
          ...data.pinnedSettings,
          ...updates
        }
      };
    });
  };
};
export const useToggleIsExpanded = () => {
  const {
    data,
    loading
  } = useApplicationStateByKey({
    applicationStateKey,
    toExpectedType: createPinnedState
  });
  const updatePinnedSettings = useUpdatePinnedSettings();
  const isMobile = useIsMobile();
  const [state, toggle] = useToggleState();
  const isExpanded = Boolean(isMobile ? state : data?.pinnedSettings.isExpanded);
  const toggleIsExpanded = useCallback(() => {
    logDockVisibilityChanged({
      isExpanded: !isExpanded
    });
    if (isMobile) {
      toggle();
    } else {
      updatePinnedSettings({
        isExpanded: !isExpanded
      });
    }
  }, [isExpanded, isMobile, toggle, updatePinnedSettings]);
  return {
    loading,
    isExpanded,
    toggleIsExpanded
  };
};