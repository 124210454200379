import Logo from '@clubhouse/assets/png/third-party-logos/codestream_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function CodeStream() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.CODESTREAM
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "CodeStream"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "CodeStream Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Attach commits, branches, and merge requests to stories and use", ' ', _jsx(ExternalLink, {
          href: "https://www.codestream.com/",
          children: "CodeStream"
        }), " workflow triggers to automate your workflow."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.codestream.com/integrations/clubhouse",
          children: "Learn more"
        }), " on how the CodeStream integration works in Shortcut."]
      })]
    })]
  });
}
CodeStream.displayName = "CodeStream";