import Icons from '@clubhouse/shared/components/Icons';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ErrorState(_ref) {
  let {
    state
  } = _ref;
  return _jsx("div", {
    children: _jsxs(Text, {
      size: Text.SIZE.SMALL,
      children: [_jsx(Icons.Ban, {}), " We failed to load ", state, " epics."]
    })
  });
}
ErrorState.displayName = "ErrorState";