import { slugify } from '@clubhouse/shared/utils';
import { PAGE_NAMES, generatePathWithSlug, getCurrentOrigin, getPathForPage } from 'utils/navigation';
export const getHref = function (id) {
  let {
    name,
    includeDomain = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const sluggedName = slugify(name ?? '', {
    limit: 120
  });
  return (includeDomain ? getCurrentOrigin() : '') + generatePathWithSlug(getPathForPage(PAGE_NAMES.OBJECTIVE), {
    id,
    objectiveName: sluggedName || undefined
  });
};