import { getDisplayName } from 'data/typeConfigs';
export const itemToString = item => item ? item.value : '';
export const getSelectedItemsText = _ref => {
  let {
    type
  } = _ref;
  return _ref2 => {
    let {
      items
    } = _ref2;
    if (items.length === 0) return 'All';
    return `${items.length} ${getDisplayName({
      type,
      isSingular: items.length === 1
    })}`;
  };
};