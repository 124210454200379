import { QueryWorkspaceTeamSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { FILTER_ICON_WIDTH } from '@clubhouse/shared/components/FilterIcon';
import { GroupIcon } from '@clubhouse/shared/components/Icons';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQueryWithCurrentPermissionId } from 'components/gql/filters/hooks/useFilterOptionsQueryWithCurrentPermissionId';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { getSection } from 'components/gql/team/utils/sections';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const icon = _jsx(GroupIcon, {
  fill: "currentColor",
  width: FILTER_ICON_WIDTH
});
const QUERY_WORKSPACE_TEAM_SELECT = QueryWorkspaceTeamSelectDocument;
export function TeamsFilter(_ref) {
  let {
    entityType,
    filterHeading,
    paramName
  } = _ref;
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: paramName || 'group_ids',
    paramType: 'string[]'
  });
  return _jsx(TeamsSelect, {
    value: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    filterHeading: filterHeading
  });
}
TeamsFilter.displayName = "TeamsFilter";
export const TeamsSelect = _ref2 => {
  let {
    value,
    onChange,
    entityType,
    filterHeading
  } = _ref2;
  const fetchTeams = useFilterOptionsQueryWithCurrentPermissionId({
    query: QUERY_WORKSPACE_TEAM_SELECT,
    selectedIds: value
  });
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchTeams,
    getSection,
    selectedValues: value,
    filterType: Nouns.Team
  });
  return _jsx(GroupedOptionsFilter, {
    selectedValues: value,
    fetchSections: fetchSections,
    totalItemCount: totalItemCount,
    items: items,
    onChange: onChange,
    entityType: entityType,
    filterType: Nouns.Team,
    icon: icon,
    filterHeading: filterHeading
  });
};
TeamsSelect.displayName = "TeamsSelect";