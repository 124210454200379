import { useCallback, useMemo } from 'react';
import { pluralize } from '@clubhouse/shared/utils';
import { FIELD_ID } from 'components/shared/table/types';
import { getPointScale } from 'data/entity/estimateScale';
import { saveChanges } from 'data/entity/story';
import { storyEstimate } from 'utils/sort';
import { TableStoryEstimate } from './tableComponents';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EstimateFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  const availableEstimates = useMemo(() => {
    return getPointScale().map(point => {
      return {
        name: pluralize({
          count: point,
          singular: 'point',
          plural: 'points'
        }),
        value: point.toString()
      };
    });
  }, []);
  const save = useCallback(_ref2 => {
    let [estimate] = _ref2;
    saveChanges(entity.id, {
      estimate: estimate ? Number.parseInt(estimate, 10) : null
    });
  }, [entity]);
  return _jsx(TableStoryEstimate, {
    availableEstimates: availableEstimates,
    estimate: entity.estimate?.toString(),
    onChange: save
  });
}
EstimateFieldComponent.displayName = "EstimateFieldComponent";
export const EstimateField = () => ({
  name: FIELD_ID.ESTIMATE,
  displayName: 'Estimate',
  sort: storyEstimate,
  Component: EstimateFieldComponent,
  width: 90
});