import { slugify } from '@clubhouse/shared/utils';
import { PAGE_NAMES, generatePathWithSlug, getCurrentOrigin, getPathForPage } from 'utils/navigation';
export const getHref = function (id, name) {
  let includeDomain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const pathForPage = getPathForPage(PAGE_NAMES.STORY);
  const sluggedName = slugify(name ?? '', {
    limit: 120
  });
  return (includeDomain ? getCurrentOrigin() : '') + generatePathWithSlug(pathForPage, {
    id,
    storyName: sluggedName || undefined
  });
};