import { hasModifierKey } from '@clubhouse/shared/utils/keyboard';
import { findTable, getLine, getSelection } from '../utils';
const getTableRow = cols => `|${Array.from({
  length: cols
}, (_, i) => ` Cell${i + 1} `).join('|')}|`;
const getTable = cols => {
  const header = `|${Array.from({
    length: cols
  }, (_, i) => ` Header${i + 1} `).join('|')}|`;
  const divider = `|${Array.from({
    length: cols
  }, () => ` -------- `).join('|')}|`;
  return `${header}\n${divider}\n${getTableRow(cols)}\n${getTableRow(cols)}\n`;
};
const HEADER_LENGTH = `Header1`.length;
const CELL_LENGTH = `Cell1`.length;
export const tableCommand = function () {
  let {
    disableAutoTables
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'Table',
    icon: 'Table',
    fn(textarea, setValue) {
      const {
        before,
        after,
        selected,
        selectionStart
      } = getSelection(textarea);
      if (!selected) {
        setValue(`${before}${getTable(2)}${after}`, {
          selectionStart: selectionStart + 2,
          // Add 2 for pipe and space
          selectionEnd: selectionStart + HEADER_LENGTH + 2
        });
      }
    },
    events: {
      keydown: (e, textarea, setValue) => {
        if (disableAutoTables) return;
        if (e.key === 'Enter' && !hasModifierKey(e)) {
          // TODO: Don't include in initial version
          // const { value, selectionStart, selectionEnd, before, after } = getSelection(textarea);
          // if (selectionStart !== selectionEnd) return;
          // const { line, startOfLine } = getLine(value, selectionStart);
          // if (/^\|+$/.test(line)) {
          //   // Only pipe character(s): Expand table
          //   e.preventDefault();
          //   const numCols = line.split('').filter((char) => char === '|').length - 1;
          //   setValue(`${value.substring(0, startOfLine)}${getTable(numCols || 2)}${after}`, {
          //     selectionStart: startOfLine + 2, // Add 1 for space after pipe
          //     selectionEnd: startOfLine + HEADER_LENGTH + 2,
          //   });
          // } else if (/^\|.+\|$/.test(line)) {
          //   e.preventDefault();
          //   const numCols = line.split('').filter((char) => char === '|').length - 1;
          //   const newRow = getTableRow(numCols);
          //   setValue(`${before}\n${newRow}${after}`, {
          //     selectionStart: selectionStart + newRow.length + 1, // +1 for new line
          //     selectionEnd: selectionEnd + newRow.length + 1,
          //   });
          // }
        } else if (e.key === 'Tab' && !hasModifierKey(e)) {
          const {
            value,
            selectionStart,
            selectionEnd,
            after
          } = getSelection(textarea);
          if (selectionStart !== selectionEnd) return;
          const {
            line,
            startOfLine
          } = getLine(value, selectionStart);
          if (/^\|+$/.test(line)) {
            // Only pipe character(s): Expand table

            e.preventDefault();
            const existingTable = findTable(value, selectionStart);
            if (existingTable) {
              setValue(`${value.substring(0, startOfLine)}${getTableRow(existingTable.cols)}${after}`, {
                selectionStart: startOfLine + 2,
                selectionEnd: startOfLine + CELL_LENGTH + 2
              });
            } else {
              const numCols = line.split('').length;
              setValue(`${value.substring(0, startOfLine)}${getTable(numCols || 2)}${after}`, {
                selectionStart: startOfLine + 2,
                // Add 1 for space after pipe
                selectionEnd: startOfLine + HEADER_LENGTH + 2
              });
            }
          }
        }
      }
    }
  };
};