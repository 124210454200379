import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { useEffect } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryWillBeHiddenToast = () => {
  return _jsx(ToastText, {
    children: _jsx("strong", {
      children: `Switch to 'Everything' or 'Requested by Me' space to see new stories.`
    })
  });
};
StoryWillBeHiddenToast.displayName = "StoryWillBeHiddenToast";
export const useStoryWillBeHiddenToast = willBeHidden => {
  const {
    addToast,
    removeToast
  } = useToast();
  useEffect(() => {
    if (willBeHidden) {
      const toastId = addToast({
        timeout: 5000,
        kind: 'info',
        Content: StoryWillBeHiddenToast
      });
      return () => {
        removeToast(toastId);
      };
    }
  }, [willBeHidden, addToast, removeToast]);
};