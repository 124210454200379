import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Left = _ref => {
  let {
    fill,
    width,
    className
  } = _ref;
  return _jsx("svg", {
    className: className,
    width: width,
    height: width,
    viewBox: "0 0 5 8",
    fill: fill,
    children: _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.5 8.00049C4.36467 8.00049 4.24733 7.95116 4.148 7.85249L0.648 4.35249C0.549333 4.25382 0.5 4.13649 0.5 4.00049C0.5 3.86449 0.549333 3.74715 0.648 3.64849L4.148 0.148488C4.24667 0.0498216 4.364 0.000488281 4.5 0.000488281C4.636 0.000488281 4.75333 0.0498216 4.852 0.148488C4.95067 0.247155 5 0.364488 5 0.500488L5 7.50049C5 7.63582 4.95067 7.75316 4.852 7.85249C4.75333 7.95182 4.636 8.00115 4.5 8.00049Z"
    })
  });
};
Left.displayName = "Left";