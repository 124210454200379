import noop from 'lodash/noop';
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { useType } from '../Context';
const getDialogProps = (type, dialogType) => {
  switch (dialogType) {
    case 'archive':
      return {
        body: `Are you sure you want to archive this ${type}?`,
        buttonKind: Button.KIND.WARNING,
        cta: `Archive ${type}`,
        onClick: noop,
        title: `Are you sure?`
      };
    case 'delete':
      return {
        body: `Do you want to delete this ${type}? This action cannot be undone.`,
        buttonKind: Button.KIND.WARNING,
        cta: `Delete ${type}`,
        onClick: noop,
        title: `Are you sure?`
      };
    default:
      return {};
  }
};
export function useEntityConfirmationDialog() {
  const type = useType();
  const {
    openDialog: open,
    closeDialog
  } = useConfirmationDialogState();
  const openDialog = useCallback((dialogType, props) => {
    const dialogProps = getDialogProps(type, dialogType);
    open({
      ...dialogProps,
      ...props
    });
  }, [open, type]);
  return {
    openDialog,
    closeDialog
  };
}