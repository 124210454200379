import { NavigationProvider } from './NavigationProvider';
import { useClearBackgroundLocationStateOnPageRefresh } from './backgroundLocationState';
import { useLocationInternal } from './useLocationInternal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function StatefulNavigationProvider(_ref) {
  let {
    children
  } = _ref;
  useClearBackgroundLocationStateOnPageRefresh();
  const location = useLocationInternal();
  return _jsx(NavigationProvider, {
    location: location,
    children: children
  });
}
StatefulNavigationProvider.displayName = "StatefulNavigationProvider";