import { createNode } from '@clubhouse/shared/utils/dom';
import { useCallback } from 'react';
import { visuallyHiddenClass } from '../styles';
const BASE_ID = 'a11y-notifications';
const getOrCreateElement = level => {
  const id = `${BASE_ID}-${level}`;
  let el = document.getElementById(id);
  if (el) return el;
  el = createNode({
    id
  });
  el.setAttribute('aria-live', level);
  el.classList.add(visuallyHiddenClass);
  document.body.appendChild(el);
  return el;
};
export function useAssistiveNotification() {
  let levelDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'polite';
  const notifyFn = useCallback((msg, level) => {
    const el = getOrCreateElement(level || levelDefault);
    el.textContent = msg;
  }, [levelDefault]);
  return notifyFn;
}