import { Nouns } from '@clubhouse/shared/constants';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { PermissionsFilter } from '../permission/PermisssionsFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function OwnersFilter(_ref) {
  let {
    entityType,
    filterHeading
  } = _ref;
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'owner_ids',
    paramType: 'string[]'
  });
  return _jsx(PermissionsFilter, {
    value: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    filterHeading: filterHeading,
    filterType: Nouns.Owner
  });
}
OwnersFilter.displayName = "OwnersFilter";