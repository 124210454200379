/**
 * @description Takes the previous and next values for one field in the filter state.
 * If the user [de/]selected one item, then the difference between the lengths of `prev` and `next`
 * will be 1.
 * If the user [de/]selected multiple items at once, then the difference will be greater than 1.
 * This function should not be called when the user clears part of the filter state.
 */
const selectedMultiple = (prev, next) => Math.abs(next.length - prev.length) > 1;

/**
 * @description Takes labels for the different `interaction_type` values that are possible: the
 * user might clear a filter, select one new value, or select multiple values. (Note that it is
 * permitted to omit `selectMany`. In this case, the returned function will only decide between
 * `clear` and `selectOne`.)
 * @returns a function which, given `prev` and `next` values for the relevant field in filter state,
 * returns the appropriate `interaction_type` label.
 */
const deriveInteractionClassifier = _ref => {
  let {
    clear,
    selectOne,
    selectMany
  } = _ref;
  return function () {
    let prev = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let next = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    // if the filter is being set to the empty array, return the 'clear' message.
    if (next.length === 0) {
      return clear;
    }

    // if the invoker did not provide a value for selectMany, assume this filter
    // will only ever receive single-item selection updates.
    if (!selectMany) {
      return selectOne;
    }

    // If we have selectMany, we need to determine whether it's applicable.
    return selectedMultiple(prev, next) ? selectMany : selectOne;
  };
};
const SEGMENT_INTERACTION_DATA_BY_FILTER_TYPE = new Map([['project_ids', {
  filter_type: 'Projects',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Projects',
    selectOne: 'Select Project',
    selectMany: 'Select Projects By Workflow'
  })
}], ['epic_ids', {
  filter_type: 'Epics',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Epics',
    selectOne: 'Select Epic',
    selectMany: 'Select Epics by Epic State'
  })
}], ['milestone_ids', {
  filter_type: 'Milestones',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Milestones',
    selectOne: 'Select Milestone',
    selectMany: 'Select Milestones by Milestone State'
  })
}], ['label_ids', {
  filter_type: 'Labels',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Labels',
    selectOne: 'Select Label'
  })
}], ['iteration_ids', {
  filter_type: 'Iterations',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Iterations',
    selectOne: 'Select Iteration'
  })
}], ['iteration_group_ids', {
  filter_type: 'Iterations',
  // This one is special, because it covers the same data as `iteration_ids`, but only when
  // selecting more than one.
  interaction_type: 'Iterations Linked to Group'
}], ['team_ids', {
  filter_type: 'Teams',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Teams',
    selectOne: 'Select Team',
    selectMany: 'Select Teams by Workflow'
  })
}], ['owner_ids', {
  filter_type: 'Owners',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Owners',
    selectOne: 'Select Owner',
    selectMany: 'Select Owners by Team'
  })
}], ['story_types', {
  filter_type: 'Story Types',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Story Types',
    selectOne: 'Select Story Type'
  })
}], ['custom_field_value_ids', {
  filter_type: 'Custom Fields',
  interaction_type: deriveInteractionClassifier({
    clear: 'Clear Fields',
    selectOne: 'Select Custom Field',
    selectMany: 'Select Custom Fields'
  })
}]]);
export const constructFilterInteractionEvent = _ref2 => {
  let {
    key,
    prev = [],
    next = []
  } = _ref2;
  return Object.fromEntries([...Object.entries(SEGMENT_INTERACTION_DATA_BY_FILTER_TYPE.get(key) ?? {}).map(_ref3 => {
    let [k, v] = _ref3;
    return [k, v instanceof Function ? v(prev, next) : v];
  }), ['selection', next]]);
};
export const constructClearFiltersInteractionEvent = () => ({
  filter_type: 'Clear Filters',
  interaction_type: 'Clear Filters',
  selection: null
});