import { Chip } from '@clubhouse/shared/components/Chip';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DoneChip = _ref => {
  let {
    completed
  } = _ref;
  const themeName = useThemeName();
  if (!completed) return null;
  return _jsxs(Chip, {
    kind: themeName === 'light' ? 'green' : 'greenInverted',
    children: [_jsx(Chip.Icon, {
      icon: "CheckCircle",
      label: "Done"
    }), "Done"]
  });
};
DoneChip.displayName = "DoneChip";