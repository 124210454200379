import Boot from '../../../core/js/modules/boot';
import GoogleContactsController from '../controllers/googleContacts';
import Url from '../../../core/js/modules/url';
const exports = {};
const _init = () => {
  const currentPage = Url.getCurrentPage();
  if (currentPage === 'oauth' || currentPage === 'contacts') {
    GoogleContactsController.init();
  }
};
const _reInit = _init;
exports.boot = () => {
  Boot.initializePage(_init, _reInit);
};
export { exports as default };