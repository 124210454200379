import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const chartStyle = `
  .c3-bar {
    fill-opacity: .75;
    stroke-width: 0;
  }
  .c3-bar._expanded_ {
    fill-opacity: 1;
  }
  .c3-area {
    opacity: 0.75 !important;
  }
`;
export const TimeSpentInWFStateReportCard = props => _jsx(ReportCard, {
  ...props,
  title: "Time Spent In Workflow State",
  description: "The time completed stories spent in each workflow state.",
  chartStyle: chartStyle,
  Explanation: Explanation,
  Filters: Filters
});
TimeSpentInWFStateReportCard.displayName = "TimeSpentInWFStateReportCard";