import "core-js/modules/es.array.push.js";
import { nanoid } from 'nanoid';
import { FEATURE_TOGGLES as LD_FEATURES, getFeatureToggle, isInitialized as isLDInitialized, waitForFeatureTogglesResponse } from '@clubhouse/feature-toggles';
import CompanyModel from 'app/client/core/js/models/company';
import FeatureModel from 'app/client/core/js/models/feature';
import OrganizationModel from 'app/client/core/js/models/organization';
import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import { default as ProductModel } from 'app/client/core/js/models/product';
import { PRODUCT_FEATURES } from 'app/client/core/js/models/productFeatures';
import { ORGANIZATION_FEATURES } from 'app/client/core/js/modules/organizationFeatures';
const NS = '.FeaturesAndFlags';
const changeEvents = ['added', 'removed', 'updated'].map(e => e + NS).join(' ');
const ldResolver = feature => {
  const id = LD_FEATURES[feature];
  if (id) {
    return getFeatureToggle(id);
  }
};
let checkCount = 0;
function checkIsLDIinitialized() {
  if (isLDInitialized()) {
    waitForFeatureTogglesResponse().then(() => {
      Object.keys(LD_FEATURES).forEach(fireChangeEvent);
    });
  } else if (checkCount++ < 180) {
    setTimeout(checkIsLDIinitialized, 500);
  }
}
const organizationResolver = feature => {
  const id = ORGANIZATION_FEATURES[feature];
  if (id) {
    const org = OrganizationModel.getCurrent();
    return org && org[id];
  }
};
const productResolver = feature => {
  const entitlement = PRODUCT_FEATURES[feature];

  // If there's no entitlement, this is not a product feature, continue on to other resolvers
  if (!entitlement) {
    return false;
  }
  const plan = PaymentPlan2Model.getPlanForCurrentOrg();

  // Can't have a feature if you don't have a plan
  if (!plan) return false;
  return PaymentPlan2Model.hasEntitlement(plan, entitlement);
};
const resolve = feature => {
  return organizationResolver(feature) || productResolver(feature) || ldResolver(feature);
};

// this avoids an issue with module loading
export const init = () => {
  checkIsLDIinitialized();
  let fetchingFeatures = false;
  const fetchFeatures = () => {
    fetchingFeatures = true;
    FeatureModel.Promises.fetchAll().then(() => {
      Object.keys(ORGANIZATION_FEATURES).forEach(fireChangeEvent);
      Object.keys(PRODUCT_FEATURES).forEach(fireChangeEvent);
      fetchingFeatures = false;
    });
  };
  OrganizationModel.on(changeEvents, _ref => {
    let {
      id
    } = _ref;
    if (CompanyModel.getCurrentID() && !fetchingFeatures) {
      fetchFeatures();
    }
    const org = OrganizationModel.getCurrent();
    if (org && org.id === id) {
      Object.keys(ORGANIZATION_FEATURES).forEach(fireChangeEvent);
    }
  });
  if (OrganizationModel.all().length > 0 && CompanyModel.getCurrentID() && !fetchingFeatures) {
    fetchFeatures();
  }
  PaymentPlan2Model.on(changeEvents, () => {
    Object.keys(PRODUCT_FEATURES).forEach(fireChangeEvent);
  });

  // REMOVE when free and standard are no longer used
  ProductModel.on(changeEvents, () => {
    Object.keys(PRODUCT_FEATURES).forEach(fireChangeEvent);
  });
};
let listeners = [];
const fireChangeEvent = feature => {
  listeners.forEach(listener => {
    if (listener.feature === feature) {
      listener.fn(resolve(feature));
    }
  });
};
const ALL_FEATURES = {
  ...LD_FEATURES,
  ...PRODUCT_FEATURES,
  ...ORGANIZATION_FEATURES
};
export const FEATURES = Object.keys(ALL_FEATURES).reduce((features, featureKey) => {
  features[featureKey] = featureKey;
  return features;
}, {});
const isFeature = feature => !!FEATURES[feature];
export const addChangeListener = (feature, fn) => {
  if (isFeature(feature)) {
    const id = nanoid();
    listeners.push({
      id,
      feature,
      fn
    });
    return id;
  }
};
export const removeChangeListener = listenerId => {
  listeners = listeners.filter(l => l.id !== listenerId);
};

/**
 * Returns a boolean indicating if the feature is enabled or not.
 * Checks LaunchDarkly, Labs, features gated by plan type, and features that
 * can be enabled/disabled by the org.
 */
export const usesFeature = feature => {
  return isFeature(feature) && resolve(feature);
};