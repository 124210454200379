import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportVelocity = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsxs("svg", {
    width: width,
    height: width,
    viewBox: "0 0 46 46",
    fill: "none",
    children: [_jsxs("g", {
      clipPath: "url(#clip0)",
      children: [_jsx("path", {
        d: "M13.34 37.3367H6.36337C5.5967 37.3367 4.98337 36.7233 4.98337 35.9567V12.0367C4.98337 11.27 5.5967 10.6567 6.36337 10.6567H13.34C14.1067 10.6567 14.72 11.27 14.72 12.0367V35.9567C14.72 36.7233 14.1067 37.3367 13.34 37.3367Z",
        fill: "#FCEBA3",
        fillOpacity: "0.9"
      }), _jsx("path", {
        d: "M39.9433 37.3367H32.9666C32.2 37.3367 31.5866 36.7233 31.5866 35.9567V12.0367C31.5866 11.27 32.2 10.6567 32.9666 10.6567H39.9433C40.71 10.6567 41.3233 11.27 41.3233 12.0367V35.9567C41.3233 36.7233 40.71 37.3367 39.9433 37.3367Z",
        fill: "#FCEBA3",
        fillOpacity: "0.9"
      }), _jsx("path", {
        d: "M26.6032 37.3367H19.6266C18.8599 37.3367 18.2466 36.7233 18.2466 35.9567V21.9267C18.2466 21.16 18.8599 20.5467 19.6266 20.5467H26.6032C27.3699 20.5467 27.9832 21.16 27.9832 21.9267V35.9567C27.9832 36.7233 27.3699 37.3367 26.6032 37.3367Z",
        fill: "#FCE7E7"
      }), _jsx("path", {
        d: "M0.919949 25.1467C1.53328 24.7633 9.73662 16.33 9.73662 16.33L22.77 29.3633L36.0333 17.02L44.85 24.9933",
        stroke: "#2BA848",
        strokeWidth: "2",
        strokeMiterlimit: "10",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      })]
    }), _jsx("defs", {
      children: _jsx("clipPath", {
        id: "clip0",
        children: _jsx("rect", {
          width: "46",
          height: "46",
          fill: "white"
        })
      })
    })]
  });
};
ReportVelocity.displayName = "ReportVelocity";