export const formatSpace = space => {
  const {
    id,
    name,
    shared,
    author
  } = space;
  const description = shared && author ? `Shared by ${author.name}` : null;
  return {
    id,
    name,
    description
  };
};
export const formatSpaces = spaces => spaces.map(formatSpace);