// Stores the state between uploading a file and editing metadata.
import Collection from '../_frontloader/collection';
const exports = {};

/*

Uploader entity:

{
  id: "file_name.jpg",
  name: "file name",
  description: "",
  updated: false,
  raw: [native File object]
}

*/

Collection.create('Uploader', exports);
exports.EDITABLE_FIELDS = ['name', 'description'];
exports.mapNewFilesToEntities = files => {
  return _.map(files, file => {
    return exports.updateFile(file);
  });
};
exports.getRefreshedList = uploaderEntities => {
  return _.compact(_.map(uploaderEntities, entity => {
    return exports.get({
      id: entity.id
    });
  }));
};
exports.getEditableProperties = id => {
  const cached = exports.get({
    id
  });
  return cached ? _.reduce(exports.EDITABLE_FIELDS, (changes, field) => {
    changes[field] = cached[field];
    return changes;
  }, {}) : {};
};
exports.getUpdated = id => {
  return exports.get({
    id,
    updated: true
  });
};
exports.updateFile = (file, overrides) => {
  const id = file.name;
  overrides = overrides || {};
  exports.update({
    id,
    name: overrides.name || file.name,
    description: overrides.description || '',
    updated: !!overrides.updated,
    raw: file.raw || file
  });
  return exports.get({
    id
  });
};
exports.removeIfNotUpdated = file => {
  const cached = exports.get({
    id: file.id
  });
  if (cached && cached.updated === false) {
    exports.remove(cached);
  }
};
export { exports as default };