import { createRoot } from 'react-dom/client';
import { IterationStatus } from '@clubhouse/shared/types';
import { ShapesProvider } from '@useshortcut/shapes-ds';
import { Badges } from './Badges';
import iconIterationDone from '@clubhouse/assets/svg/iteration-done.svg';
import iconIterationStarted from '@clubhouse/assets/svg/iteration-started.svg';
import iconIterationUnstarted from '@clubhouse/assets/svg/iteration-unstarted.svg';
import { getById as getIterationById, getLabelForStatus, useIterationWithGroups } from '../../../../data/entity/iteration';
import { ThemeContextProvider } from '@clubhouse/shared/utils/theme';
import { iterationToSearchProps } from '../../../searchResults/utils';
import { htmlStringToElement } from '../helpers';
import { sortByCategory, sortByOrderedStatus } from './utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const iterationStatusOrderMap = {
  [IterationStatus.UNSTARTED]: 0,
  [IterationStatus.STARTED]: 1,
  [IterationStatus.DONE]: 2
};
const iconMap = {
  [IterationStatus.UNSTARTED]: {
    icon: iconIterationUnstarted,
    color: 'var(--iconGrayHoverColor)'
  },
  [IterationStatus.STARTED]: {
    icon: iconIterationStarted,
    color: 'var(--iconBlueColor)'
  },
  [IterationStatus.DONE]: {
    icon: iconIterationDone,
    color: 'var(--iconGreenColor)'
  }
};
const selectedTeam = `Selected Team's Iterations`;
const otherIterations = 'Other Iterations';
const getIterationCategory = (groupId, iteration) => {
  return iteration.group_ids?.includes(groupId) ? selectedTeam : otherIterations;
};
const getIcon = status => {
  const svg = htmlStringToElement(iconMap[status].icon);
  svg.setAttribute('fill', iconMap[status].color);
  return svg.outerHTML;
};
export const formatIterationByStory = groupId => iteration => {
  const {
    id,
    name,
    status
  } = iteration;
  const category = getIterationCategory(groupId, iteration);
  const statusLabel = getLabelForStatus(status);
  const icon = getIcon(status);
  return {
    id,
    name,
    category,
    status,
    statusLabel,
    icon,
    __preserveSVG: true
  };
};
const isValidIteration = iteration => Boolean(iteration);
export const formatIterationsByStory = groupId => iterations => iterations.filter(isValidIteration).map(formatIterationByStory(groupId)).sort(sortByOrderedStatus(iterationStatusOrderMap)).sort((a, b) => sortByCategory(b, a));
const IterationBadges = _ref => {
  let {
    id
  } = _ref;
  const iteration = useIterationWithGroups({
    id
  });
  const searchData = iterationToSearchProps(iteration);
  return _jsx(Badges, {
    data: searchData
  });
};
IterationBadges.displayName = "IterationBadges";
export const formatIteration = (_ref2, themeName) => {
  let {
    id
  } = _ref2;
  const stateObject = getIterationById(id);
  let badges = null;
  try {
    badges = div => {
      const root = createRoot(div);
      root.render(_jsx(ThemeContextProvider, {
        themeName: themeName,
        children: _jsx(ShapesProvider, {
          themeName: themeName,
          children: _jsx(IterationBadges, {
            id: id
          })
        })
      }));
    };
  } catch (e) {}
  return {
    label: stateObject?.formatted_name ?? '',
    url: stateObject?.app_url,
    __extraHTML: badges
  };
};
export const formatIterations = (iterations, themeName) => iterations.map(iteration => formatIteration(iteration, themeName));