import { StoryGroupByTechnicalAreaFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { TechnicalAreaWithIcon } from 'components/gql/technicalArea/TechnicalAreaWithIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupByTechnicalAreaFragment = StoryGroupByTechnicalAreaFragmentFragmentDoc;
export const TechnicalAreaGroupHeader = _ref => {
  let {
    technicalArea
  } = _ref;
  return _jsx(GroupByHeader, {
    entityType: Nouns.TechnicalArea,
    children: typeof technicalArea?.stringValue === 'string' && typeof technicalArea?.colorKey === 'string' ? _jsx(TechnicalAreaWithIcon, {
      spacing: 12,
      name: technicalArea.stringValue,
      color: technicalArea.colorKey
    }) : 'No Technical Area'
  });
};
TechnicalAreaGroupHeader.displayName = "TechnicalAreaGroupHeader";