import { getFocusableChildren } from '@clubhouse/shared/utils/focus';
const getSibling = (items, current, direction) => items[(items.length + items.indexOf(current) + direction) % items.length];
const focusNext = (items, el) => {
  const next = getSibling(items, el, 1);
  next.focus();
};
const focusPrev = (items, el) => {
  const prev = getSibling(items, el, -1);
  prev.focus();
};
const getAll = parent => getFocusableChildren(parent);
export const useKeyboardMenuNavigation = _ref => {
  let {
    parentRef,
    closeMenu,
    openSubMenu
  } = _ref;
  const onKeyDown = e => {
    if (!parentRef.current) return false;
    switch (e.key) {
      case 'ArrowUp':
        focusPrev(getAll(parentRef.current), e.currentTarget);
        break;
      case 'ArrowDown':
        focusNext(getAll(parentRef.current), e.currentTarget);
        break;
      case 'ArrowLeft':
        closeMenu?.();
        break;
      case 'ArrowRight':
        openSubMenu?.();
        break;
      default:
        return false;
    }
    return true;
  };
  return {
    onKeyDown
  };
};