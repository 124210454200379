import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { getCurrent as getCurrentCompany } from 'data/entity/company';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function OrganizationsDashboardHeader() {
  const canAddOrg = getCurrentCompany()?.closed !== true;
  return _jsxs("div", {
    className: "header",
    children: [_jsx("h2", {
      children: "Your Shortcut Organizations and Workspaces"
    }), canAddOrg ? _jsx("a", {
      href: "/signup-new-org",
      className: "action mini white",
      children: _jsx("span", {
        className: "align-center",
        children: _jsxs(DeprecatedIconAdapter, {
          fill: "var(--iconGreenColor)",
          children: [_jsx(Icon, {
            icon: "Add"
          }), " Add new organization"]
        })
      })
    }) : null]
  });
}
OrganizationsDashboardHeader.displayName = "OrganizationsDashboardHeader";