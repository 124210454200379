import Instrument from 'app/client/core/js/modules/instrument';
import { nanoid } from 'nanoid';
import { INTERNAL_LOG_EVENTS } from '../ownedEvents';
import { NOOP_MONITOR } from '../utils';
export const NAME = 'Internal Log';
export const shouldOwnEvent = event => INTERNAL_LOG_EVENTS.has(event);
export const shouldOwnNamedEvent = () => false;
export const shouldInitialize = () => true;
export const initialize = config => {
  Instrument.init(config);
  return {
    ...NOOP_MONITOR,
    startTimedBlock,
    logEvent
  };
};
const startTimedBlock = (namespace, name) => {
  const id = nanoid();
  Instrument.startIfUnstarted(id, {
    chPage: namespace,
    interactionName: name,
    eventType: 'interaction'
  });
  return {
    end: data => {
      Instrument.end(id, data ?? {});
    }
  };
};
const logEvent = async (event, data) => {
  await new Promise(resolve => {
    Instrument.log({
      data: {
        eventType: event,
        ...(data || {})
      },
      onComplete: resolve
    });
  });
};