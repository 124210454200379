import moment from 'moment';
import { getSmallestAllowedIntervalForDuration } from 'utils/charts';
const getStartDate = epic => {
  const start = epic?.started_at_override || epic?.planned_start_date || epic?.started_at;
  return start ? moment.utc(start) : null;
};
const getEndDate = epic => {
  if (epic.deadline) return moment.utc(epic.deadline);
  if (epic.completed_at) return moment.utc(epic.completed_at);
  return moment.utc();
};
export function getEpicReportData(epic) {
  const startDate = getStartDate(epic);
  if (!startDate) return null;
  let endDate = getEndDate(epic);
  if (startDate.isAfter(endDate)) endDate = startDate.clone();
  const duration = endDate.diff(startDate, 'days');
  const range = {
    start: startDate.startOf('day').format('YYYY-MM-DD'),
    end: endDate.endOf('day').format('YYYY-MM-DD')
  };
  const query = {
    epic_ids: [epic.id]
  };
  const interval = getSmallestAllowedIntervalForDuration(duration);
  return {
    startDate,
    endDate,
    duration,
    range,
    interval,
    query
  };
}