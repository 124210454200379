import Logo from '@clubhouse/assets/png/third-party-logos/linearb_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function LinearB() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.LINEAR_B
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "LinearB"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "LinearB Logo",
        invertOnDarkMode: true
      }), _jsx(Layout.Blurb, {
        children: "Fix process bottlenecks and measure performance with team-based metrics and a live timeline of Git activity correlated to your Shortcut stories."
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://linearb.io/blog/linearb-clubhouse-integration/",
          children: "Learn more"
        }), " on how LinearB integrates with Shortcut."]
      })]
    })]
  });
}
LinearB.displayName = "LinearB";