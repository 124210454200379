import { useMemo } from 'react';
import { useMultiInlineEditing } from '@clubhouse/shared/components/Table';
import { TableLabels } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import { denormalizeLabels, all as getAllLabels } from 'data/entity/label';
import { saveChanges } from 'data/entity/story';
import { labels } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LabelsFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const allLabels = useMemo(() => getAllLabels(), []);
  const [selectedLabels, handleChange, handleClose, {
    loading
  }] = useMultiInlineEditing(entity.labels, labels => saveChanges(entity.id, {
    labels: denormalizeLabels(labels)
  }));
  return _jsx(TableLabels, {
    isSelectionDisabled: loading,
    labels: allLabels,
    onChange: handleChange,
    onClose: handleClose,
    title: "Update Story Labels",
    value: selectedLabels
  });
};
LabelsFieldComponent.displayName = "LabelsFieldComponent";
export const LabelsField = () => ({
  name: FIELD_ID.LABELS,
  displayName: 'Labels',
  sort: labels,
  Component: LabelsFieldComponent,
  width: 295
});