import { Icon } from '@useshortcut/shapes-ds';
import { LoadingDots } from '@clubhouse/shared/animations';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { HeaderIconLink, HideWhenContainerIsSmall } from 'components/layout/header/HeaderIconLink';
import { useOptInSetState } from './useOptInSetState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OptOutButton = () => {
  const {
    setValue,
    isChangingState
  } = useOptInSetState();
  return _jsx(HeaderIconLink, {
    text: _jsx(HideWhenContainerIsSmall, {
      children: "Use the old Stories Page"
    }),
    onClick: () => setValue(false),
    icon: isChangingState ? _jsx(LoadingDots, {}) : _jsx(DeprecatedIconAdapter, {
      width: 20,
      fill: "currentColor",
      children: _jsx(Icon, {
        icon: "ChevronLeft"
      })
    }),
    disabled: isChangingState
  });
};
OptOutButton.displayName = "OptOutButton";