import { focusOutlineClass } from '@clubhouse/shared/styles';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function LoadMore(_ref) {
  let {
    loading,
    loadMore
  } = _ref;
  return _jsx("div", {
    className: "load-more-search-results-button",
    children: _jsxs("button", {
      onClick: loadMore,
      className: `${focusOutlineClass} action mini ${loading ? ' light-gray disabled' : 'flat-white'}`,
      children: [loading && _jsx("span", {
        className: "fa fa-spin fa-star"
      }), loading ? 'Loading' : 'Load more...']
    })
  });
}
LoadMore.displayName = "LoadMore";