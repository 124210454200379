import { makeVar, useReactiveVar } from '@apollo/client';
import { nanoid } from 'nanoid';
export const TOAST_BODY_CLASS = 'is-toast-message-visible';
const toasts = makeVar([]);
const _addToast = toast => {
  const renderId = nanoid();
  document.body.classList.add(TOAST_BODY_CLASS);
  if (toast.id) removeToast(toast.id);

  // renderId is always unique, which ensures that the timer is restarted when re-adding the same toast
  toasts(toasts().concat({
    ...toast,
    id: toast.id || renderId,
    renderId
  }));
  return renderId;
};
export const addToast = toast => _addToast(toast);

/**
 * We should figure out a better way to support the various variations of MessageController.
 * @deprecated
 */
export const addCustomLayoutToast = toast => _addToast({
  ...toast,
  customLayout: true
});
export const removeToast = id => {
  const isToastWithIdVisible = toasts().some(t => t.id === id);
  if (!isToastWithIdVisible) {
    return;
  }
  const remainingToasts = toasts().filter(t => t.id !== id);
  if (remainingToasts.length === 0) {
    document.body.classList.remove(TOAST_BODY_CLASS);
  }
  toasts(remainingToasts);
};
export const hasToast = id => toasts().some(t => t.id === id);
export const useToasts = () => {
  return useReactiveVar(toasts);
};
export const removeAllToasts = () => {
  document.body.classList.remove(TOAST_BODY_CLASS);
  toasts([]);
};