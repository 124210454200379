import { professionRoles, roleInputLabel } from '@clubhouse/shared/constants';
import { Select } from 'components/shared/Select';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const RoleSelect = () => _jsx("div", {
  "data-validate": "notEmpty",
  className: "form-input role",
  children: _jsx(Select, {
    id: "signup-role",
    name: "role",
    label: roleInputLabel,
    options: professionRoles,
    required: true
  })
});
RoleSelect.displayName = "RoleSelect";