import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { hasLabel } from 'data/entity/label';
import { getBlockedByCount } from 'data/entity/storyLink';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BlockerChip = () => {
  const story = useContext(StoryContext);
  if (!story) return null;
  const hasBlockerLabel = hasLabel(story, 'blocker');
  if (!story.blocker && !hasBlockerLabel) return null;
  const blockingCount = getBlockedByCount(story);
  return _jsx(StoryCardInternal.Chips.BlockerChip, {
    blockingCount: blockingCount,
    hasBlockerLabel: hasBlockerLabel
  });
};
BlockerChip.displayName = "BlockerChip";