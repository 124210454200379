import Logo from '@clubhouse/assets/png/third-party-logos/statushero_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function StatusHero() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.STATUS_HERO
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "StatusHero"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "StatusHero Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://statushero.com/",
          children: "Status Hero"
        }), " provides daily goals, check-ins, and activity logs for software teams."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_STATUS_HERO,
          children: "our instructions"
        }), ' ', "for how to configure this integration in your Status Hero account."]
      })]
    })]
  });
}
StatusHero.displayName = "StatusHero";