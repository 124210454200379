import Downshift from 'downshift';
import { useCallback } from 'react';
import { useIsSelectOpen } from '@clubhouse/shared/utils/isSelectOpen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function useStateReducer(_ref) {
  let {
    closeOnSelect,
    initialHighlightedIndex,
    isOpen: _isOpen
  } = _ref;
  const stateReducer = useCallback((state, changes) => {
    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
      delete changes.selectedItem;
    }
    const shouldClose = closeOnSelect || changes.selectedItem?.closeOnSelect;
    const isOpen = typeof _isOpen === 'boolean' ? _isOpen : !shouldClose;
    if (changes.type === Downshift.stateChangeTypes.keyDownEnter || changes.type === Downshift.stateChangeTypes.clickItem) {
      return {
        ...changes,
        selectedItem: {
          ...changes.selectedItem
        },
        isOpen,
        inputValue: state.inputValue,
        highlightedIndex: state.highlightedIndex
      };
    }
    if (changes.isOpen === false) {
      return {
        ...changes,
        highlightedIndex: initialHighlightedIndex,
        inputValue: ''
      };
    }
    return changes;
  }, [closeOnSelect, initialHighlightedIndex, _isOpen]);
  return stateReducer;
}
export function MultiDownshift(props) {
  const [, setIsSelectOpen] = useIsSelectOpen();
  const stateReducer = useStateReducer(props);
  const {
    onOpen,
    onClose,
    onFilter
  } = props;
  const onStateChange = useCallback(_ref2 => {
    let {
      isOpen,
      inputValue
    } = _ref2;
    setIsSelectOpen(!!isOpen);
    if (isOpen === true) {
      onOpen?.();
    } else if (isOpen === false) {
      onClose?.();
    }
    if (typeof inputValue === 'string') {
      onFilter?.(inputValue);
    }
  }, [onOpen, onClose, onFilter, setIsSelectOpen]);
  return _jsx(Downshift, {
    ...props,
    stateReducer: stateReducer,
    selectedItem: null,
    onStateChange: onStateChange,
    children: props.children
  });
}
MultiDownshift.displayName = "MultiDownshift";