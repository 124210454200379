import { TableGroupHeader, TableGroupHeaderButton, TableGroupHeaderLinks, TableGroupHeaderText } from '@clubhouse/shared/components/Table/components/TableGroupHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const isUrl = obj => obj.entityUrl !== undefined;
export const GroupByHeader = _ref => {
  let {
    children,
    hideCollapse,
    ...props
  } = _ref;
  return _jsxs(TableGroupHeader, {
    children: [_jsx(TableGroupHeaderButton, {
      hideCollapse: hideCollapse,
      children: _jsx(TableGroupHeaderText, {
        children: children
      })
    }), isUrl(props) ? _jsx(TableGroupHeaderLinks, {
      entityName: props.entityType.singular,
      url: props.entityUrl
    }) : props.aggregations]
  });
};
GroupByHeader.displayName = "GroupByHeader";