import { capitalize } from '@useshortcut/shapes-ds';
import { useContext } from 'react';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { TooltipLayout } from '@clubhouse/shared/components/Tooltip';
import { DensityContext } from '../../../../DensityContext';
import { ChipTooltip } from '../ChipTooltip';
import { getStatusIconFromType } from './utils';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const typeToProps = {
  bug: {
    icon: 'Bug',
    kind: 'red'
  },
  chore: {
    icon: 'Chore',
    kind: 'blue'
  },
  feature: {
    icon: 'Feature',
    kind: 'yellow'
  }
};
export const TypeChip = _ref => {
  let {
    publicId,
    type,
    workflowStateName,
    workflowStateType
  } = _ref;
  const density = useContext(DensityContext);
  return _jsx(ChipTooltip, {
    content: _jsx(TooltipLayout, {
      title: `${capitalize(type)} #${publicId}`,
      text: workflowStateName,
      children: _jsx(TooltipLayout.Slot, {
        name: "Icon",
        children: _jsx(DeprecatedIconAdapter, {
          width: 18,
          children: getStatusIconFromType(workflowStateType)
        })
      })
    }),
    children: _jsxs(Chip, {
      kind: typeToProps[type].kind,
      children: [_jsx(Chip.Icon, {
        icon: typeToProps[type].icon,
        label: typeToProps[type].icon
      }), ['standard', 'jumbo'].includes(density) && _jsxs(_Fragment, {
        children: ["#", publicId]
      })]
    })
  });
};
TypeChip.displayName = "TypeChip";