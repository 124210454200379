import { EVENTS, logEvent } from 'utils/monitoring';
import View from '../app/client/core/js/modules/view';
import { MemoryGameDialog } from '../components/shared/memoryGame';
import { celebrateRandomBursts } from './celebrate';
const logPlay = (event, result) => {
  logEvent(event, result);
};
export function startMemoryGame() {
  const el = document.createElement('div');
  document.body.appendChild(el);
  const {
    unmountComponent
  } = View.renderComponent({
    mountNode: el,
    component: MemoryGameDialog,
    props: {
      onWin: result => {
        logPlay(EVENTS.MemoryGame_Finished, result);
        celebrateRandomBursts(5);
      },
      close: () => {
        unmountComponent();
      },
      onClose: (finished, result) => {
        if (!finished) logPlay(EVENTS.MemoryGame_Aborted, result);
      }
    },
    componentKey: 'memory-game'
  });
}