import { useCallback, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { getFocusableChildren } from '../utils/focus';
import { insertIf as insertObjectIf } from '../utils/object';
import { useTabs } from './useTabs';
export function useControlledTabs(_ref) {
  let {
    tabs,
    initialTab = 0,
    onSelect,
    description,
    focusPanelOnSelect = true,
    disableEnhancedKeyboardNav
  } = _ref;
  const [activeTabIndex, setActiveTabIndex] = useState(initialTab);
  const [focus, setFocus] = useState({});
  const tabpanelRef = useRef(null);
  const handleSelect = useCallback(selectedIndex => {
    setActiveTabIndex(selectedIndex);
    if (typeof onSelect === 'function') onSelect(tabs[selectedIndex]);
    setFocus({});
  }, [tabs, onSelect]);
  useUpdateEffect(() => {
    if (focusPanelOnSelect && tabpanelRef.current) {
      const focusableElements = getFocusableChildren(tabpanelRef.current);
      (focusableElements[0] || tabpanelRef.current).focus();
    }
  }, [focusPanelOnSelect, focus]);
  const {
    getTabListProps,
    getTabPanelProps,
    getTabProps
  } = useTabs({
    onSelect: handleSelect,
    description,
    disableEnhancedKeyboardNav
  });
  return {
    tabListProps: getTabListProps(),
    tabPanelProps: {
      ...getTabPanelProps({
        key: tabs[activeTabIndex].key
      }),
      ref: tabpanelRef,
      tabIndex: -1
    },
    activeTab: tabs[activeTabIndex],
    tabs: tabs.map((tab, index) => {
      const active = index === activeTabIndex;
      return {
        tab,
        active,
        props: getTabProps({
          key: tab.key,
          value: index,
          active,
          ...insertObjectIf(!disableEnhancedKeyboardNav, {
            tabPanelRef: tabpanelRef
          })
        })
      };
    })
  };
}