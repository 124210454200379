import moment from 'moment';
/**
 * Converts an `IsoCalendarDate` to a `DateTimeUTCOffset` while preserving the right UTC offset,
 * rather than the UTC time for the date with the local timezone offset.
 *
 * @example
 * ```ts
 * convertIsoCalendarDateToDateTimeUTCOffset('2023-12-20'); // '2023-12-20T00:00:00.000-05:00'
 * ```
 */
// TODO: (@charpeni) We currently do not support `Day` scalar, let's clarify this function when we do.
export function convertIsoCalendarDateToDateTimeUTCOffset(date, utcOffset) {
  return moment(date).utc(true).utcOffset(utcOffset ?? '', true).toISOString(true);
}