import Async from './async';
import Iterate from './iterate';
import Log from './log';
const exports = {};
const IFRAME_WHITELIST = {
  'Google Analytics': iframe => iframe.id.indexOf('oauth2relay') === 0,
  Figma: iframe => iframe.src.indexOf('https://www.figma.com') === 0,
  FullStory: iframe => iframe.id.indexOf('FS-helper') === 0,
  Harvest: iframe => iframe.id.indexOf('harvest-iframe') === 0,
  'Google OAuth': iframe => iframe.id.indexOf('ssIFrame_google') === 0,
  'Google Docs': iframe => iframe.className.indexOf('picker-dialog') !== -1,
  'Pendo Designer': iframe => iframe.src.indexOf('https://app.pendo.io') === 0,
  Stripe: iframe => iframe.id.indexOf('stripe') !== -1,
  CommandBar: iframe => iframe.id === 'commandbar-editor',
  ReactRefresh: iframe => iframe.id === 'react-refresh-overlay'
};
exports.init = () => {
  Async.callbackTimer({
    speed: 250,
    max: 30000,
    fn: _checkForAndRemoveIFrames
  });
};
function _checkForAndRemoveIFrames() {
  const iframes = $('iframe');
  if (iframes.length === 0) {
    return false;
  }
  iframes.each(function () {
    if (!_iFrameIsInWhitelist(this)) {
      _removeIFrame(this, 'Removing iframe not in whitelist!');
    }
  });
}
function _iFrameIsInWhitelist(iframe) {
  /* The iframe needs an id and a src for us to identify its intention. */
  if (!iframe.id || !iframe.src) return true;
  let inWhitelist = false;
  Iterate.each(IFRAME_WHITELIST, whitelistFn => {
    if (whitelistFn(iframe)) {
      inWhitelist = true;
      return false;
    }
  });
  return inWhitelist;
}
function _removeIFrame(iframe, message) {
  Log.log(message, {
    className: iframe.className,
    id: iframe.id,
    src: iframe.src
  });
  $(iframe).remove();
}
export { exports as default };