import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import noop from 'lodash/noop';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useDeviceType } from '@clubhouse/shared/hooks';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { emptyObject } from '@clubhouse/shared/utils/emptyObject';
import { getFocusableChildren, isFocusable } from '@clubhouse/shared/utils/focus';
import { Popover } from '../Popover/Popover';
import { SearchInput } from '../SearchInput';
import { CloseButton } from './CloseButton';
import { DefaultLabelComponent } from './DefaultLabelComponent';
import { DefaultMenuComponent } from './DefaultMenuComponent';
import { DefaultTargetComponent } from './DefaultTargetComponent';
import { MultiDownshift } from './MultiDownshift';
import { SelectMenu } from './SelectMenu';
import { StyledContent, StyledSearch, StyledSearchInput, Wrapper } from './styledComponent';
import { CloseSelectProvider } from './useCloseSelect';
import { OpenSelectProvider } from './useOpenSelect';
import { WIDTH, widths } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const getDisabledToggleButtonProps = () => ({
  disabled: true
});
const itemToString = item => item?.value ?? '';
export const InternalSelect = props => {
  const {
    hideWhenReferenceHidden = false,
    isDisabled = false,
    isOpen,
    isSearchable,
    showSearchPlaceholder,
    isSelectionDisabled = false,
    items,
    totalItemCount = 0,
    label,
    LabelComponent = DefaultLabelComponent,
    maxHeight = 600,
    maxSelected,
    MenuComponent = DefaultMenuComponent,
    FooterComponent,
    onChange = noop,
    onClose = noop,
    selectedValues = emptyArray,
    TargetComponent = DefaultTargetComponent,
    targetComponentProps = emptyObject,
    unit,
    width = WIDTH.LARGE,
    children,
    onOpen,
    onBeforeOpen,
    onFilter,
    inputDebounceMs = 200,
    includeWrapper = true,
    hideClose = false,
    includeTypeahead = true,
    onEnterKey,
    ...attrs
  } = props;
  const scrollRef = useRef(null);
  const targetRef = useRef(null);
  const [scrollTopValue, setScrollTopValue] = useState(0);
  const handleChange = useCallback(selection => {
    if (selection.isDisabled) return;
    if (scrollRef.current) {
      setScrollTopValue(scrollRef.current.scrollTop);
    }
    if (selection.onClick) return;
    if (selection.selectedValues) {
      onChange(selection.selectedValues);
      return;
    }
    if (!selection.value) return;
    if (maxSelected === 1) {
      onChange([selection.value]);
      return;
    }
    const newValues = new Set(selectedValues);
    if (newValues.has(selection.value)) {
      newValues.delete(selection.value);
    } else if (!maxSelected || newValues.size < maxSelected) {
      newValues.add(selection.value);
    }
    onChange([...newValues]);
  }, [maxSelected, selectedValues, onChange]);
  useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollTopValue;
    }
  });
  const handleClose = useCallback(() => {
    const focusTrigger = () => {
      const trigger = targetRef.current;
      if (trigger) {
        // This is a bit hacky. The targetRef should ideally always be a button,
        // but currently that isn't the case since we allow custom targets that can add their own button.
        // TODO: Story for fixing this: [sc-179784]
        if (isFocusable(trigger)) {
          trigger.focus();
        } else {
          getFocusableChildren(trigger)[0]?.focus();
        }
      }
    };
    setScrollTopValue(0);
    if (scrollRef.current) scrollRef.current.scrollTop = 0;
    focusTrigger();
    onClose?.();
  }, [onClose]);
  const forceValue = onChange;
  const handleOnOuterClick = useCallback(stateAndHelpers => {
    stateAndHelpers.closeMenu();
  }, []);
  const handleBeforeOpen = useCallback(() => onBeforeOpen?.(), [onBeforeOpen]);
  const deviceType = useDeviceType();
  const shouldAutoFocus = deviceType === 'desktop';
  const multiDownshift = _jsx(MultiDownshift, {
    initialHighlightedIndex: -1,
    defaultHighlightedIndex: -1,
    closeOnSelect: maxSelected === 1 ? true : false,
    onChange: handleChange,
    onOuterClick: handleOnOuterClick,
    itemToString: itemToString,
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: handleClose,
    onFilter: onFilter,
    children: _ref => {
      let {
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        inputValue,
        highlightedIndex,
        openMenu,
        closeMenu,
        setHighlightedIndex
      } = _ref;
      const handleCloseButtonClick = e => {
        e.stopPropagation();
        closeMenu();
      };
      const handleTargetKeyDown = e => {
        if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && !isOpen) {
          e.nativeEvent.preventDownshiftDefault = true;
        } else if ((e.key === 'Enter' || e.key === ' ') && !isOpen) {
          handleBeforeOpen();
        }
      };
      const content = _jsx(CloseSelectProvider, {
        closeMenu: closeMenu,
        children: _jsx(MenuComponent, {
          getMenuProps: getMenuProps,
          children: _jsxs(StyledContent, {
            width: widths[width],
            maxHeight: maxHeight,
            children: [(isSearchable || label || !hideClose) && _jsxs(StyledSearch, {
              children: [label && _jsx(LabelComponent, {
                ...getLabelProps(),
                children: label
              }), !hideClose && _jsx(CloseButton, {
                onClick: handleCloseButtonClick
              }), isSearchable && _jsx(StyledSearchInput, {
                children: _jsx(SearchInput, {
                  autoFocus: shouldAutoFocus,
                  iconPosition: SearchInput.ICON_POSITION.RIGHT,
                  inputProps: getInputProps({
                    autoFocus: true,
                    onKeyDown: e => {
                      const input = e.target;
                      if (e.key === 'Enter' && onEnterKey) {
                        const shouldClose = onEnterKey(input.value);
                        if (shouldClose) closeMenu();
                      }
                    }
                  }),
                  value: !isOpen ? '' : undefined,
                  shape: SearchInput.SHAPE.SQUARE,
                  width: "100%",
                  onChangeDebounceMs: inputDebounceMs
                })
              })]
            }), _jsx(SelectMenu, {
              showSearchPlaceholder: showSearchPlaceholder,
              maxSelected: maxSelected,
              getItemProps: getItemProps,
              highlightedIndex: highlightedIndex,
              setHighlightedIndex: setHighlightedIndex,
              inputValue: inputValue,
              isSelectionDisabled: isSelectionDisabled,
              isSearchable: isSearchable,
              items: items,
              totalItemCount: totalItemCount,
              scrollRef: scrollRef,
              selectedValues: selectedValues,
              unit: unit,
              includeTypeahead: includeTypeahead
            }), FooterComponent]
          })
        })
      });
      const target = _jsx(OpenSelectProvider, {
        openMenu: openMenu,
        children: _jsx(TargetComponent, {
          ref: targetRef,
          getToggleButtonProps: isDisabled ? getDisabledToggleButtonProps : options => getToggleButtonProps({
            ...options,
            onKeyDown: handleTargetKeyDown,
            onMouseDown: handleBeforeOpen
          }),
          isOpen: isOpen,
          setValue: forceValue,
          value: selectedValues,
          items: items,
          ...targetComponentProps,
          children: children
        })
      });
      return _jsx("div", {
        ...attrs,
        children: _jsx(Popover, {
          hideWhenReferenceHidden: hideWhenReferenceHidden,
          placement: Popover.PLACEMENT.BOTTOM_LEFT,
          visible: isOpen,
          isSameWidth: width === WIDTH.FULL,
          content: content,
          children: target
        })
      });
    }
  });
  return includeWrapper ? _jsx(Wrapper, {
    children: multiDownshift
  }) : multiDownshift;
};
InternalSelect.displayName = 'InternalSelect';