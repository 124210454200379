import { DockedIterationFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref } from 'components/gql/iteration/links';
import { useIterationContextMenu } from 'utils/contextMenus/useIterationContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DockedIterationFragment = DockedIterationFragmentDoc;
export const isIteration = node => {
  return node?.__typename === 'Iteration';
};
export function DockedIteration(_ref) {
  let {
    node,
    index,
    onUnpinClicked
  } = _ref;
  const url = getHref(node.publicId);
  const contextMenuProps = useIterationContextMenu(node.publicId);
  return _jsx(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: _jsx(SizedIcon, {
      icon: "Iteration",
      fill: "interactive",
      size: 14
    }),
    title: _jsx(Title, {
      url: url,
      children: node.iterationName
    }),
    contextMenuProps: contextMenuProps
  });
}
DockedIteration.displayName = "DockedIteration";