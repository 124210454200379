import * as Event from '../../../core/js/_frontloader/event';
import FilterModel from '../../../core/js/models/filter';
const exports = {};
exports.setAllInactive = () => {
  FilterModel.each(x => {
    x.active = false;
  });
};
exports.disableAll = () => {
  exports.setAllInactive();
  FilterModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
export { exports as default };