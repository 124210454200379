import { QueryWorkspaceCurrentPermissionDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
const QUERY_WORKSPACE_CURRENT_PERMISSION = QueryWorkspaceCurrentPermissionDocument;
const useWorkspaceCurrentPermissionQuery = () => {
  const slug = useWorkspaceSlug();
  const {
    data,
    error
  } = useQuery(QUERY_WORKSPACE_CURRENT_PERMISSION, {
    variables: {
      slug: slug ?? ''
    },
    skip: !slug
  });
  return {
    data,
    error
  };
};
export const useProfile = () => {
  const {
    data
  } = useWorkspaceCurrentPermissionQuery();
  return data?.workspace2?.currentUserPermission || null;
};