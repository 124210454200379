import { initializeFeatureTogglesWithAnonymousUser, initializeFeatureTogglesWithUser } from '@clubhouse/feature-toggles';
import { matchPath } from 'react-router-dom';
import { isLaunchDarklyEnabled, isLaunchDarklyProdEnv } from 'utils/devTools';
import { getDeviceType } from 'utils/device';
import { isLoggedInPage } from 'utils/is';
import { error } from 'utils/log';
import { PAGE_NAMES, getCurrentPage, getCurrentSlug, getPathForPage } from 'utils/navigation';
const shouldInitialize = () => isLaunchDarklyProdEnv() || isLaunchDarklyEnabled();
const getCurrentProfileForPathname = _ref => {
  let {
    user,
    slug
  } = _ref;
  if (!slug) {
    return {};
  }
  if (isOrganizationPage()) {
    const profile = findProfileForOrganization2Slug({
      user,
      slug
    });
    if (!profile) {
      return {};
    }
    const {
      organization2
    } = profile;
    return {
      current_org2_slug: organization2.slug,
      // current_slug is deprecated because ambiguous whether it is org2 or work2.
      // (See comment below)
      current_slug: organization2.slug,
      current_company_id: organization2.id,
      current_company_created_at: organization2.created_at
    };
  }
  const {
    profile,
    permission
  } = findProfileAndPermissionForWorkspace2Slug({
    user,
    slug
  }) || {};
  if (!profile || !permission) {
    return {};
  }
  const {
    organization2
  } = profile;
  const {
    workspace2
  } = permission;
  return {
    // Added June 2023
    current_wksp2_slug: workspace2.url_slug,
    current_org2_slug: organization2.slug,
    // These are old names (org = workspace2) because these are old fields.
    current_org_id: workspace2.id,
    current_org_name: workspace2.name,
    current_org_created_at: workspace2.created_at,
    // current_slug is deprecated because ambiguous whether it is org2 or work2.
    // The FE has used organization2, but the BE used workspace2 until June 2023.
    // Please use current_wksp2_slug or current_org2_slug above.
    current_slug: organization2.slug,
    // These are old names (company = organization2) because these are old fields.
    current_company_id: organization2.id,
    current_company_created_at: organization2.created_at
  };
};
const userHasAccessToPath = _ref2 => {
  let {
    user,
    slug
  } = _ref2;
  if (!slug) {
    return true;
  }
  if (isOrganizationPage()) {
    return Boolean(findProfileForOrganization2Slug({
      user,
      slug
    }));
  }
  return Boolean(findProfileAndPermissionForWorkspace2Slug({
    user,
    slug
  }));
};
const isOrganizationPage = () => Boolean(matchPath(window.location.pathname, {
  path: getPathForPage(PAGE_NAMES.ORGANIZATION)
}));
const findProfileForOrganization2Slug = _ref3 => {
  let {
    user,
    slug
  } = _ref3;
  return user.profiles.find(profile => profile.organization2.slug === slug);
};
const findProfileAndPermissionForWorkspace2Slug = _ref4 => {
  let {
    user,
    slug
  } = _ref4;
  for (const profile of user.profiles) {
    for (const permission of profile.permissions) {
      if (permission.workspace2.url_slug === slug) {
        return {
          profile,
          permission
        };
      }
    }
  }
};
const getUserProperties = _ref5 => {
  let {
    user
  } = _ref5;
  return {
    emails: user.emails,
    profiles: user.profiles,
    id: user.id,
    name: user.name,
    created_at: user.created_at,
    reports_group_by: user.reports_group_by,
    story_dialog_expanded: user.story_dialog_expanded,
    profession: user.profession,
    entity_type: user.entity_type,
    deactivated: user.deactivated,
    app_theme: user.app_theme,
    impersonation_mode: user.impersonation_mode || false
  };
};
const shouldInitializeWithAnonymousUser = () => {
  const pageName = getCurrentPage();

  /*  We can remove `Url.isCurrentPage('invite-link')` in the future
   *   when 'new-invite-page-images' feature toggle is no longer is use.
   *   (also the `Url.isCurrentPage('invites')` )
   */
  return [PAGE_NAMES.SIGNUP, 'invite-link', 'invites', 'verify-email'].includes(pageName);
};
export const initLaunchDarkly = async _ref6 => {
  let {
    getUser
  } = _ref6;
  if (!shouldInitialize()) {
    return;
  }
  const slug = getCurrentSlug();
  let user;
  try {
    if (isLoggedInPage()) {
      user = await getUser();
      if (user && user.id && userHasAccessToPath({
        user,
        slug
      })) {
        const options = {
          user: getUserProperties({
            user
          }),
          currentProfile: getCurrentProfileForPathname({
            user,
            slug
          }),
          device: getDeviceType()
        };
        await initializeFeatureTogglesWithUser(window._LAUNCHDARKLY_CLIENT_ID, options);
      }
      return;
    }
    if (shouldInitializeWithAnonymousUser()) {
      const options = {
        device: getDeviceType()
      };
      await initializeFeatureTogglesWithAnonymousUser(window._LAUNCHDARKLY_CLIENT_ID, options);
      return;
    }
  } catch (e) {
    error(e, {
      slug,
      userId: user?.id ?? 'undefined',
      pathname: window.location.pathname
    });
  }
};