import { EVENTS, logEvent } from 'utils/monitoring';
export const logNavigateToItem = () => logEvent(EVENTS.Interaction_PinnedItems_NavigateToItem);
export const logAddItem = _ref => {
  let {
    typename
  } = _ref;
  return logEvent(EVENTS.Interaction_PinnedItems_AddItem, {
    typename
  });
};
export const logRemoveItem = _ref2 => {
  let {
    typename
  } = _ref2;
  return logEvent(EVENTS.Interaction_PinnedItems_RemoveItem, {
    typename
  });
};
export const logDockVisibilityChanged = _ref3 => {
  let {
    isExpanded
  } = _ref3;
  return logEvent(EVENTS.Interaction_PinnedItems_DockVisibilityChanged, {
    isExpanded
  });
};