import "core-js/modules/es.array.push.js";
import ActivityModel from './activity';
import ApplicationState from '../modules/applicationState';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Iterate from '../modules/iterate';
import StoryModel from './story';
import Utils from '../modules/utils';
import Tests from '../modules/tests';
const exports = {};

/*

Example Story History Object:

  {
    "id":"5877f936-b6e7-41c6-8fb3-2f0d96e10512",
    "changed_at":"2017-01-12T21:46:30Z",
    "primary_id":14753,
    "member_id":"56d8a841-0b1d-4b0e-aeef-570e2fd89b76",
    "actions":[
      {
        "id":14753,
        "action":"create"
      },
      {
        "id":500012715,
        "action":"create"
      }
    ],
    "references":[
      {
        "description":"",
        "entity_type":"story",
        "name":"Allow Milestones to Have Labels",
        "type":"feature",
        "epic_id":14162,
        "requested_by_id":"56d8a841-0b1d-4b0e-aeef-570e2fd89b76",
        "workflow_state_id":500005575,
        "follower_ids":["56d8a841-0b1d-4b0e-aeef-570e2fd89b76"],
        "id":14753,
        "subject_story_link_ids":[500012715],
        "project_id":10001053886
      },
      {
        "entity_type":"story-link",
        "id":500012715,
        "subject_id":14753,
        "verb":"blocks",
        "object_id":14752
      },
      {
        "entity_type":"project",
        "id":10001053886,
        "name":"Frontend"
      },
      {
        "entity_type":"epic",
        "id":14162,
        "name":"Milestones Backlog"
      }
    ]
  }

*/

const WHITELISTS = {
  ALL_STORY_CHANGES: {
    story: {
      create: true,
      update: ['archived', 'commit_ids', 'custom_field_value_ids', 'deadline', 'description', 'epic_id', 'estimate', 'follower_ids', 'group_id', 'label_ids', 'name', 'owner_ids', 'project_id', 'requested_by_id', 'type', 'workflow_state_id']
    },
    'story-link': {
      create: true,
      delete: true
    },
    'story-task': {
      create: true,
      delete: true,
      update: ['complete', 'description', 'owner_ids']
    },
    branch: {
      create: true,
      delete: true
    },
    'pull-request': {
      open: true,
      close: true,
      merge: false
    }
  },
  IMPORTANT_STORY_CHANGES_ONLY: {
    story: {
      create: true,
      update: ['archived', 'workflow_state_id']
    },
    'story-task': {
      update: ['complete']
    },
    branch: {
      create: true,
      delete: true
    },
    'pull-request': {
      open: true,
      close: true,
      merge: false
    }
  }
};
let _isFetching = [];
exports.resetIsFetching = () => {
  _isFetching = [];
};
Collection.create('StoryHistory', exports);
exports.on('beforeAdd.collection beforeUpdate.collection', change => {
  exports._groupActivity(change);
});
exports.isValid = obj => {
  return Utils.hasKeys(obj, ['id', 'actions']);
};
exports.normalize = change => {
  const changed = moment(change.changed_at);
  change.timestamp = changed.valueOf();
  change.day = changed.format('YYYYMMDD');
};
const getLastHistoryItem = story => {
  return _.last(_.sortBy(exports.filter(change => {
    return change.story_id === story.id;
  }), 'timestamp'));
};
exports._groupActivity = () => /*story*/{
  // exports._resetStoryActivityGrouping(story);
  // exports._setStoryActivityGrouping(story);
  // exports._detectEmptyChanges(story);
};
exports.getActivity = story => {
  const whiteListName = exports.getWhiteList();
  const whitelist = getWhiteListItems(whiteListName);
  return exports._filterByWhitelist(whitelist, story.id);
};
exports._filterByWhitelist = (whitelist, story_id) => {
  return _.sortBy(_.map(exports.filter(change => {
    return story_id && change.story_id === story_id;
  }), change => {
    const actions = _.filter(change.actions, action => {
      return ActivityModel.actionIsWhitelisted(whitelist, action, change);
    });
    return _.assign({}, change, {
      actions
    });
  }), 'timestamp');
};
const getWhiteListItems = whiteListName => {
  const whiteList = _.cloneDeep(whiteListName === 'IMPORTANT' ? WHITELISTS.IMPORTANT_STORY_CHANGES_ONLY : WHITELISTS.ALL_STORY_CHANGES);
  if (Tests.usesIterations()) {
    whiteList.story.update = [...whiteList.story.update, 'iteration_id'];
  }
  return whiteList;
};
exports.getWhiteList = () => {
  return ApplicationState.get('StoryActivityWhitelist') || 'IMPORTANT';
};
exports.setWhiteList = (story, name) => {
  ApplicationState.set('StoryActivityWhitelist', name);
  exports._setStoryActivityGrouping(story);
};
exports._alreadyFetchingActivity = story => {
  return _.includes(_isFetching, story.id);
};
exports._doneFetchingActivity = story => {
  _isFetching = _.without(_isFetching, story.id);
};
exports._markAsFetchingActivity = story => {
  _isFetching.push(story.id);
};

// exports._getUpdate = function () {
//   return '';
// };

exports._resetStoryActivityGrouping = () => /*story*/{
  // exports.each(function (change) {
  //   if (change.story_id === story.id) {
  //     change.was_grouped = false;
  //     change.was_empty_change = false;
  //     if (change.grouped_changes) {
  //       delete change.grouped_changes;
  //     }
  //   }
  // });
};
exports._setStoryActivityGrouping = () => /*story*/{
  // var changes = exports.getActivity(story);
  //
  // Iterate.each(changes, function (change, i) {
  //   var next = changes[i + 1];
  //
  //   if (next && exports._changesAreGroupable(change, next)) {
  //     change.was_grouped = true;
  //     next.grouped_changes = next.grouped_changes || change.grouped_changes || [];
  //     delete change.grouped_changes;
  //     next.grouped_changes.push(change);
  //   }
  // });
};
exports._detectEmptyChanges = () => /*story*/{
  // var emptyChangeFound = false;
  // var changes = exports.getActivity(story);
  //
  // Iterate.each(changes, function (change) {
  //   var update = exports._getUpdate(change);
  //
  //   // Don't display net unchanged story names and descriptions.
  //   if (change.grouped_changes) {
  //     var startingState = change.grouped_changes[0].values.old;
  //     var endingState = change.values.new;
  //
  //     Iterate.each(['name', 'description'], function (prop) {
  //       if (update === prop && (startingState[prop] || '').trim() === (endingState[prop] || '').trim()) {
  //         change.was_empty_change = true;
  //         emptyChangeFound = true;
  //       }
  //     });
  //   }
  //
  //   // Don't display empty task creations or deletions.
  //   if ((change.scope === 'task/create' || change.scope === 'task/delete') && !change.values.description) {
  //     change.was_empty_change = true;
  //     emptyChangeFound = true;
  //   }
  // });
  //
  // if (emptyChangeFound) {
  //   exports._setStoryActivityGrouping(story);
  // }
};
exports._changesAreGroupable = () => /*first, second*/{
  // var firstUpdate = exports._getUpdate(first);
  // var secondUpdate = exports._getUpdate(second);
  //
  // return (
  //     first.scope === second.scope ||
  //     first.primary_type === 'story_link' && second.primary_type === 'story_link'
  //   ) &&
  //   first.subtype !== 'story-workflow-state-move' &&
  //   first.subtype !== 'story-branch-update' &&
  //   first.subtype !== 'story-commit-update' &&
  //   first.subtype === second.subtype &&
  //   first.user && second.user &&
  //   first.user.id === second.user.id &&
  //   firstUpdate === secondUpdate &&
  //   (
  //     firstUpdate === 'archived?' || // force archive updates to be grouped
  //     firstUpdate === 'description' || // force description updates to be grouped
  //     ActivityModel.getUpdateVerb(first, firstUpdate) === ActivityModel.getUpdateVerb(second, secondUpdate)
  //   ) &&
  //   moment(second.changed_at).diff(first.changed_at, 'days') < 7;
};
exports.flattenChanges = () => /*change*/{
  // var changes = [];
  // var mapFn = function (change) {
  //   var mapped = {};
  //   Iterate.each(_.without(_.keys(change), 'grouped_changes'), function (key) {
  //     mapped[key] = change[key];
  //   });
  //
  //   return mapped;
  // };
  //
  // Iterate.each(change.grouped_changes || [], function (grouped_change) {
  //   changes.push(mapFn(grouped_change));
  // });
  // changes.push(mapFn(change));
  //
  // return changes;
};
exports.fetch = story => {
  // This can happen if hitting /story/:id route where story isn't found.
  if (!StoryModel.isValid(story)) {
    return false;
  }
  if (exports._alreadyFetchingActivity(story)) {
    return false;
  }
  const lastChange = getLastHistoryItem(story);
  let pagination = '';
  if (lastChange) {
    pagination = '/after/' + lastChange.id;
  }
  exports._markAsFetchingActivity(story);
  Backend.get('/api/private/stories/' + story.id + '/history' + pagination, {
    onComplete: res => {
      exports._doneFetchingActivity(story);
      // Let's get a fresh copy of the story in case it's changed.
      story = StoryModel.getById(story.id);

      // As long as the story wasn't deleted...
      if (story) {
        if (_.isArray(res) && res.length > 0) {
          story.has_history = false;
          Iterate.each(res, change => {
            if (change) {
              change.story_id = story.id;
              exports.updateIfValid(change);
            }
          });
          exports._groupActivity(story);
        } else {
          story.has_history = false;
        }
        Event.trigger('storyHistoryFetched', story);
      }
    }
  });
};
export { exports as default };