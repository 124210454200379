import uniqueId from 'lodash/uniqueId';
import { useEffect, useState } from 'react';
import { useContextMenuState } from './useContextMenuState';
export function useContextMenu(getMenu) {
  const [id] = useState(() => uniqueId());
  const {
    register
  } = useContextMenuState();
  useEffect(() => {
    if (getMenu) return register(id, getMenu);
  }, [register, id, getMenu]);
  return {
    id,
    props: {
      'data-cm': id
    }
  };
}