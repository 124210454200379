import { WorkflowStateIcon } from 'components/shared/WorkflowStateIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOptionalConfigForGroupKey = {
  selected: null,
  backlog: allowBulkSelect => ({
    sectionId: 'backlog',
    sectionTitle: 'Backlog',
    allowBulkSelect
  }),
  unstarted: allowBulkSelect => ({
    sectionId: 'unstarted',
    sectionTitle: 'Unstarted',
    allowBulkSelect
  }),
  started: allowBulkSelect => ({
    sectionId: 'started',
    sectionTitle: 'Started',
    allowBulkSelect
  }),
  done: allowBulkSelect => ({
    sectionId: 'done',
    sectionTitle: 'Done',
    allowBulkSelect
  })
};
export const getSection = (groupKey, options, _ref) => {
  let {
    hasMultiplePages,
    isFiltered,
    maxSelected
  } = _ref;
  return {
    ...getOptionalConfigForGroupKey[groupKey]?.(!hasMultiplePages && !isFiltered && maxSelected !== 1),
    items: options.map(_ref2 => {
      let {
        node
      } = _ref2;
      const {
        type,
        id,
        name
      } = node;
      return {
        value: id,
        name,
        Icon: () => _jsx(WorkflowStateIcon, {
          type: type
        }),
        additionalData: node
      };
    })
  };
};