import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import Icons from 'components/shared/Icon';
import * as GroupData from 'data/entity/group';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const GroupMismatchIndicator = _ref => {
  let {
    messages
  } = _ref;
  return _jsx(Tooltip, {
    content: messages.map((m, i) => _jsx("div", {
      children: m
    }, i)),
    children: _jsx(Icons.Exclamation, {
      "aria-label": "Team mismatch"
    })
  });
};
GroupMismatchIndicator.displayName = "GroupMismatchIndicator";
const getGroupMismatchMessage = _ref2 => {
  let {
    selectedMembers = []
  } = _ref2;
  if (selectedMembers.length > 1) {
    return "Owners do not belong to this Story's Team";
  }
  return "Owner does not belong to this Story's Team";
};
export const GroupMismatchIndicatorForOwners = _ref3 => {
  let {
    story,
    selectedMembers
  } = _ref3;
  // Abort checks if story is not assigned to a group
  if (!story.group_id) return null;
  const isAnyMemberInSameStoryAsGroup = selectedMembers.some(member => {
    return GroupData.isInGroup({
      group_id: story.group_id,
      group_ids: member.group_ids
    });
  });
  if (isAnyMemberInSameStoryAsGroup) {
    return null;
  }
  return _jsx(GroupMismatchIndicator, {
    messages: [getGroupMismatchMessage({
      selectedMembers
    })]
  });
};
GroupMismatchIndicatorForOwners.displayName = "GroupMismatchIndicatorForOwners";