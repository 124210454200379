import { makeVar, useReactiveVar } from '@apollo/client';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { CHARTS } from '@clubhouse/shared/types';
const refreshKeysVar = makeVar({
  [CHARTS.BURNDOWN]: nanoid(),
  [CHARTS.CUMULATIVE_FLOW]: nanoid(),
  [CHARTS.CYCLE_TIME]: nanoid()
});
export function useRefreshKey() {
  const refreshKeys = useReactiveVar(refreshKeysVar);
  const handleRefresh = useCallback(chartId => {
    refreshKeysVar({
      ...refreshKeys,
      [chartId]: nanoid()
    });
  }, [refreshKeys]);
  return [refreshKeys, handleRefresh];
}