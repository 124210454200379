import Collection from '../_frontloader/collection';
import NotificationsController from '../controllers/notifications';
import QuickSearchController from '../controllers/quickSearch';
const exports = {};
Collection.create('Dropdown', exports);
exports.findByTarget = target => {
  const element = _.isElement(target) ? target : target[0];
  return exports.get(dropdown => {
    return dropdown.target[0] === element;
  });
};
exports.findByChildElement = childElement => {
  const element = $(childElement).closest('.dropdown')[0];
  return exports.get(dropdown => {
    return dropdown.dropdownElement[0] === element;
  });
};
exports.isOnlyQuickSearch = () => {
  return exports.size() === 1 && QuickSearchController.isOpen();
};
exports.isOnlyNotifications = () => {
  return exports.size() === 1 && NotificationsController.isOpen();
};

// Allow dropdowns to be garbage-collected.
exports.destroyAll = () => {
  exports.each(dropdown => {
    if (dropdown.dropdownElement) {
      dropdown.dropdownElement.remove();
      dropdown.dropdownElement = null;
    }
    (dropdown.options || {}).target = null;
    dropdown.target = null;
  });
  exports.flush();
};
export { exports as default };