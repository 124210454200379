import { darken, lighten } from 'polished';
import { assertUnreachable } from '@clubhouse/shared/utils';
export const getChipStyles = (kind, theme) => {
  let styles = {};
  const darkBg = lighten(0.02, theme.color.raw.slate70); // replace with token from Shapes post release

  if (theme.themeName === 'dark') {
    switch (kind) {
      case 'default':
        {
          styles = {
            backgroundColor: darkBg
          };
          break;
        }
      case 'defaultOnCanvas':
        {
          styles = {
            backgroundColor: theme.color.background.tertiary
          };
          break;
        }
      case 'blue':
      case 'green':
      case 'orange':
      case 'purple':
      case 'red':
      case 'yellow':
        {
          styles = {
            backgroundColor: darkBg
          };
          break;
        }
      case 'blueInverted':
        {
          styles = {
            backgroundColor: darken(0.04, theme.color.raw.blue100)
          };
          break;
        }
      case 'greenInverted':
        {
          styles = {
            backgroundColor: darken(0.08, theme.color.raw.green100)
          };
          break;
        }
      case 'orangeInverted':
        {
          styles = {
            backgroundColor: darken(0.06, theme.color.raw.orange100)
          };
          break;
        }
      case 'purpleInverted':
        {
          styles = {
            backgroundColor: theme.color.raw.purple100
          };
          break;
        }
      case 'redInverted':
        {
          styles = {
            backgroundColor: darken(0.1, theme.color.error.dark)
          };
          break;
        }
      case 'transparent':
      case 'transparentBlue':
      case 'transparentGreen':
      case 'transparentOrange':
      case 'transparentRed':
      case 'transparentYellow':
      case 'transparentPurple':
        {
          styles = {
            backgroundColor: theme.color.background.transparent
          };
          break;
        }
      case 'alertOrange':
        {
          styles = {
            backgroundColor: theme.color.raw.orange40,
            color: theme.color.raw.orange100
          };
          break;
        }
      case 'warningRed':
        {
          styles = {
            backgroundColor: theme.color.raw.red100,
            color: theme.color.raw.red40
          };
          break;
        }
      default:
        assertUnreachable(kind);
    }
  } else {
    switch (kind) {
      case 'default':
        {
          styles = {
            backgroundColor: darken(0.016, theme.color.raw.slate10)
          };
          break;
        }
      case 'defaultOnCanvas':
        {
          styles = {
            backgroundColor: theme.color.background.tertiary
          };
          break;
        }
      case 'blue':
        {
          styles = {
            backgroundColor: theme.color.raw.blue40,
            color: darken(0.06, theme.color.raw.blue100)
          };
          break;
        }
      case 'green':
        {
          styles = {
            backgroundColor: theme.color.raw.green40,
            color: darken(0.07, theme.color.raw.green100)
          };
          break;
        }
      case 'orange':
        {
          styles = {
            backgroundColor: darken(0.02, theme.color.raw.orange20),
            color: darken(0.15, theme.color.raw.orange100)
          };
          break;
        }
      case 'purple':
        {
          styles = {
            backgroundColor: theme.color.raw.purple20,
            color: theme.color.raw.purple100
          };
          break;
        }
      case 'red':
        {
          styles = {
            backgroundColor: darken(0.01, theme.color.raw.red20),
            color: darken(0.04, theme.color.raw.red100)
          };
          break;
        }
      case 'yellow':
        {
          styles = {
            backgroundColor: theme.color.raw.yellow30,
            color: theme.color.raw.yellow110
          };
          break;
        }
      case 'blueInverted':
        {
          styles = {
            backgroundColor: darken(0.03, theme.color.raw.blue100),
            color: theme.color.text.highlight
          };
          break;
        }
      case 'greenInverted':
        {
          styles = {
            backgroundColor: darken(0.025, theme.color.raw.green100),
            color: theme.color.text.highlight
          };
          break;
        }
      case 'orangeInverted':
        {
          styles = {
            backgroundColor: darken(0.04, theme.color.raw.orange100),
            color: theme.color.text.highlight
          };
          break;
        }
      case 'purpleInverted':
        {
          styles = {
            backgroundColor: theme.color.raw.purple100,
            color: theme.color.text.highlight
          };
          break;
        }
      case 'redInverted':
        {
          styles = {
            backgroundColor: theme.color.error.main,
            color: theme.color.text.highlight
          };
          break;
        }
      case 'transparent':
        {
          styles = {
            backgroundColor: 'transparent'
          };
          break;
        }
      case 'transparentBlue':
        {
          styles = {
            backgroundColor: 'transparent',
            color: darken(0.06, theme.color.raw.blue100)
          };
          break;
        }
      case 'transparentGreen':
        {
          styles = {
            backgroundColor: 'transparent',
            color: darken(0.07, theme.color.raw.green100)
          };
          break;
        }
      case 'transparentOrange':
        {
          styles = {
            backgroundColor: 'transparent',
            color: darken(0.15, theme.color.raw.orange100)
          };
          break;
        }
      case 'transparentRed':
        {
          styles = {
            backgroundColor: 'transparent',
            color: darken(0.04, theme.color.raw.red100)
          };
          break;
        }
      case 'transparentYellow':
        {
          styles = {
            backgroundColor: 'transparent',
            color: theme.color.raw.yellow110
          };
          break;
        }
      case 'transparentPurple':
        {
          styles = {
            backgroundColor: 'transparent',
            color: theme.color.raw.purple100
          };
          break;
        }
      case 'alertOrange':
        {
          styles = {
            backgroundColor: theme.color.raw.orange40,
            color: theme.color.raw.orange100
          };
          break;
        }
      case 'warningRed':
        {
          styles = {
            backgroundColor: theme.color.raw.red100,
            color: theme.color.raw.red40
          };
          break;
        }
      default:
        assertUnreachable(kind);
    }
  }
  return styles;
};
export const getIconColor = (kind, theme) => {
  let color = '';
  if (theme.themeName === 'dark') {
    switch (kind) {
      case 'default':
      case 'defaultOnCanvas':
      case 'transparent':
        {
          color = theme.color.icon.main;
          break;
        }
      case 'blue':
      case 'transparentBlue':
        {
          color = theme.color.raw.blue60;
          break;
        }
      case 'green':
      case 'transparentGreen':
        {
          color = theme.color.raw.green80;
          break;
        }
      case 'orange':
      case 'transparentOrange':
        {
          color = theme.color.raw.orange80;
          break;
        }
      case 'purple':
      case 'transparentPurple':
        {
          color = theme.color.raw.purple60;
          break;
        }
      case 'red':
      case 'transparentRed':
        {
          color = lighten(0.18, theme.color.raw.red80);
          break;
        }
      case 'yellow':
      case 'transparentYellow':
        {
          color = darken(0.04, theme.color.raw.yellow60);
          break;
        }
      case 'blueInverted':
      case 'greenInverted':
      case 'orangeInverted':
      case 'redInverted':
      case 'purpleInverted':
      case 'alertOrange':
      case 'warningRed':
        {
          color = 'currentColor';
          break;
        }
      default:
        assertUnreachable(kind);
    }
  } else {
    switch (kind) {
      case 'default':
      case 'transparent':
        {
          color = theme.color.icon.main;
          break;
        }
      case 'defaultOnCanvas':
        color = theme.color.icon.disabled;
        break;
      case 'yellow':
      case 'transparentYellow':
        {
          color = darken(0.146, theme.color.raw.yellow90);
          break;
        }
      case 'blue':
      case 'green':
      case 'orange':
      case 'purple':
      case 'red':
      case 'transparentBlue':
      case 'transparentGreen':
      case 'transparentOrange':
      case 'transparentRed':
      case 'transparentPurple':
      case 'blueInverted':
      case 'greenInverted':
      case 'orangeInverted':
      case 'purpleInverted':
      case 'redInverted':
      case 'alertOrange':
      case 'warningRed':
        {
          color = 'currentColor';
          break;
        }
      default:
        assertUnreachable(kind);
    }
  }
  return color;
};