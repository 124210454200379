import StoryController from 'app/client/core/js/controllers/story';
import TaskController from 'app/client/core/js/controllers/task';
import TaskModel from 'app/client/core/js/models/task';
import { getCurrentUserProfileDetails } from './profile';
export const getById = taskId => TaskModel.getById(taskId);
export const filterTasksInColumn = (tasks, column) => TaskModel.filterTasksInColumn(tasks, column);
export const deleteTask = task => new Promise((resolve, reject) => TaskModel.deleteTask(task, err => {
  if (!err) resolve();else reject(err);
}));
export const isNewStory = () => TaskModel.isNewStory();
export const saveChanges = (task, changes) => new Promise((resolve, reject) => {
  TaskModel.saveChanges(task, changes, (err, newTask) => {
    if (err) reject(err);else resolve(newTask);
  });
});
export const prepareDescriptionForEditing = task => TaskController.prepareDescriptionForEditing(task.description);
export const createStoryFromTask = task => TaskController._createStoryFromTask(task);
export const openTaskOwnerUpdaterForTask = (target, task, callback) => {
  StoryController.openTaskOwnerUpdaterForTask({
    target,
    task,
    callback
  });
};
export const getStartedStoryTasksForCurrentUser = () => TaskModel.getStartedStoryTasksForUser(getCurrentUserProfileDetails());
export const getActiveTasksForCurrentUser = () => TaskModel.getActiveTasksForUser(getCurrentUserProfileDetails());
export const fetchCurrentUserActiveTasks = () => new Promise((resolve, reject) => TaskModel.fetchCurrentUserActiveTasks(err => {
  if (err) {
    reject(err);
  } else resolve();
}));
export const fetchMissingStoriesForTasks = () => new Promise((resolve, reject) => TaskModel.fetchMissingStoriesForTasks(err => {
  if (err) {
    reject(err);
  } else resolve();
}));