import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Globals from '../_frontloader/globals';
import OrganizationModel from './organization';
const exports = {};

/*

InviteLink entity:

{
  created_at: "2019-09-04T09:43:31Z"
  created_by: "5d4a73d3-7bc3-42cc-a958-df01f2e35be6"
  entity_type: "invite-link"
  id: "5d6f8743-c8cc-4b8d-88e0-3757357907b1"
  role: "member"
  updated_at: "2019-09-04T09:43:31Z"
  url: "http://localhost:3000/invite-link/5d6f8743-c8cc-4b8d-88e0-3757357907b1"
}
*/

Collection.create('InviteLink', exports);
exports.fetch = function () {
  let {
    role
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    role: 'member'
  };
  return new Promise((resolve, reject) => Backend.get(`/api/private/invite-link?role=${role}`, {
    onComplete: (res, jqXHR) => {
      exports.defaultGetHandler(res, (err, inviteLink) => {
        if (err) {
          if (jqXHR.status === 404 || jqXHR.status === 403) return resolve(null);
          return reject(err);
        }
        return resolve(inviteLink);
      });
    }
  }));
};
exports.delete = id => new Promise((resolve, reject) => Backend.delete(`/api/private/invite-link/${id}`, {
  onComplete: (res, xhr) => {
    exports.defaultDeleteHandler({
      id
    }, res, xhr, err => {
      if (err) {
        return reject(err);
      }
      resolve();
    });
  }
}));
exports.create = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    role: 'member'
  };
  return new Promise((resolve, reject) => Backend.post('/api/private/invite-link', _.assign({
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, (err, inviteLink) => {
        if (err) {
          return reject(err);
        }
        resolve(inviteLink);
      });
    }
  })));
};
exports.getForOrganization = id => {
  const orgID = id || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  return exports.get({
    workspace: {
      id: orgID
    }
  });
};
exports.fetchPublic = id => new Promise((resolve, reject) => Backend.get(`/api/private/invite-links/${id}`, {
  onComplete: res => {
    exports.defaultGetHandler(res, (err, inviteLink) => {
      if (err) {
        return reject(err);
      }
      return resolve(inviteLink);
    });
  }
}));
exports.Promises = {};
export { exports as default };