import { useCallback, useMemo } from 'react';
import { LabelSelect } from '@clubhouse/shared/components/LabelSelect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TableLabels = _ref => {
  let {
    isSelectionDisabled = false,
    labels = [],
    onChange,
    onClose,
    title,
    value = []
  } = _ref;
  const onChangeLabels = useCallback(labelIds => {
    const newSelectedLabels = labels.filter(label => labelIds.includes(`${label.id}`));
    onChange(newSelectedLabels);
  }, [labels, onChange]);
  const selectedValues = useMemo(() => {
    return value.map(label => String(label.id));
  }, [value]);
  return _jsx(LabelSelect, {
    isSelectionDisabled: isSelectionDisabled,
    labels: labels,
    onChange: onChangeLabels,
    onClose: onClose,
    selectedValues: selectedValues,
    title: title
  });
};
TableLabels.displayName = "TableLabels";