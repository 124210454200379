import AddNewStoryController from './addNewStory';
import DropdownController from './dropdown';
import EpicsController from './epics';
import MessageController from './message';
import SearchController from './search';
import SearchTipsController from './searchTips';
import StoryController from './story';
import StoryDialogController from './storyDialog';
import Globals from '../_frontloader/globals';
import CompanyModel from '../models/company';
import EpicModel from '../models/epic';
import EstimateScaleModel from '../models/estimateScale';
import Feature from '../models/feature';
import GroupModel from '../models/group';
import LabelModel from '../models/label';
import OrganizationModel from '../models/organization';
import ProfileModel from '../models/profile';
import StoryModel from '../models/story';
import StoryLinkModel from '../models/storyLink';
import ApplicationState from '../modules/applicationState';
import Dialog from '../modules/dialog';
import Format from '../modules/format';
import Is from '../modules/is';
import Search from '../modules/search';
import Tests from '../modules/tests';
import Url from '../modules/url';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};
const TABS = {
  stories: 'stories',
  epics: 'epics',
  neither: 'neither'
};
const CURRENT_TAB_KEY = 'CurrentQuickSearchTab';
let dropdown = null;
exports.lastRequest = {};
const state = {
  dropdownItems: {
    [TABS.epics]: null,
    [TABS.stories]: null,
    [TABS.neither]: null
  },
  activeTab: null
};
exports.isOpen = () => {
  return $('.dropdown.quick-search').length > 0;
};
exports.getDropdownController = () => DropdownController;
exports.getView = () => View;
exports.selectEpicsTab = () => {
  _changeTab(TABS.epics);
};
exports.selectStoriesTab = () => {
  _changeTab(TABS.stories);
};
const _changeTab = newTab => {
  const savedItems = state.dropdownItems[newTab];
  state.activeTab = newTab;
  ApplicationState.set(CURRENT_TAB_KEY, newTab);
  DropdownController.updateItems(dropdown, savedItems);
};
exports.getOperatorSuggestions = query => {
  const lastWord = query.match(/(\w+\:\s?)?(\w+)?$/g);
  const highlightMapper = str => option => option.replace(str, '<strong>$&</strong>');
  if (!lastWord || !lastWord[0]) return [];
  const lastWordToLowercase = lastWord[0].toLowerCase();
  if (lastWordToLowercase.indexOf(':') < 0) {
    const operators = ['team:', 'id:', 'is:', 'has:', ...(Feature.isProjectsFeatureEnabled() ? ['project:'] : []), 'milestone:', 'owner:', 'requester:', 'label:', 'state:', 'created:', 'updated:', 'moved:', 'due:', 'type:', 'completed:', 'estimate:', 'epic:'];
    const suggestions = operators.filter(operator => operator.indexOf(lastWordToLowercase) >= 0).map(highlightMapper(lastWordToLowercase));
    return suggestions;
  } else {
    const operator = lastWordToLowercase.match(/\w+:/);
    const operatorValue = lastWordToLowercase.match(/\:\s?(\w+)/);
    const queryStr = lastWordToLowercase.replace(/\s/g, '');
    let suggestions = [];
    const dateOperatorValues = function (operator) {
      let exclude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return ['today', 'yesterday', 'tomorrow', 'YYYY-MM-DD', 'YYYY-MM-DD..YYYY-MM-DD', '*..YYYY-MM-DD', 'YYYY-MM-DD..*'].filter(value => !exclude.includes(value)).map(value => `${operator}${value}`);
    };
    const operatorsOptions = {
      'is:': ['is:unestimated', 'is:overdue', 'is:archived', 'is:blocked', 'is:blocker', 'is:unstarted', 'is:started', 'is:done', 'is:story'],
      'has:': ['has:attachment', 'has:task', 'has:label', 'has:epic', 'has:comment', 'has:deadline', 'has:owner'],
      'type:': ['type:bug', 'type:chore', 'type:feature'],
      'created:': dateOperatorValues('created:', ['tomorrow']),
      'updated:': dateOperatorValues('updated:', ['tomorrow']),
      'moved:': dateOperatorValues('moved:', ['tomorrow']),
      'due:': dateOperatorValues('due:'),
      'owner:': ProfileModel.getAllActiveProfileDetails().map(profile => `owner:${profile.mention_name}`),
      'requester:': ProfileModel.getAllActiveProfileDetails().map(profile => `requester:${profile.mention_name}`),
      'team:': GroupModel.allNotArchived().map(group => `team:${group.name}`),
      'epic:': EpicModel.allNotArchived().map(epic => `epic:${epic.name}`),
      'label:': LabelModel.allNotArchived().map(label => `label:${label.name}`)
    };
    if (operatorsOptions[operator]) {
      if (operatorValue && operatorValue[1]) {
        suggestions = operatorsOptions[operator].filter(option => option.toLowerCase().indexOf(queryStr.toLowerCase()) >= 0);
      } else {
        suggestions = operatorsOptions[operator];
      }
    }
    const queryWords = (query.match(/\w+:\s?\w+/g) || []).map(option => option.replace(/\s/g, ''));
    suggestions = suggestions.filter(suggestion => !queryWords.includes(suggestion));
    return suggestions.map(highlightMapper(queryStr));
  }
};
exports.close = () => {
  if (dropdown) {
    DropdownController.close(dropdown);
    dropdown = null;
  }
};
exports.closeForStoryNavigation = target => {
  const isNavItem = target.hasClass('modal-nav-item');
  const isInsideDialog = target.closest('.story-dialog').length > 0;
  return !isNavItem && !isInsideDialog;
};
exports.calculateMaxHeight = () => {
  // We don't have the element on the page yet, so we need to hard-code these values.
  // but we have the target?...
  const spacingAboveDropdown = 80; // top of window to top of dropdown list element
  const spacingBelowDropdown = 420; // bottom of dropdown list element to bottom of window

  return $(window).height() - (spacingAboveDropdown + spacingBelowDropdown);
};
const containerId = 'react-quick-search';
exports.focus = () => {
  const input = document.querySelector(`#${containerId} input`);
  if (input) {
    input.focus();
  }
};
exports.getContextProps = () => {
  return {
    companyId: CompanyModel.getCurrentID(),
    organizationId: Globals.get('organizationID') || OrganizationModel.getCurrentID(),
    Format,
    Search,
    Tests,
    Url,
    Is,
    Utils,
    Dialog,
    Model: {
      Profile: ProfileModel,
      Story: StoryModel,
      Label: LabelModel,
      Epic: EpicModel,
      EstimateScale: EstimateScaleModel,
      StoryLink: StoryLinkModel
    },
    Controller: {
      Message: MessageController,
      Search: SearchController,
      AddNewStory: AddNewStoryController,
      Story: StoryController,
      StoryDialog: StoryDialogController,
      SearchTips: SearchTipsController,
      Epics: EpicsController,
      QuickSearch: exports
    }
  };
};
export { exports as default };