import * as DropdownTemplate from 'app/client/core/views/templates/dropdown.html';
import * as DropdownItemsTemplate from 'app/client/core/views/templates/dropdownItems.html';
import Async from '../modules/async';
import AutocompleteController from './autocomplete';
import Dialog from '../modules/dialog';
import DropdownModel from '../models/dropdown';
import Log from '../modules/log';
import StoryDialogController from './storyDialog';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};
const NS = '.App.Dropdown'; // Event namespace.
const DEFAULT_OPTIONS = {
  applyOnClick: true,
  maxHeight: 350,
  leftOffset: 0,
  showCloseButton: false,
  topOffset: 4,
  width: null,
  constrainToViewport: false
};
exports.open = options => {
  let isAlreadyOpen = false;
  const existingDropdown = DropdownModel.findByTarget(options.target);
  if (existingDropdown) {
    isAlreadyOpen = true;
    exports.close(existingDropdown);
    return false;
  }
  const dropdown = {};
  if (!Utils.animationDisabled()) {
    exports.isOpening = true;
  }
  if (options.fixBelowTarget) {
    options.maxHeight = DEFAULT_OPTIONS.maxHeight;
  }
  const id = Utils.simpleHash('dropdown' + Date.now()) + Date.now();
  dropdown.options = _.assignIn({}, DEFAULT_OPTIONS, options);
  dropdown.id = dropdown.options.id || id;
  const html = DropdownTemplate.render({
    id: dropdown.id,
    className: dropdown.options.className,
    footer: dropdown.options.footer,
    header: dropdown.options.header,
    items: dropdown.options.items,
    title: dropdown.options.title,
    showCloseButton: dropdown.options.showCloseButton,
    applyOnClick: dropdown.options.applyOnClick
  });
  View.attach(html, 'body');
  dropdown.dropdownElement = $('#dropdown-' + dropdown.id);
  dropdown.target = $(dropdown.options.target);
  if (options.animate !== false && !isAlreadyOpen && !Utils.animationDisabled()) {
    dropdown.dropdownElement.addClass('element-is-hiding');
    setTimeout(() => {
      // We can occasionally get into a situation where dropdownElement is null.
      if (dropdown.dropdownElement) {
        dropdown.dropdownElement.removeClass('element-is-hiding').addClass('element-is-open');
      }
    }, 0);
  }
  exports.setItemValues(dropdown);
  Utils.autoTabIndex(dropdown.dropdownElement);
  exports.setPosition(dropdown);
  $(window).on('scroll' + NS + '.' + dropdown.id, _.isFunction(dropdown.options.onScroll) ? dropdown.options.onScroll : () => {
    exports.setPosition(dropdown);
  });
  exports.setScrollTop(dropdown);
  exports.bindToClicksOutsideDropdown(dropdown);
  _bindToKeys(dropdown);
  Utils.autoTabIndex(dropdown.dropdownElement);
  DropdownModel.add(dropdown);
  setTimeout(() => {
    exports.isOpening = false;
    if (options.component) {
      View.renderComponent({
        mountNode: document.querySelector(options.component.mountId),
        ...options.component
      });
    }
  }, 0);
  return dropdown;
};
exports.isOpen = () => {
  return DropdownModel.size() > 0;
};
exports.getItems = dropdown => {
  return dropdown.options.items;
};
exports.updateItems = (dropdown, items) => {
  if (exports.isOpen()) {
    dropdown.options.items = items;
    const html = DropdownItemsTemplate.render({
      items: dropdown.options.items,
      applyOnClick: dropdown.options.applyOnClick
    });
    dropdown.dropdownElement.find('.list').html(html);
    exports.setItemValues(dropdown);
    // Reset positioning.
    $(window).trigger('scroll' + NS);
  }
};
exports.setItemValues = dropdown => {
  $('.list', dropdown.dropdownElement).children().each(function (i) {
    this.dropdownValue = dropdown.options.items[i].value;
    this.dropdownDisplayValue = dropdown.options.items[i].displayValue;
  });
};
exports.bindToClicksOutsideDropdown = dropdown => {
  if (!dropdown.dropdownElement) {
    return false;
  }
  const eventName = 'click' + NS + '.' + dropdown.id;
  $('body').off(eventName).on(eventName, e => {
    if (!dropdown) {
      return false;
    }
    const bounds = Utils.getElementBounds(dropdown.dropdownElement);
    const isOutsideBounds = bounds ? Utils.outsideBounds(bounds, e.pageX, e.pageY) : true;
    const isNotAutocompleteClick = !AutocompleteController.isClosing && $(e.target).closest('.dropdown').data('controller') !== 'Autocomplete';
    const isNotDialogClick = !Dialog.isClosing();
    const isReactQuickFilter = $(e.target).closest('.react-quick-filter').length > 0;
    if (exports.isOpening !== true && isOutsideBounds && isNotAutocompleteClick && isNotDialogClick && !isReactQuickFilter) {
      const customCallbackResult = _.isFunction(dropdown.options.beforeOutsideClick) ? dropdown.options.beforeOutsideClick(e) : true;
      if (customCallbackResult) {
        exports.close(dropdown);
        return false;
      }
    }
  });
};
function _bindToKeys(dropdown) {
  const eventName = 'keyup' + NS + '.' + dropdown.id;
  if (dropdown.options.onKeyDown) {
    $('body').on('keydown' + NS + '.' + dropdown.id, dropdown.options.onKeyDown);
  }
  $('body').on(eventName, dropdown.options.onKeyUp || (e => {
    if (Utils.keyPressed(e, 'DOWN_ARROW') && exports.canFocusNextPrev()) {
      return exports.focusNext(dropdown);
    } else if (Utils.keyPressed(e, 'UP_ARROW') && exports.canFocusNextPrev()) {
      return exports.focusPrev(dropdown);
    } else if (Utils.keyPressed(e, 'ESCAPE') && !AutocompleteController.isClosing) {
      if (_.isFunction(dropdown.options.beforeEscape) && dropdown.options.beforeEscape() === false) {
        return;
      }
      exports.close(dropdown);
      return false;
    }
  }));
}
exports.canFocusNextPrev = () => {
  return !(DropdownModel.isOnlyQuickSearch() && StoryDialogController.isOpen());
};
const getNextIndex = (currentIndex, length) => {
  const nextIndex = currentIndex + 1;
  return nextIndex >= length ? 0 : nextIndex;
};
const getPreviousIndex = (currentIndex, length) => {
  const previousIndex = currentIndex - 1;
  return previousIndex < 0 ? length - 1 : previousIndex;
};
const setFocus = (dropdown, getFocusIndex) => {
  const focusableItems = dropdown.dropdownElement.find('.focusable:visible');
  if (focusableItems.length > 0) {
    const currentIndex = focusableItems.index(document.activeElement);
    focusableItems[getFocusIndex(currentIndex, focusableItems.length)].focus();
  }
  exports.setScrollTop(dropdown);
  return false;
};
exports.focusNext = dropdown => setFocus(dropdown, getNextIndex);
exports.focusPrev = dropdown => setFocus(dropdown, getPreviousIndex);
exports.setScrollTop = dropdown => {
  let focused = dropdown.dropdownElement.find('.list .focusable:focus');
  let top = 0;
  if (!focused.length) {
    focused = dropdown.dropdownElement.find('.list .focusable.active');
  }
  if (focused.length) {
    const firstOffset = dropdown.dropdownElement.find('.list .focusable').eq(0).offset().top;
    const focusedOffset = focused.offset().top;
    const listHeight = dropdown.dropdownElement.find('.list').height();
    top = focusedOffset - firstOffset - listHeight * 0.3;
  }
  $('.list', dropdown.dropdownElement).stop(true, true).animate({
    scrollTop: top
  }, 200, 'easeOutQuad');
};
exports.close = function (dropdown) {
  return new Promise((resolve, reject) => {
    if (!dropdown) {
      return resolve();
    }
    if (AutocompleteController.isClosing) {
      reject('Autocomplete prevents closing');
      return;
    }

    // Handle cases where user clicks on the dropdown overlay or close button.
    if (Utils.isEvent(dropdown)) {
      dropdown = Utils.getModelFromContext(this);
    }
    if (_.isFunction(dropdown.options.beforeClose) && dropdown.options.beforeClose() === false) {
      reject('beforeClose prevents closing');
      return;
    }
    if (dropdown.options && _.isFunction(dropdown.options.onClose)) {
      dropdown.options.onClose();
    }
    if (dropdown.dropdownElement) {
      dropdown.dropdownElement.remove();
      dropdown.dropdownElement = null;
    }
    $(window).off('scroll' + NS + '.' + dropdown.id);
    $('body').off(NS + '.' + dropdown.id);
    DropdownModel.remove({
      id: dropdown.id
    });
    if (!Utils.animationDisabled()) {
      exports.isClosing = true;
      setTimeout(() => {
        exports.resetIsClosing();
        resolve();
      }, 0);
    } else {
      resolve();
    }
  }).catch(err => {
    Log.debug(err);
  });
};
exports.closeById = id => {
  const dropdown = DropdownModel.getById(id);
  return dropdown ? exports.close(dropdown) : Promise.resolve();
};
exports.resetIsClosing = () => {
  exports.isClosing = false;
};
exports.closeAll = () => {
  const promiseReturningFunctions = DropdownModel.map(dropdown => {
    return _.wrap(dropdown, exports.close);
  }).reverse();
  return Async.eachPromiseInSequence(promiseReturningFunctions);
};
exports.setPosition = dropdown => {
  if (!dropdown.dropdownElement) {
    return false;
  }
  if (_.isFunction(dropdown.options.beforePositioning)) {
    dropdown.options.beforePositioning(dropdown);
  }
  const margin = 8;
  let offset = dropdown.target.offset();
  if (offset.top === 0 && offset.left === 0 && dropdown.options.targetSelector) {
    const newTarget = $(dropdown.options.targetSelector);
    if (newTarget.length > 0) {
      dropdown.target = newTarget;
      offset = dropdown.target.offset();
    } else {
      return false;
    }
  }
  const leftPosition = offset.left + dropdown.options.leftOffset;

  // We need to set max-height before calculating dropdownElement.outerHeight:
  dropdown.dropdownElement.find('.list').css({
    maxHeight: dropdown.options.maxHeight
  });
  const css = {
    left: leftPosition,
    width: dropdown.options.width || dropdown.target.outerWidth() - 2,
    ...(dropdown.options.constrainToViewport && {
      boxSizing: 'border-box',
      maxHeight: `calc(100vh - ${margin * 2}px)`,
      maxWidth: `calc(100vw - ${margin * 2}px)`,
      overflow: 'auto'
    }),
    ...dropdown.options.css
  };
  dropdown.dropdownElement.css(css);
  if (dropdown.options.fixBelowTarget) {
    dropdown.dropdownElement.css({
      left: offset.left - 2,
      top: offset.top + dropdown.target.outerHeight() + margin,
      zIndex: 2000
    });
    return;
  }

  // Needs to be calculated after the width is set which could update the height
  const topPosition = offset.top + dropdown.target.outerHeight() + dropdown.options.topOffset;
  const maxTopPosition = $(window).height() - dropdown.dropdownElement.outerHeight() - margin + window.scrollY;
  const maxLeftPosition = $(window).width() - dropdown.dropdownElement.outerWidth() - margin + window.scrollX;
  dropdown.dropdownElement.css({
    left: Math.max(margin, Math.min(leftPosition, maxLeftPosition)),
    top: Math.max(margin, Math.min(topPosition, maxTopPosition))
  });
};
exports.applyValue = function () {
  const dropdown = Utils.getModelFromContext(this);
  const value = this.dropdownValue;
  if (dropdown.options.onApply) {
    dropdown.options.onApply.call(this, value);
  } else if (_.isFunction(value)) {
    // TODO: Would like to move this to before onApply callback.
    value.call(dropdown.target);
  } else if (value === '') {
    // TODO: Describe when this happens, if ever.
    return false;
  }
  exports.close(dropdown);
  return false;
};
exports.isClickOnDatepicker = e => {
  const classNames = $(e.target).attr('class');
  return !_.some(['fa-caret-left', 'fa-caret-right'], className => {
    return _.includes(classNames, className);
  });
};
export { exports as default };