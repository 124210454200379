import * as yup from 'yup';
const handleYupError = (e, errors) => {
  return (e.inner || []).reduce((acc, innerError) => innerError.path ? {
    ...acc,
    [innerError.path]: innerError.message
  } : acc, errors);
};
export class SchemaValidator {
  constructor(schema) {
    this.schema = schema;
  }
  validate = values => {
    try {
      this.schema.validateSync(values, {
        abortEarly: false
      });
      return {};
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        return handleYupError(e);
      } else {
        throw e;
      }
    }
  };
  isValid = values => this.schema.isValidSync(values);
}
export const emailFieldValidator = () => yup.string().trim().label('Email').required().email('Invalid email format.');
export const emailValidator = new SchemaValidator(yup.object().shape({
  email: emailFieldValidator()
}));
export const zipCodeFieldValidator = () => yup.string().label('Zip Code').trim().matches(/^[0-9]{5}$/, 'Zip Code should be a 5 digit number.');
export const cardNumberFieldValidator = () => yup.string().label('Card Number').trim().test('is-valid-card', 'Not a valid Credit Card number.', value => window.Stripe.card.validateCardNumber(value));