import Updates from 'app/client/core/js/modules/updates';
import { getGlobal, setGlobal } from './globals';
import { getCurrentOrigin } from './navigation';
export const getBasisT = () => getGlobal('databaseTime');
export const setupSSEListeners = () => Updates.setupSSEListeners();
export const setBasisT = time => {
  if (time) setGlobal('databaseTime', Number(time));
  return getBasisT();
};
export const getUpdatesUrl = path => {
  const origin = getCurrentOrigin();
  const shortcut = origin.match(/^https:\/\/(app\.shortcut(?:-[^.]+)?\.com)$/);
  if (shortcut) {
    // Shortcut in any environment
    // route to the appropriate api subdomain to avoid the cost of using Fastly for updates traffic
    return 'https://api.' + shortcut[1] + path;
  } else if (window._UPDATES_DOMAIN) {
    // LOCAL DEV + LOCAL BACKEND
    // when developing both node-app and backend locally
    return window._UPDATES_DOMAIN + path;
  }

  // LOCAL DEV + STAGING BACKEND (or PR ENV)
  // route through the same domain as the app is being served from
  // this case occurs when running node-app locally connected to staging,
  // and in the PR review environments
  return origin + '/backend' + path;
};