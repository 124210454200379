import { TimeUnitSelectReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TimeUnitSelectReport_SelectorFragement = TimeUnitSelectReport_SelectorFragmentDoc;
export const TimeUnitSelect = props => {
  return _jsx(ReportSelectFilter, {
    ...props,
    label: "Time Unit"
  });
};
TimeUnitSelect.displayName = "TimeUnitSelect";