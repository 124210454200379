import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/stories/links';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from '../actions';
import { useContextMenuWithLogging } from '../useContextMenuWithLogging';
import { MoveToIterationContextMenuItem, MoveWithinColumnContextMenuItem, ToggleOwnerContextMenuItem } from './components';
import { useFetchStoryContextMenuData } from './hooks';
import { useTableGroup } from '@clubhouse/shared/components/Table/TableGroup';

// eslint-disable-next-line import/no-restricted-paths
import { useColumnMaybe } from 'pages/stories-v3/kanban/ColumnProvider';
// eslint-disable-next-line import/no-restricted-paths
import { useOptimisticUpdateMaybe } from 'pages/stories-v3/shared/GroupStoriesProvider';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const LOADING_TEMPLATE_WITHOUT_REORDER = '0-000-0000-0';
const LOADING_TEMPLATE_WITH_REORDER = '0-00-000-0000-0';
const StoryContextMenu = _ref => {
  let {
    id,
    canReorder,
    onUpdateOptimistically
  } = _ref;
  const data = useFetchStoryContextMenuData({
    id
  });
  if (!data) return _jsx(ContextMenuLoading, {
    template: canReorder ? LOADING_TEMPLATE_WITH_REORDER : LOADING_TEMPLATE_WITHOUT_REORDER
  });
  const {
    publicId,
    name,
    owners,
    type,
    formattedVcsBranchName
  } = data;
  return _jsxs(ContextMenu, {
    children: [_jsx(OpenInNewTabContextMenuItem, {
      url: getHref(publicId, name)
    }), canReorder && _jsxs(_Fragment, {
      children: [_jsx(ContextMenuDivider, {}), _jsx(MoveWithinColumnContextMenuItem, {
        ...data,
        moveTo: "top",
        onUpdateOptimistically: onUpdateOptimistically
      }), _jsx(MoveWithinColumnContextMenuItem, {
        ...data,
        moveTo: "bottom",
        onUpdateOptimistically: onUpdateOptimistically
      })]
    }), _jsx(ContextMenuDivider, {}), _jsx(ToggleOwnerContextMenuItem, {
      id: id,
      name: name,
      owners: owners,
      publicId: publicId,
      type: type
    }), _jsx(MoveToIterationContextMenuItem, {
      ...data,
      moveTo: "current"
    }), _jsx(MoveToIterationContextMenuItem, {
      ...data,
      moveTo: "next"
    }), _jsx(ContextMenuDivider, {}), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getHref(publicId, name, true),
      label: "Link"
    }), _jsx(CopyContextMenuItem, {
      value: String(publicId),
      label: "ID"
    }), _jsx(CopyContextMenuItem, {
      icon: "CopyDocument",
      value: name,
      label: "Title"
    }), _jsx(CopyContextMenuItem, {
      icon: "Branch",
      isDisabled: !formattedVcsBranchName,
      value: formattedVcsBranchName || '',
      label: "Branch Name"
    }), _jsx(ContextMenuDivider, {}), _jsx(PinContextMenuItem, {
      id: id,
      entityType: Nouns.Story,
      loggingContext: {
        typename: 'Story'
      }
    })]
  });
};
StoryContextMenu.displayName = "StoryContextMenu";
export const useStoryContextMenu = id => {
  const {
    canReorder
  } = useTableGroup();
  const columnInfo = useColumnMaybe();
  const updateOptimistically = useOptimisticUpdateMaybe();
  return useContextMenuWithLogging(() => _jsx(StoryContextMenu, {
    id: id,
    canReorder: Boolean(canReorder || columnInfo),
    onUpdateOptimistically: updateOptimistically
  }), {
    entityType: 'Story'
  }).props;
};