import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const GroupSparkle = _ref => {
  let {
    width
  } = _ref;
  return _jsxs("svg", {
    width: width,
    height: width,
    viewBox: "0 0 160 124",
    fill: "none",
    children: [_jsx("path", {
      opacity: "0.4",
      d: "M160 59.0305C160 89.2011 91.9655 123.402 59.2453 123.402C26.525 123.402 0 98.9438 0 68.7732C0 38.6026 68.0345 4.40186 100.755 4.40186C133.475 4.40186 160 28.8599 160 59.0305Z",
      fill: "#F1F1F5"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M62.1497 68.0319C62.1497 77.3381 69.6939 84.8822 79.0001 84.8822C88.3063 84.8822 95.8504 77.3381 95.8504 68.0319C95.8504 58.7257 88.3063 51.1815 79.0001 51.1815C69.6939 51.1815 62.1497 58.7257 62.1497 68.0319ZM90.2338 68.0319C90.2338 74.236 85.2044 79.2654 79.0002 79.2654C72.7961 79.2654 67.7666 74.236 67.7666 68.0319C67.7666 61.8277 72.7961 56.7983 79.0002 56.7983C85.2044 56.7983 90.2338 61.8277 90.2338 68.0319Z",
      fill: "#444444"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M89.0871 86.0236L83.009 100.487C82.2083 102.393 83.6709 104.481 85.7354 104.38C97.7683 103.791 104.276 102.35 104.276 97.7386C104.276 92.5767 99.7545 87.3478 92.7286 84.5079C91.3045 83.9322 89.6822 84.6075 89.0871 86.0236ZM53.7245 97.7386C53.7245 102.35 60.2319 103.791 72.2648 104.38C74.3293 104.481 75.7919 102.393 74.9912 100.487L68.9131 86.0236C68.3181 84.6075 66.6957 83.9322 65.2716 84.5079C58.2457 87.3478 53.7245 92.5767 53.7245 97.7386Z",
      fill: "#444444"
    }), _jsx("path", {
      d: "M107.733 84.0043L113.811 69.5406C114.407 68.1245 116.029 67.4492 117.453 68.0248C124.479 70.8648 129 76.0937 129 81.2555C129 85.8665 122.493 87.3084 110.46 87.8974C108.395 87.9984 106.933 85.9099 107.733 84.0043Z",
      fill: "#444444"
    }), _jsx("path", {
      d: "M47.5403 87.8974C35.5074 87.3084 29 85.8665 29 81.2555C29 76.0937 33.5212 70.8648 40.5471 68.0248C41.9712 67.4492 43.5936 68.1245 44.1887 69.5406L50.2667 84.0043C51.0675 85.9099 49.6048 87.9984 47.5403 87.8974Z",
      fill: "#444444"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M38.0657 48.2522C38.0657 57.5584 45.6098 65.1026 54.916 65.1026C55.6292 65.1026 56.3321 65.0583 57.022 64.9722C57.0215 64.9082 57.0212 64.8441 57.0212 64.78C57.0212 62.8233 57.2785 60.9268 57.7611 59.1224C56.8525 59.3595 55.899 59.4858 54.9161 59.4858C48.712 59.4858 43.6825 54.4563 43.6825 48.2522C43.6825 42.0481 48.712 37.0186 54.9161 37.0186C60.7186 37.0186 65.4935 41.4179 66.0875 47.0631C67.6767 45.9152 69.4265 44.976 71.2968 44.2854C69.5131 36.8934 62.8559 31.4019 54.916 31.4019C45.6098 31.4019 38.0657 38.946 38.0657 48.2522ZM121.57 48.2522C121.57 57.5584 114.026 65.1026 104.72 65.1026C104.007 65.1026 103.304 65.0583 102.614 64.9722C102.614 64.9311 102.614 64.89 102.615 64.8489C102.615 64.8259 102.615 64.8029 102.615 64.78C102.615 62.8233 102.357 60.9268 101.875 59.1224C102.783 59.3595 103.737 59.4858 104.72 59.4858C110.924 59.4858 115.953 54.4563 115.953 48.2522C115.953 42.0481 110.924 37.0186 104.72 37.0186C98.9172 37.0186 94.1423 41.4179 93.5483 47.0631C91.9591 45.9153 90.2093 44.976 88.3391 44.2855C90.1227 36.8934 96.78 31.4019 104.72 31.4019C114.026 31.4019 121.57 38.946 121.57 48.2522Z",
      fill: "#444444"
    }), _jsx("path", {
      d: "M147.311 19.6399L145.584 20.2064L145.017 18.4791C144.896 18.1088 144.632 17.802 144.284 17.6262C143.936 17.4504 143.533 17.42 143.162 17.5417C142.792 17.6634 142.485 17.9271 142.309 18.275C142.134 18.6229 142.103 19.0263 142.225 19.3966L142.795 21.1119L141.068 21.6783C140.885 21.7385 140.715 21.8343 140.569 21.9601C140.422 22.086 140.302 22.2394 140.215 22.4116C140.039 22.7595 140.009 23.1629 140.131 23.5332C140.191 23.7165 140.287 23.8862 140.412 24.0326C140.538 24.1789 140.692 24.299 140.864 24.3861C141.212 24.5619 141.615 24.5923 141.986 24.4706L143.713 23.9042L144.279 25.6314C144.339 25.8148 144.435 25.9845 144.561 26.1308C144.687 26.2771 144.84 26.3972 145.013 26.4843C145.185 26.5713 145.372 26.6236 145.565 26.6381C145.757 26.6526 145.951 26.6291 146.134 26.5688C146.317 26.5086 146.487 26.4128 146.633 26.287C146.78 26.1612 146.9 26.0078 146.987 25.8355C147.074 25.6633 147.126 25.4756 147.141 25.2832C147.155 25.0907 147.132 24.8973 147.072 24.7139L146.505 22.9867L148.232 22.4203C148.416 22.36 148.585 22.2643 148.732 22.1384C148.878 22.0126 148.998 21.8592 149.085 21.687C149.172 21.5147 149.225 21.327 149.239 21.1346C149.254 20.9421 149.23 20.7487 149.17 20.5654C149.109 20.382 149.014 20.2123 148.888 20.066C148.762 19.9197 148.609 19.7995 148.436 19.7125C148.264 19.6255 148.076 19.5732 147.884 19.5587C147.692 19.5442 147.498 19.5677 147.315 19.628L147.311 19.6399Z",
      fill: "#00D38C"
    }), _jsx("path", {
      d: "M128.79 3.24897L127.785 2.5509L128.483 1.56561C128.635 1.35137 128.696 1.08539 128.652 0.826182C128.609 0.56697 128.464 0.33576 128.249 0.183414C128.143 0.107981 128.023 0.0541797 127.896 0.0250826C127.77 -0.00401447 127.638 -0.00783797 127.51 0.0138313C127.251 0.0575943 127.019 0.202537 126.867 0.416772L126.169 1.422L125.168 0.723926C125.06 0.649278 124.939 0.596565 124.811 0.568795C124.683 0.541025 124.551 0.538743 124.422 0.562079C124.293 0.585415 124.17 0.633912 124.06 0.704801C123.949 0.77569 123.854 0.867584 123.78 0.975234C123.705 1.08288 123.652 1.20418 123.625 1.33221C123.597 1.46023 123.594 1.59247 123.618 1.72137C123.641 1.85027 123.69 1.97332 123.761 2.08348C123.831 2.19364 123.923 2.28876 124.031 2.36341L125.032 3.06149L124.334 4.06672C124.195 4.28221 124.145 4.54342 124.194 4.79519C124.244 5.04695 124.389 5.26959 124.6 5.41609C124.811 5.56259 125.07 5.62148 125.323 5.58034C125.577 5.53921 125.804 5.40126 125.958 5.19561L126.656 4.19038L127.665 4.86851C127.771 4.94264 127.891 4.99509 128.018 5.02288C128.144 5.05066 128.275 5.05324 128.403 5.03046C128.53 5.00768 128.652 4.95999 128.762 4.89011C128.871 4.82023 128.965 4.72953 129.039 4.62319C129.113 4.51685 129.166 4.39695 129.194 4.27034C129.221 4.14372 129.224 4.01288 129.201 3.88527C129.178 3.75766 129.131 3.63579 129.061 3.52662C128.991 3.41744 128.9 3.3231 128.794 3.24897H128.79Z",
      fill: "#FF5555"
    }), _jsx("path", {
      d: "M19.3871 68.3578L18.3659 67.6597L19.064 66.6545C19.2033 66.439 19.2534 66.1778 19.2038 65.926C19.1542 65.6742 19.0087 65.4516 18.798 65.3051C18.5873 65.1586 18.3279 65.0997 18.0746 65.1408C17.8213 65.182 17.5939 65.3199 17.4404 65.5256L16.7703 66.5308L15.7651 65.8327C15.5496 65.6934 15.2884 65.6433 15.0366 65.6929C14.7848 65.7425 14.5622 65.888 14.4157 66.0987C14.2692 66.3094 14.2103 66.5688 14.2514 66.8221C14.2926 67.0754 14.4305 67.3028 14.6362 67.4563L15.6414 68.1543L14.9433 69.1596C14.812 69.375 14.768 69.6326 14.8203 69.8794C14.8726 70.1263 15.0172 70.3439 15.2246 70.4876C15.4321 70.6313 15.6866 70.6903 15.9361 70.6526C16.1856 70.6148 16.4112 70.4831 16.5668 70.2845L17.2649 69.2832L18.2702 69.9813C18.3765 70.0607 18.4977 70.1179 18.6267 70.1494C18.7556 70.181 18.8895 70.1863 19.0205 70.165C19.1515 70.1437 19.2769 70.0963 19.3892 70.0256C19.5015 69.9549 19.5984 69.8623 19.6741 69.7533C19.7499 69.6443 19.803 69.5212 19.8302 69.3913C19.8574 69.2615 19.8582 69.1274 19.8325 68.9972C19.8068 68.867 19.7552 68.7433 19.6808 68.6334C19.6063 68.5236 19.5105 68.4298 19.399 68.3578H19.3871Z",
      fill: "#FF5555"
    }), _jsx("path", {
      d: "M16.2437 16.5085H14.6042V14.869C14.6042 14.5178 14.4647 14.1809 14.2164 13.9326C13.968 13.6842 13.6311 13.5447 13.2799 13.5447C12.9287 13.5447 12.5918 13.6842 12.3434 13.9326C12.0951 14.1809 11.9555 14.5178 11.9555 14.869V16.5085H10.3161C9.96481 16.5085 9.62796 16.648 9.37959 16.8964C9.13123 17.1448 8.9917 17.4816 8.9917 17.8329C8.9917 18.1841 9.13123 18.521 9.37959 18.7693C9.62796 19.0177 9.96481 19.1572 10.3161 19.1572H11.9555V20.7967C11.9555 21.1479 12.0951 21.4848 12.3434 21.7332C12.5918 21.9815 12.9287 22.1211 13.2799 22.1211C13.6311 22.1211 13.968 21.9815 14.2164 21.7332C14.4647 21.4848 14.6042 21.1479 14.6042 20.7967V19.1572H16.2437C16.595 19.1572 16.9318 19.0177 17.1802 18.7693C17.4286 18.521 17.5681 18.1841 17.5681 17.8329C17.5681 17.4816 17.4286 17.1448 17.1802 16.8964C16.9318 16.648 16.595 16.5085 16.2437 16.5085Z",
      fill: "#A3C5EB"
    }), _jsx("path", {
      d: "M144.782 55.9399L144.311 56.8175L143.434 56.3468C143.34 56.297 143.238 56.2661 143.133 56.2558C143.028 56.2455 142.922 56.256 142.82 56.2868C142.719 56.3175 142.625 56.3679 142.544 56.435C142.462 56.5021 142.394 56.5846 142.345 56.6778C142.295 56.7711 142.264 56.8732 142.254 56.9784C142.243 57.0836 142.254 57.1898 142.285 57.2909C142.315 57.392 142.366 57.4861 142.433 57.5678C142.5 57.6494 142.582 57.7171 142.676 57.7668L143.553 58.2375L143.082 59.1151C143.033 59.2084 143.002 59.3105 142.992 59.4157C142.981 59.5209 142.992 59.6271 143.023 59.7282C143.053 59.8293 143.104 59.9234 143.171 60.0051C143.238 60.0867 143.32 60.1544 143.414 60.2041C143.507 60.2539 143.609 60.2848 143.714 60.2951C143.819 60.3054 143.926 60.2949 144.027 60.2641C144.128 60.2334 144.222 60.183 144.304 60.1159C144.385 60.0488 144.453 59.9663 144.503 59.873L144.973 58.9955L145.851 59.4622C146.039 59.5627 146.259 59.5845 146.463 59.5228C146.666 59.461 146.837 59.3209 146.938 59.1331C147.038 58.9453 147.06 58.7253 146.998 58.5214C146.937 58.3176 146.797 58.1466 146.609 58.0461L145.731 57.5754L146.198 56.6978C146.248 56.6048 146.279 56.5029 146.289 56.398C146.299 56.293 146.289 56.1871 146.259 56.0861C146.228 55.9852 146.178 55.8913 146.111 55.8097C146.044 55.7281 145.962 55.6605 145.869 55.6108C145.776 55.561 145.674 55.5301 145.569 55.5197C145.464 55.5093 145.358 55.5196 145.257 55.5502C145.156 55.5808 145.062 55.6309 144.981 55.6978C144.899 55.7646 144.832 55.8469 144.782 55.9399Z",
      fill: "#A3C5EB"
    }), _jsx("path", {
      d: "M88.9276 6.79119H87.4237V5.28733C87.4237 4.96465 87.2956 4.65519 87.0674 4.42703C86.8392 4.19886 86.5298 4.07068 86.2071 4.07068C85.8844 4.07068 85.575 4.19886 85.3468 4.42703C85.1186 4.65519 84.9904 4.96465 84.9904 5.28733V6.79119H83.4866C83.1644 6.79119 82.8555 6.91916 82.6277 7.14695C82.3999 7.37474 82.2719 7.6837 82.2719 8.00584C82.2719 8.32799 82.3999 8.63694 82.6277 8.86474C82.8555 9.09253 83.1644 9.2205 83.4866 9.2205H84.9825V10.7403C84.9825 11.063 85.1107 11.3725 85.3388 11.6006C85.567 11.8288 85.8764 11.957 86.1991 11.957C86.5218 11.957 86.8313 11.8288 87.0594 11.6006C87.2876 11.3725 87.4158 11.063 87.4158 10.7403V9.2205H88.9196C89.2418 9.2205 89.5507 9.09253 89.7785 8.86474C90.0063 8.63694 90.1343 8.32799 90.1343 8.00584C90.1343 7.6837 90.0063 7.37474 89.7785 7.14695C89.5507 6.91916 89.2418 6.79119 88.9196 6.79119H88.9276Z",
      fill: "#FBB81B"
    }), _jsx("path", {
      d: "M54.2311 7.36567L52.9347 6.67558L53.6248 5.37915C53.7274 5.10718 53.7275 4.80715 53.6251 4.53511C53.5227 4.26306 53.3248 4.03759 53.0683 3.9008C52.8118 3.76401 52.5143 3.72525 52.2313 3.79176C51.9484 3.85827 51.6993 4.0255 51.5306 4.26222L50.8405 5.55466L49.548 4.86456C49.2761 4.76195 48.976 4.76184 48.704 4.86425C48.4319 4.96666 48.2065 5.16459 48.0697 5.42107C47.9329 5.67756 47.8941 5.97507 47.9606 6.25804C48.0272 6.54101 48.1944 6.7901 48.4311 6.9588L49.7275 7.64889L49.0374 8.94532C48.9377 9.08395 48.8688 9.24232 48.8354 9.40978C48.8019 9.57725 48.8047 9.74993 48.8435 9.91623C48.8824 10.0825 48.9563 10.2386 49.0605 10.3739C49.1646 10.5093 49.2965 10.6208 49.4473 10.7009C49.5981 10.7811 49.7643 10.828 49.9348 10.8386C50.1052 10.8491 50.2759 10.8231 50.4355 10.7622C50.595 10.7014 50.7397 10.6071 50.8598 10.4856C50.9799 10.3642 51.0726 10.2185 51.1317 10.0583L51.8218 8.76183L53.1142 9.45592C53.3862 9.55853 53.6862 9.55864 53.9582 9.45623C54.2303 9.35382 54.4558 9.15589 54.5926 8.89941C54.7294 8.64292 54.7681 8.34541 54.7016 8.06244C54.6351 7.77947 54.4679 7.53038 54.2311 7.36168V7.36567Z",
      fill: "#00D38C"
    })]
  });
};
GroupSparkle.displayName = "GroupSparkle";