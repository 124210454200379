import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
const exports = {};

/*

Example Repository entity:

{
  branches: [Branch...],
  created_at: "2016-05-18T21:19:02Z",
  external_id: "56009352",
  full_name: "useshortcut/shortcut.js",
  id: 500004768,
  name: "shortcut.js",
  updated_at: "2016-05-24T15:51:18Z",
  url: "https://github.com/useshortcut/shortcut.js"
}

*/

Collection.create('Repository', exports);
exports.fetchAll = callback => {
  Backend.get('/api/private/repositories', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
export { exports as default };