import { Swatch } from 'components/shared/Swatch';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getGroupConfig = (groupKey, options, allowBulkSelect) => {
  if (!groupKey || !options[0]) return null;
  return {
    sectionId: groupKey,
    sectionTitle: options[0].group.name,
    allowBulkSelect
  };
};
export const getSection = (groupKey, options, _ref) => {
  let {
    hasMultiplePages,
    isFiltered
  } = _ref;
  return {
    ...getGroupConfig(groupKey, options, !hasMultiplePages && !isFiltered),
    items: options.map(_ref2 => {
      let {
        node
      } = _ref2;
      return {
        value: node.id,
        name: node.name,
        Icon: () => _jsx(Swatch, {
          backgroundColor: node.color
        })
      };
    })
  };
};