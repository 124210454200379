import "core-js/modules/es.array.push.js";
import BaseUtils from '../_frontloader/baseUtils';
import Globals from '../_frontloader/globals';
import Is from './is';
import Iterate from './iterate';
import Log from './log';
import PermissionModel from '../models/permission';
import Url from './url';
import User from './user';
import Utils from './utils';
import * as Event from '../_frontloader/event';
import { onApplicationStateChanged } from 'utils/collectionizeToApolloMessageBus';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { VALID_FILTER_KEYS } from 'components/detailPageStoryTable/storyTableSettingsConfig';
import { isEmpty, isString, keys, omit } from 'lodash';
import { logError } from 'utils/monitoring';
const exports = {};
const QUICK_SYNC_DELAY = 1000; // 1 second
const GLOBALS_KEY = 'applicationState';
const GLOBALS_RAW_KEY = 'applicationRawState';
let syncTimeout;
const pendingUpdates = new Map();
const applyAndClearPendingUpdates = applicationState => {
  applicationState = {
    ...applicationState
  };
  pendingUpdates.forEach((value, key) => {
    if (value === null) {
      delete applicationState[key];
    } else {
      applicationState[key] = value;
    }
  });
  pendingUpdates.clear();
  return applicationState;
};
exports.hasPendingUpdate = key => pendingUpdates.has(key);
exports.resetPendingUpdates = () => pendingUpdates.clear();
exports.set = function (key, value) {
  let {
    syncDelay
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    syncDelay: QUICK_SYNC_DELAY
  };
  // Guard against setting applicationState from the /organizations page.
  const user = User.getCurrentUser();
  if (!user) {
    return;
  }
  const applicationState = Globals.setOnlyIfMissing(GLOBALS_KEY, {});
  pendingUpdates.set(key, value);
  if (value === null) {
    delete applicationState[key];
  } else {
    applicationState[key] = value;
  }
  clearTimeout(syncTimeout);
  syncTimeout = setTimeout(exports.sync, syncDelay);
  Event.trigger(`applicationStateChanged.${key}`);
  return applicationState[key];
};
exports.addListener = (key, listener) => {
  Event.on(`applicationStateChanged.${key}`, listener);
};
exports.removeListener = (key, listener) => {
  Event.off(`applicationStateChanged.${key}`, listener);
};
exports.get = key => {
  // Guard against getting applicationState from the /organizations page.
  const user = User.getCurrentUser();
  if (!user) {
    return;
  }
  const applicationState = Globals.setOnlyIfMissing(GLOBALS_KEY, {});

  // This currently only affects the Stories page table groupby settings
  // Assuming we won't ever add more features/settings involving projects, this shouldn't cause issues for other pages.
  if (applicationState[key] === 'project_id' && !isProjectsFeatureEnabled()) {
    return 'none';
  }
  return applicationState[key];
};
exports.remove = key => {
  return exports.set(key, null);
};
function _cleanAppState(data) {
  try {
    data = _removeHashedKeys(data);
    data = _removeNoOpStoryTableFilters(data);
    data = _copyStoriesSettingsToV2(data);
  } catch (err) {
    logError(err);
  }
  return data;
}

// Copy settings from Stories v1 to Stories v2 if they don't exist already.
function _copyStoriesSettingsToV2(data) {
  if (!data) return data;
  if (typeof data.StoriesPageSpaceHeaderCardDensity !== 'string') {
    data.StoriesPageSpaceHeaderCardDensity = data.StoryDensity;
  }
  if (typeof data.StoriesPageSpaceHeaderColumnCollapse !== 'boolean') {
    data.StoriesPageSpaceHeaderColumnCollapse = data.CollapseEmptyColumns;
  }
  return data;
}

// This removes story table filters that are no-op to the user experience in an effort to reduce app state size.
function _removeNoOpStoryTableFilters(data) {
  const updated = {};
  if (!data) return data;
  Object.entries(data).forEach(_ref => {
    let [k, v] = _ref;
    if (/^(Epic|Iteration|Label)Page\.\d+\.StoryFilter$/.test(k)) {
      // If the value isn't an object, remove it.
      if (typeof v !== 'object' || !v) return;
      const cleanedFilterState = Object.entries(v).reduce((acc, _ref2) => {
        let [key, value] = _ref2;
        // Remove any keys other than these since they are not used
        if (!VALID_FILTER_KEYS.includes(key)) return acc;
        // Remove empty values
        if (isEmpty(value)) return acc;
        acc[key] = value;
        return acc;
      }, {});
      if (!isEmpty(cleanedFilterState)) {
        updated[k] = cleanedFilterState;
      }
    } else {
      updated[k] = v;
    }
  });
  return updated;
}

// Convert "CLUBHOUSE.1878175014.EpicPage.11546.StoryFilter" to just "EpicPage.11546.StoryFilter".
// We should leave this in for a while and monitor how long it takes for this to trail off.
function _removeHashedKeys(data) {
  const migrated = {};
  const hashedKeys = [];
  Iterate.each(_removeOldKeys(data), (v, k) => {
    if (_keyHasHash(k)) {
      hashedKeys.push(k);
    }
    migrated[_keyWithoutHash(k)] = v;
  });
  if (hashedKeys.length > 0) {
    Log.log('Removing permission hash from applicationState keys!', {
      hashedKeys
    });
  }
  return migrated;
}

// Let this run for a month or two, and keep an eye on this Rollbar issue:
function _removeOldKeys(data) {
  const oldKeys = ['CLUBHOUSE.viewedActivityTimestamp',
  // Stored in LocalStorage instead
  'viewedActivityTimestamp',
  // Stored in LocalStorage instead
  'CLUBHOUSE.OldSpaces',
  // no longer used
  'OldSpaces',
  // no longer used
  'CLUBHOUSE.Space',
  // no longer used
  'Workspace',
  // no longer used
  'CLUBHOUSE.displayStoriesNotInEpics',
  // Stored in Globals instead
  'CLUBHOUSE.lastView',
  // 'lastView' is used
  'teamFilter',
  // no longer used
  'fullscreen',
  // no longer used
  'lastMigrationRun',
  // no longer used
  'expandedStories',
  // no longer used
  'TrainingPage', 'TrainingProgress',
  // no longer used(renamed to Training)
  'Roadmap.roadmapFilter', 'Roadmap.UrlParams'];
  return omit(data, oldKeys);
}
function _keyHasHash(key) {
  return key.indexOf('CLUBHOUSE.') === 0;
}
function _keyWithoutHash(key) {
  return key.replace(/CLUBHOUSE\.[0-9a-zA-Z]*\./, '');
}
exports.sync = () => {
  clearTimeout(syncTimeout);
  const user = User.getCurrentUser();
  if (!user) {
    Log.log('No user found, not saving applicationState!');
    return false;
  }
  PermissionModel.fetchCurrentApplicationState(() => {
    let applicationState = Globals.get(GLOBALS_KEY);
    if (isString(applicationState)) {
      Log.log('[ch12910] application state was already a string!', {
        applicationState
      });
      applicationState = JSON.parse(applicationState);
    }
    const stringifiedData = JSON.stringify(_cleanAppState(applicationState));

    // There should be at least 3 keys saved on initial page load.
    if (keys(applicationState).length < 3) {
      Log.log('[ch12910] Trying to save applicationState with less than three keys!', {
        stringifiedData
      });
      return;
    }
    if (!stringifiedData || stringifiedData === '{}' || stringifiedData === '[]') {
      // This has never happened, as of Jul 30 2017.
      Log.log('[ch12910] Trying to save empty applicationState!', {
        stringifiedData
      });
      return;
    }
    if (stringifiedData && stringifiedData !== Globals.get(GLOBALS_RAW_KEY)) {
      Globals.set(GLOBALS_RAW_KEY, stringifiedData);
      PermissionModel.saveApplicationState(stringifiedData);
    }
  });
};
let isListeningForApplicationStateUpdatesFromApollo = false;
exports.restore = data => {
  if (!isListeningForApplicationStateUpdatesFromApollo) {
    onApplicationStateChanged(exports.restore);
    isListeningForApplicationStateUpdatesFromApollo = true;
  }
  const parsed = _cleanAppState(Utils.parseJSON(data, {
    fallback: {}
  }));
  if (keys(parsed).length === 0) {
    // This has never happened, as of Jul 29 2017.
    Log.log('[ch12910] Parsed application state is empty!', {
      data
    });
  }
  const convertedKeys = [];
  Iterate.each(parsed, (val, key) => {
    if (val === 'true') {
      convertedKeys.push(key);
      parsed[key] = true;
    } else if (val === 'false') {
      convertedKeys.push(key);
      parsed[key] = false;
    } else if (Is.arrayString(val) || Is.objectString(val)) {
      convertedKeys.push(key);
      parsed[key] = Utils.parseJSON(val);
    } else if (Is.numericString(val)) {
      convertedKeys.push(key);
      parsed[key] = BaseUtils.toNumber(val);
    }
  });
  if (convertedKeys.length > 0) {
    Log.log('Converted application state to JSON.', {
      convertedKeys
    });
  }
  Globals.set(GLOBALS_RAW_KEY, data);
  Globals.set(GLOBALS_KEY, applyAndClearPendingUpdates(parsed));
};

// ----- App specific usage -----

exports.setTableSort = options => {
  const sortDirection = exports.get(options.prefix + '.SortDirection') || options.defaultDirection;
  const sortBy = exports.get(options.prefix + '.SortBy') || options.defaultSort;
  const newSortBy = options.sortBy;
  if (sortBy === newSortBy && sortDirection === 'ascending') {
    exports.set(options.prefix + '.SortDirection', 'descending');
  } else {
    exports.set(options.prefix + '.SortDirection', 'ascending');
  }
  exports.set(options.prefix + '.SortBy', newSortBy);
};
exports.getLastView = () => {
  let lastView = exports.get('lastView');
  if (lastView === '/files') {
    lastView = '/dashboard';
  }
  return lastView || '/stories';
};
exports.setLastView = pathname => {
  exports.set('lastView', pathname || Url.getCurrentPathnameWithHash());
};
export { exports as default };