const exports = {};

// This is faster than lodash.each, but we're really trying to avoid
// issues on Mobile Safari 8.0, where lodash.each is giving us trouble.
// http://jsperf.com/jquery-each-vs-for-loop/40
exports.each = (obj, cb) => {
  if (obj && _.isFunction(cb)) {
    if (_.isPlainObject(obj)) {
      return _eachObject(obj, cb);
    } else if (_.isArray(obj) || _.isString(obj) || obj && obj.length) {
      return _eachArray(obj, cb);
    }
  }
};
function _eachArray(obj, cb) {
  let i = 0;
  const max = obj.length;
  for (; i < max; i++) {
    if (cb(obj[i], i, obj) === false) {
      return false;
    }
  }
}
function _eachObject(obj, cb) {
  let key;
  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (cb(obj[key], key, obj) === false) {
        return false;
      }
    }
  }
}
export { exports as default };