import { nanoid } from 'nanoid';
import { assign, setup } from 'xstate';
import { convertActiveTabForMonitoring } from '../monitoring/convertActiveTabForMonitoring';
import { convertSearchOperatorForMonitoring } from '../monitoring/convertSearchOperatorForMonitoring';
import { convertTypenameToEntityType } from '../monitoring/convertTypenameToEntityType';
import { logQuickSearchInteraction } from '../monitoring/logQuickSearchInteraction';
export const quickSearchSessionMachine = setup({
  types: {
    context: {},
    events: {}
  },
  guards: {
    isTextEmpty: _ref => {
      let {
        event
      } = _ref;
      return event.type === 'INPUT_TEXT' && event.text.trim().length === 0;
    },
    isTextNotEmpty: _ref2 => {
      let {
        event
      } = _ref2;
      return event.type === 'INPUT_TEXT' && event.text.trim().length > 0;
    }
  },
  actions: {
    startNewSession: assign({
      sessionStartTimestamp: () => new Date(),
      sessionId: () => nanoid(),
      inputText: '',
      selectedEntitiesCount: 0,
      results: null,
      selectedTab: null
    }),
    updateInputText: assign({
      inputText: _ref3 => {
        let {
          event
        } = _ref3;
        if (event.type !== 'INPUT_TEXT') {
          return null;
        }
        return event.text;
      },
      results: null
    }),
    logInteraction: _ref4 => {
      let {
        context,
        event,
        self
      } = _ref4;
      const sessionData = {
        search_session_id: context.sessionId,
        search_session_start: context.sessionStartTimestamp,
        search_session_seconds: Math.round((new Date().getTime() - (context.sessionStartTimestamp ?? new Date()).getTime()) / 1000)
      };
      if (event.type === 'OPEN_SEARCH') {
        logQuickSearchInteraction({
          action_context: 'quick search opened',
          interaction_type: 'search-panel-opened',
          ...sessionData,
          // We aren't in the right state to read it from the state machine, but it's always the default view, so we can hard code it.
          is_recently_viewed_selected: true
        });
      }
      if (event.type === 'CLOSE_SEARCH') {
        logQuickSearchInteraction({
          action_context: 'quick search closed',
          interaction_type: 'search-panel-closed',
          ...sessionData,
          tab_selected: convertActiveTabForMonitoring(context.selectedTab),
          user_query_text_input: context.inputText ?? undefined,
          is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
        });
      }
      if (event.type === 'INTERACTION') {
        if (event.interactionType === 'entity-selected') {
          logQuickSearchInteraction({
            action_context: 'quick search entity selected',
            interaction_type: event.interactionType,
            ...sessionData,
            selection_entity_type: convertTypenameToEntityType(event.entity.__typename),
            selection_entity_public_id: event.entity.__typename === 'Doc' ? event.entity.urlId : event.entity.publicId?.toString(),
            selection_results_index: event.index,
            selection_click_order: context.selectedEntitiesCount,
            total_results_returned: context.results?.total ?? 0,
            total_stories_returned: context.results?.stories ?? 0,
            total_epics_returned: context.results?.epics ?? 0,
            total_objectives_returned: context.results?.objectives ?? 0,
            total_iterations_returned: context.results?.iterations ?? 0,
            total_docs_returned: context.results?.docs ?? 0,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'recent-searches-viewed') {
          logQuickSearchInteraction({
            action_context: 'quick search recent searches viewed',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'recent-searches-applied') {
          logQuickSearchInteraction({
            action_context: 'quick search recent searches applied',
            interaction_type: event.interactionType,
            ...sessionData,
            recent_search_queries: event.recentSearches,
            user_query_text_input: event.selection ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'expand-operators') {
          logQuickSearchInteraction({
            action_context: 'quick search operators expanded',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'view-all-search-operators') {
          logQuickSearchInteraction({
            action_context: 'quick search view all search operators',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'search-operator-user-input') {
          logQuickSearchInteraction({
            action_context: 'quick search search operator user input',
            interaction_type: event.interactionType,
            ...sessionData,
            search_operator_user_input: event.operatorUserInput,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'search-operator-selected') {
          logQuickSearchInteraction({
            action_context: 'quick search search operator selected',
            interaction_type: event.interactionType,
            ...sessionData,
            search_operator_selected: convertSearchOperatorForMonitoring(event.operator),
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'advanced-search-view-stories-in-page') {
          logQuickSearchInteraction({
            action_context: 'quick search advanced search view stories in page',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
      }
      if (event.type === 'SELECT_TAB') {
        logQuickSearchInteraction({
          action_context: 'quick search entity tab selected',
          interaction_type: 'entity-tab-selected',
          ...sessionData,
          tab_selected: convertActiveTabForMonitoring(event.tab),
          user_query_text_input: context.inputText ?? undefined,
          is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
        });
      }
      if (event.type === 'INPUT_TEXT' && !self.getSnapshot().matches('closed')) {
        logQuickSearchInteraction({
          action_context: 'quick search text input',
          interaction_type: 'user-query-text-input',
          ...sessionData,
          user_query_text_input: event.text,
          is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
        });
      }
    },
    maybeBumpSelectedEntitiesCount: assign({
      selectedEntitiesCount: _ref5 => {
        let {
          context,
          event
        } = _ref5;
        if (event.type === 'INTERACTION' && event.interactionType === 'entity-selected') {
          return context.selectedEntitiesCount + 1;
        }
        return context.selectedEntitiesCount;
      }
    }),
    setResults: assign({
      results: _ref6 => {
        let {
          event
        } = _ref6;
        if (event.type !== 'RESULTS') {
          return null;
        }
        return event.results;
      }
    }),
    selectTab: assign({
      selectedTab: _ref7 => {
        let {
          event
        } = _ref7;
        // We want to reset the tab if we switch between Recently Viewed <> Search Results
        if (['OPEN_SEARCH', 'INPUT_TEXT'].includes(event.type)) {
          return 'story';
        }
        if (event.type !== 'SELECT_TAB') {
          return null;
        }
        return event.tab;
      }
    })
  }
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEcCuBLAxgawLSzAEMAnTAC3zlnQHsA7AOkwBsaCIBiAeQAUBRAHIB9AMp8AggCUAwgAkA2gAYAuolAAHNugAutOmpAAPRAFYAHCYYBGRQE4ALFZMB2E4oBsZgMwAmADQgAJ6IPor2DLYePu7OZlbu9l5mzvYAvqkBaFh4BCTklLDU9AzEYJhgdNrMgQBq6GAA7pAcAJICPACqACpCXXwAGl1KqkggmtS69AbGCOaWNg5Orh7e-kGmVrYMXlY+ifb2Lntx6ZkYOJR5FASFeiVlFVW19U2c0gAyXGKiEjIKKgZxjo9NNEJ4vAx7LZodCvOYrPZFM4AsEEHFITDbMknPYEs5TiAshdcqRrlQ7qVypVqnVGs02n1JOJpF0WlwBMNAVpJvpRjNkmYGGYfHF3D4krD3F4USFbM4GO47L4TLinCYEQSiTkiKSCkVGCTyJI4KhmNpYK12t1egMhgDRkCeaCED4rILhYodq5bCr3G57DKEF4xdYleqrMGzPsvJrztqrnq7oayMbYKbzRwPl8+D8pHJOQ7uSC+SE3ULQl6TD7cf7A5t3OWfJE4mFFDYvPiMoS45ddTd9Qxk6n0xaGXwmSy2Rz7Roi1MSwhDgwfM4vAcOwk2z4RSZAwcG4kUp7gzE252u3QaBA4AYtb38v3i7OJk+jIhcD4GM5oiYTGK4VizjOE4ga4A2igQZBUGQa6sbZPeZK3MULBsJAXIvvOoAzPYQFCgBJjRO40JuLYgbihCQGKvYZiRCsTZwcSOoPuSxSUo8NIvGhhYYbyWGIMklgQbEvieK49g+Lu6xBtCDBRIcQFmFuQEMfGfYsQaTEpiaZrwNxwKYW+i7IlJKryjRigmHCngEbEVjpOkQA */
  id: 'quick-search-session',
  initial: 'closed',
  context: {
    sessionStartTimestamp: null,
    sessionId: null,
    inputText: null,
    selectedEntitiesCount: 0,
    results: null,
    selectedTab: null
  },
  states: {
    closed: {
      on: {
        OPEN_SEARCH: {
          target: 'recentlyViewed',
          actions: ['startNewSession', 'logInteraction']
        }
      }
    },
    recentlyViewed: {
      entry: ['selectTab'],
      on: {
        INPUT_TEXT: {
          guard: 'isTextNotEmpty',
          target: 'searchResults',
          actions: ['logInteraction', 'updateInputText']
        },
        CLOSE_SEARCH: {
          target: 'closed',
          actions: ['logInteraction']
        },
        INTERACTION: {
          actions: ['logInteraction', 'maybeBumpSelectedEntitiesCount']
        },
        RESULTS: {
          actions: ['setResults']
        },
        SELECT_TAB: {
          actions: ['selectTab', 'logInteraction']
        }
      }
    },
    searchResults: {
      entry: ['selectTab'],
      on: {
        INPUT_TEXT: {
          guard: 'isTextEmpty',
          target: 'recentlyViewed',
          actions: ['logInteraction', 'updateInputText', 'selectTab']
        },
        CLOSE_SEARCH: {
          target: 'closed',
          actions: ['logInteraction']
        },
        INTERACTION: {
          actions: ['logInteraction', 'maybeBumpSelectedEntitiesCount']
        },
        RESULTS: {
          actions: ['setResults']
        },
        SELECT_TAB: {
          actions: ['selectTab', 'logInteraction']
        }
      }
    }
  },
  on: {
    INPUT_TEXT: {
      actions: ['logInteraction', 'updateInputText']
    }
  }
});