import { THEME_NAMES } from '@clubhouse/shared/utils/theme';
import { COLORS } from '../../../colors';
import { COLOR, SIZE } from './types';
export const THEME_BY_COLOR = {
  [THEME_NAMES.DARK]: {
    [COLOR.DEFAULT]: {
      color: COLORS.GRAY87
    },
    [COLOR.LIGHT]: {
      color: COLORS.GRAY70
    },
    [COLOR.XLIGHT]: {
      color: COLORS.GRAY40
    },
    [COLOR.WHITE]: {
      color: COLORS.WHITE
    }
  },
  [THEME_NAMES.LIGHT]: {
    [COLOR.DEFAULT]: {
      color: COLORS.GRAY27
    },
    [COLOR.LIGHT]: {
      color: COLORS.GRAY50
    },
    [COLOR.XLIGHT]: {
      color: COLORS.GRAY70
    },
    [COLOR.WHITE]: {
      color: COLORS.WHITE
    }
  }
};
export const THEME_BY_SIZE = {
  [SIZE.LARGE]: {
    fontSize: 16,
    lineHeight: 22 / 16
  },
  [SIZE.MEDIUM]: {
    fontSize: 14,
    lineHeight: 19 / 14
  },
  [SIZE.SMALL]: {
    fontSize: 13,
    lineHeight: 18 / 13
  },
  [SIZE.XSMALL]: {
    fontSize: 12,
    lineHeight: 17 / 12
  },
  [SIZE.XXSMALL]: {
    fontSize: 11,
    lineHeight: 16 / 11
  }
};