import { Nouns } from '@clubhouse/shared/constants';
import { EpicStateWithIcon } from 'components/gql/epicState/EpicStateWithIcon';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const config = {
  getKey: _ref => {
    let {
      epicState
    } = _ref;
    return epicState?.id;
  },
  getHeader: _ref2 => {
    let {
      epicState
    } = _ref2;
    return () => _jsx(GroupByHeader, {
      entityType: Nouns.State,
      children: epicState ? _jsx(EpicStateWithIcon, {
        spacing: 10,
        name: epicState.name,
        type: epicState.type
      }) : 'No State'
    });
  }
};