import * as InPlaceEditorTemplate from 'app/client/core/views/templates/inPlaceEditor.html';
import Autosize from '../modules/autosize';
import Utils from '../modules/utils';
const exports = {};
exports.cavemanName = 'InPlaceEditor';
const PARENT_SELECTOR = '.in-place-editor';
exports.open = function () {
  if (Utils.dragDistance() < 10) {
    exports.render(this);
  }
  return false;
};
exports.cancel = function () {
  const editorElement = $(this).closest(PARENT_SELECTOR);
  exports.destroy(editorElement);
  return false;
};
exports.isEditorOpen = context => {
  const options = _getOptionsFromContext(context);
  const inputType = _getInputType(options);
  return Boolean(context.find(inputType).length);
};
exports.destroy = editorElement => {
  const options = _getOptionsFromContext(editorElement);
  const inputType = _getInputType(options);
  const formElement = editorElement.find(inputType);
  const originalElement = editorElement.prev();
  formElement.off('keyup keydown blur');
  if (options.multiline) {
    Autosize.destroy(formElement);
  }
  editorElement.remove();
  originalElement.show();
  return false;
};
exports.render = context => {
  const originalElement = $(context);
  const model = Utils.getModelFromContext(context);
  const options = _getOptionsFromContext(context);
  const inputType = _getInputType(options);
  const html = InPlaceEditorTemplate.render({
    model,
    value: model[options.key],
    limit: options.limit,
    multiline: options.multiline,
    onCancel: options.onCancel,
    onSave: options.onSave
  });
  const editorElement = $(html).insertAfter(originalElement);
  originalElement.hide();
  const formElement = editorElement.find(inputType);
  if (options.multiline) {
    Autosize.bind(formElement);
  }
  formElement.focus().on('keydown', e => {
    if (options.multiline && Utils.keyPressed(e, 'ENTER')) {
      return false;
    }
  }).on('keyup', function (e) {
    if (Utils.keyPressed(e, 'ESCAPE')) {
      _cancelEdit(options, editorElement, this);
      return false;
    }
  }).on('blur', function () {
    if ($(this).val() === '') {
      _cancelEdit(options, editorElement, this);
    }
    if (originalElement.is(':hidden')) {
      editorElement.find('.save').click();
    }
  }).val('').val(model[options.key]);
  return false;
};
function _cancelEdit(options, editorElement, context) {
  if (options.onCancel) {
    const onCancelFn = Utils.strToFunction(options.onCancel);
    if (_.isFunction(onCancelFn)) {
      onCancelFn.call(context);
      return false;
    }
  } else {
    exports.destroy(editorElement);
    return false;
  }
}
function _getOptionsFromContext(context) {
  return {
    key: Utils.data(context, 'key'),
    limit: Utils.data(context, 'limit'),
    onSave: Utils.data(context, 'on-save'),
    onCancel: Utils.data(context, 'on-cancel'),
    multiline: Utils.data(context, 'multiline') !== 'false'
  };
}
function _getInputType(options) {
  return options && options.multiline ? 'textarea' : 'input';
}
export { exports as default };