import moment from 'moment';
import { assertIsoCalendarDate, getDayOfWeek } from '@clubhouse/shared/utils/date';
import OrganizationModel from 'app/client/core/js/models/organization';
import Constants from 'app/client/core/js/modules/constants';
import FasterMoment from 'app/client/core/js/modules/fasterMoment';
export const toFormattedDateString = x => moment(x).format('MMM Do, YYYY');

/**
 * Returns the UTC offset for the current workspace, in a string format like '-05:00'
 */
export const workspaceUtcOffset = () => {
  return OrganizationModel.getCurrentUtcOffset();
};
export const isoDateToTimestamp = (date, utcOffset) => {
  if (!date) return null;
  assertIsoCalendarDate(date);
  const timestamp = moment(`${date}T00:00:00.000${utcOffset || ''}`).format();
  return timestamp;
};
export const timestampToIsoDate = (timestamp, utcOffset) => {
  if (!timestamp) return null;
  const m = moment(timestamp);
  if (utcOffset) m.utcOffset(utcOffset);
  const date = m.format('YYYY-MM-DD');
  return assertIsoCalendarDate(date);
};

/**
 * Converts a date string sent from the API into an ISOCalendarDate
 */
export const timestampToWorkspaceDate = timestamp => {
  return timestampToIsoDate(timestamp, workspaceUtcOffset());
};

/**
 * Converts an IsoCalendarDate back into a timestamp that the API server will be semi-likely to
 * interpret correctly as a workspace timestamp
 */
export const workspaceDateToTimestamp = date => {
  return isoDateToTimestamp(date, workspaceUtcOffset());
};
export const isWorkingDay = date => {
  const workingDays = OrganizationModel.getCurrent().working_days_in_a_week;
  return workingDays.includes(getDayOfWeek(date));
};
export const dateObjectToShortDate = date => {
  return moment(date).format(Constants.SHORT_DATE_FORMAT);
};
export const isOlderInDays = (date, thresholdInDays) => FasterMoment.isOlderInDays(date, thresholdInDays);
export const getLastBusinessDay = () => {
  let lastDay = moment().subtract(1, 'day');
  const dayNumber = lastDay.day(); // 0: Sunday, 6: Saturday

  if (dayNumber === 0) {
    lastDay = lastDay.subtract(2, 'day');
  } else if (dayNumber === 6) {
    lastDay = lastDay.subtract(1, 'day');
  }
  return lastDay;
};