import { ThirdPartyIcon } from '@useshortcut/shapes-ds';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ExternalLinkChip = _ref => {
  let {
    count,
    icon,
    type = 'External'
  } = _ref;
  if (!count) return null;
  return _jsx(ChipTooltip, {
    content: `This Story has ${pluralize({
      count,
      singular: `${type} Link`,
      plural: `${type} Links`
    })} attached.`,
    children: _jsxs(Chip, {
      children: [_jsx(DeprecatedIconAdapter, {
        width: 18,
        children: _jsx(ThirdPartyIcon, {
          label: pluralize({
            count,
            singular: `${type} Link`,
            plural: type + ' Links'
          }),
          icon: icon
        })
      }), count]
    })
  });
};
ExternalLinkChip.displayName = "ExternalLinkChip";