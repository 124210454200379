import { ObjectiveSearchCardObjectiveFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { MilestoneState } from '@clubhouse/shared/types';
import { timestampToFromNowDate } from '@clubhouse/shared/utils/date';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { getHref as getObjectiveHref } from 'components/gql/objective/links';
import { useObjectiveContextMenuWithQuickSearchSessionLogging } from 'utils/contextMenus/useObjectiveContextMenu';
import { loadURL } from 'utils/hotReload';
import { SearchCard, SearchCardLink } from '../SearchCard/SearchCard';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const objectiveStateNameMap = {
  toDo: 'To Do',
  inProgress: 'In Progress',
  done: 'Done'
};
export const objectiveStateMap = {
  [MilestoneState.InProgress]: 'inProgress',
  [MilestoneState.ToDo]: 'toDo',
  [MilestoneState.Done]: 'done'
};
export const ObjectiveSearchCardObjectiveFragment = ObjectiveSearchCardObjectiveFragmentDoc;
export const ObjectiveSearchCard = _ref => {
  let {
    objective,
    openModalOnClick,
    onClick
  } = _ref;
  const {
    publicId: id,
    objectiveState,
    objectiveName,
    archived,
    updatedAt
  } = objective;
  const url = getObjectiveHref(id);
  const contextMenuProps = useObjectiveContextMenuWithQuickSearchSessionLogging(objective.publicId, useCallback(() => {
    onClick?.({
      ...objective,
      url
    });
  }, [objective, onClick, url]));
  const handleClick = e => {
    onClick?.({
      ...objective,
      url
    });
    if (isClickedWithModifier(e)) return;
    if (onClick) {
      e.preventDefault();
    }

    // This handles whether we navigate to a milestone or add a badge in docs
    if (openModalOnClick) {
      loadURL(url, 'milestones');
    }
  };
  return _jsx(SearchCardLink, {
    onClick: handleClick,
    href: url,
    "data-focusable": true,
    ...contextMenuProps,
    children: _jsxs(SearchCard, {
      isArchived: archived,
      children: [_jsx(SearchCard.Icon, {
        icon: "Objectives",
        isCompleted: objectiveState === WORKFLOW_STATE_TYPES.DONE,
        kind: objectiveState === 'toDo' ? 'default' : objectiveState === 'done' ? 'green' : 'blue',
        label: "Objective",
        isArchived: archived
      }), objectiveName && _jsx(SearchCard.Title, {
        children: _jsx(Emojify, {
          children: objectiveName
        })
      }), _jsxs(SearchCard.Metadata, {
        children: [archived ? _jsx(SearchCard.MetadataItem, {
          children: "Archived"
        }) : _jsx(SearchCard.MetadataItem, {
          children: objectiveStateNameMap[objectiveState]
        }), _jsxs(SearchCard.MetadataItem, {
          children: ["Updated ", timestampToFromNowDate(updatedAt)]
        })]
      })]
    })
  });
};
ObjectiveSearchCard.displayName = "ObjectiveSearchCard";
// Collectionize:
export const MilestoneSearchCardAdapter = props => {
  const objective = {
    __typename: 'Objective',
    id: props.milestone.global_id,
    publicId: Number(props.milestone.id),
    objectiveName: props.milestone.name,
    objectiveState: objectiveStateMap[props.milestone.state],
    updatedAt: props.milestone.updated_at,
    archived: props.milestone.archived
  };
  return _jsx(ObjectiveSearchCard, {
    ...props,
    objective: objective
  });
};
MilestoneSearchCardAdapter.displayName = "MilestoneSearchCardAdapter";