import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import { useCallback, useRef } from 'react';
import { useSearchParam } from 'utils/navigation';
export const SORT_BY_PARAM_NAME = 'sort_by';
export const SORT_DIR_PARAM_NAME = 'sort_dir';
export function useTableSortUrlState(_ref) {
  let {
    initialColumn,
    initialDirection,
    onSort,
    validValues
  } = _ref;
  const {
    param: currentSortColumn,
    setSearchParam: setCurrentSortColumn
  } = useSearchParam({
    paramName: SORT_BY_PARAM_NAME,
    paramType: 'string',
    defaultValue: initialColumn,
    ...insertObjectIf(!!validValues, {
      validValues
    })
  });
  const {
    param: currentSortDir,
    setSearchParam: setCurrentSortDir
  } = useSearchParam({
    paramName: SORT_DIR_PARAM_NAME,
    paramType: 'string',
    defaultValue: initialDirection,
    validValues: ['ascending', 'descending']
  });
  const onSortRef = useRef(onSort);
  onSortRef.current = onSort;
  const handleClick = useCallback(column => {
    if (validValues && !validValues.includes(column)) return;
    if (column === currentSortColumn) {
      setCurrentSortDir(currentSortDir === 'ascending' ? 'descending' : 'ascending');
    } else {
      setCurrentSortColumn(column);
      setCurrentSortDir('ascending');
    }
    if (typeof onSortRef.current === 'function') onSortRef.current({
      oldSort: currentSortColumn,
      newSort: column
    });
  }, [validValues, currentSortColumn, currentSortDir, setCurrentSortColumn, setCurrentSortDir]);
  return {
    sortColumn: currentSortColumn,
    sortDirection: currentSortDir,
    onSortChange: handleClick
  };
}