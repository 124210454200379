import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportEpics = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsxs("svg", {
    width: width,
    height: width,
    viewBox: "0 0 46 46",
    fill: "none",
    children: [_jsx("path", {
      opacity: "0.5",
      d: "M36.9533 14.95H23.8433V27.83H36.9533V14.95Z",
      fill: "#9DD6E9"
    }), _jsx("path", {
      d: "M29.2866 11.8067H16.1766V24.6867H29.2866V11.8067Z",
      fill: "#9DD6E9"
    }), _jsx("path", {
      d: "M14.1833 9.96667V33.9633",
      stroke: "#ADC2DF",
      strokeWidth: "2",
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }), _jsx("path", {
      opacity: "0.5",
      d: "M29.2866 24.6867L23.8433 27.83V24.61L29.2866 24.6867Z",
      fill: "#ADC2DF"
    })]
  });
};
ReportEpics.displayName = "ReportEpics";