import { useEffect, useState } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
import { fetchAnyMatchingDomains, fetchPendingInvites, fetch as fetchSignup } from 'data/entity/signup';
import { isGDPR } from 'data/entity/user';
import { setSessionAttributes } from 'utils/monitoring';
import { usePageParams } from 'utils/navigation';
export const useSignup = ready => {
  const [isLoading, {
    off: loadingCompleted
  }] = useToggleState(true);
  const [signup, setSignup] = useState(null);
  const [isUserGDPR, setIsUserGDPR] = useState(true);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [matchingDomains, setMatchingDomains] = useState([]);
  const [error, setError] = useState();
  const path = usePageParams();
  useEffect(() => {
    const loadSignup = async () => {
      if (!path.signupId) {
        return;
      }
      try {
        const [gdprResponse, invites, domains, signupStatus] = await Promise.allSettled([isGDPR(), fetchPendingInvites(path.signupId), fetchAnyMatchingDomains(path.signupId), fetchSignup(path.signupId)]);
        if (invites.status === 'fulfilled') {
          setPendingInvites(invites.value);
        }
        if (domains.status === 'fulfilled') {
          setMatchingDomains(domains.value);
        }
        if (gdprResponse.status === 'fulfilled') {
          const isGDPRRegion = gdprResponse.value;
          setIsUserGDPR(isGDPRRegion);
        } else {
          setIsUserGDPR(true);
        }
        if (signupStatus.status === 'fulfilled') {
          setSignup(signupStatus.value);
          const user = {
            userId: String(signupStatus.value.user_id),
            email: signupStatus.value.email
          };
          setSessionAttributes(user);
        } else {
          setError(signupStatus.reason);
        }
      } catch (error) {
        setIsUserGDPR(true);
      } finally {
        loadingCompleted();
      }
    };
    if (isLoading && ready && path.signupId) {
      loadSignup();
    }
  }, [isLoading, ready, path.signupId, loadingCompleted]);
  return {
    signup,
    error,
    isUserGDPR,
    isLoading,
    pendingInvites,
    matchingDomains
  };
};