import { isParseableUrl } from 'components/shared/MarkdownEditor/utils';
import { LinkMenu } from '../LinkMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const URL_PREFIX = /^https?:\/\//;
export const LinksAutocomplete = {
  hasPrefix: false,
  test: text => false && !!text && URL_PREFIX.test(text) && isParseableUrl(text),
  // Disable until we can improve
  Component: _ref => {
    let {
      triggerRef,
      fragment
    } = _ref;
    return _jsx(LinkMenu, {
      triggerRef: triggerRef,
      fragment: fragment
    });
  }
};