const TRACKING_ENDPOINT = `${window.location.origin}/backend/api/private/track`;
export const track = data => {
  return fetch(TRACKING_ENDPOINT, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};