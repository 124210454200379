import * as Event from '../_frontloader/event';
import LocalStorage from '../modules/localStorage';
import { NAV_SIZE_KEY, NavSize } from 'components/layout/NavDOMUtils';
import ViewSettingsController from './viewSettings';
const exports = {};
exports.isFullscreen = () => LocalStorage.get(NAV_SIZE_KEY) === NavSize.SMALL;
exports.toggle = () => {
  if (exports.isFullscreen()) {
    ViewSettingsController.density.setStandard();
    Event.trigger('fullscreenEnabled');
  } else {
    ViewSettingsController.density.setJumbo();
    Event.trigger('fullscreenDisabled');
  }
  return false;
};
export { exports as default };