import classNames from 'classnames';
import { useState } from 'react';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { getNumberOfImports } from 'data/entity/import';
import { createSync, useActiveSync } from 'data/entity/sync';
// eslint-disable-next-line import/no-restricted-paths
import { SettingsPageHeader } from 'pages/settings/SettingsPageHeader';
import { ActiveJiraSyncPage } from './ActiveJiraSyncPage';
import { Importer } from './Importer';
import { PastImports } from './PastImports';
import { TeamLinkDialog } from './TeamLinkDialog';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const NO_TEAM_VALUE = 'no-team';
export const ImportDataPage = _ref => {
  let {
    workflows,
    projects,
    clubhouseUsers,
    importModel,
    loadImportDetails,
    pushRouterState,
    resetRouterState
  } = _ref;
  const [activeTab, setActiveTab] = useState('importer');
  const [syncImport, setSyncImport] = useState();
  const activeSync = useActiveSync();
  const isCreateSyncDialogVisible = Boolean(syncImport);
  const closeCreateSyncDialog = () => setSyncImport(undefined);
  const openCreateSyncDialog = imp => setSyncImport(imp);
  const handleSyncSuccess = () => {
    addToast({
      Content: () => _jsx(ToastText, {
        children: "Jira Sync Created"
      }),
      kind: 'success',
      timeout: 2000
    });
    setActiveTab('jira-sync');
  };
  const handleSyncError = () => {
    addToast({
      Content: () => _jsx(ToastText, {
        children: "JIRA Sync was unsuccessful. Please try again, or contact our support team."
      }),
      kind: 'alert',
      timeout: 6000
    });
  };
  const handleCreateSync = async (teamId, importId) => {
    const id = importId ?? syncImport?.id;
    try {
      if (!id) throw new Error('Missing import ID');
      await createSync(id, teamId !== NO_TEAM_VALUE ? teamId : undefined);
      handleSyncSuccess();
    } catch (error) {
      handleSyncError();
    }
  };
  return _jsxs(_Fragment, {
    children: [_jsx(SettingsPageHeader, {
      breadcrumbs: [{
        label: 'Import / Sync'
      }],
      activeItem: 'importData'
    }), _jsx(TeamLinkDialog, {
      visible: isCreateSyncDialogVisible,
      syncImport: syncImport,
      onClose: closeCreateSyncDialog,
      createSync: handleCreateSync
    }, syncImport?.id), _jsxs("div", {
      className: "wrapper",
      children: [_jsxs("div", {
        role: "tablist",
        className: "tabs file-tabs mini clearfix",
        children: [_jsx("button", {
          id: "importer-tab",
          role: "tab",
          "aria-selected": activeTab === 'importer',
          "aria-controls": "importer-panel",
          className: classNames('tab', {
            active: activeTab === 'importer'
          }),
          onClick: () => setActiveTab('importer'),
          style: {
            fontSize: 15
          },
          children: "Importer"
        }), getNumberOfImports() > 0 && _jsx("button", {
          id: "past-imports-tab",
          role: "tab",
          "aria-selected": activeTab === 'past-imports',
          "aria-controls": "past-imports-panel",
          className: classNames('tab', {
            active: activeTab === 'past-imports'
          }),
          onClick: () => setActiveTab('past-imports'),
          style: {
            fontSize: 15
          },
          children: "Past Imports"
        }), !!activeSync && _jsx("button", {
          id: "jira-sync-tab",
          role: "tab",
          "aria-selected": activeTab === 'jira-sync',
          "aria-controls": "jira-sync-panel",
          className: classNames('tab', {
            active: activeTab === 'jira-sync'
          }),
          onClick: () => setActiveTab('jira-sync'),
          style: {
            fontSize: 15
          },
          children: "Jira Sync"
        })]
      }), activeTab === 'importer' ? _jsx("div", {
        id: "importer-panel",
        role: "tabpanel",
        "aria-labelledby": "importer-tab",
        children: _jsx(Importer, {
          existingWorkflows: workflows,
          existingProjects: projects,
          clubhouseUsers: clubhouseUsers,
          importModel: importModel,
          createSync: handleCreateSync,
          openCreateSyncDialog: openCreateSyncDialog,
          pushRouterState: pushRouterState,
          resetRouterState: resetRouterState
        })
      }) : activeTab === 'past-imports' ? _jsx("div", {
        id: "past-imports-panel",
        role: "tabpanel",
        "aria-labelledby": "past-imports-tab",
        children: _jsx(PastImports, {
          openCreateSyncDialog: openCreateSyncDialog,
          loadImportDetails: loadImportDetails
        })
      }) : activeTab === 'jira-sync' && activeSync ? _jsx("div", {
        id: "jira-sync-panel",
        role: "tabpanel",
        "aria-labelledby": "jira-sync-tab",
        children: _jsx(ActiveJiraSyncPage, {
          sync: activeSync,
          onRemove: () => setActiveTab('importer')
        })
      }) : null]
    })]
  });
};