import ReactDOMServer from 'react-dom/server';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { ShapesProvider } from '@useshortcut/shapes-ds';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ShapesThemeProvider = _ref => {
  let {
    children
  } = _ref;
  return _jsx(ShapesProvider, {
    themeName: useThemeName(),
    children: children
  });
};
ShapesThemeProvider.displayName = "ShapesThemeProvider";
export const renderComponentToString = children => ReactDOMServer.renderToString(_jsx(ShapesThemeProvider, {
  children: children
}));
export const htmlStringToElement = string => {
  const template = document.createElement('template');
  template.innerHTML = string.trim();
  return template.content.firstChild;
};