import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TooltipContent = _ref => {
  let {
    blockingCount,
    hasBlockerLabel
  } = _ref;
  let text = 'This Story ';
  if (blockingCount) {
    text += 'is blocking ' + pluralize({
      count: blockingCount,
      singular: 'Story',
      plural: 'Stories'
    });
  }
  if (hasBlockerLabel) {
    text += `${blockingCount ? ', and ' : ' '}has the "blocker" label`;
  }
  text += '.';
  return _jsx(ChipTooltip.Text, {
    children: text
  });
};
TooltipContent.displayName = "TooltipContent";
export const BlockerChip = _ref2 => {
  let {
    blockingCount,
    hasBlockerLabel
  } = _ref2;
  if (!blockingCount && !hasBlockerLabel) return null;
  const blockerChip = _jsxs(Chip, {
    kind: "yellow",
    children: [_jsx(DeprecatedIconAdapter, {
      width: 18,
      children: _jsx(Chip.Icon, {
        icon: "Warn",
        label: "Blocker"
      })
    }), blockingCount || null]
  });
  return _jsx(ChipTooltip, {
    content: _jsx(TooltipContent, {
      blockingCount: blockingCount,
      hasBlockerLabel: hasBlockerLabel
    }),
    children: blockerChip
  });
};
BlockerChip.displayName = "BlockerChip";