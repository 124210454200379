import { useCallback, useEffect, useMemo } from 'react';
import { useAppState } from 'gql/hooks/application-state/useAppState';
import { useSearchParams } from 'utils/navigation';
import { CUMULATIVE_FLOW_WORKFLOW_ID_SEARCH_PARAM } from '../reports-v2/constants';
const ITERATION_REPORT_APP_STATE_KEY = 'IterationPage';
export function computeAppStateKey(iterationId, searchParam) {
  return `${ITERATION_REPORT_APP_STATE_KEY}${iterationId}.Report${
  // We need to use the abbreviated key for the cumulative flow chart because this is what we introduced first.
  // Other charts would be using the full key, but if we change it for CFD, users would lose their saved state.
  searchParam === CUMULATIVE_FLOW_WORKFLOW_ID_SEARCH_PARAM ? '' : `.${searchParam}`}`;
}

/**
 * Hook to manage the state of the report configuration.
 *
 * Expects two parameters: the search param key and the iteration id.
 *
 * It will then read from two sources in the following order:
 * - URLSearchParams
 * - App State
 *
 * If the search param is present, it will use that value.
 * If the search param is missing, it will use the app state value.
 *
 * Also returns a setter to update the config state with a new value that will be
 * persisted in both the URLSearchParams and App State.
 */
export function useReportConfigState(searchParam, iterationId) {
  const {
    params,
    setSearchParams
  } = useSearchParams([searchParam]);
  const searchParamValue = params?.[searchParam]?.[0];
  const [workflowIdState, setWorkflowIdState] = useAppState(computeAppStateKey(iterationId, searchParam), undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want initial state
  const initialState = useMemo(() => ({
    workflowId: workflowIdState
  }), []);
  const setSavedState = useCallback(settings => {
    const {
      workflowId
    } = settings || initialState;
    setSearchParams({
      [searchParam]: workflowId
    });
    setWorkflowIdState(workflowId);
  }, [initialState, searchParam, setSearchParams, setWorkflowIdState]);

  // If we don't have a search param value, but we have a value in the App State,
  // we want to set the value as the search param value.
  useEffect(() => {
    if (!searchParamValue && workflowIdState) {
      setSavedState({
        workflowId: workflowIdState
      });
    }
  }, [searchParamValue, setSavedState, workflowIdState]);
  return [{
    workflowId: searchParamValue || workflowIdState
  }, setSavedState];
}