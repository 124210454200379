import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { getWorkflowStateName } from 'data/entity/story';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TypeChip = () => {
  const story = useContext(StoryContext);
  if (!story) return null;
  return _jsx(StoryCardInternal.Chips.TypeChip, {
    publicId: Number(story.id),
    type: story.story_type,
    workflowStateName: getWorkflowStateName(story),
    workflowStateType: story.stateObject.type
  });
};
TypeChip.displayName = "TypeChip";