import { useEffect } from 'react';
import { C3ChartContainer } from './C3ChartContainer';
import { ReportErrorMsg } from './ReportErrorMsg';
import { ReportHeader } from './ReportHeader';
import { mark, permaIdForChart } from '@clubhouse/shared/utils';
import { destroy, saveChart } from 'utils/charts';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportCard = _ref => {
  let {
    chartId,
    chartData,
    errorMsg,
    onChangeFilter,
    renderChart,
    title,
    description,
    chartStyle,
    Explanation,
    Filters = () => null
  } = _ref;
  useEffect(() => {
    if (chartData) {
      renderChart(chartData).then(chartApi => saveChart(chartId, chartApi));
    }
    return () => destroy(chartId);
  }, [chartId, chartData, renderChart]);
  const onChangeReportFilter = () => {
    if (onChangeFilter) onChangeFilter();
    if (chartData) renderChart(chartData).then(chartApi => saveChart(chartId, chartApi));
  };
  return _jsxs("div", {
    ...mark(permaIdForChart(chartId)),
    children: [_jsx(ReportHeader, {
      title: title,
      reportExplanation: _jsx(Explanation, {
        isEmbedded: false
      }),
      reportFilters: _jsx(Filters, {
        onChange: onChangeReportFilter
      }),
      reportDescription: description
    }), _jsx("div", {
      id: `${chartId}-metrics`
    }), errorMsg && _jsx(ReportErrorMsg, {
      msg: errorMsg
    }), !errorMsg && _jsx(C3ChartContainer, {
      customChartStyle: chartStyle,
      chartId: chartId
    })]
  });
};
ReportCard.displayName = "ReportCard";