import { useEffect, useState } from 'react';
const runQuery = query => window.matchMedia(query);
const addListener = (mql, fn) => {
  if ('addEventListener' in mql) {
    mql.addEventListener('change', fn);
    return () => {
      mql.removeEventListener('change', fn);
    };
  } else {
    mql.addListener(fn);
    return () => {
      mql.removeListener(fn);
    };
  }
};

/**
 * If you are checking the size of the screen, use useBreakpoint instead.
 * But this hook can be used for other types of media queries.
 */
export function useMediaQuery(query) {
  const [state, setState] = useState(() => runQuery(query).matches);
  useEffect(() => {
    let mounted = true;
    const mql = runQuery(query);
    const removeListener = addListener(mql, e => {
      if (mounted) setState(e.matches);
    });
    setState(mql.matches);
    return () => {
      mounted = false;
      removeListener();
    };
  }, [query]);
  return state;
}