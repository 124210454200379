import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import { useOnUnmount } from '@clubhouse/shared/hooks';
import { getApolloClient } from 'gql/utils/initApollo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { trigger } from 'utils/event';
import { isPlaywrightTestEnv } from 'utils/isPlaywrightTestEnv';
import { createAlert } from 'utils/offline';
import { getUpdatesUrl, setupSSEListeners } from 'utils/updates';
import { getDbTime, setDbTime, useDbTime } from './dbTime';
const getUrl = () => {
  const path = '/api/private/sse-updates';
  return getUpdatesUrl(path);
};
const clearCache = async () => {
  const client = await getApolloClient();
  await client?.cache.reset();
};
const EVENT_SOURCE_STATE_NAME = {
  [EventSource.CONNECTING]: 'connecting',
  [EventSource.OPEN]: 'open',
  [EventSource.CLOSED]: 'closed'
};
const getState = s => EVENT_SOURCE_STATE_NAME[s] || 'unknown';
const parseUpdatePayload = payload => (payload || '').split('\n').map(str => {
  try {
    return JSON.parse(str.trim());
  } catch {
    return null;
  }
}).filter(Boolean);
const getEventSourceUrl = (workspaceId, dbTime) => {
  return `${getUrl()}/${workspaceId}/${dbTime ?? getDbTime()}?legacy-updates=1`;
};
const FailedToConnectToast = createAlert({
  kind: 'alert',
  message: 'The server connection for receiving real-time updates could not be established. Please try reloading the page, and contact support if the error persists.'
});
const createEventSource = (url, onMessage, onStateChange) => {
  const eventSource = new EventSource(url, {
    withCredentials: true
  });
  onStateChange(getState(eventSource.readyState));
  eventSource.addEventListener('error', () => onStateChange('error'));
  eventSource.addEventListener('open', () => onStateChange(getState(eventSource.readyState)));
  eventSource.addEventListener('reset', event => onMessage({
    type: 'reset',
    data: null,
    dbTime: event.lastEventId
  }));
  eventSource.addEventListener('update', event => {
    const data = parseUpdatePayload(event.data);
    if (data?.length) onMessage({
      type: 'update',
      data,
      dbTime: event.lastEventId
    });
  });
  eventSource.addEventListener('legacy-update', event => {
    const data = parseUpdatePayload(event.data);
    if (data?.length) onMessage({
      type: 'legacy-update',
      data,
      dbTime: event.lastEventId
    });
  });
  return eventSource;
};
let eventSource = null;
const useDelayedWarning = () => {
  const timeoutId = useRef(null);
  const show = useCallback(() => {
    timeoutId.current = setTimeout(() => {
      FailedToConnectToast.show();
    }, 5000);
  }, []);
  const hide = useCallback(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    FailedToConnectToast.hide();
  }, []);
  useOnUnmount(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
  });
  return {
    show,
    hide
  };
};
export function useEventSource(_ref) {
  let {
    onUpdate,
    onLegacyUpdate,
    publicId
  } = _ref;
  const {
    show,
    hide
  } = useDelayedWarning();
  const [url, setUrl] = useState(null);
  const reconnectCount = useRef(0);
  const reconnectTimeoutId = useRef(null);
  const [reconnect, setReconnect] = useState({});
  const dbTime = useDbTime();
  useEffect(() => {
    if (typeof publicId !== 'string' || !dbTime) setUrl(null);else setUrl(getEventSourceUrl(publicId, dbTime));
  }, [dbTime, publicId]);

  // We want to rerun the effect by setting reconnect to a new object
  // but that object isn't actually used inside the effect callback

  // biome-ignore lint/correctness/useExhaustiveDependencies: see above
  useEffect(() => {
    if (eventSource || !url) return;
    setupSSEListeners();
    eventSource = createEventSource(url, async _ref2 => {
      let {
        type,
        data,
        dbTime
      } = _ref2;
      switch (type) {
        case 'reset':
          {
            console.debug('SSE - Reset');
            trigger('datalayer-reset');
            onLegacyUpdate({
              type: 'reset',
              dbTime
            });
            setDbTime(dbTime);
            await clearCache();
            break;
          }
        case 'update':
          {
            setDbTime(dbTime);
            onUpdate(data);
            break;
          }
        case 'legacy-update':
          {
            onLegacyUpdate({
              type: 'update',
              data,
              dbTime
            });
            trigger('datalayer-update', {
              data: structuredClone(data),
              dbTime
            });
            break;
          }
      }
    }, state => {
      console.debug('SSE - EventSource state changed:', state);
      if (!isPlaywrightTestEnv()) {
        if (state === 'error') {
          show();
          if (eventSource) {
            console.debug('SSE - EventSource.readyState:', getState(eventSource.readyState));

            // we don't want to retry connecting if the eventSource.readyState is CONNECTING
            // because it is already in the process of connecting!
            if (eventSource.readyState === EventSource.CLOSED) {
              // wait with an exponential backoff up to 5 seconds before reconnecting
              const reconnectDelayMs = Math.min(5000, 2 ** reconnectCount.current * 500);
              console.debug('SSE - Reconnecting in', reconnectDelayMs, 'ms');
              reconnectTimeoutId.current = setTimeout(setReconnect, reconnectDelayMs, {});
              reconnectCount.current += 1;
            }
          }
        } else if (state === 'open') {
          hide();
          reconnectCount.current = 0;
          if (reconnectTimeoutId.current) {
            clearTimeout(reconnectTimeoutId.current);
            reconnectTimeoutId.current = null;
          }
        }
      }
    });
    return () => {
      if (reconnectTimeoutId.current) clearTimeout(reconnectTimeoutId.current);
      if (eventSource) {
        console.debug('SSE - EventSource state closed');
        eventSource.close();
        eventSource = null;
      }
    };
  }, [onUpdate, onLegacyUpdate, url, show, hide, reconnect]);
}