import { DocSearchCardDocFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { timestampToFromNowDate } from '@clubhouse/shared/utils/date';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { navigateToDoc } from 'pages/write/lib/navigateToDoc';
import { useDocContextMenuWithQuickSearchSessionLogging } from 'utils/contextMenus/useDocContextMenu';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { UserCohortAdapter } from '../../shared/UserCohortAdapter';
import { SearchCard, SearchCardLink } from '../SearchCard/SearchCard';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DocSearchCardFragment = DocSearchCardDocFragmentDoc;
export const DocSearchCard = _ref => {
  let {
    doc,
    onClick,
    openModalOnClick
  } = _ref;
  const url = generatePathWithSlug(getPathForPage(PAGE_NAMES.DOC), {
    docId: doc.urlId
  });
  const contextMenuProps = useDocContextMenuWithQuickSearchSessionLogging(doc.id, doc.urlId, useCallback(() => {
    onClick?.({
      ...doc,
      url
    });
  }, [doc, onClick, url]));
  const handleClick = e => {
    onClick?.({
      ...doc,
      url
    });
    if (isClickedWithModifier(e)) return;
    if (onClick) {
      e.preventDefault();
    }
    if (openModalOnClick) {
      navigateToDoc(doc.urlId);
    }
  };
  return _jsx(SearchCardLink, {
    onClick: handleClick,
    href: url,
    "data-focusable": true,
    ...contextMenuProps,
    children: _jsxs(SearchCard, {
      isArchived: doc.archived,
      children: [_jsx(SearchCard.Icon, {
        icon: "Document",
        kind: "blue",
        label: "Doc",
        isArchived: doc.archived
      }), _jsx(SearchCard.Title, {
        children: _jsx(Emojify, {
          children: doc.title || 'Untitled'
        })
      }), _jsxs(SearchCard.Metadata, {
        children: [doc.archived && _jsx(SearchCard.MetadataItem, {
          children: "Archived"
        }), _jsxs(SearchCard.MetadataItem, {
          children: ["Updated ", timestampToFromNowDate(doc?.updatedAt || '')]
        })]
      }), _jsx(SearchCard.Avatars, {
        children: doc.creator ? _jsx(UserCohortAdapter, {
          withTooltip: true,
          users: [doc.creator],
          numberOfUsers: 1
        }) : null
      })]
    })
  });
};
DocSearchCard.displayName = "DocSearchCard";
export function DocSearchCardAdapter(props) {
  const doc = {
    __typename: 'Doc',
    archived: props.doc.archived,
    id: props.doc.id,
    urlId: props.doc.id,
    title: props.doc.title || null,
    updatedAt: props.doc.contentLastUpdatedAt,
    accessControlScope: props.doc?.accessControlScope.toLowerCase() ?? 'workspace',
    creator: {
      __typename: 'Permission',
      displayName: props.doc.creator?.name,
      email: props.doc.creator?.email || null,
      id: props.doc.creator?.id,
      mentionName: props.doc.creator?.mentionName,
      publicId: props.doc.creator?.publicId,
      state: props.doc.creator?.deactivated || props.doc.creator?.disabled ? 'disabled' : 'full',
      displayIcon: {
        __typename: 'Icon',
        id: props.doc.creator?.avatarUrl,
        thumbnailUrl: props.doc.creator?.avatarUrl || null
      }
    }
  };
  return _jsx(DocSearchCard, {
    ...props,
    doc: doc
  });
}
DocSearchCardAdapter.displayName = "DocSearchCardAdapter";