import { useCallback } from 'react';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import EpicModel from 'app/client/core/js/models/epic'; // eslint-disable-line import/no-restricted-paths
import { CommentInput } from 'components/shared/Comments/CommentInput';
import { useCommentNotificationContext } from 'components/shared/Comments/CommentNotifications';
import { Actions, Content, Item, Loading, Section } from 'components/shared/Comments/components';
import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { getLoggedInUserPermissionID, isLoggedInUserAdmin } from 'data/entity/user';
import { error } from 'utils/message';
import { CommentReply, SimplifiedReply } from '../CommentReply';
import { AuthorAvatar, AuthorName, CommentContent, CommentDate, CommentReplies, Root } from './components';

// Public id until GQL is used
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicComment = Object.assign(Root, {
  AuthorAvatar,
  AuthorName,
  CommentDate,
  CommentContent,
  CommentReply,
  Section,
  Item,
  Actions,
  ContentLayout: Content,
  CommentReplies,
  Loading
});
export const SimplifiedEpicComment = _ref => {
  let {
    epicId,
    comment
  } = _ref;
  return _jsxs(Root, {
    id: epicId,
    comment: comment,
    children: [_jsx(AuthorAvatar, {}), _jsxs(Content, {
      children: [_jsxs(Spaced, {
        vertically: true,
        amount: 0,
        children: [_jsx(AuthorName, {}), _jsx(CommentDate, {
          isLinkable: false
        })]
      }), _jsx(CommentContent, {
        renderEmbeds: false,
        className: "popover"
      }), _jsx(SimplifiedReply, {}), _jsx(CommentReplies, {
        children: c => _jsx(SimplifiedEpicComment, {
          epicId: epicId,
          comment: c
        })
      })]
    })]
  });
};
SimplifiedEpicComment.displayName = "SimplifiedEpicComment";
export const DefaultComment = _ref2 => {
  let {
    epicId,
    comment,
    isActive
  } = _ref2;
  const isEditable = !comment.deleted && comment.author_id === getLoggedInUserPermissionID();
  const isDeletable = !comment.deleted && (comment.author_id === getLoggedInUserPermissionID() || isLoggedInUserAdmin());
  const [isCollapsed, toggleCollapsed] = useToggleState();
  const [isEditing, toggleEditing] = useToggleState();
  const [isDeleting, toggleDeleting] = useToggleState();
  const {
    onCommentDeleted,
    onCommentUpdated
  } = useCommentNotificationContext();
  const deleteComment = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      toggleDeleting.on();
      EpicModel.deleteComment({
        id: epicId
      }, comment.id, () => {
        onCommentDeleted();
        toggleDeleting.off();
      });
    }
  }, [comment.id, epicId, onCommentDeleted, toggleDeleting]);
  const editOnShiftClick = useCallback(e => {
    if (e.shiftKey && isEditable) toggleEditing.on();
  }, [isEditable, toggleEditing]);
  const saveComment = useCallback(async value => {
    if (value) {
      EpicModel.updateComment({
        id: epicId
      }, comment.id, value, err => {
        if (err) {
          error(err, {
            secondary: 'Your comment has been restored.'
          });
        } else {
          onCommentUpdated();
          toggleEditing.off();
        }
      });
    } else {
      error('Your comment is empty!');
    }
  }, [comment.id, epicId, onCommentUpdated, toggleEditing]);
  return _jsxs(Root, {
    id: epicId,
    comment: comment,
    isActive: isActive,
    children: [_jsx(AuthorAvatar, {
      size: AuthorAvatar.SIZE.M
    }), _jsxs(Content, {
      children: [_jsxs(Spaced, {
        horizontally: true,
        amount: "space2",
        align: "center",
        children: [_jsx(AuthorName, {
          size: AuthorName.SIZE.MEDIUM
        }), _jsx(CommentDate, {
          size: CommentDate.SIZE.XSMALL
        }), _jsx(Actions, {
          children: _jsxs(Spaced, {
            horizontally: true,
            amount: "space1",
            align: "center",
            children: [!!comment.comments?.length && _jsx(Tooltip, {
              content: "Toggle Replies",
              children: _jsx(UnstyledButton, {
                onClick: toggleCollapsed,
                children: _jsx(SizedIcon, {
                  icon: isCollapsed ? 'Expand' : 'Collapse',
                  size: 18,
                  fill: "disabled"
                })
              })
            }), isEditable && _jsx(Tooltip, {
              content: "Edit comment",
              children: _jsx(UnstyledButton, {
                onClick: toggleEditing.on,
                children: _jsx(SizedIcon, {
                  icon: "Edit",
                  size: 16,
                  fill: "disabled"
                })
              })
            }), isDeletable && _jsx(Tooltip, {
              content: "Delete comment",
              children: _jsx(UnstyledButton, {
                onClick: deleteComment,
                children: _jsx(SizedIcon, {
                  icon: "Trash",
                  size: 16,
                  fill: "disabled"
                })
              })
            })]
          })
        })]
      }), isEditing ? _jsx(CommentInput, {
        snapshotKey: `epic.editEpicComment.${comment.id}`,
        onSave: saveComment,
        onCancel: toggleEditing.off,
        children: _ref3 => {
          let {
            isSaving,
            onSave,
            onCancel,
            ...props
          } = _ref3;
          return _jsx(MarkdownEditor, {
            context: "Comment",
            isReadOnly: isSaving,
            initialValue: comment.text,
            onSave: onSave,
            onCancel: onCancel,
            ...props
          });
        }
      }) : _jsx(CommentContent, {
        className: "epic-page",
        onClick: editOnShiftClick,
        isDeleting: isDeleting,
        onChange: newValue => new Promise((resolve, reject) => {
          EpicModel.updateComment({
            id: epicId
          }, comment.id, newValue, err => {
            if (err) {
              reject(err);
            } else {
              onCommentUpdated();
              resolve();
            }
          });
        })
      }), _jsx(CommentReply, {})]
    }), !isCollapsed && _jsx(CommentReplies, {
      children: c => _jsx(DefaultComment, {
        epicId: epicId,
        comment: c
      })
    })]
  });
};
DefaultComment.displayName = "DefaultComment";