import { useEffect } from 'react';
import * as Event from 'utils/event';
export const useListenToImportUpdates = _ref => {
  let {
    currentImport = null,
    listenTo,
    callback
  } = _ref;
  useEffect(() => {
    Event.on('somebodyModifiedImport', res => {
      if (!res) return;
      if (currentImport === null || res.id === currentImport.id) {
        const eventIndex = res.import_status ? listenTo.indexOf(res.import_status) : null;
        if (typeof eventIndex === 'number' && eventIndex >= 0) {
          callback(res, listenTo[eventIndex]);
        }
      }
    });
    return () => {
      Event.off('somebodyModifiedImport');
    };
  }, [callback, listenTo, currentImport]);
};