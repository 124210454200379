import { useReactiveVar } from '@apollo/client';
import intersection from 'lodash/intersection';
import { useCallback, useMemo } from 'react';
import { useCollectionizeBulkSelectSyncItems } from 'data/entity/bulkSelection';
import { useTableBulkSelectionState } from '../bulkSelectContext';
export const useBulkEdit = id => {
  const {
    selectedItems,
    select,
    unselect
  } = useTableBulkSelectionState();
  return {
    isSelected: useReactiveVar(selectedItems).has(id),
    select: useCallback(selectRange => select(id, selectRange), [id, select]),
    unselect: useCallback(selectRange => unselect(id, selectRange), [id, unselect])
  };
};
export const useGroupBulkEdit = ids => {
  const {
    selectedItems,
    selectMultiple,
    unselectMultiple
  } = useTableBulkSelectionState();
  const selected = useReactiveVar(selectedItems);
  const isSelected = selected.size > 0 && ids.length <= selected.size && intersection([...selected], ids).length === ids.length;
  return {
    isSelected,
    selectAll: useCallback(() => selectMultiple(ids), [ids, selectMultiple]),
    unselectAll: useCallback(() => unselectMultiple(ids), [ids, unselectMultiple])
  };
};
export const useTableBulkSelection = () => {
  const {
    clearState,
    selectedItems
  } = useTableBulkSelectionState();
  const selectedStories = useReactiveVar(selectedItems);
  const publicIds = useMemo(() => [...selectedStories], [selectedStories]);
  useCollectionizeBulkSelectSyncItems({
    publicIds,
    onBulkSelectRemoveEverythingEvent: clearState
  });
  const getIsRowSelected = useCallback(item => selectedStories.has(item.publicId), [selectedStories]);
  return [selectedStories, getIsRowSelected];
};