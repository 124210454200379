import "core-js/modules/es.array.push.js";
import Hash from 'app/client/core/js/modules/hash';
import Url from 'app/client/core/js/modules/url';
import StoryModel from './story';
import UserModel from './user';
import Collection from '../_frontloader/collection';
import ApplicationState from '../modules/applicationState';
import Backend from '../modules/backend';
const exports = {};
Collection.create('Comment', exports);
exports.CONSTANTS = {
  DELETED: 'Deleted',
  DELETING: 'Deleting...'
};
exports.wasEdited = comment => comment.created_at && comment.updated_at && comment.created_at !== comment.updated_at && !comment.deleted;
exports.isAuthorImportedUser = _ref => {
  let {
    author
  } = _ref;
  return !!author?.id && author.created_without_invite && author.disabled;
};
exports.isAuthorCurrentUser = _ref2 => {
  let {
    author
  } = _ref2;
  return !!author?.id && UserModel.isLoggedInProfile(author);
};
exports.commentHasChildren = comment => {
  const children = exports.getCommentChildren(comment);
  return Boolean(children && children.length);
};
exports.isCommentRoot = _ref3 => {
  let {
    parent_id
  } = _ref3;
  return !parent_id;
};
exports.isCommentDeleted = _ref4 => {
  let {
    deleted
  } = _ref4;
  return Boolean(deleted);
};
const COMMENT_ENTITIES = {
  STORY_COMMENT: 'story-comment',
  STORY_FILE_COMMENT: 'story-file-comment',
  STORY_LINKED_FILE_COMMENT: 'story-linked-file-comment'
};
const isCommentOfEntityType = (_ref5, entityType) => {
  let {
    entity_type
  } = _ref5;
  return entityType === entity_type;
};
exports.isCommentReplyable = comment => exports.isCommentRoot(comment) && !exports.isCommentDeleted(comment) && isCommentOfEntityType(comment, COMMENT_ENTITIES.STORY_COMMENT);
exports.getCommentChildren = _ref6 => {
  let {
    comments
  } = _ref6;
  return comments || [];
};
exports.isCommentAnchored = comment => Hash.get() === exports.getCommentAnchorSlug(comment);

// This is important since story#normalize will include things that won't have this property
exports.getReactionsFromComment = _ref7 => {
  let {
    reactions
  } = _ref7;
  return reactions ?? [];
};
exports.commentHasReactions = comment => exports.getReactionsFromComment(comment).length > 0;
exports.getCommentAnchorSlug = _ref8 => {
  let {
    id
  } = _ref8;
  return `activity-${id}`;
};
exports.getCommentURL = (entity, comment) => {
  const entityPath = entity.url || Url.getEntityPath(entity);
  const commentPath = exports.getCommentAnchorSlug(comment);

  // Gracefully route to root url if entity is borked
  return `${Url.getCurrentOrigin()}${entityPath}${entityPath ? `#${commentPath}` : ''}`;
};
const COMMENT_SORT_ORDER = {
  ASCENDING: 'ascending',
  // oldest first
  DESCENDING: 'descending' // newest first
};
exports.getCommentSortOrder = () => {
  const sortOrder = ApplicationState.get('Comments.sortOrder');
  if (!sortOrder) {
    exports.setCommentSortOrder(COMMENT_SORT_ORDER.ASCENDING);
    return COMMENT_SORT_ORDER.ASCENDING;
  }
  return sortOrder;
};
exports.setCommentSortOrder = sortOrder => ApplicationState.set('Comments.sortOrder', sortOrder);
exports.toggleCommentSortOrder = () => exports.setCommentSortOrder(exports.commentsAreAscending() ? COMMENT_SORT_ORDER.DESCENDING : COMMENT_SORT_ORDER.ASCENDING);
exports.commentsAreAscending = () => exports.getCommentSortOrder() === COMMENT_SORT_ORDER.ASCENDING;
exports.findAllParentComments = comments => comments.filter(_ref9 => {
  let {
    parent_id
  } = _ref9;
  return !parent_id;
});

// TODO Do not rely on entity model's normalizing behavior; we should normalize comments here, not in the entity
exports.extractCommentsFromEntity = entity => {
  // Activity is a normalized property created in model/story#normalize
  //  it is an concatenated list of comments, files, and linked_files so the structure is not guaranteed!
  return entity?.activity ?? [];
};

// NOTE: Assumes one level deep only
exports.normalizeComments = comments => {
  const children = comments.filter(_ref10 => {
    let {
      parent_id
    } = _ref10;
    return !!parent_id;
  }).reduce((acc, comment) => {
    const parentId = comment.parent_id;
    if (!acc[parentId]) acc[parentId] = [];
    acc[parentId].push(comment);

    // (acc[id] || (acc[id] = [])).push(comment)
    return acc;
  }, {});
  return comments.map(comment => {
    comment.comments = children[comment.id] || [];
    return comment;
  });
};
exports.parseThreadedComments = comments => {
  const normalized = exports.normalizeComments(comments);
  return exports.findAllParentComments(normalized);
};
exports.saveComment = (entity, comment, parentId, callback) => {
  const data = Object.assign({
    text: comment
  });
  if (parentId) {
    data.parent_id = parentId;
  }

  // TODO Should determine patch dynamically based on entity type
  const url = '/api/private/stories/' + entity.id + '/comments';
  Backend.post(url, {
    data,
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler.call(StoryModel, res, callback);
      } else {
        StoryModel.fetchStory(entity.id, callback);
      }
    }
  });
};
exports.updateComment = (entity, comment_id, comment, callback) => {
  // TODO Should determine patch dynamically based on entity type
  const url = '/api/private/stories/' + entity.id + '/comments/' + comment_id;
  Backend.put(url, {
    data: {
      text: comment
    },
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler(res, callback);
      } else {
        // TODO(threaded comments) Make refetch dynamic in what entity is refetches
        StoryModel.fetchStory(entity.id, callback);
      }
    }
  });
};
exports.unlinkFromSlack = (entityId, commentId, callback) => {
  const url = '/api/private/stories/' + entityId + '/comments/' + commentId + '/unlink-from-slack';
  Backend.post(url, {
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler(res, callback);
      } else {
        StoryModel.fetchStory(entityId, callback);
      }
    }
  });
};
exports.deleteComment = (entityId, commentId, callback) => {
  // TODO Should determine patch dynamically based on entity type
  const url = '/api/private/stories/' + entityId + '/comments/' + commentId;
  Backend.delete(url, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204 || xhr.status === 404) {
        StoryModel.fetchStory(entityId, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteReaction = (storyId, commentId, emoji) => {
  // TODO Should determine patch dynamically based on entity type
  Backend.delete(`/api/private/stories/${storyId}/comments/${commentId}/reactions`, {
    data: {
      emoji
    },
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler(res);
      } else {
        StoryModel.fetchStory(storyId);
      }
    }
  });
};
exports.addReaction = (storyId, commentId, emoji) => {
  // TODO Should determine patch dynamically based on entity type
  Backend.post(`/api/private/stories/${storyId}/comments/${commentId}/reactions`, {
    data: {
      emoji
    },
    onComplete: res => {
      if (_.get(res, 'error')) {
        exports.defaultErrorHandler(res);
      } else {
        StoryModel.fetchStory(storyId);
      }
    }
  });
};
export { exports as default };