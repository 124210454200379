import { QueryWorkspaceEpicSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { useFilterOptionsQueryWithCurrentPermissionId } from '../filters/hooks/useFilterOptionsQueryWithCurrentPermissionId';
import { getSection } from './utils/sections';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_WORKSPACE_EPIC_SELECT = QueryWorkspaceEpicSelectDocument;
export function EpicsFilter(_ref) {
  let {
    entityType,
    staticOptions
  } = _ref;
  const [selectedValues, setSelectedValues] = useFilterState({
    paramName: 'epic_ids',
    paramType: 'string[]'
  });
  return _jsx(EpicsSelect, {
    value: selectedValues,
    onChange: setSelectedValues,
    entityType: entityType,
    staticOptions: staticOptions
  });
}
EpicsFilter.displayName = "EpicsFilter";
export const EpicsSelect = _ref2 => {
  let {
    value,
    onChange,
    entityType,
    staticOptions
  } = _ref2;
  const fetchEpics = useFilterOptionsQueryWithCurrentPermissionId({
    query: QUERY_WORKSPACE_EPIC_SELECT,
    selectedIds: value
  });
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchEpics,
    getSection,
    selectedValues: value,
    filterType: Nouns.Epic
  });
  return _jsx(GroupedOptionsFilter, {
    selectedValues: value,
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    onChange: onChange,
    entityType: entityType,
    filterType: Nouns.Epic,
    staticOptions: staticOptions,
    icon: "Epic"
  });
};
EpicsSelect.displayName = "EpicsSelect";