import { StoryGroupByEpicFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ButtonText } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TableGroupHeaderLinks } from '@clubhouse/shared/components/Table';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/epics/links';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryGroupByEpicFragment = StoryGroupByEpicFragmentFragmentDoc;
export const EpicGroupHeader = _ref => {
  let {
    epic
  } = _ref;
  return _jsxs(Spaced, {
    horizontally: true,
    amount: 12,
    inline: true,
    align: "center",
    children: [_jsx(GroupByHeader, {
      entityType: Nouns.Epic,
      children: typeof epic?.name === 'string' ? _jsxs(ButtonText, {
        children: [" ", epic.name, " "]
      }) : 'No Epic'
    }), typeof epic?.publicId === 'number' && _jsx(TableGroupHeaderLinks, {
      entityName: "Epic",
      url: getHref(epic.publicId, true)
    })]
  });
};
EpicGroupHeader.displayName = "EpicGroupHeader";