import { Chip } from '@clubhouse/shared/components/Chip';
import { sample } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TaskChip = _ref => {
  let {
    totalTasks,
    completedTasks
  } = _ref;
  if (typeof completedTasks !== 'number' || !totalTasks) return null;
  const areAllCompleted = totalTasks === completedTasks;
  let tooltip = totalTasks === 1 ? `This Story has 1 Task, ${completedTasks === 1 ? 'and it is complete.' : 'and it is not complete.'}` : `This Story has ${completedTasks} of ${totalTasks} Tasks completed.`;
  if (areAllCompleted) {
    tooltip += sample([' ✅', ' 🎉', ' 👍', ' 🏆']);
  }
  return _jsx(ChipTooltip, {
    content: _jsx(ChipTooltip.Text, {
      children: tooltip
    }),
    children: _jsxs(Chip, {
      kind: areAllCompleted ? 'green' : 'default',
      children: [_jsx(Chip.Icon, {
        icon: "Check",
        label: "Tasks"
      }), completedTasks, "/", totalTasks]
    })
  });
};
TaskChip.displayName = "TaskChip";