import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { renameSpace, saveNew, selectSpace, setSpaceGroupFilterToTeamId } from 'data/entity/space';
import { pushSpaceState } from 'utils/space';
import { useSpacesContext } from './SpacesContext';
export const useSpaceForm = space => {
  const [spaceName, setSpaceName] = useState(space.name);
  const [isSaving, setIsSaving] = useState(false);
  const {
    unsavedSpace,
    setUnsavedSpace
  } = useSpacesContext();
  const isUnsavedSpace = isEqual(unsavedSpace, space);
  const inputRef = useRef(null);
  const [isFormVisible, {
    on: showForm,
    off: hideForm
  }] = useToggleState(isUnsavedSpace);
  useEffect(() => {
    if (isUnsavedSpace) {
      inputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [isUnsavedSpace]);
  useEffect(() => {
    if (isFormVisible) {
      inputRef.current?.focus();
    }
  }, [isFormVisible]);
  const closeForm = useCallback(() => {
    setSpaceName(space.name);
    hideForm();
    if (isUnsavedSpace) setUnsavedSpace(null);
  }, [hideForm, isUnsavedSpace, setUnsavedSpace, space.name]);
  const saveForm = useCallback(async () => {
    if (!spaceName.trim() || !isUnsavedSpace && spaceName === space.name) {
      closeForm();
      return;
    }
    setIsSaving(true);
    if (isUnsavedSpace) {
      const teamGlobalId = getTeamScopedCollectionizeId();
      if (teamGlobalId) {
        setSpaceGroupFilterToTeamId(space, teamGlobalId);
      }
      const newSpace = await saveNew({
        name: spaceName,
        data: space.data,
        shared: false
      });
      setIsSaving(false);
      hideForm();
      setUnsavedSpace(null);
      selectSpace(newSpace);
    } else {
      const updatedSpace = await renameSpace(space, spaceName);
      setIsSaving(false);
      hideForm();
      if (space.active) pushSpaceState(updatedSpace);
    }
  }, [closeForm, hideForm, isUnsavedSpace, setUnsavedSpace, space, spaceName]);
  const onChange = useCallback(e => {
    setSpaceName(e.target.value);
  }, []);
  const onKeyUp = useCallback(e => {
    if (e.key === 'Escape') {
      closeForm();
    }
  }, [closeForm]);
  const onBlur = useCallback(() => {
    if (!spaceName.trim() || spaceName === space.name) {
      closeForm();
    }
  }, [closeForm, space.name, spaceName]);
  return {
    isFormVisible,
    showForm,
    closeForm,
    saveForm,
    inputProps: {
      autoFocus: isUnsavedSpace,
      disabled: isSaving,
      onBlur,
      onChange,
      onKeyUp,
      placeholder: 'New Space',
      ref: inputRef,
      type: 'text',
      value: spaceName
    }
  };
};