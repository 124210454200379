import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { sanitize } from '@clubhouse/shared/utils';
import { ArchiveMessage } from 'components/shared/ArchiveMessage';
import { deleteEpic, unarchiveEpic } from 'data/entity/epic';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ArchivedEpic(_ref) {
  let {
    epic
  } = _ref;
  const {
    addToast
  } = useToast();
  return _jsx(ArchiveMessage, {
    typeName: "Epic",
    onUnarchive: () => {
      unarchiveEpic(epic, err => {
        if (err) {
          addToast({
            kind: 'alert',
            Content: () => _jsxs(ToastText, {
              children: ["Unable to unarchive ", _jsx("strong", {
                children: sanitize(epic.name)
              }), "."]
            }),
            timeout: 5000
          });
        } else {
          addToast({
            kind: 'success',
            Content: () => _jsxs(ToastText, {
              children: ["Epic ", _jsx("strong", {
                children: sanitize(epic.name)
              }), " unarchived."]
            }),
            timeout: 5000
          });
        }
      });
    },
    onDelete: () => {
      if (window.confirm('Are you sure you want to delete this Epic? No Stories will be harmed during deletion.')) {
        deleteEpic(epic, err => {
          if (err) {
            addToast({
              kind: 'alert',
              Content: () => _jsxs(ToastText, {
                children: ["Unable to delete ", _jsx("strong", {
                  children: sanitize(epic.name)
                }), "."]
              }),
              timeout: 5000
            });
          }
        });
      }
    },
    children: "Archived Epics and its Stories are excluded from reports and charts, as well as from the Stories, Status, and My Work pages. Archived Epics can still be viewed directly using their permalink."
  });
}
ArchivedEpic.displayName = "ArchivedEpic";