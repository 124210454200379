import { useApolloClient } from '@apollo/client';
import { useDataLayerUpdateEvent } from './useDataLayerUpdateEvent';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const NOT_EVICTABLE_ITEMS = ['FilterPillOptions'];
const evictUpdatedItems = (client, items) => {
  if (!items.length) return;
  for (const item of items) {
    const cacheId = client.cache.identify(item);
    if (cacheId) client.cache.evict({
      id: cacheId
    });
  }
  client.cache.gc();
};
export const EvictCacheOnUpdate = _ref => {
  let {
    children
  } = _ref;
  const client = useApolloClient();
  useDataLayerUpdateEvent(items => {
    evictUpdatedItems(client, items);
  }, {
    filter: item => !NOT_EVICTABLE_ITEMS.includes(item.__typename)
  });
  return _jsx(_Fragment, {
    children: children
  });
};