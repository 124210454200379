import { Icon } from '@useshortcut/shapes-ds';
import arrayMove from 'array-move';
import merge from 'lodash/merge';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { sanitize, sanitizeAndEmojify } from '@clubhouse/shared/utils';
import SpaceModel from 'app/client/core/js/models/space';
import { useSpaceSaveToasts } from 'components/stories/Spaces/useSpaceSaveToasts';
import { getTeamScopedApplicationStateKey, useTeamScopeId } from 'components/team-navigation';
import { getSnapshot, hasSnapshot, restoreSnapshot, saveDraftToActiveSpace } from 'data/page/stories/spaces';
import { useAppState } from 'utils/appState';
import { start } from 'utils/exportData';
import * as Log from 'utils/log';
import { error, success } from 'utils/message';
import { simpleCompleteTask } from 'utils/quickstart';
import { getTeamScopedSpaces } from 'utils/space';
import { useEntities, useEntity } from './collection';
import { getActive as getActiveTeam } from './team';
import { getLoggedInUserPermissionID, isLoggedInUserAdmin } from './user';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const useSpaces = () => {
  const {
    entities
  } = useEntities({
    model: SpaceModel
  });
  return entities;
};
export const useTeamScopedSpaces = () => {
  const spaces = useSpaces();
  return getTeamScopedSpaces(spaces);
};
function subscribeToActiveSpace(onActiveSpaceChange) {
  SpaceModel.on('updated.onActiveChange', space => {
    if (space.active) onActiveSpaceChange(space);
  });
  return () => {
    SpaceModel.off('updated.onActiveChange');
  };
}
export function useActiveSpaceDataSelector(selector) {
  return useSyncExternalStore(subscribeToActiveSpace, () => selector(getActive()?.data));
}
export const useActiveSpace = () => {
  const spaces = useSpaces();
  const saveToastRef = useRef();
  const {
    showSpaceSaveErrorToast,
    showSpaceSaveSuccessToast,
    removeToast
  } = useSpaceSaveToasts();
  const activeSpace = spaces.find(_ref => {
    let {
      active
    } = _ref;
    return active;
  });
  const snapshot = activeSpace ? getSnapshot(activeSpace.id) : undefined;
  const hasUnsavedChanges = activeSpace ? hasSnapshot(activeSpace.id) : false;
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    if (saveToastRef.current && hasUnsavedChanges) removeToast(saveToastRef.current);
  }, [hasUnsavedChanges, removeToast]);
  const isEditable = activeSpace && isSpaceEditable(activeSpace);
  const save = useCallback(async () => {
    if (!isEditable) return;
    setIsSaving(true);
    try {
      const updatedSpace = await saveDraftToActiveSpace();
      if (updatedSpace) {
        saveToastRef.current = showSpaceSaveSuccessToast({
          name: updatedSpace.name,
          undoChanges: async () => {
            if (snapshot) {
              const space = await updateSpace(updatedSpace.id, snapshot);
              load(space);
            }
          }
        });
      }
    } catch (e) {
      showSpaceSaveErrorToast(_jsxs(_Fragment, {
        children: ["Unable to update Space ", _jsx("strong", {
          children: activeSpace.name
        })]
      }));
    } finally {
      setIsSaving(false);
    }
  }, [activeSpace?.name, isEditable, showSpaceSaveErrorToast, showSpaceSaveSuccessToast, snapshot]);
  const cancel = useCallback(() => {
    if (!activeSpace) return;
    const restored = restoreSnapshot(activeSpace.id);
    if (restored) load(restored);
  }, [activeSpace]);
  return {
    activeSpace: SpaceModel.copyById(activeSpace?.id),
    isEditable,
    save,
    cancel,
    isSaving
  };
};
export const useSpace = id => {
  const {
    entity
  } = useEntity({
    model: SpaceModel,
    id
  });
  return entity;
};
export const updateSpace = (id, updates) => {
  const data = updates ? updates.data : {};
  return SpaceModel.Promises.updateSpace({
    id
  }, {
    ...updates,
    data: JSON.stringify(data)
  });
};
export const isOwnedByCurrentUser = space => SpaceModel.isOwnedByCurrentUser(space);
export const useTeamScopedSpacePreferences = () => {
  const {
    value: teamScopeId
  } = useTeamScopeId();
  const [preferences, setPreferences] = useAppState({
    appStateKey: getTeamScopedApplicationStateKey(teamScopeId, 'Space.preferences'),
    default: SpaceModel._compileDefaultPreferences()
  });
  return [preferences, setPreferences];
};
export const useVisibleDraggableSpaces = () => {
  const spaces = useTeamScopedSpaces();
  const [preferences] = useTeamScopedSpacePreferences();
  const visibleSpaces = spaces.filter(space => !(SpaceModel.getSpacePreferences(space.id, preferences)?.hidden ?? false));
  return SpaceModel.sortSpacesByPosition(visibleSpaces, preferences);
};
export const useUpdateSpacePosition = () => {
  const [preferences, setPreferences] = useTeamScopedSpacePreferences();
  const updateSpacePosition = (sourcePos, destinationPos) => {
    const newPrefs = arrayMove(preferences, sourcePos, destinationPos);
    setPreferences(newPrefs);
  };
  return updateSpacePosition;
};
export const deleteSpace = space => {
  SpaceModel.deleteSpace(space, err => {
    if (err) {
      error(err, {
        secondary: 'Unable to delete the space <strong>' + sanitize(space.name) + '</strong>.'
      });
    } else {
      success('Space <strong>' + sanitize(space.name) + '</strong> deleted.', {
        id: 'space',
        icon: 'fa-desktop',
        timeout: 2000
      });
    }
  });
};
export const hideSpace = space => {
  SpaceModel.hide(space);
};
export const shareSpace = space => {
  SpaceModel.updateSpace(space, {
    shared: true
  }, err => {
    if (err) {
      error(err, {
        secondary: 'Unable to share space <strong>' + sanitizeAndEmojify(space.name) + '</strong>.'
      });
    } else {
      success('Space <strong>' + sanitizeAndEmojify(space.name) + '</strong> has been shared, which means that anyone in your organization can find this ' + 'space in the Spaces dropdown.', {
        timeout: 14000
      });
      Log.log('Space shared', {
        spaceName: space.name
      });
    }
  });
};
export const unshareSpace = space => {
  const userId = getLoggedInUserPermissionID();
  const confirmMsg = space.author.id === userId ? 'Are you sure you want to unshare this space? It will no longer be visible to anyone else.' : 'Are you sure you want to unshare this space? It will no longer be visible to anyone except the author (' + space.author.name + ').';
  if (window.confirm(confirmMsg)) {
    SpaceModel.updateSpace(space, {
      shared: false
    }, err => {
      if (err) {
        error(err, {
          secondary: 'Unable to unshare space <strong>' + sanitize(space.name) + '</strong>.'
        });
      } else {
        if (space.author.id !== userId) {
          SpaceModel.remove(space);
        }
        success('Space <strong>' + sanitize(space.name) + '</strong> has been unshared.');
        Log.log('Space unshared', {
          spaceName: space.name
        });
      }
    });
  }
};
export const exportSpace = space => {
  const team = getActiveTeam();
  start({
    space_id: space.id,
    team_id: team.id
  });
};
export const toggleSpaceWorkflow = async space => {
  const activeWorkflow = getActiveTeam('stories');
  const updates = {
    team_id: space.team_id === activeWorkflow.id ? null : activeWorkflow.id
  };
  try {
    const updatedSpace = await updateSpace(space.id, updates);
    const workflowName = sanitizeAndEmojify(activeWorkflow.name);
    const spaceName = sanitizeAndEmojify(updatedSpace.name);
    const message = updates.team_id ? `Space <strong>${spaceName}</strong> has been connected to the <strong>${workflowName}</strong> Workflow. Selecting this Space will also update the current Workflow.` : `Space <strong>${spaceName}</strong> has been disconnected from the <strong>${workflowName}</strong> Workflow.`;
    success(message, {
      timeout: 15000
    });
  } catch (err) {
    error(err, {
      secondary: `Unable to update Workflow of the Space <strong>${sanitize(space.name)}</strong>.`
    });
  }
};
export const duplicateSpace = space => {
  const name = `${space.name} copy`;
  SpaceModel.save({
    name,
    quiet: true
  }, (err, savedSpace) => {
    if (err) {
      error(err, {
        secondary: 'Unable to save a duplicate of <strong>' + sanitize(name) + '</strong>.'
      });
    } else {
      SpaceModel.load(savedSpace);
    }
  });
};
export const selectSpace = space => {
  if (!space.active) {
    const activeSpace = getActive();
    if (activeSpace) {
      restoreSnapshot(activeSpace.id);
    }
    SpaceModel.load(space);
    simpleCompleteTask('visit-spaces');
  }
};
export const load = space => SpaceModel.load(space);
export const save = options => SpaceModel.Promises.save(options);
export const saveNew = async _ref2 => {
  let {
    data,
    name,
    shared
  } = _ref2;
  return SpaceModel.Promises.saveNew({
    name,
    shared,
    data: {
      ...data,
      scratch: false,
      readymade: false
    }
  });
};
export const renameSpace = async (space, name) => {
  if (!name) return;
  try {
    const updatedSpace = await updateSpace(space.id, {
      name,
      data: {
        ...space.data,
        scratch: false
      }
    });
    simpleCompleteTask('visit-spaces');
    return updatedSpace;
  } catch (err) {
    error(err, {
      secondary: 'Unable to update space.'
    });
  }
};

/** @deprecated Use 'useUpdateSpacePosition' instead */
export const reorder = (oldIndex, newIndex) => SpaceModel.reorder(oldIndex, newIndex);
export const getActive = () => SpaceModel.get({
  active: true
});
export const getBlankSpace = overrides => {
  const defaults = {
    team_id: getActiveTeam('stories').id,
    name: '',
    shared: false,
    hidden: false,
    readymade: false,
    scratch: true,
    icon: {
      active: 'pencil-square-o',
      inactive: 'pencil-square-o'
    },
    data: {
      displayStoriesNotInEpics: true,
      displayStoriesNotInIterations: true,
      filterType: 'AND',
      scratch: true,
      readymade: false,
      Column: '*',
      Epic: '*',
      Project: '*',
      Iteration: '*',
      Group: '*',
      CustomField: [],
      Filter: []
    }
  };
  return merge(defaults, overrides);
};
export const getCurrentSpaceState = () => SpaceModel.getCurrentSpaceState();
export const setSpaceGroupFilterToTeamId = (space, teamId) => space.data.Group = [teamId];
export const getIcon = (space, forceFill) => {
  const fill = forceFill || (space.active ? 'interactive' : 'main');
  if (space.readymade) {
    return () => _jsx(Icon, {
      icon: "Default",
      fill: fill
    });
  }
  if (space.shared) {
    return () => _jsx(Icon, {
      icon: "Shared",
      fill: fill
    });
  }
  if (space.scratch) {
    return () => _jsx(Icon, {
      icon: "Unsaved",
      fill: fill
    });
  }
  return () => _jsx(Icon, {
    icon: "Personal",
    fill: fill
  });
};
export const isSpaceEditable = space => !space.readymade && (isOwnedByCurrentUser(space) || isLoggedInUserAdmin());