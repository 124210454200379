export const pluralizeWordOnly = _ref => {
  let {
    count,
    singular,
    plural
  } = _ref;
  // TODO: When we add localization we'll need to accept the actual locale
  // and handle 'zero', 'two', 'few', 'many', 'other' Plural Rules
  const pluralRule = new Intl.PluralRules('en-US').select(count);
  return pluralRule === 'one' ? singular : plural;
};
export const pluralize = _ref2 => {
  let {
    count,
    singular,
    plural
  } = _ref2;
  // Note: the spaces in here are non-breaking spaces so that the numbers aren't separated across a
  // line break. In some ideal future maybe we could return a JSX fragment here with a proper &nbsp;
  // entity?

  // eslint-disable-next-line no-irregular-whitespace
  return `${count} ${pluralizeWordOnly({
    count,
    singular,
    plural
  })}`;
};
export const pluralizeNoun = (noun, count) => pluralizeWordOnly({
  count,
  ...noun
});
export const pluralizeNounWithCount = (noun, count) => pluralize({
  count,
  ...noun
});