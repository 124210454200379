import BulkSelectionModel from '../../../core/js/models/bulkSelection';
import ColumnModel from '../../../core/js/models/column';
import * as Event from '../../../core/js/_frontloader/event';
import Format from '../../../core/js/modules/format';
import Globals from '../../../core/js/_frontloader/globals';
import Iterate from '../../../core/js/modules/iterate';
import * as Log from 'utils/log';
import { EVENTS, logEvent } from 'utils/monitoring';
import MessageController from '../../../core/js/controllers/message';
import SpaceModel from '../../../core/js/models/space';
import StoriesStoryController from '../controllers/storiesStory';
import StoriesView from './storiesView';
import StoryModel from '../../../core/js/models/story';
import Updates from '../../../core/js/modules/updates';
import Utils from '../../../core/js/modules/utils';
const exports = {};
let dragInFn;
let columnOut;
let columnIn;
let startIndex;
let dragInterval;
let draggedStory;
exports.getDragInFn = element => {
  let fn;
  element = $(element).closest('.column');
  ColumnModel.each(column => {
    if (element.hasClass(column.class_name)) {
      fn = column.onDragIn;
    }
  });
  return fn;
};
exports.scrollClosestColumn = storyElement => {
  if (!storyElement) {
    return;
  }
  let $column = $('.has-sortable-story .column-bucket');
  if ($column.length === 0) {
    $column = $('#sidebar');
  }
  const $story = $(storyElement);
  const storyOffset = $story.offset();
  const columnOffset = $column.offset();
  const scrollTop = $column.scrollTop();
  const windowHeight = $(window).height();
  const threshold = 150;
  const acceleration = 0.3;
  const bottomThreshold = threshold + $story.height();
  let scrollAmount;
  if (storyOffset.top - columnOffset.top < threshold) {
    scrollAmount = (threshold - (storyOffset.top - columnOffset.top)) * acceleration;
    $column.scrollTop(scrollTop - scrollAmount);
    $('.sortable-column.ui-sortable').sortable('refreshPositions');
  } else if (windowHeight - storyOffset.top < bottomThreshold) {
    scrollAmount = (bottomThreshold - (windowHeight - storyOffset.top)) * acceleration;
    $column.scrollTop(scrollTop + scrollAmount);
    $('.sortable-column.ui-sortable').sortable('refreshPositions');
  }
};
exports.scrollContentWindow = sidebarWidth => {
  const windowWidth = $(window).width();
  const contentElement = document.body.classList.contains('using-raven-nav') ? $('.react-stories-table-wrapper') : $('#content');
  const scrollLeft = contentElement.scrollLeft();
  const threshold = 150;
  const acceleration = 0.3;
  let amount;
  if (Globals.get('mouseX') - sidebarWidth < threshold) {
    amount = (threshold - (Globals.get('mouseX') - sidebarWidth)) * acceleration;
    contentElement.scrollLeft(scrollLeft - amount);
  } else if (windowWidth - Globals.get('mouseX') < threshold) {
    amount = (threshold - (windowWidth - Globals.get('mouseX'))) * acceleration;
    contentElement.scrollLeft(scrollLeft + amount);
  }
};
exports.onStoryDragStart = (event, ui) => {
  Log.debug('DragAndDrop: starting drag');
  Globals.set('isDragging', true);
  Globals.set('isValidSidebarTarget', false);
  $('body').addClass('user-dragging-story');
  const sidebar = $('#sidebar');
  const sidebarWidth = sidebar.is(':visible') ? sidebar.outerWidth() : 0;
  draggedStory = $(ui.item);
  columnOut = draggedStory.closest('.column')[0];
  startIndex = draggedStory.index();
  const story = Utils.getModelFromContext(ui.item);
  const storyIsSelected = BulkSelectionModel.isSelected(story);
  const selectedStoryCount = BulkSelectionModel.getSelectionCount();
  if (storyIsSelected && selectedStoryCount > 1) {
    draggedStory.append('<span class="dragging-multiple-stories-note">+' + Format.pluralize(selectedStoryCount - 1, 'Story', 'Stories') + '</span>');
  }
  dragInterval = setInterval(() => {
    if (!draggedStory || draggedStory.length === 0) {
      return false;
    }
    exports.scrollClosestColumn(ui.item);
    exports.scrollContentWindow(sidebarWidth);
    if (sidebarWidth && Globals.get('mouseX') < sidebarWidth) {
      draggedStory.addClass('over-sidebar');
    } else {
      draggedStory.removeClass('over-sidebar');
    }
  }, 50);
};
exports.onOver = (event, ui) => {
  $('.column.has-sortable-story').removeClass('has-sortable-story');
  $(ui.placeholder).closest('.column').addClass('has-sortable-story');
};
exports.onStoryBeforeStop = (event, ui) => {
  if (ui && ui.item) {
    columnIn = $(ui.item).closest('.column')[0];
  }
};
exports.onStoryDragStop = (event, ui) => {
  clearInterval(dragInterval);
  draggedStory = null;
  $('body').removeClass('user-dragging-story');
  $('.dragging-multiple-stories-note').remove();
  $('.column.has-sortable-story').removeClass('has-sortable-story');
  const story = StoryModel.getById(Utils.data(ui.item, 'id'));

  // Dragging to sidebar means columnIn will be null or undefined.
  if (columnIn && ui && ui.item) {
    const priorityChanges = StoryModel.changePriority(story);
    const updates = {};
    const cIn = Utils.getModelFromContext(columnIn);
    const cOut = Utils.getModelFromContext(columnOut);
    if (columnOut !== columnIn) {
      dragInFn = exports.getDragInFn(ui.item);
      if (_.isFunction(dragInFn)) {
        _.assignIn(updates, dragInFn(story));
      }
      if (dragInFn) {
        exports.handleStoryDragUpdate(story, ui.item, updates, priorityChanges);
        updates.id = story.id;
        logEvent(EVENTS.Story_Workflow_State_Changed_In_Spaces, {
          space_name: SpaceModel.getSpaceTitleFromURL(),
          previous_workflow_state_position: cOut.position,
          previous_workflow_state_type: cOut.type,
          workflow_state_position: cIn.position,
          workflow_state_type: cIn.type
        });
        StoryModel.update(updates);
      }
    } else if ($(ui.item).index() !== startIndex) {
      logEvent(EVENTS.Story_Workflow_State_Changed_In_Spaces, {
        space_name: SpaceModel.getSpaceTitleFromURL(),
        previous_workflow_state_position: cOut.position,
        previous_workflow_state_type: cOut.type,
        workflow_state_position: cIn.position,
        workflow_state_type: cIn.type
      });
      if (ColumnModel.isDone(cIn)) {
        const html = '<strong>"Done" columns are ordered by the date the story was moved into that column.</strong>' + ' You can drag stories into and out of (but not within) a "done" column.';
        MessageController.alert(html, {
          id: 'cancel-done-story-sort',
          timeout: 15000
        });
        exports.cancelStorySort();
      } else {
        exports.handleStoryDragUpdate(story, ui.item, updates, priorityChanges);
        updates.id = story.id;
        StoryModel.update(updates);
      }
    }
    StoriesView.updateColumnDimensions();
  } else {
    // User dropped a story onto the sidebar.
    // We're waiting 200ms to allow valid drops in the sidebar to take effect.
    setTimeout(() => {
      // Globals.get('isValidSidebarTarget') is set to true by the individual sidebar buckets.
      // We have to do this because the droppable configurations happen *after* the
      // story draggable configuration.
      if (Globals.get('isValidSidebarTarget') !== true) {
        Globals.set('isValidSidebarTarget', false);
        StoriesView.drawStories();
        $('#sidebar .story').remove();
      }
    }, 200);
  }
  dragInFn = null;
  columnOut = null;
  columnIn = null;

  // Allow sortable component to finish up before allowing updates.
  setTimeout(() => {
    Log.debug('DragAndDrop: stopping drag');
    Globals.set('isDragging', false);
  }, 10);
};
exports.handleStoryDragUpdate = (story, storyElement, updates, priorityChanges) => {
  $(storyElement).addClass('is-updating');
  updates = _.omit(_.assignIn(updates, priorityChanges || {}), 'id');
  const existingColumn = ColumnModel.get({
    id: story.workflow_state_id
  });
  const workflowStateID = updates.workflow_state_id || existingColumn.id; // moving within a column

  const storyIsSelected = BulkSelectionModel.isSelected(story);
  if (storyIsSelected) {
    BulkSelectionModel.removeFromSelection(story.id);
  }
  const selectedStoryCount = BulkSelectionModel.getSelectionCount();
  if (storyIsSelected && selectedStoryCount > 0) {
    StoriesStoryController.disallowStoryActions();
  }
  _ensureNoAfterIdForDoneColumn(updates);
  StoryModel.serverSave(story, updates, {
    callback: err => {
      $(storyElement).removeClass('is-updating');
      if (err) {
        StoriesView.drawStories();
        MessageController.error(err, {
          secondary: 'Unable to update ' + (selectedStoryCount === 1 ? 'position' : 'positions') + '.'
        });
      }
    },
    afterRender: () => {
      if (storyIsSelected && selectedStoryCount > 0) {
        // Optimistically update the local copies in order to make the update appear to happen sooner.
        exports.updateStoriesLocallyMovedInBulk(workflowStateID, story.id);
        StoriesStoryController.updateBulkStoriesAndRedraw({
          after_id: story.id,
          workflow_state_id: workflowStateID
        });
      } else {
        _handleStoryDragUpdateResponse();
        StoriesStoryController.allowStoryActions();
      }
    }
  });
};
function _ensureNoAfterIdForDoneColumn(updates) {
  const column = ColumnModel.getById(updates.workflow_state_id);
  if (column && ColumnModel.isDone(column)) {
    if (updates.after_id) {
      delete updates.after_id;
    }
    if (updates.before_id) {
      delete updates.before_id;
    }
  }
}
exports.updateStoriesLocallyMovedInBulk = (workflowStateID, afterID) => {
  let prevStory = StoryModel.getById(afterID);
  // By default this collection is sorted by order clicked.
  const stories = _.sortBy(BulkSelectionModel.getStoriesForEachSelection(), 'position');
  Iterate.each(stories, story => {
    const storyUpdates = {
      id: story.id,
      // This could theoretically collide with the next lower story but it's extremely unlikely,
      // would only be for a couple seconds, and would be not visible in the column during the update.
      position: prevStory.position + 1,
      workflow_state_id: workflowStateID
    };
    StoryModel.update(storyUpdates);
    prevStory = story;
  });
  StoryModel.sortStoriesByPosition();
  StoriesStoryController.allowStoryActions();
  StoriesView.drawStories();
};
function _handleStoryDragUpdateResponse() {
  if (Globals.get('isDragging')) {
    Log.debug('DragAndDrop: ready to update story positions, but user is dragging.');
  } else if (Globals.get('isSaving')) {
    Log.debug('DragAndDrop: ready to update story positions, but something is mid-save.');
  } else {
    Updates.checkForUpdates();
  }
}
exports.cancelStorySort = () => {
  $('.sortable-column, #sidebar').sortable('cancel');
};
exports.destroy = () => {
  Utils.destroySortable($('.sortable-column.ui-sortable, #sidebar.ui-sortable'));
};
exports.initStorySorting = options => {
  // Preventing memory leaks:
  exports.destroy();

  // We're adding #sidebar so we can drop stories into columns and projects subnavs.
  $('.sortable-column, #sidebar').sortable({
    cancel: '.sidebar-bucket',
    connectWith: '.sortable-column, #sidebar',
    distance: 10,
    handle: '.story-summary, .bulk-checkbox, .sortable-handle, .js-sortable-handle',
    tolerance: 'pointer',
    start: exports.onStoryDragStart,
    over: exports.onOver,
    beforeStop: exports.onStoryBeforeStop,
    stop: options.preventDrop ? exports.cancelStorySort : exports.onStoryDragStop
  });
};
exports.init = options => {
  exports.initStorySorting(options);
  Event.onlyOn('pageDestroy.DragAndDrop', () => {
    exports.destroy();
  });
};
export { exports as default };