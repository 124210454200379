export const renderMermaid = async element => {
  if (!element) return;
  if (!element.querySelectorAll('.language-mermaid').length) return;
  const {
    default: mermaid
  } = await import(/* webpackChunkName: "mermaid" */'mermaid');
  if (element) {
    mermaid.initialize({
      startOnLoad: false,
      theme: 'base',
      themeVariables: {
        primaryColor: '#bfe6ff',
        primaryBorderColor: '#3A95C9',
        secondaryColor: '#e7d7ff',
        primaryTextColor: '#333',
        lineColor: '#7e7f85',
        textColor: '#333',
        // Gantt nodes
        fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
        fontSize: '14px',
        // Pie chart
        pieSectionTextColor: '#333',
        pieTitleTextSize: '20px',
        pieLegendTextSize: '14px',
        pieStrokeColor: '#333',
        pieStrokeWidth: '1px',
        pieOuterStrokeWidth: '0',
        pieOuterStrokeColor: 'transparent',
        pieOpacity: '1',
        // Sequence chart
        signalColor: '#7e7f85',
        // User Journey
        fillType0: '#7dcbfe',
        fillType1: '#c8e797',
        fillType2: '#ffa770',
        fillType3: '#fff5ad',
        fillType4: '#e7d7ff',
        fillType5: '#f0afe5'
      }
    });
    await mermaid.run({
      nodes: element.querySelectorAll('.language-mermaid')
    });
  }
};