import { COLORS } from '@clubhouse/shared/colors';
import { getIsDueSoon, getIsOverdue } from '@clubhouse/shared/utils';
export const getDueDateColor = (shouldColorize, dueDate) => {
  if (shouldColorize) {
    if (getIsOverdue(dueDate)) {
      return COLORS.RED50;
    }
    if (getIsDueSoon(dueDate)) {
      return COLORS.ORANGE47;
    }
  }
};