import * as ManageSecurityTemplate from 'app/client/settingsShared/views/templates/manageSecurity.html';
import Url from '../../../core/js/modules/url';
// TODO: Figure out what models we need (and maybe remove this?)
import UserModel from '../../../core/js/models/user';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import ManageTwoFactorAuthController from './manageTwoFactorAuth';
import ChangePasswordController from './changePassword';
const exports = {};
exports.title = `Security | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/account/manage-security';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  const html = ManageSecurityTemplate.render({
    active: 'manageSecurity',
    me: UserModel.getLoggedInUserPermission()
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  ManageTwoFactorAuthController.openPage();
  ChangePasswordController.render();
};
export { exports as default };