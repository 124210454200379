import { ListObjectivesForColumnDocument, ListObjectivesForTableDocument, ObjectivePageDocument } from '@clubhouse/datalayer/types';
import { ContextMenu, ContextMenuDivider, ContextMenuItem } from '@clubhouse/shared/components/ContextMenu';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/keyResults/links';
import { useDuplicateKeyResult } from 'components/gql/objective/hooks/useDuplicateKeyResult';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from 'utils/contextMenus/actions';
import { useContextMenuWithLogging } from 'utils/contextMenus/useContextMenuWithLogging';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DuplicateContextMenuItem = _ref => {
  let {
    id
  } = _ref;
  const {
    addToast
  } = useToast();
  const duplicateKeyResult = useDuplicateKeyResult(id);
  return _jsx(ContextMenuItem, {
    icon: "Copy",
    label: "Duplicate Key Result",
    onClick: async () => {
      await duplicateKeyResult({
        refetchQueries: [ObjectivePageDocument, ListObjectivesForTableDocument, ListObjectivesForColumnDocument]
      });
      addToast({
        Content: () => _jsx(ToastText, {
          children: `Key Result duplicated!`
        }),
        timeout: 2000
      });
    }
  });
};
DuplicateContextMenuItem.displayName = "DuplicateContextMenuItem";
export const KeyResultContextMenu = _ref2 => {
  let {
    id,
    objectiveId
  } = _ref2;
  return _jsxs(ContextMenu, {
    children: [_jsx(OpenInNewTabContextMenuItem, {
      url: getHref(objectiveId, id)
    }), _jsx(ContextMenuDivider, {}), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getHref(objectiveId, id, true),
      label: "Link"
    }), _jsx(ContextMenuDivider, {}), _jsx(DuplicateContextMenuItem, {
      id: id
    }), _jsx(ContextMenuDivider, {}), _jsx(PinContextMenuItem, {
      entityType: Nouns.KeyResult,
      loggingContext: {
        typename: 'KeyResult'
      },
      id: id
    })]
  });
};
KeyResultContextMenu.displayName = "KeyResultContextMenu";
/**
 * @param keyResultId Global Id
 * @param objectiveId Public Id
 */
export const useKeyResultContextMenu = (keyResultId, objectiveId) => {
  const {
    props
  } = useContextMenuWithLogging(() => _jsx(KeyResultContextMenu, {
    id: keyResultId,
    objectiveId: objectiveId
  }), {
    entityType: 'KeyResult'
  });
  return props;
};