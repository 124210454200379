import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ShimmerColumn = () => {
  return _jsxs("div", {
    className: "iteration-card iteration-card-shimmer",
    children: [_jsxs("div", {
      className: "iteration-card-header",
      style: {
        marginBottom: 20
      },
      children: [_jsx("div", {
        className: "shimmer shimmer-title"
      }), _jsx("div", {
        className: "shimmer shimmer-text"
      })]
    }), _jsxs("div", {
      className: "iteration-card-content",
      children: [_jsx("div", {
        className: "shimmer shimmer-story"
      }), _jsx("div", {
        className: "shimmer shimmer-story"
      }), _jsx("div", {
        className: "shimmer shimmer-story"
      })]
    })]
  });
};
ShimmerColumn.displayName = "ShimmerColumn";