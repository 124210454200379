import { QueryStorySubTasksDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { ComponentErrorBoundary } from 'components/shared/ComponentErrorBoundary';
import { useQuery } from 'gql';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const QUERY_STORY_SUBTASKS = QueryStorySubTasksDocument;
/**
 * `StorySubtasksInternal` contains the logic for Subtasks, but it is not meant to be used directly.
 * Prefer to use `StorySubtasks` instead, which contains the feature flag logic.
 */
function StorySubtasksInternal(_ref) {
  let {
    storyGlobalId
  } = _ref;
  const {
    data
  } = useQuery(QUERY_STORY_SUBTASKS, {
    variables: {
      // `storyGlobalId` won't be undefined here as we're skipping if that's the case
      storyId: storyGlobalId
    },
    fetchPolicy: 'cache-first',
    skip: !storyGlobalId
  });

  // TODO: (@charpeni) We probably want to split this component to handle new story vs. view story, but too early to tell.

  return _jsxs("div", {
    children: ["SUBTASKS PLACEHOLDER for ", storyGlobalId ? `Story Global Id: ${storyGlobalId}` : 'New Story', _jsx("pre", {
      children: JSON.stringify(data, null, 2)
    })]
  });
}
StorySubtasksInternal.displayName = "StorySubtasksInternal";
export function StorySubtasks(props) {
  const isSubtasksEnabled = useVariation(FEATURE_TOGGLES.ENABLE_SUBTASKS).value;
  if (!isSubtasksEnabled) {
    return null;
  }
  return _jsx(ComponentErrorBoundary, {
    componentName: "StorySubtasks",
    children: _jsx(StorySubtasksInternal, {
      ...props
    })
  });
}
StorySubtasks.displayName = "StorySubtasks";