import groupBy from 'lodash/groupBy';
import { Fragment, useMemo } from 'react';
import CheckDocumentsPng from '@clubhouse/assets/png/empty-tasks.png';
import { StoryTypeIcon } from 'components/shared/StoryTypeIcon';
import { useAllColumnsInterleaved } from 'data/entity/column';
import { getById } from 'data/entity/story';
import { filterTasksInColumn, getActiveTasksForCurrentUser, getStartedStoryTasksForCurrentUser } from 'data/entity/task';
import { useAppState } from 'utils/appState';
import { Task as TaskComponent } from '../shared/Task';
import { DisplaySelect } from './DisplaySelect';
import { StoryLinkName } from './components';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const DISPLAY_ITEMS = [{
  name: 'In Progress',
  note: 'My tasks from in progress stories.',
  value: 'STARTED_ONLY'
}, {
  name: 'To-Do and In Progress',
  note: 'My tasks from to-do and in progress stories.',
  value: 'UNFINISHED_ONLY'
}];
const defaultDisplayItem = DISPLAY_ITEMS[1];
export function ActiveTasks() {
  const [taskFilter, setTaskFilter] = useAppState({
    appStateKey: 'dashboardTaskFilter',
    default: defaultDisplayItem.value
  });
  const normalizedTaskFilter = useMemo(() => {
    const displayItem = DISPLAY_ITEMS.find(_ref => {
      let {
        value
      } = _ref;
      return value === taskFilter;
    });
    return displayItem?.value || defaultDisplayItem.value;
  }, [taskFilter]);
  const allColumns = useAllColumnsInterleaved();
  const {
    columns,
    tasks
  } = useMemo(() => {
    if (normalizedTaskFilter === 'STARTED_ONLY') {
      return {
        columns: allColumns.filter(c => c.type === 'started'),
        tasks: getStartedStoryTasksForCurrentUser()
      };
    } else {
      return {
        columns: allColumns.filter(c => c.type !== 'done'),
        tasks: getActiveTasksForCurrentUser()
      };
    }
  }, [normalizedTaskFilter, allColumns]);
  const hasTasks = tasks.length > 0;
  const tasksPerColumn = columns.map(column => [column, filterTasksInColumn(tasks, column)]).filter(_ref2 => {
    let [, tasks] = _ref2;
    return tasks.length > 0;
  });
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "dashboard-header",
      children: [_jsx("h3", {
        children: "My Tasks"
      }), _jsx("div", {
        className: "bucket-actions",
        children: _jsx(DisplaySelect, {
          label: "Select which Tasks to show",
          current: normalizedTaskFilter,
          options: DISPLAY_ITEMS,
          onChange: setTaskFilter
        })
      })]
    }), hasTasks ? _jsx("div", {
      className: "active-tasks",
      children: tasksPerColumn.map(_ref3 => {
        let [column, tasksByColumn] = _ref3;
        return _jsx(Fragment, {
          children: Object.entries(groupBy(tasksByColumn, 'story_id')).map(_ref4 => {
            let [storyId, tasks] = _ref4;
            const story = getById(storyId);
            if (!story) return null;
            return _jsxs(Fragment, {
              children: [_jsxs("div", {
                className: "story-above-task",
                children: [_jsx(StoryTypeIcon, {
                  size: 18,
                  storyType: story.story_type
                }), _jsx(StoryLinkName, {
                  story: getById(storyId)
                })]
              }), tasks.map(task => _jsx(TaskComponent, {
                task: task
              }, task.id))]
            }, storyId);
          })
        }, column.id);
      })
    }) : _jsxs("div", {
      className: "empty",
      children: [_jsx("img", {
        src: CheckDocumentsPng,
        className: "dashboard__image",
        alt: ""
      }), _jsx("p", {
        className: "heading",
        children: "You have no active tasks"
      }), _jsx("p", {
        className: "subheading",
        children: "Add tasks to any story and assign yourself."
      })]
    })]
  });
}