import { Component } from 'react';
import { logError } from 'utils/monitoring';
export class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, reactErrorInfo) {
    logError(error, {
      componentName: this.props.componentName
    }, {
      react: reactErrorInfo
    });
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback ?? null;
    }
    return this.props.children;
  }
}
ComponentErrorBoundary.displayName = "ComponentErrorBoundary";