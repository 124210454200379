import { oneOf } from '@charpeni/one-of';
import { rgba } from 'polished';
const colorKeyMap = {
  midnightBlue: 'midnight-blue',
  skyBlue: 'sky-blue',
  yellowGreen: 'yellow-green'
};
export const TINTS = {
  red: '#FE4A49',
  'sky-blue': '#49BDFE',
  yellow: '#FECB49',
  blue: '#47A5D3',
  green: '#44AC80',
  purple: '#917AED',
  fuchsia: '#CD65B6',
  turquoise: '#75DDDD',
  orange: '#FC945A',
  'midnight-blue': '#4B64A5',
  pink: '#FF75B7',
  gray: '#818181',
  black: '#444444',
  'yellow-green': '#B3D459',
  slate: '#789CC0',
  brass: '#C6AB82',
  'light-gray': '#C0C0C0'
};
export const getTint = key => TINTS[key] || TINTS[colorKeyMap[key]];
export const getFadedBackground = key => {
  return rgba(TINTS[key], 0.1);
};
export const PALETTE_TINT = ['red', 'sky-blue', 'yellow', 'blue', 'green', 'purple', 'fuchsia', 'turquoise', 'orange', 'midnight-blue', 'pink', 'gray', 'black', 'yellow-green', 'slate', 'brass'];
export const getRandomPaletteTint = () => oneOf(PALETTE_TINT);