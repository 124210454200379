import Logo from '@clubhouse/assets/png/third-party-logos/marker_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function MarkerIO() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.MARKER
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Marker.IO"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Marker.IO Logo",
        invertOnDarkMode: true
      }), _jsx(Layout.Blurb, {
        children: "Make it easy for your whole team to report and fix bugs easily without leaving your preferred workflow."
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://marker.io/shortcut-bug-tracking",
          children: "Learn more"
        }), " on how the", ' ', _jsx(ExternalLink, {
          href: "https://www.marker.io",
          children: "Marker.io"
        }), " integration works in Shortcut."]
      })]
    })]
  });
}
MarkerIO.displayName = "MarkerIO";