export const mediaQueries = {
  maxWidth600: '(max-width: 600px)',
  minWidth600: '(min-width: 601px)',
  maxWidth768: '(max-width: 768px)',
  minWidth768: '(min-width: 769px)',
  maxWidth1024: '(max-width: 1024px)',
  minWidth1024: '(min-width: 1025px)',
  maxWidth1440: '(max-width: 1440px)',
  minWidth1440: '(min-width: 1441px)',
  maxWidth1920: '(max-width: 1920px)',
  minWidth1920: '(min-width: 1921px)'
};
export const mixins = {
  maxHeight: height => `(max-height: ${height}px)`,
  minHeight: height => `(min-height: ${height}px)`,
  openSans: "font-family: 'Open Sans', Helvetica, Arial, sans-serif;",
  bold: function (fontSize, lineHeight) {
    return `
        ${this.openSans};
        font-size: ${fontSize};
        font-weight: 700;
        line-height: ${lineHeight};
    `;
  }
};
export const colors = {
  purple: '#6515dd',
  purpleDark: '#3e1191',
  green: '#0db350',
  greenDark: '#1cc344',
  greenVeryDark: '#138e01',
  grayLight: '#ccc',
  grayDark: '#333',
  gray: '#888',
  grayBitDarker: '#777',
  white: '#fff',
  whiteBitDarker: '#fafafa',
  whiteDark: '#eee',
  black: '#000',
  blackLight: '#111',
  blue: '#1982fc',
  blueDark: '#0d61bb',
  blueLight: '#4cabdd',
  blueVeryLight: '#e8effd',
  blueVeryDark: '#024',
  deepBlue: '#5381de',
  deepBlueLight: '#688fde',
  deepBlueVeryLight: '#a3d7e7',
  yellow: '#ffad27',
  red: '#d33',
  redDark: '#600',
  redVeryLight: '#ffcece',
  redLight: '#e05d5d'
};