import "core-js/modules/es.array.push.js";
import { useStory } from 'data/entity/story';
export const useStoryComment = (storyId, commentId) => {
  const story = useStory({
    id: storyId
  });
  const children = [];
  let comment = null;
  for (const c of story?.comments || []) {
    if (!c) continue;
    if (c.id === commentId) comment = c;else if (c.parent_id === commentId && !c.deleted) children.push(c);
  }
  if (comment !== null) comment.comments = children;
  return comment;
};