export function sumRow(td) {
  let sum = 0;
  while (td) {
    const value = Number.parseFloat(td.textContent || '');
    if (!Number.isNaN(value)) sum += value;
    td = td.previousElementSibling;
  }
  return sum;
}
export function sumCol(td) {
  let sum = 0;
  if (!td) return 0;
  let tr = td.parentElement;
  if (!tr || tr.tagName !== 'TR') return 0;
  const index = [...(tr.querySelectorAll('td') || [])].indexOf(td);
  if (index < 0) return 0;
  while (td) {
    const value = Number.parseFloat(td.textContent || '');
    if (!Number.isNaN(value)) sum += value;
    tr = tr?.previousElementSibling;
    td = [...(tr?.querySelectorAll?.(`td`) || [])][index];
  }
  return sum;
}