import noop from 'lodash/noop';
import { useRef } from 'react';
import { useOnUnmount } from './useOnUnmount';
const onMouseEnter = (stateRef, event) => {
  if (event.buttons === 1 || event.buttons === 4) return;
  const handleEvent = e => unhoverIfEventIsWithinElement(stateRef, e);
  stateRef.current.destroyMouseMove();
  document.addEventListener('mousemove', handleEvent, {
    passive: true
  });
  stateRef.current.destroyMouseMove = () => {
    document.removeEventListener('mousemove', handleEvent);
  };
  stateRef.current.clearDelayTimeout();
  if (stateRef.current.delay) {
    const timeoutId = window.setTimeout(stateRef.current.hover, stateRef.current.delay);
    stateRef.current.clearDelayTimeout = () => {
      window.clearTimeout(timeoutId);
    };
  } else if (stateRef.current.element) stateRef.current.hover(stateRef.current.element);
};
const unhoverIfEventIsWithinElement = (stateRef, e) => {
  if (!stateRef.current.element?.contains(e.target)) {
    unhoverAndDestroy(stateRef);
  }
};
const unhoverAndDestroy = stateRef => {
  stateRef.current.destroyMouseMove();
  stateRef.current.clearDelayTimeout();
  if (stateRef.current.element) stateRef.current.unhover(stateRef.current.element);
};
export const useHover = _ref => {
  let {
    delay = 0,
    hover,
    unhover
  } = _ref;
  const elementRef = useRef();
  const stateRef = useRef({
    delay,
    clearDelayTimeout: noop,
    destroyMouseEnter: noop,
    destroyMouseMove: noop,
    destroyMouseLeave: noop,
    hover: noop,
    unhover: noop,
    element: null
  });
  stateRef.current.hover = hover;
  stateRef.current.unhover = unhover;
  stateRef.current.delay = delay;
  const destroy = () => {
    const {
      clearDelayTimeout,
      destroyMouseEnter,
      destroyMouseMove,
      destroyMouseLeave
    } = stateRef.current;
    clearDelayTimeout();
    destroyMouseEnter();
    destroyMouseMove();
    destroyMouseLeave();
  };
  useOnUnmount(destroy);
  const ref = e => {
    if (!e) {
      stateRef.current.destroyMouseEnter();
      return;
    }
    elementRef.current = e;
    stateRef.current.element = e;
    stateRef.current.destroyMouseEnter();
    const handleMouseEnter = e => onMouseEnter(stateRef, e);
    e.addEventListener('mouseenter', handleMouseEnter);
    stateRef.current.destroyMouseEnter = () => {
      e.removeEventListener('mouseenter', handleMouseEnter);
    };
    stateRef.current.destroyMouseLeave();
    const handleMouseLeave = () => unhoverAndDestroy(stateRef);
    e.addEventListener('mouseleave', handleMouseLeave);
    stateRef.current.destroyMouseLeave = () => {
      e.removeEventListener('mouseleave', handleMouseLeave);
    };
  };
  return {
    ref,
    elementRef
  };
};