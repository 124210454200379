import { Icon } from '@useshortcut/shapes-ds';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useMemo } from 'react';
import CalendarPng from '@clubhouse/assets/png/empty-due-date.png';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { getIsDueSoon, getIsOverdue } from '@clubhouse/shared/utils';
import { isActiveEpic, isDone as isEpicDone, useEpics } from 'data/entity/epic';
import { isArchived as isStoryArchived, isDoneState as isStoryDone, useStories } from 'data/entity/story';
import { getLoggedInUserPermissionID } from 'data/entity/user';
import { useAppState } from 'utils/appState';
import { hasUpcomingDeadline } from 'utils/deadline';
import { getIconForStoryType } from 'utils/getIconForStoryType';
import { EpicAutoLink } from '../shared/EpicAutoLink';
import { DisplaySelect } from './DisplaySelect';
import { StoryLinkName } from './components';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const DISPLAY_ITEMS = [{
  name: 'Owned By Me',
  note: 'Only my stories and epics.',
  value: 'OWNER'
}, {
  name: 'Everything',
  note: 'All stories and epics.',
  value: 'EVERYTHING'
}];
const defaultDisplayItem = DISPLAY_ITEMS[1];
function isEpic(entity) {
  return entity.entity_type === 'epic';
}
const isOwner = (entity, currentUserId) => Boolean(entity?.owner_ids?.includes(currentUserId));
export function DueDates() {
  const [dueDatesFilter, setDueDatesFilter] = useAppState({
    appStateKey: 'dueDateFilter',
    default: defaultDisplayItem.value
  });
  const normalizedTaskFilter = useMemo(() => {
    const displayItem = DISPLAY_ITEMS.find(_ref => {
      let {
        value
      } = _ref;
      return value === dueDatesFilter;
    });
    return displayItem?.value || defaultDisplayItem.value;
  }, [dueDatesFilter]);
  const {
    stories
  } = useStories();
  const {
    epics
  } = useEpics();
  const dueDates = useMemo(() => {
    const ownerOnly = normalizedTaskFilter === 'OWNER';
    const profileId = getLoggedInUserPermissionID();
    const _stories = stories.filter(story => {
      const ownerFilter = ownerOnly ? isOwner(story, profileId) : true;
      const storyIsNotDoneOrArchived = !isStoryDone(story) && !isStoryArchived(story);
      return storyIsNotDoneOrArchived && hasUpcomingDeadline(story) && ownerFilter;
    });
    const _epics = epics.filter(epic => {
      const ownerFilter = ownerOnly ? isOwner(epic, profileId) : true;
      const epicIsNotDoneOrArchived = !isEpicDone(epic) && isActiveEpic(epic);
      return epicIsNotDoneOrArchived && hasUpcomingDeadline(epic) && ownerFilter;
    });
    return sortBy([..._stories, ..._epics], entity => entity.deadline ? new Date(entity.deadline) : null);
  }, [stories, epics, normalizedTaskFilter]);
  const hasDueDates = dueDates.length > 0;
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "dashboard-header",
      children: [_jsx("h3", {
        children: "Upcoming Due Dates"
      }), _jsx("div", {
        className: "bucket-actions",
        children: _jsx(DisplaySelect, {
          label: "Select which due dates to show",
          current: normalizedTaskFilter,
          options: DISPLAY_ITEMS,
          onChange: setDueDatesFilter
        })
      })]
    }), hasDueDates ? _jsx("div", {
      className: "due-dates",
      children: _jsx("ul", {
        children: dueDates.map(entity => {
          let StoryIcon;
          if (!isEpic(entity)) StoryIcon = getIconForStoryType(entity);
          const isOverdue = getIsOverdue(entity?.deadline);
          const isDueSoon = getIsDueSoon(entity?.deadline);
          return _jsxs("li", {
            children: [isEpic(entity) ? _jsxs("div", {
              className: "epic-wrapper",
              children: [_jsx(DeprecatedIconAdapter, {
                width: 18,
                children: _jsx(Icon, {
                  icon: "Epic",
                  fill: "interactive"
                })
              }), _jsx(EpicAutoLink, {
                epic: entity,
                showTooltip: true
              })]
            }) : _jsxs("div", {
              style: {
                display: 'flex',
                alignItems: 'center',
                minWidth: 0,
                gap: 4,
                width: '100%'
              },
              children: [_jsx(StoryIcon, {
                width: 18
              }), " ", _jsx(StoryLinkName, {
                story: entity
              })]
            }), _jsx(Chip, {
              kind: isOverdue ? 'redInverted' : isDueSoon ? 'orangeInverted' : 'default',
              children: moment(entity.deadline).format(DATE_FORMAT.SHORT_DATE_NO_YEAR)
            })]
          }, entity.id);
        })
      })
    }) : _jsxs("div", {
      className: "empty",
      children: [_jsx("img", {
        src: CalendarPng,
        className: "dashboard__image",
        alt: ""
      }), _jsx("p", {
        className: "heading",
        children: "All Clear"
      }), _jsx("p", {
        id: "empty_tasks",
        className: "subheading",
        children: "Nothing is due in the next 30 days."
      })]
    })]
  });
}