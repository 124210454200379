const getDomain = () => {
  if (process.env.ENV_NAME === 'production') return 'https://api.app.shortcut.com';else return 'https://api.app.shortcut-staging.com';
};
export class VerificationError extends Error {
  constructor(response, serverMessage) {
    super(`Verification failed: ${serverMessage ?? 'Unknown error'}`);
    this.status = response.status;
  }
}
export const verifyEmail = async (email, code) => {
  const response = await fetch(`${getDomain()}/api/private/signups/verify`, {
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'no-cache',
    method: 'POST',
    body: JSON.stringify({
      email,
      verification_code: code
    })
  });
  const result = await response.json();
  if (!response.ok) throw new VerificationError(response, result?.message);
  const {
    signup_id
  } = result;
  if (!signup_id) throw new VerificationError(response, result?.message);
  return signup_id;
};