import "core-js/modules/es.array.push.js";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Url'], Url]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as IterationNotFoundTemplate from 'app/client/iterations/views/templates/iterationNotFound.html';
import * as IterationPageTemplate from 'app/client/iterations/views/templates/iterationPage.html';
import { Button } from 'components/shared/DeprecatedButton';
import { Problem } from 'components/shared/Problem';
import { IterationPage } from 'pages/iteration/IterationPage';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import * as Event from '../../../core/js/_frontloader/event';
import InPlaceEditorController from '../../../core/js/controllers/inPlaceEditor';
import Is from '../../../core/js/modules/is';
import IterationModel from '../../../core/js/models/iteration';
import IterationPageStoriesController from './iterationPageStories';
import IterationUtils from '../modules/iterationUtils';
import MessageController from '../../../core/js/controllers/message';
import ProblemController from '../../../core/js/controllers/problem';
import RangedDatepickerController from '../../../core/js/controllers/rangedDatepicker';
import StoryModel from '../../../core/js/models/story';
import Tests from '../../../core/js/modules/tests';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import StoryDialog from '../../../core/js/controllers/storyDialog';
import NewStoryDialog from '../../../core/js/controllers/addNewStory';
import * as Page from 'utils/page';
const exports = {};
const getEventWithNs = eventName => `${eventName}.IterationPage`;
exports.getIdFromUrl = () => {
  const path = Url.parsePath();
  return path.iteration;
};
const setPageTitle = _ref2 => {
  let {
    title
  } = _ref2;
  Page.setPageTitle(title + ` | Iterations | ${BRAND.NAME}`);
};
const storyDialogIsOpen = () => {
  return StoryDialog.isOpen() || NewStoryDialog.isOpen();
};
exports.reRender = _.debounce(() => {
  if (storyDialogIsOpen()) {
    return;
  }
  const id = exports.getIdFromUrl();
  const iteration = IterationModel.getById(id);
  if (!iteration) {
    renderError({
      iterationFetchError: true
    });
    return;
  }
  IterationModel.addStoryDataToSingleIteration(id);
}, 50);
exports.init = () => {
  if (!Tests.usesIterations()) {
    Utils.redirect(Url.getSlugPath() + '/dashboard');
    return false;
  }
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
    exports.reInit();
  });
};
const bindEvents = _ref3 => {
  let {
    id
  } = _ref3;
  const iterationModelEvents = getEventWithNs('iterationChangesSaved');
  IterationModel.off(iterationModelEvents);
  IterationModel.on(iterationModelEvents, () => {
    exports.reRender({
      id
    });
  });
  const iterationRemoved = getEventWithNs('removed');
  IterationModel.off(iterationRemoved);
  IterationModel.on(iterationRemoved, iteration => {
    if (iteration.id === Url.parsePath().iteration) {
      Utils.redirect(Url.getSlugPath() + '/iterations');
    }
  });

  // When story dialog is closed
  Event.off('storyElementTearDown.IterationPage');
  Event.on('storyElementTearDown.IterationPage', exports.reRender);
  const storyModelEvents = ['added', 'updated', 'removed', 'bulkEnd'].map(getEventWithNs);
  storyModelEvents.forEach(StoryModel.off);
  const bulkEnd = storyModelEvents.pop();
  StoryModel.on(storyModelEvents.join(' '), story => {
    if (StoryModel.isNotBulk() && _.get(story, 'iteration_id') === id) {
      exports.reRender({
        id
      });
    }
  });
  StoryModel.on(bulkEnd, () => exports.reRender({
    id
  }));
  Event.onlyOn(getEventWithNs('pageDestroy'), () => {
    [iterationModelEvents, iterationRemoved].forEach(IterationModel.off);
    [bulkEnd, ...storyModelEvents].forEach(StoryModel.off);
  });
};
exports.reInit = async () => {
  const id = exports.getIdFromUrl();
  let errors;
  try {
    await Promise.all([IterationModel.fetch({
      id
    }), IterationPageStoriesController.fetch({
      id
    })]);
  } catch ([iterationFetchError, storiesFetchError]) {
    errors = {
      iterationFetchError,
      storiesFetchError
    };
  }
  bindEvents({
    id
  });
  IterationPageStoriesController.init();
  exports.render({
    errors,
    id
  });
};
exports.render = _ref4 => {
  let {
    errors,
    id
  } = _ref4;
  if (!IterationUtils.isViewingDetailPage()) {
    return;
  }
  if (errors) {
    renderError(errors);
    Event.trigger('pageRendered', IterationUtils.ITERATION_DETAIL_PAGE);
    return;
  }
  const iteration = IterationModel.getById(id);
  setPageTitle({
    title: iteration.name
  });
  IterationModel.addStoryDataToSingleIteration(id);
  View.renderWithComponents({
    template: IterationPageTemplate,
    templateData: {
      id: iteration.id,
      readOnly: Is.readOnly()
    },
    templateMountNode: document.querySelector('#iterations'),
    components: {
      iterationPage: {
        component: IterationPage
      }
    }
  });
  Event.trigger('pageRendered', IterationUtils.ITERATION_DETAIL_PAGE);
};
exports.createStoryInIteration = () => {
  const id = exports.getIdFromUrl();
  NewStoryDialog.updateState({
    iteration_id: id
  });
  NewStoryDialog.render({
    context: 'iteration'
  });
};
const renderError = _ref5 => {
  let {
    iterationFetchError,
    storiesFetchError
  } = _ref5;
  let description, title;
  if (iterationFetchError) {
    title = 'Iteration Not Found.';
    description = "The iteration was either deleted, or you don't have access to it.";
  } else if (storiesFetchError) {
    title = 'Iteration fetch error.';
    description = 'The iteration was found, but there was an error fetching the stories.';
  }
  setPageTitle({
    title
  });
  const html = IterationNotFoundTemplate.render();
  $('#iterations').html(html);
  View.renderComponentConditionally({
    containerSelector: '#no-iteration-found',
    component: Problem,
    props: {
      height: ProblemController.getContentHeight(ProblemController.EXTRA_OFFSETS.M),
      title,
      description,
      icon: React.createElement('span', {
        className: 'fa fa-exclamation-triangle'
      }),
      buttons: React.createElement(Button, {
        text: 'Go to Iterations page',
        href: `${App.Url.getSlugPath()}/iterations`,
        size: Button.sizes.xsmall
      })
    }
  });
};
exports.saveName = function () {
  const iteration = Utils.getModelFromContext(this);
  const editorElement = $(this).closest('.in-place-editor');
  const newName = editorElement.find('textarea').val().trim();
  if (!newName) {
    MessageController.error('A name is required.');
    return false;
  }
  if (iteration.name !== newName) {
    IterationModel.saveChanges(iteration, {
      name: newName
    }, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'The original name has been restored.'
        });
      }
    });
  }
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.openIterationDatesUpdater = function () {
  const model = Utils.getModelFromContext(this);
  RangedDatepickerController.open(this, {
    startDate: model.start_date,
    endDate: model.end_date,
    onApply: (start, end) => {
      const data = {
        start_date: start.format('YYYY-MM-DD'),
        end_date: end.format('YYYY-MM-DD')
      };
      IterationModel.saveChanges(model, data, err => {
        if (err) {
          MessageController.error(err, {
            secondary: 'The date change was not saved'
          });
        }
      });
    }
  });
};
export { exports as default };