import { QueryWorkspaceCurrentPermissionIdDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useLazyQuery, useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
const QUERY_WORKSPACE_CURRENT_PERMISSION_ID = QueryWorkspaceCurrentPermissionIdDocument;
export const useLazyWorkspaceCurrentPermissionIdQuery = () => {
  const slug = useWorkspaceSlug();
  const [executeQuery] = useLazyQuery(QUERY_WORKSPACE_CURRENT_PERMISSION_ID, {
    variables: slug ? {
      slug
    } : undefined
  });
  return useCallback(async () => {
    const {
      data
    } = await executeQuery();
    return data?.workspace2?.currentUserPermission?.id ?? null;
  }, [executeQuery]);
};
export const useCurrentPermissionIdQuery = slug => {
  const {
    data
  } = useQuery(QUERY_WORKSPACE_CURRENT_PERMISSION_ID, {
    variables: slug ? {
      slug
    } : undefined
  });
  return data?.workspace2?.currentUserPermission?.id ?? null;
};
export const useWorkspaceCurrentPermissionIdQuery = () => {
  const slug = useWorkspaceSlug();
  return useCurrentPermissionIdQuery(slug);
};