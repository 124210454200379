import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { useLatestRef } from '@clubhouse/shared/hooks/useLatestRef';
import { useStateWhenMounted } from '@clubhouse/shared/hooks/useStateWhenMounted';
const MIN_LIMIT = 20;
export const useTablePaginationState = function () {
  let {
    defaultOffset = 0,
    defaultLimit = 40,
    minLimit = MIN_LIMIT
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [pagination, setPagination] = useStateWhenMounted({
    offset: defaultOffset,
    limit: defaultLimit
  });
  const debouncedSetPagination = useMemo(() => debounce(setPagination, 1000), [setPagination]);
  const currentPaginationRef = useLatestRef(pagination);
  const setRangeByList = useCallback(range => {
    const first = range?.at(0) ?? defaultOffset;
    const last = range?.at(-1) ?? defaultOffset + defaultLimit - 1;
    const {
      offset,
      limit
    } = currentPaginationRef.current;
    if (first === offset && last === offset + limit) return;
    debouncedSetPagination({
      offset: first,
      limit: Math.max(last - first + 1, minLimit)
    });
  }, [currentPaginationRef, debouncedSetPagination, defaultLimit, defaultOffset, minLimit]);
  return [pagination, setRangeByList];
};