import { getTeamScopedApplicationStateKey, useTeamScopeId } from 'components/team-navigation';
import { useAppState } from 'utils/appState';
export const EPIC = 'Epic';
export const WORKFLOW = 'Workflow';
export const SELECTABLE_ENTITIES = [EPIC, WORKFLOW];
const ENTITIES_ARTICLES = {
  [EPIC]: 'an',
  [WORKFLOW]: 'a'
};
export const getArticleForEntity = entity => ENTITIES_ARTICLES[entity] || 'a';
const DEFAULT_FILTER_STATE = {
  epicId: null,
  workflowTeamId: null,
  workflowStateId: null
};
const STORAGE_KEY_ENTITY = 'Iterations.EntitySelected';
const STORAGE_KEY_ENTITY_STATE = 'Iterations.EntityStateFilter';
export function useTeamScopedStorageKey() {
  const {
    value
  } = useTeamScopeId();
  return getTeamScopedApplicationStateKey(value, STORAGE_KEY_ENTITY_STATE);
}
export function useEntitySelected() {
  return useAppState({
    appStateKey: STORAGE_KEY_ENTITY,
    default: SELECTABLE_ENTITIES[0]
  });
}
export function useEntityStateFilter() {
  const appStateKey = useTeamScopedStorageKey();
  const [filter, setFilter] = useAppState({
    appStateKey,
    default: DEFAULT_FILTER_STATE
  });
  if (filter.workflowTeam) {
    filter.workflowTeamId = filter.workflowTeam.id;
    delete filter.workflowTeam;
  }
  return [filter, change => setFilter({
    ...filter,
    ...change
  })];
}