import "core-js/modules/es.array.push.js";
import { useCallback, useState } from 'react';
import EpicModel from 'app/client/core/js/models/epic'; // eslint-disable-line import/no-restricted-paths
import { useEpic } from 'data/entity/epic';

// TODO: This might be removed and handled by GQL backend
//       Or if we don't receive a flattened list, it can maybe be handled in CSS/HTML alone instead.
const flattenComments = comments => {
  return comments.reduce((acc, comment) => {
    acc.push(comment);
    if (comment.comments?.length) {
      acc.push(...flattenComments(comment.comments));
      comment.comments = [];
    }
    return acc;
  }, []);
};
export function useEpicCommentsOnRoadmapPage(publicId) {
  const [comments, setComments] = useState(null);
  const fetch = useCallback(() => {
    EpicModel.fetchEpicPromise(publicId).then(_ref => {
      let {
        comments
      } = _ref;
      const list = (comments || []).map(c => ({
        ...c,
        comments: flattenComments(c.comments)
      }));
      list.reverse();
      setComments(list);
    });
  }, [publicId]);
  return [comments, fetch];
}
export function useEpicCommentsOnDetailPage(publicId) {
  const epic = useEpic({
    id: publicId
  });
  const comments = epic.comments || [];
  return comments;
}