import "core-js/modules/es.array.push.js";
import ManageTeamsController from 'app/client/settingsShared/js/controllers/manageTeams.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const ManageTeamsController = exports;
  [[['Model', 'Team'], TeamModel], [['Controller', 'ManageTeams'], ManageTeamsController], [['Controller', 'ManageTeams'], ManageTeamsController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as Event from 'app/client/core/js/_frontloader/event';
import Globals from 'app/client/core/js/_frontloader/globals';
import AddNewProjectController from 'app/client/core/js/controllers/addNewProject';
import FlashController from 'app/client/core/js/controllers/flash';
import HotReloadController from 'app/client/core/js/controllers/hotReload';
import MessageController from 'app/client/core/js/controllers/message';
import GroupModel from 'app/client/core/js/models/group';
import ProjectModel from 'app/client/core/js/models/project';
import TeamModel from 'app/client/core/js/models/team';
import UserModel from 'app/client/core/js/models/user';
import WorkflowModel from 'app/client/core/js/models/workflow';
import Dialog from 'app/client/core/js/modules/dialog';
import Format from 'app/client/core/js/modules/format';
import Is from 'app/client/core/js/modules/is';
import Url from 'app/client/core/js/modules/url';
import Utils from 'app/client/core/js/modules/utils';
import Validator from 'app/client/core/js/modules/validator';
import View from 'app/client/core/js/modules/view';
import SettingsPage from 'app/client/settings/js/controllers/settingsPage';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
import * as ManageTeamsTemplate from 'app/client/settingsShared/views/templates/manageTeams.html';
import * as ManageTeamsControlsTemplate from 'app/client/settingsShared/views/templates/manageTeamsControls.html';
import { WorkflowStateIcon } from 'data/entity/column';
import { simpleCompleteTask } from 'utils/quickstart';
import { WorkflowsList } from 'pages/workflows/components/WorkflowsList';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const PARENT_SELECTOR = '#manage-teams';
const TEAMID_KEY = 'ManageTeams.teamID';
const NEW_WORKFLOW_KEY = 'NEW_WORKFLOW';
export const EXPANDED_TEAMIDS_KEY = 'ManageTeams.expandedTeamIDs';
Globals.set(EXPANDED_TEAMIDS_KEY, []);

// No big deal if this state gets destroyed on hot-reload.
let newTeamState = {};
const selectedGroups = new Map();
exports.title = () => `${TeamModel.getLabel({
  isPlural: true
})} | ${BRAND.NAME}`;
exports.route = () => getRoute();
const getRoute = () => {
  const slug = Url.getSlugPath();
  return slug + '/settings/workflows';
};
exports.createRoute = () => {
  return `${exports.route()}/create`;
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.openCreateNew = () => {
  SettingsPage.loadPane({
    ...exports,
    route: exports.createRoute
  });
};
exports.render = callback => {
  const me = UserModel.getLoggedInUserPermission();
  callback = _.isFunction(callback) ? callback : _.noop;
  View.renderWithComponents({
    template: ManageTeamsTemplate,
    templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR),
    templateData: {
      active: 'manageTeams',
      readOnly: !Is.admin(me)
    },
    components: {
      readOnlyMessage: {
        component: AskAnOwnerAlert,
        props: {
          description: 'Only owners and admins can edit Workflows.'
        }
      }
    }
  });
  simpleCompleteTask('configure-workflow');
  TeamModel.fetchAll(() => {
    if (Url.isCurrentPath(exports.route()) || Url.isCurrentPath(exports.createRoute())) {
      const teamID = Globals.get(TEAMID_KEY);
      exports.renderControls();
      if (Url.isCurrentPath(exports.createRoute())) {
        exports.showNewTeamForm();
      } else if (teamID) {
        Globals.set(TEAMID_KEY, null);
        _openTeamEditorByID(teamID, callback);
      } else {
        callback();
      }
      SettingsPage.onDestroy('ManageTeams', () => {
        exports.destroyValidation();
        Globals.set(EXPANDED_TEAMIDS_KEY, []);
      });
    }
  });
  ProjectModel.on('added', () => {
    exports.renderControls();
  });
};
exports.openAndEdit = teamID => {
  Globals.set(TEAMID_KEY, teamID);
  exports.open();
};
exports.renderControls = () => {
  const me = UserModel.getLoggedInUserPermission();
  const teams = TeamModel.allSortedByName(); // TODO: sort by position

  const data = {
    readOnly: !Is.admin(me),
    teams,
    expandedTeamIDs: Globals.get(EXPANDED_TEAMIDS_KEY)
  };
  const groupCount = {};
  teams.forEach(team => groupCount[team.id] = WorkflowModel.getAssociatedGroups(team.workflow.id).length);
  data.setAssociatedGroupsToNewWorkflow = _setSelectedGroups(NEW_WORKFLOW_KEY);
  data.groupCount = groupCount;
  data.workflowStateStatusIcons = {
    backlog: i => View.renderComponentDelayed({
      componentKey: `backlogStateIcon-${i}`,
      component: _jsx(WorkflowStateIcon, {
        type: "backlog"
      })
    }).html,
    unstarted: i => View.renderComponentDelayed({
      componentKey: `unstartedStateIcon-${i}`,
      component: _jsx(WorkflowStateIcon, {
        type: "unstarted"
      })
    }).html,
    started: i => View.renderComponentDelayed({
      componentKey: `startedStateIcon-${i}`,
      component: _jsx(WorkflowStateIcon, {
        type: "started"
      })
    }).html,
    done: i => View.renderComponentDelayed({
      componentKey: `doneStateIcon-${i}`,
      component: _jsx(WorkflowStateIcon, {
        type: "done"
      })
    }).html
  };
  data.workflowsList = View.renderComponentDelayed({
    componentKey: `workflowsList`,
    component: WorkflowsList,
    props: {
      workflows: teams,
      onOpen: _addTeamToExpandedIds,
      onClose: _removeTeamFromExpandedIds,
      updateWorkflow: (team, updates, selectedGroups) => TeamModel.updateTeam(team, updates, async err => {
        _scrollDialogToTop();
        if (err) {
          throw err;
        } else {
          try {
            await _updateWorkflowGroups(team.workflow.id, selectedGroups, 'workflow update');
            FlashController.success(PARENT_SELECTOR, 'The <em>' + Format.sanitize(team.name) + `</em> ${TeamModel.getLabel()} has been updated.`);
          } catch {
            MessageController.error(err, {
              secondary: `Unable to associate Teams to ${TeamModel.getLabel()}.`
            });
          } finally {
            _removeTeamFromExpandedIds(team);
            exports.renderControls();
          }
        }
      }),
      deleteWorkflow: team => TeamModel.deleteTeam(team, err => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to delete Team.'
          });
        } else {
          Event.trigger('userDeletedTeam', team);
          WorkflowModel.remove({
            id: team.workflow.id
          });
        }
        _removeTeamFromExpandedIds(team);
        exports.renderControls();
      })
    }
  }).html;
  const html = ManageTeamsControlsTemplate.render(data);
  $(PARENT_SELECTOR).find('.controls').html(html);
  exports.initValidation();

  // This is just to fix some choppiness with the first time
  // fadeAndSlideToggleElements is called on the workflow selection step
  // (see exports.setAsActiveWorkflow function below).
  // (I could've just done this in the HTML, but in here it's better documented!)
  const activeWorkflow = $('.workflow.active', PARENT_SELECTOR);
  activeWorkflow.find('.state-abbreviation').css({
    display: 'none'
  });
  activeWorkflow.find('.states').css({
    display: 'block'
  });
};
exports.initValidation = () => {
  exports.destroyValidation();
  Validator.init(PARENT_SELECTOR);
};
exports.destroyValidation = () => {
  Validator.destroy(PARENT_SELECTOR);
};

// ----- Actions -----

exports.onKeyDownNewTitle = e => {
  if (Utils.keyPressed(e, ['ENTER', 'CMD+ENTER', 'CTRL+ENTER'])) {
    exports.continueToTypeScreen();
    return false;
  }
};
exports.showNewTeamForm = () => {
  const closingElement = $('#existing-teams');
  const openingElement = $('#new-team-form');
  newTeamState = {};
  selectedGroups.set(NEW_WORKFLOW_KEY, []);
  _updateWizardProgressToStep(1);
  View.slideElementsLeft(closingElement, openingElement, () => {
    $('#new-team-name').focus();
  });
  return false;
};
exports.cancelNewTeam = () => {
  const closingElement = $('#new-team-form');
  const openingElement = $('#existing-teams');
  View.slideElementsRight(closingElement, openingElement);
  return false;
};
exports.continueToTypeScreen = () => {
  const closingElement = $('#team-name-form');
  const openingElement = $('#team-type-form');
  if (Validator.isValid(closingElement)) {
    _.assignIn(newTeamState, {
      name: $('#new-team-name').val().trim(),
      description: $('#new-team-description').val().trim()
    });
    _updateWizardProgressToStep(2);
    View.slideElementsLeft(closingElement, openingElement);
  } else {
    Validator.focusOnFirstError(closingElement);
  }
  return false;
};
exports.showProjectImportScreen = () => {
  const closingElement = $('#team-type-form');
  const openingElement = $('#select-projects-for-team');
  newTeamState.type = 'projects';
  _updateWizardProgressToStep(3);
  View.slideElementsLeft(closingElement, openingElement);
  return false;
};
exports.showWorkflowScreen = () => {
  const closingElement = $('#team-type-form');
  const openingElement = $('#select-workflow-for-team');
  newTeamState.type = 'workflow';
  _updateWizardProgressToStep(3);
  View.slideElementsLeft(closingElement, openingElement);
  return false;
};
exports.backToTypeScreen = function () {
  const closingSelector = Utils.data(this, 'closing-selector');
  const closingElement = $(closingSelector);
  const openingElement = $('#team-type-form');
  _updateWizardProgressToStep(2);
  View.slideElementsRight(closingElement, openingElement);
  return false;
};
exports.backToNameScreen = () => {
  const openingElement = $('#team-name-form');
  const closingElement = $('#team-type-form');
  _updateWizardProgressToStep(1);
  View.slideElementsRight(closingElement, openingElement);
  return false;
};
function _updateWizardProgressToStep(stepNumber) {
  const parent = $('#new-team-form .wizard-progress');
  parent.find('.current-step').html(stepNumber);
  parent.find('.bullet').each(function (n) {
    if (stepNumber > n) {
      $(this).addClass('completed');
    } else {
      $(this).removeClass('completed');
    }
  });
}
function _removeTeamFromExpandedIds(team) {
  const expandedTeamIds = Globals.get(EXPANDED_TEAMIDS_KEY).slice();
  expandedTeamIds.splice(expandedTeamIds.indexOf(team.id), 1);
  Globals.set(EXPANDED_TEAMIDS_KEY, expandedTeamIds);
}
function _addTeamToExpandedIds(team) {
  Globals.set(EXPANDED_TEAMIDS_KEY, [...Globals.get(EXPANDED_TEAMIDS_KEY), team.id]);
}
exports.toggleProject = function () {
  _toggleProjectCheckbox(this);
  _highlightCurrentTeamChecklist(this);
  return false;
};
function _highlightCurrentTeamChecklist(context) {
  const parent = $('#select-projects-for-team');
  const checkbox = $(context);
  if (parent.find('.checked').length === 0) {
    parent.find('.team-group').removeClass('different-team');
    return false;
  }
  parent.find('.team-group').addClass('different-team');
  checkbox.closest('.team-group').removeClass('different-team');
  parent.find('.different-team .checked').each(function () {
    _uncheckProjectCheckbox(this);
  });
}
function _toggleProjectCheckbox(context) {
  if ($(context).hasClass('checked')) {
    _uncheckProjectCheckbox(context);
  } else {
    _checkProjectCheckbox(context);
  }
}
function _checkProjectCheckbox(context) {
  const project = Utils.getModelFromContext(context);
  newTeamState.project_ids = newTeamState.project_ids || [];
  if (!_.includes(newTeamState.project_ids, project.id)) {
    newTeamState.project_ids.push(project.id);
  }
  $(context).addClass('checked').find('.fa').removeClass('fa-square-o').addClass('fa-check-square');
}
function _uncheckProjectCheckbox(context) {
  const project = Utils.getModelFromContext(context);
  newTeamState.project_ids = newTeamState.project_ids || [];
  _.pull(newTeamState.project_ids, project.id);
  $(context).removeClass('checked').find('.fa').addClass('fa-square-o').removeClass('fa-check-square');
}
const _openTeamEditorByID = function (teamID) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _.noop;
  const teamEl = $(`.team[data-id="${teamID}"]`);
  const summaryEl = teamEl.find('.team-summary');
  const editorEl = teamEl.find('.team-editor');
  const column = $('#account-settings-form .right-column');
  View.fadeAndSlideToggleElements(summaryEl, editorEl, () => {
    column.animate({
      scrollTop: teamEl.offset().top - column.offset().top + column.scrollTop()
    }, 600);
    callback();
  });
};
exports.setAsActiveWorkflow = function () {
  const me = $(this);
  if (me.hasClass('active')) {
    return false;
  }
  const active = $('.workflow.active', PARENT_SELECTOR);
  active.find('.state-abbreviation').hide();
  active.removeClass('active').find('.fa-circle').removeClass('fa-circle').addClass('fa-circle-o');
  View.fadeAndSlideToggleElements(active.find('.states'), active.find('.state-abbreviation'));
  me.addClass('active').find('.fa-circle-o').removeClass('fa-circle-o').addClass('fa-circle');
  me.find('.states').hide();
  View.fadeAndSlideToggleElements(me.find('.state-abbreviation'), me.find('.states'));
  return false;
};
exports.addNewProjectInTeam = function () {
  // Close Settings dialog
  Dialog.close();
  AddNewProjectController.addNewInTeam.call(this);
  return false;
};
function _addWorkflowToFormData(data) {
  const workflowElement = $('.workflow.active', PARENT_SELECTOR);
  const prop = Utils.data(workflowElement, 'workflow-type') === 'global' ? 'global_workflow_id' : 'team_id';
  data[prop] = Utils.data(workflowElement, 'id');
}
function _scrollDialogToTop() {
  $(PARENT_SELECTOR).scrollTop(0);
}
exports.viewTeamOnStoriesPage = function () {
  const team = Utils.getModelFromContext(this);
  TeamModel.setActive(team, 'stories');
  if (Is.storiesPage()) {
    Event.trigger('teamSelectedInManageTeamsDialog');
  } else {
    HotReloadController.loadURL(Url.getSlugPath() + `/stories?workflow=${team.workflow.id}`, 'stories');
  }
  return false;
};
exports.createNewTeam = () => {
  const parent = $('#new-team-form');
  const button = parent.find('.create-team-button');
  if (newTeamState.type === 'workflow') {
    _addWorkflowToFormData(newTeamState);
    delete newTeamState.project_ids;
  } else {
    if (!newTeamState.project_ids || newTeamState.project_ids.length === 0) {
      MessageController.error('Please select at least one Project to move over.', {
        id: 'create-new-team-error'
      });
      return false;
    }
    const firstProject = ProjectModel.get({
      id: newTeamState.project_ids[0]
    });
    newTeamState.team_id = firstProject.team_id;
  }
  delete newTeamState.type;
  button.html('<span class="fa fa-spin fa-star"></span> Saving...').addClass('disabled').attr('disabled', 'disabled');
  TeamModel.saveNew(newTeamState, (err, savedTeam, errContext) => {
    exports.renderControls();
    _scrollDialogToTop();
    if (err) {
      if (!errContext?.responseIntercepted) {
        FlashController.error(PARENT_SELECTOR, `Unable to create ${TeamModel.getLabel()}.`, err);
      }
    } else {
      let guidance = '';
      const linkToStoriesPage = ' <a href="#" data-controller="ManageTeams" data-on-click="viewTeamOnStoriesPage" ' + 'data-model="Team" data-id="' + savedTeam.id + `">View this ${TeamModel.getLabel()} on the Stories page.</a>`;
      if (savedTeam.project_ids.length === 0) {
        guidance = `There are no Projects in this ${TeamModel.getLabel()}. Create a new Project in this ` + `${TeamModel.getLabel()} or move some existing Projects over.`;
      } else {
        guidance = 'There are ' + Format.pluralize(savedTeam.project_ids.length, 'Project', 'Projects') + ` in this ${TeamModel.getLabel()}.`;
      }
      const teamCreatedMessage = `The <em> ${Format.sanitize(savedTeam.name)} </em> ${TeamModel.getLabel()} has been created.`;
      _saveGroupsToWorkflowAssociation(savedTeam.workflow.id, {
        key: NEW_WORKFLOW_KEY,
        teamUpdated: 'workflow creation'
      }).then(() => {
        exports.renderControls();
        FlashController.success(PARENT_SELECTOR, teamCreatedMessage, linkToStoriesPage);
      }).catch(() => {
        guidance = `This ${TeamModel.getLabel()} could not be associated to all the selected teams.`;
        FlashController.error(PARENT_SELECTOR, teamCreatedMessage, guidance + linkToStoriesPage);
      });
    }
  });
  return false;
};
exports.deleteTeam = function () {
  const team = Utils.getModelFromContext(this);
  if (window.confirm(`Are you sure you want to delete the "${team.name}" ${TeamModel.getLabel()}?`)) {
    $(this).html('<span class="fa fa-spin fa-star"></span>').attr('data-on-click', '');
    TeamModel.deleteTeam(team, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to delete Team.'
        });
      } else {
        MessageController.success(`The <strong>${Format.sanitize(team.name)}</strong> ${TeamModel.getLabel()} was deleted.`);
        Event.trigger('userDeletedTeam', team);
        WorkflowModel.remove({
          id: team.workflow.id
        });
      }
      _removeTeamFromExpandedIds(team);
      exports.renderControls();
    });
  }
  return false;
};
const _setSelectedGroups = workflowId => function () {
  let groups = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (selectedGroups.has(workflowId)) {
    selectedGroups.set(workflowId, groups);
  }
};
const _saveGroupsToWorkflowAssociation = function (workflowId) {
  let {
    key = workflowId,
    teamUpdated = 'workflow update'
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const groupSelection = selectedGroups.get(key);
  return _updateWorkflowGroups(workflowId, groupSelection, teamUpdated);
};
const _updateWorkflowGroups = (workflowId, groupSelection, teamUpdated) => {
  const alreadyAssociatedGroups = WorkflowModel.getAssociatedGroups(workflowId);
  const removalUpdates = alreadyAssociatedGroups.filter(group => !groupSelection.some(selected => selected.id === group.id)).map(_ref2 => {
    let {
      id,
      workflow_ids
    } = _ref2;
    const filteredWorkflowIds = workflow_ids.filter(wId => Boolean(WorkflowModel.getById(wId)) && wId !== workflowId);
    return GroupModel.Promises.updateGroupWorkflows({
      id,
      workflow_ids: filteredWorkflowIds
    }, {
      teamUpdated
    });
  });
  const additionUpdates = groupSelection.filter(selected => !alreadyAssociatedGroups.some(group => group.id === selected.id)).map(_ref3 => {
    let {
      id,
      workflow_ids
    } = _ref3;
    const existingWorkflowIds = workflow_ids.filter(wId => Boolean(WorkflowModel.getById(wId)));
    return GroupModel.Promises.updateGroupWorkflows({
      id,
      workflow_ids: [...existingWorkflowIds, workflowId]
    }, {
      teamUpdated
    });
  });
  return Promise.all([...additionUpdates, ...removalUpdates]);
};
export { exports as default };