import { EpicNameFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableName, TableNameText } from 'components/shared/table/base';
import { NameField as SharedNameField } from 'components/shared/table/fields';
import { getHref } from '../../links';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EpicNameFieldFragment = EpicNameFieldFragmentFragmentDoc;
const NameFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const {
    publicId,
    name,
    archived
  } = entity;
  const url = getHref(publicId);
  return _jsx(TableName, {
    archived: archived,
    url: url,
    children: _jsx(TableNameText, {
      text: name
    })
  });
};
NameFieldComponent.displayName = "NameFieldComponent";
export const NameField = () => SharedNameField({
  Component: NameFieldComponent,
  width: 450,
  sort: true
});