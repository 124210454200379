import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const AddNewController = exports;
  [[['Controller', 'AddNew'], AddNewController], [['Controller', 'AddNewStory'], AddNewStoryController], [['Model', 'Story'], StoryModel], [['Model', 'StoryTemplate'], StoryTemplateModel], [['Controller', 'AddNew'], AddNewController], [['Controller', 'AddNewStory'], AddNewStoryController], [['Model', 'Story'], StoryModel], [['Model', 'StoryTemplate'], StoryTemplateModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { isEqual } from 'lodash';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon/SizedIcon';
import { Checkbox } from '@clubhouse/shared/components/Checkbox/Checkbox';
import { PROFILE_STATES } from '@clubhouse/shared/constants';
import { OpinionatedFieldType } from '@clubhouse/shared/types';
import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import * as AddNewStoryTemplate from 'app/client/core/views/templates/addNewStory.html';
import * as AddNewStoryActionsTemplate from 'app/client/core/views/templates/addNewStoryActions.html';
import * as AddNewStoryDeadlineTemplate from 'app/client/core/views/templates/addNewStoryDeadline.html';
import * as AddOwnerRequesterFieldsTemplate from 'app/client/core/views/templates/addOwnerRequesterFields.html';
import * as AttachmentTemplate from 'app/client/core/views/templates/attachment.html';
import * as CreateNewTemplateTemplate from 'app/client/core/views/templates/createNewTemplate.html';
import * as CreateStoryLinkTemplate from 'app/client/core/views/templates/createStoryLink.html';
import * as DropdownOwnersTemplate from 'app/client/core/views/templates/dropdownOwners.html';
import * as DropdownProjectTemplate from 'app/client/core/views/templates/dropdownProject.html';
import * as DropdownStoryEpicTemplate from 'app/client/core/views/templates/dropdownStoryEpic.html';
import * as DropdownStoryEstimateTemplate from 'app/client/core/views/templates/dropdownStoryEstimate.html';
import * as DropdownStoryTypeTemplate from 'app/client/core/views/templates/dropdownStoryType.html';
import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html';
import * as StoryCreatedMessageActionsTemplate from 'app/client/core/views/templates/storyCreatedMessageActions.html';
import * as UpdateTemplateTemplate from 'app/client/core/views/templates/updateTemplate.html';
import { StoryGroupDropdown } from 'components/groups/StoryGroupDropdown';
import { CustomFieldSelectList } from 'components/shared/custom-fields/CustomFieldSelectList';
import { SelectUsersDropdown } from 'components/shared/dropdowns/select-users';
import { WorkflowField } from 'components/shared/forms/fields/WorkflowField';
import { GroupMismatchIndicatorForOwners } from 'components/shared/GroupMismatchIndicator';
import { NewEntityDescriptionEditor } from 'components/shared/NewEntityDescriptionEditor';
import { JiraSyncCreateStoryMessage } from 'components/stories/JiraSyncCreateStoryMessage';
import { NewStoryKeyboardShortcuts } from 'components/stories/NewStoryKeyboardShortcuts';
import { StoryAttachments } from 'components/stories/StoryAttachments';
import { CustomFieldHeader } from 'components/stories/StoryAttributes/CustomFieldHeader';
import { getItemsForAutocomplete } from 'data/entity/column';
import { getIdForCanonicalName } from 'data/entity/customField';
import { getById, getTeamByTeamScopedId, isArchived } from 'data/entity/group';
import { createInvites } from 'data/entity/invite';
import { OptionalProject } from 'data/entity/project';
import * as StoryData from 'data/entity/story';
import { fetchAll } from 'data/entity/user';
import { getDefaultStateIdForWorkflow, getDefaultWorkflow } from 'data/entity/workflow';
import { withWriteContext } from 'pages/write';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
import { getCurrentPage } from 'utils/navigation';
import AddExternalLinkController from './addExternalLink';
import AddNewController from './addNew';
import AutocompleteController from './autocomplete';
import DropdownController from './dropdown';
import LabelAttachmentsController from './labelAttachments';
import MessageController from './message';
import PanelController from './panel';
import StoryController from './story';
import StoryLinkController from './storyLink';
import TaskController from './task';
import UploaderController from './uploader';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import WritePageController from '../../../write/js/controllers/writePage';
import BaseUtils from '../_frontloader/baseUtils';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import ColumnModel from '../models/column';
import CustomFieldModel from '../models/customField';
import DropdownModel from '../models/dropdown';
import EpicModel from '../models/epic';
import EstimateScaleModel from '../models/estimateScale';
import FeatureModel from '../models/feature';
import GroupModel from '../models/group';
import IterationModel from '../models/iteration';
import OrganizationModel from '../models/organization';
import PanelModel from '../models/panel';
import ProfileModel from '../models/profile';
import ProjectModel from '../models/project';
import StoryModel from '../models/story';
import StoryLinkModel from '../models/storyLink';
import StoryTemplateModel from '../models/storyTemplate';
import TaskModel from '../models/task';
import UserModel from '../models/user';
import WorkflowModel from '../models/workflow';
import ApplicationState from '../modules/applicationState';
import Autosize from '../modules/autosize';
import Constants from '../modules/constants';
import Dialog from '../modules/dialog';
import Format from '../modules/format';
import Is from '../modules/is';
import IterationModule from '../modules/iteration';
import Lightbox from '../modules/lightbox';
import LocalStorage from '../modules/localStorage';
import Settings from '../modules/settings';
import Tooltip from '../modules/tooltip';
import Url from '../modules/url';
import User from '../modules/user';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
import View from '../modules/view';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const PANEL_ID = 'addNewStory';
const PARENT_SELECTOR = '.add-new-dialog';
const SAVE_BTN_SELECTOR = '.create-button[data-on-click="save"]';
const CANCELED_DRAFT_KEY = 'AddNewStory.CanceledDraft';
const TITLE_SELECTOR = `${PARENT_SELECTOR} .title-container >.in-place-textarea > textarea`;
exports.LOCALSTORAGE_KEY = 'newStoryState';
exports.LOCALSTORAGE_DEFAULTS = 'newStoryDefaults';
exports.open = false;
exports.saving = false;
exports.onSave = _.noop;
const CREATE_ANOTHER_TOGGLE_KEY_MAPPING = {
  story: 'StoryIsCreateAnotherEnabled',
  epic: 'EpicIsCreateAnotherEnabled',
  iteration: 'IterationIsCreateAnotherEnabled',
  team: 'TeamIsCreateAnotherEnabled',
  label: 'LabelIsCreateAnotherEnabled',
  project: 'ProjectIsCreateAnotherEnabled',
  backlog: 'BacklogIsCreatedAnotherEnabled'
};
exports.route = () => {
  return Url.getSlugPath() + '/stories/new';
};
exports.renderIfDirectLink = () => {
  if (Url.getCurrentPathname() === exports.route()) {
    const {
      description,
      name,
      title,
      template_id
    } = Url.parseLocationSearch();
    const content = {
      description,
      name: name || title
    };
    const template = StoryTemplateModel.getById(template_id);
    if (template) {
      exports.renderFromTemplate(template, content);
    } else if (Object.values(content).some(value => value !== undefined)) {
      exports.renderFromContent(content);
    } else {
      exports.render();
    }
  }
};
exports.newTemplate = () => {
  const storyDefaults = exports.getStoryDefaults();
  const defaultProjectWithWorkflowAndState = OptionalProject.getDefaultValueForTemplate(storyDefaults);
  const profile = ProfileModel.getCurrentUserProfileDetails();
  const group = GroupModel.find(group => {
    if (group.archived) return false;
    return group.member_ids.includes(profile?.id);
  }) || GroupModel.find({
    archived: false
  });
  const groupWorkflowId = group?.workflow_ids.length && group.workflow_ids[0];
  const defaultWorkflowId = storyDefaults?.workflow_id;
  const workflowId = groupWorkflowId || defaultWorkflowId || undefined;
  return {
    deadline: null,
    name: '',
    activity: [],
    files: [],
    file_ids: [],
    group_id: typeof storyDefaults.group_id === 'undefined' && Boolean(group) ? group.id : null,
    linked_files: [],
    linked_file_ids: [],
    follower_ids: [],
    story_links: [],
    story_type: 'feature',
    external_links: [],
    requested_by_id: profile ? profile.id : null,
    owner_ids: [],
    estimate: null,
    epic_id: null,
    iteration_id: null,
    labels: [],
    tasks: [],
    template_name: Constants.NO_TEMPLATE,
    ...defaultProjectWithWorkflowAndState,
    workflow_id: workflowId,
    workflow_state_id: getDefaultStateIdForWorkflow(workflowId)
  };
};
exports.resetProject = () => {
  const state = exports.getState();
  const stateUpdates = OptionalProject.getDefaultValueForStory(state);
  exports.updateState(stateUpdates);
};
exports.getStoryDefaults = () => {
  return Utils.parseJSON(LocalStorage.get(exports.LOCALSTORAGE_DEFAULTS, {
    prefix: true
  }) || '{}');
};
exports.setStoryDefaults = storyDefaults => {
  return LocalStorage.set(exports.LOCALSTORAGE_DEFAULTS, storyDefaults, {
    prefix: true
  });
};
exports.openLabelDropdown = function () {
  return LabelAttachmentsController.openDropdown.call(this, exports.updateState, exports.getState().labels);
};
exports.removeLabel = function () {
  return LabelAttachmentsController.remove.call(this, exports.updateState, exports.getState().labels);
};
let initialState;
function setInitialState(state) {
  initialState = state;
}
function getInitialState() {
  return initialState;
}
function hasPendingChanges() {
  /* Current state wrapped in _updateStateForRendering because, in some instances the current story state was missing the 'description' prop,
   * resulting in the 'Open Draft' toast being displayed when there were actually no pending changes.
   */
  const currentState = _updateStateForRendering(exports.getState());
  return !isEqual(currentState, getInitialState());
}
exports.getState = () => {
  return AddNewController.getState(exports.LOCALSTORAGE_KEY) || exports.newTemplate();
};
exports.isOpen = () => {
  return exports.open;
};
exports.close = () => {
  if (exports.isOpen()) {
    PanelController.closeById(PANEL_ID);
    AddExternalLinkController.close();
    exports.onSave = _.noop;
  }
};
exports.getStoryDialogExpanded = () => {
  return User.isStoryDialogExpanded();
};

// We do these once instead of every time we verify state
// Ref: https://help.shortcut.com/agent/tickets/1003
exports._validateStateOnRender = state => {
  _.assignIn(state, OptionalProject.getDefaultValueForStory(state, true));

  // Ensure there's no project_id if Projects are disabled
  if (!FeatureModel.isProjectsFeatureEnabled()) {
    state.project = null;
    state.project_id = null;
  }

  // Ensure workflow state is from the current workflow or project's team.
  // Ref: https://help.shortcut.com/agent/tickets/8149
  if (!exports._workflowStateIsValid(state)) {
    _.assignIn(state, {
      workflow_state_id: OptionalProject.getDefaultWorkflowStateId(state)
    });
  }

  // If only a single epic is active, set it as the default epic.
  // We're scoping this to the Stories page only, because if the user navigates to an Epic page,
  // and clicks "Create Story in Epic", this would override that action.
  // Ref: https://app.shortcut.com/internal/story/31141
  const singleActiveEpic = Is.storiesPage() && EpicModel.singleActive();
  if ((!state.epic_id || _hasCleanState()) && singleActiveEpic && EpicModel.getVisibleEpics().length > 1) {
    state.epic_id = singleActiveEpic.id;
  }

  // Guard against deleted epic
  if (state.epic_id && !EpicModel.getById(state.epic_id)) {
    state.epic_id = null;
  }

  // Guard against archived team when creating a new story
  if (state.group_id) {
    const group = getById(state.group_id);
    if (isArchived(group)) {
      state.group_id = null;
    }
  }

  // Guard against deleted story links
  StoryLinkController.removeInvalidStoryLinks(state.story_links).then(filteredStoryLinks => {
    state.story_links = filteredStoryLinks;
  });
};
function _hasCleanState() {
  const template = exports.newTemplate();
  const dynamicProps = ['epic_id', 'project_id'];
  return _.isEqual(_.omit(template, dynamicProps), _.omit(_.pick(exports.getState(), _.keys(template)), dynamicProps));
}
exports._validateStateBeforeUpdate = state => {
  // Add followers, files and tasks if missing from client-side state:
  state.follower_ids = state.follower_ids || [];
  state.owner_ids = state.owner_ids || [];
  state.tasks = state.tasks || [];
  state.file_ids = state.file_ids || [];
  state.linked_file_ids = state.linked_file_ids || [];
  state.activity = state.activity || [];
  state.story_links = state.story_links || [];
  state.external_links = state.external_links || [];

  // Guard against deleted or missing column.
  const column = ColumnModel.getById(state.workflow_state_id);
  state.workflow_state_id = column ? column.id : WorkflowModel.getDefaultState().id;
  if (!state.requested_by_id) {
    state.requested_by_id = UserModel.getLoggedInUserPermissionID();
  }
  const group = GroupModel.getById(state.group_id);

  // Guard against Group and Workflow mismatch
  if (group && !GroupModel.isValidWorkflowForGroup(state.group_id, state.workflow_id)) {
    const {
      workflow_ids = []
    } = group;
    state.workflow_id = workflow_ids[0];
    state.workflow_state_id = getDefaultStateIdForWorkflow(workflow_ids[0]);
  }

  // Guard against Group without associated Workflows
  if (group && group.workflow_ids.length === 0) {
    state.group_id = null;
  }

  // Guard against missing Workflow
  if (!state.workflow_id) {
    state.workflow_id = getDefaultWorkflow().id;
  }

  // Guard against deleted story links
  StoryLinkController.removeInvalidStoryLinks(state.story_links).then(filteredStoryLinks => {
    state.story_links = filteredStoryLinks;
  });

  // Guard against deleted epic
  if (state.epic_id && !EpicModel.getById(state.epic_id)) {
    state.epic_id = null;
  }
  if (state.epic) {
    state.epic = null;
  }
  if (state.iteration) {
    state.iteration = null;
  }
};
exports.updateState = function () {
  let updates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const state = exports.getState();
  if (!state.group_id) {
    if (updates.epic_id) {
      const {
        group_ids: epic_group_ids
      } = EpicModel.getById(updates.epic_id);
      if (epic_group_ids?.length) {
        updates.group_id = epic_group_ids[0];
      }
    }
    if (updates.iteration_id) {
      const iteration_group_id = IterationModel.getById(updates.iteration_id)?.group_ids[0];
      if (iteration_group_id) {
        updates.group_id = iteration_group_id;
      }
    }
  }
  _.assignIn(state, updates);
  StoryModel.normalize(state);
  StoryModel.normalizeFiles(state);
  StoryModel.normalizeLinkedFiles(state);
  StoryModel.normalizeActivity(state, {
    attachmentsOnly: true
  });
  state.tasks.forEach((task, index) => {
    if (task.id) {
      TaskModel.update(task);
      state.tasks[index] = TaskModel.getById(task.id);
    } else {
      task.id = Utils.generateUUID();
      state.tasks[index] = task;
    }
    TaskModel.resetTaskStatus(task);
  });
  exports._validateStateBeforeUpdate(state);
  LocalStorage.set(exports.LOCALSTORAGE_KEY, state, {
    prefix: true
  });
  Utils.autoTabIndex($('.add-new-story'), false);

  // This will survive multiple sessions in localStorage,
  // unlike the above which includes arbitrary user input.
  exports.setStoryDefaults({
    project_id: state.project_id,
    group_id: state.group_id,
    workflow_id: state.workflow_id
  });
  StoryModel.trigger('newStoryUpdated', exports.getState(), updates);
};
function _updateStateForRendering(state) {
  const decorators = {
    owners: state.owners || ProfileModel.mapIDsToProfileDetails(state.owner_ids),
    followers: state.followers || ProfileModel.mapIDsToProfileDetails(state.follower_ids),
    requested_by: state.requested_by || ProfileModel.getAllDetailsById(state.requested_by_id)
  };
  const overrides = {
    description: Format.convertCustomMarkdownMentionsToNaked(state.description)
  };
  return _.assignIn(decorators, state, overrides);
}
const getInitialEpicIdValue = _ref2 => {
  let {
    epic_id
  } = _ref2;
  const epic = EpicModel.getFromUrl();
  return {
    epic_id: epic?.id ?? epic_id ?? null
  };
};
const getInitialGroupIdValue = _ref3 => {
  let {
    group_id
  } = _ref3;
  const team = getTeamByTeamScopedId();
  return {
    group_id: team?.id ?? group_id ?? null
  };
};
exports._getStateForRendering = function () {
  let isRenderedFromTemplate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const _state = exports.getState();
  if (_state.epic_id && !EpicModel.getById(_state.epic_id)) _state.epic_id = null;
  if (_state.iteration_id && !IterationModel.getById(_state.iteration_id)) _state.iteration_id = null;
  if (_state.group_id && !GroupModel.getById(_state.group_id)) _state.group_id = null;
  if (_state.project_id && !ProjectModel.getById(_state.project_id)) _state.project_id = null;
  const state = {
    ..._state,
    ...insertObjectIf(!isRenderedFromTemplate, {
      ...getInitialEpicIdValue(_state),
      ...getInitialGroupIdValue(_state)
    })
  };
  exports._validateStateOnRender(state);
  exports.updateState(state);
  return exports.getState();
};
exports.renderFromTemplate = (template, content) => {
  exports.resetState();
  const column = ColumnModel.getById(template.story_contents?.workflow_state_id);
  exports.updateState({
    // Ensure we're not loading draft state on templates
    project_id: null,
    group_id: null,
    workflow_state_id: null,
    template_id: template.id,
    template_name: template.name,
    ...template.story_contents,
    workflow_id: column?.workflow_id,
    ...insertObjectIf(content?.name != null, {
      name: content?.name
    }),
    ...insertObjectIf(content?.description != null, {
      description: content?.description
    })
  });
  // Presently we do not support create another feat. on story templates.
  exports.render({
    useCreateAnother: false,
    isRenderedFromTemplate: true
  });
};
exports.renderFromContent = _ref4 => {
  let {
    name,
    description,
    onSave
  } = _ref4;
  exports.resetState();
  exports.updateState({
    name,
    description
  });
  if (onSave) {
    exports.onSave = onSave;
  }
  exports.render();
};
exports.render = function () {
  let {
    context = 'story',
    useCreateAnother = true,
    isRenderedFromTemplate = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  window.addEventListener('beforeunload', TaskController.saveUncommittedTaskOnReload);
  const isReadOnly = Is.readOnly(UserModel.getLoggedInUserPermission());
  if (isReadOnly || exports.isOpen() || Settings.isOpen()) {
    return false;
  }
  const newStoryState = exports._getStateForRendering(isRenderedFromTemplate);
  const renderState = _updateStateForRendering(newStoryState);
  setInitialState(renderState);

  // The user may have the Create Story menu open at this point.
  DropdownController.closeAll();
  // User might create a story and then click "Create another" in the message dialog.
  MessageController.closeAll();
  exports.open = true;
  Globals.set(CANCELED_DRAFT_KEY, null);
  UploaderController.initLibraries();
  let panel;
  panel = PanelController.open({
    width: 900,
    html: AddNewStoryTemplate.render({
      ...renderState,
      areCustomFieldsEnabled: CustomFieldModel.areCustomFieldsEnabled(),
      context,
      useCreateAnother,
      isMobile: Is.mobile()
    }),
    id: PANEL_ID,
    keyEvent: 'keydown',
    beforeEscape: () => {
      const taskNotBeingEdited = $(document.activeElement).closest('.tasks').length === 0;
      const noDropdownsOpen = DropdownModel.size() === 0 && !AutocompleteController.isOpen() && !Dialog.isOpen() && !Lightbox.isOpen() && $('#panel-addNewStory textarea[aria-expanded="true"]').length === 0;
      return taskNotBeingEdited && noDropdownsOpen;
    },
    onOpen: panel => {
      if (panel && panel.element) {
        panel.element.find(TITLE_SELECTOR).focus();
        View.renderComponent({
          componentKey: 'addNewStoryAttachments',
          mountNode: panel.element.find('#story-attachments')[0],
          component: withWriteContext(StoryAttachments, WritePageController.getProps()),
          props: {
            StoryModel,
            story: exports.getState(),
            getNewStoryState: exports.getState,
            addExternalLink: e => exports.addExternalLink.call(e.target),
            addFile: exports.addFile
          }
        });
        renderDescriptionEditorV2(panel);
        subscribeToOrgUpdates(context, useCreateAnother);
        subscribeToStoryUpdates();
      }
      exports.renderRequesterOwnerFields();
    },
    onClose: () => {
      if (panel && panel.element) {
        const textarea = panel.element.find('textarea');
        textarea.off('keyup blur');
        Autosize.destroy(textarea);
        Event.trigger('storyElementTearDown', panel.element);
      }
      const wasClosedFromCancelAction = !!Globals.get(CANCELED_DRAFT_KEY);

      // save any "uncommitted" task to draft state on close.
      TaskController.saveUncommittedTask();
      if (hasPendingChanges() && !wasClosedFromCancelAction && !exports.saving) {
        MessageController.info('Your story draft has not been lost. Open the draft by clicking "Create Story" to continue editing.', {
          id: 'saved-new-story-dialog-changes',
          actions: '<button class="action mini elevated" data-controller="AddNewStory" ' + 'data-on-click="openDraftFromToast" >Open Draft</button>',
          timeout: 12_000
        });
      }
      Event.off('resize.AddNewStory');
      AutocompleteController.close();
      Validator.destroy(PARENT_SELECTOR);
      if (Is.mobile()) {
        $('#content').css({
          display: 'block'
        });
      }
      unsubscribeToOrgUpdates();
      unsubscribeToStoryUpdates();
      exports.open = false;
      window.removeEventListener('beforeunload', TaskController.saveUncommittedTaskOnReload);
    },
    onRemove: () => {
      View.unmountDetachedComponents();
      window.removeEventListener('beforeunload', TaskController.saveUncommittedTaskOnReload);
    },
    onScroll: Is.mobile() ? () => {
      return false;
    } : null,
    target: document.getElementById('add-new-button'),
    expanded: User.isStoryDialogExpanded()
  });
  if (!panel) {
    return false;
  }
  if (Is.mobile()) {
    $('#content').css({
      display: 'none'
    });
  }
  Validator.init(PARENT_SELECTOR);
  Event.trigger('afterStoryDetailsRender', panel.element);
  const textareas = panel.element.find('textarea');
  Autosize.bind(textareas);
  textareas.on('keyup blur', _.debounce(function () {
    if (!panel || !panel.element) {
      return;
    }
    const textarea = $(this);
    const key = textarea.data('binding');
    if (!key) return;
    const update = {};
    update[key] = textarea.val().trim();
    exports.updateState(update);
  }, 50));
  if (!newStoryState.deadline) {
    panel.element.find('.story-deadline').prev('.attribute-toggle').hide();
  }
  renderGroupDropdown();
  return false;
};
const renderDescriptionEditorV2 = panel => {
  Event.onlyOn('newStoryCreated.EditorV2', () => {
    renderDescriptionEditorV2(panel);
  });
  if (!panel.element) return;
  View.renderComponent({
    componentKey: 'addNewStoryReactMarkdownEditor',
    mountNode: panel.element.find('#story-description-v2')[0],
    component: NewEntityDescriptionEditor,
    props: {
      // The renderId forces a re-render of the editor with clean state.
      // `initialValue` doesn't work because it is usually an empty string (ie no change is triggered)
      renderId: String(Date.now()),
      initialValue: exports.getState()?.description || '',
      onChange: val => {
        exports.updateState({
          description: val
        });
      },
      onSave: async val => {
        exports.updateState({
          description: val
        });
        exports.save();
      }
    }
  });
};
const subscribeToOrgUpdates = (context, useCreateAnother) => {
  OrganizationModel.on('updated.reRenderCreateStoryButton', org => {
    if (org.id === OrganizationModel.getCurrentID()) {
      exports.reRenderCreateButton(context, useCreateAnother);
    }
  });
};
const unsubscribeToOrgUpdates = () => {
  OrganizationModel.off('updated.reRenderCreateStoryButton');
};
const GROUP_DROPDOWN_UPDATES_NAMESPACE = 'newStoryUpdated.groupDropdown';
const REQUESTER_OWNER_UPDATES_NAMESPACE = 'newStoryUpdated.requesterOwnerDropdown';
const WORKFLOW_DROPDOWN_UPDATES_NAMESPACE = 'newStoryUpdated.workflowDropdown';
const FOLLOWER_UPDATES_NAMESPACE = 'newStoryUpdated.followers';
const LABELS_UPDATES_NAMESPACE = 'newStoryUpdated.labels';
const ITERATION_UPDATES_NAMESPACE = 'newStoryUpdated.iteration';
const DEADLINE_UPDATES_NAMESPACE = 'newStoryUpdated.deadline';
const subscribeToStoryUpdates = () => {
  StoryModel.on(GROUP_DROPDOWN_UPDATES_NAMESPACE, (_story, updates) => {
    if (StoryData.hasNameOrDescriptionChanged(updates)) {
      return;
    }
    renderGroupDropdown();
  });
  StoryModel.on(REQUESTER_OWNER_UPDATES_NAMESPACE, (_story, updates) => {
    if (updates.group_id !== undefined || updates.owner_ids !== undefined) {
      exports.renderRequesterOwnerFields();
    }
  });
  StoryModel.on(WORKFLOW_DROPDOWN_UPDATES_NAMESPACE, (_story, updates) => {
    if (StoryData.hasNameOrDescriptionChanged(updates)) {
      return;
    }
    exports.renderWorkflowDropdown();
    exports.renderJiraSyncMessage();
    exports._renderWorkflowStateControl(ColumnModel.getById(_story.workflow_state_id));
  });
  StoryModel.on(REQUESTER_OWNER_UPDATES_NAMESPACE, (_story, updates) => {
    if (updates.group_id !== undefined || updates.owner_ids !== undefined) {
      exports.renderRequesterOwnerFields();
    }
  });
  StoryModel.on(FOLLOWER_UPDATES_NAMESPACE, (state, updates) => {
    if (updates.follower_ids !== undefined) {
      $('.add-new-story .story-followers .value').html(StoryModel.describeFollowers(state));
    }
  });
  StoryModel.on(LABELS_UPDATES_NAMESPACE, (state, updates) => {
    if (updates.labels !== undefined) {
      LabelAttachmentsController.redraw(state.labels);
    }
  });
  StoryModel.on(ITERATION_UPDATES_NAMESPACE, (state, updates) => {
    if (updates.iteration_id !== undefined) {
      IterationModule.renderDropdown(`${PARENT_SELECTOR} #story-dialog-iteration-dropdown`, updates.iteration_id);
    }
  });
  StoryModel.on(DEADLINE_UPDATES_NAMESPACE, (state, updates) => {
    if (updates.deadline !== undefined) {
      const deadlineNode = document.querySelector(`${PARENT_SELECTOR} .story-deadline`);
      if (deadlineNode) {
        deadlineNode.outerHTML = AddNewStoryDeadlineTemplate.render(state);
      }
    }
  });
};
const unsubscribeToStoryUpdates = () => {
  [GROUP_DROPDOWN_UPDATES_NAMESPACE, REQUESTER_OWNER_UPDATES_NAMESPACE, WORKFLOW_DROPDOWN_UPDATES_NAMESPACE, FOLLOWER_UPDATES_NAMESPACE, LABELS_UPDATES_NAMESPACE, ITERATION_UPDATES_NAMESPACE, DEADLINE_UPDATES_NAMESPACE].forEach(namespace => StoryModel.off(namespace));
};

// ----- Actions -----

exports.cancel = () => {
  _saveCanceledDraft();
  exports.resetState();
  PanelController.closeById(PANEL_ID);
  return false;
};
exports.toggleStoryDialogExpanded = () => {
  const currentExpanded = User.isStoryDialogExpanded();
  User.setStoryDialogExpanded(!currentExpanded);
  const panel = PanelModel.getById(PANEL_ID);
  PanelController.setExpanded(PANEL_ID, !currentExpanded);
  Tooltip.close();
  $(panel.element).one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
    Dialog.toggleExpandButtonLabel($(panel.element).find('.expand-button'));
  });
};
function _saveCanceledDraft() {
  const state = exports.getState();
  Globals.set(CANCELED_DRAFT_KEY, state);
  MessageController.info('Story draft deleted.', {
    id: 'story-draft-canceled-message',
    timeout: 12000,
    actions: '<button class="action mini elevated" data-controller="AddNewStory" ' + 'data-on-click="restoreDraft">Restore</button> <button class="action mini elevated" ' + 'data-controller="AddNewStory" data-on-click="render">Start over</button>'
  });
}
exports.openDraftFromToast = () => {
  exports.render();
};
exports.restoreDraft = () => {
  const state = Globals.get(CANCELED_DRAFT_KEY);
  Globals.set(CANCELED_DRAFT_KEY, null);
  if (state) {
    exports.updateState(state);
    exports.render();
  }
  return false;
};
exports.onKeyDown = function (e) {
  if (Utils.keyPressed(e, ['CMD+ENTER', 'CTRL+ENTER'])) {
    // Needed to prevent ENTER keypress from bubbling.
    $(this).prop('readonly', true);
    setTimeout(exports.save, 50, e);
    return false;
  }
};
exports.onKeyDownTitle = function (e) {
  if (Utils.keyPressed(e, ['ENTER', 'CMD+ENTER', 'CTRL+ENTER'])) {
    // Needed to prevent ENTER keypress from bubbling.
    $(this).prop('readonly', true);
    setTimeout(exports.save, 50, e);
    return false;
  }
};
exports._workflowStateIsValid = state => {
  const workflow = WorkflowModel.getById(state.workflow_id);
  const stateIds = workflow?.states.map(state => state.id) ?? [];
  return stateIds.includes(state.workflow_state_id);
};
function _validateStateBeforeSave(story) {
  story.name = story.name.trim();

  // In Safari the name field can get out of sync by switching tabs, this should prevent that from happening:
  if (story.name === '') {
    const currentStoryName = document.querySelector('.add-new-dialog .title-container textarea')?.value?.trim();
    story.name = currentStoryName;
  }

  // Guard against deleted or missing project.
  // Ref: https://app.shortcut.com/internal/story/1146
  if (OptionalProject.isProjectInvalidForStory(story)) {
    MessageController.alert('The Project you had selected is either deleted or archived. Please select a different Project.');
    return false;
  }
  if (story.epic_id && !EpicModel.getById(story.epic_id)) {
    MessageController.alert('The Epic you had selected has been deleted. Please select a different Epic.');
    return false;
  }
  if (_.some(story.story_links, StoryLinkModel.isInvalid)) {
    MessageController.alert('The Story you had selected in a relationship has been deleted. Please select a different Story.');
    return false;
  }
  if (!story.workflow_state_id || !ColumnModel.getById(story.workflow_state_id)) {
    MessageController.alert('The workflow state you had selected has been deleted. Please select a new state.');
    return false;
  }
  if (!WorkflowModel.getById(story.workflow_id)) {
    MessageController.alert('The selected Workflow has been deleted. Please select a new Workflow.');
    return false;
  }
  if (!exports._workflowStateIsValid(story)) {
    MessageController.alert('The selected workflow state is not from the selected Workflow.');
    return false;
  }
  const requester = ProfileModel.getAllDetailsById(story.requested_by_id);
  if (requester && requester.state === PROFILE_STATES.DISABLED) {
    MessageController.alert('The requester of this Story, <strong>' + Format.sanitize(requester.name) + '</strong> has been disabled. Please select a user that is not disabled.');
    return false;
  }
  const disabledOwners = [];
  story.owner_ids.forEach(id => {
    const user = ProfileModel.getAllDetailsById(id);
    if (user.state === PROFILE_STATES.DISABLED) {
      disabledOwners.push(user);
    }
  });
  if (disabledOwners.length > 0) {
    exports.removeDisabledOwners(story, disabledOwners);
    if (disabledOwners.length === 1) {
      MessageController.alert('The owner <strong>' + Format.sanitize(disabledOwners[0].name) + "</strong> has been disabled. We've removed them as an owner, but the Story has not yet been created.");
    } else {
      MessageController.alert('The owners <strong>' + Format.sanitize(_.map(disabledOwners, 'name').join(', ')) + "</strong> have been disabled. We've removed them as an owner, but the Story has not yet been created.");
    }
    return false;
  }
  if (story.group_id && !GroupModel.isValidWorkflowForGroup(story.group_id, story.workflow_id)) {
    MessageController.alert("Workflow does not match the selected Team. Update the Workflow to one of the Team's Workflows.");
    return false;
  }
  if (EstimateScaleModel.isDisabled()) {
    story.estimate = null;
  }
}
exports.removeDisabledOwners = (story, disabledOwners) => {
  disabledOwners.forEach(user => {
    _.pull(story.owner_ids, user.id);
  });
  exports.updateState({
    owner_ids: story.owner_ids
  });
  $(PARENT_SELECTOR).find('.story-owner').html(DropdownOwnersTemplate.render({
    owners: ProfileModel.mapIDsToProfileDetails(story.owner_ids)
  }));
  return false;
};
exports.openSaveOptionsDropdown = function () {
  const items = [{
    name: 'Save as New Template...',
    value: 'saveAsTemplate'
  }];
  const templateId = exports.getState().template_id;
  const template = StoryTemplateModel.getById(templateId);
  if (template) {
    items.push({
      name: `Update Template...`,
      value: 'updateTemplate',
      className: 'update-template'
    });
  }
  const dropdown = DropdownController.open({
    items,
    className: 'oversized',
    target: $(this).closest('.story-dropdown-target'),
    width: 225,
    onApply: value => {
      if (value === 'saveAsTemplate') {
        _openNewTemplateDialog();
      } else if (value === 'updateTemplate') {
        _openUpdateTemplateDialog(exports.getState());
      }
    }
  });
  if (dropdown) {
    dropdown.dropdownElement.find('.active').focus();
  }
  return false;
};
const _createAnother = () => {
  TaskModel.deleteTemporaryTasks();
  const updateVals = {
    activity: [],
    deadline: null,
    description: '',
    estimate: null,
    files: [],
    file_ids: [],
    linked_files: [],
    linked_file_ids: [],
    name: '',
    follower_ids: [],
    story_links: [],
    external_links: [],
    tasks: []
  };
  exports.updateState(updateVals);
  const storyTitleTextarea = document.querySelector(TITLE_SELECTOR);

  // "Reset" non persisted story UI elements for "save and create-another".
  TaskController.resetTasks();
  storyTitleTextarea.value = updateVals.name; // Story Title.
  storyTitleTextarea.focus(); // Focus Story title field.

  $(`${PARENT_SELECTOR} .story-attachments-wrapper >.story-attachment >.comments`).empty(); // Story attachments (relationships, files, ext. links).
  exports.updateStoryEstimate(updateVals.estimate); // Story estimate.
  $('.add-new-dialog textarea').prop('readonly', false); // Set Title and Description inputs to non-read only state.
};
exports.save = () => {
  if (exports.saving) {
    return;
  }
  const saveBtnElem = document.querySelector(`${PARENT_SELECTOR} ${SAVE_BTN_SELECTOR}`);
  /** Get the 'data-context' & 'data-use-create-another' attribute values from Save btn and not the current target,
   *  so when Enter || CMD+Enter key is used to create story correct values are passed along.
   **/
  const currentContext = saveBtnElem?.dataset.context;
  const shouldUseCreateAnother = saveBtnElem?.dataset.useCreateAnother;
  if (Validator.isValid(PARENT_SELECTOR)) {
    const story = exports.getState();
    if (_validateStateBeforeSave(story) === false) {
      return false;
    }
    const isCreateAnotherChecked = document.querySelector('div.create-another-story #create-another')?.checked;
    exports.saving = true;
    $('.add-new-dialog .add-new-action').html('<div class="saving"><span class="fa fa-star fa-spin"></span> Saving...</div>');
    const page = getCurrentPage();
    StoryModel.addStory(story, {
      actionContext: page
    }, (err, res) => {
      if (err) {
        $('.add-new-dialog .add-new-action').html(AddNewStoryActionsTemplate.render({
          areCustomFieldsEnabled: CustomFieldModel.areCustomFieldsEnabled(),
          context: currentContext,
          useCreateAnother: shouldUseCreateAnother
        }));
        MessageController.error(err, {
          secondary: 'Unable to create <strong>' + Format.sanitize(story.name) + '</strong>.'
        });
      } else {
        _showStoryCreatedMessage(res, isCreateAnotherChecked);
        if (shouldUseCreateAnother && isCreateAnotherChecked) {
          _createAnother();
          exports.reRenderCreateButton(currentContext, shouldUseCreateAnother);
        } else {
          PanelController.closeById(PANEL_ID);
          // State needs to be reset last so that the original task can be deleted.
          exports.resetState();
        }
        Event.trigger('newStoryCreated', res);
        dispatchEvent(EVENT_TYPES.STORY_CREATED);
        exports.onSave(res);
      }
      exports.saving = false;
    });
  } else {
    $('.add-new-dialog textarea').prop('readonly', false);
    $('body').focus();
    Validator.focusOnFirstError(PARENT_SELECTOR);
  }
};
function _showStoryCreatedMessage(story) {
  let isCreateAnotherEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const storyAutoLink = StoryAutoLinkTemplate.render(story);
  const actions = StoryCreatedMessageActionsTemplate.render({
    id: story.id,
    url: story.app_url,
    storyAutoLink,
    createAnotherController: Is.epicPage() ? 'EpicPage' : 'AddNewStory',
    createAnotherOnClick: Is.epicPage() ? 'createStoryInEpic' : 'render',
    isCreateAnotherEnabled
  });
  MessageController.success('Story ' + storyAutoLink + 'created.', {
    actions,
    timeout: 20000,
    width: 404
  });
}
exports.resetState = () => {
  const state = exports.getState();
  TaskModel.deleteTemporaryTasks();
  Event.trigger('newStoryStateReset');
  exports.updateState({
    activity: [],
    deadline: null,
    description: '',
    epic_id: null,
    iteration_id: null,
    estimate: null,
    files: [],
    file_ids: [],
    linked_files: [],
    linked_file_ids: [],
    custom_fields: [],
    labels: [],
    tasks: [],
    source_task_id: null,
    source_task_story_id: null,
    name: '',
    follower_ids: [],
    story_links: [],
    story_type: 'feature',
    external_links: [],
    requested_by_id: UserModel.getLoggedInUserPermissionID(),
    template_name: Constants.NO_TEMPLATE,
    template_id: null,
    owner_ids: [],
    workflow_state_id: OptionalProject.getDefaultWorkflowStateId(state)
  });
};
exports.openProjectDropdown = function () {
  const state = exports.getState();
  const container = $(this);
  const items = ProjectModel.getFilteredItemsForAutocomplete(state.workflow_id);
  return AutocompleteController.open({
    items,
    onApply: id => {
      const project = ProjectModel.getById(id);
      container.html(DropdownProjectTemplate.render(project));
      exports._updateProjectState(project);
      Utils.deferFocus(container);
    },
    inputValue: (state.project || {}).name,
    title: 'Select Project',
    value: (state.project || {}).id,
    showInput: true,
    target: this,
    topOffset: 0,
    width: 240
  });
};
exports._updateWorkflowState = workflowId => {
  const workflow = WorkflowModel.getById(workflowId);
  const stateUpdates = {
    workflow_id: workflowId,
    workflow_state_id: workflow.default_state_id,
    project_id: null
  };
  if (FeatureModel.isProjectsFeatureEnabled()) {
    document.getElementById('story-project-dropdown-container').innerHTML = DropdownProjectTemplate.render({});
  }
  exports.updateState(stateUpdates);
};
exports._updateProjectState = newProject => {
  const stateUpdates = OptionalProject.getProjectEntry(newProject);
  exports.updateState(stateUpdates);
};
function _openUpdateTemplateDialog(state) {
  const {
    template_name
  } = state;
  const showStoryLinkWarning = _.has(state, 'story_links[0]');
  const html = UpdateTemplateTemplate.render({
    template_name,
    showStoryLinkWarning
  });
  const dialogId = 'update-template';
  Dialog.open(html, {
    id: dialogId,
    closeOnEscape: true,
    onOpen: () => {
      Validator.init(dialogId);
      $('#template-name').select().focus();
    },
    onClose: () => {
      Validator.destroy(dialogId);
    }
  });
}
exports.updateTemplateKeydown = e => {
  if (Utils.keyPressed(e, 'ENTER')) {
    e.preventDefault();
    exports.updateTemplate();
  }
  return false;
};
exports.createTemplateKeydown = e => {
  if (Utils.keyPressed(e, 'ENTER')) {
    e.preventDefault();
    exports.createNewTemplate();
  }
  return false;
};
function _openNewTemplateDialog() {
  const showStoryLinkWarning = _.has(exports.getState(), 'story_links[0]');
  const html = CreateNewTemplateTemplate.render({
    showStoryLinkWarning
  });
  const dialogId = 'create-template';
  Dialog.open(html, {
    id: dialogId,
    closeOnEscape: true,
    onOpen: () => {
      Validator.init(dialogId);
      $('#template-name').focus();
    },
    onClose: () => {
      Validator.destroy(dialogId);
    }
  });
}
exports.createStoryWithNewTemplate = event => {
  const template = Utils.getModelFromContext(event.target);
  exports.renderFromTemplate(template);
};
exports.createNewTemplate = () => {
  const name = $('#template-name').val();
  if (Validator.isValid('#create-template')) {
    $('#create-template .action.green').replaceWith('<div class="saving"><span class="fa fa-star fa-spin"></span> Saving...</div>');
    const template = exports.getState();
    if (template.project_id === null) {
      delete template.project_id;
    }
    StoryTemplateModel.create(name, template).then(res => {
      Dialog.close();
      MessageController.success(`Template <b>${StoryModel.getFormattedName({
        name
      })}</b> created.`, {
        actions: `
            <button
              class="action mini elevated"
              data-controller="AddNewStory"
              data-on-click="createStoryWithNewTemplate"
              data-model="StoryTemplate"
              data-id="${res.id}"
            >
              Create Story with Template
            </button>`
      });
      TaskModel.deleteTemporaryTasks();
      exports.resetState();
      exports.close();
    }).catch(res => {
      MessageController.error(res.error, {
        secondary: `Unable to create template <b>${StoryModel.getFormattedName({
          name
        })}</b>.`
      });
      _openNewTemplateDialog();
      $('#template-name').val(name);
    });
  } else {
    Validator.focusOnFirstError('#create-template');
  }
  return false;
};
exports.updateTemplate = () => {
  const currentState = exports.getState();
  const templateId = currentState.template_id;
  const newName = $('#template-name').val();
  const newTemplate = {
    story_contents: currentState,
    name: newName
  };
  if (Validator.isValid('#update-template')) {
    $('#update-template .action.green').replaceWith('<div class="saving"><span class="fa fa-star fa-spin"></span> Saving...</div>');
    StoryTemplateModel.updateTemplate(templateId, newTemplate).then(res => {
      Dialog.close();
      MessageController.success(`Template <b>${StoryModel.getFormattedName(newTemplate)}</b> updated.`, {
        actions: `
            <button
              class="action mini elevated"
              data-controller="AddNewStory"
              data-on-click="createStoryWithNewTemplate"
              data-model="StoryTemplate"
              data-id="${res.id}"
            >
              Create Story with Template
            </button>`
      });
      TaskModel.deleteTemporaryTasks();
      exports.resetState();
      exports.close();
    }).catch(res => {
      MessageController.error(res.error, {
        secondary: `Unable to update template <b>${StoryModel.getFormattedName(newTemplate)}</b>`
      });
      _openUpdateTemplateDialog(exports.getState());
    });
  } else {
    Validator.focusOnFirstError('#update-template');
  }
  return false;
};
exports.reRenderCreateButton = (context, useCreateAnother) => {
  $('.add-new-action').html(AddNewStoryActionsTemplate.render({
    areCustomFieldsEnabled: CustomFieldModel.areCustomFieldsEnabled(),
    context,
    useCreateAnother
  }));
};
const storyTypeDropdownSelector = '#add-new-story-type-dropdown';
exports.openStoryTypeDropdown = function () {
  const container = $(this);
  const state = exports.getState();
  const dropdown = DropdownController.open({
    items: Utils.getStoryTypes(state),
    targetSelector: storyTypeDropdownSelector,
    title: 'Select Story Type',
    onApply: exports.updateStoryType,
    onClose: () => {
      Utils.deferFocus(container);
    },
    target: this,
    topOffset: 0,
    width: 240
  });
  if (dropdown) {
    dropdown.dropdownElement.find('.active').focus();
  }
  return false;
};
exports.updateStoryType = value => {
  const container = $(storyTypeDropdownSelector);
  const state = exports.getState();
  if (value === state.story_type) {
    return false;
  }

  // Clear severity field if the story is not a bug
  if (value !== 'bug') {
    const severityFieldId = getIdForCanonicalName(OpinionatedFieldType.SEVERITY);
    exports.updateState({
      custom_fields: state.custom_fields.filter(_ref5 => {
        let {
          field_id
        } = _ref5;
        return field_id !== severityFieldId;
      })
    });
  }
  exports.updateState({
    story_type: value
  });
  container.html(DropdownStoryTypeTemplate.render(exports.getState()));
  exports.renderCustomFields(exports.getState());
  Utils.deferFocus(container);
};
exports.toggleOwnerState = profile => {
  const state = exports.getState();
  state.owners = state.owners || [];
  if (!profile) {
    state.owners = [];
  } else if (!_.some(state.owners, p => p.id === profile.id)) {
    state.owners.push(profile);
    exports.addUserAsFollower(state, profile);
  } else {
    _.remove(state.owners, {
      id: profile.id
    });
  }
  state.owners = _.sortBy(state.owners, 'lowercase_name');
  state.owner_ids = _.map(state.owners, 'id');
  exports.updateState({
    owner_ids: state.owner_ids
  });
};
exports.openStoryFollowerDropdown = function () {
  const state = exports.getState();
  return AutocompleteController.open({
    items: ProfileModel.getItemsForFollowerAutocomplete(state),
    multiSelect: true,
    footer: (InviteUsersController.getInviteUsersFooter() || '') + '<div class="autocomplete-footer-tip">' + ' Followers are notified of story activity. Story requesters and owners are automatically' + ' added as followers.</div>',
    onApply: exports.toggleFollowerState,
    noActive: true,
    title: 'Select Story Followers',
    showInput: true,
    target: this,
    topOffset: 0,
    width: 240,
    onResultsUpdate: InviteUsersController.getAutocompleteWithInviteUpdateHandler
  });
};
exports.toggleFollowerState = profile => {
  // Guard against non-matching input
  if (profile && !ProfileModel.isValid(profile)) {
    return false;
  }
  const container = $('.story-followers', PARENT_SELECTOR);
  const state = exports.getState();
  state.followers = state.followers || [];
  if (_.some(state.followers, {
    id: profile.id
  })) {
    _.remove(state.followers, {
      id: profile.id
    });
  } else {
    state.followers.push(profile);
  }
  state.follower_ids = _.map(state.followers, 'id');
  exports.updateState({
    follower_ids: state.follower_ids
  });
  exports.updateFollowerToggleState();
  Utils.deferFocus(container);
};
exports.addUserAsFollower = (state, profile) => {
  state.follower_ids = state.follower_ids || [];
  if (!_.includes(state.follower_ids, profile.id)) {
    state.follower_ids.push(profile.id);
    exports.updateState({
      follower_ids: state.follower_ids
    });
    $('.add-new-story .story-followers .value').html(StoryModel.describeFollowers(state));
    exports.updateFollowerToggleState();
  }
};
exports.removeUserAsFollower = (state, profile) => {
  state.follower_ids = state.follower_ids || [];
  if (_.includes(state.follower_ids, profile.id)) {
    _.pull(state.follower_ids, profile.id);
    exports.updateState({
      follower_ids: state.follower_ids
    });
    $('.add-new-story .story-followers .value').html(StoryModel.describeFollowers(state));
    exports.updateFollowerToggleState();
  }
};
exports.openStoryEstimateDropdown = function () {
  const container = $(this);
  const state = exports.getState();
  const items = [{
    className: state.estimate === null ? 'active' : '',
    name: '<em>Unestimated</em>',
    value: null
  }];
  EstimateScaleModel.getPointScale().forEach(point => {
    point = BaseUtils.toNumber(point);
    items[items.length] = {
      className: state.estimate === point ? 'active' : '',
      name: Format.pluralize(point, 'point', 'points'),
      value: point
    };
  });
  const dropdown = DropdownController.open({
    items,
    onApply: exports.updateStoryEstimate,
    onClose: () => {
      Utils.deferFocus(container);
    },
    target: this,
    title: 'Estimate Story',
    topOffset: 0,
    width: 240
  });
  if (dropdown) {
    dropdown.dropdownElement.find('.active').focus();
  }
  return false;
};
exports.updateStoryEstimate = value => {
  const container = $('div.attribute.editable-attribute.story-points.condensed');
  container.html(DropdownStoryEstimateTemplate.render({
    estimate: value
  }));
  exports.updateState({
    estimate: value
  });
};
exports.openStoryEpicUpdater = function () {
  const state = exports.getState();
  const container = $(this);
  const items = EpicModel.getItemsForAutocomplete({
    selectedGroupId: state.group_id
  });
  let inputValue = '';

  // Guard against deleted epic on dropdown open.
  if (state.epic_id) {
    inputValue = (EpicModel.getById(state.epic_id) || {}).name;
    if (!inputValue) {
      state.epic_id = null;
    }
  }
  return AutocompleteController.open({
    items,
    preserveCaptions: true,
    onApply: id => {
      exports.updateStoryEpic(id);
      Utils.deferFocus(container);
    },
    inputValue,
    value: state.epic_id,
    showInput: true,
    title: 'Select or Create an Epic',
    onResultsUpdate: _ref6 => {
      let {
        hasResults,
        value,
        setNoResults
      } = _ref6;
      if (!hasResults) {
        setNoResults('Enter to create new epic <strong>' + Format.sanitize(value) + '</strong>');
      }
    },
    target: this,
    topOffset: 0,
    width: 240
  });
};
exports.updateStoryEpic = epicId => {
  const state = exports.getState();
  const container = $('div.attribute.editable-attribute.story-epic');
  if (epicId) {
    const epic = EpicModel.getById(epicId);
    if (!epic) {
      EpicModel.saveNew({
        name: epicId
      }, (err, epic) => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to create a new epic.'
          });
        }
        if (epic) {
          container.html(DropdownStoryEpicTemplate.render({
            epic_id: epic.id
          }));
          exports.updateState({
            epic_id: epic.id
          });
          Utils.deferFocus(container);
        }
      });
      return false;
    } else if (epicId === state.epic_id) {
      container.html(DropdownStoryEpicTemplate.render());
      exports.updateState({
        epic_id: null
      });
    } else {
      container.html(DropdownStoryEpicTemplate.render({
        epic_id: epic.id
      }));
      exports.updateState({
        epic_id: epic.id
      });
    }
  } else {
    container.html(DropdownStoryEpicTemplate.render());
    exports.updateState({
      epic_id: null
    });
  }
};
exports.renderEpicIcon = () => View.renderComponentDelayed({
  componentKey: `epic-icon`,
  component: _jsx(SizedIcon, {
    icon: "Epic",
    size: "20"
  })
}).html;
exports.renderExpandIcon = () => View.renderComponentDelayed({
  componentKey: `expand-icon`,
  component: _jsx(SizedIcon, {
    icon: "Expand",
    size: "25",
    fill: "currentColor"
  })
}).html;
exports.renderCollapseIcon = () => View.renderComponentDelayed({
  componentKey: `collapse-icon`,
  component: _jsx(SizedIcon, {
    icon: "Collapse",
    size: "25",
    fill: "currentColor"
  })
}).html;
exports.renderLinkIcon = function () {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return View.renderComponentDelayed({
    componentKey: `link-icon-${id}`,
    component: _jsx(SizedIcon, {
      icon: "Link",
      size: "20",
      fill: "currentColor"
    })
  }).html;
};
exports.renderIterationIcon = _ref7 => {
  let {
    status
  } = _ref7;
  let fill;
  if (status === IterationModel.STATUS_VALUES.DONE) {
    fill = 'var(--iconGreenColor)';
  } else if (status === IterationModel.STATUS_VALUES.STARTED) {
    fill = 'var(--iconBlueColor)';
  }
  return View.renderComponentDelayed({
    componentKey: `iteration-icon`,
    component: _jsx(SizedIcon, {
      icon: "Iteration",
      size: "20",
      customFill: fill
    })
  }).html;
};
exports.renderFeatureIcon = id => View.renderComponentDelayed({
  componentKey: `feature-icon-${id}`,
  component: _jsx(SizedIcon, {
    icon: "Feature",
    size: "20",
    customFill: "var(--iconYellowColor)"
  })
}).html;
exports.renderBugIcon = id => View.renderComponentDelayed({
  componentKey: `bug-icon-${id}`,
  component: _jsx(SizedIcon, {
    icon: "Bug",
    size: "20",
    customFill: "var(--iconRedColor)"
  })
}).html;
exports.renderChoreIcon = id => View.renderComponentDelayed({
  componentKey: `chore-icon-${id}`,
  component: _jsx(SizedIcon, {
    icon: "Chore",
    size: "20"
  })
}).html;
const renderGroupDropdown = () => {
  View.renderComponent({
    mountNode: document.querySelector('#addNewStoryGroupDropdown'),
    componentKey: 'addNewStoryGroupDropdown',
    component: _jsx(StoryGroupDropdown, {
      shouldFilterEmptyWorkflows: true,
      story: exports.getState(),
      selectedGroup: GroupModel.getById(exports.getState().group_id),
      onChange: group => {
        exports.updateState({
          group_id: group ? group.id : null
        });
      }
    })
  });
};
exports.renderCustomFieldsHeader = () => {
  return View.renderComponentDelayed({
    componentKey: 'custom-fields-header-container',
    component: _jsx(CustomFieldHeader, {
      handleEditLinkClick: () => {
        exports.close();
      }
    })
  }).html;
};
exports.renderCustomFields = story => {
  return View.renderComponentDelayed({
    componentKey: 'custom-fields-container',
    component: _jsx(CustomFieldSelectList, {
      storyType: story.story_type,
      storyCustomFields: story.custom_fields,
      onChange: (fieldId, valueId) => {
        exports.updateCustomField(fieldId, valueId);
      }
    })
  }).html;
};
exports.updateCustomField = (fieldId, valueId) => {
  const customFields = exports.getState().custom_fields ?? [];
  const customFieldsMap = new Map(customFields.map(_ref8 => {
    let {
      field_id,
      value_id
    } = _ref8;
    return [field_id, value_id];
  }));
  if (valueId === undefined) {
    customFieldsMap.delete(fieldId);
  } else {
    customFieldsMap.set(fieldId, valueId);
  }
  const updatedCustomFields = [];
  for (const [field_id, value_id] of customFieldsMap) {
    updatedCustomFields.push({
      field_id,
      value_id
    });
  }
  exports.updateState({
    custom_fields: updatedCustomFields
  });
};
exports.renderWorkflowDropdown = () => {
  const {
    group_id,
    workflow_id
  } = exports.getState();
  const group = GroupModel.getById(group_id);
  return View.renderComponentDelayed({
    componentKey: 'addNewWorkflowDropdown',
    cssClass: 'addNewWorkflowDropdown',
    component: _jsx(WorkflowField, {
      label: 'Select Workflow',
      footerType: 'message',
      permittedWorkflowIds: group?.workflow_ids,
      input: {
        value: [workflow_id],
        onChange: function () {
          let [value = null] = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          // When the value is captured from the UI it always comes as a string, but in our data model the workflowId is a number
          exports._updateWorkflowState(Number(value));
        }
      }
    })
  }).html;
};
exports.openStoryIterationUpdater = function () {
  const context = $(this);
  const getState = exports.getState;
  const setValue = value => new Promise(resolve => {
    exports.updateState({
      iteration_id: value
    });
    return resolve(exports.getState());
  });
  return AutocompleteController.open({
    ...IterationModule.getIterationPickerConfig({
      getState,
      setValue,
      context
    })
  });
};
exports.openStoryStateDropdown = function () {
  const state = exports.getState();
  // Fallback to first column if column in state has been deleted.

  let column;
  let items;
  column = ColumnModel.getById(state.workflow_state_id) || WorkflowModel.getDefaultStateForWorkflow(state.workflow_id);
  items = getItemsForAutocomplete({
    workflow: WorkflowModel.getById(state.workflow_id)
  });
  return AutocompleteController.open({
    items,
    onApply: exports.updateStoryWorkflowState,
    inputValue: column.name,
    value: column.id,
    title: 'Select State',
    showInput: true,
    target: this,
    topOffset: 0,
    width: 240
  });
};
exports.updateStoryWorkflowState = id => {
  // Fallback to first column if selected column has been deleted.
  const selectedColumn = ColumnModel.getById(id) || ColumnModel.firstFromActiveWorkflow();
  exports._renderWorkflowStateControl(selectedColumn);
  exports.updateState({
    workflow_state_id: id
  });
};
exports._renderWorkflowStateControl = selectedColumn => {
  const context = $('#add-new-story-workflow-state');
  context.find('.left-icon').children().replaceWith(() => ColumnModel.renderStateIconFromStateType(selectedColumn.type));
  context.find('.value').text(selectedColumn.name);
};
exports.openDeadlineDatepicker = function () {
  const state = exports.getState();
  const context = $(this);
  StoryController.openDeadlineDatepicker({
    context,
    story: state,
    callback: deadline => {
      const formatted = deadline ? moment(deadline).format(Constants.SHORT_DATE_FORMAT) : null;
      context.find('.value').html(formatted || '<em>No date</em>');
      const toggle = context.closest('.attribute').prev('.attribute-toggle');
      if (formatted) {
        toggle.show();
      } else {
        toggle.hide();
      }
      exports.updateState({
        deadline
      });
    }
  });
  return false;
};
exports.clearDeadline = function () {
  const toggle = $(this).closest('.attribute-toggle');
  toggle.hide();
  const context = toggle.next('.attribute');
  exports.updateState({
    deadline: null
  });
  context.find('.value').html('<em>No date</em>');
  return false;
};
exports.addMeAsFollower = () => {
  const state = exports.getState();
  const profileDetails = ProfileModel.getCurrentUserProfileDetails();
  exports.addUserAsFollower(state, profileDetails);
  return false;
};
exports.removeMeAsFollower = () => {
  const state = exports.getState();
  const profileDetails = ProfileModel.getCurrentUserProfileDetails();
  exports.removeUserAsFollower(state, profileDetails);
  return false;
};
exports.updateFollowerToggleState = () => {
  const state = exports.getState();
  const context = $('.add-new-dialog .story-followers').prev('.attribute-toggle').find('a');
  if (StoryModel.currentUserIsFollower(state)) {
    exports.markToggleAsFollower(context);
  } else {
    exports.markToggleAsNotFollower(context);
  }
};
exports.markToggleAsFollower = context => {
  context.attr('data-on-click', 'removeMeAsFollower');
  context.attr('data-tooltip', 'Remove Yourself as Follower');
  context.find('.fa').removeClass('fa-plus').addClass('fa-times');
};
exports.markToggleAsNotFollower = context => {
  context.attr('data-on-click', 'addMeAsFollower');
  context.attr('data-tooltip', 'Follow this Story');
  context.find('.fa').removeClass('fa-times').addClass('fa-plus');
};
exports.attachFilesToStory = files => {
  const state = exports.getState();
  state.file_ids = _.uniq(state.file_ids.concat(_.map(files, 'id')));
  exports.updateState(state);
  exports.renderNewAttachment();
};
exports.attachLinkedFileToStory = file => {
  const state = exports.getState();
  state.linked_file_ids.push(file.id);
  exports.updateState(state);
  exports.renderNewAttachment();
};
exports.renderNewAttachment = () => {
  const state = exports.getState();
  const html = _.map(state.activity, file => {
    return AttachmentTemplate.render(file);
  }).join('');
  $(PARENT_SELECTOR).find('.comments').html(html);
};
exports.renderStoryLinks = () => {
  StoryLinkController.updateStoryLinkComponent(exports.getState(), $(PARENT_SELECTOR), {
    update: _ref9 => {
      let {
        existingStoryLink,
        update
      } = _ref9;
      return exports.updateStoryLinks({
        existingStoryLink,
        update
      });
    },
    remove: _ref10 => {
      let {
        existingStoryLink
      } = _ref10;
      return exports.updateStoryLinks({
        existingStoryLink
      });
    }
  });
};
exports.updateStoryLinks = _ref11 => {
  let {
    existingStoryLink,
    update
  } = _ref11;
  let {
    story_links
  } = exports.getState();
  story_links = StoryLinkModel.updateStoryLinks({
    storyLinks: story_links,
    existingStoryLink,
    update
  });
  exports.updateState({
    story_links
  });
  exports.renderStoryLinks();
};
exports.openCreateLinkHelper = function () {
  const {
    template_id
  } = exports.getState();
  DropdownController.open({
    title: `Share a link to this ${template_id ? 'template' : 'dialog'}`,
    footer: CreateStoryLinkTemplate.render({
      template_id
    }),
    target: this,
    leftOffset: -268,
    width: 300
  });
  return false;
};
exports.addExternalLink = function () {
  AddExternalLinkController.open.call(this, externalLink => {
    const externalLinks = exports.getState().external_links;
    const uniqueExternalLinks = new Set(externalLinks);
    uniqueExternalLinks.add(externalLink.url);
    exports.updateState({
      external_links: [...uniqueExternalLinks]
    });
  });
};
exports.addTask = () => {
  const target = document.querySelector('.empty-task');
  TaskController.openNewTaskForm.call(target);
};
exports.addFile = e => {
  StoryController.openFileUploadDropdown.call(e.target);
};
exports.removeExternalLink = function () {
  const url = Utils.data(this, 'value');
  const externalLinks = exports.getState().external_links || [];
  const uniqueExternalLinks = new Set(externalLinks);
  uniqueExternalLinks.delete(url);
  exports.updateState({
    external_links: [...uniqueExternalLinks]
  });
  return false;
};
const updateRequesterField = (values, id) => new Promise((resolve, reject) => {
  const profile = ProfileModel.getAllDetailsById(id);
  if (!profile) {
    reject();
  }
  const state = exports.getState();
  exports.addUserAsFollower(state, profile);
  exports.updateState({
    requested_by_id: profile.id
  });
  resolve();
});
const updateOwnerField = (values, id) => {
  const profile = ProfileModel.getAllDetailsById(id);
  exports.toggleOwnerState(profile);
};
const getInvitePromise = (actionContext, invitedUser) => new Promise((resolve, reject) => {
  const inviteRequestFn = async () => {
    try {
      const resp = await createInvites([invitedUser], {
        actionContext
      });
      if (Array.isArray(resp)) {
        resolve(resp[0]);
      } else {
        reject(resp);
      }
    } catch (e) {
      if (Array.isArray(e)) {
        reject(e[0]);
      } else {
        reject(e);
      }
    }
  };
  inviteRequestFn();
});
exports.renderRequesterOwnerFields = () => {
  const {
    requested_by_id,
    owner_ids
  } = exports.getState();
  View.renderWithComponents({
    template: AddOwnerRequesterFieldsTemplate,
    templateMountNode: document.querySelector('#addStoryOwnerRequester'),
    components: {
      AddStoryRequesterField: {
        component: SelectUsersDropdown,
        getProps(_ref12) {
          let {
            unmountComponent
          } = _ref12;
          return {
            type: 'Requester',
            title: 'Select Story Requester',
            initialSelectedUserIds: [requested_by_id || ProfileModel.getCurrentUserProfileDetails().id],
            users: ProfileModel.getAllAssignableProfiles(),
            currentUser: ProfileModel.getCurrentUserProfileDetails(),
            onSelect: updateRequesterField,
            isRequired: true,
            groupIds: exports.getState()?.group_id ? [exports.getState()?.group_id] : [],
            showInvite: true,
            onInvite: async invitedUser => {
              try {
                const inviteRequest = await getInvitePromise(['story_card', 'requester_field'], invitedUser);
                fetchAll(() => {
                  MessageController.success(`${invitedUser.email} has been invited to the Workspace.`);
                  unmountComponent();
                  exports.updateState({
                    requested_by_id: inviteRequest.permission_id
                  });
                  exports.renderRequesterOwnerFields();
                });
                return inviteRequest;
              } catch (e) {
                return e;
              }
            }
          };
        }
      },
      AddStoryOwnerField: {
        component: SelectUsersDropdown,
        getProps(_ref13) {
          let {
            unmountComponent
          } = _ref13;
          return {
            type: 'Owner',
            title: 'Select Story Owner',
            initialSelectedUserIds: owner_ids,
            users: ProfileModel.getAllAssignableProfiles(),
            currentUser: ProfileModel.getCurrentUserProfileDetails(),
            onSelect: updateOwnerField,
            onMouseUp: () => {
              exports.renderRequesterOwnerFields();
            },
            SelectedUserIndicator: _ref14 => {
              let {
                selectedUsers,
                index
              } = _ref14;
              if (index > 0) {
                return null;
              }
              return _jsx(GroupMismatchIndicatorForOwners, {
                story: exports.getState(),
                selectedMembers: selectedUsers,
                memberIndex: index
              });
            },
            groupIds: exports.getState()?.group_id ? [exports.getState()?.group_id] : [],
            showInvite: true,
            onInvite: async invitedUser => {
              try {
                const inviteRequest = await getInvitePromise(['story_card', 'owner_field'], invitedUser);
                fetchAll(() => {
                  MessageController.success(`${invitedUser.email} has been invited to the Workspace.`);
                  unmountComponent();
                  const newOwners = exports.getState().owner_ids.concat([inviteRequest.permission_id]);
                  exports.updateState({
                    owner_ids: newOwners
                  });
                  exports.renderRequesterOwnerFields();
                });
                return inviteRequest;
              } catch (e) {
                return e;
              }
            }
          };
        }
      }
    }
  });
};
exports.renderJiraSyncMessage = () => {
  const {
    workflow_id
  } = exports.getState();
  return View.renderComponentDelayed({
    componentKey: 'jira-sync-create-story-message',
    cssClass: 'jira-sync-message',
    component: _jsx(JiraSyncCreateStoryMessage, {
      workflowId: workflow_id
    })
  }).html;
};
exports.getCreateAnotherToggleState = context => ApplicationState.get(CREATE_ANOTHER_TOGGLE_KEY_MAPPING[context]) ?? false;
exports.setCreateAnotherToggleState = (context, newVal) => ApplicationState.set(CREATE_ANOTHER_TOGGLE_KEY_MAPPING[context], newVal);
exports.renderCreateAnotherCheckbox = (context, useCreateAnother) => {
  return View.renderComponentDelayed({
    componentKey: 'create-another-story-checkbox',
    component: Checkbox,
    cssClass: 'create-another-story',
    props: {
      label: 'Create Another',
      id: 'create-another',
      isSelected: exports.getCreateAnotherToggleState(context),
      showLabel: true,
      onChange: () => {
        exports.setCreateAnotherToggleState(context, !exports.getCreateAnotherToggleState(context));
        exports.reRenderCreateButton(context, useCreateAnother);
      }
    }
  }).html;
};
exports.renderKeyboardShortcuts = () => {
  return View.renderComponentDelayed({
    componentKey: 'keyboard-shortcuts',
    component: NewStoryKeyboardShortcuts,
    props: {
      toggleCurrentUserAsOwner: () => exports.toggleOwnerState(ProfileModel.getCurrentUserProfileDetails()),
      openIterationDropdown: () => exports.openStoryIterationUpdater.call($('#story-dialog-iteration-dropdown')),
      openStateDropdown: () => exports.openStoryStateDropdown.call($('#add-new-story-workflow-state')),
      openEstimateDropdown: () => exports.openStoryEstimateDropdown.call($('[data-on-click="openStoryEstimateDropdown"]'))
    }
  }).html;
};
export { exports as default };