import { QueryWorkspacePermissionSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQueryWithCurrentPermissionId } from 'components/gql/filters/hooks/useFilterOptionsQueryWithCurrentPermissionId';
import { getSection } from './utils/sections';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_WORKSPACE_PERMISSION_SELECT = QueryWorkspacePermissionSelectDocument;
export const PermissionsFilter = _ref => {
  let {
    value,
    onChange,
    entityType,
    filterHeading,
    filterType
  } = _ref;
  const fetchPermissions = useFilterOptionsQueryWithCurrentPermissionId({
    query: QUERY_WORKSPACE_PERMISSION_SELECT,
    selectedIds: value
  });
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchPermissions,
    getSection,
    selectedValues: value,
    filterType
  });
  return _jsx(GroupedOptionsFilter, {
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    selectedValues: value,
    onChange: onChange,
    filterType: filterType,
    entityType: entityType,
    icon: "User",
    filterHeading: filterHeading
  });
};
PermissionsFilter.displayName = "PermissionsFilter";