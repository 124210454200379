import moment from 'moment';
import { formatNumber } from '@clubhouse/shared/utils/numbers';
export const getFormattedKeyResultValue = _ref => {
  let {
    value,
    type,
    compact = true
  } = _ref;
  if (type === 'boolean' && 'booleanValue' in value) return value.booleanValue ? 'Complete' : 'Incomplete';
  if (type === 'percent' && 'numericValueString' in value) return `${value.numericValueString}%`;
  if (type === 'numeric' && 'numericValueString' in value) return formatNumber(Number.parseFloat(value.numericValueString), {
    compact
  });
  throw new Error(`Invalid value and type combination - type: ${type}, booleanValue: ${JSON.stringify(value)}`);
};
function formatDate(now) {
  return `MMM Do ${this.year() !== now.year() ? ' yyyy' : ''}`;
}
const DATE_FORMATS = {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: formatDate,
  sameElse: formatDate
};
export const getLastUpdateInfo = _ref2 => {
  let {
    current,
    target
  } = _ref2;
  const latest = moment(current.createdAt).isSameOrAfter(moment(target.createdAt)) ? current : target;
  return {
    lastUpdated: moment(latest.createdAt),
    lastUpdatedBy: latest.creator?.displayName ?? 'Somebody'
  };
};
export const getLastUpdated = _ref3 => {
  let {
    current,
    target
  } = _ref3;
  return getLastUpdateInfo({
    current,
    target
  }).lastUpdated.calendar(DATE_FORMATS);
};