import { useGroups } from 'data/entity/group';
import { useCallback, useMemo } from 'react';
import { renderAddNewGroup } from 'utils/addNew';
export const useManageGroupsState = function () {
  let {
    onCreate,
    shouldHideArchived
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    groups
  } = useGroups();
  const openEditor = useCallback(() => {
    renderAddNewGroup({
      onCreate
    });
  }, [onCreate]);
  const filteredGroups = useMemo(() => shouldHideArchived ? groups.filter(group => !group.archived) : groups, [groups, shouldHideArchived]);
  return {
    groups: filteredGroups,
    openEditor
  };
};