import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import ProfileModel from './profile';
import Url from '../modules/url';
import SlackIntegrationController from '../../../integrations/js/controllers/slackIntegration';
import _ from 'lodash';
const exports = {};

/*

Example Integration entity:

{
  id: 1992,
  created_at: null,
  updated_at: null,
  type: "slack",
  webhook_url: "https:\/\/useshortcut.slack.com\/services\/hooks\/incoming-webhook?token=juXdfxJEoSMngOFb9mksMpDa",
  disabled: false
}

Example Slack Integration entity:

{
  channel: "#clubhouse-api",
  project_id: 274,
  url: "https://hooks.slack.com/services/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"
}

*/

Collection.create('Integration', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.type;
};
exports.normalize = integration => {
  if (integration.creator_id) {
    integration.creator = ProfileModel.getAllDetailsById(integration.creator_id);
  }
  return integration;
};
exports.fetchAll = callback => {
  Backend.get('/api/private/integrations', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.fetchSlackIntegrationProjects = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const slack = exports.getSlackIntegration();
  if (!slack) {
    return callback();
  }
  Backend.get('/api/private/integrations/' + slack.id + '/projects', {
    onComplete: res => {
      if (_.isArray(res)) {
        slack.projectIntegrations = res;
      }
      callback();
    }
  });
};
exports.fetchSlackIntegrationTeams = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const slack = exports.getSlackIntegration();
  if (!slack) {
    return callback();
  }
  Backend.get('/api/private/integrations/' + slack.id + '/slack/groups', {
    onComplete: res => {
      if (_.isArray(res)) {
        slack.teamIntegrations = res;
      }
      callback();
    }
  });
};
exports.fetchSlackIntegrationFields = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const slack = exports.getSlackIntegration();
  if (!slack) {
    return callback();
  }
  Backend.get('/api/private/integrations/' + slack.id + '/slack/fields', {
    onComplete: res => {
      if (_.isArray(res)) {
        slack.fieldIntegrations = res;
      }
      callback();
    }
  });
};
exports.createNew = (integration, callback) => {
  Backend.post('/api/private/integrations', {
    data: integration,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.createIntegration = (_ref, callback) => {
  let {
    integration,
    code,
    id,
    redirect_uri,
    type
  } = _ref;
  callback = _.isFunction(callback) ? callback : _.noop;
  let url;
  if (type === SlackIntegrationController.INTEGRATION_TYPE.TEAM) {
    url = `/api/private/integrations/${integration.id}/slack/groups/${id}`;
  } else if (type === SlackIntegrationController.INTEGRATION_TYPE.PROJECT) {
    url = `/api/private/integrations/${integration.id}/projects/${id}/v2`;
  } else if (type === SlackIntegrationController.INTEGRATION_TYPE.FIELD) {
    const [field_id, value_id] = id.split(':');
    url = `/api/private/integrations/${integration.id}/slack/fields/${field_id}/${value_id}`;
  } else {
    callback();
    return;
  }
  Backend.put(url, {
    data: {
      code,
      redirect_uri
    },
    onComplete: (res, xhr) => {
      if (xhr.status === 200 && _.isArray(res)) {
        let integratedItem;
        if (type === SlackIntegrationController.INTEGRATION_TYPE.TEAM) {
          integration.teamIntegrations = res;
          integratedItem = res.find(team => team.group_id === id);
        } else if (type === SlackIntegrationController.INTEGRATION_TYPE.PROJECT) {
          integration.projectIntegrations = res;
          integratedItem = res.find(project => project.project_id === Number.parseInt(id));
        } else if (type === SlackIntegrationController.INTEGRATION_TYPE.FIELD) {
          const [, value_id] = id.split(':');
          integration.fieldIntegrations = res;
          integratedItem = res.find(field => field.field_value_id === value_id);
        }
        callback(null, integratedItem);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.authorizeSlackIntegration = _ref2 => {
  let {
    integration,
    code
  } = _ref2;
  return new Promise((resolve, reject) => {
    Backend.put(`/api/private/integrations/${integration.id}/slack/authorized`, {
      data: {
        code
      },
      onComplete: (res, xhr) => {
        if (xhr.status !== 200) {
          return exports.defaultErrorHandler(res, reject);
        }
        Object.assign(integration, res);
        return resolve(integration);
      }
    });
  });
};
exports.authorizeSlackIntegrationV2 = _ref3 => {
  let {
    integration,
    code,
    redirect_uri
  } = _ref3;
  return new Promise((resolve, reject) => {
    Backend.put(`/api/private/integrations/${integration.id}/slack/v2/authorized`, {
      data: {
        code,
        redirect_uri
      },
      onComplete: (res, xhr) => {
        if (xhr.status !== 200) {
          return exports.defaultErrorHandler(res, reject);
        }
        Object.assign(integration, res);
        return resolve(integration);
      }
    });
  });
};
exports.authorizeGsheetsIntegration = _ref4 => {
  let {
    integration,
    code
  } = _ref4;
  return new Promise((resolve, reject) => {
    Backend.put(`/api/private/integrations/${integration.id}/gapi/authorized`, {
      data: {
        code
      },
      onComplete: (res, xhr) => {
        if (xhr.status !== 200) {
          return exports.defaultErrorHandler(res, reject);
        }
        Object.assign(integration, res);
        return resolve(integration);
      }
    });
  });
};
exports.updateIntegration = (integration, data, callback) => {
  Backend.put('/api/private/integrations/' + integration.id, {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.disableIntegration = (integration, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/integrations/' + integration.id + '/disable', {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        integration.disabled = true;
        exports.update(integration);
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteTeamIntegration = (teamIntegration, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const slack = exports.getSlackIntegration();
  if (!slack) {
    return callback();
  }
  Backend.delete('/api/private/integrations/' + slack.id + '/slack/groups/' + teamIntegration.group_id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        _.remove(slack.teamIntegrations, {
          group_id: teamIntegration.group_id
        });
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteProjectIntegration = (projectIntegration, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const slack = exports.getSlackIntegration();
  if (!slack) {
    return callback();
  }
  Backend.delete('/api/private/integrations/' + slack.id + '/projects/' + projectIntegration.project_id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        _.remove(slack.projectIntegrations, {
          project_id: projectIntegration.project_id
        });
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteFieldIntegration = (fieldIntegration, callback) => {
  const {
    field_id,
    field_value_id
  } = fieldIntegration;
  callback = _.isFunction(callback) ? callback : _.noop;
  const slack = exports.getSlackIntegration();
  if (!slack) {
    return callback();
  }
  Backend.delete('/api/private/integrations/' + slack.id + '/slack/fields/' + field_id + '/' + field_value_id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        _.remove(slack.fieldIntegrations, {
          field_value_id: field_value_id
        });
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteIntegration = (integrationId, callback) => {
  Backend.delete('/api/private/integrations/' + integrationId, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler({
        id: integrationId
      }, res, xhr, callback);
    }
  });
};
exports.enableIntegration = (integration, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/integrations/' + integration.id + '/enable', {
    onComplete: (res, xhr) => {
      if (xhr.status === 204) {
        integration.disabled = false;
        exports.update(integration);
        callback(null, res);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.genericIntegrationsEnabled = () => {
  return _.some(exports.getGenericIntegrations(), {
    disabled: false
  });
};
exports.getGenericIntegrations = () => {
  return exports.filter({
    type: 'generic'
  });
};
exports.getSlackIntegration = () => {
  return exports.get({
    type: 'slack'
  });
};
exports.getGsheetsIntegration = () => {
  return exports.get({
    type: 'gsheets'
  });
};
exports.getNotionIntegration = () => {
  return exports.get({
    type: 'notion'
  });
};
exports.getSlackProjectIntegrationByID = id => {
  const slack = exports.getSlackIntegration();
  return _.find(slack.projectIntegrations, {
    project_id: id
  });
};
exports.getSlackRedirectUrl = () => {
  return Url.getCurrentOrigin() + Url.getCurrentPathname();
};
export { exports as default };