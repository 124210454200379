import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
const URL_SCHEME = 'https://';
const URL_SCHEME_PATTERN = /^(https?:\/\/)/i;
export const MIRO_LINK_PATTERN = /^(https?:\/\/)?(www\.)?miro\.com\/app\/(live-embed|board)\/[a-zA-Z0-9_-]+(?:\?.*)?/i;
const MIRO_BOARD_URL_PORTION_PATTERN = /board/i;
const MIRO_LIVE_EMBED_URL_PORTION_PATTERN = /live-embed/i;
export const SHOW_PREVIEW_STATE_KEY = 'Miro.ShowPreview';
export const DEFAULT_SHOW_PREVIEW_VALUE = true;
export const DEFAULT_BOARD_TITLE = 'Miro board';
export const isMiroLink = url => MIRO_LINK_PATTERN.test(url);
const swapURLSection = (url, target) => {
  const sourceURL = /^(https:\/\/)/i.test(url) ? url : /^(http:\/\/)/i.test(url) ? url.replace(/^(http:\/\/)/i, URL_SCHEME) : URL_SCHEME + url;
  const sourceURLObj = new URL(sourceURL);
  const searchParams = sourceURLObj.searchParams;
  switch (target) {
    case 'board':
      sourceURLObj.pathname = sourceURLObj.pathname.replace(MIRO_LIVE_EMBED_URL_PORTION_PATTERN, target);
      break;
    case 'live-embed':
      sourceURLObj.pathname = sourceURLObj.pathname.replace(MIRO_BOARD_URL_PORTION_PATTERN, target);
      searchParams.set('autoplay', true.toString());
      break;
    case 'oembed-api':
      sourceURLObj.hostname = sourceURLObj.hostname.replace(/^www\./i, '');
  }
  return sourceURLObj.toString();
};
export const formatMiroBoardURL = boardURL => {
  const formattedURL = swapURLSection(boardURL, 'live-embed');
  return formattedURL;
};
export const formatMiroEmbedURL = embedURL => {
  const formattedURL = swapURLSection(embedURL, 'board');
  return formattedURL;
};
export const formatBoardURLForAPI = boardURL => {
  const formattedURL = swapURLSection(boardURL, 'oembed-api');
  return formattedURL;
};
export const retrieveBoardIdFromURL = embedURL => {
  const url = new URL(embedURL);
  const urlPathnameSections = url.pathname.split('/');
  const formattedBoardId = urlPathnameSections[3].replace(/=/g, '');
  return formattedBoardId;
};
export const innerTextMatchesMiroLink = (url, innerText) => {
  let miroLinkInnerText = url;
  if (!URL_SCHEME_PATTERN.test(innerText)) {
    miroLinkInnerText = url.replace(URL_SCHEME_PATTERN, '');
  }
  return miroLinkInnerText === innerText;
};
export const getMiroEmbedMetaData = function (boardURL) {
  let requestOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return fetch(`https://miro.com/api/v1/oembed?url=${encodeURIComponent(formatBoardURLForAPI(boardURL))}`, {
    method: 'GET',
    headers: {
      accept: 'application/json'
    },
    ...requestOptions
  }).then(res => {
    if (!res.ok) {
      throw res;
    }
    return res.json();
  });
};