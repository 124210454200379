// This file fixes a circular dependency between controllers/viewSettings.js and models/space.js
import { CardDensityOptions } from '@clubhouse/shared/types';
import { trigger } from 'utils/event';
export const getDefaultViewSettings = () => ({
  cardColor: 'story-type',
  cardDensity: 'minimal',
  viewType: 'kanban',
  groupBy: 'none',
  collapseState: true
});
export const applyCardDensity = density => {
  const viewSettingsDensitySelector = `[data-density-controller="ViewSettings"]`;
  document.querySelector(viewSettingsDensitySelector)?.classList.remove(...CardDensityOptions);
  document.querySelector(viewSettingsDensitySelector)?.classList.add(density);
};
export const applyCollapseState = collapseState => {
  const isCollapsed = document.body.classList.toggle('collapse-empty-columns', collapseState);
  trigger('CollapseEmptyColumns.Change');
  return isCollapsed;
};