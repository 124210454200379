import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { ArchiveMessage } from 'components/shared/ArchiveMessage';
import { useType } from '../Context';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ArchivedMessageBar = _ref => {
  let {
    children,
    name,
    onDelete,
    onUnarchive,
    openDeletionConfirmationDialog
  } = _ref;
  const {
    closeDialog
  } = useConfirmationDialogState();
  const type = useType();
  return _jsx(ArchiveMessage, {
    typeName: type,
    ...(onDelete && openDeletionConfirmationDialog ? {
      onDelete: () => {
        openDeletionConfirmationDialog('delete', {
          onClick: async () => {
            closeDialog();
            try {
              await onDelete();
              addToast({
                kind: 'success',
                timeout: 5000,
                Content: () => _jsxs(ToastText, {
                  children: [type, ' ', _jsx("strong", {
                    children: _jsx(Emojify, {
                      children: name
                    })
                  }), ' ', "deleted."]
                })
              });
            } catch (error) {
              addToast({
                kind: 'alert',
                timeout: 5000,
                Content: () => _jsxs(ToastText, {
                  children: ["Unable to delete", ' ', _jsx("strong", {
                    children: _jsx(Emojify, {
                      children: name
                    })
                  }), "."]
                })
              });
            }
          }
        });
      }
    } : {}),
    onUnarchive: onUnarchive,
    children: children
  });
};
ArchivedMessageBar.displayName = "ArchivedMessageBar";