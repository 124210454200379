import uniqueId from 'lodash/uniqueId';
import { useCallback, useEffect, useMemo } from 'react';
import { closestAncestor, sanitize } from '@clubhouse/shared/utils';
import Lightbox from 'app/client/core/js/modules/lightbox';
export const isOpen = () => Lightbox.isOpen();

// This is a hacky solution to support the existing image lightbox library in React.
// As a future improvement, the lightbox should probably be replaced with a React library or a custom one.
export function useLightbox() {
  const parentId = useMemo(() => uniqueId('lightbox-'), []);
  useEffect(() => {
    Lightbox.loadLibraryIfMissing();
  }, []);
  const refCallback = useCallback(parent => {
    if (!parent) return;
    parent.querySelectorAll('img[src]').forEach((img, i) => {
      if (!closestAncestor(img, '.use-lightbox')) {
        const src = img.getAttribute('src');
        const name = src.split('/').pop();
        const imgParent = img.parentElement;
        const a = document.createElement('A');
        a.setAttribute('href', src);
        a.setAttribute('data-lightbox', `${parentId}:${i}`);
        a.setAttribute('target', '_blank');
        a.classList.add('use-lightbox');
        const dataTitleAnchor = document.createElement('a');
        dataTitleAnchor.setAttribute('href', src);
        const icon = document.createElement('span');
        icon.classList.add('fa', 'fa-cloud-download');
        dataTitleAnchor.append(icon, sanitize(name).replace(/%20/g, ' '));
        a.setAttribute('data-title', dataTitleAnchor.outerHTML);
        imgParent.replaceChild(a, img);
        a.appendChild(img);
      }
    });
  }, [parentId]);
  return refCallback;
}