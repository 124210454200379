import View from 'app/client/core/js/modules/view';
import { Dropbox } from 'pages/integrations/Dropbox';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
const exports = {};
exports.title = `Dropbox Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/dropbox';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Dropbox, 'integrations-dropbox'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };