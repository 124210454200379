import { GoToEpicActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/epics/links';
import { navigateTo } from 'utils/navigation';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const GoToEpicFragment = GoToEpicActionFragmentFragmentDoc;
export const GoToEpicAction = _ref => {
  let {
    epic
  } = _ref;
  return _jsxs(MoreActions.Item, {
    icon: "Zoom",
    onClick: () => navigateTo({
      url: getHref(epic.publicId)
    }),
    children: ["Go To ", Nouns.Epic.singular]
  });
};
GoToEpicAction.displayName = "GoToEpicAction";