import { TeamHeaderDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useEffect } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { useQuery } from 'gql';
import { useTeamContextMenu } from 'utils/contextMenus/useTeamContextMenu';
import { Header } from './Header';
import { HeaderText } from './HeaderText';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TeamHeaderQuery = TeamHeaderDocument;
export const TeamHeaderV2 = _ref => {
  let {
    id,
    isNavExpanded,
    isActive,
    onRemoveTeamNav,
    onAddTeamNav,
    isSelectedTeam = true,
    onRemoveInvalidTeam
  } = _ref;
  const {
    loading,
    error,
    data,
    previousData
  } = useQuery(TeamHeaderQuery, {
    variables: {
      id
    },
    fetchPolicy: 'cache-first'
  });
  const _node = (loading ? data || previousData : data)?.node ?? null;
  const node = _node?.__typename === 'Team' ? _node : null;
  const cmProps = useTeamContextMenu({
    id: node?.publicId || '',
    onRemoveTeamNav: isSelectedTeam ? onRemoveTeamNav : undefined,
    onAddTeamNav: isSelectedTeam ? undefined : onAddTeamNav
  });
  useEffect(() => {
    if (!loading && !error && (!node || node.archived)) onRemoveInvalidTeam(id);
  }, [error, id, loading, node, onRemoveInvalidTeam]);
  if (!node || node.archived) return null;
  const teamColor = getTeamColor(node);
  const iconUrl = node?.displayIcon?.thumbnailUrl;
  const content = isSelectedTeam ? _jsx(Emojify, {
    children: node?.name ?? ''
  }) : `${node?.name} is not in your team navigation. Right click to add.`;
  return _jsx(Tooltip, {
    content: content,
    delay: 300,
    children: _jsx(Header, {
      contextMenuProps: cmProps,
      isNavExpanded: isNavExpanded,
      isActive: isActive,
      icon: _jsx(TeamIcon, {
        iconUrl: iconUrl,
        size: 20,
        backgroundColor: teamColor,
        iconType: "new-team-icon",
        alt: `Team "${node?.name}" icon`
      }),
      children: _jsx(HeaderText, {
        isHidden: !isNavExpanded,
        children: _jsx(Emojify, {
          children: node?.name ?? ''
        })
      })
    })
  });
};
TeamHeaderV2.displayName = "TeamHeaderV2";