import BulkSelectionModel from '../../../core/js/models/bulkSelection';
import ColumnModel from '../../../core/js/models/column';
import * as Event from '../../../core/js/_frontloader/event';
import SpaceModel from '../../../core/js/models/space';
const exports = {};

// Extends core/js/models/column.

let storyLimits = {};
exports.STARTING_STORY_LIMIT = 5;
exports.STORY_FILL_COLUMN_INCREMENT = 5;
exports.STORY_LIMIT_INCREMENT = 10;
exports.initEventBindings = () => {
  ColumnModel.on('beforeAdd', column => {
    column.active = SpaceModel.isModelActiveInActiveSpace('Column', column);
  });
};
exports.setAllActive = () => {
  ColumnModel.each(x => {
    x.active = true;
  });
};
exports.showAll = () => {
  exports.setAllActive();
  ColumnModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
exports.hideAll = () => {
  ColumnModel.each(column => {
    column.active = false;
  });
  ColumnModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
exports.toElement = column => {
  return $('.column[data-id="' + column.id + '"]');
};
exports.onlyCurrentColumnActive = column => {
  const activeColumns = ColumnModel.filter({
    active: true
  });
  return activeColumns.length === 1 && activeColumns[0].id === column.id;
};
exports.getSelectedStories = column => {
  const allSelectedStories = BulkSelectionModel.getStoriesForEachSelection();
  if (allSelectedStories.length === 0) {
    return [];
  } else {
    return _.filter(allSelectedStories, story => {
      return story.workflow_state_id === column.id;
    });
  }
};
exports.getStoryLimitForColumn = column => {
  return storyLimits['column' + column.id] || exports.STARTING_STORY_LIMIT;
};
exports.setStoryLimitForColumn = (column, limit) => {
  storyLimits['column' + column.id] = limit;
};
exports.resetStoryLimitForColumn = column => {
  storyLimits['column' + column.id] = exports.STARTING_STORY_LIMIT;
};
exports.resetColumnStoryLimits = () => {
  storyLimits = {};
};
export { exports as default };