const exports = {};
const DELAY = 800;
exports.markAsSaving = elementOrSelector => {
  $(elementOrSelector).find('.toggle-button').toggleClass('active').end().find('.toggle-response').css({
    opacity: 1
  }).find('.fa').removeClass('fa-check').addClass('fa-spin fa-spinner');
};
exports.markAsSaved = (elementOrSelector, callback) => {
  $(elementOrSelector).find('.toggle-response').css({
    opacity: 1
  }).find('.fa').removeClass('fa-spin fa-spinner').addClass('fa-check');
  setTimeout(() => {
    $(elementOrSelector).find('.toggle-response').animate({
      opacity: 0
    }, 200, callback);
  }, DELAY);
};
exports.revert = elementOrSelector => {
  $(elementOrSelector).find('.toggle-button').toggleClass('active').end().find('.toggle-response').css({
    opacity: 0
  }).find('.fa').removeClass('fa-spin fa-spinner');
};
export { exports as default };