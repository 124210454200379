import { ReportCard } from 'components/shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const chartStyle = `
  .c3-bar {
    fill-opacity: 0.5;
  }

  .c3-bar._expanded_ {
    fill-opacity: 1;
  }

  .c3-line-Ideal-Stories-Remaining,
  .c3-line-Ideal-Points-Remaining,
  .c3-line-Projected-Points-Remaining,
  .c3-line-Projected-Stories-Remaining {
    stroke-dasharray: 4, 4;
  }

  .c3-line-Actual-Stories-Remaining,
  .c3-line-Actual-Points-Remaining {
    stroke-width: unset;
  }

  .c3-area-Actual-Stories-Remaining,
  .c3-area-Actual-Points-Remaining {
    opacity: 0.1 !important;
  }

  .c3-target-Unchanged-Stories,
  .c3-target-Unchanged-Points {
    .c3-bar {
      fill-opacity: 0 !important;
    }
    .c3-bar._expanded_ {
      fill-opacity: 0.1 !important;
    }
  }

  .c3-legend-item-Projected-Points-Remaining,
  .c3-legend-item-Projected-Stories-Remaining,
  .c3-legend-item-Ideal-Points-Remaining,
  .c3-legend-item-Ideal-Stories-Remaining {
    .c3-legend-item-tile {
      stroke-width: 2px;
      stroke-dasharray: 4, 2;
    }
  }

  .c3-tooltip-name--Ideal-Points-Remaining > .name > span {
    background-color: transparent !important;
    background: repeating-linear-gradient(to right, #999 0 45%, transparent 45% 55%);
    max-height: 2px;
    vertical-align: middle;
    margin-bottom: 0.25em;
  }
`;
export const BurndownReportCard = props => {
  const title = 'ARC (Added/Remaining/Completed) Chart';
  return _jsx(ReportCard, {
    ...props,
    title: title,
    description: "A visual representation of work completed/remaining over time.",
    chartStyle: chartStyle,
    Explanation: Explanation,
    Filters: Filters
  });
};
BurndownReportCard.displayName = "BurndownReportCard";