import { useMemo } from 'react';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { EpicSelect } from 'components/shared/EpicSelect';
import { getById, saveNew, useEpics } from 'data/entity/epic';
import { addEpic, removeEpic } from 'data/entity/story';
import { sortByEpicStateThenName } from 'utils/sort';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryEpic = _ref => {
  let {
    story,
    epic
  } = _ref;
  const {
    epics
  } = useEpics();
  const onChange = async epicId => {
    return new Promise(resolve => {
      if (!epicId) {
        removeEpic(story, resolve);
        return;
      }
      const selectedEpic = epics.find(m => `${m.id}` === epicId);
      if (!selectedEpic) {
        saveNew({
          name: epicId
        }, (err, res) => {
          if (err) throw new Error('Error saving new epic.');
          const epic = getById(res.id);
          if (epic) addEpic(story, epic, resolve);else throw new Error('Epic not found after saving');
        });
        return;
      }
      addEpic(story, selectedEpic, resolve);
    });
  };
  const sortedAndFilteredEpics = useMemo(() => epics.filter(e => !e.archived || e.id === epic?.id).sort(sortByEpicStateThenName), [epics, epic?.id]);
  return _jsx(EpicSelect, {
    groupId: story.group_id,
    epic: epic,
    epics: sortedAndFilteredEpics,
    onChange: onChange,
    children: _jsxs("div", {
      className: "attribute editable-attribute attribute-has-toggle story-epic has-icon-on-left",
      "data-tabindex": true,
      children: [_jsx("span", {
        className: "custom-icon",
        children: _jsx(SizedIcon, {
          size: 20,
          icon: "Epic"
        })
      }), _jsx("span", {
        "data-testid": "attribute-name",
        className: "name",
        children: "Epic"
      }), _jsx("span", {
        className: "value",
        children: epic ? _jsx(Emojify, {
          children: epic.name
        }) : _jsx("em", {
          children: "None"
        })
      })]
    })
  });
};
StoryEpic.displayName = "StoryEpic";