import { useEffect } from 'react';
export const useVideoControls = ref => {
  useEffect(() => {
    if (!ref) return;
    const onMouseEnter = e => {
      e.target.setAttribute('controls', 'controls');
    };
    const onMouseLeave = e => {
      e.target.removeAttribute('controls');
    };
    const onError = _ref => {
      let {
        currentTarget: video
      } = _ref;
      const div = document.createElement('DIV');
      div.setAttribute('style', `display: inline-block; color: #333; background: #eee; font-weight: bold; width:${video.offsetWidth}px; height:${video.offsetHeight}px; text-align: center; display: flex; align-items: center; justify-content: center;`);
      div.innerHTML = '<span class="fa fa-ban"></span>&nbsp;Failed to load video';
      video.replaceWith(div);
    };
    ref.querySelectorAll('video').forEach(el => {
      if (el.error) {
        onError({
          currentTarget: el
        });
      } else {
        el.addEventListener('mouseenter', onMouseEnter);
        el.addEventListener('mouseleave', onMouseLeave);
        el.addEventListener('error', onError);
      }
    });
    return () => {
      ref.querySelectorAll('video').forEach(el => {
        el.removeEventListener('mouseenter', onMouseEnter);
        el.removeEventListener('mouseleave', onMouseLeave);
        el.removeEventListener('error', onError);
      });
    };
  }, [ref]);
};