function isUnusableEntitlementError(input) {
  return input !== null && typeof input === 'object' && 'entitlement_tag' in input;
}
function isErrorPayload(input) {
  return input !== null && typeof input === 'object' && 'message' in input;
}

/**
 * Converts the input into a causified error message.
 * Especially useful to handle API errors when you're not sure what the error format is.
 *
 * Supports the following inputs:
 * - `Error`
 * - `string`
 * - `{ entitlement_tag: string; message: string }`
 * - `{ message: string; tag?: string }`
 * - `{ error: string }`
 */
export function causify(input) {
  if (!input) {
    return undefined;
  }
  if (input instanceof Error) {
    return input;
  }
  if (typeof input === 'string') {
    return new Error(input);
  }
  if (isUnusableEntitlementError(input)) {
    return new Error(`${input.entitlement_tag}: ${input.message}`);
  }
  if (isErrorPayload(input)) {
    if (input.tag) {
      return new Error(`${input.tag}: ${input.message}`);
    }
    return new Error(input.message);
  }
  if (typeof input === 'object' && 'error' in input && typeof input.error === 'string') {
    return new Error(input.error);
  }
  return undefined;
}