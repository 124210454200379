import noop from 'lodash/noop';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Context = createContext({
  setQueryEntry: noop,
  deleteQueryEntry: noop,
  queryFunctions: {
    cancel: noop,
    restart: noop,
    resetAbort: noop
  }
});
export const UseQueryContext = _ref => {
  let {
    children
  } = _ref;
  const activeQueriesRef = useRef(new Map());
  const setQueryEntry = useCallback(entry => {
    activeQueriesRef.current.set(entry.queryId, entry);
  }, []);
  const deleteQueryEntry = useCallback(_ref2 => {
    let {
      queryId
    } = _ref2;
    activeQueriesRef.current.delete(queryId);
  }, []);
  const filter = useCallback(mapFn => {
    return queryNames => [...activeQueriesRef.current.values()].filter(_ref3 => {
      let {
        query
      } = _ref3;
      return queryNames.includes(query);
    }).map(mapFn);
  }, []);
  const queryFunctions = useMemo(() => {
    return {
      cancel: filter(entry => {
        entry.stopPolling();
        entry.abort('Query cancelled');
      }),
      restart: filter(entry => {
        entry.resetAbort();
        entry.startPolling();
        entry.rerun();
      }),
      resetAbort: filter(entry => {
        entry.resetAbort();
      })
    };
  }, [filter]);
  return _jsx(Context.Provider, {
    value: {
      setQueryEntry,
      deleteQueryEntry,
      queryFunctions
    },
    children: children
  });
};
UseQueryContext.displayName = "UseQueryContext";
export const useAddQuery = entry => {
  const setQueryEntry = useContextSelector(Context, context => context.setQueryEntry);
  const deleteQueryEntry = useContextSelector(Context, context => context.deleteQueryEntry);
  useEffect(() => {
    setQueryEntry(entry);
    return () => {
      deleteQueryEntry(entry);
    };
  }, [setQueryEntry, entry, deleteQueryEntry]);
};
export const useQueryFunctions = () => useContextSelector(Context, context => context.queryFunctions);