import { WorkflowSelectReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WorkflowSelectReport_SelectorFragment = WorkflowSelectReport_SelectorFragmentDoc;
export const WorkflowSelect = props => {
  return _jsx(ReportSelectFilter, {
    ...props,
    label: "Workflow",
    LoadingComponent: _jsx(SkeletonRectangle, {
      width: 80,
      animate: true
    })
  });
};
WorkflowSelect.displayName = "WorkflowSelect";