import { useContext, useEffect } from 'react';
import { DataLayerUpdatesContext } from './DataLayerUpdatesContext';
export function useDataLayerUpdateEvent(fn, options) {
  const context = useContext(DataLayerUpdatesContext);
  if (!context) throw new Error('No DataLayerUpdatesContext found');
  const {
    registerListener
  } = context;
  const {
    filter,
    disable
  } = options || {};
  useEffect(() => {
    if (disable) return;
    const filterFn = changes => {
      if (!filter) fn(changes);else {
        const isArrayOfTypes = Array.isArray(filter);
        const filteredChanges = changes.filter(change => isArrayOfTypes ? filter.includes(change.__typename) : filter(change));
        if (filteredChanges.length > 0) fn(filteredChanges);
      }
    };
    return registerListener(filterFn);
  }, [disable, filter, fn, registerListener]);
}