export const listFormat = list => {
  // Polyfill for Safari <14.1
  if (!window.Intl || !window.Intl.ListFormat) {
    return list && list.length > 0 ? `${list.slice(0, -1).join(', ')}, and ${list.slice(-1)}` : '';
  }
  const locale = 'en';
  const options = {
    style: 'long',
    type: 'conjunction'
  };
  const formatter = new Intl.ListFormat(locale, options);
  const formattedList = formatter.format(list);
  return formattedList;
};