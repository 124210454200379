import { QueryCurrentPermissionJoyEnabledDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useLazyQuery } from 'gql';
import { useCallback } from 'react';
import { useWorkspaceSlug } from 'utils/navigation';
const QueryCurrentPermissionJoyEnabled = QueryCurrentPermissionJoyEnabledDocument;
export const useIsJoyEnabled = () => {
  const slug = useWorkspaceSlug();
  const [query] = useLazyQuery(QueryCurrentPermissionJoyEnabled, {
    variables: {
      slug
    }
  });
  return useCallback(async () => {
    const {
      data
    } = await query();
    return !!data?.workspace2?.currentUserPermission?.joyEnabled;
  }, [query]);
};