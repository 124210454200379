import { createNode } from '@clubhouse/shared/utils/dom';
import uniqueId from 'lodash/uniqueId';
import { useEffect, useState } from 'react';
import { visuallyHiddenClass } from '../styles';
const createElement = (id, text) => {
  const el = createNode({
    id
  });
  el.textContent = text;
  el.classList.add(visuallyHiddenClass);
  document.body.appendChild(el);
};
const activeDescriptions = new Map();
export function useAssistiveDescription(str) {
  const [id, setId] = useState(undefined);
  useEffect(() => {
    let elId;
    const existingItem = str ? activeDescriptions.get(str) : null;
    if (existingItem) {
      // Use existing element
      existingItem.count += 1;
      elId = existingItem.id;
    } else if (str) {
      // Create a new element
      elId = uniqueId('a11y-desc-');
      createElement(elId, str);
      activeDescriptions.set(str, {
        id: elId,
        count: 1
      });
    }
    setId(elId);
    return () => {
      if (str) {
        const existingItem = activeDescriptions.get(str);
        if (existingItem) {
          existingItem.count -= 1;
          if (!existingItem.count) {
            document.getElementById(existingItem.id)?.remove();
            activeDescriptions.delete(str);
          }
        }
      }
    };
  }, [str]);
  return {
    'aria-describedby': id
  };
}