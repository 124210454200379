import { useCallback } from 'react';
import { FIELD_ID } from 'components/shared/table/types';
import { getAll as getAllIterations } from 'data/entity/iteration';
import { setIteration } from 'data/entity/story';
import { storyIteration } from 'utils/sort';
import { TableIteration } from './tableComponents';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function IterationFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  const iterations = getAllIterations();
  const handleChange = useCallback(value => {
    setIteration(entity, value);
  }, [entity]);
  return _jsx(TableIteration, {
    groupId: entity.group_id,
    iteration: entity.iteration,
    iterations: iterations,
    onChange: handleChange
  });
}
IterationFieldComponent.displayName = "IterationFieldComponent";
export const IterationField = () => ({
  name: FIELD_ID.ITERATION,
  displayName: 'Iteration',
  sort: storyIteration,
  Component: IterationFieldComponent,
  width: 200
});