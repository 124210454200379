import { noop } from '@useshortcut/shapes-ds';
import { useMemo, useRef } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Context = createContext({
  isOpen: false,
  triggerRef: {
    current: null
  },
  toggleIsOpen: noop,
  multiSelect: false
});
export const ComboboxSelectProvider = _ref => {
  let {
    children,
    isOpen,
    toggleIsOpen,
    multiSelect
  } = _ref;
  const triggerRef = useRef(null);
  return _jsx(Context.Provider, {
    value: useMemo(() => ({
      isOpen,
      triggerRef,
      toggleIsOpen,
      multiSelect
    }), [isOpen, multiSelect, toggleIsOpen]),
    children: children
  });
};
ComboboxSelectProvider.displayName = "ComboboxSelectProvider";
export const useTriggerRef = () => useContextSelector(Context, context => context.triggerRef);
export const useIsOpen = () => useContextSelector(Context, context => context.isOpen);
export const useToggleIsOpen = () => useContextSelector(Context, context => context.toggleIsOpen);
export const useMultiSelect = () => useContextSelector(Context, context => context.multiSelect);