import * as ProfileData from 'data/entity/profile';
import { addMeAsFollower as add, currentUserIsFollower, isNew, removeMeAsFollower as remove, saveFollowers } from 'data/entity/story';
import { addMeAsFollower, removeMeAsFollower, updateState } from 'utils/addNew';
import { formatProfile, formatProfiles } from '../../formatters/profile';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryFollowers = story => {
  lazilyLoadArgumentChoices('currentFollowersForStory', () => getCurrentFollowersForStory(story));
  lazilyLoadArgumentChoices('followersForCurrentStory', () => getFollowersForCurrentStory(story));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryFollowers', updateStoryFollowers(story));
    getCommandBar().addCallback('toggleMeAsFollower', toggleMeAsFollower(story));
  }
};
const getCurrentFollowersForStory = story => {
  if (!story) return [];
  return formatProfiles(story.followers);
};
const getFollowersForCurrentStory = story => {
  if (!story) return [];
  const currentUserProfile = ProfileData.getCurrentUserProfileDetails();
  const otherProfiles = ProfileData.getAllActiveProfileDetails().filter(profile => profile.id !== currentUserProfile.id);
  return [formatProfile(currentUserProfile), ...formatProfiles(otherProfiles)];
};
const updateStoryFollowers = story => _ref => {
  let {
    followers
  } = _ref;
  const newFollowerIds = followers.map(follower => follower.id);
  if (isNew(story)) {
    updateState({
      follower_ids: newFollowerIds
    });
  } else {
    const newStory = {
      ...story,
      follower_ids: newFollowerIds
    };
    saveFollowers(newStory);
  }
};
const toggleMeAsFollower = story => () => {
  const isFollower = currentUserIsFollower(story);
  if (isNew(story)) {
    isFollower ? removeMeAsFollower() : addMeAsFollower();
  } else {
    isFollower ? remove(story) : add(story);
  }
};