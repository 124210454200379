import { useLatestRef } from '@clubhouse/shared/hooks';
import { causify } from '@clubhouse/shared/utils';
import { getCurrentUserPrefix } from 'data/entity/user';
import { useCallback, useEffect } from 'react';
import { logError } from 'utils/monitoring';
let broadcastChannel = null;
export function useBroadcastChannel(_ref) {
  let {
    name,
    isBroadcaster,
    onMessage
  } = _ref;
  const isBroadcasterRef = useLatestRef(isBroadcaster);
  useEffect(() => {
    if (broadcastChannel) return;
    broadcastChannel = new BroadcastChannel(`${getCurrentUserPrefix()}${name}`);
    broadcastChannel.onmessage = event => {
      if (isBroadcasterRef.current) return;
      onMessage(event.data);
    };
    return () => {
      if (broadcastChannel) {
        broadcastChannel.close();
        broadcastChannel = null;
      }
    };
  }, [name, onMessage, isBroadcasterRef]);
  return useCallback(event => {
    if (broadcastChannel && isBroadcasterRef.current) {
      try {
        broadcastChannel.postMessage(event);
      } catch (e) {
        logError(new Error('Failed to broadcast message', {
          cause: causify(e)
        }), {
          name: broadcastChannel.name,
          stringifiedEvent: JSON.stringify(event)
        });
      }
    }
  }, [isBroadcasterRef]);
}