export const isDoubleEncoded = data => {
  return data.indexOf('"{\\"') === 0;
};
export const parseCurrentUserPermissionSpaceData = str => {
  if (!str) {
    return null;
  }
  if (isDoubleEncoded(str)) {
    str = JSON.parse(str);
  }
  return str ? JSON.parse(str) : null;
};