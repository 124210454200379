import { v4 as uuidv4 } from 'uuid';
import View from '../modules/view';
import { TeamSelectionPanel } from 'pages/workflows/components/TeamSelectionPanel';
const exports = {};
exports.render = _ref => {
  let {
    key = uuidv4(),
    onChange,
    groups
  } = _ref;
  return View.renderComponentDelayed({
    componentKey: key,
    component: TeamSelectionPanel,
    props: {
      onChange,
      groups
    }
  }).html;
};
export { exports as default };