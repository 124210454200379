import "core-js/modules/es.array.push.js";
import { MoveWithinColumnContextMenuItemFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ContextMenuItem } from '@clubhouse/shared/components/ContextMenu';
import { useUpdateStoryPosition } from 'components/gql/stories/hooks/useUpdateStoryPosition';

// eslint-disable-next-line import/no-restricted-paths
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const MoveWithinColumnContextMenuItemFragment = MoveWithinColumnContextMenuItemFragmentDoc;
export function MoveWithinColumnContextMenuItem(_ref) {
  let {
    id,
    workflowState,
    moveTo,
    onUpdateOptimistically
  } = _ref;
  const save = useUpdateStoryPosition();
  return _jsx(ContextMenuItem, {
    isDisabled: workflowState.type === 'done',
    icon: moveTo === 'top' ? 'ChevronUp' : 'ChevronDown',
    label: `Move to ${moveTo}`,
    onClick: () => {
      if (workflowState.type === 'done') return;
      let undo;
      if (onUpdateOptimistically) {
        undo = onUpdateOptimistically(groups => {
          const data = groups.get(workflowState.id);
          if (!data) return groups;
          const {
            stories
          } = data;
          const index = stories.findIndex(s => s.id === id);
          if (index >= 0) {
            const [item] = stories.splice(index, 1);
            if (moveTo === 'top') stories.unshift(item);else stories.push(item);
          }
          groups.set(workflowState.id, {
            ...data,
            stories
          });
          return groups;
        }).undo;
      }
      save({
        storyId: id,
        moveTo,
        onError: undo
      });
    }
  });
}
MoveWithinColumnContextMenuItem.displayName = "MoveWithinColumnContextMenuItem";