import { Nouns } from '@clubhouse/shared/constants';
import { EpicsFilter } from 'components/gql/epics/EpicsFilter';
import { ClearAllCurrentFiltersButton } from 'components/gql/filters/ClearAllCurrentFiltersButton';
import { CustomFieldsFilter } from 'components/gql/filters/CustomFieldFilter';
import { QueryFilter } from 'components/gql/filters/QueryFilter';
import { StoryTypeFilter } from 'components/gql/filters/StoryTypeFilter';
import { LabelsFilter } from 'components/gql/label/LabelsFilter';
import { OwnersFilter } from 'components/gql/owner/OwnersFilter';
import { PriorityFilter } from 'components/gql/priority/PriorityFilter';
import { RequestersFilter } from 'components/gql/requester/RequestersFilter';
import { TeamsFilter } from 'components/gql/team/TeamsFilter';
import { IfNoTeamScope } from 'components/team-navigation/utils/IfNoTeamScope';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BacklogFilters = _ref => {
  let {
    enabledCustomFields
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx(StoryTypeFilter, {}), _jsx(IfNoTeamScope, {
      children: _jsx(TeamsFilter, {
        entityType: Nouns.Story
      })
    }), _jsx(OwnersFilter, {
      entityType: Nouns.Story
    }), _jsx(RequestersFilter, {
      entityType: Nouns.Story
    }), !!enabledCustomFields.priority && _jsx(PriorityFilter, {
      entityType: Nouns.Story
    }), _jsx(EpicsFilter, {
      entityType: Nouns.Story
    }), _jsx(LabelsFilter, {
      entityType: Nouns.Story
    }), _jsx(CustomFieldsFilter, {
      entityType: Nouns.Story,
      severityFieldId: enabledCustomFields.severity?.id,
      skillSetFieldId: enabledCustomFields.skillSet?.id,
      technicalAreaFieldId: enabledCustomFields.technicalArea?.id,
      productAreaFieldId: enabledCustomFields.productArea?.id
    }), _jsx(ClearAllCurrentFiltersButton, {})]
  });
};
export const BacklogFiltersSearch = () => {
  return _jsx(QueryFilter, {
    entityType: Nouns.Story
  });
};
BacklogFiltersSearch.displayName = "BacklogFiltersSearch";