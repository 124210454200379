import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Constants from '../modules/constants';
import EpicModel from './epic';
import LabelModel from './label';
import ProjectModel from './project';
import StoryModel from './story';
import Updates from '../modules/updates';
import { getCurrentPage } from 'utils/navigation';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
const exports = {};

/*
Bulk Selection story entities:
{
  "id": 4058 // Maps to a story ID
}
*/

Collection.create('BulkSelection', exports);
const BULK_SELECT_LIMIT = 500;
exports.isValid = story => {
  return !!StoryModel.isValid(story);
};
exports.updateIfValid = story => {
  if (exports.isValid(story)) {
    exports.update({
      id: story.id
    });
    StoryModel.getOrFetchStory(story.id);
  }
};
exports.hasSelection = () => {
  return !!exports.getSelectionCount();
};
exports.getSelectionCount = () => {
  return exports.size();
};
exports.isAtSelectionLimit = () => {
  return exports.getSelectionCount() === BULK_SELECT_LIMIT;
};
exports.getRemainingLimit = () => {
  return BULK_SELECT_LIMIT - exports.getSelectionCount();
};
exports.getBulkSelectLimit = () => {
  return BULK_SELECT_LIMIT;
};
exports.getAllIds = () => {
  return exports.map('id').filter(n => typeof n === 'number');
};
exports.getAndOptionallyFetchStoriesForEachSelection = () => new Promise(resolve => {
  const ids = exports.all();
  if (!ids?.length) resolve([]);else {
    const unavailable = ids.filter(_ref => {
      let {
        id
      } = _ref;
      return !StoryModel.getById(id);
    }).map(_ref2 => {
      let {
        id
      } = _ref2;
      return id;
    });
    const resolveWithStories = () => resolve(exports.all().map(_ref3 => {
      let {
        id
      } = _ref3;
      return StoryModel.getById(id);
    }));
    if (!unavailable.length) resolveWithStories();else StoryModel.getStoriesById(unavailable, resolveWithStories);
  }
});
exports.getStoriesForEachSelection = () => {
  return _.compact(_.map(exports.all(), selection => {
    return StoryModel.getById(selection.id);
  }));
};
exports.isSelected = story => {
  return exports.isValid(story) && !!exports.getById(story.id);
};
exports.allSelected = stories => {
  return !!stories.length && _.every(stories, exports.isSelected);
};
exports.areAllArchived = () => {
  const stories = exports.getStoriesForEachSelection();
  return !!stories.length && _.every(stories, 'archived');
};
exports.removeFromSelection = id => {
  if (exports.getById(id)) {
    exports.remove({
      id
    });
    StoryModel.trigger('bulkStoriesUpdated');
  }
};
exports.save = (data, callback, options) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  options = options || {};
  if (exports.size() && _.isPlainObject(data)) {
    const page = getCurrentPage();
    Backend.put('/api/private/stories/bulk', {
      data: _.assign({}, data, {
        story_ids: exports.getAllIds()
      }),
      actionContext: page,
      onComplete: (res, xhr) => {
        if (res.error || xhr.status !== 200) {
          callback(res && res.error || Constants.SERVER_DOWN_ERROR);
        } else {
          dispatchEvent(EVENT_TYPES.STORY_UPDATED);
          res.forEach(story => {
            StoryModel.updateIfValid(story);
          });

          // Fetch entities related to the update, that aren't
          // included in the response from the updates request.
          _fetchRelatedChanges(data);
          if (!options.persistSelection) {
            _emptyCacheAndFetchUpdates(callback);
          } else {
            callback(null, []);
          }
        }
      }
    });
  } else {
    callback(null, []);
  }
};
exports.archiveAndMaintainSelection = callback => {
  exports.save({
    archived: true
  }, callback, {
    persistSelection: true
  });
};
exports.delete = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (exports.size()) {
    const page = getCurrentPage();
    Backend.delete('/api/private/stories/bulk', {
      data: {
        story_ids: exports.getAllIds()
      },
      actionContext: page,
      onComplete: (res, xhr) => {
        if (res.error || xhr.status !== 204) {
          callback(res && res.error || Constants.SERVER_DOWN_ERROR);
        } else {
          _emptyCacheAndFetchUpdates(callback);
        }
      }
    });
  } else {
    callback(null, []);
  }
};
exports.selectAll = ids => {
  exports.flush();
  exports.selectMultiple(ids);
};
exports.selectMultiple = ids => {
  exports.trigger('bulkStart');
  ids.forEach(id => exports.update({
    id
  }));
  exports.trigger('bulkEnd');
  const foundStories = exports.getStoriesForEachSelection().map(story => story.id);
  const unloadedStories = _.difference(ids, foundStories);
  if (unloadedStories) {
    StoryModel.getStoriesById(unloadedStories);
  }
};
exports.removeAll = ids => {
  exports.trigger('bulkStart');
  ids.forEach(id => exports.remove({
    id
  }));
  exports.trigger('bulkEnd');
};
exports.removeEverything = () => {
  exports.flush();
  exports.trigger('removeEverything');
};
function _emptyCacheAndFetchUpdates(callback) {
  // Empty selection cache.
  exports.removeEverything();

  // Let's fetch updates right away so that we get correct position updates and only redraw once.
  Updates.checkForUpdates({
    callback
  });
}
function _fetchRelatedChanges(data) {
  // If labels were created during this step, lets explicitly
  // fetch all labels again, since we don't get the created
  // label(s) during the updates poll.
  if (data.labels_add) {
    LabelModel.fetchAllSlim();
  }

  // If an Epic was selected during this step, lets fetch
  // all stories in those Epics again, since we don't get
  // epic id changes during the updates poll.
  if (data.epic_id) {
    // TODO: This might be very slow! What can we do to avoid this?
    EpicModel.fetchStoriesForEpics(_getAllSelectedEpics(data.epic_id));
  }

  // If a Project was selected during this step, lets fetch
  // all stories in those Projects again, since we don't get
  // project id changes during the updates poll.
  if (data.project_id) {
    ProjectModel.fetchStoriesForProjects(_getAllSelectedProjects(data.project_id));
  }
}
function _getAllSelectedEpics(currentId) {
  return _getChangedEntityIds('epic_id', currentId);
}
function _getAllSelectedProjects(currentId) {
  return _getChangedEntityIds('project_id', currentId);
}
function _getChangedEntityIds(prop, currentId) {
  const selectionIds = _.compact(_.uniq(_.map(exports.getStoriesForEachSelection(), prop).concat([currentId])));
  return _.map(selectionIds, id => {
    return {
      id
    };
  });
}
export { exports as default };