import "core-js/modules/es.array.push.js";
import TooltipController from 'app/client/core/js/controllers/tooltip.js';
import Search from 'app/client/core/js/modules/search.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Tooltip'], TooltipController], [['Search'], Search], [['Tooltip'], Tooltip], [['Controller', 'Tooltip'], TooltipController], [['Search'], Search], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
export const OPERATORS = [{
  name: 'team:',
  tooltip: 'Team names or IDs',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "team:"
    }), "\"Growth Team\""]
  })
}, {
  name: 'owner:',
  tooltip: 'Usernames',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "owner:"
    }), "robertsmith"]
  })
}, {
  name: 'requester:',
  tooltip: 'Usernames',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "requester:"
    }), "janesmith"]
  })
}, {
  name: 'type:',
  tooltip: 'Feature, bug, or chore',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "type:"
    }), "bug"]
  })
}, {
  name: 'label:',
  tooltip: '',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "label:"
    }), "q2-design"]
  })
}, {
  name: 'state:',
  tooltip: 'Workflow state names or IDs',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "state:"
    }), "\"ready for dev\""]
  })
}, {
  name: 'created:',
  tooltip: '',
  tooltipFn: 'App.Controller.Tooltip.renderSearchByDateTooltip',
  description: _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: "created:"
    }), "*..YYYY-MM-DD"]
  })
}];
export const INPUT_OPERATORS = ['branch', 'comment', 'commit', 'completed', 'created', 'description', 'due', 'epic', 'estimate', 'has:attachment', 'has:branch', 'has:comment', 'has:commit', 'has:deadline', 'has:epic', 'has:label', 'has:owner', 'has:pr', 'has:task', 'id', 'is:archived', 'is:blocked', 'is:blocker', 'is:done', 'is:overdue', 'is:started', 'is:story', 'is:unestimated', 'is:unstarted', 'label', 'moved', 'objective', 'owner', 'pr', 'project', 'requester', 'state', 'team', 'title', 'type', 'updated'];
export const isInputOperator = maybeInputOperator => INPUT_OPERATORS.includes(maybeInputOperator);