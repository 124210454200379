import { useUniqueId } from '@useshortcut/shapes-ds';
import { useCallback, useEffect, useRef } from 'react';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { useToggleState } from '@clubhouse/shared/hooks';
import { closestAncestor } from '@clubhouse/shared/utils';
import { CommentInput } from 'components/shared/Comments/CommentInput';
import { Actions, Content } from 'components/shared/Comments/components';
import { EmojiPicker } from 'components/shared/EmojiPicker';
import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { addReaction, deleteComment, deleteReaction, unlinkFromSlack, updateComment } from 'data/entity/story';
import { getLoggedInUserPermissionID, isLoggedInUserAdmin } from 'data/entity/user';
import { error } from 'utils/message';
import { AuthorAvatar, AuthorName, CommentAction, CommentContent, CommentDate, CommentReactions, CommentReplies, FaIcon, Header, Reply, Root } from './components';
import { useStoryComment } from './useStoryComment';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function StoryComment(_ref) {
  let {
    storyId,
    comment
  } = _ref;
  const rootRef = useRef(null);
  const isEditable = !comment.deleted && comment.author_id === getLoggedInUserPermissionID();
  const isDeletable = !comment.deleted && (comment.author_id === getLoggedInUserPermissionID() || isLoggedInUserAdmin());
  const isReplyable = !comment.deleted && !comment.parent_id;
  const isLinkedToSlack = !!comment.linked_to_slack;
  const [isCollapsed, toggleCollapsed] = useToggleState();
  const [isEditing, toggleEditing] = useToggleState();
  const [isDeleting, toggleDeleting] = useToggleState();
  const deleteStoryComment = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      toggleDeleting.on();
      await deleteComment(storyId, comment.id);
      toggleDeleting.off();
    }
  }, [comment.id, storyId, toggleDeleting]);
  const unlinkCommentFromSlack = useCallback(() => unlinkFromSlack(storyId, comment.id), [comment.id, storyId]);
  const editOnShiftClick = useCallback(e => {
    if (e.shiftKey && isEditable) toggleEditing.on();
  }, [isEditable, toggleEditing]);
  const saveComment = useCallback(async value => {
    if (value) {
      try {
        await updateComment(storyId, comment.id, value);
      } catch (err) {
        error(err, {
          secondary: 'Your comment has been restored.'
        });
      } finally {
        toggleEditing.off();
      }
    } else {
      error('Your comment is empty!');
    }
  }, [comment.id, storyId, toggleEditing]);
  const onAddReaction = emoji => {
    addReaction(storyId, comment.id, emoji);
  };
  const onRemoveReaction = emoji => {
    deleteReaction(storyId, comment.id, emoji);
  };
  useEffect(() => {
    // This is temporary code until the full thread is in rendered in React
    if (!rootRef.current) return;
    const threadWrapper = closestAncestor(rootRef.current, '.comment-wrapper');
    if (threadWrapper) threadWrapper.classList.toggle('thread-collapsed', isCollapsed);
  }, [isCollapsed]);
  const repliesId = useUniqueId('replies');
  return _jsxs(Root, {
    ref: rootRef,
    entityId: storyId,
    comment: comment,
    children: [_jsx(AuthorAvatar, {
      size: AuthorAvatar.SIZE.M
    }), _jsxs(Content, {
      onClick: editOnShiftClick,
      children: [_jsxs(Header, {
        children: [_jsx(AuthorName, {
          size: AuthorName.SIZE.MEDIUM
        }), _jsx(CommentDate, {
          size: CommentDate.SIZE.XSMALL
        }), _jsx(Actions, {
          children: _jsxs(Spaced, {
            horizontally: true,
            amount: "space1",
            align: "center",
            children: [!!comment.comments?.length && _jsx(Tooltip, {
              content: "Toggle Replies",
              children: _jsx(CommentAction, {
                onClick: toggleCollapsed,
                "aria-label": "Toggle Replies",
                "aria-expanded": !isCollapsed,
                "aria-controls": repliesId,
                children: _jsx(SizedIcon, {
                  icon: isCollapsed ? 'Expand' : 'Collapse',
                  size: 18,
                  fill: "disabled"
                })
              })
            }), !comment.deleted && _jsx(Tooltip, {
              content: "Add Reaction",
              children: _jsx(EmojiPicker, {
                onSelect: onAddReaction,
                children: _jsx(CommentAction, {
                  "aria-label": "Add Reaction",
                  children: _jsx(SizedIcon, {
                    icon: "Reaction",
                    size: 16,
                    fill: "disabled"
                  })
                })
              })
            }), isEditable && _jsx(Tooltip, {
              content: "Edit comment",
              children: _jsx(CommentAction, {
                onClick: toggleEditing.on,
                "aria-label": "Edit Comment",
                children: _jsx(SizedIcon, {
                  icon: "Edit",
                  size: 16,
                  fill: "disabled"
                })
              })
            }), isDeletable && _jsx(Tooltip, {
              content: "Delete comment",
              children: _jsx(CommentAction, {
                onClick: deleteStoryComment,
                "aria-label": "Delete Comment",
                children: _jsx(SizedIcon, {
                  icon: "Trash",
                  size: 16,
                  fill: "disabled"
                })
              })
            }), isLinkedToSlack && _jsx(Tooltip, {
              content: "Unlink Comment Thread from Slack",
              children: _jsx(CommentAction, {
                onClick: unlinkCommentFromSlack,
                "aria-label": "Unlink Comment Thread from Slack",
                children: _jsx(FaIcon, {
                  className: "fa fa-unlink"
                })
              })
            })]
          })
        })]
      }), isEditing ? _jsx(CommentInput, {
        snapshotKey: `story.${storyId}.editComment.${comment.id}`,
        onSave: saveComment,
        onCancel: toggleEditing.off,
        children: _ref2 => {
          let {
            isSaving,
            onSave,
            onCancel,
            ...props
          } = _ref2;
          return _jsx(MarkdownEditor, {
            context: "Comment",
            isReadOnly: isSaving,
            initialValue: comment.text,
            onSave: onSave,
            onCancel: onCancel,
            ...props
          });
        }
      }) : _jsxs(_Fragment, {
        children: [_jsx(CommentContent, {
          isDeleting: isDeleting,
          onChange: async newValue => {
            try {
              await updateComment(storyId, comment.id, newValue);
            } catch {}
          }
        }), !!comment.reactions?.length && _jsx(CommentReactions, {
          onAddEmoji: onAddReaction,
          onRemoveEmoji: onRemoveReaction
        })]
      })]
    }), _jsx(CommentReplies, {
      elId: repliesId,
      isCollapsed: isCollapsed,
      onShowReplies: toggleCollapsed.off,
      children: c => _jsx(StoryComment, {
        storyId: storyId,
        comment: c
      })
    }), isReplyable && _jsx(Reply, {})]
  });
}
StoryComment.displayName = "StoryComment";
export function StoryCommentById(_ref3) {
  let {
    storyId,
    commentId
  } = _ref3;
  const comment = useStoryComment(storyId, commentId);
  if (!comment || comment.deleted && !comment.comments?.length) return null;
  return _jsx(StoryComment, {
    storyId: storyId,
    comment: comment
  });
}
StoryCommentById.displayName = "StoryCommentById";