import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { ConfirmationDialog as SharedConfirmationDialog } from '@clubhouse/shared/components/ConfirmationDialog/ConfirmationDialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ConfirmationDialog = () => {
  const {
    isConfirmationDialogOpen,
    dialogProps,
    closeDialog
  } = useConfirmationDialogState();
  return dialogProps ? _jsx(SharedConfirmationDialog, {
    ...dialogProps,
    onClose: closeDialog,
    visible: isConfirmationDialogOpen
  }) : null;
};