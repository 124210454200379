import * as ResetEmailFormTemplate from 'app/client/login/views/templates/resetEmailForm.html';
import * as ResetPasswordFormTemplate from 'app/client/login/views/templates/resetPasswordForm.html';
import * as InvalidResetCodeTemplate from 'app/client/login/views/templates/invalidResetCode.html';
import * as ServerMessageTemplate from 'app/client/core/views/templates/serverMessage.html';
import { Button } from 'components/shared/DeprecatedButton';
import { Problem } from 'components/shared/Problem';
import Backend from '../../../core/js/modules/backend';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import * as Event from '../../../core/js/_frontloader/event';
import LoginController from './login';
import CreateOrgOrWorkspaceController from './createOrgOrWorkspace';
import OrganizationModel from '../../../core/js/models/organization';
import Log from '../../../core/js/modules/log';
import { init as initPasswordStrength } from '../../../core/js/controllers/passwordStrength';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import { setPageTitle } from 'utils/page';
import { generatePathForPage } from 'utils/navigation';
const exports = {};
const PARENT_SELECTOR = '#reset-form';
exports.init = () => {
  const path = Url.parsePath();
  setPageTitle(`Reset your password | ${BRAND.NAME}`);
  if (path.reset === 'change-password') {
    if (Url.parseLocationSearch().msg) {
      return renderError();
    }
    Backend.get('/api/private/user/reset-password/is-valid', {
      onComplete: res => {
        if (res.error) {
          return renderError();
        }
        renderPasswordForm(res);
      }
    });
  } else if (path.reset) {
    Backend.get('/api/private/user/reset-password/' + path.reset, {
      onComplete: res => {
        if (exports.isValidCode(res)) {
          renderPasswordForm(res);
        } else {
          renderError();
        }
      }
    });
  } else {
    exports.renderEmailForm();
  }
};
const renderError = () => {
  setPageTitle(`Invalid reset code | ${BRAND.NAME}`);
  exports.renderInvalidResetCode();
};
exports.isValidCode = obj => {
  return _.isPlainObject(obj) && obj.email && obj.reset_code;
};
exports.renderEmailForm = () => {
  const html = ResetEmailFormTemplate.render();
  $('#form-container').html(html);
  Utils.fadePageIn();
  Validator.init(PARENT_SELECTOR);
  $('#reset-email').focus();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'ResetPassword');
};
const renderPasswordForm = res => {
  const html = ResetPasswordFormTemplate.render({
    email: res.email
  });
  $('#form-container').html(html);
  Utils.fadePageIn();
  const passwordStrengthSelector = PARENT_SELECTOR + ' .password input';
  initPasswordStrength(passwordStrengthSelector);
  Validator.init(PARENT_SELECTOR);
  $('#password-field').focus();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'ResetPasswordForm');
};
exports.renderInvalidResetCode = () => {
  const html = InvalidResetCodeTemplate.render();
  $('#form-container').html(html);
  View.renderComponentConditionally({
    containerSelector: '#reset-not-found',
    component: Problem,
    props: {
      height: '40vh',
      icon: React.createElement('span', {
        className: 'fa fa-exclamation-triangle'
      }),
      title: 'Invalid Reset Code',
      description: React.createElement(React.Fragment, {}, ["This reset code is either invalid or expired. If you're having any trouble resetting your password, please ", React.createElement('a', {
        href: `mailto:${BRAND.SUPPORT_EMAIL}`
      }, 'contact us'), '.']),
      buttons: React.createElement(Button, {
        text: 'Go back to login page',
        href: '/login',
        size: Button.sizes.xsmall
      })
    }
  });
  Utils.fadePageIn();
  Event.trigger('resize');
};
exports.sendResetCode = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  const email = data.email.trim();
  const button = context.find('button.action');
  if (Validator.isValid(context)) {
    button.html('<span class="fa fa-spin fa-star"></span> Checking...').addClass('disabled').attr('disabled', 'disabled');
    Backend.post('/api/private/user/reset-password', {
      data: {
        email
      },
      onComplete: res => {
        Log.log('Password Reset Initiated', {
          email,
          response: res
        });
        if (res && !res.error) {
          exports.onResetEmailSent();
        } else {
          exports.onResetEmailError(res.error);
        }
      }
    });
  } else {
    Validator.focusOnFirstError(context);
    setTimeout(() => {
      Event.trigger('resize');
    }, 300);
  }
  return false;
};
exports.savePassword = e => {
  e.preventDefault();
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  const path = Url.parsePath();
  const button = context.find('button.action');
  const email = Utils.data(context, 'email').trim();
  if (Validator.isValid(context)) {
    button.html('<span class="fa fa-spin fa-star"></span> Saving...').addClass('disabled').attr('disabled', 'disabled');
    if (path.reset === 'change-password') {
      return Backend.put('/api/private/user/reset-password', {
        data,
        onComplete: (res, xhr) => {
          if (res && !res.error) {
            Log.log('Password Reset Successful');
            exports.onSaveSuccess({
              username: email,
              password: data.password
            });
          } else {
            Log.error(new Error(res.error), {
              status: xhr ? xhr.status : '?',
              res
            });
            exports.onSaveError(res);
          }
        }
      });
    }
    Backend.put('/api/private/user/reset-password/' + path.reset, {
      data,
      onComplete: (res, xhr) => {
        if (res && !res.error) {
          Log.log('Password Reset Successful', {
            email
          });
          exports.onSaveSuccess({
            username: email,
            password: data.password
          });
        } else {
          Log.error(new Error(res.error), {
            email: data.email,
            status: xhr ? xhr.status : '?',
            res
          });
          exports.onSaveError(res);
        }
      }
    });
  } else {
    Validator.focusOnFirstError(context);
    setTimeout(() => {
      Event.trigger('resize');
    }, 300);
  }
  return false;
};
exports.onResetEmailSent = () => {
  const context = $(PARENT_SELECTOR);
  context.fadeOut(150, () => {
    const html = ServerMessageTemplate.render({
      className: 'success full-page-server-message',
      id: 'reset-email-sent',
      iconLeft: 'fa-check-circle',
      title: 'Reset code emailed.',
      message: 'Please check your email for instructions on how to reset your password.'
    });
    $('#form-container').html(html);
    $('#reset-email-sent').hide().fadeIn(150);
  });
};
exports.onResetEmailError = err => {
  const context = $(PARENT_SELECTOR);
  const button = context.find('button.action');
  if (err === 'The resource requested was not found.') {
    err = 'That email address was not found in our system.';
  }
  const html = ServerMessageTemplate.render({
    className: 'error',
    iconLeft: 'fa-minus-circle',
    title: 'Unable to reset password.',
    message: err || 'Please try a different email address.'
  });
  context.find('.server-messages').hide().html(html).slideDown(300, 'swing');
  button.html('Email Reset Code').removeClass('disabled').attr('disabled', false);
};
exports.onSaveSuccess = data => {
  const context = $(PARENT_SELECTOR);
  context.fadeOut(150, () => {
    const html = ServerMessageTemplate.render({
      className: 'success full-page-server-message',
      id: 'password-reset-successfully',
      iconLeft: 'fa-check',
      title: 'Password updated.',
      message: '<span class="fa fa-spin fa-star"></span> Logging you in...'
    });
    $('#form-container').html(html);
    $('#password-reset-successfully').hide().fadeIn(150);
    setTimeout(() => {
      const {
        username,
        password
      } = data;
      LoginController.attemptLogin({
        username,
        password
      }, {
        onError: () => {
          const html = ServerMessageTemplate.render({
            className: 'success full-page-server-message',
            id: 'password-reset-successfully',
            iconLeft: 'fa-exclamation-triangle',
            title: 'Unable to log in.',
            message: 'You might have two-factor authentication enabled. ' + 'Please go to the <a href="/login">login page</a> and try again.'
          });
          $('#form-container').html(html);
        },
        onSuccess: () => {
          ConsolidatedFetch.fetchUserOrgAndApplicationState(() => {
            const org = OrganizationModel.getCurrent();
            const redirectUrl = generatePathForPage(CreateOrgOrWorkspaceController.DEFAULT_REDIRECT_PAGE, {
              slug: org?.url_slug
            });
            Utils.redirect(redirectUrl);
          });
        }
      });
    }, 2000);
  });
};
exports.onSaveError = res => {
  const context = $(PARENT_SELECTOR);
  const button = context.find('button.action');
  const pleaseContactUs = '<p>Please try again. If you continue to have trouble, ' + `please <a href="mailto:${BRAND.SUPPORT_EMAIL}">contact us</a>.</p>`;
  const html = ServerMessageTemplate.render({
    className: 'error',
    iconLeft: 'fa-minus-circle',
    title: 'We were unable to reset your password.',
    message: res.error + pleaseContactUs
  });
  context.find('.server-messages').hide().html(html).slideDown(300, 'swing');
  button.html('Save Password').removeClass('disabled').attr('disabled', false);
};
export { exports as default };