import Color from 'color';
export const COLORS = {
  RED23: Color.hsl(0, 100, 23).hex(),
  // #750000
  RED33: Color.hsl(0, 90, 33).hex(),
  // #A00808
  RED50: Color.hsl(0, 60, 50).hex(),
  // #CC3333
  RED58: Color.hsl(0, 82, 58).hex(),
  // #EC3B3B
  RED77: Color.hsl(0, 67, 77).hex(),
  // #EC9D9D
  RED95: Color.hsl(0, 77, 95).hex(),
  // #FCE8E8

  ORANGE38: Color.hsl(32, 100, 38).hex(),
  // #C26700
  ORANGE47: Color.hsl(32, 98, 47).hex(),
  // #ED8002
  ORANGE63: Color.hsl(14, 89, 63).hex(),
  // #F5754D

  YELLOW41: Color.hsl(48, 100, 41).hex(),
  // #CFA500
  YELLOW45: Color.hsl(48, 75, 45).hex(),
  // #C9A61D
  YELLOW50: Color.hsl(48, 60, 50).hex(),
  // #CCAD33
  YELLOW63: Color.hsl(36, 91, 63).hex(),
  // #F6B14A
  YELLOW68: Color.hsl(48, 90, 68).hex(),
  // #F7D964
  YELLOW82: Color.hsl(48, 95, 82).hex(),
  // #FDEBA5
  YELLOW92: Color.hsl(48, 90, 92).hex(),
  // #FDF6D8
  YELLOW94: Color.hsl(48, 50, 94).hex(),
  // #F7F4E8

  GREEN23: Color.hsl(140, 95, 23).hex(),
  // #037228
  GREEN30: Color.hsl(140, 90, 30).hex(),
  // #089136
  GREEN38: Color.hsl(140, 80, 38).hex(),
  // #13AE47
  GREEN67: Color.hsl(140, 66, 67).hex(),
  // #73E298
  GREEN88: Color.hsl(140, 71, 88).hex(),
  // #CBF6D9
  GREEN97: Color.hsl(140, 88, 97).hex(),
  // #F1FEF5

  BLUE22: Color.hsl(218, 15, 22).hex(),
  // #2F353F
  BLUE39: Color.hsl(218, 87, 39).hex(),
  // #0D4CBA
  BLUE64: Color.hsl(218, 81, 64).hex(),
  // #598FEE
  BLUE77: Color.hsl(202, 72, 77).hex(),
  // #9AD0EF
  BLUE82: Color.hsl(218, 100, 82).hex(),
  // #A3C5FF
  BLUE88: Color.hsl(218, 50, 88).hex(),
  // #D1DCF0
  BLUE93: Color.hsl(203, 72, 93).hex(),
  // #E0F0FA
  BLUE97: Color.hsl(218, 82, 97).hex(),
  // #F1F6FE

  DUSTYBLUE41: Color.hsl(202, 57, 41).hex(),
  // #2D78A4
  DUSTYBLUE51: Color.hsl(202, 57, 51).hex(),
  // #3B95C9
  DUSTYBLUE62: Color.hsl(202, 72, 62).hex(),
  // #58B1E4

  PINK72: Color.hsl(314, 68, 72).hex(),
  // #E887D1

  PURPLE17: Color.hsl(264, 30, 17).hex(),
  // #291E38
  PURPLE26: Color.hsl(264, 30, 26).hex(),
  // #3E2E56
  PURPLE29: Color.hsl(264, 30, 29).hex(),
  // #463460
  PURPLE37: Color.hsl(264, 82, 37).hex(),
  // #4F11AC
  PURPLE47: Color.hsl(264, 83, 47).hex(),
  // #6414DB
  PURPLE75: Color.hsl(264, 91, 75).hex(),
  // #B485F9
  PURPLE93: Color.hsl(264, 70, 93).hex(),
  // #EBE1FA
  PURPLE97: Color.hsl(264, 83, 97).hex(),
  // #F6F1FE

  DARKPURPLE98: Color.hsl(265, 30, 29).hex(),
  // #463460

  GRAY7: '#111111',
  GRAY13: '#222222',
  GRAY20: '#333333',
  GRAY27: '#444444',
  GRAY26: '#414042',
  GRAY33: '#555555',
  GRAY40: '#666666',
  GRAY47: '#777777',
  GRAY49: '#7A7C7F',
  GRAY50: '#808080',
  GRAY60: '#999999',
  GRAY65: '#AAAAAA',
  GRAY70: '#b3b3b3',
  GRAY80: '#cccccc',
  GRAY81: '#CCCCCE',
  GRAY87: '#dddddd',
  GRAY91: '#e9e9e9',
  GRAY93: '#eeeeee',
  GRAY95: '#f3f3f3',
  GRAY97: '#fbfbfc',
  GRAY98: '#f9f9f9',
  DURPLE: '#452b5b',
  DARKDURPLE: '#5C4D6E',
  EMERALDGREEN60: '#AAD19B',
  WHITE: '#ffffff',
  BLACK: '#000000'
};