import { important } from 'polished';
export const hideScrollbarsInSafari = {
  '@supports selector(::-webkit-scrollbar)': {
    '&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-corner, &::-webkit-scrollbar-thumb': {
      background: 'transparent'
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: 'var(--scrollbarThumbColor)'
    },
    '&:hover': {
      '--fix': "''"
    } // This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari.
  }
};
export const stableVerticalScrollbarGutters = {
  scrollbarGutter: 'stable',
  // Safari doesn't support scrollbar-gutter but we can safely use overflowY: 'scroll' to always
  // display a scrollbar since we make its background transparent with global styles.
  '@supports not (scrollbar-gutter: stable)': {
    ...important({
      overflowY: 'scroll'
    })
  }
};