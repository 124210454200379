import { useFinishActiveTransaction } from './useFinishActiveTransaction';
export const useGqlActiveTransaction = _ref => {
  let {
    isInitialDataFromCache,
    isLoading,
    tags
  } = _ref;
  useFinishActiveTransaction({
    shouldFinishTransaction: !isLoading,
    tags: {
      'A.cacheHit': isInitialDataFromCache ? 'true' : 'false',
      ...tags
    }
  });
};