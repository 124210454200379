export const PRODUCT_FEATURES = {
  ACTIVE_SEATS_CREATE: 'active-seats-create',
  GOOGLE_SHEETS: 'integration-gsheets',
  LABS: 'labs',
  MEMBERS_CREATE: 'members-create',
  MILESTONE_CREATE: 'milestone-create',
  OBSERVERS_CREATE: 'observers-create',
  REPORTS_PAGE_ADVANCED: 'reports-page-advanced',
  REPORTS_PAGE_BASIC: 'reports-page-basic',
  ROADMAP: 'roadmap',
  ROLE_CREATE_OBSERVER: 'role-create-observer',
  TEAMS_CREATE: 'teams-create',
  WORKFLOWS_CREATE: 'workflows-create',
  WORKSPACES_CREATE: 'workspaces-create'
};