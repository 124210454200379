import { StoryTypes } from '@clubhouse/shared/types';
import { isNew, setType } from 'data/entity/story';
import * as AddNew from 'utils/addNew';
import { formatStoryTypes } from '../../formatters/storyType';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryType = story => {
  lazilyLoadArgumentChoices('storyTypesForCurrentStory', () => getStoryTypesForCurrentStory(story));
  lazilyLoadArgumentChoices('currentStoryTypeForStory', () => getCurrentStoryTypeForStory(story));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryType', updateStoryType(story));
  }
};
const getStoryTypesForCurrentStory = story => {
  if (!story) return [];
  return formatStoryTypes([StoryTypes.FEATURE, StoryTypes.BUG, StoryTypes.CHORE]);
};
const getCurrentStoryTypeForStory = story => {
  if (!story) return null;
  return getStoryTypesForCurrentStory(story).find(storyType => storyType.value === story.story_type);
};
const updateStoryType = story => _ref => {
  let {
    storyType
  } = _ref;
  if (isNew(story)) {
    AddNew.updateStoryType(storyType.value);
  } else {
    setType(story, storyType.value);
  }
};