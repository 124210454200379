import * as StoryData from 'data/entity/story';
import { getStoryState, isOpen } from 'utils/addNew';
import { getCurrentlyDisplayedStory } from 'utils/storyDialog';
export const getCurrentStory = () => {
  const currentStory = (() => {
    if (isOpen()) {
      return getStoryState();
    }
    return getCurrentlyDisplayedStory();
  })();
  if (!currentStory) {
    return null;
  }
  return StoryData.getStoryFull({
    story: currentStory
  });
};