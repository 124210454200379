import WebhookModel from 'app/client/core/js/models/webhook';
import { useEntity } from './collection';
import { ApplicationIds, fetchByApplicationId, getInstallation } from './installation';
export const fetchAll = () => WebhookModel.Promises.fetchAll();
export const useWebhook = id => useEntity({
  model: WebhookModel,
  id
}).entity ?? null;
export const useBitbucket = () => useEntity({
  model: WebhookModel,
  id: getBitbucket()?.id
}).entity ?? null;
export const enableWebhook = webhook => WebhookModel.Promises.enableWebhook(webhook);
export const disableWebhook = webhook => WebhookModel.Promises.disableWebhook(webhook);
export const getBitbucket = () => WebhookModel.getBitbucket();
export const getOrCreateBitbucket = async () => {
  let webhook = getBitbucket();
  if (!webhook) {
    try {
      await WebhookModel.Promises.create({
        name: 'Bitbucket',
        type: 'bitbucket'
      });
      await fetchByApplicationId(ApplicationIds.Bitbucket);
      webhook = getBitbucket();
      if (webhook && !getInstallation(ApplicationIds.Bitbucket)) await disableWebhook(webhook);
    } catch (err) {
      return null;
    }
  }
  return webhook;
};
export const getCalendar = () => WebhookModel.getCalendar();
export const useCalendar = () => useEntity({
  model: WebhookModel,
  id: getCalendar()?.id
}).entity ?? null;
export const getCalendarUrl = () => WebhookModel.getCalendarURL();
export const getOrCreateCalendar = async () => {
  let webhook = getCalendar();
  if (!webhook) {
    try {
      await WebhookModel.Promises.create({
        name: 'Calendar',
        type: 'ical'
      });
      webhook = getCalendar();
      if (webhook) await disableWebhook(webhook);
    } catch (err) {
      return null;
    }
  }
  return webhook;
};