/* eslint-disable no-restricted-properties */
export const CACHE_PREFIX = 'apollo-cache';
const supportsIndexedDb = () => !!window.indexedDB && 'deleteDatabase' in window.indexedDB && 'databases' in window.indexedDB;
const deleteIndexedDatabases = async () => {
  if (!supportsIndexedDb()) return;
  const databases = await window.indexedDB.databases();
  await Promise.allSettled(databases.filter(_ref => {
    let {
      name
    } = _ref;
    return !!name && name.includes(CACHE_PREFIX);
  }).map(_ref2 => {
    let {
      name
    } = _ref2;
    return name ? indexedDB.deleteDatabase(name) : Promise.resolve();
  }));
};
const deleteLocalStorageCache = async () => {
  const storage = window.localStorage;
  if (!storage) return;
  for (let i = 0; i < storage.length; ++i) {
    const key = storage.key(i);
    if (key && key.includes(CACHE_PREFIX)) storage.removeItem(key);
  }
};
export const clearPersistentCache = async () => {
  await Promise.allSettled([deleteIndexedDatabases(), deleteLocalStorageCache()]);
};
/* eslint-enable no-restricted-properties */