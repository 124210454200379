import { PAGE_NAMES, generatePathWithSlug, getCurrentOrigin, getPathForPage } from 'utils/navigation';
export const getHref = function (id, keyResultId) {
  let includeDomain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (includeDomain ? getCurrentOrigin() : '') + generatePathWithSlug(getPathForPage(PAGE_NAMES.KEY_RESULT_MODAL), {
    id,
    keyResultId
  });
};
export const getObjectivesHref = function (keyResultId, params) {
  let includeDomain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (includeDomain ? getCurrentOrigin() : '') + generatePathWithSlug(getPathForPage(PAGE_NAMES.OBJECTIVES_WITH_KEY_RESULT), {
    keyResultId
  }) + params;
};