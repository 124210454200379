import { useCallback, useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useToggleState } from '@clubhouse/shared/hooks';
import { DragAndDropContext } from './DragAndDropContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function DragAndDropContainer(_ref) {
  let {
    children,
    onBeforeDragStart,
    onDragStart,
    onDragEnd
  } = _ref;
  const [isDragging, {
    on: startDragging,
    off: stopDragging
  }] = useToggleState();
  const handleDragEnd = useCallback((result, provided) => {
    stopDragging();
    onDragEnd?.(result, provided);
  }, [onDragEnd, stopDragging]);
  const contextState = useMemo(() => ({
    isDragging
  }), [isDragging]);
  return _jsx(DragDropContext, {
    onBeforeDragStart: onBeforeDragStart,
    onDragStart: onDragStart,
    onBeforeCapture: startDragging,
    onDragEnd: handleDragEnd,
    children: _jsx(DragAndDropContext.Provider, {
      value: contextState,
      children: children
    })
  });
}
DragAndDropContainer.displayName = "DragAndDropContainer";