import { useActiveElementId, useComboboxEvents, useComboboxId, useInteractionMode } from './ComboboxProvider';
import { updateActiveElement } from './utils';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ComboboxInteractions = _ref => {
  let {
    children
  } = _ref;
  const [activeElementId, setActiveElementId] = useActiveElementId();
  const interactionModeRef = useInteractionMode();
  const menuId = useComboboxId();
  const sendEvent = useComboboxEvents(_ref2 => {
    let {
      type,
      payload
    } = _ref2;
    switch (type) {
      case 'keyboard':
        {
          interactionModeRef.current = 'keyboard';
          switch (payload.action) {
            case 'Escape':
            case 'Tab':
              sendEvent({
                type: 'close'
              });
              break;
            case 'Enter':
              {
                const el = activeElementId ? document.getElementById(activeElementId) : null;
                if (el) {
                  const type = el.dataset.type ?? 'select';
                  const value = el.dataset.value;
                  if ((type === 'select' || type === 'create') && value) sendEvent({
                    type,
                    payload: value
                  });
                } else if (payload.value?.trim()) sendEvent({
                  type: 'create',
                  payload: payload.value
                });
                break;
              }
            case 'ArrowUp':
              setActiveElementId(updateActiveElement(menuId, 'previous'));
              break;
            case 'ArrowDown':
              setActiveElementId(updateActiveElement(menuId, 'next'));
              break;
          }
          break;
        }
      case 'mouse':
        {
          switch (payload.action) {
            case 'enter-menu':
              interactionModeRef.current = 'mouse';
              break;
            case 'leave-menu':
              setActiveElementId(null);
              break;
            case 'enter-item':
              if (interactionModeRef.current === 'mouse' && payload.elementId !== activeElementId) setActiveElementId(payload.elementId ?? null);
              break;
            case 'leave-item':
              // Do nothing for now.
              break;
          }
          break;
        }
      case 'close':
        setActiveElementId(null);
        break;
    }
  });
  return _jsx(_Fragment, {
    children: children
  });
};