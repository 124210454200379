import * as Emoji from '@clubhouse/shared/utils/emoji';
import AssetManifest from 'static/manifest/asset-manifest.json';
const exports = {};
exports.init = () => {
  Emoji.init(`${AssetManifest.emojiPath}/64/`);
};
exports.setAsSingleImage = () => Emoji.setAsSingleImage(`${AssetManifest.emojiPath}/64/`);
exports.setAsSprite = Emoji.setAsSprite;
exports.mapOldToneToNew = shortname => {
  const parts = shortname.split('_tone');
  const toneComponent = parts[1];
  let adjustment = '';
  if (toneComponent) {
    const tonevalue = toneComponent.slice(0, 1);
    const remaining = toneComponent.slice(1, toneComponent.length);
    adjustment = '::skin-tone-' + (Number.parseInt(tonevalue, 10) + 1) + remaining;
  }
  return parts[0] + adjustment;
};
export { exports as default };