import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import * as Integrations from 'app/client/core/js/modules/integrationConfiguration';
import IntegrationsController from 'app/client/integrations/js/controllers/integrations';
export const openIntegrationModal = () => IntegrationsController.open();
export const openIntegration = id => {
  switch (id) {
    case 'gsheets':
      Integrations.getIntegration(INTEGRATION_TYPE.GOOGLE_SHEETS).openHandler();
      break;
    default:
      throw new Error(`No controller has been associated with ${id} integrations yet`);
  }
};
export const getIntegrationsForPlan = () => Integrations.getIntegrationsForPlan();
export const isVCSEnabled = () => IntegrationsController.isVCSEnabled();
export const isCalendarEnabled = () => Boolean(IntegrationsController.isCalendarEnabled());

/**  Fastbinder made me do it!  */