import * as FeedbackTemplate from 'app/client/core/views/templates/feedback.html';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import DropdownController from './dropdown';
import FlashController from './flash';
import Backend from '../modules/backend';
import localStorage from '../modules/localStorage';
import Log from '../modules/log';
import SideDrawer from '../modules/sideDrawer';
import Tooltip from '../modules/tooltip';
import Url from '../modules/url';
import User from '../modules/user';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
const exports = {};
export const SNAPSHOT_KEY = 'helpDialogContent';
const ACCESS_GRANT_MSG = `

I grant permission to ${BRAND.NAME} to temporarily access my ${BRAND.NAME} data to investigate this issue.`;
const TEMPORARY_ERROR_MESSAGE = 'This is probably a temporary server or network problem. In the meantime, please email us directly at ' + `<a href="mailto:${BRAND.SUPPORT_EMAIL}">${BRAND.SUPPORT_EMAIL}</a>.`;
exports.title = `Help and Feedback | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/feedback';
};
exports.open = () => {
  DropdownController.closeAll();
  SideDrawer.render({
    route: exports.route,
    pageTitle: exports.title,
    innerHTML: FeedbackTemplate.render({
      me: User.getCurrentUser(),
      snapshot: _getSnapshot()
    }),
    title: 'Talk to us!',
    description: 'Have any ideas, troubles or questions?',
    width: 'var(--sideDrawerWidthSmall)',
    onOpen: () => {
      Tooltip.close();
      Validator.init('#help-feedback-modal');
    },
    onClose: () => {
      Validator.destroy('#help-feedback-modal');
    },
    breadcrumbs: [{
      text: 'Help',
      onClick: () => {
        Validator.destroy('#help-feedback-modal');
        SideDrawer.close();
        toggleHelpHub();
      }
    }, {
      text: 'Talk to us'
    }]
  });
  return false;
};
function _setSnapshot(subject, body, permission) {
  localStorage.set(SNAPSHOT_KEY, JSON.stringify({
    subject,
    body,
    permission
  }));
}
function _getSnapshot() {
  const snapshot = localStorage.get(SNAPSHOT_KEY) || null;
  try {
    return snapshot ? JSON.parse(snapshot) : {};
  } catch {
    return {};
  }
}
function _clearSnapshot() {
  localStorage.remove(SNAPSHOT_KEY);
}

// ----- Actions -----

exports.updateSnapshot = () => {
  const subject = $('#your-subject').val().trim();
  const body = $('#your-message').val().trim();
  const permission = $('#grant-access-checkbox a.checked').length === 1;
  _setSnapshot(subject, body, permission);
};
exports.onKeyDown = e => {
  if (Utils.keyPressed(e, ['CMD+ENTER', 'CTRL+ENTER'])) {
    // Needed to prevent ENTER keypress from bubbling.
    setTimeout(exports.submit, 50);
    return false;
  }
};
exports.submit = () => {
  $(this).prop('readonly', true);
  const context = $('#help-feedback-modal');
  if (Validator.isValid(context)) {
    const button = context.find('.send-message-button');
    const data = Utils.formData(context);
    const oldHTML = button.html();
    const snapshot = _getSnapshot();
    button.html('<span class="fa fa-spin fa-star"></span> Saving...').addClass('disabled').attr('disabled', 'disabled');
    Backend.post('/api/private/support', {
      data: {
        subject: data.subject,
        body: snapshot.permission ? data.body + ACCESS_GRANT_MSG : data.body
      },
      onError: err => {
        Log.error(new Error('Failed to submit feedback'), {
          errorInfo: JSON.stringify(err)
        });
        FlashController.error(context, 'Unable to submit form.', TEMPORARY_ERROR_MESSAGE);
        button.html(oldHTML).removeClass('disabled').removeAttr('disabled');
      },
      onSuccess: () => {
        const message = "Thanks for reaching out. You'll get a copy of your message in your inbox as well as a response from the team soon.";
        FlashController.success(context, 'We got your message!', message);
        _clearSnapshot();
        context.find('textarea, input').val('');
        context.find('.form-input, .form-action').slideUp(250);
        context.find('.more-help-button').show();
      }
    });
  }
  return false;
};
exports.togglePermissionCheckbox = () => {
  $('#grant-access-checkbox a').toggleClass('checked').find('.fa').toggleClass('fa-check-square').toggleClass('fa-square-o');
  exports.updateSnapshot();
  return false;
};
export { exports as default };