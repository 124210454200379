import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/sentry_logo.png';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, addToast } from '@clubhouse/shared/components/Toast';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { useToggleState } from '@clubhouse/shared/hooks';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { ApplicationIds, fetchByApplicationId, getInstallation, useInstallationByApplicationId } from 'data/entity/installation';
import { fetchAll, updateSentryProject, useSentryProjects } from 'data/entity/sentryProject';
import { isLoggedInUserAdminOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { FieldValueSelect } from './components/FieldValueSelect';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { ProjectSelect } from './components/ProjectSelect';
import { RequesterSelect } from './components/RequesterSelect';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
function useFetchSentryData() {
  const [isReady, toggleIsReady] = useToggleState();
  useEffect(() => {
    fetchByApplicationId(ApplicationIds.Sentry).then(sentry => {
      if (sentry && !sentry.disabled) {
        return fetchAll();
      }
    }).finally(toggleIsReady.on);
  }, [toggleIsReady]);
  return isReady;
}
const getExternalLink = () => {
  const link = 'https://sentry.io/settings/';
  const sentryOrgSlug = getInstallation(ApplicationIds.Sentry)?.sentry_organization_slug;
  return sentryOrgSlug ? link + sentryOrgSlug + '/integrations/' : link;
};
const saveSentryProjectChange = (slug, changes) => {
  return updateSentryProject(slug, changes).then(() => {
    addToast({
      kind: 'success',
      timeout: 5000,
      Content: () => _jsx(ToastText, {
        children: "Sentry project settings updated."
      })
    });
  }).catch(err => {
    addToast({
      kind: 'alert',
      timeout: 10000,
      Content: () => _jsxs(Spaced, {
        vertically: true,
        amount: "space1",
        children: [_jsx(ToastTitle, {
          children: "Unable to update Sentry project settings."
        }), err?.message ? _jsx(ToastText, {
          children: err.message
        }) : null]
      })
    });
  });
};
export function Sentry() {
  const isAdmin = isLoggedInUserAdminOnly();
  const isReady = useFetchSentryData();
  const installation = useInstallationByApplicationId(ApplicationIds.Sentry);
  const sentryProjects = useSentryProjects();
  const isEnabled = installation?.disabled === false;
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.SENTRY
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Sentry"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Sentry Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://sentry.io/",
          children: "Sentry"
        }), " provides open-source error tracking that helps developers monitor and fix crashes in real time."]
      }), _jsx(Layout.SectionTitle, {
        children: "Integration Status"
      }), !isReady ? _jsx(IntegrationToggle.Loading, {}) : _jsxs(_Fragment, {
        children: [_jsx(IntegrationToggle, {
          isEnabled: isEnabled
        }), _jsxs(Layout.Blurb, {
          children: ["To ", isEnabled ? 'disable' : 'enable', " this integration, go to your", ' ', _jsx(ExternalLink, {
            href: getExternalLink(),
            children: "Sentry account"
          }), ". You can also visit our", ' ', _jsx(ExternalLink, {
            href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_SENTRY,
            children: "Help Center"
          }), " for more information."]
        }), !isAdmin && _jsx(Layout.HelpMessage, {
          children: "Only admins can manage this integration."
        }), !!sentryProjects?.length && _jsx("div", {
          children: _jsxs("table", {
            className: "table",
            style: {
              border: 'none'
            },
            cellPadding: 0,
            cellSpacing: 0,
            children: [_jsx("thead", {
              children: _jsxs("tr", {
                children: [_jsx("th", {
                  children: "Sentry Project"
                }), isProjectsFeatureEnabled() && _jsxs("th", {
                  children: [BRAND.NAME, " Project", ' ', _jsx(Tooltip, {
                    content: _jsxs(Spaced, {
                      vertically: true,
                      amount: "space2",
                      children: [_jsxs("div", {
                        children: ["Set the default ", BRAND.NAME, " Project for Stories created from the Sentry Project."]
                      }), _jsx("div", {
                        children: "This field is optional."
                      })]
                    }),
                    children: _jsx("span", {
                      className: "fa fa-question-circle-o"
                    })
                  })]
                }), areCustomFieldsEnabled() && _jsxs("th", {
                  children: ["Field Value", ' ', _jsx(Tooltip, {
                    content: _jsxs(Spaced, {
                      vertically: true,
                      amount: "space2",
                      children: [_jsx("div", {
                        children: "Set the default Field and Value for Stories created from the Sentry Project."
                      }), _jsx("div", {
                        children: "This field is optional."
                      })]
                    }),
                    children: _jsx("span", {
                      className: "fa fa-question-circle-o"
                    })
                  })]
                }), _jsxs("th", {
                  children: ["Requester", ' ', _jsx(Tooltip, {
                    content: _jsxs(Spaced, {
                      vertically: true,
                      amount: "space2",
                      children: [_jsx("div", {
                        children: "Set the default Requester for Stories created from the Sentry Project."
                      }), _jsx("div", {
                        children: "This field is optional."
                      })]
                    }),
                    children: _jsx("span", {
                      className: "fa fa-question-circle-o"
                    })
                  })]
                })]
              })
            }), _jsx("tbody", {
              children: sentryProjects.map(_ref => {
                let {
                  sentry_project_slug,
                  project,
                  requester,
                  fieldValue,
                  field_id,
                  value_id
                } = _ref;
                return _jsxs("tr", {
                  children: [_jsx("td", {
                    children: sentry_project_slug
                  }), isProjectsFeatureEnabled() && _jsx("td", {
                    children: _jsx(ProjectSelect, {
                      isDisabled: !isAdmin,
                      project: project ?? null,
                      onChange: selectedValue => {
                        if (!selectedValue && !project) return;
                        saveSentryProjectChange(sentry_project_slug, {
                          project_id: selectedValue
                        });
                      }
                    })
                  }), areCustomFieldsEnabled() && _jsx("td", {
                    children: _jsx(FieldValueSelect, {
                      isDisabled: !isAdmin,
                      label: fieldValue?.value,
                      selectedValue: fieldValue ? `${field_id}:${value_id}` : undefined,
                      onChange: _ref2 => {
                        let [selectedValue] = _ref2;
                        const isNoneValue = selectedValue === 'none';
                        if (isNoneValue && !fieldValue) return;
                        const [field_id = null, value_id = null] = isNoneValue ? [] : selectedValue.split(':');
                        saveSentryProjectChange(sentry_project_slug, {
                          field_id,
                          value_id
                        });
                      }
                    })
                  }), _jsx("td", {
                    children: _jsx(RequesterSelect, {
                      isDisabled: !isAdmin,
                      label: requester?.name,
                      selectedValue: requester?.id,
                      onChange: _ref3 => {
                        let [selectedValue] = _ref3;
                        if (!selectedValue && !requester) return;
                        saveSentryProjectChange(sentry_project_slug, {
                          permission_id: selectedValue ?? null
                        });
                      }
                    })
                  })]
                }, sentry_project_slug);
              })
            })]
          })
        })]
      })]
    })]
  });
}
Sentry.displayName = "Sentry";