import { useMemo } from 'react';
import { isModalPath } from './paths';
const isValidBackgroundLocation = pathname => {
  if (isModalPath(pathname)) {
    return false;
  }
  return true;
};
export const createBackgroundLocationState = function () {
  let {
    pathname,
    href,
    search
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.history.state?.backgroundLocation ?? window.location;
  if (!isValidBackgroundLocation(pathname)) {
    return {
      backgroundLocation: undefined
    };
  }
  return {
    backgroundLocation: {
      title: document.title,
      pathname,
      href,
      search
    }
  };
};
export const getCurrentBackgroundLocationState = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.history.state;
  const {
    backgroundLocation
  } = state ?? {};
  return {
    backgroundLocation
  };
};
const clearCurrentBackgroundLocationState = () => {
  if (history.state?.backgroundLocation) {
    delete history.state.backgroundLocation;
  }
};
export const useClearBackgroundLocationStateOnPageRefresh = () => {
  useMemo(() => {
    clearCurrentBackgroundLocationState();
  }, []);
};