import { useEffect } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
export const useWait = ms => {
  const [state, toggle] = useToggleState();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Don't restart timer even if `ms` were to change
  useEffect(() => {
    const timeoutId = setTimeout(toggle.on, ms);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return state;
};