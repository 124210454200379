import { Icon } from '@useshortcut/shapes-ds';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderComponent = () => _jsx(Icon, {
  icon: "Handle",
  fill: "currentColor"
});
HeaderComponent.displayName = "HeaderComponent";
export const PositionField = () => ({
  name: 'position',
  displayName: 'Epic Priority',
  sort: true,
  HeaderComponent,
  LoadingComponent: () => _jsx("div", {
    style: {
      padding: '0 5px'
    },
    children: _jsx(SkeletonRectangle, {
      width: 13
    })
  }),
  headerProps: {
    centered: true
  },
  minWidth: 50,
  width: 50,
  frozen: {
    left: -25
  }
});