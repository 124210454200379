import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
const stackVar = makeVar([]);
const pushToStack = layer => {
  stackVar([layer, ...stackVar()]);
};
const removeFromStack = id => {
  stackVar(stackVar().filter(item => item.id !== id));
};
const openNewLayer = function (id) {
  let {
    mouse = true,
    keyboard = true
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  pushToStack({
    id,
    mouse,
    keyboard
  });
  return () => {
    removeFromStack(id);
  };
};
export const useIsTopLayerForInput = id => {
  let keyboard = null;
  let mouse = null;
  const stack = useReactiveVar(stackVar);
  for (let i = 0; i < stack.length; ++i) {
    if (keyboard === null && stack[i].keyboard) keyboard = stack[i].id === id;
    if (mouse === null && stack[i].mouse) mouse = stack[i].id === id;
    if (keyboard !== null && mouse !== null) break;
  }
  return {
    keyboard: !!keyboard,
    mouse: !!mouse
  };
};
export const useIsTopLayer = id => {
  const {
    mouse,
    keyboard
  } = useIsTopLayerForInput(id);
  return mouse || keyboard;
};
export const useFloatingStack = function (id) {
  let {
    enabled = true,
    mouse = true,
    keyboard = true
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  useEffect(() => {
    if (!enabled) return;
    return openNewLayer(id, {
      mouse,
      keyboard
    });
  }, [enabled, id, keyboard, mouse]);
  return useIsTopLayerForInput(id);
};