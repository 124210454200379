import { useEffect } from 'react';
import { insertControls } from 'utils/codeBlock';
import { renderMermaid } from 'utils/mermaid';
import { highlightElement } from 'utils/syntaxHighlight';
export const useCodeBlock = el => {
  useEffect(() => {
    if (el) {
      insertControls(el);
      highlightElement(el);
      renderMermaid(el);
    }
  }, [el]);
};