import CardModel from './card';
import Backend from '../modules/backend';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import OrganizationModel from './organization';
import PaymentPlan2Model from './paymentPlan2';
import UserModel from './user';
const exports = {};
exports.getCurrentOrg = () => {
  return OrganizationModel.get({
    id: exports.getCurrentOrgId()
  });
};
exports.getCurrentOrgId = () => {
  return Globals.get('organizationID');
};
exports.updateState = state => {
  state = state || {};
  if (state.id) {
    Globals.set('organizationID', state.id);
  }
};
exports.clearState = () => {
  Globals.set('organizationID', null);
};

// UserModel.fetchAll
exports.fetchAllUsers = (org, callback) => {
  let endpoint = '/api/private/members';
  if (Is.owner(UserModel.getPermissionFromOrg(org.id))) {
    endpoint = '/api/private/owner/workspaces2/' + org.id + '/members';
  }
  exports.updateState({
    id: org.id
  });
  Backend.get(endpoint, {
    onComplete: res => {
      exports.updateState({
        id: org.id
      });
      UserModel.fetchAllHandler(res, function () {
        exports.clearState();
        callback.apply(this, arguments);
      });
    }
  });
};
exports.fetchPaymentPlan2 = (org, callback) => {
  let endpoint = '/api/private/payment-plan-2';
  if (Is.owner(UserModel.getPermissionFromOrg(org.id))) {
    endpoint = '/api/private/owner/payment-plan-2';
  }
  exports.updateState({
    id: org.id
  });
  Backend.get(endpoint, {
    onComplete: res => {
      exports.updateState({
        id: org.id
      });
      PaymentPlan2Model.getHandler(res, function () {
        exports.clearState();
        callback.apply(this, arguments);
      });
    },
    cache: false
  });
};

// CardModel.fetchAll
exports.fetchAllCards = (org, callback) => {
  exports.updateState({
    id: org.id
  });
  Backend.get('/api/private/owner/cards', {
    onComplete: res => {
      exports.updateState({
        id: org.id
      });
      CardModel.defaultFetchAllHandler(res, function () {
        exports.clearState();
        callback.apply(this, arguments);
      });
    }
  });
};
export { exports as default };