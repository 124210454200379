import { TableRow, TableRowForInfiniteScroll } from '@clubhouse/shared/components/Table';
import { useCollectionizeStoryContextMenu } from 'utils/contextMenus/useCollectionizeStoryContextMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function StoryRow(props) {
  const contextMenuProps = useCollectionizeStoryContextMenu(props.item.publicId);
  return _jsx(TableRow, {
    ...props,
    contextMenuProps: contextMenuProps
  });
}
StoryRow.displayName = "StoryRow";
export function StoryRowForInfiniteScroll(props) {
  const contextMenuProps = useCollectionizeStoryContextMenu(props.item.publicId);
  return _jsx(TableRowForInfiniteScroll, {
    ...props,
    contextMenuProps: contextMenuProps
  });
}
StoryRowForInfiniteScroll.displayName = "StoryRowForInfiniteScroll";