import "core-js/modules/es.array.push.js";
import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html';
import * as Event from '../_frontloader/event';
import StoryModel from '../models/story';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};
exports.lookups = [];
exports.notFound = [];
exports.add = id => {
  if (_.includes(exports.lookups, id) || _.includes(exports.notFound, id)) {
    return false;
  }
  exports.lookups.push(id);
  exports.fetchStories();
};
exports.fetchStories = _.debounce(() => {
  StoryModel.getStoriesById(exports.lookups, exports.rerenderAfterLookup);
  exports.lookups = [];
}, 0);
exports.rerenderAfterLookup = (err, ids_not_found, stories_found) => {
  if (err) {
    return;
  }
  if (ids_not_found) {
    exports.notFound = exports.notFound.concat(ids_not_found);
  }
  if (stories_found) {
    Event.trigger('storiesLookedUp');
    exports.redrawLookedUpStories();
  }
};
exports.redrawLookedUpStories = () => {
  $('[data-story-lookup]').each((i, el) => {
    const id = Utils.data(el, 'id');
    const story = StoryModel.getById(id);
    if (story) {
      const href = Utils.data(el, 'href');
      View.replaceElement(el, StoryAutoLinkTemplate.render({
        ...story,
        href
      }));
    }
  });
};
export { exports as default };