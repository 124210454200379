import { BILLING_CYCLE_TYPES } from '@clubhouse/shared/types/';
import { getPlanPricePerSeat, useCurrentPlan, usePrices } from 'data/entity/paymentPlan2';
import { toMoney } from 'utils/format';
import { TIERS } from 'utils/tiers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PlanPrice = _ref => {
  let {
    tier,
    frequency = BILLING_CYCLE_TYPES.ANNUAL
  } = _ref;
  const {
    paymentPlan: plan
  } = useCurrentPlan();
  const {
    prices
  } = usePrices(plan) || {};
  const price = getPlanPricePerSeat({
    prices,
    tier,
    frequency,
    basePrice: true
  });
  return _jsx("span", {
    children: tier === TIERS.ENTERPRISE ? 'Contact Sales' : `$${toMoney(price)}`
  });
};
PlanPrice.displayName = "PlanPrice";