import { EpicMoreActionsDataFragmentFragmentDoc } from "../../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { MoreActions as MoreActionsRoot } from '@clubhouse/shared/components/MoreActions';
import { useAsyncSelect } from '@clubhouse/shared/components/Select';
import { useUpdateEpicState } from 'components/gql/epicState/useUpdateEpicState';
import { ArchiveEpicAction } from 'components/gql/epics/actions/ArchiveEpicAction';
import { DeleteEpicAction } from 'components/gql/epics/actions/DeleteEpicAction';
import { DuplicateEpicAction } from 'components/gql/epics/actions/DuplicateEpicAction';
import { useWorkspaceEpicStatesAsyncSelectQuery } from 'pages/epics-apollo/entities/epicStates/hooks/useWorkspaceEpicStatesAsyncSelectQuery';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicMoreActionsDataFragment = EpicMoreActionsDataFragmentFragmentDoc;
export const MoreActions = _ref => {
  let {
    epic,
    onChangeEpicState
  } = _ref;
  const fetchEpicStates = useWorkspaceEpicStatesAsyncSelectQuery();
  const {
    sections,
    fetchSections
  } = useAsyncSelect(fetchEpicStates);
  const updateEpicState = useUpdateEpicState();
  const items = useMemo(() => {
    if (!sections) return null;
    return sections.reduce((acc, _ref2) => {
      let {
        items
      } = _ref2;
      return acc.concat(items.filter(item => String(item.value) !== String(epic.epicState.id)).map(item => ({
        ...item,
        onClick: async () => {
          if (epic.epicState.type && item.additionalData.type) {
            await updateEpicState(epic, item.additionalData);
            onChangeEpicState({
              from: epic.epicState.type,
              to: item.additionalData.type
            });
          }
        }
      })));
    }, []);
  }, [epic, onChangeEpicState, sections, updateEpicState]);
  const isLoading = items === null;
  return _jsxs(MoreActionsRoot, {
    onOpen: fetchSections,
    children: [_jsx(MoreActionsRoot.Button, {}), _jsx(MoreActionsRoot.Menu, {
      children: isLoading ? _jsxs(_Fragment, {
        children: [_jsx(MoreActionsRoot.Loading, {}), _jsx(MoreActionsRoot.Loading, {}), _jsx(MoreActionsRoot.Loading, {})]
      }) : _jsxs(_Fragment, {
        children: [(items || []).map(_ref3 => {
          let {
            value,
            Icon,
            name,
            onClick
          } = _ref3;
          return _jsxs(MoreActionsRoot.Item, {
            icon: Icon ? _jsx(Icon, {}) : null,
            onClick: onClick,
            children: ["Move To ", name]
          }, value);
        }), _jsx(MoreActionsRoot.Divider, {}), _jsx(ArchiveEpicAction, {
          epic: epic
        }), _jsx(DuplicateEpicAction, {
          epic: epic
        }), _jsx(DeleteEpicAction, {
          epic: epic
        })]
      })
    })]
  });
};
MoreActions.displayName = "MoreActions";