import Logo from '@clubhouse/assets/png/third-party-logos/firehydrant_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function FireHydrant() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.FIREHYDRANT
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "FireHydrant"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "FireHydrant Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Create or sync incidents and action items created in", ' ', _jsx(ExternalLink, {
          href: "https://firehydrant.com/integrations/shortcut/",
          children: "FireHydrant"
        }), " during an incident with Stories in Shortcut."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.TAG_INTEGRATIONS,
          children: "Learn more"
        }), " on how the FireHydrant integration works in Shortcut."]
      })]
    })]
  });
}
FireHydrant.displayName = "FireHydrant";