import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { DEFAULT_ROW_HEIGHT } from '@clubhouse/shared/components/Table/config';
import { CellWrapper } from '@clubhouse/shared/components/Table/internalComponents/CellWrapper';
import { Row } from '@clubhouse/shared/components/Table/internalComponents/Row';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DefaultSkeletonRectangle = () => _jsx(SkeletonRectangle, {
  width: "90%"
});
DefaultSkeletonRectangle.displayName = "DefaultSkeletonRectangle";
export const LoadingTableRow = _ref => {
  let {
    columns
  } = _ref;
  return _jsx(Row, {
    minHeight: DEFAULT_ROW_HEIGHT,
    isSelected: false,
    role: "row",
    children: columns.map(_ref2 => {
      let {
        name,
        width,
        minWidth,
        maxWidth,
        LoadingComponent = DefaultSkeletonRectangle
      } = _ref2;
      return _jsx(CellWrapper, {
        width: width,
        minWidth: minWidth,
        maxWidth: maxWidth,
        children: LoadingComponent ? _jsx(LoadingComponent, {}) : null
      }, name);
    })
  });
};
LoadingTableRow.displayName = "LoadingTableRow";
export const createLoadingRows = count => new Array(count).fill(null).map((_, i) => ({
  id: `loading-${i}`
}));
const SINGLE_LOADING_ROW = createLoadingRows(1);
const LOADING_ROWS = createLoadingRows(3);
export const LOADING_STATE = {
  RowComponent: LoadingTableRow,
  items: LOADING_ROWS,
  allItems: LOADING_ROWS
};
export const SINGLE_ROW_LOADING_STATE = {
  RowComponent: LoadingTableRow,
  items: SINGLE_LOADING_ROW,
  allItems: SINGLE_LOADING_ROW
};