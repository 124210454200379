export const isLeft = e => e.left !== undefined;
export const isRight = e => e.right !== undefined;
export const makeLeft = left => ({
  left
});
export const makeRight = right => ({
  right
});
export function unwrapEither(e) {
  if (isRight(e)) return e.right;else if (isLeft(e)) return e.left;else throw new Error(`Received value with neither left nor right when unwrapping Either`);
}