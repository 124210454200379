import { FIELD_ID } from 'components/shared/table/types';
import { name } from 'utils/sort';
export const NameField = function () {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: FIELD_ID.NAME,
    displayName: 'Name',
    sort: name,
    isHeader: true,
    ...overrides,
    headerProps: {
      ...overrides?.headerProps
    }
  };
};