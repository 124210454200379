import { useCallback } from 'react';
import { insertIf } from '@clubhouse/shared/utils/object';
import { useLazyQuery } from 'gql';
import { useLazyWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
export function useFieldOptionsQuery(_ref) {
  let {
    query,
    storyId,
    includeCurrentPermissionId,
    input
  } = _ref;
  const [executeQuery] = useLazyQuery(query);
  const lazyCurrentPermission = useLazyWorkspaceCurrentPermissionIdQuery();
  return useCallback(async _ref2 => {
    let {
      inputValue
    } = _ref2;
    const {
      data
    } = await executeQuery({
      variables: {
        storyId,
        input: {
          ...input,
          ...insertIf(includeCurrentPermissionId, {
            currentPermissionId: await lazyCurrentPermission()
          }),
          ...insertIf(Boolean(inputValue?.trim()), {
            fuzzyName: inputValue?.trim()
          })
        }
      }
    });
    return data?.node?.options;
  }, [executeQuery, storyId, input, includeCurrentPermissionId, lazyCurrentPermission]);
}