import * as WeAreDownTemplate from 'app/client/login/views/templates/weAreDown.html';
import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/login/less/app.less';
import { Button } from 'components/shared/DeprecatedButton';
import { Problem } from 'components/shared/Problem';
import Boot from '../../../core/js/modules/boot';
import ConfirmEmailController from '../controllers/confirmEmail';
import CreateOrgOrWorkspaceController from '../controllers/createOrgOrWorkspace';
import InviteLinkController from '../controllers/inviteLink';
import * as Event from '../../../core/js/_frontloader/event';
import Flags from '../../../core/js/modules/flags';
import InvitesController from '../controllers/invites';
import Layout from '../../../core/js/modules/layout';
import LoginController from '../controllers/login';
import RecoveryCodeFormController from '../controllers/recoveryCodeForm';
import ResetYourPasswordController from '../controllers/resetYourPassword';
import VerifyEmailController from '../controllers/verifyEmail';
import SSOController from '../controllers/sso';
import Url from '../../../core/js/modules/url';
import User from '../../../core/js/modules/user';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import { PAGE_NAMES } from 'utils/navigation/paths';
const exports = {};

// Poll every 30 seconds to check if user is logged in via another tab.
const LOGIN_POLL_INTERVAL = 30000;
const getDestination = () => '?dest=' + encodeURIComponent(Url.getCurrentPathnameWithSearchAndHash());
const _init = () => {
  if (Flags.SERVER_IS_DOWN) {
    exports.renderWeAreDown();
  } else {
    UserModel.fetchCurrentUserWithoutRedirect(() => {
      const currentPage = Url.getCurrentPage();
      if (_redirectIfAlreadyLoggedIn(currentPage)) {
        return false;
      }
      Layout.renderContent('<div id="form-container"></div>');
      if (currentPage === PAGE_NAMES.INVITES) {
        exports.onResize = exports.onInvitesResize;
        InvitesController.init();
      } else if (currentPage === 'signup' || currentPage === 'signup-new-org') {
        exports.onResize = exports.onInvitesResize;
        if (User.isLoggedIn()) {
          CreateOrgOrWorkspaceController.init();
        } else {
          // BAD USER.  You gotta be logged in to use signup without a signup ID.
          Utils.redirect('/login' + getDestination());
          return;
        }
      } else if (currentPage === 'recovery') {
        RecoveryCodeFormController.init();
      } else if (currentPage === 'reset') {
        ResetYourPasswordController.init();
      } else if (currentPage === 'sso') {
        SSOController.init();
      } else if (currentPage === 'confirm-email') {
        ConfirmEmailController.init();
      } else if (currentPage === 'invite-link') {
        InviteLinkController.init();
      } else if (currentPage === 'verify-email') {
        VerifyEmailController.init();
      } else if (currentPage === 'create-organization') {
        if (User.isLoggedIn()) {
          CreateOrgOrWorkspaceController.init();
        } else {
          // You gotta be logged in to use this page.
          Utils.redirect('/login' + getDestination());
          return;
        }
      } else {
        setInterval(_pollForLoginFromAnotherTab, LOGIN_POLL_INTERVAL);
        LoginController.init();
      }
      Event.onlyOn('resize.Login', exports.onResize);
      Event.trigger('resize');
    });
  }
};
function _pageCannotHaveAuthenticatedUser(currentPage) {
  return false === ['create-organization', 'confirm-email', 'invites', 'signup', 'signup-new-org', 'invite-link'].includes(currentPage);
}
function _redirectIfAlreadyLoggedIn(currentPage) {
  const user = User.getCurrentUser();
  if (user && _pageCannotHaveAuthenticatedUser(currentPage)) {
    LoginController.handleLoginSuccess();
    return true;
  }
}
function _pollForLoginFromAnotherTab() {
  UserModel.fetchCurrentUserWithoutRedirect(() => {
    _redirectIfAlreadyLoggedIn(Url.getCurrentPage());
  });
}
exports.renderWeAreDown = () => {
  const html = WeAreDownTemplate.render();
  $('#form-container').html(html);
  View.renderComponentConditionally({
    containerSelector: '#we-are-down',
    component: Problem,
    props: {
      height: '40vh',
      title: `${BRAND.NAME} is Offline.`,
      icon: React.createElement('span', {
        className: 'fa fa-exclamation-triangle'
      }),
      description: React.createElement(React.Fragment, {}, ['Please refer to ', React.createElement('a', {
        href: `https://${BRAND.DOMAIN_STATUS_WEBSITE}`,
        target: '_blank'
      }, ['our status page']), ' for updates on this service incident. Thank you for your patience.']),
      buttons: React.createElement(Button, {
        text: 'Go to the Organizations page',
        href: '/organizations',
        size: Button.sizes.xsmall
      })
    }
  });
  Utils.fadePageIn();
};
exports.onInvitesResize = () => {
  View.genericResizeHandler();
};
exports.onResize = () => {
  View.genericResizeHandler();
};
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _init);
};
export { exports as default };