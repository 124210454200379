import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getIconForStoryType = _ref => {
  let {
    story_type
  } = _ref;
  switch (story_type) {
    case 'feature':
      return _ref2 => {
        let {
          width
        } = _ref2;
        return _jsx(DeprecatedIconAdapter, {
          width: width,
          fill: "var(--iconYellowColor)",
          children: _jsx(Icon, {
            icon: "Feature"
          })
        });
      };
    case 'bug':
      return _ref3 => {
        let {
          width
        } = _ref3;
        return _jsx(DeprecatedIconAdapter, {
          width: width,
          fill: "var(--iconRedColor)",
          children: _jsx(Icon, {
            icon: "Bug"
          })
        });
      };
    case 'chore':
      return _ref4 => {
        let {
          width
        } = _ref4;
        return _jsx(DeprecatedIconAdapter, {
          width: width,
          children: _jsx(Icon, {
            icon: "Chore",
            fill: "main"
          })
        });
      };
    default:
      throw new Error('Invalid story type: ' + story_type);
  }
};
export const getIconNameForStoryType = type => type === 'feature' ? 'Feature' : type === 'bug' ? 'Bug' : 'Chore';