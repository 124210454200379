import { useContext } from 'react';
import { ToastContext } from './ToastContext';

/**
 * @deprecated Import addToast directly from @clubhouse/shared/src/components/Toast/toasts.ts
 * @example
 * const { addToast } = useToast();
 * addToast({
 *   kind: 'success',
 *   timeout: 5000,
 *   Content: () => (
 *     <Spaced vertically amount="space1">
 *       <ToastTitle>Story Created!</ToastTitle>
 *       <ToastText>Good job!</ToastText>
 *     </Spaced>
 *   ),
 * });
 */
export function useToast() {
  return useContext(ToastContext);
}