import { Content } from './components/Content';
import { Header } from './components/Header';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Section = _ref => {
  let {
    children
  } = _ref;
  return _jsx(_Fragment, {
    children: children
  });
};
Section.Content = Content;
Section.Header = Header;