import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useMemo, useRef, useState } from 'react';
export const useSetState = initialData => {
  const {
    current: set
  } = useRef(initialData || new Set());
  const [, setState] = useState({});
  return useMemo(() => ({
    add(item) {
      set.add(item);
      setState({});
    },
    remove(item) {
      set.delete(item);
      setState({});
    },
    has(item) {
      return set.has(item);
    },
    clear() {
      set.clear();
      setState({});
    },
    toArray() {
      return Array.from(set);
    },
    get isEmpty() {
      return set.size === 0;
    }
  }), []);
};