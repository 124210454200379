import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="GraduationCap" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const GraduationCap = _ref => {
  let {
    fill = '#333333',
    width = '23px'
  } = _ref;
  return _jsx(DeprecatedIconAdapter, {
    fill: fill,
    width: width,
    children: _jsx(Icon, {
      icon: "GraduationCap"
    })
  });
};
GraduationCap.displayName = "GraduationCap";