const HELP_WEBSITE_BASE_URL = `https://${BRAND.DOMAIN_HELP_WEBSITE}`;
const ARTICLES = '/hc/en-us/articles';
const SEARCH = '/search?utf8=%E2%9C%93&query=';
const SECTIONS = '/hc/en-us/sections';
const CATEOGORIES = '/hc/en-us/categories';
export const KNOWLEDGE_BASE_SLUGS = {
  HELP_HOME: HELP_WEBSITE_BASE_URL,
  HELP_CENTER: `${HELP_WEBSITE_BASE_URL}/hc/en-us`,
  REPORTS_PAGE: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/115000999583`,
  POWER_BAR: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360061689571`,
  SEARCH_REPORTS: `${HELP_WEBSITE_BASE_URL}${SEARCH}reports`,
  GIT_HELPERS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360023113972`,
  PRICING_AND_BILLING: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/205917656`,
  SEARCH_OPERATORS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360000046646`,
  ARCHIVING_FAQ: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360018662831`,
  REPORTS_CALCULATION_TYPES: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360054189431`,
  INTEGRATION_GOOGLE_SHEETS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360045789091`,
  INTEGRATION_BOX: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/209819133`,
  INTEGRATION_BUGSEE: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/115003048663`,
  INTEGRATION_BUGSNAG: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360000573623`,
  INTEGRATION_CALENDAR: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/115002212403`,
  INTEGRATION_DROPBOX: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/209819123`,
  INTEGRATION_FIGMA: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360036373971`,
  INTEGRATION_GITHUB: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/204909925`,
  INTEGRATION_GITLAB: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/204909925`,
  INTEGRATION_GOOGLE_CALENDAR: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/115002212403-Setting-Up-the-Shortcut-Calendar-Integration#h_01HAMGHVX84XXE95PB5778KMPF`,
  INTEGRATION_GOOGLE_DRIVE: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/209819153`,
  INTEGRATION_HONEY_BADGER: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/115005456363`,
  INTEGRATION_INSTABUG: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/115005594686`,
  INTEGRATION_LOOM: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/27213652388500-Loom-Integration`,
  INTEGRATION_MIRO: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/15766548451092`,
  INTEGRATION_NOTION: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/15889330901652`,
  INTEGRATION_PRODUCT_BOARD: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360061591811`,
  INTEGRATION_ROLLBAR: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360000102963`,
  INTEGRATION_SENTRY: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360000398466`,
  INTEGRATION_SLACK: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/205268749`,
  INTEGRATION_STATUS_HERO: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360000103043`,
  INTEGRATION_YOUTUBE: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/27213648496916-YouTube-Integration`,
  INTEGRATION_ZAPIER: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/206093065`,
  INTEGRATION_ZENDESK: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/205268759`,
  EMAIL_NOTIFICATIONS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/205268919`,
  TWO_FACTOR_AUTH: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/206288915`,
  SPACES: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/208180646`,
  BULK_EDIT: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360044698631`,
  IMPORT_JIRA: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360045791591`,
  IMPORT_TRELLO: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360044279272`,
  MANAGE_INVITES: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360033599271`,
  FEATURE_ROADMAP: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360044695952`,
  REPORTS_CREATED_COMPLETED: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360054189851`,
  FAQ: `${HELP_WEBSITE_BASE_URL}${SECTIONS}/360003261132`,
  GUIDE_GIT: `${HELP_WEBSITE_BASE_URL}${SECTIONS}/360003734811`,
  GUIDE_VCS: `${HELP_WEBSITE_BASE_URL}${SECTIONS}/360003734811`,
  GUIDE_WORKFLOW_STATES: `${HELP_WEBSITE_BASE_URL}${SECTIONS}/360002459451`,
  TAG_INTEGRATIONS: `${HELP_WEBSITE_BASE_URL}${CATEOGORIES}/201172283`,
  REQUEST_NEW_DOCUMENT: `${HELP_WEBSITE_BASE_URL}/hc/en-us/requests/new`,
  COMMUNITY_FORUM: `${HELP_WEBSITE_BASE_URL}/hc/en-us/community/topics`,
  PUBLIC_DOCS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/12436413157780`,
  TEAMS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/360057862792`,
  CUSTOM_FIELDS: `${HELP_WEBSITE_BASE_URL}${ARTICLES}/4463938120724`
};