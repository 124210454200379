import { hasPendingTierDowngrade } from 'data/entity/paymentPlan2';

// Plan Statuses are a sort of state machine intent, basically the plan goes through different
//  states based on if the plan is on a trial or is changing tiers. This captures the different
//  parts of the plan lifecycle
export const PLAN_STATUSES = {
  PLAN_TRIALING_AND_NO_SELECTION: 'PLAN_TRIALING_AND_NO_SELECTION',
  PLAN_TRIALING_AND_HAS_SELECTION: 'PLAN_TRIALING_AND_HAS_SELECTION',
  PLAN_TRIALING_AND_IS_CHANGING_SELECTION: 'PLAN_TRIALING_AND_IS_CHANGING_SELECTION',
  PLAN_HAS_SELECTION: 'PLAN_HAS_SELECTION',
  PLAN_IS_CHANGING_SELECTION: 'PLAN_IS_CHANGING_SELECTION'
};

/**
 * Determines what the plan state is for a given plan and its product.
 * The plan state is a complicated dance of what state the current plan is in
 * and which states are upcoming for the plan.  Downgrades, pending tier changes, etc,
 * all are accounted for in this function.
 *
 *  The currentProduct MUST be associated with the plan given or you're gonna
 *  have a bad time.
 */
export const getPlanState = (plan, currentProduct) => {
  const pendingProduct = hasPendingTierDowngrade(plan);
  if ('trialing' in plan.statuses) {
    if (plan.statuses.trialing?.has_selected_tier) {
      return PLAN_STATUSES.PLAN_TRIALING_AND_HAS_SELECTION;
    }
    if (pendingProduct) {
      return PLAN_STATUSES.PLAN_TRIALING_AND_IS_CHANGING_SELECTION;
    }
    return PLAN_STATUSES.PLAN_TRIALING_AND_NO_SELECTION;
  }
  if (pendingProduct) {
    return PLAN_STATUSES.PLAN_IS_CHANGING_SELECTION;
  }

  // weird thought -- is it POSSIBLE for a currentProduct to be null or undefined? :thinking-face:
  // Answer: Yes - found out this may be the case for a currently disabled org, i.e. currentProduct is undefined.
  if (currentProduct) {
    return PLAN_STATUSES.PLAN_HAS_SELECTION;
  }
  return PLAN_STATUSES.PLAN_TRIALING_AND_NO_SELECTION;
};