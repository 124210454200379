import { Icon } from '@useshortcut/shapes-ds';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DetailPageSidebarAction, DetailPageSidebarRow, SecondaryActionButton } from 'components/shared/DetailPageSidebar';
import { currentUserIsFollower } from 'data/entity/epic';
import { EmptyValue } from './EmptyValue';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function EpicFollowers(_ref) {
  let {
    epic
  } = _ref;
  const isFollower = currentUserIsFollower(epic);
  const numFollowers = epic.follower_ids?.length ?? 0;
  const tooltipText = `${isFollower ? 'Unfollow' : 'Follow'} this Epic`;
  return _jsx(DetailPageSidebarRow, {
    label: "Followers",
    "data-perma-id": "epic-followers-field",
    children: _jsx(DetailPageSidebarAction, {
      icon: "User",
      id: "cid-followers-field",
      "data-on-click": "openEpicFollowerUpdater",
      secondarySlot: _jsx(Tooltip, {
        content: tooltipText,
        children: _jsx(SecondaryActionButton, {
          "data-on-click": isFollower ? 'removeMeAsFollower' : 'addMeAsFollower',
          children: _jsx(Icon, {
            icon: isFollower ? 'Remove' : 'Add',
            fill: "main",
            label: tooltipText
          })
        })
      }),
      children: numFollowers === 0 ? _jsx(EmptyValue, {
        children: "Nobody"
      }) : isFollower ? numFollowers === 1 ? 'Just You' : `You + ${numFollowers - 1}` : numFollowers
    })
  });
}
EpicFollowers.displayName = "EpicFollowers";