import { useEffect } from 'react';
import { useContextMenuForCaveman } from 'utils/contextMenus/useContextMenuForCaveman';
export function DashboardActivityContextMenu() {
  const contextMenuProps = useContextMenuForCaveman();
  const id = contextMenuProps['data-cm'];
  useEffect(() => {
    document.querySelector('.dashboard-activity-feed')?.setAttribute('data-cm', id);
    return () => {
      document.querySelector('.dashboard-activity-feed')?.removeAttribute('data-cm');
    };
  });
  return null;
}