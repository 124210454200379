import { TooltipTitle } from '@clubhouse/shared/components/Tooltip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Name = _ref => {
  let {
    children
  } = _ref;
  return _jsx(TooltipTitle, {
    children: children
  });
};
Name.displayName = "Name";