import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { sanitize } from '@clubhouse/shared/utils';
import { deleteEpic, unarchiveEpic } from 'data/entity/epic';
import { EntityPage } from 'pages/_components/EntityPage';
import { useEntityConfirmationDialog } from 'pages/_components/EntityPage/hooks/useEntityConfirmationDialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ArchivedMessageBar = _ref => {
  let {
    epic
  } = _ref;
  const {
    openDialog,
    closeDialog
  } = useEntityConfirmationDialog();
  return _jsx(EntityPage.ArchivedMessageBar, {
    name: sanitize(epic.name),
    onDelete: () => deleteEpic(epic, closeDialog),
    onUnarchive: () => unarchiveEpic(epic, err => {
      if (err) {
        addToast({
          kind: 'alert',
          Content: () => _jsxs(ToastText, {
            children: ["Unable to unarchive ", _jsx("strong", {
              children: sanitize(epic.name)
            }), "."]
          }),
          timeout: 5000
        });
      } else {
        addToast({
          kind: 'success',
          Content: () => _jsxs(ToastText, {
            children: ["Epic ", _jsx("strong", {
              children: sanitize(epic.name)
            }), " unarchived."]
          }),
          timeout: 5000
        });
      }
    }),
    openDeletionConfirmationDialog: openDialog,
    children: "Archived Epics and its Stories are excluded from reports and charts, as well as from the Stories, Status, and My Work pages. Archived Epics can still be viewed directly using their permalink."
  });
};
ArchivedMessageBar.displayName = "ArchivedMessageBar";