import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DeadlineChip = () => {
  const story = useContext(StoryContext);
  if (!story || !story.deadline) return null;
  return _jsx(StoryCardInternal.Chips.DeadlineChip, {
    completedAt: story.completed_at,
    deadline: story.deadline
  });
};
DeadlineChip.displayName = "DeadlineChip";