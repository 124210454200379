// This is a copy/paste from react-use: https://github.com/streamich/react-use/blob/master/src/useLocation.ts, because react-use will bind event listeners in useEffect, which is too late.
// The react-use license is public domain: https://github.com/streamich/react-use/blob/master/LICENSE
import { useState } from 'react';
import { useOnFirstRender } from '@clubhouse/shared/hooks/useOnFirstRender';
const patchHistoryMethod = method => {
  const history = window.history;
  const original = history[method];
  history[method] = function (state) {
    const result = original.apply(this, arguments);
    const event = new Event(method.toLowerCase());
    event.state = state;
    window.dispatchEvent(event);
    return result;
  };
};
patchHistoryMethod('pushState');
patchHistoryMethod('replaceState');
const buildState = trigger => {
  const {
    state,
    length
  } = window.history;
  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search
  } = window.location;
  return {
    trigger,
    state,
    length,
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search
  };
};
export const useLocationInternal = () => {
  const [state, setState] = useState(buildState('load'));
  useOnFirstRender(() => {
    const onPopstate = () => setState(buildState('popstate'));
    const onPushstate = () => setState(buildState('pushstate'));
    const onReplacestate = () => setState(buildState('replacestate'));
    window.addEventListener('popstate', onPopstate);
    window.addEventListener('pushstate', onPushstate);
    window.addEventListener('replacestate', onReplacestate);
    return () => {
      window.removeEventListener('popstate', onPopstate);
      window.removeEventListener('pushstate', onPushstate);
      window.removeEventListener('replacestate', onReplacestate);
    };
  });
  return state;
};