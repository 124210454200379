import { Radio as RadioDefault } from './default';
import { Radio as RadioOutline } from './outline';
import { KIND } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Radio = props => {
  const {
    kind = KIND.DEFAULT
  } = props;
  if (kind === KIND.OUTLINE) {
    return _jsx(RadioOutline, {
      ...props
    });
  } else {
    return _jsx(RadioDefault, {
      ...props
    });
  }
};
Radio.KIND = KIND;