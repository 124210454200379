import { decode, encode } from '@clubhouse/shared/utils/encodedReactKey';
import { createKeyCountFn } from './createKeyCountFn';
export const encodeKey = obj => encode(obj);
export const decodeKey = key => decode(key);
export const decodeKeyToGlobalId = key => decodeKey(key).id;
export const decodeKeyToPublicId = key => decodeKey(key).publicId;
export const getKeyEncoder = () => {
  const getKeyCount = createKeyCountFn();
  const getKey = (id, publicId) => encodeKey({
    id,
    publicId,
    count: getKeyCount(id)
  });
  return getKey;
};
export const useKeyEncoder = () => getKeyEncoder();