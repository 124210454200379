import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { useIterations } from 'data/entity/iteration';
import orderBy from 'lodash/orderBy';
export const useTeamScopedIterations = () => {
  const {
    iterations
  } = useIterations();
  const teamId = getTeamScopedCollectionizeId();
  const allIterations = teamId ? iterations.filter(iteration => {
    if (iteration.group_ids[0] === teamId) return true;
    if ((iteration.associated_groups || []).find(g => g.group_id === teamId)) return true;
    return false;
  }) : iterations;
  return orderBy(allIterations, [i => new Date(i.start_date), i => new Date(i.created_at)], 'desc');
};