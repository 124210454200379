import Is from 'app/client/core/js/modules/is';
import { get } from 'utils/localStorage';
import { NOOP_MONITOR } from '../utils';
const FORCE_CONSOLE_KEY = 'force-console-monitor';
export const NAME = 'Console';
export const shouldOwnEvent = () => false;
export const shouldOwnNamedEvent = () => false;
export const shouldInitialize = () => Is.internal() || get(FORCE_CONSOLE_KEY) === 'true';
export const initialize = () => {
  return {
    ...NOOP_MONITOR,
    logError: (err, extra, contexts) => {
      console.error(err, extra, contexts);
    },
    logEvent: async (event, data) => {
      console.debug(`Monitor:logEvent: ${event}`, {
        data
      });
    },
    logNamedEvent: async (event, data) => {
      console.debug(`Monitor:logNamedEvent: ${event}`, {
        data
      });
    },
    setSessionAttributes: attrs => {
      console.debug('Monitor:setSessionAttributes', attrs);
    },
    setCurrentPage: (currentPage, pattern) => {
      console.debug(`Monitor:setCurrentPage=${currentPage}, ${pattern}`);
    },
    startTimedBlock: (namespace, name) => {
      console.debug(`Monitor:startTimedBlock=${namespace}:${name}`);
      const start = Date.now();
      return {
        end: data => {
          console.debug(`Monitor:endTimedBlock=${namespace}:${name}`, {
            elapsedTimeMs: Date.now() - start,
            data
          });
        }
      };
    },
    setTags: tags => {
      console.debug(`Monitor:setTags`, tags);
    }
  };
};