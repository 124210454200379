import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Star" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StarSvg = _ref => {
  let {
    fill,
    width
  } = _ref;
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill,
    children: _jsx(Icon, {
      icon: "Star"
    })
  });
};
StarSvg.displayName = "StarSvg";