import { useCallback } from 'react';
import { logSignupPageCompleted } from 'pages/signup/utils/tracking';
import { logError } from 'utils/monitoring';
export const useTrackedGoNext = (goNext, currentStep, signupSteps, signup) => useCallback(async () => {
  try {
    logSignupPageCompleted({
      signupStep: currentStep,
      pageName: `${signupSteps[currentStep].id}`,
      signup
    });
  } catch {
    logError(new Error('Tracking during Signup failed'));
  } finally {
    goNext();
  }
}, [currentStep, goNext, signup, signupSteps]);