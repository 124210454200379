import "core-js/modules/es.array.push.js";
import ArchiveWarningController from 'app/client/core/js/controllers/archiveWarning.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'ArchiveWarning'], ArchiveWarningController], [['Controller', 'ArchiveWarning'], ArchiveWarningController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export function ArchiveWarning(_ref2) {
  let {
    type,
    title
  } = _ref2;
  return _jsxs("div", {
    "data-controller": "ArchiveWarning",
    children: [_jsxs("h2", {
      id: "archive-label",
      children: ["Are you sure you want to archive this ", type, "?"]
    }), _jsxs("div", {
      className: "blurb",
      id: "archive-description",
      children: [_jsx("p", {
        children: type === 'Epic' || type === 'Project' ? _jsxs(_Fragment, {
          children: ["Archiving this ", type, " will exclude it and all of its Stories from reports and charts, as well as from the Stories, Status, and My Work pages."]
        }) : type === 'Story' ? _jsxs(_Fragment, {
          children: ["Archiving this ", type, " will exclude it from all reports and charts, as well as from the Stories page."]
        }) : type === 'Label' ? _jsxs(_Fragment, {
          children: ["Archiving this ", type, " removes it from the Labels dropdown list. It does ", _jsx("em", {
            children: "not"
          }), " remove the ", type, ' ', "from associated Stories or Epics."]
        }) : null
      }), _jsxs("p", {
        children: ["Continue archiving", ' ', _jsx("strong", {
          className: "title",
          children: _jsx(Emojify, {
            children: title
          })
        }), "?"]
      })]
    }), _jsxs("div", {
      className: "actions",
      children: [_jsx("button", {
        type: "button",
        className: "action elevated",
        id: "no-archive-button",
        "data-on-click": "close",
        children: "No"
      }), _jsx("button", {
        type: "button",
        className: "action red",
        id: "approve-archive-button",
        "data-on-click": "approve",
        children: "Yes"
      })]
    })]
  });
}
ArchiveWarning.displayName = "ArchiveWarning";