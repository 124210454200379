import { IterationSearchCardIterationFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { DateRange } from '@clubhouse/shared/components/DateRange';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { IterationStatus } from '@clubhouse/shared/types';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { GroupProfileIconTooltip } from 'components/shared/GroupProfileIconTooltip';
import { useIterationContextMenuWithQuickSearchSessionLogging } from 'utils/contextMenus/useIterationContextMenu';
import { loadURL } from 'utils/hotReload';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { SearchCard, SearchCardLink } from '../SearchCard/SearchCard';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const IterationSearchCardIterationFragment = IterationSearchCardIterationFragmentDoc;
export const IterationSearchCard = _ref => {
  let {
    iteration,
    openModalOnClick,
    onClick
  } = _ref;
  const {
    startEpochDay,
    endEpochDay,
    iterationName,
    iterationState,
    team,
    publicId
  } = iteration;
  const showDate = !!startEpochDay || !!endEpochDay;
  const iterationUrl = generatePathWithSlug(getPathForPage(PAGE_NAMES.ITERATION), {
    id: publicId
  });
  const contextMenuProps = useIterationContextMenuWithQuickSearchSessionLogging(iteration.publicId, useCallback(() => {
    onClick?.({
      ...iteration,
      url: iterationUrl
    });
  }, [iteration, iterationUrl, onClick]));
  const handleClick = e => {
    onClick?.({
      ...iteration,
      url: iterationUrl
    });
    if (isClickedWithModifier(e)) return;
    if (onClick) {
      e.preventDefault();
    }

    // This handles whether we navigate to a iteration or add a badge in docs
    if (openModalOnClick) {
      loadURL(iterationUrl, 'iterations');
    }
  };
  return _jsx(SearchCardLink, {
    onClick: handleClick,
    href: iterationUrl,
    "data-focusable": true,
    ...contextMenuProps,
    children: _jsxs(SearchCard, {
      children: [_jsx(SearchCard.Icon, {
        icon: "Iteration",
        kind: iterationState === IterationStatus.UNSTARTED ? 'default' : iterationState === IterationStatus.DONE ? 'green' : 'blue',
        label: "Iteration",
        isCompleted: iterationState === IterationStatus.DONE
      }), _jsx(SearchCard.Title, {
        children: _jsx(Emojify, {
          children: iterationName ?? ''
        })
      }), _jsxs(SearchCard.Metadata, {
        children: [_jsx(SearchCard.MetadataItem, {
          children: iterationState
        }), _jsx(SearchCard.MetadataItem, {
          children: showDate ? _jsx(DateRange, {
            start: startEpochDay,
            end: endEpochDay,
            fontSize: 12,
            format: DATE_FORMAT.SHORT_DATE_NO_YEAR
          }) : null
        })]
      }), team?.id && _jsx(SearchCard.Avatars, {
        children: _jsx(GroupProfileIconTooltip, {
          groupId: team.id
        })
      })]
    })
  });
};
IterationSearchCard.displayName = "IterationSearchCard";
export function IterationSearchCardAdapter(props) {
  const iteration = {
    __typename: 'Iteration',
    id: props.iteration.global_id,
    endEpochDay: props.iteration.end_date,
    iterationName: props.iteration.name,
    publicId: props.iteration.id,
    iterationState: props.iteration.status,
    startEpochDay: props.iteration.start_date,
    team: props.iteration.group_ids?.length > 0 ? {
      __typename: 'Team',
      id: props.iteration.group_ids[0]
    } : null
  };
  return _jsx(IterationSearchCard, {
    ...props,
    iteration: iteration
  });
}
IterationSearchCardAdapter.displayName = "IterationSearchCardAdapter";