import { GetStoryLabelOptionsDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { UpdateStoryLabelsDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryLabelsFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsField } from 'components/gql/GroupedOptionsField';
import { LabelsSelectTarget } from 'components/gql/label/LabelsSelectTarget';
import { getSection } from 'components/gql/label/utils/sections';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useMutationContext } from './FieldContextProvider';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryLabelsFieldFragment = StoryLabelsFieldFragmentDoc;
const UpdateStoryLabels = UpdateStoryLabelsDocument;
const GetStoryLabelOptions = GetStoryLabelOptionsDocument;
const LabelsFieldComponent = _ref => {
  let {
    entity: {
      id,
      labels
    }
  } = _ref;
  const mutationContext = useMutationContext();
  const selectedLabels = useMemo(() => (labels?.edges || []).map(_ref2 => {
    let {
      node: {
        id,
        name,
        color
      }
    } = _ref2;
    return {
      id,
      name,
      color
    };
  }), [labels]);
  const fetchLabels = useFieldOptionsQuery({
    query: GetStoryLabelOptions,
    storyId: id,
    includeCurrentPermissionId: false
  });
  const [save] = useMutation(UpdateStoryLabels);
  const handleChange = useCallback(labelIds => {
    save({
      variables: {
        id,
        input: {
          labels: {
            reset: labelIds
          }
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(id, {
        labels: {
          __typename: 'LabelConnection',
          edges: labelIds.map(id => ({
            __typename: 'LabelEdge',
            node: {
              __typename: 'Label',
              id
            }
          }))
        }
      }),
      ...mutationContext
    });
  }, [id, mutationContext, save]);
  return _jsx(GroupedOptionsField, {
    selectedEntities: selectedLabels,
    fetchOptions: fetchLabels,
    getSection: getSection,
    onChange: handleChange,
    TargetComponent: LabelsSelectTarget,
    unit: Nouns.Label
  });
};
LabelsFieldComponent.displayName = "LabelsFieldComponent";
LabelsFieldComponent.displayName = 'LabelsFieldComponent';
export const LabelsField = () => ({
  name: 'labels',
  displayName: 'Labels',
  Component: LabelsFieldComponent,
  LoadingComponent: () => _jsxs(Spaced, {
    horizontally: true,
    amount: 5,
    children: [_jsx(SkeletonRectangle, {
      width: "20%"
    }), _jsx(SkeletonRectangle, {
      width: "20%"
    }), _jsx(SkeletonRectangle, {
      width: "20%"
    })]
  }),
  width: 295,
  sort: true
});