import { Bug, Chore, Feature } from '@clubhouse/shared/components/Icons';
import { StoryTypes } from '@clubhouse/shared/types';
import { capitalize } from '@clubhouse/shared/utils';
import { renderComponentToString } from '../helpers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const iconMap = {
  [StoryTypes.FEATURE]: _jsx(Feature, {
    fill: "var(--iconYellowColor)"
  }),
  [StoryTypes.BUG]: _jsx(Bug, {
    fill: "var(--iconRedColor)"
  }),
  [StoryTypes.CHORE]: _jsx(Chore, {
    fill: "var(--iconGrayHoverColor)"
  })
};
const getIcon = storyType => {
  try {
    return renderComponentToString(iconMap[storyType]);
  } catch (e) {
    return null;
  }
};
export const formatStoryType = storyType => {
  const name = capitalize(storyType);
  return {
    value: storyType,
    name,
    icon: getIcon(storyType),
    __preserveSVG: true
  };
};
export const formatStoryTypes = storyTypes => storyTypes.map(formatStoryType);