import { StoryBreadcrumbsDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { useEffect, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useIsSelectOpen } from '@clubhouse/shared/utils/isSelectOpen';
import { BreadcrumbsV2 } from 'components/shared/breadcrumbs/BreadcrumbsV2';
import { useOptimizedStory } from 'data/entity/story';
import { useQuery } from 'gql';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateTo } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const StoryBreadcrumbsQuery = StoryBreadcrumbsDocument;

// Only used to connect collectionize to GQL. Can be removed when story dialog is all GQL.
function useRefetchOnStoryChange(publicId, refetch) {
  const story = useOptimizedStory(publicId);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We rerun if story changes
  useEffect(() => {
    refetch();
  }, [refetch, story]);
}
const useFlags = (publicId, globalId) => {
  const {
    data,
    previousData,
    refetch
  } = useQuery(StoryBreadcrumbsQuery, {
    variables: {
      id: globalId
    }
  });
  useRefetchOnStoryChange(publicId, refetch);
  const dataToRender = data || previousData;
  const segments = dataToRender?.node?.__typename === 'Story' ? dataToRender?.node?.breadcrumbs?.segments : null;
  const flags = useMemo(() => {
    if (!segments) return null;
    const objectives = segments.flatMap(conn => conn.__typename === 'ObjectiveConnection' ? conn.edges.map(e => e.node) : []);
    const keyResults = segments.flatMap(conn => conn.__typename === 'KeyResultConnection' ? conn.edges.map(e => e.node) : []);
    const epics = segments.flatMap(conn => conn.__typename === 'EpicConnection' ? conn.edges.map(e => e.node) : []);
    const stories = segments.flatMap(conn => conn.__typename === 'StoryConnection' ? conn.edges.map(e => e.node) : []);
    const epic = epics.length ? {
      ...epics[0],
      publicId: epics[0].publicId.toString()
    } : null;
    const story = stories.length ? {
      ...stories[0],
      publicId: stories[0].publicId.toString()
    } : null;
    return {
      objectives: objectives.map(obj => ({
        ...obj,
        publicId: obj.publicId.toString(),
        name: obj.name ?? '',
        keyResults: keyResults.filter(kr => kr.objective.id === obj.id).map(kr => ({
          ...kr,
          name: kr.name ?? ''
        }))
      })),
      epic,
      story
    };
  }, [segments]);
  return flags;
};
export const StoryBreadcrumbs = Object.assign(_ref => {
  let {
    publicId,
    globalId
  } = _ref;
  const flags = useFlags(publicId, globalId);
  const [, setIsSelectOpen] = useIsSelectOpen();
  if (!flags) return null;
  const onSelection = selection => {
    if ('objectiveId' in selection) {
      if (typeof selection.keyResultId === 'string') {
        navigateTo({
          url: generatePathWithSlug(getPathForPage(PAGE_NAMES.KEY_RESULT_MODAL), {
            id: selection.objectiveId,
            keyResultId: selection.keyResultId
          })
        });
      } else {
        navigateTo({
          url: generatePathWithSlug(getPathForPage(PAGE_NAMES.OBJECTIVE), {
            id: selection.objectiveId
          })
        });
      }
    } else if ('epicId' in selection) {
      navigateTo({
        url: generatePathWithSlug(getPathForPage(PAGE_NAMES.EPIC), {
          id: selection.epicId
        })
      });
    }
  };
  const onOpenDropdown = () => {
    setIsSelectOpen(true);
  };
  const onCloseDropdown = () => {
    setIsSelectOpen(false);
  };
  return _jsx(BreadcrumbsV2, {
    objectives: flags.objectives,
    epic: flags.epic,
    story: flags.story,
    onSelection: onSelection,
    onOpenDropdown: onOpenDropdown,
    onCloseDropdown: onCloseDropdown
  });
}, {
  EpicsAndStoriesOnly: _ref2 => {
    let {
      publicId,
      globalId
    } = _ref2;
    const flags = useFlags(publicId, globalId);
    if (!flags) return null;
    const onSelection = selection => {
      navigateTo({
        url: generatePathWithSlug(getPathForPage(PAGE_NAMES.EPIC), {
          id: selection.epicId
        })
      });
    };
    return _jsx(BreadcrumbsV2.EpicsAndStoriesOnly, {
      epic: flags.epic,
      story: flags.story,
      onSelection: onSelection
    });
  }
});