import SpaceModel from 'app/client/core/js/models/space';
import TeamModel from 'app/client/core/js/models/team';
import { getActive, updateSpace } from '../../entity/space';
const spaceSnapshots = new Map();
const saveSnapshot = snapshot => {
  spaceSnapshots.set(snapshot.id, snapshot);
};
export const getSnapshot = id => spaceSnapshots.get(id);
export const hasSnapshot = id => spaceSnapshots.has(id);
const deleteSnapshot = id => spaceSnapshots.delete(id);
export const restoreSnapshot = id => {
  const snapshot = getSnapshot(id);
  if (!snapshot) {
    return;
  }
  snapshot.scratch = false;
  snapshot.data.scratch = false;
  SpaceModel.update(snapshot);
  deleteSnapshot(id);
  return SpaceModel.copyById(id);
};
export const saveDraft = () => {
  const active = getActive();
  if (!active) {
    return;
  }
  if (!hasSnapshot(active.id)) {
    saveSnapshot(SpaceModel.copyById(active.id));
  }
  active.scratch = true;
  active.team_id = TeamModel.getActive('stories').id;
  SpaceModel.compileData(active);
  SpaceModel.update(active);
};
export const saveDraftToActiveSpace = async () => {
  const space = getActive();
  if (!space || space.readymade) {
    return;
  }
  const updatedSpace = await updateSpace(space.id, {
    data: {
      ...space.data,
      scratch: false
    },
    team_id: space.team_id
  });
  deleteSnapshot(space.id);
  return updatedSpace;
};