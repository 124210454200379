import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ExternalLinkChips = () => {
  const story = useContext(StoryContext);
  if (!story?.external_links?.length) return null;
  return _jsx(StoryCardInternal.Chips.ExternalLinkChips, {
    externalLinks: story.external_links
  });
};
ExternalLinkChips.displayName = "ExternalLinkChips";