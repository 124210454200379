export const IDEAL_BURNDOWN_STARTS_NOW_PARAM_KEY = 'ideal_burndown_starts_now';
export const IDEAL_STARTS_TODAY_CHECKBOX_INPUT_ID = 'ideal-today-checkbox';
export const AGGREGATE_TYPE_TO_AXIS_LABEL_MAPPING = {
  stories: 'Stories',
  points: 'Points'
};
export const CUMULATIVE_FLOW_WORKFLOW_ID_SEARCH_PARAM = 'cf_workflow_id';
export const CUMULATIVE_FLOW_CHART_HEIGHT_EXCLUDING_LEGEND = 290;
export const CUMULATIVE_FLOW_CHART_HEIGHT = 320;
export const BURNDOWN_CHART_HEIGHT = 320;
export const INSIGHTS_CHART_HEIGHT = 185;
export const CYCLE_TIME_WORKFLOW_ID_SEARCH_PARAM = 'ct_workflow_id';
export const CYCLE_TIME_CHART_HEIGHT_EXCLUDING_LEGEND = 290;
export const CYCLE_TIME_CHART_HEIGHT = 320;
export const CYCLE_TIME_TOOLTIP_MAX_HEIGHT = 225;

/** CYCLE TIME CHART SCALE SELECT CONSTANTS **/
const SCALE_VALS = ['log', 'linear'];
export const MAP_SCALE_VALUE_TO_DISPLAYABLE_NAME = {
  log: 'Log',
  linear: 'Linear'
};

/**  SHARED CHART STYLES - Overrides certain reCharts chart styles on all new charts **/

export const SHARED_CHART_CONTAINER_STYLES = {
  '.recharts-wrapper': {
    /**
     * Adding a small bit of top padding to the chart so that if we have any data points right up at the top edge of the chart sureface area,
     * their tooltips always render consistently (specifically needed for the Cycle time fruit loops).
     */
    paddingTop: 5,
    '.recharts-surface': {
      // Remove focus outline from chart on click, only show on keyboard navigation
      '&:focus:not(:focus-visible)': {
        outline: 'none'
      },
      // We want to hide the first Y Axis value so that `0` doesn't show up on the chart,
      // as it will always interact strangely with the first value on the x-axis.
      '.recharts-yAxis .recharts-cartesian-axis-tick:first-child': {
        display: 'none'
      }
    }
  }
};

/**  Insights chart components useQuery fetch-policy value: **/
export const INSIGHTS_CHARTS_FETCH_POLICY_VAL = 'cache-first';

/** This is the y-axis lower domain offset value ('dataMin - 0.19') that is required for the cycle time chart
 *  to have any of its '0' valued scatter plot items display their Tooltips correctly on hover.
 **/
export const CYCLE_TIME_PRIMARY_YAXIS_DOMAIN = ['dataMin - 0.19', 'dataMax'];