import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController], [['Tooltip'], Tooltip], [['Controller', 'Story'], StoryController], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import isEmpty from 'lodash/isEmpty';
import { COLORS } from '@clubhouse/shared/colors';
import { NO_STORY_TEMPLATE } from '@clubhouse/shared/constants';
import ProjectModel from 'app/client/core/js/models/project';
import Is from 'app/client/core/js/modules/is';
import { getDefaultStateIdForWorkflow, getDefaultWorkflow, getById as getWorkflowById } from 'data/entity/workflow';
import { name as sortByName } from 'utils/sort';
import { useEntities, useEntity, useOptimizedEntities } from './collection';
import { isProjectsFeatureEnabled } from './feature';
export const NO_PROJECT_TINT = COLORS.GRAY70;
export const all = () => ProjectModel.all();
export const allByTeam = () => ProjectModel.allNotArchivedInActiveTeam();
export const getProjectByGlobalId = globalId => ProjectModel.get({
  global_id: globalId
});
export const getById = id => ProjectModel.getById(id);
export const generateURL = project => ProjectModel.generateURL(project);
export const getCalendarLink = project => ProjectModel.getCalendarLink(project);
export const allActive = () => ProjectModel.allActive();
export const isActiveProject = project => Boolean(project) && !project.archived;
export const currentUserIsFollower = project => ProjectModel.currentUserIsFollower(project);
export const addMeAsFollower = project => new Promise((resolve, reject) => ProjectModel.addMeAsFollower(project, err => err ? reject(err) : resolve()));
export const removeMeAsFollower = project => new Promise((resolve, reject) => ProjectModel.removeMeAsFollower(project, err => err ? reject(err) : resolve()));
export const filterByWorkflowTeam = team => _ref2 => {
  let {
    team_id
  } = _ref2;
  return team_id === team.id;
};
export const unarchiveProject = id => new Promise((resolve, reject) => ProjectModel.unarchiveProject({
  id
}, err => err ? reject(err) : resolve()));
export const archiveProject = id => new Promise((resolve, reject) => ProjectModel.archiveProject({
  id
}, err => err ? reject(err) : resolve()));
export const deleteProject = id => new Promise((resolve, reject) => ProjectModel.deleteProject({
  id
}, err => err ? reject(err) : resolve()));
export const useProjects = () => {
  const {
    entities: projects,
    diff
  } = useEntities({
    model: ProjectModel
  });
  return {
    projects,
    diff
  };
};
export const useProject = id => {
  const {
    entity
  } = useEntity({
    model: ProjectModel,
    id
  });
  return entity;
};
export const useOptimizedProjects = _ref3 => {
  let {
    fields,
    filter
  } = _ref3;
  return useOptimizedEntities({
    model: ProjectModel,
    filter,
    fields
  });
};
export const sortProjectByName = (a, b) => sortByName(a, b);

//--- Optional project

export const fetchSingle = (id, cb) => ProjectModel.fetchSingle(id, cb);
export const getProjectFromStory = story => (isEmpty(story.project) && story.project_id ? getById(story.project_id) : story.project) ?? null;

// Active means it's selected on the stories page
const getOnlyActiveProject = () => Is.storiesPage() && ProjectModel.singleActive();
const getProjectEntry = project => {
  return {
    project: project ?? null,
    project_id: project?.id ?? null
  };
};
export const save = (id, changes) => new Promise(resolve => {
  ProjectModel.saveChanges({
    id
  }, changes, resolve);
});
const getDefaultWorkflowStateId = story => {
  return story.workflow_id ? getDefaultStateIdForWorkflow(story.workflow_id) : undefined;
};
const getDefaultValueForTemplate = story => {
  const project = story.project || (story.project_id ? getById(story.project_id) : undefined);
  return {
    ...getProjectEntry(project),
    workflow_id: story.workflow_id ?? null,
    workflow_state_id: getDefaultWorkflowStateId(story) ?? null
  };
};
const getDefaultValueForStory = function (story) {
  let shouldCheckTemplate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  // Guard against deleted or missing project.
  // Ref: https://app.shortcut.com/internal/stories/1146
  // Ref: https://help.shortcut.com/agent/tickets/118
  const onlyActiveProject = getOnlyActiveProject();
  let project = null;
  if (shouldCheckTemplate) {
    const hasNoTemplate = !story.template_id || story.template_name === NO_STORY_TEMPLATE;
    const noTemplateProject = hasNoTemplate && ProjectModel.get({
      id: story.project_id,
      archived: false
    });
    const storyProject = isEmpty(story.project) ? null : story.project;
    project = noTemplateProject || storyProject || null;
  } else {
    project = onlyActiveProject || null;
  }
  const workflow = getWorkflowById(story.workflow_id) ?? getDefaultWorkflow();
  const workflowStateId = workflow.states.find(s => s.id === story.workflow_state_id)?.id ?? workflow.default_state_id;
  return {
    ...(project ? getProjectEntry(project) : {
      project: null,
      project_id: null
    }),
    workflow_id: workflow.id,
    workflow_state_id: workflowStateId
  };
};
const isProjectInvalidForStory = story => {
  if (story.project_id === null) {
    return false;
  }
  const project = story.project || (story.project_id ? getById(story.project_id) : undefined);
  return !project || project.archived === true;
};
const getNewStoryValue = story => {
  const {
    project_id
  } = story;
  return project_id ? {
    project_id
  } : {};
};
const getStoryBadge = story => {
  const isMoreThanOneProjectActive = allActive().length > 1;
  const project = getProjectFromStory(story);
  if (!isProjectsFeatureEnabled() || !project || !isMoreThanOneProjectActive || !Is.storiesPage()) return null;
  return {
    className: 'story-badge--project',
    tooltipFn: 'App.Controller.Story.getProjectBadgeTooltip',
    text: project.abbreviation,
    icon: 'fa-circle',
    color: project.color
  };
};
export const OptionalProject = {
  getProjectEntry,
  getDefaultValueForStory,
  getDefaultValueForTemplate,
  getDefaultWorkflowStateId,
  isProjectInvalidForStory,
  getNewStoryValue,
  getStoryBadge
};