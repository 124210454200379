import { useAbortController } from 'utils/useAbortController';
import { useQuery } from './useQuery';
/**
 * A hook for creating an abortable query.
 *
 * Mostly a wrapper around our existing custom hook: `useQuery`,but this one also
 * provides an `abort` function to abort the current request via an `AbortController`,
 * as well as passing an `AbortController` signal only when needed (only when using `useAbortableQuery`).
 */
export const useAbortableQuery = function (query) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    signal,
    abort,
    resetAbort
  } = useAbortController({
    resetOnAbort: false
  });
  const result = useQuery(query, {
    ...options,
    context: {
      ...options.context,
      fetchOptions: {
        ...options.context?.fetchOptions,
        signal
      }
    }
  });
  return {
    ...result,
    abort,
    resetAbort
  };
};