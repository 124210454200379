import { Chip } from '@clubhouse/shared/components/Chip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MentionName = _ref => {
  let {
    mentionName
  } = _ref;
  if (!mentionName) return null;
  return _jsx("div", {
    children: _jsx(Chip, {
      kind: "default",
      children: `@${mentionName}`
    })
  });
};
MentionName.displayName = "MentionName";