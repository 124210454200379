import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
export const mergeConnections = (existing, incoming, options) => {
  if (!options.variables) return incoming;
  if (!incoming?.edges) return existing;
  if (!existing?.edges) return incoming;
  const offset = options.variables.input?.pagination?.offset ?? 0;
  const merged = incoming.edges.reduce((acc, incomingEdge, index) => {
    const matchingExistingEdge = acc.edges[offset + index];
    if (!matchingExistingEdge || options.readField('id', matchingExistingEdge.node) !== options.readField('id', incomingEdge.node)) {
      acc.edges[offset + index] = incomingEdge;
    }
    return acc;
  }, {
    ...existing,
    edges: [...existing.edges]
  });
  const incomingEdgeIds = new Set(incoming.edges.map(edge => options.readField('id', edge.node)));
  const deduplicatedMatchingExistingEdges = existing.edges.filter(edge => !incomingEdgeIds.has(options.readField('id', edge.node)));
  deduplicatedMatchingExistingEdges.splice(offset, 0, ...incoming.edges);
  const sameTotalSizeAsIncoming = deduplicatedMatchingExistingEdges.slice(0, incoming.pageInfo.totalSize);
  const result = {
    ...merged,
    edges: sameTotalSizeAsIncoming,
    pageInfo: incoming.pageInfo
  };
  return result;
};
export function createConnectionMerger(mapInput) {
  return (existing, incoming, options) => {
    const expectedShape = mapInput(options.variables);
    return mergeConnections(existing, incoming, {
      ...options,
      variables: expectedShape
    });
  };
}