import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
const cache = {};
export function useRestoreScrollPosition(ref, key) {
  const initialRender = useRef(true);
  useEffect(() => {
    const {
      current: el
    } = ref;
    if (!key || !el) return;
    if (initialRender.current) {
      initialRender.current = false;
      const storedValue = cache[key];
      if (storedValue) {
        el.scrollTo({
          left: storedValue.x,
          top: storedValue.y,
          behavior: 'instant'
        });
      }
    }
    const onScroll = debounce(() => {
      cache[key] = {
        x: el.scrollLeft,
        y: el.scrollTop
      };
    }, 100);
    el.addEventListener('scroll', onScroll, {
      passive: true
    });
    return () => {
      el?.removeEventListener('scroll', onScroll);
    };
  }, [key, ref]);
}