import { WorkspaceLogoQueryDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import fallbackLogo from '@clubhouse/assets/svg/shortcut-logos/default-workspace-logo.svg?url';
import fallbackLogoProjectRaven from '@clubhouse/assets/svg/shortcut-logos/shortcut-logo-mark.svg?url';
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
const WORKSPACE_LOGO_QUERY = WorkspaceLogoQueryDocument;
export const useWorkspaceLogoQueryLegacy = () => {
  const slug = useWorkspaceSlug();
  const {
    data
  } = useQuery(WORKSPACE_LOGO_QUERY, {
    variables: {
      slug: slug ?? ''
    },
    skip: !slug
  });
  if (!slug) return {
    isCustom: false,
    isLoading: false,
    src: fallbackLogo,
    companyName: BRAND.NAME
  };
  if (!data) return {
    isLoading: true
  };
  const customLogo = data.workspace2?.displayIcon?.thumbnailUrl;
  const companyName = data.workspace2?.name ?? '';
  return {
    isLoading: false,
    isCustom: !!customLogo,
    companyName,
    src: customLogo || fallbackLogo
  };
};
export const useWorkspaceLogoQuery = () => {
  const slug = useWorkspaceSlug();
  const {
    data
  } = useQuery(WORKSPACE_LOGO_QUERY, {
    variables: {
      slug: slug ?? ''
    },
    skip: !slug
  });
  if (!slug) return {
    isCustom: false,
    isLoading: false,
    src: fallbackLogo,
    companyName: BRAND.NAME
  };
  if (!data) return {
    isLoading: true
  };
  const customLogo = data.workspace2?.displayIcon?.thumbnailUrl;
  const companyName = data.workspace2?.name ?? '';
  return {
    isLoading: false,
    isCustom: !!customLogo,
    companyName,
    src: customLogo || fallbackLogoProjectRaven
  };
};