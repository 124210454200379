import sighFailPng from '@clubhouse/assets/png/sigh-fail.png';
import { LoadingState } from './shared/LoadingState';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UndonePage = () => _jsx(LoadingState, {
  title: _jsx("span", {
    style: {
      fontSize: 18
    },
    children: "Your failed import has been removed."
  }),
  message: _jsxs(_Fragment, {
    children: ["Please contact ", _jsx("a", {
      href: `mailto: ${BRAND.SUPPORT_EMAIL}`,
      children: BRAND.SUPPORT_EMAIL
    }), " with any questions."]
  }),
  hero: _jsx("img", {
    src: sighFailPng,
    alt: "",
    style: {
      marginBottom: 20
    }
  })
});
UndonePage.displayName = "UndonePage";