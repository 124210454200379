import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Context = createContext({
  mutationContext: {}
});
export const FieldContextProvider = _ref => {
  let {
    children,
    value
  } = _ref;
  return _jsx(Context.Provider, {
    value: value,
    children: children
  });
};
FieldContextProvider.displayName = "FieldContextProvider";
export const useMutationContext = () => useContextSelector(Context, v => v.mutationContext);