import { useCallback } from 'react';
import { useApplicationStateByKey } from './useApplicationStateByKey';
import { useUpdateApplicationState } from './useUpdateApplicationState';
const isEmpty = value => {
  if (value == null) return true;
  if (Array.isArray(value) && !value.length) return true;
  if (typeof value === 'object' && !Object.keys(value).length) return true;
  return false;
};
export function useAppState(applicationStateKey, defaultValue, options) {
  const {
    emptyArrayIsUndefined = true
  } = options ?? {};
  const identityFn = useCallback(x => x ?? defaultValue, [defaultValue]);
  const {
    data: value
  } = useApplicationStateByKey({
    applicationStateKey,
    toExpectedType: identityFn
  });
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: identityFn
  });
  const setValue = useCallback(value => {
    return update(prevValue => {
      let newValue;
      if (typeof value === 'function') {
        newValue = value(prevValue);
      } else {
        newValue = value;
      }
      if (newValue === defaultValue || isEmpty(newValue)) {
        if (emptyArrayIsUndefined !== false || !Array.isArray(newValue)) newValue = undefined;
      }
      return newValue;
    });
  }, [defaultValue, emptyArrayIsUndefined, update]);
  return [value ?? defaultValue, setValue];
}