import { useCallback, useRef } from 'react';
import { useOnUnmount } from '@clubhouse/shared/hooks/useOnUnmount';
/**
 * Custom hook that provides an `AbortController` and related functions for aborting requests.
 *
 * A few notes about the implementation:
 * - The `abort` function will abort the current request and create a new `AbortController`.
 *   - If we pass `useAbortController({ resetOnAbort: false })`, the `abort` function will not create a new `AbortController`.
 * - The `resetAbort` function will create a new `AbortController`.
 * - We also clean up the `AbortController` on unmount by aborting and preventing the creating of a new `AbortController`.
 */
export function useAbortController() {
  let {
    resetOnAbort = true,
    abortOnUnmount = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const abortControllerRef = useRef(new AbortController());
  const abort = useCallback((reason, skipReset) => {
    abortControllerRef.current.abort(reason);
    if (resetOnAbort && skipReset === undefined || skipReset === false) {
      abortControllerRef.current = new AbortController();
    }
  }, [resetOnAbort]);
  const resetAbort = useCallback(() => {
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current;
  }, []);
  useOnUnmount(() => {
    if (abortOnUnmount) {
      abort('Unmounted');
    }
  });
  return {
    get abortController() {
      return abortControllerRef.current;
    },
    get signal() {
      return abortControllerRef.current.signal;
    },
    abort,
    resetAbort
  };
}