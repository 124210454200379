export const start = _ref => {
  let {
    mark: name
  } = _ref;
  mark(name);
};
export const end = _ref2 => {
  let {
    mark,
    measure: name
  } = _ref2;
  if (hasEntry(name)) {
    return;
  }
  measure(name, mark);
};
export const getDurationSincePageLoad = function () {
  let {
    name
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  try {
    const TRACE_NAME = `load:${name}`;
    performance.measure(TRACE_NAME);
    const [{
      duration
    }] = performance.getEntriesByName(TRACE_NAME);
    performance.clearMeasures(TRACE_NAME);
    return Math.round(duration);
  } catch (e) {
    return undefined;
  }
};
const hasEntry = name => {
  try {
    return performance.getEntriesByName(name).length > 0;
  } catch (e) {
    return false;
  }
};
const mark = function (name) {
  try {
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }
    performance.mark(name, ...rest);
  } catch {}
};
const measure = function () {
  try {
    performance.measure(...arguments);
  } catch {}
};
export const getMeasuresToLog = () => {
  try {
    return Object.values(MEASURES).filter(value => value.shouldLog && performance.getEntriesByName(value.measure).length > 0).map(value => {
      const [{
        duration
      }] = performance.getEntriesByName(value.measure);
      return {
        name: value.measure,
        duration
      };
    }).reduce((acc, _ref3) => {
      let {
        name,
        duration
      } = _ref3;
      return {
        ...acc,
        [`performance.${name}`]: Math.round(duration)
      };
    }, {});
  } catch (e) {
    return [];
  }
};
export const MEASURES = {
  html: {
    mark: undefined,
    measure: 'html',
    shouldLog: true
  },
  htmlLoad: {
    mark: undefined,
    measure: 'htmlLoad',
    shouldLog: true
  },
  waitingForInit: {
    mark: 'waitingForInit.start',
    measure: 'waitingForInit',
    shouldLog: true
  },
  appShell: {
    mark: 'appShell.start',
    measure: 'appShellRender'
  },
  commonInit: {
    mark: 'commonInit.start',
    measure: 'commonInitialization',
    shouldLog: true
  },
  page: {
    mark: 'pageRendered.start',
    measure: 'pageRender'
  },
  consolidatedFetch_fetchOrgAndApplicationState: {
    mark: 'consolidatedFetch_fetchOrgAndApplicationState.start',
    measure: 'consolidatedFetch_fetchOrgAndApplicationState'
  },
  consolidatedFetch_fetchConsolidatedDataAndActivity: {
    mark: 'consolidatedFetch_fetchConsolidatedDataAndActivity.start',
    measure: 'consolidatedFetch_fetchConsolidatedDataAndActivity',
    shouldLog: true
  },
  reportsFilters_filterStories: {
    mark: 'reportsFilters_filterStories.start',
    measure: 'reportsFilters_filterStories',
    shouldLog: true
  }
};