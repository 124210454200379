/** @deprecated Use Shapes breakpoints instead */
export const TABLET_SIZES = {
  Small: {
    min: 601,
    max: 768
  },
  Large: {
    min: 769,
    max: 1024
  }
};

/** @deprecated Use Shapes breakpoints instead */
export const DESKTOP_SIZES = {
  Small: {
    min: 1025,
    max: 1440
  },
  Medium: {
    min: 1441,
    max: 1920
  },
  Large: {
    min: 1921,
    max: null
  }
};

/** @deprecated Use Shapes breakpoints instead */
export const DEVICE_SIZES = {
  Mobile: {
    min: 0,
    max: 600
  },
  Tablet: {
    min: TABLET_SIZES.Small.min,
    max: TABLET_SIZES.Large.max
  },
  Desktop: {
    min: DESKTOP_SIZES.Small.min,
    max: DESKTOP_SIZES.Large.max
  }
};

/** @deprecated Use Shapes breakpoints instead */
export const DEVICE_MEDIA_QUERIES = {
  Mobile: `(max-width: ${DEVICE_SIZES.Mobile.max}px)`,
  Tablet: `(min-width: ${DEVICE_SIZES.Tablet.min}px) and (max-width: ${DEVICE_SIZES.Tablet.max}px)`,
  Desktop: `(min-width: ${DEVICE_SIZES.Desktop.min}px)`,
  LargerThanMobile: `(min-width: ${TABLET_SIZES.Small.min}px)`,
  SmallerThanLargeTablet: `(max-width: ${TABLET_SIZES.Small.max}px)`,
  TabletSmall: `(min-width: ${TABLET_SIZES.Small.min}px) and (max-width: ${TABLET_SIZES.Small.max}px)`,
  TabletLarger: `(min-width: ${TABLET_SIZES.Large.min}px) and (max-width: ${TABLET_SIZES.Large.max}px)`,
  TabletOrSmaller: `(max-width: ${TABLET_SIZES.Large.max}px)`,
  DesktopSmall: `(min-width: ${DESKTOP_SIZES.Small.min}px) and (max-width: ${DESKTOP_SIZES.Small.max}px)`,
  DesktopMedium: `(min-width: ${DESKTOP_SIZES.Medium.min}px) and (max-width: ${DESKTOP_SIZES.Medium.max}px)`,
  DesktopLarge: `(min-width: ${DESKTOP_SIZES.Large.min}px)`
};
export const MOBILE_USERAGENT_REGEXP = /(android|blackberry|iemobile|iphone|ipod|opera mini|palm|windows phone)/i;
export const TABLET_USERAGENT_REGEXP = /Tablet|iPad/i;
export const IPAD_PRO_PLATFORM_REGEXP = /MacIntel/i;