import { StateIcon } from '@clubhouse/shared/components/StateIcon';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { convertToGQL } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ObjectiveStateIcon = _ref => {
  let {
    state
  } = _ref;
  if (!state) return _jsx(StateIcon, {
    type: WORKFLOW_STATE_TYPES.UNSTARTED
  });
  const objectiveState = convertToGQL(state);
  if (objectiveState === 'toDo') {
    return _jsx(StateIcon, {
      type: WORKFLOW_STATE_TYPES.UNSTARTED
    });
  } else if (objectiveState === 'inProgress') {
    return _jsx(StateIcon, {
      type: WORKFLOW_STATE_TYPES.STARTED
    });
  }
  return _jsx(StateIcon, {
    type: WORKFLOW_STATE_TYPES.DONE
  });
};
ObjectiveStateIcon.displayName = "ObjectiveStateIcon";