import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ApplicationState from 'app/client/core/js/modules/applicationState';
export const getAppState = key => ApplicationState.get(key);
export const setAppState = (key, value) => ApplicationState.set(key, value);
export const subscribeToAppState = (key, fn) => {
  const listener = () => {
    fn(ApplicationState.get(key));
  };
  ApplicationState.addListener(key, listener);
  return () => ApplicationState.removeListener(key, listener);
};
export const getTypedAppState = _ref => {
  let {
    id,
    type,
    defaultValue
  } = _ref;
  const original = getAppState(id);
  if (typeof original === type) return original;
  return defaultValue;
};
export const useAppState = _ref2 => {
  let {
    appStateKey,
    default: defaultValue,
    aliases
  } = _ref2;
  const [state, setState] = useState(() => ApplicationState.get(appStateKey));

  // We use a ref instead of adding deafult to the dependency array to ensure that "useAppState({ default: {} })" doesn't result in new "set"-function on every render.
  const defaultRef = useRef(defaultValue);
  defaultRef.current = defaultValue;
  const set = useCallback(value => {
    if (typeof defaultRef.current === 'undefined' || !isEqual(defaultRef.current, value)) {
      ApplicationState.set(appStateKey, value);
    } else {
      ApplicationState.remove(appStateKey);
    }
    setState(value);
  }, [appStateKey]);
  useEffect(() => subscribeToAppState(appStateKey, setState), [appStateKey]);
  const value = useMemo(() => {
    if (state == null && defaultValue !== undefined) {
      return defaultValue;
    }
    if (!aliases) return state;
    return typeof state === 'string' && Object.prototype.hasOwnProperty.call(aliases, state) ? aliases[state] : state;
  }, [state, aliases, defaultValue]);
  return useMemo(() => [value, set], [set, value]);
};