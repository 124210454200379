import { ChartTypeSelectReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ChartTypeSelectReport_SelectorFragment = ChartTypeSelectReport_SelectorFragmentDoc;
const CHART_TYPE_DESCRIPTIONS = {
  cycleTime: 'Time between any <b>started</b> state and any <b>done</b> state.',
  leadTime: 'Time between any <b>created-in</b> state and any <b>done</b> state',
  custom: `Limit stories to specific start and end states.`
};
export const ChartTypeSelect = props => {
  return _jsx(ReportSelectFilter, {
    ...props,
    label: "Type",
    LoadingComponent: _jsx(SkeletonRectangle, {
      width: 65,
      animate: true
    }),
    descriptions: CHART_TYPE_DESCRIPTIONS
  });
};
ChartTypeSelect.displayName = "ChartTypeSelect";