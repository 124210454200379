import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { MarkdownDisplay } from 'components/shared/MarkdownDisplay';
import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { save, useStory } from 'data/entity/story';
import { hasSnapshot } from 'utils/snapshot';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function StoryDescriptionEditor(_ref) {
  let {
    id
  } = _ref;
  const snapshotKey = `story.${id}.description`;
  const [isEditing, {
    on: startEditing,
    off: stopEditing
  }] = useToggleState(hasSnapshot(snapshotKey));
  const story = useStory({
    id
  });
  const handleClickOnDescription = useCallback(e => {
    if (!e.shiftKey || e.button !== 0) return;
    e.preventDefault();
    e.stopPropagation();
    if (!isEditing) {
      startEditing();
    }
  }, [isEditing, startEditing]);
  if (!story) return _jsx("div", {});
  return _jsx("div", {
    children: isEditing ? _jsx(MarkdownEditor, {
      context: "Description",
      snapshotKey: snapshotKey,
      initialValue: story.description || '',
      onSave: async value => {
        try {
          stopEditing();
          await save({
            id: story.id,
            description: value
          });
        } catch {
          startEditing();
          addToast({
            kind: 'alert',
            timeout: 5000,
            Content: () => _jsx(ToastText, {
              children: "The change could not be saved."
            })
          });
        }
      },
      onCancel: stopEditing
    }) : _jsxs(_Fragment, {
      children: [_jsx("div", {
        onClick: handleClickOnDescription,
        role: "presentation",
        children: _jsx(MarkdownDisplay, {
          onChange: async value => {
            try {
              await save({
                id: story.id,
                description: value
              });
            } catch {
              addToast({
                kind: 'alert',
                timeout: 5000,
                Content: () => _jsx(ToastText, {
                  children: "The change could not be saved."
                })
              });
            }
          },
          children: story.description || ''
        }, story.description)
      }), _jsx(Button, {
        kind: Button.KIND.SECONDARY,
        size: Button.SIZE.SMALL,
        fit: Button.FIT.MEDIUM,
        IconLeft: () => _jsx(SizedIcon, {
          icon: "Edit",
          fill: "disabled",
          size: 16
        }),
        onClick: startEditing,
        children: "Edit Description"
      })]
    })
  });
}
StoryDescriptionEditor.displayName = "StoryDescriptionEditor";