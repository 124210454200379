import { useContext, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, generatePath, useLocation } from 'react-router-dom';
import { THEME_NAMES, addThemeToHTML } from '@clubhouse/shared/utils/theme';
import { PAGE_NAMES, getPathForPage } from 'utils/navigation';
import { ErrorFlow } from './ErrorFlow';
import { InviteSignupFlow } from './InviteSignupFlow';
import { JoinFromOrgFlow } from './JoinFromOrgFlow';
import { SignupContext, useSignupError } from './SignupContext';
import { SignupFlow } from './SignupFlow';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const getInviteUrl = signupId => generatePath(getPathForPage(PAGE_NAMES.JOIN_WORKSPACE), {
  signupId
});
const getSignupUrl = signupId => generatePath(getPathForPage(PAGE_NAMES.SIGNUP), {
  signupId
});
export const SignupPageContent = () => {
  useLayoutEffect(() => {
    // We explicitly lock the theme on light and forward it up to the HTML `data-theme` attribute,
    // as there's a possibility that the theme could be set to dark from local storage.
    addThemeToHTML(THEME_NAMES.LIGHT);
  }, []);
  const {
    state
  } = useLocation();
  const error = useSignupError();
  const signupData = useContext(SignupContext);
  const {
    pendingInvites,
    matchingDomains,
    signup
  } = signupData;
  const doInviteFlow = Boolean(pendingInvites?.length || matchingDomains?.length) && !state?.fromJoinOrg;
  if (error || !signup?.id) return _jsx(ErrorFlow, {
    error: error
  });
  const inviteUrl = getInviteUrl(signup.id.toString());
  const signupUrl = getSignupUrl(signup.id.toString());
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      path: getPathForPage(PAGE_NAMES.JOIN_INVITE),
      component: InviteSignupFlow
    }), _jsx(Route, {
      path: inviteUrl,
      component: JoinFromOrgFlow
    }), _jsx(Route, {
      path: signupUrl,
      exact: true,
      children: doInviteFlow ? _jsx(Redirect, {
        to: inviteUrl
      }) : _jsx(SignupFlow, {})
    })]
  });
};
SignupPageContent.displayName = "SignupPageContent";