import { Nouns } from '@clubhouse/shared/constants';
import { EpicStatesFilter } from 'components/gql/epicState/EpicStatesFilter';
import { ClearAllCurrentFiltersButton } from 'components/gql/filters/ClearAllCurrentFiltersButton';
import { QueryFilter } from 'components/gql/filters/QueryFilter';
import { LabelsFilter } from 'components/gql/label/LabelsFilter';
import { ObjectivesFilter } from 'components/gql/objective/ObjectivesFilter';
import { OwnersFilter } from 'components/gql/owner/OwnersFilter';
import { ProjectsFilter } from 'components/gql/projects/ProjectsFilter';
import { TeamsFilter } from 'components/gql/team/TeamsFilter';
import { IfNoTeamScope } from 'components/team-navigation/utils/IfNoTeamScope';
import { useObjectivesEnabledForCurrentOrg } from 'data/entity/organization';
import { ShowArchived } from '../../../components/filters/ShowArchived';
import { useWorkspaceProjectsFeature } from '../../../hooks/useWorkspaceProjectsFeature';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicsFilters = () => {
  const objectivesEnabled = useObjectivesEnabledForCurrentOrg();
  return _jsxs(_Fragment, {
    children: [useWorkspaceProjectsFeature() && _jsx(ProjectsFilter, {
      entityType: Nouns.Epic
    }), _jsx(OwnersFilter, {
      entityType: Nouns.Epic
    }), objectivesEnabled && _jsx(ObjectivesFilter, {
      entityType: Nouns.Epic
    }), _jsx(IfNoTeamScope, {
      children: _jsx(TeamsFilter, {
        entityType: Nouns.Epic
      })
    }), _jsx(EpicStatesFilter, {
      entityType: Nouns.Epic
    }), _jsx(LabelsFilter, {
      entityType: Nouns.Epic
    }), _jsx(ClearAllCurrentFiltersButton, {}), _jsx(ShowArchived, {})]
  });
};
export const EpicsQueryFilters = () => {
  return _jsx(QueryFilter, {
    entityType: Nouns.Epic
  });
};
EpicsQueryFilters.displayName = "EpicsQueryFilters";