import * as ManageBillingOverviewTemplate from 'app/client/settingsShared/views/templates/manageBillingOverview.html';
import * as ManagePaymentPlanTemplate from 'app/client/settingsShared/views/templates/managePaymentPlan.html';
import * as ManageBillingLoaderTemplate from 'app/client/settingsShared/views/templates/manageBillingLoader.html';
import * as ManageBillingTemplate from 'app/client/settingsShared/views/templates/manageBilling.html';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Icon } from '@useshortcut/shapes-ds';
import Async from '../../../core/js/modules/async';
import CardModel from '../../../core/js/models/card';
import Backend from '../../../core/js/modules/backend';
import CompanyModel from '../../../core/js/models/company';
import * as Event from '../../../core/js/_frontloader/event';
import Feature from 'app/client/core/js/models/feature';
import Globals from '../../../core/js/_frontloader/globals';
import InviteModel from '../../../core/js/models/invite';
import Is from '../../../core/js/modules/is';
import Log from '../../../core/js/modules/log';
import MessageController from '../../../core/js/controllers/message';
import MultiOrgModel from '../../../core/js/models/multiOrg';
import OrganizationModel from '../../../core/js/models/organization';
import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import InvoicesModel from '../../../core/js/models/invoices';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import Router from '../../../core/js/_frontloader/router';
import Dialog from '../../../core/js/modules/dialog';
import FlashController from 'app/client/core/js/controllers/flash';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { ManagedBilling } from 'components/settings/manage-billing/ManagedBilling';
import ManagePaymentPlanController from 'app/client/settingsShared/js/controllers/managePaymentPlan';
import { PlanSummary } from 'components/settings/manage-billing/PlanSummary';
import { PlanOverview } from 'components/settings/manage-billing/PlanOverview';
import { UpgradeDialog } from 'components/settings/manage-billing/UpgradeDialog';
import { BillingAlerts as BillingAlertsNext } from 'components/shared/BillingAlertsNext';
import { PaidSeatsRemaining } from 'components/shared/PaidSeatsRemaining';
import { PLAN_TABLE_TRIGGER_LOCATIONS, sendPlanTableOpenedEvent } from 'components/settings/manage-billing/tracking';
import { ReferralMessage } from 'components/settings/manage-billing/ReferralMessage';
import throttle from 'lodash/throttle';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const DIALOG_CONTAINER_SELECTOR = '#react-dialogs';
const PARENT_SELECTOR = exports.PARENT_SELECTOR = '#manage-billing';
exports.title = `Billing | ${BRAND.NAME}`;
exports.route = () => {
  const pagePath = '/settings/billing';

  // There are some cases where we're loading the billing page without an active workspace2.
  // By example, from `/organizations/ORGANIZATION_SLUG/manage`.
  // For those cases, we can get an active workspace2 based on the organization we're managing.
  if (!Is.withinOrg()) {
    const company = CompanyModel.getCurrent();
    const targetWorkspace2 = company?.workspaces2?.find(workspace2 => workspace2.disabled === false && workspace2.locked_out === false);
    if (targetWorkspace2?.url_slug != null) {
      return '/' + targetWorkspace2.url_slug + pagePath;
    }
  }

  // Be careful, `Url.getSlugPath` can return the slug of the organization if we're managing one.
  // TODO: (@charpeni) We should figure out if having a route for `/organizations/settings/billing` makes sense.
  // I think it doesn't, but I don't want to play Jenga here.
  const slug = Url.getSlugPath() || '/organizations';
  return slug + pagePath;
};
const _setStripeKey = res => {
  return new Promise(resolve => {
    if (!window.Stripe.card || !window.Stripe.card.createToken) {
      Log.error(new Error('The Stripe.js library was probably blocked by a browser extension.'));
    }
    if (res && _.isString(res.key)) {
      window.Stripe.setPublishableKey(res.key);
    }
    resolve();
  });
};
const _loadClubhouseDataForOwner = () => {
  return Promise.all([UserModel.Promises.fetchAll(), InviteModel.Promises.fetchAll(), PaymentPlan2Model.Promises.fetch().catch(err => {
    Log.error(new Error('Failed to fetch payment plan'), {
      errorInfo: JSON.stringify(err)
    });
  }), InvoicesModel.Promises.fetch(), CompanyModel.Promises.fetch(CompanyModel.getCurrentID())]);
};
const _loadCards = () => {
  return CardModel.Promises.fetchAll();
};
const _loadDataForOwner = () => {
  return Backend.get('/api/private/owner/payment-plan-2/provider-publishable-key', {
    excludeOrgHeader: true
  }).then(_setStripeKey).then(_loadClubhouseDataForOwner).then(_loadCards);
};
const _loadData = () => {
  return Is.owner(UserModel.getLoggedInUserPermission()) ? _loadDataForOwner() : PaymentPlan2Model.Promises.fetch();
};
const initStripe = async function () {
  let {
    force = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!window.Stripe || force) {
    return Async.Promises.loadScriptAndThen('https://js.stripe.com/v2/', 'Stripe').then(_loadData);
  }
};
const refetchPaymentPlans = throttle(async () => {
  await PaymentPlan2Model.Promises.fetch();
  setTimeout(renderPaymentPlan, 0);
}, 1500, {
  leading: true,
  trailing: false
});
exports.init = () => {
  const handlePaymentPlanUpdate = () => {
    refetchPaymentPlans();
  };
  Event.onlyOn('paymentPlanUpdated.ManageBilling', handlePaymentPlanUpdate);
  SettingsPage.onDestroy('ManageBilling', () => {
    Event.off('paymentPlanUpdated.ManageBilling', handlePaymentPlanUpdate);
  });
  return initStripe({
    force: true
  });
};
exports.redirectHere = () => Utils.redirect(exports.route());
exports.openWithNoTracking = () => SettingsPage.loadPane(exports);
exports.open = e => {
  e?.preventDefault();

  // Send tracking event only when the plan tab is focused
  Event.once('manage-plan-link.tab-focus', () => sendPlanTableOpenedEvent(PLAN_TABLE_TRIGGER_LOCATIONS.BILLING_PANE));
  return exports.openWithNoTracking();
};
exports.openFromBanner = () => {
  sendPlanTableOpenedEvent(PLAN_TABLE_TRIGGER_LOCATIONS.GLOBAL_BANNER);
  return exports.openWithNoTracking();
};

// only call this method if the settings page has already been loaded
exports.openPane = callback => {
  Dialog.close({
    force: true
  });
  Router.pushState(exports.route(), exports.title);
  if ($(PARENT_SELECTOR).length > 0) {
    exports.openWithData(callback);
  } else {
    exports.render(callback);
  }
};

/** @deprecated Use PaymentPlan2.isManaged */
exports.isPlanManaged = () => {
  const plan2 = PaymentPlan2Model.getPlanForCurrentOrg();
  return PaymentPlan2Model.isManaged(plan2);
};
exports.renderManagedBillingPage = () => View.renderComponentDelayed({
  componentKey: 'ManagedBilling',
  component: _jsx(ManagedBilling, {})
}).html;
exports.render = callback => {
  if (Is.companyPage()) {
    // We pull a sample ID here until billing is refactored to reference only the company
    const {
      organizations
    } = Utils.getModelFromContext(this, 'Company');
    const id = _.get(organizations, '0.id') || OrganizationModel.getCurrentID();
    MultiOrgModel.updateState({
      id
    });
  } else if (Is.organizationsPage()) {
    const container = $(this).closest('.org');
    MultiOrgModel.updateState({
      id: Utils.data(container, 'id')
    });
  }
  renderTemplate({
    template: ManageBillingLoaderTemplate
  }, {
    active: 'manageBilling',
    me: UserModel.getLoggedInUserPermission()
  });
  exports.init().then(Feature.Promises.fetchAll).then(() => {
    if (Url.isCurrentPath(exports.route())) {
      exports.openWithData(callback);
      Event.on('CurrentUserRoleChanged.ManageBilling', () => {
        exports.open();
      });
      SettingsPage.onDestroy('ManageBilling', () => {
        Event.off('CurrentUserRoleChanged.ManageBilling');
        exports.destroyValidation();
      });
      Event.trigger('Ready.ManageBilling');
    }
  }).catch(e => {
    if (Url.isCurrentPath(exports.route())) {
      MessageController.error(e);
    }
  });
};
const renderTemplate = (_ref, data) => {
  let {
    template
  } = _ref;
  const html = template.render(data);
  $(SettingsPage.PARENT_SELECTOR).html(html);
};
const renderPaymentPlan = () => {
  const overviewData = getOverviewData();
  $('#overview-tab').html(ManageBillingOverviewTemplate.render(overviewData));
  $('#plan-tab').html(ManagePaymentPlanTemplate.render());
  renderComponents();
};
exports.openPaymentPlanTab = () => {
  ManagePaymentPlanController.open();
};
const getOverviewData = () => {
  const orgID = Globals.get('organizationID');
  const org = OrganizationModel.get({
    id: orgID
  }) || OrganizationModel.getCurrent();
  const company = CompanyModel.getFromOrg(org);
  const invoices = InvoicesModel.get({
    id: company.id
  });
  const card = CardModel.getCardForOrganization(org.id);
  const me = UserModel.getLoggedInUserPermission();
  const readOnly = !Is.owner(me);
  const paymentPlan2 = PaymentPlan2Model.getPlanForOrg(org.id);
  const activeUserCount = _.get(paymentPlan2, 'active_seats', 0);
  const hideCreditCardTab = PaymentPlan2Model.isOnEnterpriseTier(paymentPlan2) || PaymentPlan2Model.planTrialInfo(paymentPlan2) && !PaymentPlan2Model.planTrialInfo(paymentPlan2).has_selected_tier;
  return {
    active: 'manageBilling',
    card,
    me,
    org,
    company,
    paymentPlan2,
    invoices,
    activeUserCount,
    readOnly,
    shouldRenderFrequency: false,
    hideCreditCardTab
  };
};
const renderComponents = () => {
  document.querySelectorAll('.new-tiers-plan-summary').forEach(node => View.renderComponent({
    mountNode: node,
    component: PlanSummary
  }));
  View.renderComponent({
    containerSelector: '.new-plan-overview',
    component: PlanOverview
  });
};
exports.openWithData = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const orgID = Globals.get('organizationID');
  const org = OrganizationModel.get({
    id: orgID
  }) || OrganizationModel.getCurrent();
  const plan = PaymentPlan2Model.getPlanForOrg(org.id);
  if (!plan) {
    Log.error(new Error('No payment plan found!'));
    const loader = $('.inline-loader');
    View.replaceElement(loader, '<div class="blurb admin-only-action">' + '<div class="title">No Payment Plan data found.</div>' + '<p>We are still setting up your billing account, so please come back later.</p></div>');
    return false;
  }
  renderTemplate({
    template: ManageBillingTemplate
  }, getOverviewData());
  renderComponents();
  callback();
};
exports.renderNewTabIcon = id => View.renderComponentDelayed({
  componentKey: 'newTabIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    width: 13,
    fill: "currentColor",
    children: _jsx(Icon, {
      icon: "NewTab"
    })
  })
}).html;
exports.openUpgradeDialog = async function () {
  let {
    onComplete,
    tier
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let unmountDialog = null;
  const closeDialog = () => {
    unmountDialog && unmountDialog();
  };
  unmountDialog = View.renderComponent({
    containerSelector: DIALOG_CONTAINER_SELECTOR,
    component: _jsx(UpgradeDialog, {
      onClose: () => {
        closeDialog();
        onComplete && onComplete();
      },
      selectedTier: tier
    })
  }).unmountComponent;
  return false;
};
exports.destroyValidation = () => {
  Validator.destroy(PARENT_SELECTOR);
};
exports.renderBillingAlerts = () => View.renderComponentDelayed({
  componentKey: 'BillingAlertsNext',
  component: _jsx(BillingAlertsNext, {})
}).html;
exports.renderPaidSeatsRemaining = () => View.renderComponentDelayed({
  componentKey: 'PaidSeatsRemaining',
  component: _jsx(PaidSeatsRemaining, {})
}).html;
exports.renderReferralMesage = () => View.renderComponentDelayed({
  componentKey: 'ReferralMessage',
  component: _jsx(ReferralMessage, {})
}).html;
exports.openAdditonalBillingInfo = () => {
  if ($(SettingsPage.PARENT_SELECTOR).length > 0) {
    exports.openPane(() => {
      $('#manage-invoices-link').click();
    });
  } else {
    exports.open();
  }
  return false;
};
exports.addAdditionalInformation = () => {
  const parentSelector = '#manage-billing-info';
  const buttonSelector = `${parentSelector} .update-info`;
  const context = $(parentSelector);
  const data = Utils.formData(context);
  const oldButtonHTML = $(buttonSelector).html();
  View.changeButtonToSaving(buttonSelector);
  PaymentPlan2Model.updatePlan(data, err => {
    if (err) {
      FlashController.error(parentSelector, 'We were unable to update your billing information.', err);
    } else {
      FlashController.success(parentSelector, 'Your billing information has been updated.');
    }
    View.revertButtonState(buttonSelector, oldButtonHTML);
  });
  return false;
};
export { exports as default };