import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import * as LocalStorage from 'utils/localStorage';
const NAV_EXPANDED_KEY = 'navIsExpanded';
const makeNavSize = () => makeVar(LocalStorage.getValue(NAV_EXPANDED_KEY) !== false);
let navSize;
export const useIsNavExpanded = () => {
  if (!navSize) navSize = makeNavSize();
  const isExpanded = useReactiveVar(navSize);
  const toggleExpanded = useCallback(() => {
    const newValue = !isExpanded;
    navSize(newValue);
    LocalStorage.setValue(NAV_EXPANDED_KEY, newValue);
  }, [isExpanded]);
  return [isExpanded, toggleExpanded];
};