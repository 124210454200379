import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';
export const useDebounce = (callback, timeout, options) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: See below
  const debouncedCallback = useMemo(() => debounce(() => {
    ref.current?.();
  }, timeout, {
    leading: true,
    ...options
  }),
  // We do not expect the options object to change between re-evaluations of the hook,
  // therefore to prevent our debouncedCB fn from being redeclared unnecessarily we omit the options obj from the dep array here.
  [timeout]);
  return debouncedCallback;
};