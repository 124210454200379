import { getExternalLinks } from 'data/entity/externalLink';
import { DeprecatedIconExternalLinkChip, ExternalLinkChip } from './components';
import { useNamedExternalLinks } from './hooks';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const externalLinkConfigs = getExternalLinks();
export const ExternalLinkChips = _ref => {
  let {
    externalLinks
  } = _ref;
  const {
    deskExternalLinks,
    freshdeskExternalLinks,
    frontExternalLinks,
    figmaExternalLinks,
    gitHubExternalLinks,
    helpScoutExternalLinks,
    intercomExternalLinks,
    jiraServiceDeskExternalLinks,
    kustomerExternalLinks,
    rollbarExternalLinks,
    sentryExternalLinks,
    zendeskExternalLinks
  } = useNamedExternalLinks(externalLinks);
  if (!externalLinks?.length) return null;
  return _jsxs(_Fragment, {
    children: [_jsx(DeprecatedIconExternalLinkChip, {
      count: deskExternalLinks.length,
      iconURL: externalLinkConfigs.DESK.ICON,
      type: externalLinkConfigs.DESK.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: freshdeskExternalLinks.length,
      iconURL: externalLinkConfigs.FRESHDESK.ICON,
      type: externalLinkConfigs.FRESHDESK.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: frontExternalLinks.length,
      iconURL: externalLinkConfigs.FRONT.ICON,
      type: externalLinkConfigs.FRONT.NAME
    }), _jsx(ExternalLinkChip, {
      count: figmaExternalLinks.length,
      icon: "Figma",
      type: externalLinkConfigs.FIGMA.NAME
    }), _jsx(ExternalLinkChip, {
      count: gitHubExternalLinks.length,
      icon: "Github",
      type: externalLinkConfigs.GITHUB.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: helpScoutExternalLinks.length,
      iconURL: externalLinkConfigs.HELPSCOUT.ICON,
      type: externalLinkConfigs.HELPSCOUT.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: intercomExternalLinks.length,
      iconURL: externalLinkConfigs.INTERCOM.ICON,
      type: externalLinkConfigs.INTERCOM.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: jiraServiceDeskExternalLinks.length,
      iconURL: externalLinkConfigs.JIRA_SERVICE_DESK.ICON,
      type: externalLinkConfigs.JIRA_SERVICE_DESK.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: kustomerExternalLinks.length,
      iconURL: externalLinkConfigs.KUSTOMER.ICON,
      type: externalLinkConfigs.KUSTOMER.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: rollbarExternalLinks.length,
      iconURL: externalLinkConfigs.ROLLBAR.ICON,
      type: externalLinkConfigs.ROLLBAR.NAME
    }), _jsx(DeprecatedIconExternalLinkChip, {
      count: sentryExternalLinks.length,
      iconURL: externalLinkConfigs.SENTRY.ICON,
      type: externalLinkConfigs.SENTRY.NAME
    }), _jsx(ExternalLinkChip, {
      count: zendeskExternalLinks.length,
      icon: "Zendesk",
      type: externalLinkConfigs.ZENDESK.NAME
    })]
  });
};