import { StoryGroupByIterationFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TableGroupHeaderLinks } from '@clubhouse/shared/components/Table';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { IterationWithIcon } from 'components/gql/iteration/IterationWithIcon';
import { getHref } from 'components/gql/iteration/links';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StoryGroupByIterationFragment = StoryGroupByIterationFragmentFragmentDoc;
export const IterationGroupHeader = _ref => {
  let {
    iteration
  } = _ref;
  return _jsxs(Spaced, {
    horizontally: true,
    amount: 12,
    inline: true,
    align: "center",
    children: [_jsx(GroupByHeader, {
      entityType: Nouns.Iteration,
      children: typeof iteration?.name === 'string' && iteration?.state ? _jsx(IterationWithIcon, {
        name: iteration.name,
        state: iteration.state
      }) : 'No Iteration'
    }), typeof iteration?.publicId === 'number' && _jsx(TableGroupHeaderLinks, {
      entityName: "Iteration",
      url: getHref(iteration.publicId, true)
    })]
  });
};
IterationGroupHeader.displayName = "IterationGroupHeader";