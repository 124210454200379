import Boot from '../../../core/js/modules/boot';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import FlashController from '../../../core/js/controllers/flash';
import HotReloadController from '../../../core/js/controllers/hotReload';
import Router from '../../../core/js/_frontloader/router';
import SlackAuth from './slackAuth';
import GsheetsAuth from './gsheetsAuth';
import Url from '../../../core/js/modules/url';
const exports = {};
const _init = () => {
  if (Url.isSlackAuth()) {
    return SlackAuth.handleAuth().finally(() => {
      exports.handleDefault();
    });
  } else if (Url.isGsheetsAuth()) {
    return GsheetsAuth.handleAuth().finally(() => {
      exports.handleDefault();
    });
  } else if (Url.isBitbucket()) {
    exports.handleBitbucketAuth();
    exports.handleDefault();
  } else if (Url.isGithubAuth()) {
    exports.handleGithubAuth();
    exports.handleDefault();
  } else {
    exports.handleDefault();
  }
};
exports.handleBitbucketAuth = () => {
  const {
    error,
    error_description
  } = Url.parseLocationSearch();
  if (error) {
    const title = 'There was an error authenticating to Bitbucket.';
    const message = `The message we got back was "${error}: ${error_description}".`;
    FlashController.saveErrorMessage('#individual-integration', title, message);
  }
};
exports.handleGithubAuth = () => {
  const {
    setup_status,
    setup_error
  } = Url.parseLocationSearch();
  if (setup_status === 'success') {
    FlashController.saveSuccessMessage('#individual-integration', 'Your GitHub integration has been updated!');
  } else if (setup_status === 'error') {
    FlashController.saveErrorMessage('#individual-integration', 'There was a problem with your GitHub integration', setup_error);
  }
};
exports.handleDefault = () => {
  const currentRoute = Url.getCurrentPathnameWithHash();
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
    if (Router.hasRouteForCurrentUrl()) {
      Router.saveRoute(currentRoute);
    }
    let page = 'dashboard';
    if (currentRoute.indexOf('/organizations') === 0) {
      page = 'organizations';
    }
    HotReloadController.loadURL(Url.getSlugPath() + '/' + page, page);
  });
};
exports.boot = () => {
  Boot.initializePage(_init, _init);
};
export { exports as default };