import { useMemo } from 'react';
import { useStateWhenMounted } from '@clubhouse/shared/hooks/useStateWhenMounted';
import { useLatestRef } from './useLatestRef';
import { useUpdateEffect } from './useUpdateEffect';
export function useToggleState() {
  let initialValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let onChange = arguments.length > 1 ? arguments[1] : undefined;
  const [state, setState] = useStateWhenMounted(initialValue);
  const onChangeRef = useLatestRef(onChange);
  const toggle = useMemo(() => {
    return Object.assign(() => setState(val => !val), {
      on: () => setState(true),
      off: () => setState(false),
      set: val => setState(val),
      toggleOrSet: val => {
        setState(prev => val === undefined ? !prev : val);
      }
    });
  }, [setState]);
  useUpdateEffect(() => {
    onChangeRef.current?.(state);
  }, [onChangeRef, state]);
  return [state, toggle];
}