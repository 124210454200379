/** @deprecated Use @clubhouse/assets instead */
export const getPrefix = () => {
  return window._STATIC_ASSETS_URL || '';
};

/** @deprecated Use @clubhouse/assets instead */
export const getPrefixedPath = path => {
  if (!path.startsWith('/')) {
    throw new Error(`Prefixed path ${path} does not start with /`);
  }
  return getPrefix() + path;
};

/** @deprecated Use @clubhouse/assets instead */
export default {
  getPrefix,
  getPrefixedPath
};