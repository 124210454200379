import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '@clubhouse/shared/utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Milestone" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Milestone = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill = 'currentColor'
  } = _ref;
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill,
    children: _jsx(Icon, {
      icon: "Milestone"
    })
  });
};
Milestone.displayName = "Milestone";