import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import flatten from 'lodash/flatten';
import { useCallback, useMemo } from 'react';
import { AsyncSelect, Divider, SectionHeader, SelectOptionChecked } from '@clubhouse/shared/components/Select';
import { useAsyncSelect } from '@clubhouse/shared/components/Select/AsyncSelect/useAsyncSelect';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { groupBy, insertIf } from '@clubhouse/shared/utils';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { intersperse } from '@clubhouse/shared/utils/intersperse';
import { CategoryHeader } from '../Select/AsyncSelect/CategoryHeader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const addUnselectedItemsToSelectedValues = (selectedValues, items) => selectedValues.concat(items.map(item => item.value).filter(val => typeof val === 'string' && !selectedValues.includes(val)));
const removeItemsFromSelectedValues = (selectedValues, items) => selectedValues.filter(value => items.every(item => item.value !== value));
const mapSectionsToItems = (sections, selectedValues) => {
  if (!sections) return null;
  const selectedValuesSet = new Set(selectedValues);
  let currentCategoryId = '';
  const listsWithHeaders = sections.map(section => {
    const {
      categoryId = '',
      categoryTitle = '',
      sectionId,
      sectionTitle,
      allowBulkSelect
    } = section;
    const isSectionFullySelected = section.items.every(item => !!item.value && selectedValuesSet.has(item.value));
    const selectAllValues = isSectionFullySelected ? removeItemsFromSelectedValues(selectedValues, section.items) : addUnselectedItemsToSelectedValues(selectedValues, section.items);
    const sectionItems = [...insertIf(!!categoryTitle && categoryId !== currentCategoryId, [{
      Component: () => _jsx(CategoryHeader, {
        name: categoryTitle
      }),
      key: `${categoryId}-category`
    }]), ...insertIf(!!sectionTitle, [{
      Component: _ref => {
        let {
          isActive
        } = _ref;
        return _jsx(SectionHeader, {
          showSelectAll: !!allowBulkSelect,
          isSelected: isSectionFullySelected,
          isHighlighted: isActive,
          name: sectionTitle
        });
      },
      key: `${sectionId}-section`,
      ...(allowBulkSelect ? {
        selectedValues: selectAllValues
      } : {})
    }]), ...section.items.map(item => ({
      key: item.value,
      Component: SelectOptionChecked,
      ...item
    }))];
    currentCategoryId = categoryId;
    return sectionItems;
  });
  return flatten(intersperse({
    items: listsWithHeaders,
    separator: _ref2 => {
      let {
        index
      } = _ref2;
      return Divider(`${index}`);
    }
  }));
};
const FailedFetch = () => {
  return _jsx(Spaced, {
    vertically: true,
    amount: "space2",
    children: _jsx(ToastText, {
      children: "Something went wrong. Try resetting the filter."
    })
  });
};
FailedFetch.displayName = "FailedFetch";
export const useItems = _ref3 => {
  let {
    sections,
    selectedValues,
    staticItems,
    filtered
  } = _ref3;
  return useMemo(() => {
    const items = mapSectionsToItems(sections, selectedValues);
    if (!items) return null;
    return [...insertIf(items.length > 0 && !filtered, staticItems || []), ...items];
  }, [sections, selectedValues, staticItems, filtered]);
};
export const useFetchGroupedOptions = _ref4 => {
  let {
    selectedValues,
    staticItems = emptyArray,
    fetchOptions,
    maxSelected,
    getSection,
    onError
  } = _ref4;
  const fetcher = useCallback(async _ref5 => {
    let {
      inputValue
    } = _ref5;
    try {
      const connection = await fetchOptions({
        inputValue
      });
      const groupedEdges = groupBy(connection?.edges ?? [], edge => edge.groupKey ?? '');
      const totalItemCount = connection?.pageInfo?.totalSize ?? connection?.edges?.length ?? undefined;
      const info = {
        ...connection?.pageInfo,
        hasMultiplePages: typeof totalItemCount === 'number' ? totalItemCount > (connection?.edges?.length ?? 0) : false,
        isFiltered: inputValue.trim().length > 0,
        maxSelected
      };
      const sections = [...groupedEdges.entries()].map(_ref6 => {
        let [groupKey, options] = _ref6;
        return getSection(groupKey, options, info);
      });
      return {
        sections,
        totalItemCount
      };
    } catch (e) {
      if (e instanceof Error) onError?.(e);
      addToast({
        kind: 'alert',
        Content: FailedFetch,
        timeout: 10_000
      });
    }
  }, [fetchOptions, getSection, maxSelected, onError]);
  const {
    sections,
    totalItemCount,
    fetchSections,
    refetchSections,
    filtered
  } = useAsyncSelect(fetcher);
  const items = useItems({
    sections,
    filtered,
    selectedValues,
    staticItems
  });
  return {
    items,
    totalItemCount,
    fetchSections,
    refetchSections
  };
};
export function GroupedOptionsSelect(_ref7) {
  let {
    'data-perma-id': dataPermaId,
    selectedValues,
    fetchSections,
    items,
    totalItemCount,
    onChange,
    TargetComponent,
    targetComponentProps,
    label,
    unit,
    maxSelected,
    isDisabled = false,
    includeWrapper,
    onOpen,
    onClose,
    isOpen,
    isSearchable,
    FooterComponent,
    includeTypeahead
  } = _ref7;
  const handleClose = useCallback(() => {
    fetchSections();
    onClose?.();
  }, [fetchSections, onClose]);
  const handleChange = useCallback(values => {
    onChange(values, (items || []).filter(item => !!item.value && values.includes(item.value)));
  }, [onChange, items]);
  return _jsx(AsyncSelect, {
    "data-perma-id": dataPermaId,
    isSearchable: isSearchable,
    width: AsyncSelect.WIDTH.LARGE,
    label: label,
    items: items,
    isDisabled: isDisabled,
    totalItemCount: totalItemCount,
    onChange: handleChange,
    onFetch: fetchSections,
    TargetComponent: TargetComponent,
    targetComponentProps: targetComponentProps,
    selectedValues: selectedValues,
    onClose: handleClose,
    unit: unit,
    maxSelected: maxSelected,
    maxHeight: "50vh",
    includeWrapper: includeWrapper,
    onOpen: onOpen,
    isOpen: isOpen,
    FooterComponent: FooterComponent,
    includeTypeahead: includeTypeahead
  });
}
GroupedOptionsSelect.displayName = "GroupedOptionsSelect";