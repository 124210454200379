import { getCurrentPage } from 'utils/navigation';
import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const chartStyle = `
  .c3-bar {
    fill-opacity: .75;
  }
  .c3-bar._expanded_ {
    fill-opacity: 1;
  }
`;
export const CumulativeFlowReportCard = props => {
  const page = getCurrentPage();
  return _jsx(ReportCard, {
    ...props,
    title: "Cumulative Flow Diagram",
    description: `Work ${page === 'reports' || page === 'project' ? 'completed' : 'activity'} over time, grouped by workflow state.`,
    chartStyle: chartStyle,
    Explanation: Explanation,
    Filters: Filters
  });
};
CumulativeFlowReportCard.displayName = "CumulativeFlowReportCard";