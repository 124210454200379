import { useCallback, useMemo } from 'react';
import { QueryEpicsTableRowsDocument } from '@clubhouse/datalayer/types';
import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { ScrollContainerGroup } from '@clubhouse/shared/components/Table/internalComponents/ScrollContainer';
import { EpicRow, EpicRowForInfiniteScroll } from 'components/gql/epics/table/EpicsTable';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { useQueryFunctions } from 'gql/hooks/UseQueryContext';
import { useEpicReordering } from '../../hooks/useEpicReordering';
import { createGroups } from './groupBy';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const GroupedTable = _ref => {
  let {
    group,
    offset,
    isLoading,
    onReorder,
    infiniteScroll,
    ...props
  } = _ref;
  return _jsx(StatelessTable, {
    indexOffset: offset,
    HeaderComponent: group.header,
    isCollapsible: true,
    initiallyCollapsed: false,
    allItems: group.items,
    items: group.items,
    onReorder: onReorder,
    RowComponent: infiniteScroll ? EpicRowForInfiniteScroll : EpicRow,
    ...props,
    ...(isLoading && LOADING_STATE)
  });
};
GroupedTable.displayName = "GroupedTable";
export const GroupedTables = _ref2 => {
  let {
    groupBy,
    items,
    columns,
    sortColumn,
    sortDirection,
    onSort,
    isLoading,
    isStale,
    footer,
    updateQuery,
    infiniteScroll
  } = _ref2;
  const {
    cancel,
    restart
  } = useQueryFunctions();
  const onReorder = useEpicReordering({
    epics: items,
    sortDirection,
    updateQuery
  });
  const cancelQuery = useCallback(() => {
    cancel([QueryEpicsTableRowsDocument]);
  }, [cancel]);
  const restartQuery = useCallback(() => {
    restart([QueryEpicsTableRowsDocument]);
  }, [restart]);
  const handleReorder = useCallback(async change => {
    await onReorder(change);
    restartQuery();
  }, [onReorder, restartQuery]);
  const groups = useMemo(() => createGroups(groupBy, items), [items, groupBy]);
  let offset = 0;
  return _jsxs(ScrollContainerGroup, {
    children: [groups.map(group => {
      const currOffset = offset;
      offset += group.items.length;
      return _jsx(GroupedTable, {
        infiniteScroll: infiniteScroll,
        group: group,
        offset: currOffset,
        columns: columns,
        onStartReorder: cancelQuery,
        onCancelReorder: restartQuery,
        onReorder: handleReorder,
        isLoading: isLoading,
        onSort: onSort,
        sortDirection: sortDirection,
        sortColumn: sortColumn
      }, group.key || 'none');
    }), footer, isStale && _jsx(StaleLoading, {})]
  });
};
GroupedTables.displayName = "GroupedTables";