import * as UserWarningTemplate from 'app/client/core/views/templates/userWarning.html';
import PanelController from './panel';
const exports = {};
exports.open = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  exports.panel = PanelController.open({
    id: options.id,
    center: true,
    css: {
      'z-index': 2001
    },
    html: UserWarningTemplate.render(options),
    onOpen: panel => {
      panel.overlay.css({
        'z-index': 2000
      });
      $('#approve-button').focus();
    },
    onApprove: options.onApprove,
    target: options.target,
    width: 460
  });
  return false;
};
exports.approve = () => {
  const approvalFn = _.get(exports.panel, 'options.onApprove', _.noop);
  approvalFn(exports.panel);
  return exports.close();
};
exports.close = () => {
  const panel = exports.panel;
  if (panel) {
    PanelController.close(panel);
  }
  return false;
};
export { exports as default };