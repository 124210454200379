import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { useEntityStateFilter } from './utils';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { getById, useTeamScopedWorkflows } from 'data/entity/team';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const List = _ref => {
  let {
    activeId
  } = _ref;
  const teams = useTeamScopedWorkflows();
  return _jsx(ComboboxSelect.List, {
    children: teams.map(team => _jsx(ComboboxSelect.Option, {
      value: String(team.id),
      isSelected: String(team.id) === activeId,
      children: _jsx(Emojify, {
        children: team.name
      })
    }, team.id))
  });
};
List.displayName = "List";
export function WorkflowSelector() {
  let label = 'None';
  const [entityStateFilter, setEntityStateFilter] = useEntityStateFilter();
  if (entityStateFilter?.workflowTeamId) {
    const workflowTeam = getById(entityStateFilter.workflowTeamId);
    if (workflowTeam) label = workflowTeam.name;
  }
  return _jsxs(ComboboxSelect, {
    onChange: teamId => {
      setEntityStateFilter({
        workflowTeamId: teamId ? Number(teamId) : null,
        workflowStateId: null
      });
    },
    children: [_jsx(ComboboxSelect.Trigger, {
      children: _jsxs("button", {
        className: "action mini light-gray",
        children: [_jsx(Emojify, {
          children: label
        }), " ", _jsx("span", {
          className: "fa fa-caret-down"
        })]
      })
    }), _jsx(ComboboxSelect.Menu, {
      children: _jsx(List, {
        activeId: String(entityStateFilter?.workflowTeamId)
      })
    })]
  });
}
WorkflowSelector.displayName = "WorkflowSelector";