import * as Event from 'app/client/core/js/_frontloader/event';
export const trigger = function () {
  return Event.trigger(...arguments);
};
export const onlyOn = function () {
  return Event.onlyOn(...arguments);
};
export const on = function () {
  return Event.on(...arguments);
};
export const off = function () {
  return Event.off(...arguments);
};