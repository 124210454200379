import { EpicHealthFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { EpicHealthUpdateSelect } from 'components/gql/epics/EpicHealthUpdateSelect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicHealthFieldFragment = EpicHealthFieldFragmentDoc;
export function EpicHealthFieldComponent(_ref) {
  let {
    entity,
    onOpen,
    onClose,
    onChangeCurrent,
    onChangeHistory
  } = _ref;
  return _jsx(EpicHealthUpdateSelect, {
    ...entity,
    showCommentCount: true,
    onOpen: onOpen,
    onClose: onClose,
    onChangeCurrent: onChangeCurrent,
    onChangeHistory: onChangeHistory
  });
}
EpicHealthFieldComponent.displayName = "EpicHealthFieldComponent";
export const EpicHealthField = () => ({
  name: 'currentHealthStatus',
  displayName: 'Health',
  Component: EpicHealthFieldComponent,
  LoadingComponent: null,
  width: 145,
  sort: true
});