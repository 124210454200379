import ProductModel from 'app/client/core/js/models/product';
import { DISPLAY_CONFIG } from 'app/client/core/js/modules/constants';
import { getAvailableTiers } from 'utils/tiers';
export const getProducts = () => getAvailableTiers().map(_ref => {
  let {
    id
  } = _ref;
  return ProductModel.get({
    display_key: id
  });
});
export const getProductTitle = product => {
  if (!product) return null;
  return DISPLAY_CONFIG[product.display_key]?.displayName ?? null;
};
export const isSameProduct = (left, right) => left.display_key === right.display_key;
export const getProductById = idToFind => ProductModel.all().find(_ref2 => {
  let {
    id
  } = _ref2;
  return id === idToFind;
});