import { DeleteHealthReactionDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { CreateHealthReactionDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { UpdateHealthStatusDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
const UpdateHealthStatus = UpdateHealthStatusDocument;
const CreateHealthReaction = CreateHealthReactionDocument;
const DeleteHealthReaction = DeleteHealthReactionDocument;
export function useUpdateHealthStatusMutation() {
  const [update] = useMutation(UpdateHealthStatus);
  return update;
}
export function useCreateHealthReactionMutation() {
  const [createReaction] = useMutation(CreateHealthReaction);
  return createReaction;
}
export function useDeleteHealthReactionMutation() {
  const [deleteReaction] = useMutation(DeleteHealthReaction);
  return deleteReaction;
}