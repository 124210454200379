import { VisuallyHidden } from '@react-aria/visually-hidden';
import moment from 'moment';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { TIERS } from '@clubhouse/shared/types';
import { pluralize } from '@clubhouse/shared/utils/pluralize';
import { getAvailableSeats, planIsTier, useCurrentPlan, useSubscriptionDetails } from 'data/entity/paymentPlan2';
import { getLoggedInUserPermission } from 'data/entity/user';
import { ownerOnly } from 'utils/is';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const PaidSeatsRemaining = () => {
  const {
    paymentPlan
  } = useCurrentPlan();
  const availableSeats = getAvailableSeats(paymentPlan);
  const subscriptionDetails = useSubscriptionDetails(paymentPlan);
  const currentUserPermission = getLoggedInUserPermission();
  // current_period_end will be nil when a subscription is first created. We use a short
  // (1 minute) trial to allow our tax system to add sales tax to the new invoice.
  // When in that short trial, the backend does not provide a current_period_end,
  // so we show different wording in that case.
  const ownerDetails = ownerOnly(currentUserPermission) && subscriptionDetails?.current_period_end ? ` before ${moment(subscriptionDetails?.current_period_end).format(DATE_FORMAT.SHORT_DATE)} ` : '';
  if (planIsTier(paymentPlan, TIERS.FREE_LIMITED) || !availableSeats) return null;
  return _jsxs("p", {
    children: ["Add ", pluralize({
      count: availableSeats,
      singular: 'user',
      plural: 'users'
    }), ownerDetails, " at no extra cost.", ' ', _jsx(Tooltip, {
      placement: Tooltip.PLACEMENT.BOTTOM,
      content: "Since you removed user(s) in the middle of your billing cycle, you can add user(s) before the end of your billing cycle at no extra cost. Click to learn more.",
      children: _jsxs("a", {
        href: KNOWLEDGE_BASE_SLUGS.PRICING_AND_BILLING,
        target: "_blank",
        rel: "noreferrer",
        children: [_jsx("span", {
          className: "fa fa-question-circle",
          "aria-hidden": "true"
        }), _jsx(VisuallyHidden, {
          children: "Pricing and Billing FAQ"
        })]
      })
    })]
  });
};
PaidSeatsRemaining.displayName = "PaidSeatsRemaining";