import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export const YOUTUBE_LINK_PATTERN = /^(https?:\/\/)?(www\.)?(((youtube\.com)\/((embed\/)|(watch\/)|(watch\?v=)))|(youtu\.be\/))[a-zA-Z0-9_-]+(?:\?.*)?/i;
export const isYoutubeLink = url => YOUTUBE_LINK_PATTERN.test(url);
export const getYoutubeEmbedUrl = url => {
  if (!isYoutubeLink(url)) return null;
  try {
    const u = new URL(url);
    if (u.hostname === 'youtu.be') {
      u.pathname = `/embed${u.pathname}`;
    } else if (/^\/watch\/[a-zA-Z0-9_-]+$/.test(u.pathname)) {
      const [,, id] = u.pathname.split('/');
      if (id) u.pathname = `/embed/${id}`;
    } else if (u.pathname.startsWith('/watch') && u.searchParams.has('v')) {
      u.pathname = `/embed/${u.searchParams.get('v')}`;
    }
    u.protocol = 'https';
    u.hostname = 'www.youtube.com';
    u.searchParams.delete('v');
    return u.toString();
  } catch {
    return null;
  }
};