import { UpdateStoryPositionDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { useMutation } from 'gql';
import { useCallback } from 'react';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const UpdateStoryPosition = UpdateStoryPositionDocument;
const toOrderPosition = {
  top: 'first',
  bottom: 'last'
};
export const useUpdateStoryPosition = () => {
  const [save] = useMutation(UpdateStoryPosition);
  return useCallback(_ref => {
    let {
      storyId,
      moveTo,
      onError
    } = _ref;
    save({
      variables: {
        storyId,
        input: {
          moveTo: toOrderPosition[moveTo]
        }
      },
      onError
    }).then(() => {
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => _jsx(Spaced, {
          vertically: true,
          amount: "space2",
          children: _jsxs(ToastText, {
            children: ["Story was moved to the ", moveTo, " of its current workflow state."]
          })
        })
      });
    }).catch(() => {
      addToast({
        kind: 'alert',
        timeout: 5_000,
        Content: () => _jsx(ToastText, {
          children: "Failed to move story."
        })
      });
    });
  }, [save]);
};