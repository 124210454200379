import { createBrowserHistory } from 'history';
import compact from 'lodash/compact';
import { analytics } from 'utils/monitoring/monitors/analytics';
export const createHistory = () => {
  if (window._CLUBHOUSE_HISTORY) return window._CLUBHOUSE_HISTORY;

  // TODO: Find better way of managing globals
  const basename = compact(window.location.pathname.split('/'))[0] + '/write';
  window._CLUBHOUSE_HISTORY = createBrowserHistory({
    basename
  });
  window._CLUBHOUSE_HISTORY.listen(location => {
    try {
      window.ga('set', 'page', location.pathname + location.search);
      window.ga('send', 'pageview');
    } catch {}
    try {
      analytics.page();
    } catch {}
  });
  return window._CLUBHOUSE_HISTORY;
};
export const getHistory = () => window._CLUBHOUSE_HISTORY || createHistory();