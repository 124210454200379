import { EmojiMenu } from 'components/shared/EmojiPicker';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EMOJI_PREFIX = ':';
const EMOJI_FRAGMENT_REGEX = new RegExp(`^${EMOJI_PREFIX}[a-zA-Z0-9\\_\\-\\+]+$`);
export const EmojiAutocomplete = {
  hasPrefix: true,
  test: text => !!text && EMOJI_FRAGMENT_REGEX.test(text),
  Component: _ref => {
    let {
      triggerRef,
      fragment
    } = _ref;
    return _jsx(EmojiMenu, {
      withSkinToneSelector: false,
      isModal: false,
      isSearchable: false,
      triggerRef: triggerRef,
      filter: fragment
    });
  }
};