import { isMac } from '@clubhouse/shared/utils';
import { hasModifierKey } from '@clubhouse/shared/utils/keyboard';
import { getLine, getLines, getSelection } from '../utils';
export const blockquoteCommand = function () {
  let {
    disableAutoQuotes
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    key: 'b',
    modifiers: isMac() ? 'cmd+shift' : 'ctrl+shift',
    name: 'Blockquote',
    icon: 'Blockquote',
    fn(textarea, setValue) {
      const {
        selectionStart,
        selectionEnd,
        value
      } = getSelection(textarea);
      if (selectionStart !== selectionEnd) {
        const {
          lines,
          before,
          after,
          startOfFirstLine
        } = getLines(value, selectionStart, selectionEnd);
        let selStart = selectionStart;
        let selEnd = selectionEnd;
        const linesStartingWithQuote = lines.filter(line => /^>+[ ]*/.test(line));
        const removeQuotes = linesStartingWithQuote.length > 0 && linesStartingWithQuote.length === lines.length;
        const newValue = lines.map((line, i) => {
          const hasQuote = /^>+[ ]*/.test(line);
          if (removeQuotes) {
            if (!hasQuote) return line;
            let change = 0;
            if (line.startsWith('> ')) change = 2;else change = 1;
            if (i === 0 && selStart > startOfFirstLine) selStart -= change;
            selEnd -= change;
            return line.substring(change);
          } else {
            if (hasQuote) return line;
            if (i === 0 && selStart > startOfFirstLine) selStart += 2;
            selEnd += 2;
            return `> ${line}`;
          }
        }).join('\n');
        setValue(`${before}${newValue}${after}`, {
          selectionStart: selStart,
          selectionEnd: selEnd
        });
      } else {
        const {
          line,
          startOfLine,
          endOfLine
        } = getLine(value, selectionStart);
        const before = value.substring(0, startOfLine);
        const after = value.substring(endOfLine);
        const hasQuote = /^>+[ ]*/.test(line);
        if (hasQuote) {
          const change = line.startsWith('> ') ? 2 : 1;
          setValue(`${before}${line.substring(change)}${after}`, {
            selectionStart: selectionStart - (selectionStart > startOfLine ? change : 0),
            selectionEnd: selectionEnd - (selectionStart > startOfLine ? change : 0)
          });
        } else {
          setValue(`${before}> ${line}${after}`, {
            selectionStart: selectionStart + 2,
            selectionEnd: selectionEnd + 2
          });
        }
      }
    },
    events: {
      keydown(event, textarea, setValue) {
        if (disableAutoQuotes) return;
        if (event.key === 'Enter' && !hasModifierKey(event)) {
          const {
            value,
            selectionStart,
            selectionEnd,
            before,
            after
          } = getSelection(textarea);
          if (selectionStart === selectionEnd) {
            const {
              line
            } = getLine(value, selectionStart);
            if (/^>+[ ]*/.test(line)) {
              event.preventDefault();
              const [, level, spaces] = /^(>+)([ ]*)/.exec(line) || [];
              setValue(`${before}\n${level}${spaces}${after}`, {
                selectionStart: selectionStart + level.length + spaces.length + 1,
                // +1 for new
                selectionEnd: selectionEnd + level.length + spaces.length + 1
              });
            }
          }
        }
      }
    }
  };
};