import { UnarchiveStoryDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { UnarchiveStoryDataFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
UnarchiveStoryDataFragmentDoc;
const UnarchiveStoryMutation = UnarchiveStoryDocument;
export function useUnarchiveStoryMutation() {
  const [setUnarchived] = useMutation(UnarchiveStoryMutation);
  return useCallback(id => setUnarchived({
    variables: {
      id
    },
    optimisticResponse: {
      __typename: 'Mutation',
      storyUpdate: {
        __typename: 'StoryPayload',
        story: {
          __typename: 'Story',
          id,
          archived: false
        }
      }
    }
  }), [setUnarchived]);
}