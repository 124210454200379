export const noop = () => {};
export const asyncNoop = () => Promise.resolve();
export const startCase = str => {
  str = str.trim();
  if (str === '') return str;
  const parts = str.split(/\s+/);
  const capitalizedParts = parts.map(p => {
    return `${p[0].toUpperCase()}${p.substr(1)}`;
  });
  return capitalizedParts.join(' ');
};