import { EpicMutateEpicStateDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicMutateEpicStateFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { UpdateEpicStateFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
import { useIsJoyEnabled } from 'gql/hooks/useIsJoyEnabled';
import { celebrateFall } from 'utils/celebrate';
const UpdateEpicStateFragment = UpdateEpicStateFragmentDoc;
EpicMutateEpicStateFragmentFragmentDoc;
const UPDATE_EPIC_STATE = EpicMutateEpicStateDocument;
export function useUpdateEpicState() {
  const [updateEpicState] = useMutation(UPDATE_EPIC_STATE);
  const isJoyEnabled = useIsJoyEnabled();
  return useCallback((epic, epicState) => updateEpicState({
    variables: {
      id: epic.id,
      input: {
        epicState: epicState.id
      }
    },
    optimisticResponse: {
      __typename: 'Mutation',
      epicUpdate: {
        __typename: 'EpicPayload',
        epic: {
          __typename: 'Epic',
          id: epic.id,
          epicState: {
            __typename: 'EpicState',
            ...epicState
          }
        }
      }
    },
    async onCompleted(mutationResponse) {
      if (mutationResponse?.epicUpdate?.epic?.epicState?.type === 'done' && (await isJoyEnabled())) {
        celebrateFall();
      }
    }
  }), [isJoyEnabled, updateEpicState]);
}