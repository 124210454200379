import { Button } from '@clubhouse/shared/components/Button';
import { clearPersistentCache } from 'gql/utils/cacheUtils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const clearCacheAndReload = async () => {
  await clearPersistentCache();
  window.location.href = `${window.location.origin}${window.location.pathname}`;
};
export const ClearCacheAndReload = () => {
  return _jsx(Button, {
    kind: Button.KIND.WARNING,
    onClick: clearCacheAndReload,
    children: "Clear cache and reload page"
  });
};
ClearCacheAndReload.displayName = "ClearCacheAndReload";