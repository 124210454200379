import Logo from '@clubhouse/assets/png/third-party-logos/dropbox_logo.png';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { useIntegrationState } from 'data/entity/organization';
import { isLoggedInUserAdmin, isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Dropbox() {
  const [isEnabled, toggleEnabled] = useIntegrationState(INTEGRATION_TYPE.DROPBOX);
  const isLoading = typeof isEnabled !== 'boolean';
  const readOnly = isReadOnly();
  const admin = isLoggedInUserAdmin();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.DROPBOX
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Dropbox"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Dropbox Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://www.dropbox.com/",
          children: "Dropbox"
        }), " allows you to store, synchronize and share files online with public access. Our Dropbox integration allows you to attach files directly to a", ' ', BRAND.NAME, " story."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_DROPBOX,
          children: "our instructions"
        }), " for how to upload files from your Dropbox account."]
      }), _jsx(Layout.SectionTitle, {
        children: "Integration Status"
      }), isLoading ? _jsx(IntegrationToggle.Loading, {}) : _jsx(IntegrationToggle, {
        isReadOnly: !admin,
        isEnabled: isEnabled,
        onChange: async () => {
          const success = await toggleEnabled();
          if (!success) {
            const newValue = !isEnabled;
            addToast({
              kind: 'alert',
              timeout: 5000,
              Content: () => _jsxs(ToastText, {
                children: ["Unable to ", newValue ? 'enable' : 'disable', " integration."]
              })
            });
          }
        }
      })]
    })]
  });
}
Dropbox.displayName = "Dropbox";