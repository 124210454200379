import Collection from '../_frontloader/collection';
import PullRequestModel from './pullRequest';
const exports = {};

/*

Example Branch entity:

{
  commits: [Commit, ...]
  created_at: "2015-10-27T18:09:14Z"
  deleted: true
  id: 3917
  merged_branch_ids: [3184]
  name: "camilleacey/3206/billing-related-emails"
  persistent: false
  pull_requests: [PullRequest...]
  repository_name: "server"
  updated_at: "2015-10-31T00:21:06Z"
  url: "https://github.com/useshortcut/server/tree/camilleacey/3206/billing-related-emails"
}

*/

Collection.create('Branch', exports);
exports.normalize = branch => {
  branch.pull_requests.forEach(pr => {
    pr.branch_id = branch.id;
    pr.branch_name = branch.name;
    pr.repository = branch.repository;
    PullRequestModel.update(pr);
  });
};
export { exports as default };