import FeatureModel from 'app/client/core/js/models/feature';
import { FEATURES as _FEATURES, usesFeature as _usesFeature } from 'utils/features';
import IntegrationModel from '../../app/client/core/js/models/integration';
import { useEntities, useEntity } from './collection';
export const useProjectFeature = () => {
  const {
    entity: feature
  } = useEntity({
    model: FeatureModel,
    id: 'projects'
  });
  return feature;
};
export const useFeature = _ref => {
  let {
    id
  } = _ref;
  const {
    entity: feature
  } = useEntity({
    model: FeatureModel,
    id
  });
  const {
    entities: integrations
  } = useEntities({
    model: IntegrationModel
  });
  let configured;
  switch (id) {
    case 'gsheets':
      {
        // There won't be an integration entity at all for the gsheets feature until we authenticate
        // with Google, so we use that to decide whether the gsheets integration is configured. There
        // is also an disabled state, which in theory could signal we're not fully configured. But in
        // practice the api seems to remove the entity when we deauthenticate.
        const integration = integrations.find(_ref2 => {
          let {
            type
          } = _ref2;
          return type === id;
        });
        configured = !!integration && !integration.disabled;
        break;
      }
    default:
      // Seems like most of the other features don't have a configuration step, or at least: we
      // haven't been asked to differentiate in the UI between configured/not configured, so we just
      // assume all of these are already "configured enough" out of the box:
      configured = true;
  }
  return {
    ...feature,
    configured
  };
};
export const toggleFeature = options => {
  const {
    newState
  } = options;
  let result;
  if (newState) {
    result = FeatureModel.Promises.enable(options);
  } else {
    result = FeatureModel.Promises.disable(options);
  }

  // Enabling/disabling a feature can sometimes trigger a new integration entity to be created or
  // destroyed
  IntegrationModel.fetchAll();
  return result;
};
export const all = () => FeatureModel.all();
export const FEATURES = _FEATURES;
export const usesFeature = feature => _usesFeature(feature);
export const enableFeature = id => FeatureModel.Promises.enable({
  id
});
export const disableFeature = id => FeatureModel.Promises.disable({
  id
});

/**
 * @deprecated To check for the projects feature in React components use useWorkspaceProjectsFeature instead or query for the features field in a GraphQL query
 */
export const isProjectsFeatureEnabled = () => FeatureModel.isProjectsFeatureEnabled();