import * as InternalSegment from 'app/client/core/js/modules/internalSegment';
import { INTERNAL_SEGMENT_EVENTS } from '../ownedEvents';
import { NOOP_MONITOR, initializeAsync } from '../utils';
export const NAME = 'Internal Segment';
export const shouldOwnEvent = event => {
  return INTERNAL_SEGMENT_EVENTS.has(event);
};
export const shouldOwnNamedEvent = () => false;
export const shouldInitialize = _ref => {
  let {
    env
  } = _ref;
  return env !== 'test';
};
export const initialize = (_, _ref2) => {
  let {
    onError
  } = _ref2;
  let sessionAttributes = null;
  const setSessionAttributes = attributes => {
    if (!attributes?.userId) throw new Error('Analytic tracking requires a "userId" and it is missing! Please fix otherwise this event will not track properly');
    sessionAttributes = attributes;
  };
  const {
    callWhenReady,
    triggerInitialization
  } = initializeAsync(async attrs => {
    setSessionAttributes(attrs);
  }, {
    triggerManually: true,
    onError
  });
  return {
    ...NOOP_MONITOR,
    logEvent: callWhenReady(async (event, data) => {
      const userId = sessionAttributes?.userId;
      if (INTERNAL_SEGMENT_EVENTS.has(event) && userId) {
        await InternalSegment.track({
          event,
          properties: data || {},
          userId
        });
      }
    }),
    setSessionAttributes: async attributes => {
      await triggerInitialization(attributes);
    }
  };
};