import ActivityModel from '../models/activity';
import BulkSelectionModel from '../models/bulkSelection';
import ConsolidatedFetch from './consolidatedFetch';
import EpicModel from '../models/epic';
import EstimateScaleModel from '../models/estimateScale';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import EventModel from '../models/event';
import GroupModel from '../models/group';
import InstallationModel from '../models/installation';
import InviteModel from '../models/invite';
import Iterate from './iterate';
import IterationModel from '../models/iteration';
import IntegrationModel from '../models/integration';
import ImportModel from '../models/import';
import LabelModel from '../models/label';
import Log from './log';
import MilestoneModel from '../models/milestone';
import OrganizationModel from '../models/organization';
import ProjectModel from '../models/project';
import RepositoryModel from '../models/repository';
import SpaceModel from '../models/space';
import StoryDialogController from '../controllers/storyDialog';
import StoryModel from '../models/story';
import StoryTemplateModel from '../models/storyTemplate';
import TeamModel from '../models/team';
import ProfileModel from '../models/profile';
import WebhookModel from '../models/webhook';
import FeatureModel from '../models/feature';
import CustomFieldModel from '../models/customField';
import Tests from './tests';
import Utils from './utils';
const exports = {};
function _setPageRenderFn(fn) {
  return Globals.set('UpdatesPageRenderFn', fn);
}
function _getPageRenderFn() {
  return Globals.get('UpdatesPageRenderFn');
}
exports.setupSSEListeners = () => {
  Event.onlyOn('datalayer-update', _ref => {
    let {
      data
    } = _ref;
    for (const update of data) {
      _handleUpdates(update, () => {
        _updatePage(update);
      });
    }
  });
  Event.onlyOn('datalayer-reset', () => {
    ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
      _updatePage(null);
    });
  });
};
exports.init = renderFn => {
  _setPageRenderFn(renderFn);
  _callPageRenderFn({});
};
exports.checkForUpdates = function () {
  let {
    callback = _.noop
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  callback();
};
const rateLimitedFetchActivity = _.throttle(() => {
  ActivityModel.fetchSinceLastCheck();
}, 5_000, {
  leading: false,
  trailing: true
});
function _updatePage(res) {
  let {
    callback = _.noop
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  _callPageRenderFn(res);
  if (document.visibilityState === 'visible') rateLimitedFetchActivity();
  callback();
}
function _handleUpdates(res, callback) {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (res.updated_members) {
    _handleModelUpdates({
      model: ProfileModel,
      name: 'Profile'
    }, res.updated_members);
  }
  if (res.updated_spaces) {
    _handleModelUpdates({
      model: SpaceModel,
      name: 'Space'
    }, res.updated_spaces, {
      beforeUpdate: space => {
        // Prevent Spaces shared by other users from showing in the Space list of the current user.
        if (space.shared && typeof space.hidden !== 'boolean') {
          space.hidden = true;
        }
        return space;
      }
    });
  }
  if (res.updated_milestones) {
    _handleModelUpdates({
      model: MilestoneModel,
      name: 'Milestone'
    }, res.updated_milestones);
  }
  if (res.updated_installations) {
    _handleModelUpdates({
      model: InstallationModel,
      name: 'Installation'
    }, res.updated_installations);
  }
  if (res.updated_integrations) {
    _handleModelUpdates({
      model: IntegrationModel,
      name: 'Integration'
    }, res.updated_integrations);
  }
  if (res.updated_webhooks) {
    _handleModelUpdates({
      model: WebhookModel,
      name: 'Webhook'
    }, res.updated_webhooks);
  }
  if (res.updated_estimate_scales) {
    _handleModelUpdates({
      model: EstimateScaleModel,
      name: 'EstimateScale'
    }, res.updated_estimate_scales);
  }
  if (res.updated_invites) {
    _handleModelUpdates({
      model: InviteModel,
      name: 'Invite'
    }, res.updated_invites);
  }
  if (res.updated_entity_templates) {
    _handleModelUpdates({
      model: StoryTemplateModel,
      name: 'StoryTemplate'
    }, res.updated_entity_templates);
  }
  if (res.updated_repositories) {
    _handleModelUpdates({
      model: RepositoryModel,
      name: 'Repository'
    }, res.updated_repositories);
  }
  if (res.updated_groups) {
    _handleModelUpdates({
      model: GroupModel,
      name: 'Group'
    }, res.updated_groups);
  }
  if (res.updated_workspace2) {
    OrganizationModel.updateIfValid(res.updated_workspace2);
  }
  if (res.updated_stories) {
    _handleStoryUpdates(res.updated_stories);
  }
  if (res.updated_epics) {
    _handleModelUpdates({
      model: EpicModel,
      name: 'Epic'
    }, res.updated_epics);
  }
  if (res.updated_labels) {
    _handleModelUpdates({
      model: LabelModel,
      name: 'Label'
    }, res.updated_labels);
  }
  if (res.updated_teams) {
    _handleModelUpdates({
      model: TeamModel,
      name: 'Team'
    }, res.updated_teams, {
      afterUpdate: () => {
        EventModel.fetchAll();
      }
    });
  }
  if (res.updated_projects) {
    _handleModelUpdates({
      model: ProjectModel,
      name: 'Project'
    }, res.updated_projects);
  }
  if (Tests.usesIterations() && res.updated_iterations) {
    _handleModelUpdates({
      model: IterationModel,
      name: 'Iteration'
    }, res.updated_iterations);
  }
  if (!_.isEmpty(res.updated_workspace2_settings)) {
    handleWorkspaceUpdates(res.updated_workspace2_settings, res.updated_workspace2);
  }
  if (res.updated_imports) {
    _handleModelUpdates({
      model: ImportModel,
      name: 'Import'
    }, res.updated_imports);
  }
  if (res.updated_features) {
    _handleModelUpdates({
      model: FeatureModel,
      name: 'Feature'
    }, res.updated_features);
  }
  if (res.updated_custom_fields) {
    _handleModelUpdates({
      model: CustomFieldModel,
      name: 'CustomFields'
    }, res.updated_custom_fields);
  }
  callback();
}
function handleWorkspaceUpdates(_ref2, updated_workspace2) {
  let {
    enabled_feature
  } = _ref2;
  if (enabled_feature && updated_workspace2?.iterations_enabled === true) {
    IterationModel.fetchAll(() => Event.trigger('iterationsEnabled'));
  } else if (!enabled_feature && updated_workspace2?.iterations_enabled === false) {
    Event.trigger('iterationsDisabled');
  }
}
function _getCurrentStoryMobile() {
  const element = $('.story-page');
  if (element.length > 0) {
    return Utils.getModelFromContext(element);
  }
}
function _handleStoryUpdates(updates) {
  let changesMade = false;
  let fetchAndRedrawCurrentStoryDialog = false;
  let fetchAndRedrawCurrentStoryMobile = false;
  const currentStoryDialog = StoryDialogController.getCurrentlyDisplayedStory();
  const currentStoryMobile = _getCurrentStoryMobile();
  Iterate.each(updates.deleted, id => {
    changesMade = true;
    const story = StoryModel.getById(id);
    if (story) {
      BulkSelectionModel.removeFromSelection(story.id);
      Log.debug('Updates: story #' + id + ' was deleted');
      Event.trigger('somebodyDeletedStory', story);
      StoryModel.remove({
        id
      });
    }
  });
  StoryModel.trigger('bulkStart');
  Iterate.each(updates.position_map, update => {
    changesMade = true;
    const existing = StoryModel.getById(update.id);
    if (existing) {
      Log.debug('Updates: updating position on story #' + update.id + ': ' + update.position);
      existing.position = update.position;

      // We can get workflow_state_id if a Project is moved to a different Team,
      // or if a story is moved by way of a GitHub event.
      if (update.workflow_state_id) {
        existing.workflow_state_id = update.workflow_state_id;

        // If the story was moved by way of a GitHub event,
        // it won't show up in the modified array, so we need
        // to handle the fetch and redraw here.
        if (currentStoryDialog && currentStoryDialog.id === existing.id) {
          fetchAndRedrawCurrentStoryDialog = true;
        } else if (currentStoryMobile && currentStoryMobile.id === existing.id) {
          fetchAndRedrawCurrentStoryMobile = true;
        }
      }
    }
  });
  Iterate.each(updates.modified, story => {
    changesMade = true;
    Log.debug('Updates: story #' + story.id + ' was changed or added');

    // The current user (or any user) may have just created a story with a new label,
    // which we don't otherwise know about, so let's add those new labels here.
    // Ref: https://app.shortcut.com/internal/story/21200/creating-a-label-from-the-story
    Iterate.each(story.labels, label => {
      if (!LabelModel.getById(label.id)) {
        LabelModel.updateIfValid(label);
      }
    });
    StoryModel.updateIfValid(story);
    if (currentStoryDialog && currentStoryDialog.id === story.id) {
      fetchAndRedrawCurrentStoryDialog = true;
      Event.trigger('currentStory.Updated', story);
    } else if (currentStoryMobile && currentStoryMobile.id === story.id) {
      fetchAndRedrawCurrentStoryMobile = true;
      Event.trigger('currentStory.Updated', story);
    }
  });
  StoryModel.trigger('bulkEnd');
  if (fetchAndRedrawCurrentStoryDialog) {
    StoryModel.fetchStory(currentStoryDialog.id, () => {
      StoryDialogController.update({
        force: true
      });
    });
  } else if (fetchAndRedrawCurrentStoryMobile) {
    StoryModel.fetchStory(currentStoryMobile.id);
  }
  if (changesMade) {
    StoryModel.sortStoriesByPosition();
  }
}
const noop = () => {};
function _handleModelUpdates(type, updates, opts) {
  const Model = type.model;
  const name = type.name;
  const {
    beforeUpdate = obj => obj,
    afterUpdate = noop
  } = opts ?? {};
  Model.trigger('syncStart');
  Iterate.each(updates.deleted, id => {
    const obj = Model.get({
      id
    });
    if (obj) {
      Log.debug(`Updates: ${name} #${id} was deleted`);
      Model.remove({
        id
      });
      Event.trigger('somebodyDeleted' + name, obj);
    }
  });
  Iterate.each(updates.modified, obj => {
    Log.debug(`Updates: ${name} #${obj.id} was changed or added`);
    const modifiedObj = beforeUpdate(obj);
    Model.updateIfValid(modifiedObj);
    afterUpdate(modifiedObj);
    Event.trigger('somebodyModified' + name, modifiedObj);
  });
  updates.position_map?.forEach(update => {
    if (update) {
      const {
        id,
        position
      } = update;
      Model.updatePosition?.({
        id,
        position
      });
    }
  });
  Model.trigger('syncEnd');
}
function _callPageRenderFn(res) {
  // Some pages explicitly don't re-render on update, because it could be jarring.
  const pageRenderFn = _getPageRenderFn();
  if (_.isFunction(pageRenderFn)) {
    Log.debug('Updates: calling pageRenderFn...');
    return pageRenderFn(res);
  }
}
export { exports as default };