import EventEmitter from 'eventemitter3';
import { useMemo } from 'react';
import { useSubscription } from 'use-subscription';
import { getCurrentPathname } from 'utils/url';
const eventEmitter = new EventEmitter();
const PARTIAL_ROUTE_EFFECTS_EVENT = 'PARTIAL_ROUTE_EFFECTS_EVENT';
export const partialRouteChanged = () => {
  eventEmitter.emit(PARTIAL_ROUTE_EFFECTS_EVENT);
};
export const usePartialRouteEffects = () => {
  const subscription = useMemo(() => ({
    // We need something unique that won't return the same value/reference for the same input
    getCurrentValue: () => Symbol(getCurrentPathname()),
    subscribe: callback => {
      eventEmitter.addListener(PARTIAL_ROUTE_EFFECTS_EVENT, callback);
      return () => eventEmitter.removeListener(PARTIAL_ROUTE_EFFECTS_EVENT, callback);
    }
  }), []);
  return useSubscription(subscription);
};