import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { docs } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DocsFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  return _jsx(TableText, {
    text: entity.stats.num_related_documents
  });
};
DocsFieldComponent.displayName = "DocsFieldComponent";
export const DocsField = function () {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: FIELD_ID.DOCS,
    displayName: 'Docs',
    sort: docs,
    headerProps: {
      centered: true
    },
    Component: DocsFieldComponent,
    width: 70,
    ...overrides
  };
};