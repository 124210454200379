import Url from '../modules/url';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
const exports = {};
exports.title = `Help and Feedback | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/help';
};
exports.open = () => {
  toggleHelpHub();
  return false;
};
exports.KNOWLEDGE_BASE_SLUGS = KNOWLEDGE_BASE_SLUGS;
export { exports as default };