import { GroupProfileIconTooltip } from 'components/shared/GroupProfileIconTooltip';
import View from '../modules/view';
const exports = {};
exports.renderGroupIcon = _ref => {
  let {
    key,
    group_id,
    cssClass = '',
    disableTooltip = false
  } = _ref;
  return View.renderComponentDelayed({
    componentKey: key,
    cssClass,
    component: GroupProfileIconTooltip,
    props: {
      disableTooltip,
      groupId: group_id
    }
  }).html;
};
export { exports as default };