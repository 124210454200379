import Collection from '../_frontloader/collection';
const exports = {};

/*

Example Estimate as of 10/5/15:

{
  id: 1234,
  value: 1
}

*/

Collection.create('Estimate', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.value;
};
export { exports as default };