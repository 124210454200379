import { Header } from './Header';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StaticHeader = _ref => {
  let {
    children,
    isNavExpanded,
    isActive,
    icon
  } = _ref;
  return _jsx(Header, {
    isNavExpanded: isNavExpanded,
    icon: icon,
    isActive: isActive,
    children: children
  });
};
StaticHeader.displayName = "StaticHeader";