import EventEmitter from 'eventemitter3';
const exports = {};

// Even though using our own baked event emitter is tempting, it will create
// a circular dependency as Globals needs Event, and Event needs Globals.
const eventEmitter = new EventEmitter();
const SET_GLOBAL_EVENT = 'SET_GLOBAL_EVENT';
exports.GLOBAL_EVENT_ALLOWLIST = ['HotReload.renderId', 'Profile.renderId', 'consolidatedDataFetched', 'initialPage', 'partialPage', 'userOrgAndApplicationStateFetched', 'fetchingAllStories'];
exports.getEventNameForGlobal = key => `${SET_GLOBAL_EVENT}:${key}`;
exports.addChangeListener = (key, listener) => {
  eventEmitter.addListener(exports.getEventNameForGlobal(key), listener);
};
exports.removeChangeListener = (key, listener) => {
  eventEmitter.removeListener(exports.getEventNameForGlobal(key), listener);
};
const globalChanged = (key, value) => {
  eventEmitter.emit(exports.getEventNameForGlobal(key), value);
};
exports.init = () => {
  window._APP_GLOBALS = window._APP_GLOBALS || {};
};
exports.reset = () => {
  window._APP_GLOBALS = {};
};
exports.set = (key, value) => {
  exports.init();
  window._APP_GLOBALS[key] = value;
  if (exports.GLOBAL_EVENT_ALLOWLIST.includes(key)) {
    globalChanged(key, value);
  }
  return value;
};
exports.get = key => {
  exports.init();
  return window._APP_GLOBALS[key];
};
exports.setOnlyIfMissing = (key, value) => {
  const existing = exports.get(key);
  if (exports._isDefined(existing)) {
    return existing;
  }
  return exports.set(key, value);
};

// Returns false if value is null, undefined, or NaN. Otherwise returns true.
exports._isDefined = value => {
  return !!(value || value === 0 || value === false || value === '');
};

// --------------------------------------------------------------------------

// State is risky and should generally be avoided.

// For state that needs to survive normal page reloads, use the LocalStorage module.
// For state that needs to survive hot page reloads, use the Global.get/set functions.
// Anything below is state we don't need to survive a hot reload.

// --------------------------------------------------------------------------

// Handled by ClubhouseApp.Boot.initWindowFocusHandler.
exports.windowHasFocus = null;

// Used by stories and tasks.
exports.isDragging = false;

// Used by file uploader.
exports.isDraggingFile = false;

// We use this to cache the location.hash on the Search page. When a storyDialog
// is opened, that disappears, so we need to cache it.
exports.hashCache = null;

// Display or hide "more epics" in the stories page sidebar.
exports.showMoreEpics = false;

// Display or hide "more iterations" in the stories page sidebar.
exports.showMoreIterations = false;

// Used by bulk editing for selecting ranges.
exports.lastToggledStoryId = null;
export { exports as default };