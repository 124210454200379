import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
const exports = {
  Promises: {}
};
Collection.create('Coupon', exports);
exports.Promises.get = function (code, billingFrequency) {
  let tier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return new Promise(resolve => {
    const tierSelection = tier ? `&tier=${tier}` : ``;
    Backend.get(`/api/private/${tier ? 'promotions-2' : 'promotions'}/${code}?plan_period=${billingFrequency}${tierSelection}`, {
      onComplete: res => {
        exports.defaultGetHandler(res, () => resolve(res));
      },
      doNotDuplicateErrorMessage: true
    });
  });
};
export { exports as default };