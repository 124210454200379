import { getDefaultLabelColor } from 'data/entity/label';
import { LabelChip } from './components';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const LabelChips = _ref => {
  let {
    labels
  } = _ref;
  if (!labels?.length) return null;
  return _jsx(_Fragment, {
    children: labels.map(_ref2 => {
      let {
        id,
        name,
        color
      } = _ref2;
      return _jsx(LabelChip, {
        color: color || getDefaultLabelColor(),
        name: name
      }, id);
    })
  });
};