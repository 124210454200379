import Logo from '@clubhouse/assets/png/third-party-logos/rootly_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Rootly() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.ROOTLY
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Rootly"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Rootly Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Manage incident action items seemlessly by pushing them directly to Shortcut from from within your", ' ', _jsx(ExternalLink, {
          href: "https://www.rootly.com",
          children: "Rootly"
        }), " Slack incident channel."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx("a", {
          href: "https://rootly.com/integrations/shortcut",
          children: "Learn more"
        }), " on how the Rootly integration works in Shortcut."]
      })]
    })]
  });
}
Rootly.displayName = "Rootly";