import { makeVar, useReactiveVar } from '@apollo/client';
const navOpen = makeVar(false);
const open = () => navOpen(true);
const close = () => navOpen(false);
const toggle = () => navOpen(!navOpen());
export const useIsMobileNavOpen = () => {
  const isOpen = useReactiveVar(navOpen);
  return {
    isOpen,
    open,
    close,
    toggle
  };
};