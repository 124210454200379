import UserModel from '../models/user';
import OrganizationModel from '../models/organization';
import * as Event from '../_frontloader/event';
import User from '../modules/user';
import Utils from '../modules/utils';
import { simpleCompleteTask } from 'utils/quickstart';
const exports = {};
const SAMPLE_WORKSPACE_ID = '5ce70855-1432-4018-8769-4e14b21dc5bb';
const SAMPLE_WORKSPACE_SLUG = 'demoorg';
exports.isSampleObserver = () => {
  const org = OrganizationModel.getCurrent() || {};
  return UserModel.getRoleOfCurrentUser() === 'observer' && org.id === SAMPLE_WORKSPACE_ID;
};
exports.isSampleObserverAsync = () => {
  return new Promise(resolve => {
    const hasOrg = OrganizationModel.isValid(OrganizationModel.getCurrent());
    const hasUser = UserModel.isValid(User.getCurrentUser());
    if (hasOrg && hasUser) {
      resolve(exports.isSampleObserver());
    } else {
      Event.once('currentUserSet', () => {
        resolve(exports.isSampleObserver());
      });
    }
  });
};
exports.join = redirectPageName => {
  simpleCompleteTask('visit-demo-workspace');
  let destinationPage = 'stories';
  if (typeof redirectPageName === 'string') {
    destinationPage = redirectPageName;
  }
  Utils.openInNewTabWithNoopener(`${location.origin}/${SAMPLE_WORKSPACE_SLUG}/${destinationPage}`);
};
export { exports as default };