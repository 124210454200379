import { StatusIcon } from '@useshortcut/shapes-ds';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getStatusIconFromType = type => {
  switch (type) {
    case 'done':
      return _jsx(StatusIcon, {
        icon: "Done"
      });
    case 'started':
      return _jsx(StatusIcon, {
        icon: "Started"
      });
    case 'backlog':
      return _jsx(StatusIcon, {
        icon: "Backlog"
      });
    default:
      return _jsx(StatusIcon, {
        icon: "Unstarted"
      });
  }
};