import { DailyBot } from 'pages/integrations/DailyBot';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
const exports = {};
exports.title = `DailyBot Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/dailybot';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(DailyBot, 'dailybot-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };