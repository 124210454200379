import HotReloadController from 'app/client/core/js/controllers/hotReload';
import { getGlobal } from './globals';
const IS_INITIAL_PAGE_RENDER_COMPLETE = 'utils.isInitialPageRenderComplete';
export const loadPageFromMenu = function () {
  return HotReloadController.loadPageFromMenu(...arguments);
};
export const loadURL = function (url, page) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return HotReloadController.loadURL(url, page, options);
};
export const loadURLWithDelay = function (url, page) {
  let delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return setTimeout(() => HotReloadController.loadURL(url, page, options), delay);
};
export const isInitialPageRenderComplete = () => {
  return Boolean(getGlobal(IS_INITIAL_PAGE_RENDER_COMPLETE));
};
export const load = function () {
  return HotReloadController.load(...arguments);
};
export const resetPage = () => HotReloadController.resetPage();