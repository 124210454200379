import Logo from '@clubhouse/assets/png/third-party-logos/youtube-logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function YouTube() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.YOUTUBE
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "YouTube"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "YouTube Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Users can paste videos from ", _jsx(ExternalLink, {
          href: "https://www.youtube.com",
          children: "YouTube"
        }), " to see real-time videos in markdown editors, including comments. The YouTube Integration requires no set up - it should work out of the box with any shareable link from YouTube."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_YOUTUBE,
          children: "Learn more"
        }), " on how YouTube embeds work in Shortcut."]
      })]
    })]
  });
}
YouTube.displayName = "YouTube";