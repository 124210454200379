import { DisplayableNameFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DisplayableNameFragment = DisplayableNameFragmentDoc;
export const DisplayableName = _ref => {
  let {
    displayable
  } = _ref;
  return _jsx(Emojify, {
    children: displayable.displayableName
  });
};
DisplayableName.displayName = "DisplayableName";