const PAGE_CSS_ID = 'page-css';
function getOldEl() {
  return document.getElementById(PAGE_CSS_ID);
}
function createNewEl() {
  const el = document.createElement('link');
  el.id = PAGE_CSS_ID;
  el.rel = 'stylesheet';
  el.type = 'text/css';
  return el;
}
let cssTimeout;
export const resetPageCSS = src => {
  if (!src) {
    getOldEl()?.remove();
    return Promise.resolve();
  }

  /* This is only used with style-loader during testing. */
  /* TODO: Remove when SPA work is complete. */
  if (src.use) {
    return Promise.resolve();
  }
  return new Promise(resolve => {
    const href = `${src}`;
    const oldEl = getOldEl();
    const newEl = oldEl?.cloneNode() || createNewEl();
    clearTimeout(cssTimeout);
    cssTimeout = setTimeout(() => {
      console.warn('Timeout reached trying to load CSS file!', {
        src
      });
      resolve();
    }, 30000);
    const onLoad = () => {
      clearTimeout(cssTimeout);
      resolve();
      newEl.removeEventListener('load', onLoad);
    };
    newEl.addEventListener('load', onLoad);
    newEl.href = href;
    if (oldEl) {
      oldEl.parentNode.replaceChild(newEl, oldEl);
    } else {
      document.head.appendChild(newEl);
    }
  });
};