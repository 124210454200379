import { useEffect, useState } from 'react';
import { addChangeListener, FEATURES as features, removeChangeListener, usesFeature } from 'utils/features';
export const FEATURES = features;

/**
 * Check if *any* feature LaunchDarkly, Labs, plan-gated, org settings, etc
 * is enabled or not.
 */
export const useFeature = feature => {
  const [enabled, setEnabled] = useState(usesFeature(feature));
  useEffect(() => {
    const id = addChangeListener(feature, val => {
      setEnabled(val);
    });
    return () => {
      if (id) removeChangeListener(id);
    };
  }, [feature]);
  return {
    enabled
  };
};