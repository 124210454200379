import Ajax from './ajax';
const exports = {};
exports.get = (url, options) => {
  url = exports.getServerURL() + url;
  return Ajax.get(url, options);
};
exports.post = (url, options) => {
  url = exports.getServerURL() + url;
  return Ajax.post(url, options);
};
exports.put = (url, options) => {
  url = exports.getServerURL() + url;
  return Ajax.put(url, options);
};
exports.delete = (url, options) => {
  url = exports.getServerURL() + url;
  return Ajax.delete(url, options);
};
exports.getServerURL = () => {
  // This let's the server url be overridden in development.
  // If we make the development server proxy to the development server
  // appropriately, this could go away.
  if (window._SHORTCUT_API_SERVER_URL) {
    return window._SHORTCUT_API_SERVER_URL;
  }
  return `${window.location.origin}/backend`;
};
export { exports as default };