import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useMemo } from 'react';
import { NONE_VALUE } from '@clubhouse/shared/constants';
import { areCustomFieldsEnabled, storyMatchesFilter } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { useStories } from 'data/entity/story';
const checkFilterAgainstSingleValue = (filterValues, storyValue) => {
  if (!filterValues?.length) return true;
  if (storyValue == null && filterValues.includes(NONE_VALUE)) return true;
  if (!!storyValue && filterValues.some(val => String(val) === String(storyValue))) return true;
  return false;
};
const checkFilterAgainstMultipleValues = (filterValues, storyValues) => {
  if (!filterValues?.length) return true;
  if (!storyValues?.length && filterValues.includes(NONE_VALUE)) return true;
  if (storyValues && storyValues.length > 0) {
    const lookup = new Set(storyValues.map(v => String(v)));
    return filterValues.some(val => lookup.has(String(val)));
  }
  return false;
};
export function useFilteredStories(entityFilter, filters) {
  const {
    query,
    projects,
    teams,
    workflowStates,
    owners,
    labels,
    customFields
  } = filters;
  const {
    stories
  } = useStories({
    ...entityFilter,
    text: query,
    includeArchived: false
  });
  return useMemo(() => stories.filter(story => (!isProjectsFeatureEnabled() || checkFilterAgainstSingleValue(projects, story.project_id)) && checkFilterAgainstSingleValue(teams, story.group_id) && checkFilterAgainstSingleValue(workflowStates, story.workflow_state_id) && checkFilterAgainstMultipleValues(owners, story.owner_ids) && checkFilterAgainstMultipleValues(labels, story.labels.map(label => String(label.id))) && (!areCustomFieldsEnabled() || storyMatchesFilter(story, customFields))), [stories, projects, teams, owners, labels, workflowStates, customFields]);
}