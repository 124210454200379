import { slugify } from '@clubhouse/shared/utils';
import { PAGE_NAMES, generatePathForPage } from './paths';
export const getHref = (slug, _ref) => {
  let {
    publicId,
    name
  } = _ref;
  const storyNameForUrl = slugify(name);
  return generatePathForPage(PAGE_NAMES.STORY, {
    slug,
    id: String(publicId),
    ...(storyNameForUrl ? {
      storyName: storyNameForUrl
    } : {})
  });
};