import { useCallback, useState } from 'react';
import { useMountedState } from 'react-use';
export function useStateWhenMounted(initialState) {
  const isMounted = useMountedState();
  const [state, setState] = useState(initialState);
  const setStateWhenMounted = useCallback(value => {
    if (isMounted()) {
      setState(value);
    }
  }, [isMounted]);
  return [state, setStateWhenMounted];
}