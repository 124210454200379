import { FooterAnchorButton } from '@clubhouse/shared/components/Select';
import { open } from 'utils/manageTeams';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WorkflowFooter = _ref => {
  let {
    onClick
  } = _ref;
  const pageName = PAGE_NAMES.SETTINGS_WORKFLOWS;
  const href = generatePathWithSlug(getPathForPage(pageName));
  const handleClick = e => {
    e.preventDefault();
    onClick?.();
    open();
  };
  return _jsx(FooterAnchorButton, {
    onClick: handleClick,
    href: href,
    text: "Edit Workflows",
    icon: "Workflow"
  });
};
WorkflowFooter.displayName = "WorkflowFooter";