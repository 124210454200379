import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ConfirmationDialog(_ref) {
  let {
    buttonKind,
    onClick,
    cta,
    title,
    body,
    onClose,
    visible
  } = _ref;
  return _jsxs(Dialog, {
    ActionComponent: _ref2 => {
      let {
        onClose
      } = _ref2;
      return _jsxs(_Fragment, {
        children: [_jsx(Button, {
          kind: Button.KIND.SECONDARY,
          onClick: onClose,
          children: "Cancel"
        }), _jsx(Button, {
          kind: buttonKind,
          onClick: onClick,
          children: cta
        })]
      });
    },
    onClose: onClose,
    visible: visible,
    children: [_jsx(DialogTitle, {
      children: title
    }), _jsx(DialogText, {
      children: body
    })]
  });
}
ConfirmationDialog.displayName = "ConfirmationDialog";