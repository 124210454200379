import * as ContextMenuTemplate from 'app/client/core/views/templates/contextMenu.html';
import Dom from '../modules/dom';
import DropdownController from './dropdown';
import DropdownModel from '../models/dropdown';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import Log from '../modules/log';
import Tooltip from '../modules/tooltip';
import UserModel from '../models/user';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};
const MOVE_EVENT = 'mousemove.ContextMenu';
const ACTIVE_CLASS = 'menu-open';
const DEFAULTS = {
  OFFSET: {
    TOP: -5,
    RIGHT: -25
  }
};
exports.MORE_ACTIONS_ID = 'MoreActionsDropdown';
exports.target = null;
exports.menu = null;
exports.active = false;
exports.timeout = null;
exports.init = () => {
  Event.onlyOn('mouseMoved.ContextMenu', exports.initContextMenu);
};
exports.initContextMenu = e => {
  e = e || {};
  const target = Dom.closest(e.target, '[data-context-menu]');
  if (!target) {
    return;
  }
  if (!target.id) {
    throw Error('ContextMenu element needs a unique id attribute.');
  }
  const permission = UserModel.getLoggedInUserPermission();
  const isReadOnly = Is.readOnly(permission);
  const isRestricted = _.isString(Utils.data(target, 'context-menu-restricted'));
  if (isRestricted && isReadOnly) {
    return;
  }
  if (!exports.active) {
    exports.show({
      delay: Utils.data(target, 'context-menu-delay'),
      model: Utils.data(target, 'context-menu-model'),
      items: Utils.fireEventHandler($(target), 'context-menu'),
      target: Utils.fireEventHandler($(target), 'context-menu-id-fn') || '#' + target.id
    });
  }
};
exports.show = options => {
  if (!options.items) {
    return false;
  }

  // Don't show if the user is uploading a file (e.g. a loading icon)
  // Ref: https://app.shortcut.com/internal/story/21943/when-changing-workflow-state
  if ($('.drop-overlay .while-uploading').length > 0) {
    return false;
  }

  // Close any open context menus if dragging
  if (Globals.get('isDragging')) {
    return false;
  }
  if (exports.active) {
    exports.close();
  }
  const html = ContextMenuTemplate.render({
    items: options.items
  });
  exports.active = true;
  exports.target = options.target;
  exports.menu = View.attach(html, 'body');
  exports.setItemActions(options);
  $(exports.target).addClass(ACTIVE_CLASS);
  if (options.model) {
    Utils.data(exports.menu, 'model', options.model);
    Utils.data(exports.menu, 'id', Utils.data($(exports.target), 'id'));
  }
  exports.setPosition();
  exports.bindMoveListener();
};
exports.close = () => {
  if (exports.active === false) {
    return;
  }
  exports.unbindMoveListener();
  clearTimeout(exports.timeout);
  if (exports.target) {
    $(exports.target).removeClass(ACTIVE_CLASS);
  }
  exports.target = null;
  if (exports.menu) {
    exports.menu.remove();
  }
  exports.menu = null;
  exports.active = false;
  DropdownController.closeById(exports.MORE_ACTIONS_ID);
};
exports.bindMoveListener = () => {
  const selectors = [exports.target, '.context-menu', '#dropdown-' + exports.MORE_ACTIONS_ID];
  Utils.onOutsideBoundsRange(MOVE_EVENT, selectors, exports.close);
};
exports.unbindMoveListener = () => {
  $('body').off(MOVE_EVENT);
};
exports.setPosition = () => {
  const target = $(exports.target);
  const attrTop = Utils.data(target, 'context-menu-top');
  const attrRight = Utils.data(target, 'context-menu-right');
  const options = {
    top: _.isNumber(attrTop) ? attrTop : DEFAULTS.OFFSET.TOP,
    right: _.isNumber(attrRight) ? attrRight : DEFAULTS.OFFSET.RIGHT
  };
  const targetPos = target.offset();
  if (!targetPos) {
    Log.error(new Error('ContextMenu target missing'));
    return false;
  }
  const top = targetPos.top + options.top;
  const left = targetPos.left + target.outerWidth() + options.right;
  exports.menu.css({
    top: top + 'px',
    left: left + 'px'
  });
  const zIndex = Utils.data(target, 'context-menu-z-index');
  if (_.isNumber(zIndex)) {
    exports.menu.css({
      zIndex: zIndex
    });
  }
};
exports.setItemActions = options => {
  $('.context-link', exports.menu).each(function (i) {
    this.originalTarget = options.target;
    this.userAction = options.items[i].value;
    this.onHoverAction = options.items[i].onHover;
  });
};
exports.applyValue = function () {
  if (_.isFunction(this.onHoverAction)) {
    return this.onHoverAction.call(this, $(this.originalTarget));
  }
  if (_.isFunction(this.userAction)) {
    return this.userAction.call(this, $(this.originalTarget));
  }
};
exports.onHover = function () {
  if (_.isFunction(this.onHoverAction)) {
    return this.onHoverAction.call(this, $(this.originalTarget));
  }
};
exports.refresh = () => {
  if (exports.active && exports.target && exports.menu) {
    const items = Utils.fireEventHandler($(exports.target), 'context-menu');
    if (!items || items.length === 0) {
      return;
    }
    const options = {
      items,
      target: exports.target
    };
    const html = ContextMenuTemplate.render({
      items
    });
    exports.menu = View.replaceElement(exports.menu, html);
    exports.setItemActions(options);
    exports.setPosition();
  }
};
exports.openMore = options => {
  options = _.assignIn({
    id: exports.MORE_ACTIONS_ID,
    topOffset: -32
  }, options);
  const dropdown = DropdownModel.getById(exports.MORE_ACTIONS_ID);
  if (!dropdown) {
    DropdownController.open(options);
    Tooltip.close();
  }
  return false;
};
export { exports as default };