import Globals from 'app/client/core/js/_frontloader/globals';
import { CONSOLIDATED_DATA_FETCHED_KEY, USER_ORG_AND_APPLICATION_STATE_FETCHED_KEY } from 'app/client/core/js/modules/consolidatedFetch';
import CreateOrgOrWorkspaceController from 'app/client/login/js/controllers/createOrgOrWorkspace';
import { callbackToPromise } from 'components/shared/utils/callbackToPromise';
import { fetchConsolidatedDataAndActivity, fetchUserOrgAndApplicationState } from 'utils/consolidatedFetch';
export const fetchConsolidatedDataBeforeRedirectFromSignup = async () => {
  if (!Globals.get(USER_ORG_AND_APPLICATION_STATE_FETCHED_KEY)) {
    await callbackToPromise(fetchUserOrgAndApplicationState)();
  }
  if (!Globals.get(CONSOLIDATED_DATA_FETCHED_KEY)) {
    await callbackToPromise(fetchConsolidatedDataAndActivity)();
  }
};
export const redirectFromSignup = permissionId => CreateOrgOrWorkspaceController.redirectFromSignup(permissionId);