import { Emojify } from '@clubhouse/shared/components/Emojify';
import { ObjectiveTypeIcon } from '../ObjectiveTypeIcon';
import { TitleByState } from './mapping';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOptionalConfigForGroupKey = {
  toDo: allowBulkSelect => ({
    sectionId: 'ToDo',
    sectionTitle: TitleByState.toDo,
    allowBulkSelect
  }),
  inProgress: allowBulkSelect => ({
    sectionId: 'InProgress',
    sectionTitle: TitleByState.inProgress,
    allowBulkSelect
  }),
  done: allowBulkSelect => ({
    sectionId: 'Done',
    sectionTitle: TitleByState.done,
    allowBulkSelect
  }),
  epicObjective: null,
  selectedObjective: null
};
export const getSection = (groupKey, options, _ref) => {
  let {
    hasMultiplePages,
    isFiltered
  } = _ref;
  return {
    ...getOptionalConfigForGroupKey[groupKey]?.(!hasMultiplePages && !isFiltered),
    items: options.map(_ref2 => {
      let {
        node
      } = _ref2;
      return {
        value: node.id,
        name: _jsx(Emojify, {
          children: node.name
        }),
        Icon: () => _jsx(ObjectiveTypeIcon, {
          type: node.type
        }),
        additionalData: node
      };
    })
  };
};