import BurndownChartImg from '@clubhouse/assets/png/screenshots/Burndown_Chart_min.png';
import BurndownChartV2Img from '@clubhouse/assets/png/screenshots/example-burndown-chart-v2.png';
import { capitalize } from '@clubhouse/shared/utils';
import { getCurrentPage, getSlugPath } from 'utils/navigation';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Description = _ref => {
  let {
    entity
  } = _ref;
  return entity === 'Iteration' ? _jsxs(_Fragment, {
    children: [_jsx("h3", {
      children: "Overview"
    }), _jsxs("p", {
      children: ["The Burndown Report shows unfinished work remaining in an iteration as time progresses. This is the", ' ', _jsx("i", {
        children: "Actual Remaining"
      }), " section of the chart. Remaining work decreases as work is finished or increases if work is added. Remaining work is shown alongside an ", _jsx("i", {
        children: "Ideal Remaining"
      }), " line which charts a constant, \"idealized\" burndown of work from the start of the iteration until the end."]
    }), _jsxs("p", {
      children: ["Deviations from the expected burndown, such as a large difference in ", _jsx("i", {
        children: "Remaining"
      }), " versus ", _jsx("i", {
        children: "Ideal"
      }), ", are an opportunity to drill down to find the root cause and fix it before a sprint or product release is derailed."]
    }), _jsx("hr", {}), _jsx("h3", {
      children: "Chart Mechanics"
    }), _jsx("h4", {
      children: "Actual Remaining"
    }), _jsxs("p", {
      children: [_jsx("i", {
        children: "Actual Remaining"
      }), " is the amount of unfinished work in an iteration. This work can be in any Started or Unstarted workflow state."]
    }), _jsxs("p", {
      children: ["The dots represent total remaining work at the ", _jsx("b", {
        children: "end of each day"
      }), " in the iteration. Each data point demonstrates the net change in remaining work from the end of the previous day to the end of the next day."]
    }), _jsx("p", {
      children: "For example, if a team completes 5 stories, but also adds 5 stories, the line will remain flat."
    }), _jsx("h4", {
      children: "Ideal Remaining"
    }), _jsxs("p", {
      children: [_jsx("i", {
        children: "Ideal Remaining"
      }), " is a calculated line that progresses linearly from the starting amount of Stories or Points in the iteration down to zero on the last day. This line is not meant to be followed precisely, but acts as a pacing barometer for an iteration. If the ideal line is way above or below remaining work, this is an indication work may be ahead or behind schedule and allows the team to take steps to correct if they desire."]
    }), _jsxs("p", {
      children: ["The ideal line flattens across non-working days to indicate work is not expected to progress on these days. Working days can be adjusted in your ", _jsx("a", {
        href: `${getSlugPath()}/settings/workspace`,
        children: "General Settings"
      }), "."]
    }), _jsxs("p", {
      children: [_jsx("i", {
        children: "Ideal Line Starts Today"
      }), " is a toggle in the legend to show the ideal pace to finish starting from the remaining work in the iteration \"today\". This setting is useful for teams who add work throughout the iteration, making the first day an inaccurate starting point for the ideal line."]
    }), _jsx("h4", {
      children: "Starting Point"
    }), _jsxs("p", {
      children: ["The initial point for both the ", _jsx("i", {
        children: "Actual Remaining"
      }), " and ", _jsx("i", {
        children: "Ideal Remaining"
      }), " lines is finalized at the", ' ', _jsx("b", {
        children: "end of the first day"
      }), " of an iteration. This means your team can add stories throughout the first day, and these will count towards the starting point of the ideal line."]
    }), _jsx("h4", {
      children: "Tooltips"
    }), _jsxs("p", {
      children: ["Tooltips show counts of work ", _jsx("i", {
        children: "Added"
      }), ", ", _jsx("i", {
        children: "Completed"
      }), " or ", _jsx("i", {
        children: "Removed"
      }), " each day. This breakdown helps understand the net change for a day. For example, the line might be flat because work was both completed and added. Note: If a story is added and removed on the same day this activity will not be reflected in the tooltip counts."]
    }), _jsx("p", {
      children: "The \"Today\" tooltip also shows the status of all remaining work to give a clearer picture of actual progress."
    }), _jsx("h4", {
      children: "Stories vs. Points"
    }), _jsxs("p", {
      children: ["The chart defaults to Stories (total count) or Points, depending on your", ' ', _jsx("a", {
        href: `${getSlugPath()}/settings/estimates`,
        children: "Estimate Scale"
      }), " settings. There is a toggle to quickly switch between the two on the report."]
    }), _jsx("h4", {
      children: "Non-Working Days"
    }), _jsxs("p", {
      children: ["Non-working days are noted on the chart with hatched bars. The ideal line flattens across non-working days to indicate work is not expected to progress on these days. Working days for your Workspace can be adjusted in your", ' ', _jsx("a", {
        href: `${getSlugPath()}/settings/workspace`,
        children: "General Settings"
      }), "."]
    })]
  }) : _jsxs(_Fragment, {
    children: [_jsxs("blockquote", {
      children: ["With the introduction of a new, true-to-form Burndown chart found on Iteration pages, we've retained the former Burndown and given it a new name to better reflect the value it brings \u2014 re-introducing:", ' ', _jsx("b", {
        children: "ARC (Added/Remaining/Completed) Chart"
      }), ". This chart remains on the Epic, Label, and Reports pages."]
    }), _jsx("h3", {
      children: "Overview"
    }), _jsx("p", {
      children: "This chart adds extra layers to the conventional burndown chart. In addition to showing Remaining work, it also includes work Added, Removed and Completed as separate visualizations. These help a team better understand the inputs and outputs that result in net change of remaining work over time."
    }), _jsx("h3", {
      children: "Chart Mechanics"
    }), _jsxs("p", {
      children: [_jsx("b", {
        children: "Actual Stories/Points Remaining"
      }), " is represented by the blue line and shows the total remaining work for the given time period. This line goes down as stories are completed or removed, and up as stories are added."]
    }), _jsxs("p", {
      children: [_jsx("b", {
        children: "Stories/Points Added"
      }), " is represented by the purple bar and shows when work is added or an estimate is increased."]
    }), _jsxs("p", {
      children: [_jsx("b", {
        children: "Stories/Points Removed"
      }), " is represented by the yellow bar and shows when work is removed or estimate is reduced."]
    }), _jsxs("p", {
      children: [_jsx("b", {
        children: "Stories/Points Completed"
      }), " is represented by the light green bar and shows that day's number of stories or points completed."]
    }), _jsxs("p", {
      children: ["The colors on the graph showcase activity in the burndown. The easiest way to understand the graph is to notice how the colors affect the bold blue line (", _jsx("i", {
        children: "Actual Stories/Points Remaining"
      }), ")."]
    }), entity === 'Epic' && _jsxs("div", {
      children: [_jsx("h4", {
        children: "Ideal Line"
      }), _jsx("p", {
        children: "If the Epic is still in progress and has a set end date, we include a line showing the Ideal number of stories/points that should be remaining in order to complete work on time."
      }), _jsx("h4", {
        children: "Projected End Date"
      }), _jsx("p", {
        children: "If the Epic is still in progress without a set end date, we provide a forward-looking projection line based on the velocity of completed stories/points."
      })]
    }), _jsx("h4", {
      children: "Stories vs Points"
    }), _jsxs("p", {
      children: ["The chart defaults to Stories (total count) or Points, depending on your", ' ', _jsx("a", {
        href: `${getSlugPath()}/settings/estimates`,
        children: "Estimate Scale"
      }), " settings. There is a dropdown to switch between the two on the report."]
    }), _jsx("h4", {
      children: "Non-Working Days"
    }), _jsxs("p", {
      children: [entity === 'Reports' && _jsx(_Fragment, {
        children: "When Group By is set to Day, "
      }), "Non-Working days are noted on the chart with hatched bars. The ideal line flattens across Non-Working days to indicate work is not expected to progress on these days. Working days for your Workspace can be adjusted in your", ' ', _jsx("a", {
        href: `${getSlugPath()}/settings/workspace`,
        children: "General Settings"
      }), "."]
    })]
  });
};
export const Explanation = _ref2 => {
  let {
    isEmbedded = false
  } = _ref2;
  const entity = capitalize(getCurrentPage());
  const name = entity === 'Iteration' ? 'Burndown' : 'ARC (Added/Remaining/Completed)';
  return _jsx(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''}${name} Chart`,
    imgSrc: entity === 'Iteration' ? BurndownChartImg : BurndownChartV2Img,
    description: _jsx(Description, {
      entity: entity
    }),
    footer: _jsx(ReportsHelpFooter, {})
  });
};
Explanation.displayName = "Explanation";