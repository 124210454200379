import { useCallback, useRef } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Dialog } from '@clubhouse/shared/components/Dialog';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Heading } from '@clubhouse/shared/components/Typography/Heading';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { useToggleState } from '@clubhouse/shared/hooks';
import { Spacer } from 'components/shared/Flex';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const ConfirmCloseDialog = _ref => {
  let {
    visible,
    onConfirm,
    onCancel
  } = _ref;
  return _jsx(Dialog, {
    visible: visible,
    onClose: onCancel,
    children: _jsxs(Spaced, {
      vertically: true,
      amount: "space4",
      children: [_jsx(Heading, {
        size: Heading.SIZE.SMALL,
        children: "Unsaved Changes"
      }), _jsx(Text, {
        children: "It looks like you have been editing categories. If you leave before saving, your changes will be lost."
      }), _jsxs(Spaced, {
        horizontally: true,
        amount: "space2",
        children: [_jsx(Spacer, {}), _jsx(Button, {
          type: "button",
          kind: Button.KIND.SECONDARY,
          onClick: onCancel,
          children: "Cancel"
        }), _jsx(Button, {
          type: "button",
          onClick: onConfirm,
          children: "Leave & Discard Changes"
        })]
      })]
    })
  });
};
ConfirmCloseDialog.displayName = "ConfirmCloseDialog";
const hasUnsavedChanges = dialog => {
  if (!dialog) return false;
  return dialog.querySelectorAll('[data-is-dirty="true"]').length > 0;
};
export const DialogWithUnsavedChanges = _ref2 => {
  let {
    onClose,
    ...rest
  } = _ref2;
  const dialogRef = useRef(null);
  const [showConfirmDialog, toggleConfirmDialog] = useToggleState();
  const handleConfirm = useCallback(() => {
    toggleConfirmDialog.off();
    onClose?.();
  }, [onClose, toggleConfirmDialog]);
  const handleClose = useCallback(() => {
    if (!hasUnsavedChanges(dialogRef.current)) onClose?.();else toggleConfirmDialog.on();
  }, [onClose, toggleConfirmDialog]);
  return _jsxs(_Fragment, {
    children: [_jsx(Dialog, {
      ...rest,
      ref: dialogRef,
      onClose: handleClose
    }), _jsx(ConfirmCloseDialog, {
      visible: showConfirmDialog,
      onCancel: toggleConfirmDialog.off,
      onConfirm: handleConfirm
    })]
  });
};