import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { isMac } from '@clubhouse/shared/utils';
import { useUpdateStoryPosition } from '../hooks/useUpdateStoryPosition';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MoveStoryActions = _ref => {
  let {
    storyId
  } = _ref;
  const updatePosition = useUpdateStoryPosition();
  return _jsxs(_Fragment, {
    children: [_jsx(MoreActions.Item, {
      icon: "ChevronUp",
      onClick: () => {
        updatePosition({
          storyId,
          moveTo: 'top'
        });
      },
      aside: `${isMac() ? 'cmd' : 'ctrl'} shift ↑`,
      children: "Move to top"
    }), _jsx(MoreActions.Item, {
      icon: "ChevronDown",
      onClick: () => {
        updatePosition({
          storyId,
          moveTo: 'bottom'
        });
      },
      aside: `${isMac() ? 'cmd' : 'ctrl'} shift ↓`,
      children: "Move to bottom"
    })]
  });
};