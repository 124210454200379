import EpicController from '../controllers/epic';
import EpicModel from '../../../core/js/models/epic';
import * as Event from '../../../core/js/_frontloader/event';
import SpaceModel from '../../../core/js/models/space';
const exports = {};

// Extends core/js/models/epic.

exports.initEventBindings = () => {
  EpicModel.on('beforeAdd', function onBeforeAdd(epic) {
    epic.active = SpaceModel.isModelActiveInActiveSpace('Epic', epic);
  });
  EpicModel.on('added removed flushed', function onEpicAdd() {
    if (EpicModel.isNotBulk()) {
      EpicController.render();
    }
  });
};
exports.toggle = epic => {
  epic.active = !epic.active;
  Event.trigger('spaceUpdate');
};
exports.setAllActive = () => {
  EpicModel.each(x => {
    x.active = x.archived !== true;
  });
};
exports.enableAll = () => {
  exports.setAllActive();
  EpicModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
exports.disableAll = () => {
  EpicModel.each(epic => {
    epic.active = false;
  });
  EpicModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
exports.onlyCurrentEpicActive = epic => {
  const activeEpics = EpicModel.filter({
    active: true
  });
  return activeEpics.length === 1 && activeEpics[0].id === epic.id;
};
export { exports as default };