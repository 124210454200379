import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { hasSearchParams, usePageSearch, useSearchParams } from 'utils/navigation';
import { useApplicationState, useUpdateApplicationState } from '../hooks';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const SetSearchParamsFromApplicationState = _ref => {
  let {
    children,
    applicationStateKey,
    searchParamKeys
  } = _ref;
  const search = usePageSearch();
  const {
    params: searchParams,
    setSearchParams
  } = useSearchParams(searchParamKeys);
  const [isInitialUrlEmpty, setIsInitialUrlEmpty] = useState(undefined);
  const [isDone, setIsDone] = useState(undefined);
  const isInitialNetworkFetchCompleted = useRef(false);
  const currentApplicationStateKey = useRef(undefined);
  const hasApplicationStateKeyChanged = currentApplicationStateKey.current !== applicationStateKey;
  currentApplicationStateKey.current = applicationStateKey;
  const {
    parsedCurrentUserPermissionSpaceData
  } = useApplicationState({
    onCompleted: data => {
      if (isInitialNetworkFetchCompleted.current) {
        return;
      }
      isInitialNetworkFetchCompleted.current = true;
      const spaceData = data.workspace2?.parsedCurrentUserPermissionSpaceData;
      if (isInitialUrlEmpty && spaceData && !isEqual(spaceData[applicationStateKey], searchParams)) {
        setIsDone(false);
        return;
      }
    },
    fetchPolicy: 'cache-and-network'
  });
  useEffect(() => {
    if (hasApplicationStateKeyChanged) {
      setIsDone(false);
      setIsInitialUrlEmpty(!hasSearchParams(searchParamKeys, search));
      return;
    }
    if (isDone || parsedCurrentUserPermissionSpaceData === undefined) {
      return;
    }
    const searchParams = parsedCurrentUserPermissionSpaceData?.[applicationStateKey];
    if (isInitialUrlEmpty && searchParams && typeof searchParams === 'object') {
      setSearchParams(searchParams);
    }
    setIsDone(true);
  }, [applicationStateKey, hasApplicationStateKeyChanged, isDone, isInitialUrlEmpty, parsedCurrentUserPermissionSpaceData, search, searchParamKeys, setSearchParams]);
  return isDone && !hasApplicationStateKeyChanged ? _jsx(UpdateApplicationStateOnSearchParamChange, {
    applicationStateKey: applicationStateKey,
    data: parsedCurrentUserPermissionSpaceData ?? null,
    params: searchParams,
    children: children
  }) : null;
};
const FailedSpaceDataToast = () => {
  return _jsxs(Spaced, {
    vertically: true,
    amount: "space2",
    children: [_jsx(ToastText, {
      children: "Failed to persist url search params."
    }), _jsx(ToastText, {
      children: "Refresh the page and try again."
    })]
  });
};
FailedSpaceDataToast.displayName = "FailedSpaceDataToast";
const toExpectedType = data => data ?? {};
const UpdateApplicationStateOnSearchParamChange = _ref2 => {
  let {
    applicationStateKey,
    children,
    data,
    params
  } = _ref2;
  const {
    addToast
  } = useToast();
  const dataRef = useRef(data);
  dataRef.current = data;
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType
  });
  const updateRef = useRef(update);
  updateRef.current = update;
  useAsync(async () => {
    if (!isEqual(dataRef.current?.[applicationStateKey], params)) {
      const value = await updateRef.current(() => params);
      if (typeof value?.data?.permissionSpaceDataReset?.success === 'boolean' && value.data.permissionSpaceDataReset.success === false) {
        addToast({
          kind: 'alert',
          Content: FailedSpaceDataToast
        });
      }
    }
  }, [applicationStateKey, params]);
  return _jsx(_Fragment, {
    children: children
  });
};