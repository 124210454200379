import { size } from '@floating-ui/react';
export const sameWidth = () => {
  return size({
    apply(_ref) {
      let {
        elements,
        rects
      } = _ref;
      Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`
      });
    }
  });
};