import { ContextMenuItem, ContextMenuItemLink } from '@clubhouse/shared/components/ContextMenu';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { copyToClipboard } from '@clubhouse/shared/utils';
import { usePinnedItem } from 'components/shared/pin-items/hooks/usePinnedItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OpenInNewTabContextMenuItem = _ref => {
  let {
    url
  } = _ref;
  return _jsx(ContextMenuItemLink, {
    icon: "NewTab",
    label: "Open in New Tab",
    href: url,
    target: "_blank"
  });
};
OpenInNewTabContextMenuItem.displayName = "OpenInNewTabContextMenuItem";
export const PinContextMenuItem = _ref2 => {
  let {
    id,
    entityType,
    loggingContext
  } = _ref2;
  const {
    isPinned,
    toggle
  } = usePinnedItem({
    id,
    loggingContext
  });
  return _jsx(ContextMenuItem, {
    icon: "Pin",
    label: `${isPinned ? 'Unpin' : 'Pin'} ${entityType.singular}`,
    onClick: toggle
  });
};
PinContextMenuItem.displayName = "PinContextMenuItem";
export const CopyContextMenuItem = _ref3 => {
  let {
    icon = 'Copy',
    value,
    label,
    isDisabled
  } = _ref3;
  return _jsx(ContextMenuItem, {
    icon: icon,
    label: `Copy ${label}`,
    isDisabled: isDisabled,
    onClick: async () => {
      await copyToClipboard(value);
      addToast({
        Content: () => _jsx(ToastText, {
          children: `${label} Copied!`
        }),
        timeout: 2000
      });
    }
  });
};
CopyContextMenuItem.displayName = "CopyContextMenuItem";