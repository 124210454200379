import { QueryDockedItemDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useEffect } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { DockedDoc, isDoc } from './DockedDoc';
import { DockedEpic, isEpic } from './DockedEpic';
import { DockedIteration, isIteration } from './DockedIteration';
import { DockedKeyResult, isKeyResult } from './DockedKeyResult';
import { DockedLoader } from './DockedLoader';
import { DockedObjective, isObjective } from './DockedObjective';
import { DockedStory, isStory } from './DockedStory';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const QueryDockedItem = QueryDockedItemDocument;
const TypenameFactory = _ref => {
  let {
    node,
    ...rest
  } = _ref;
  if (isStory(node)) return _jsx(DockedStory, {
    ...rest,
    node: node
  });
  if (isEpic(node)) return _jsx(DockedEpic, {
    ...rest,
    node: node
  });
  if (isIteration(node)) return _jsx(DockedIteration, {
    ...rest,
    node: node
  });
  if (isObjective(node)) return _jsx(DockedObjective, {
    ...rest,
    node: node
  });
  if (isKeyResult(node)) return _jsx(DockedKeyResult, {
    ...rest,
    node: node
  });
  if (isDoc(node)) return _jsx(DockedDoc, {
    ...rest,
    node: node
  });
  return null;
};
export const GenericDockedItemV2 = _ref2 => {
  let {
    globalId,
    index,
    onUnpin
  } = _ref2;
  const {
    data,
    previousData,
    loading,
    error
  } = useQuery(QueryDockedItem, {
    variables: {
      id: globalId
    },
    fetchPolicy: 'cache-first'
  });
  const node = (loading ? data || previousData : data)?.node ?? null;
  const unpin = useCallback(() => {
    onUnpin({
      id: globalId,
      loggingContext: {
        typename: node?.__typename ?? '__UNAVAILABLE_TYPE__'
      }
    });
  }, [globalId, node?.__typename, onUnpin]);
  useEffect(() => {
    if (!node && !error && !loading) {
      unpin();
    }
  }, [error, loading, node, unpin]);
  if (!node) return _jsx(DockedLoader, {
    globalId: globalId,
    index: index,
    onUnpinClicked: unpin
  });
  return _jsx(TypenameFactory, {
    node: node,
    index: index,
    onUnpinClicked: unpin
  });
};
GenericDockedItemV2.displayName = "GenericDockedItemV2";