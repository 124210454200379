import { NetworkStatus } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useAddQuery } from './UseQueryContext';
import { useAbortableQuery } from './useAbortableQuery';
import { useRefetchWhenTabIsActive } from './useRefetchWhenTabIsActive';
export const useQueryWithPolling = (query, options) => {
  const {
    pollInterval = 0,
    skip
  } = options ?? {};
  const {
    data,
    startPolling,
    stopPolling,
    refetch,
    networkStatus,
    fetchMore,
    observable,
    abort,
    resetAbort,
    rerun,
    ...result
  } = useAbortableQuery(query, {
    ...options,
    skipPollAttempt: () => true
  });
  const startCurrentPolling = useCallback(() => {
    if (pollInterval) startPolling(pollInterval);
  }, [pollInterval, startPolling]);
  useAddQuery(useMemo(() => ({
    queryId: observable.queryId,
    query,
    startPolling: startCurrentPolling,
    stopPolling,
    abort,
    resetAbort,
    rerun
  }), [abort, observable.queryId, query, rerun, resetAbort, startCurrentPolling, stopPolling]));
  useRefetchWhenTabIsActive({
    refetch,
    skip
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only want the initial value
  const isInitialDataFromCache = useMemo(() => Boolean(data) && networkStatus === NetworkStatus.loading, []);
  const stopPollingThenFetchThenStartPolling = useCallback(opts => {
    stopPolling();
    return fetchMore(opts).finally(() => startCurrentPolling());
  }, [fetchMore, startCurrentPolling, stopPolling]);
  return {
    ...result,
    data,
    startPolling: startCurrentPolling,
    stopPolling,
    refetch,
    networkStatus,
    isInitialDataFromCache,
    fetchMore: stopPollingThenFetchThenStartPolling,
    observable,
    abort,
    resetAbort
  };
};