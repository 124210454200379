import { Emojify } from '@clubhouse/shared/components/Emojify';
import { CollectionizeStoryAutoLink } from 'components/shared/CollectionizeStoryAutoLink/CollectionizeStoryAutoLink';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReactionCreate = _ref => {
  let {
    name,
    story,
    emoji,
    isAuthor
  } = _ref;
  return _jsxs("div", {
    className: "value",
    children: [_jsx("strong", {
      children: _jsx(Emojify, {
        children: name
      })
    }), ' ', "reacted with ", _jsx(Emojify, {
      children: emoji
    }), " to ", isAuthor ? 'your' : 'a', " comment from", ' ', _jsx(CollectionizeStoryAutoLink, {
      id: story.id,
      name: story.name,
      type: story.story_type,
      children: _jsx(Emojify, {
        children: story.name
      })
    })]
  });
};
ReactionCreate.displayName = "ReactionCreate";