import _JSXStyle from "styled-jsx/style";
import { DocCardDocFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { useTheme } from '@emotion/react';
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { getDocIconForAccessControlScope } from 'pages/write/lib/getDocIconForAccessControlScope';
import { useDocContextMenu } from 'utils/contextMenus/useDocContextMenu';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const DocCardDocFragment = DocCardDocFragmentDoc;
export function DocCard(_ref) {
  let {
    doc,
    onClick,
    disableHoverStyle,
    smallPadding
  } = _ref;
  const privacyIconClass = doc.accessControlScope.toLowerCase();
  const url = generatePathWithSlug(getPathForPage(PAGE_NAMES.DOC), {
    docId: doc.urlId ?? doc.id
  });
  const onClickHandler = onClick ? e => {
    e.preventDefault(e);
    onClick({
      ...doc,
      url
    });
  } : undefined;
  const ScopeIcon = getDocIconForAccessControlScope(doc.accessControlScope);
  const contextMenuProps = useDocContextMenu(doc.urlId ? doc.id : null, doc.urlId ?? doc.id);
  const theme = useTheme();
  return _jsxs(_Fragment, {
    children: [_jsxs("a", {
      href: url,
      "data-focusable": true,
      onClick: onClickHandler,
      ...contextMenuProps,
      className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]]) + " " + (contextMenuProps && contextMenuProps.className != null && contextMenuProps.className || "doc-card"),
      children: [_jsx("span", {
        className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]]) + " " + `icon ${privacyIconClass}`,
        children: _jsx(ScopeIcon, {
          className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]])
        })
      }), _jsx("div", {
        className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]]) + " " + "doc-title",
        children: doc.title || 'Untitled'
      }), (doc?.docCollections?.edges?.length ?? 0) > 0 && _jsx(Tooltip, {
        content: doc?.docCollections?.edges?.map(_ref2 => {
          let {
            node: collection
          } = _ref2;
          return _jsxs("span", {
            className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]]),
            children: [collection.name, _jsx("br", {
              className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]])
            })]
          }, collection.id);
        }),
        children: _jsxs("span", {
          className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]]) + " " + "doc-collections",
          children: [_jsx(SizedIcon, {
            icon: "Stack",
            size: 11,
            fill: "disabled"
          }), _jsx("span", {
            className: _JSXStyle.dynamic([["216093377", [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor]]]),
            children: doc?.docCollections?.pageInfo?.totalSize
          })]
        })
      })]
    }), _jsx(_JSXStyle, {
      id: "216093377",
      dynamic: [theme.color.background.elevated, smallPadding ? '15px 12px;' : '15px 20px;', theme.color.text.main, !disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null, theme.focusedElementBorderColor, theme.color.text.main, theme.anchorColor, theme.extremelyFadedTextColor, theme.storyBadgeBackgroundColor, theme.paragraphTextColor],
      children: `.doc-card.__jsx-style-dynamic-selector{background:${theme.color.background.elevated};display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-radius:6px;padding:${smallPadding ? '15px 12px;' : '15px 20px;'} position:relative;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:15px;-webkit-transition:background-color 250ms ease-in-out;transition:background-color 250ms ease-in-out;color:${theme.color.text.main};}${!disableHoverStyle ? `
        .doc-card:hover {
          outline: none;
          background-color: ${theme.blockHoverBackground};
        }
        ` : null}.__jsx-style-dynamic-selector .doc-card.focus-visible.__jsx-style-dynamic-selector,.doc-card.__jsx-style-dynamic-selector:focus-visible{outline:2px solid ${theme.focusedElementBorderColor};border-color:transparent;border-radius:4px;}.doc-title.__jsx-style-dynamic-selector{font-size:14px;line-height:17px;color:${theme.color.text.main};margin-left:10px;margin-right:5px;-webkit-flex:1;-ms-flex:1;flex:1;}.icon.__jsx-style-dynamic-selector{color:${theme.anchorColor};}.icon.private.__jsx-style-dynamic-selector{color:${theme.extremelyFadedTextColor};}.doc-collections.__jsx-style-dynamic-selector{background-color:${theme.storyBadgeBackgroundColor};color:${theme.paragraphTextColor};display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:3px 5px;border-radius:2px;}`
    })]
  });
}
export function DocCardAdapter(props) {
  const docCollections = {
    __typename: 'DocCollectionConnection',
    edges: props.doc?.collections?.map(collection => ({
      __typename: 'DocCollectionEdge',
      node: {
        __typename: 'DocCollection',
        ...collection
      }
    })) ?? [],
    pageInfo: {
      __typename: 'PageInfo',
      totalSize: props.doc?.collections?.length ?? 0
    }
  };
  const doc = {
    __typename: 'Doc',
    id: props.doc.id,
    urlId: props.doc.id,
    title: props.doc.title,
    accessControlScope: props.doc?.accessControlScope.toLowerCase() ?? 'workspace',
    docCollections
  };
  return _jsx(DocCard, {
    ...props,
    doc: doc
  });
}
DocCardAdapter.displayName = "DocCardAdapter";