import { capitalize } from '@clubhouse/shared/utils';
import { getArticleForEntity, SELECTABLE_ENTITIES, useEntitySelected } from './utils';
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function EntityTypeSelector() {
  const [entity, setEntity] = useEntitySelected();
  return _jsx("div", {
    className: "stories-panel-entity-selector",
    children: _jsxs("span", {
      children: ["Show stories from ", getArticleForEntity(entity), ' ', _jsxs(ComboboxSelect, {
        onChange: value => setEntity(value),
        children: [_jsx(ComboboxSelect.Trigger, {
          children: _jsxs("strong", {
            className: "entity-dropdown",
            children: [capitalize(entity), _jsx("span", {
              className: "fa fa-caret-down"
            })]
          })
        }), _jsx(ComboboxSelect.Menu, {
          children: _jsx(ComboboxSelect.List, {
            children: SELECTABLE_ENTITIES.map(e => _jsx(ComboboxSelect.Option, {
              value: e,
              isSelected: e === entity,
              children: capitalize(e)
            }, e))
          })
        })]
      })]
    })
  });
}
EntityTypeSelector.displayName = "EntityTypeSelector";