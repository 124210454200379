import { EnabledCustomFieldsDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { EnabledCustomFieldsFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { emptyArray } from '@clubhouse/shared/utils';
import { useQuery } from 'gql';
import { useMemo } from 'react';
import { useWorkspaceSlug } from 'utils/navigation';
export const EnabledCustomFieldsFragment = EnabledCustomFieldsFragmentDoc;
const EnabledCustomFieldsQuery = EnabledCustomFieldsDocument;
export function useEnabledCustomFields() {
  const workspaceSlug = useWorkspaceSlug();
  const {
    data,
    previousData
  } = useQuery(EnabledCustomFieldsQuery, {
    variables: {
      slug: workspaceSlug
    }
  });
  const dataToRender = data || previousData;
  const enabledCustomFieldEdges = dataToRender?.workspace2?.enabledCustomFields?.edges || emptyArray;
  return useMemo(() => ({
    skillSet: enabledCustomFieldEdges.find(edge => edge?.node?.canonicalName === 'skillSet')?.node || null,
    technicalArea: enabledCustomFieldEdges.find(edge => edge?.node?.canonicalName === 'technicalArea')?.node || null,
    productArea: enabledCustomFieldEdges.find(edge => edge?.node?.canonicalName === 'productArea')?.node || null,
    severity: enabledCustomFieldEdges.find(edge => edge?.node?.canonicalName === 'severity')?.node || null,
    priority: enabledCustomFieldEdges.find(edge => edge?.node?.canonicalName === 'priority')?.node || null
  }), [enabledCustomFieldEdges]);
}