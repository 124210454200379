import * as InviteFormTemplate from 'app/client/login/views/templates/inviteForm.html';
import * as InviteNotFoundTemplate from 'app/client/login/views/templates/inviteNotFound.html';
import { InviteNotFound } from 'components/invite/InviteNotFound';
import { InvitesExhausted } from 'components/invite/InvitesExhausted';
import { SignupOptions } from 'components/login/SignupOptions';
import { InviteLoginForm } from 'components/signup/InviteLoginForm';
import { JoinTeamPage } from 'pages/invites/JoinTeamPage';
import Backend from '../../../core/js/modules/backend';
import * as Event from '../../../core/js/_frontloader/event';
import Iterate from '../../../core/js/modules/iterate';
import Log from '../../../core/js/modules/log';
import LogoutController from '../../../core/js/controllers/logout';
import OrganizationModel from '../../../core/js/models/organization';
import Segment from '../../../core/js/modules/segment';
import CreateOrgOrWorkspaceController from './createOrgOrWorkspace';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import InviteModel from '../../../core/js/models/invite';
import SignupModel from '../../../core/js/models/signup';
import Utils from '../../../core/js/modules/utils';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import View from '../../../core/js/modules/view';
import FlashController from '../../../core/js/controllers/flash';
import { createNode } from '@clubhouse/shared/utils/dom';
import { EVENTS, logError, setSessionAttributes } from 'utils/monitoring';
import { setPageTitle } from 'utils/page';
import Is from '../../../core/js/modules/is';
import { generatePathForPage } from 'utils/navigation';
import { getTeamScopedURL } from 'components/team-navigation';
import { fetchAndGetTeamScopedUrlForUser } from 'data/entity/group';
const exports = {};
const PARENT_SELECTOR = '#invite-form';
const orgName = '';
const getRedirectUrl = async (org, permission, teams) => {
  const baseUrl = generatePathForPage(CreateOrgOrWorkspaceController.DEFAULT_REDIRECT_PAGE, {
    slug: org.url_slug
  });
  const href = `${baseUrl}?from=signup`;
  if (teams?.length) {
    const teamGlobalId = teams[0].global_id;
    const {
      pathname,
      search
    } = getTeamScopedURL(href, teamGlobalId, window.location.origin);
    return `${pathname}${search}`;
  } else if (!teams?.length && org && permission) {
    const {
      pathname,
      search
    } = await fetchAndGetTeamScopedUrlForUser(href, org.id, permission.id, window.location.origin);
    return `${pathname}${search}`;
  } else {
    return href;
  }
};
const redirectForExistingUser = async (permission, joinedTeams) => {
  Utils.redirect(await getRedirectUrl(permission?.organization || OrganizationModel.getCurrent(), permission, joinedTeams));
};
exports.init = () => {
  const params = Url.parseLocationSearch();
  const inviteId = Url.parsePath().invites;
  Backend.get('/api/private/invites/' + inviteId, {
    onComplete: async res => {
      if (exports.isNewUser(res)) {
        const isUserGDPR = await UserModel.Promises.isGDPR();
        setPageTitle(`Create your account | ${BRAND.NAME}`);
        if (isSSORequired(res) || params['create-account']) {
          exports.render(res, {
            isUserGDPR
          });
        } else {
          renderSignupOptions(res, {
            isUserGDPR
          });
        }
      } else if (exports.isExistingUser(res)) {
        const permission = res.permission;
        if (Is.observer(permission)) {
          redirectForExistingUser(permission);
        } else {
          exports.renderJoinTeam(permission, joinedTeams => redirectForExistingUser(permission, joinedTeams));
        }
      } else if (exports.isLoginRequired(res)) {
        LogoutController.logoutAndRedirect({
          messageCode: 2
        });
      } else if (exports.isLogoutRequired(res)) {
        LogoutController.logoutAndRedirect({
          url: Url.getCurrentPathnameWithHash()
        });
      } else if (exports.isWrongUser(res)) {
        LogoutController.logoutAndRedirect({
          messageCode: 3
        });
      } else {
        setPageTitle(`Invalid invite | ${BRAND.NAME}`);
        exports.renderInviteNotFound(res.message);
      }
    }
  });
};
const renderSignupOptions = (invite, options) => {
  View.renderComponent({
    component: SignupOptions,
    props: {
      googleOauthHref: `${Backend.getServerURL()}/oauth/invite/${invite.id}`,
      email: invite.email,
      onClickPassword: () => {
        trackSignupClick();
        Event.trigger('pageDestroy');
        exports.render(invite, options);
        return false;
      },
      onClickGoogle: () => {
        trackSignupClick({
          is_oauth: true
        });
      },
      errorCode: Url.parseLocationSearch().msg
    },
    mountNode: getFormContainer()
  });
  Utils.fadePageIn();
};
const trackSignupClick = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  Segment.sendEvent(EVENTS.Invite_SignupAttempted, {
    is_oauth: false,
    ...data
  });
};
exports.isNewUser = obj => {
  return _.isPlainObject(obj) && obj.used === false && obj.email;
};
exports.isExistingUser = obj => {
  return _.isPlainObject(obj) && _.isPlainObject(obj.permission) && obj.email;
};
exports.isLoginRequired = res => {
  return _.isPlainObject(res) && res.message === 'Login Required';
};
exports.isLogoutRequired = res => {
  return _.isPlainObject(res) && res.message === 'Logout Required';
};
exports.isWrongUser = res => {
  return _.isPlainObject(res) && res.message === 'Logged in as wrong user.';
};
function isSSORequired(invite) {
  return _.get(invite, 'sign_on_policy') === 'sso-required-for-all';
}
const getFormContainer = () => document.querySelector('#form-container');
const replaceFormContainer = () => {
  getFormContainer().replaceWith(createNode({
    id: 'form-container'
  }));
};
exports.render = (invite, _ref) => {
  let {
    isUserGDPR
  } = _ref;
  const html = InviteFormTemplate.render();
  replaceFormContainer();
  getFormContainer().innerHTML = html;
  View.renderComponent({
    componentKey: 'inviteLogin',
    component: InviteLoginForm,
    props: {
      onSubmit: exports.submit,
      isUserGDPR,
      sso: isSSORequired(invite),
      ...invite
    },
    containerSelector: '#invite-login-form'
  });
  Utils.fadePageIn();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'Invite');
};
exports.renderInviteNotFound = message => {
  const html = InviteNotFoundTemplate.render({
    message
  });
  $('#form-container').html(html);
  View.renderComponentConditionally({
    containerSelector: '#invite-not-found',
    component: InviteNotFound,
    props: {
      message
    }
  });
  Utils.fadePageIn();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'InviteNotFound');
};
exports.markAsChanged = function () {
  $(this).addClass('manual-override');
};
exports.multiInviteSubmit = async (formData, _ref2) => {
  let {
    sso,
    signupId
  } = _ref2;
  formData.user_name = formData.name;
  formData.user_password = formData.password;
  // TODO: Ask BE to consolidate these properties.. seems confusing to name them differently when they are used in the same way.
  delete formData.password;
  delete formData.password_confirm;
  delete formData.name;
  try {
    let res;
    const endpoint = sso ? 'join-sso' : 'join';
    res = await SignupModel.Promises.registerUser(signupId, endpoint, formData);
    const email = _.get(res, 'emails.0.email_address');
    if (formData.invite_ids.length > 0 && signupId) {
      const deleteResponse = await SignupModel.Promises.deleteSignup(signupId);
      if (deleteResponse && deleteResponse.error) {
        exports.renderError(deleteResponse);
      }
    }
    if (res) {
      if (res.success && formData.invite_ids.length > 0) {
        Segment.sendEvent(EVENTS.Invite_Used, {
          action_context: 'signup'
        });
      }
      if (res.error || res.success === false) {
        if (res.entitlement_tag === 'active-seats-create') {
          exports.renderInvitesExhaustedError(res);
        } else {
          exports.renderError(res);
        }
      } else if (res.saml_signon_url) {
        Log.log('SSO User invited', {
          email
        });
        exports.renderSuccess(res, {
          email
        });
      } else if (!res.error) {
        Log.log('User invited', {
          email
        });
        exports.renderSuccess(formData, {
          email
        });
      }
    }
  } catch (err) {
    Log.error(err);
  }
};
exports.submit = async (formData, _ref3) => {
  let {
    sso
  } = _ref3;
  const path = Url.parsePath();
  const inviteId = path.invites;
  const secret = Url.getUrlSearchParams().get('secret');
  try {
    let res;
    let endpoint = sso ? 'create-sso-user' : 'create-user';
    endpoint = secret ? `${secret}/${endpoint}` : endpoint;
    res = await InviteModel.Promises.registerUser(inviteId, endpoint, formData);
    const email = _.get(res, 'emails.0.email_address');
    if (res) {
      if (res.success) {
        Segment.sendEvent(EVENTS.Invite_Used, {
          action_context: 'signup'
        });
      }
      if (res.error || res.success === false) {
        if (res.reason_tag === 'invites-exhausted') {
          exports.renderInvitesExhaustedError(res);
        } else {
          exports.renderError(res);
        }
      } else if (res.saml_signon_url) {
        Log.log('SSO User invited', {
          email
        });
        exports.renderSuccess(res, {
          email
        });
      } else if (!res.error) {
        Log.log('User invited', {
          email
        });
        exports.renderSuccess(formData, {
          email
        });
      }
    }
  } catch (err) {
    Log.error(err);
  }
  return false;
};
exports.renderInvitesExhaustedError = () => {
  $('#form-container').html('<div id="invites-exhausted"></div>');
  View.renderComponentConditionally({
    containerSelector: '#content',
    component: InvitesExhausted,
    props: {
      organizationName: orgName
    }
  });
  Utils.fadePageIn();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'InvitesExhausted');
};
exports.renderError = res => {
  const context = $(PARENT_SELECTOR);
  const button = context.find('button.action');
  let title = 'We were unable to save.';
  if (res.error === 'The server response was invalid.') {
    // Once we can filter out used invites, we should never get here.
    title = 'Invite already used.';
  }
  Log.log('Error during invite sign up process.', {
    originalResponse: res
  });
  FlashController.error(context, title, res.message, true);
  button.html('Create Account <span class="fa fa-arrow-right"></span>').removeClass('disabled').removeAttr('disabled');
};
const _getFetchPromise = () => new Promise(resolve => ConsolidatedFetch.fetchUserOrgAndApplicationState(resolve));
const _fetchDataBeforeRedirect = () => {
  const timeoutPromise = new Promise(resolve => setTimeout(resolve, 2000));
  return Promise.all([_getFetchPromise(), timeoutPromise]).then(_ref4 => {
    let [data] = _ref4;
    return data;
  });
};
exports.redirectToApp = async (data, permission, teams) => {
  if (data.saml_signon_url) {
    Utils.redirect(data.saml_signon_url);
  } else {
    const org = permission?.organization || OrganizationModel.getCurrent();
    Utils.redirect(await getRedirectUrl(org, permission, teams));
  }
};
exports.renderJoinTeam = (permission, onContinue) => {
  replaceFormContainer();
  getFormContainer().innerHTML = View.renderComponentDelayed({
    componentKey: 'join-team-page',
    component: JoinTeamPage,
    props: {
      permission,
      onContinue
    }
  }).html;
};
exports.renderSuccess = async (data, userData) => {
  const [org] = await _fetchDataBeforeRedirect();
  const permission = UserModel.getLoggedInUserPermission(org.id);
  try {
    setSessionAttributes({
      userId: permission.user_id,
      email: userData?.email ?? '',
      workspaceId: permission.workspace2.id
    });
  } catch (err) {
    logError(err);
  }
  if (!permission || Is.observer(permission)) {
    exports.redirectToApp(data, permission);
  } else {
    exports.renderJoinTeam(permission, joinedTeams => exports.redirectToApp(data, permission, joinedTeams));
  }
};
exports.findFirstValidOrgSlug = user => {
  let url_slug = '';
  Iterate.each(user.profiles, loggedInProfile => {
    const permission = UserModel.getDefaultPermissionFromProfile(loggedInProfile);
    const company = loggedInProfile.company || {};
    const org = permission.organization;
    const orgIsNotDisabled = OrganizationModel.isDisabled(org.id) !== true;
    if (permission.disabled !== true && company.locked_out !== true && orgIsNotDisabled) {
      url_slug = '/' + org.url_slug;
      return false;
    }
  });
  return url_slug;
};
export { exports as default };