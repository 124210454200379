import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const FlagCheckered = _ref => {
  let {
    fill,
    width
  } = _ref;
  return _jsx("svg", {
    fill: fill,
    width: width,
    height: width,
    "data-name": "Layer 16",
    viewBox: "0 0 32 32",
    children: _jsx("path", {
      d: "M7 5a2.5 2.5 0 1 0-3.5 2.29V28.5a1 1 0 0 0 2 0V7.29A2.5 2.5 0 0 0 7 5zm22.57 2L19 9.31v-3L8 8.72v14l9-2v3l12.38-2.47-2.72-6.72zM16.94 8.75v6l-7 1.53v-6zM19 21.3V16l5.53-1.33 2.1 5.08z"
    })
  });
};
FlagCheckered.displayName = "FlagCheckered";