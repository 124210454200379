import { useEffect } from 'react';
import { useHasValueChanged } from '@clubhouse/shared/hooks';
export const useRestartPollingWhenVariablesChange = _ref => {
  let {
    variables,
    stopPolling,
    startPolling
  } = _ref;
  const hasVariablesChanged = useHasValueChanged({
    value: variables
  });
  useEffect(() => {
    if (hasVariablesChanged) {
      stopPolling();
      startPolling();
    }
  }, [hasVariablesChanged, startPolling, stopPolling]);
};