import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useEffect, useRef } from 'react';
const defaultOnNextProps = _ref => {
  let {
    name,
    changes
  } = _ref;
  console.log(`<${name}> render`, changes);
};
export const useOnNextProps = _ref2 => {
  let {
    name,
    props,
    onNextProps = defaultOnNextProps
  } = _ref2;
  const previousProps = useRef(props);
  const changes = [...new Set(Object.keys(props).concat(Object.keys(previousProps.current)))].reduce((acc, propName) => {
    if (previousProps.current[propName] !== props[propName]) {
      acc[propName] = {
        previous: previousProps.current[propName],
        current: props[propName]
      };
    }
    return acc;
  }, {});
  let effect = undefined;
  const isInitialRenderRef = useRef(true);
  if (!isInitialRenderRef.current) {
    effect = onNextProps({
      name,
      changes
    });
  }
  useEffect(() => {
    if (effect) {
      effect();
    }
  }, [effect]);
  previousProps.current = props;
  isInitialRenderRef.current = false;
};