import SettingsPageController from 'app/client/settings/js/controllers/settingsPage';
import SettingsPageInit from 'app/client/settings/js/modules/settingsInit';
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard';
import ManageTeamsController from 'app/client/settingsShared/js/controllers/manageTeams';
import ManageUsersController from 'app/client/settingsShared/js/controllers/manageUsers';
import { getActive, setActive } from 'data/entity/team';
import { loadURL } from 'utils/hotReload';
import { getSlugPath } from './navigation';
export const openManageUsers = event => {
  event && event.preventDefault();
  ManageUsersController.open();
  return false;
};
export const openManageUsersAtImportTab = event => {
  event && event.preventDefault();
  ManageUsersController.openAtImportTab();
  return false;
};
export const openManageCard = event => {
  event && event.preventDefault();
  ManageCardController.open();
  return false;
};
export const openCreateWorkflowSettingsPage = () => {
  // Teams, in this context, refers to an entity that collects workflow states into a set of states
  ManageTeamsController.openCreateNew();
};
export const openEditWorkflowStatesPage = () => {
  const storiesPageTeam = getActive('stories');
  setActive(storiesPageTeam, 'Workflows');
  loadURL(`${getSlugPath()}/settings/workflow`, 'settings');
};
export const getSubnav = () => SettingsPageController.getSubnav();
export const loadStyles = () => SettingsPageInit.loadStyles();
export const loadLegacySettingsPage = pageController => SettingsPageController.loadLegacySettingsPage(pageController);