import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '../../colors';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="ChevronRight" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PointRight = _ref => {
  let {
    fill = COLORS.BLACK,
    width = '10px'
  } = _ref;
  return _jsx(DeprecatedIconAdapter, {
    fill: fill,
    width: width,
    children: _jsx(Icon, {
      icon: "ChevronRight"
    })
  });
};
PointRight.displayName = "PointRight";