import { EventNamesEnum } from '@useshortcut/events';
import { getAllFeatureToggles } from '@clubhouse/feature-toggles';
import { attachConsentPreferences } from 'components/consentManager/utils';
import { isPlaywrightTestEnv } from '../../isPlaywrightTestEnv';
import { SEGMENT_EVENTS } from '../ownedEvents';
import { NOOP_MONITOR, getNamedEventGlobalAttributes, getUserParameters, initializeAsync } from '../utils';
import { analytics } from './analytics';
export const NAME = 'Segment';
export const shouldInitialize = _ref => {
  let {
    env
  } = _ref;
  if (isPlaywrightTestEnv() || env === 'test') {
    return false;
  }
  return true;
};
export const shouldOwnEvent = event => SEGMENT_EVENTS.has(event);
export const shouldOwnNamedEvent = event => Object.values(EventNamesEnum).includes(event);
export const initialize = (_, _ref2) => {
  let {
    onError
  } = _ref2;
  const {
    callWhenReady,
    triggerInitialization
  } = initializeAsync(async attrs => {
    attachConsentPreferences();
    setSessionAttributes(attrs);
  }, {
    triggerManually: true,
    onError
  });
  return {
    ...NOOP_MONITOR,
    setSessionAttributes: attrs => {
      triggerInitialization(attrs);
    },
    setCurrentPage: callWhenReady(pageEvent),
    logEvent: callWhenReady(sendEvent),
    logNamedEvent: callWhenReady(logNamedEvent)
  };
};
let sessionAttributes;
const setSessionAttributes = attrs => {
  const {
    userId
  } = attrs;
  const userParams = getUserParameters(attrs);
  sessionAttributes = {
    userId,
    ...userParams
  };
  if (!userId) throw new Error('Analytic tracking requires a "userId" and it is missing! Please fix otherwise this event will not track properly');
  const feature_toggles = getAllFeatureToggles();
  identify(userId, {
    ...sessionAttributes,
    feature_toggles
  });
};
const identify = (userId, attrs) => analytics.identify(userId, attrs);
const pageEvent = () => analytics.page();

// The 4th parameter to `track` is just a callback function that gets called after
// a ~300ms delay. It doesn't necessarily guarantee that the event is sent, but it suffices.
// We Might need to upgrade to v2 of the SDK
const sendEvent = async (eventName, attrs) => {
  return new Promise(resolve => {
    if (sessionAttributes.userId && SEGMENT_EVENTS.has(eventName)) {
      analytics.track(eventName, {
        ...attrs,
        userId: sessionAttributes.userId
      }, {}, () => resolve());
    }
  });
};
const logNamedEvent = async (eventName, data) => {
  return new Promise(resolve => {
    const sendEvent = async (eventName, data) => {
      analytics.track(eventName, {
        ...data,
        userId: sessionAttributes.userId
      }, {}, () => resolve());
    };
    if (sessionAttributes.userId) {
      const namedEventGlobalAttrs = getNamedEventGlobalAttributes(eventName, {
        organizationId: sessionAttributes.organizationId,
        workspaceId: sessionAttributes.workspaceId,
        userId: sessionAttributes.userId
      });
      sendEvent(eventName, {
        ...data,
        ...namedEventGlobalAttrs
      });
    }
  });
};