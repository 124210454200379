import "core-js/modules/es.array.push.js";
import moment from 'moment';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { capitalize } from '@clubhouse/shared/utils';
import { calculateDuration, secondsToDays } from '@clubhouse/shared/utils/date';
import { getCurrentUtcOffset, getOrgWorkingDays } from 'data/entity/organization';
import { getFirstDoneStateForWorkflow, getFirstStartedStateForWorkflow } from 'data/entity/workflow';
import { fromLogScale, isNumber } from 'utils/math';
import { getChartConfig } from './config';
import { CHART_COLORS, CHART_ID, KEYS, LEGEND_ID_TO_ROW_KEY, NON_INTERACTIVE_IDS, ROW_KEYS } from './constants';
import StoryModel from '../../../models/story';
import Log from '../../../modules/log';
export const getStartStateForType = type => {
  if (type === 'custom') {
    return getChartConfig('ct_workflow_start_state');
  } else if (type === 'cycle time') {
    const workflowId = Number(getChartConfig('ct_workflow'));
    return isNaN(workflowId) ? undefined : getFirstStartedStateForWorkflow(workflowId)?.id;
  }
  // lead time returns undefined because the story created date is used
};
export const getEndStateForType = type => {
  if (type === 'custom') {
    return getChartConfig('ct_workflow_end_state');
  }
  const workflowId = Number(getChartConfig('ct_workflow'));
  return isNaN(workflowId) ? undefined : getFirstDoneStateForWorkflow(workflowId)?.id;
};
export const isEndState = _ref => {
  let {
    endingWFStateId,
    historicState
  } = _ref;
  return endingWFStateId ? historicState.workflow_state_id === endingWFStateId : historicState.workflow_state_type === WORKFLOW_STATE_TYPES.DONE;
};
export const isStartState = _ref2 => {
  let {
    startingWFStateId,
    historicState
  } = _ref2;
  return startingWFStateId ? historicState.workflow_state_id === startingWFStateId : historicState.workflow_state_type === WORKFLOW_STATE_TYPES.STARTED;
};
export const dateIsInRange = (date, range) => {
  return date.isBefore(range.end.endOf('day')) && date.isAfter(range.start.startOf('day'));
};
export const latestCompletedStateIsInRange = _ref3 => {
  let {
    history,
    dateRange,
    endingWFStateId
  } = _ref3;
  const latestCompletedState = history.filter(historicState => isEndState({
    endingWFStateId,
    historicState
  })).sort((a, b) => moment(a.date_entered).diff(b.date_entered)).pop();
  return latestCompletedState ? dateIsInRange(moment(latestCompletedState.date_entered), dateRange) : false;
};

/**
 * Filters the chart data for stories that are in range, which is defined as:
 *  - Present in the Story Collectionize model
 *  - Their last completed date is in range of the requested time range
 *
 * Returns a list of stories from the collectionize model with a nested object `cycleTimeData` which contains:
 *  - computed cycle and lead time
 *  - the earliest date it was moved to start state
 *  - latest date it was moved to completed.
 */
export const getStoriesInRange = _ref4 => {
  let {
    chartData,
    options,
    startingWFStateId,
    endingWFStateId
  } = _ref4;
  const stories = [];
  const utcOffset = getCurrentUtcOffset();
  chartData.forEach(storyData => {
    if (!latestCompletedStateIsInRange({
      history: storyData.history,
      dateRange: options.dateRange,
      endingWFStateId
    })) {
      return;
    }
    let startTime = null;
    let endTime = null;

    // Get the latest completed date and ealiest start date within range.
    // All other moves to and from start and end states are ignored.
    // A `startTime` may not exist if a story was created in a Done state.
    storyData.history.forEach(historicState => {
      const time = historicState.date_entered;
      if (isEndState({
        endingWFStateId,
        historicState
      })) {
        if (!endTime || moment(time).isAfter(endTime)) endTime = time;
      }
      if (isStartState({
        startingWFStateId,
        historicState
      })) {
        if (!startTime || moment(time).isBefore(startTime)) startTime = time;
      }
    });
    const workingDays = getOrgWorkingDays();
    const {
      story_id,
      name,
      created_at,
      story_type,
      iteration
    } = storyData;
    const story_type_icon = StoryModel.getStoryTypeIcon(storyData);
    try {
      stories.push({
        id: story_id,
        name,
        created_at,
        story_type,
        story_type_icon,
        iteration,
        cycleTimeData: {
          cycleTime: secondsToDays(calculateDuration([startTime, endTime], utcOffset, {
            includeDays: workingDays
          })),
          leadTime: secondsToDays(calculateDuration([created_at, endTime], utcOffset, {
            includeDays: workingDays
          })),
          startDate: moment(startTime),
          endDate: moment(endTime)
        }
      });
    } catch (err) {
      // Log additional information to root cause this issue
      // https://sentry.io/share/issue/267650d9a9e74d2bab2185a257076b67/
      Log.error(err, {
        storyId: story_id,
        startTime,
        endTime,
        created_at,
        workingDays: JSON.stringify(workingDays),
        utcOffset
      });
      throw err;
    }
  });
  return stories;
};
export const setClassForElements = _ref5 => {
  let {
    targetClass,
    className,
    isAdd
  } = _ref5;
  const targetElements = document.getElementById(CHART_ID)?.getElementsByClassName(targetClass);
  if (!targetElements) return;
  for (const element of targetElements) {
    if (isAdd) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }
};
export const findChartTooltipContainerElement = () => document.getElementById(CHART_ID)?.getElementsByClassName('c3-tooltip-container')[0];
export const findChartTooltipElement = () => document.getElementById(CHART_ID)?.getElementsByClassName('c3-tooltip')[0];
export const hideC3TooltipContainer = () => findChartTooltipContainerElement().style.visibility = 'hidden';
export const showC3TooltipContainer = () => findChartTooltipContainerElement().style.visibility = 'visible';
export const hideC3Tooltip = () => findChartTooltipElement().style.display = 'none';
export const formatTooltipName = name => name.length <= 50 ? name : name.substr(0, 47).trim() + '...';
export const findValuesForDate = _ref6 => {
  let {
    formattedData,
    date,
    value
  } = _ref6;
  const results = [];
  const valueAtScale = isNumber(value) && getChartConfig('ct_scale') === 'log' ? fromLogScale(value) : value;
  formattedData.tooltips[moment(date).format('YYYY-MM-DD')].filter(item => {
    if (isNumber(valueAtScale)) return Math.abs(item.value - valueAtScale) < 0.1;

    // Otherwise, we only want to display stories in the tooltip panel.
    return item.type !== '7 Day Trailing Average' && item.type !== 'Standard Deviation' && item.type !== 'Standard Deviation2';
  }).sort((a, b) => b.value - a.value).forEach(item => {
    results.push({
      name: item.name,
      story: item.story,
      value: formatValueLabel(item.value),
      type: item.type
    });
  });
  return results;
};
export const formatValueLabel = value => {
  let total = value;
  const d = Math.floor(total);
  total = (total - d) * 24;
  const h = Math.floor(total);
  total = (total - h) * 60;
  const m = Math.floor(total);
  return `${d}d ${h}h ${m}m`;
};
export const findDateFromTooltipValue = _ref7 => {
  let {
    formattedData,
    id,
    index
  } = _ref7;
  const key = ROW_KEYS[LEGEND_ID_TO_ROW_KEY[id]];
  index = index + 1; // adding one to account for the header value.

  return {
    date: formattedData.rows[key.x][index],
    value: formattedData.rows[key.y][index]
  };
};
const isStoryType = type => type === 'bug' || type === 'feature' || type === 'chore';
export const getTooltipRows = _ref8 => {
  let {
    date,
    value,
    formattedData,
    toggleStates
  } = _ref8;
  const rows = [];
  findValuesForDate({
    formattedData,
    date,
    value
  }).forEach(value => {
    const legendId = isStoryType(value.type) ? `${capitalize(value.type)}s` : value.type;
    const typeIsToggledOn = toggleStates[legendId];
    if (!typeIsToggledOn) return;
    const color = value.type === '7 Day Trailing Average' || value.type === 'Standard Deviation' || value.type === 'Standard Deviation2' ? CHART_COLORS[value.type] : CHART_COLORS[legendId];
    rows.push({
      color,
      story: value.story,
      name: value.name,
      value: value.value
    });
  });
  return rows;
};
export const shouldNotAllowPointerEvents = id => NON_INTERACTIVE_IDS.includes(id);
export const toggleState = _ref9 => {
  let {
    id,
    chartData,
    options,
    toggleStates,
    renderChart
  } = _ref9;
  toggleStates[id] = !toggleStates[id];
  renderChart({
    chartData,
    options,
    toggleStates
  });
};
export const isToggledOnInLegend = _ref10 => {
  let {
    toggleStates,
    id
  } = _ref10;
  return id === KEYS.StandardDeviation2 ? toggleStates[KEYS.StandardDeviation] : toggleStates[id];
};