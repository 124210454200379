/**
 * Converts the active tab from quick search to the correct plural form expected by the monitoring API
 */
export function convertActiveTabForMonitoring(activeTab) {
  switch (activeTab) {
    case 'story':
      return 'stories';
    case 'epic':
      return 'epics';
    case 'objective':
      return 'objectives';
    case 'iteration':
      return 'iterations';
    case 'doc':
      return 'docs';
    default:
      return undefined;
  }
}