import { EpicStatsProgressFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
export const EpicStatsProgressFragment = EpicStatsProgressFragmentFragmentDoc;
export const getProgressStats = (stats, isUsingPoints) => {
  if (isUsingPoints) {
    return {
      [WORKFLOW_STATE_TYPES.DONE]: stats.numPointsCompleted || 0,
      [WORKFLOW_STATE_TYPES.STARTED]: stats.numPointsInProgress || 0,
      [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.numPointsUnstarted || 0,
      [WORKFLOW_STATE_TYPES.BACKLOG]: stats.numPointsBacklog || 0
    };
  } else {
    return {
      [WORKFLOW_STATE_TYPES.DONE]: stats.numStoriesCompleted || 0,
      [WORKFLOW_STATE_TYPES.STARTED]: stats.numStoriesInProgress || 0,
      [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.numStoriesUnstarted || 0,
      [WORKFLOW_STATE_TYPES.BACKLOG]: stats.numStoriesBacklog || 0
    };
  }
};