import * as ManageTokensTemplate from 'app/client/settingsShared/views/templates/manageTokens.html';
import * as ExistingTokensTemplate from 'app/client/settingsShared/views/templates/existingTokens.html';
import FlashController from '../../../core/js/controllers/flash';
import Format from '../../../core/js/modules/format';
import TokenModel from '../../../core/js/models/token';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
const exports = {};
const PARENT_SELECTOR = '#manage-tokens';
exports.title = `API Tokens | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/account/api-tokens';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  const html = ManageTokensTemplate.render({
    active: 'manageTokens'
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  TokenModel.fetchAll(() => {
    if (Url.isCurrentPath(exports.route())) {
      const html = ExistingTokensTemplate.render({
        tokens: TokenModel.all()
      });
      $('.existing-token-container').html(html);
      Validator.init(PARENT_SELECTOR);
      SettingsPage.onDestroy('ManageTokens', () => {
        Validator.destroy(PARENT_SELECTOR);
      });
    }
  });
};
exports.createToken = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  if (Validator.isValid(context)) {
    View.changeButtonToSaving('#generate-token-button');
    TokenModel.createToken(data, (err, token) => {
      if (err) {
        View.revertButtonState('#generate-token-button', 'Generate Token');
        FlashController.error(PARENT_SELECTOR, 'Unable to generate new token.', err);
      } else {
        exports.render();
        FlashController.success(PARENT_SELECTOR, 'Token <em>' + Format.sanitize(token.description) + '</em> generated.', 'This is the only time this token value will be displayed:' + '<br /><span class="token-value">' + token.value + '</span>');
      }
    });
  }
  return false;
};
exports.deleteToken = function () {
  if (window.confirm('Are you sure you want to delete this token?')) {
    const token = Utils.getModelFromContext(this);
    $(this).html('<span class="fa fa-spin fa-star"></span>').removeAttr('data-on-click');
    TokenModel.deleteToken(token, err => {
      exports.render();
      if (err) {
        FlashController.error(PARENT_SELECTOR, 'Unable to delete token <em>' + Format.sanitize(token.description) + '</em>.', err);
      } else {
        FlashController.success(PARENT_SELECTOR, 'Token <em>' + Format.sanitize(token.description) + '</em> deleted.');
      }
    });
  }
  return false;
};
exports.enableSubmitButton = () => {
  $('#generate-token-button').removeAttr('disabled').removeClass('disabled');
};
export { exports as default };