import { Combobox } from '@clubhouse/shared/components/Combobox';
import { EmojiMenu, EmojiPicker } from 'components/shared/EmojiPicker';
import { MentionMenu, MentionPicker } from 'components/shared/MarkdownTextArea/MentionMenu';
import { SearchMenu, StoryPicker } from 'components/shared/MarkdownTextArea/SearchMenu';
import { getById as getGroupById } from 'data/entity/group';
import { getProfileById } from 'data/entity/profile';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const mapToMention = fn => id => {
  const member = getProfileById(id);
  if (member) return fn(`@${member.profile.mention_name}`);
  const group = getGroupById(id);
  if (group) fn(`@${group.mention_name}`);
};
export const Autocompletes = {
  Emoji: {
    label: 'Add emoji',
    icon: 'Emoji',
    Picker: _ref => {
      let {
        onSelect,
        children
      } = _ref;
      return _jsx(EmojiPicker, {
        onSelect: onSelect,
        children: children
      });
    },
    Menu: _ref2 => {
      let {
        onSelect,
        triggerRef,
        onClose
      } = _ref2;
      return _jsx(Combobox, {
        onSelect: onSelect,
        onClose: onClose,
        children: _jsx(EmojiMenu, {
          triggerRef: triggerRef
        })
      });
    }
  },
  Mention: {
    label: 'Add mention',
    icon: 'Mention',
    Picker: _ref3 => {
      let {
        onSelect,
        children
      } = _ref3;
      return _jsx(MentionPicker, {
        onSelect: mapToMention(onSelect),
        children: children
      });
    },
    Menu: _ref4 => {
      let {
        onSelect,
        triggerRef,
        onClose
      } = _ref4;
      return _jsx(Combobox, {
        onSelect: mapToMention(onSelect),
        onClose: onClose,
        children: _jsx(MentionMenu, {
          triggerRef: triggerRef
        })
      });
    }
  },
  Story: {
    label: 'Add story reference',
    icon: 'Story',
    Picker: _ref5 => {
      let {
        onSelect,
        children
      } = _ref5;
      return _jsx(StoryPicker, {
        onSelect: onSelect,
        children: children
      });
    },
    Menu: _ref6 => {
      let {
        onSelect,
        triggerRef,
        onClose
      } = _ref6;
      return _jsx(Combobox, {
        onSelect: onSelect,
        onClose: onClose,
        children: _jsx(SearchMenu, {
          triggerRef: triggerRef
        })
      });
    }
  }
};