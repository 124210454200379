import { useEffect, useRef } from 'react';
export const useUpdateEffect = (effect, dependencies) => {
  const isInitialRender = useRef(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This is intentional
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      return effect();
    }
  }, dependencies);
};