import "core-js/modules/es.array.push.js";
import SystemAlertsController from 'app/client/core/js/controllers/systemAlerts.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const SystemAlertsController = exports;
  [[['Controller', 'SystemAlerts'], SystemAlertsController], [['Controller', 'SystemAlerts'], SystemAlertsController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as SystemNotificationBannerTemplate from 'app/client/core/views/templates/systemNotificationBanner.html';
import { getTokens } from '@useshortcut/shapes-ds';
import Ajax from '../modules/ajax';
import ApplicationState from '../modules/applicationState';
import Cookie from '../modules/cookie';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import Log from '../modules/log';
import MessageController from './message';
import SystemCrashController from './systemCrash';
import Utils from '../modules/utils';
import View from '../modules/view';
const exports = {};

/*

Example banner config:
  "banners": [
    {
      "severity": "info",
      "message": "Welcome to the original club!",
      "url": "",
      "filters": {
        "brands": ["Clubhouse"]
      }
      "dismissible": true
    }, {
      "severity": "info",
      "message": "Shortcut",
      "url": "",
      "filters": {
        "brands": ["Shortcut"]
      }
    }
  ]

*/

const HEARTBEAT = 1000 * 60 * 2; // 2 minutes in milliseconds.
const DEPLOY_TOO_OLD = 1000 * 60 * 60 * 24 * 7; // 7 days in milliseconds.

const STORAGE_KEY = 'SystemAlert.maintenanceSoonRendered';
const HIDE_BANNER_KEY = 'clubhouse.hide-system-alert';
const START_FORMAT = 'dddd, MMMM Do [at] h:mm a';
const END_FORMAT = 'h:mm a';
const BANNER_ID = 'system-notification-banner';
const BANNER_PARENT_CLASS = 'has-notification-banner';
let mql;
exports.SEVERITY_LEVELS = ['info', 'low', 'medium', 'high'];
exports.checkForAlerts = () => {
  Log.debug('SystemAlerts: executing checkForAlerts...');
  exports.fetchAlerts(alerts => {
    if (_serverIsDown(alerts)) {
      exports._renderServerIsDownDialog();
    } else if (_serverIsNoLongerDown(alerts)) {
      exports._removeServerIsDownDialog();
    }
    if (exports.isBannerRequired(alerts)) {
      Log.debug('SystemAlerts: rendering banner.');
      exports.renderBanner(exports.getBanner(alerts));
    } else {
      exports.removeBannerIfPresent();
    }
    if (exports.currentDeployIsTooOld(alerts)) {
      Log.debug('SystemAlerts: rendering refresh alert.');
      exports.renderRefreshAlert();
    }
    if (exports.maintenanceIsStartingSoon(alerts)) {
      Log.debug('SystemAlerts: rendering maintenance mode starting soon.');
      exports.renderMaintenanceModeStartingSoon(alerts);
    } else if (exports.maintenanceIsStarted(alerts)) {
      Log.debug('SystemAlerts: rendering maintenance mode has started.');
      exports.renderMaintenanceModeStarted(alerts);
    } else if (exports.maintenanceIsOver(alerts)) {
      Log.debug('SystemAlerts: rendering maintenance mode is over.');
      exports.renderMaintenanceModeOver(alerts);
    }
  });
};
exports.fetchAlerts = callback => {
  Ajax.get('/lib/alerts.json?timestamp=' + Date.now(), {
    onSuccess: alerts => {
      callback(alerts);
    }
  });
};
function _serverIsDown(alerts) {
  return alerts.serverIsDown === true && Globals.get('serverDownDialogDisplayed') !== true;
}
function _serverIsNoLongerDown(alerts) {
  return alerts.serverIsDown !== true && Globals.get('serverDownDialogDisplayed') === true;
}
exports._renderServerIsDownDialog = () => {
  Globals.set('serverDownDialogDisplayed', true);
  SystemCrashController.render({
    logTitle: 'Displaying "Server is Down" Panel',
    title: `${BRAND.NAME} is Offline.`,
    description: `The ${BRAND.NAME} server is currently offline. Please refer to ` + `<a href="https://${BRAND.DOMAIN_STATUS_WEBSITE}" target="_blank">https://${BRAND.DOMAIN_STATUS_WEBSITE}</a> ` + 'for updates and more detail about this service incident. Thank you for your patience. ' + 'This message will disappear once service has been restored.'
  });
};
exports._removeServerIsDownDialog = () => {
  Globals.set('serverDownDialogDisplayed', false);
  SystemCrashController.close();
  Log.log('Removing "Server is Down" Panel');
  const message = '<strong>Service has been restored.</strong> Thank you for your patience!';
  MessageController.success(message, {
    timeout: 10000
  });
};

// If banners need to be filtered for any arbitrary piece of data, add that filtering here
exports.filterBanners = banners => {
  return banners.filter(banner => exports.SEVERITY_LEVELS.includes(banner?.severity) && (banner?.filters?.brands == null || banner?.filters?.brands?.includes(BRAND.NAME)));
};
exports.getBanner = alerts => {
  if (alerts.banners) {
    const filtered = exports.filterBanners(alerts.banners);

    // We can only show one banner at a time so pop the first one off and return it
    return filtered.length ? filtered[0] : null;
  }
  return null;
};
exports.isBannerRequired = alerts => !!exports.getBanner(alerts);
const getBannerElementConfig = () => {
  const bannerMountElementSelector = '#header-raven';
  return {
    bannerMountElementSelector,
    mountPosition: 'prependTo'
  };
};
const _renderBanner = (banner, persist) => {
  const {
    bannerMountElementSelector,
    mountPosition
  } = getBannerElementConfig();
  const bannerElement = $('#' + BANNER_ID);
  const bannerRendered = bannerElement.length;
  const classNames = [bannerRendered ? 'opened' : '', persist ? 'persist' : ''].join(' ');
  const systemNotificationBannerData = {
    id: BANNER_ID,
    classNames,
    mobile: Is.mobile(),
    ...banner
  };
  const html = SystemNotificationBannerTemplate.render(systemNotificationBannerData);
  if (bannerRendered) {
    View.replaceElement(bannerElement, html);
  } else {
    View.attach(html, bannerMountElementSelector, mountPosition);
    setTimeout(() => {
      $('#' + BANNER_ID).addClass('opened');
    }, 0);
  }
  $('body').addClass(BANNER_PARENT_CLASS);
};
exports.renderBanner = (banner, persist) => {
  if (Cookie.get(HIDE_BANNER_KEY)) {
    return;
  }
  if (!mql) mql = window.matchMedia(`(min-width: ${getTokens().breakpoint.desktop.minimum}px)`);
  const handleMediaChange = () => {
    if (mql.matches) {
      _renderBanner(banner, persist);
    } else {
      exports.removeBannerIfPresent(true, true);
    }
  };
  mql.onchange = handleMediaChange;

  /* So that the banner is rendered on 1st load where applicable &
   *  does not have to wait for the window to be resized to 1st render.
   */
  handleMediaChange();
};
exports.removeBannerIfPresent = function (force) {
  let noDelay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const banner = $('#' + BANNER_ID);
  if (!banner.length || banner.hasClass('persist') && !force) {
    return;
  }
  banner.addClass('closed');
  if (noDelay) {
    banner.remove();
  } else {
    setTimeout(() => {
      $('#' + BANNER_ID).remove();
    }, 800);
  }
  $('body').removeClass(BANNER_PARENT_CLASS);
};
exports.removePersistantBannerOnly = () => {
  const banner = $('#' + BANNER_ID);
  if (banner.hasClass('persist')) {
    exports.removeBannerIfPresent(true);
  }
};
exports.closeBanner = () => {
  Cookie.set(HIDE_BANNER_KEY, 'true', 1);
  exports.removeBannerIfPresent(true);
  return false;
};
exports.getCurrentDeploy = () => {
  return window._CLUBHOUSE_DEPLOY_ID;
};
exports.currentDeployIsTooOld = alerts => {
  const currentDeploy = exports.getCurrentDeploy();
  const timeSinceInitialLoad = Date.now() - Globals.get('firstPageLoadTime');

  // check if deploy is older than DEPLOY_TOO_OLD and a newer version of the app exists
  const deployTooOld = timeSinceInitialLoad > DEPLOY_TOO_OLD && Utils.versionCompare(alerts.latestDeploy, currentDeploy) === 1;

  // we want the user to be at a minimum version of alerts.lastGoodDeploy
  const promptForNewVersion = currentDeploy && currentDeploy !== 'DEV' && alerts.lastGoodDeploy && Utils.versionCompare(alerts.lastGoodDeploy, currentDeploy) === 1;
  return deployTooOld || promptForNewVersion;
};
exports.maintenanceIsStartingSoon = alerts => {
  return alerts.maintenanceStart && moment(alerts.maintenanceStart).valueOf() > Date.now();
};
exports.maintenanceIsStarted = alerts => {
  const maintenanceStarted = alerts.maintenanceStart && moment(alerts.maintenanceStart).valueOf() < Date.now();
  return maintenanceStarted && alerts.maintenanceEnd && moment(alerts.maintenanceEnd).valueOf() > Date.now();
};
exports.maintenanceIsOver = alerts => {
  return alerts.maintenanceEnd && moment(alerts.maintenanceEnd).valueOf() < Date.now();
};
exports.handleRefresh = () => window.location.reload();
exports.renderRefreshAlert = () => {
  const state = _getState();
  if (state.oldDeployRendered) {
    return false;
  } else {
    state.oldDeployRendered = true;
  }
  const message = `<strong>A new version of ${BRAND.NAME} is out ✨</strong> ` + '<p class="refresh-text">Please refresh your page to get the latest version.<p>';
  const actions = `<button class="refresh-btn" data-controller="SystemAlerts" data-on-click="handleRefresh">Refresh</button>`;
  Log.log('render refresh alert');
  MessageController.alert(message, {
    actions,
    icon: 'fa-compass',
    noCancel: true,
    id: 'system-alert-new-deploy',
    timeout: false,
    type: 'refresh-alert'
  });
};
exports.renderMaintenanceModeStartingSoon = alerts => {
  if (ApplicationState.get(STORAGE_KEY) === alerts.maintenanceStart) {
    Log.debug('SystemAlerts: Already rendered.');
    return false;
  } else {
    ApplicationState.set(STORAGE_KEY, alerts.maintenanceStart);
  }
  const start = moment(alerts.maintenanceStart).format(START_FORMAT);
  const end = moment(alerts.maintenanceEnd).format(END_FORMAT);
  const message = `<strong>${BRAND.NAME} will be in maintenance mode, starting at ${start} and ending at ${end}.</strong> You will be logged out at ${start}, so be sure to save your work before then.`;
  const actions = '<a href="#" data-on-click="removeApproachingWindowAlert" data-controller="SystemAlerts" ' + 'class="action mini elevated"><span class="fa fa-thumbs-o-up"></span> OK, got it</a>';
  Log.log('render maintenance mode is starting soon', {
    start,
    end
  });
  MessageController.alert(message, {
    actions,
    noCancel: true,
    id: 'system-alert-maintenance-mode-starting',
    timeout: false
  });
};
exports.renderMaintenanceModeStarted = alerts => {
  const state = _getState();
  if (state.maintenanceModeNowRendered) {
    return false;
  } else {
    state.maintenanceModeNowRendered = true;
  }
  exports.removeApproachingWindowAlert();
  exports.clearStoredMaintenanceValue();
  const end = moment(alerts.maintenanceEnd).format(END_FORMAT);
  const message = `<strong>${BRAND.NAME} is in maintenance mode until ` + end + '.</strong> ' + 'You may not be able to log in or make changes until maintenance mode is over.';
  Log.log('render maintenance mode started', {
    end
  });
  MessageController.error(message, {
    noCancel: true,
    id: 'system-alert-maintenance-mode-started',
    timeout: false
  });
  const duration = moment(alerts.maintenanceEnd).diff(Date.now());
  setTimeout(exports.renderMaintenanceModeOver, duration);
};
exports.renderMaintenanceModeOver = () => {
  const state = _getState();
  if (state.maintenanceModeOverRendered) {
    return false;
  } else {
    state.maintenanceModeOverRendered = true;
  }
  if (state.maintenanceModeNowRendered) {
    const message = '<strong>Maintenance mode is now over.</strong> Thanks for your patience!';
    Log.log('render maintenance mode is now over');
    MessageController.success(message, {
      timeout: 10000
    });
  }
  exports.removeApproachingWindowAlert();
  exports.removeWindowAlert();
  exports.clearStoredMaintenanceValue();
};
exports.clearStoredMaintenanceValue = () => {
  ApplicationState.remove(STORAGE_KEY);
};
exports.removeApproachingWindowAlert = () => {
  $('.message[data-id="system-alert-maintenance-mode-starting"]').remove();
  return false;
};
exports.removeWindowAlert = () => {
  $('.message[data-id="system-alert-maintenance-mode-started"]').remove();
  return false;
};
exports.init = () => {
  if (Globals.get('SystemAlertsInitialized')) {
    return false;
  }
  setInterval(exports.checkForAlerts, HEARTBEAT);
  exports.checkForAlerts();
  Globals.set('SystemAlertsInitialized', true);
};

// Only used by unit tests.
exports.clearState = () => {
  Globals.set('SystemAlertsState', null);
};
function _getState() {
  return Globals.setOnlyIfMissing('SystemAlertsState', {});
}
export { exports as default };