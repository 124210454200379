export const toUppercase = fieldName => form => {
  const unsubscribe = form.subscribe(_ref => {
    let {
      values
    } = _ref;
    if (values[fieldName]) {
      form.change(fieldName, values[fieldName].toUpperCase().trim());
    }
  }, {
    values: true
  });
  return unsubscribe;
};
export const trim = fieldNames => form => {
  const unsubscribe = form.subscribe(_ref2 => {
    let {
      values
    } = _ref2;
    fieldNames.forEach(f => {
      if (values[f]) {
        form.change(f, values[f].trim());
      }
    });
  }, {
    values: true
  });
  return unsubscribe;
};