var _this = this;
import "core-js/modules/es.array.push.js";
import noop from 'lodash/noop';
import { nanoid } from 'nanoid';
import { marked } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import Mousetrap from 'mousetrap';
import DOMPurify from 'dompurify';
import * as ServiceWorkerUtils from 'components/shared/utils/serviceWorker';
import { SignupSuccess } from 'components/signup/Success';
import Autosize from './autosize';
import BulkEditorController from '../controllers/bulkEditor';
import Clipboard from './clipboard';
import CodeBlockController from '../controllers/codeBlock';
import CompanyModel from '../models/company';
import ConsolidatedFetch from './consolidatedFetch';
import ContextMenuController from '../controllers/contextMenu';
import Dialog from './dialog';
import Dom from './dom';
import Emoji from './emoji';
import * as Event from '../_frontloader/event';
import ExtensionGuards from './extensionGuards';
import Globals from '../_frontloader/globals';
import HotReloadController from '../controllers/hotReload';
import IntegrationsController from '../../../integrations/js/controllers/integrations';
import * as Monitor from 'utils/monitoring';
import Is from './is';
import Loading from './loading';
import LocalStorage from './localStorage';
import NotificationsController from '../controllers/notifications';
import OrganizationModel from '../models/organization';
import PageLoadAction from './pageLoadAction';
import PaymentPlanController from '../controllers/paymentPlan';
import ReferralsController from '../controllers/referrals';
import Router from '../_frontloader/router';
import ShortcutsController from '../controllers/shortcuts';
import StoryController from '../controllers/story';
import StoryDialogController from '../controllers/storyDialog';
import SystemAlertsController from '../controllers/systemAlerts';
import SystemCrashController from '../controllers/systemCrash';
import TaskController from '../controllers/task';
import Tooltip from './tooltip';
import SideDrawer from './sideDrawer';
import UploaderController from '../controllers/uploader';
import Url from './url';
import User from './user';
import UserModel from '../models/user';
import Utils from './utils';
import Updates from './updates';
import * as FeaturesUtils from 'utils/features';
import { doesUserHaveQuickstart, initQuickstart } from 'utils/quickstart';
import * as PerformanceModule from './performance';
import AssetManifest from 'static/manifest/asset-manifest.json';
import Ajax from './ajax';
import { entitlementViolationMiddleware } from 'utils/useEntitlementViolation';
import { setThemePreference } from '@clubhouse/shared/utils/theme';
import { getLargestItem, getSizeInBytes, isQuotaExceeded } from 'utils/localStorage';
import { EVENTS, endTrace, logEvent, startTraceIfUnstarted } from 'utils/monitoring';
import Constants from './constants';
import { bootSequence, isReinit } from 'utils/bootStateMachine';
import { getMatchForPath, isRedirectedFrom } from 'utils/navigation';
import { hasInitialized } from 'utils/launch-darkly';
import { createLinkRenderer } from 'utils/format';
const exports = {};
const BOOT_EVENT_NS = '.BootInitLibraries';
exports.initLibrariesImmediately = () => {
  $.fastbinder({
    controllerPrefix: 'App.Controller.',
    keyupDelay: 10
  });

  // Remove generic on-scroll attribute handler, which we don't use.
  // $.fastbinder.off('mousemove');
  $.fastbinder.off('scroll');

  // Add target _blank to external links in markdown
  const renderer = new marked.Renderer();
  renderer.link = createLinkRenderer(marked.Renderer, renderer);
  renderer.checkbox = checked => {
    return `<input type="checkbox" ${checked ? 'checked' : ''} data-md="true" disabled />`;
  };
  const headingPrefixExtension = gfmHeadingId({
    prefix: 'marked-'
  });
  // Markdown rendering has been added to the API server, so
  // any changes here will need to be reflected there as well.
  // server/src/clubhouse/util/markdown.clj

  marked.use({
    breaks: true,
    gfm: true,
    pedantic: false,
    renderer
  }, headingPrefixExtension);

  // Unbind all previous page bindings.
  Mousetrap.reset();

  // Stop annoying Chrome backspace-as-back-button issue.
  Mousetrap.bind('backspace', () => {
    return false;
  });

  // Allow reversing of a jQuery collection
  $.fn.reverse = [].reverse;
  DOMPurify.removeAllHooks();
  DOMPurify.addHook('beforeSanitizeElements', node => {
    // Only allow iframes from Figma, Miro or Loom.
    if (node.nodeName && node.nodeName === 'IFRAME' && !/^https:\/\/(www\.)?(figma|miro|loom|youtube)\.com\//.test(node.src)) {
      node.remove();
    }
  });
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // We allow target as an attribute (see /modules/format>markdownify) so for security we need to
    // force all links with a target value to be _blank and add the necessary rel values
    if (node.tagName === 'A' && 'target' in node && node.target !== '') {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });
  Event.clearListeners();
  Emoji.init();
  SystemCrashController.init();
  Ajax.use(entitlementViolationMiddleware);
  _setPageClasses();
};
const ARE_SERVICES_INITIALIZED_KEY = 'boot-servicesInitialized';
const initializeServicesIfNotIntialized = () => {
  if (Globals.get(ARE_SERVICES_INITIALIZED_KEY)) {
    return;
  }
  Globals.set(ARE_SERVICES_INITIALIZED_KEY, true);
  const user = User.getCurrentUser();
  const workspace = OrganizationModel.getCurrent();
  const organization = CompanyModel.getCurrent();
  if (user?.id) {
    Monitor.setSessionAttributes({
      userId: user.id,
      username: user.username,
      email: user.emails?.[0]?.email_address,
      workspaceSlug: workspace?.url_slug,
      workspaceId: workspace?.id,
      organizationSlug: organization?.slug,
      organizationId: organization?.id
    });
  }
};
function _setPageClasses() {
  const classes = [];
  if (Is.mobile()) {
    classes.push('mobile');
  } else {
    classes.push('desktop');
  }
  $('html').addClass(classes.join(' '));
}
function _setPageClassesForUser() {
  const classes = ['user-ready'];
  const permission = UserModel.getLoggedInUserPermission();
  if (Is.readOnly(permission)) {
    classes.push('read-only');
  } else {
    classes.push('editable');
  }
  $('html').addClass(classes.join(' '));
}
exports.initLibraries = () => {
  Event.onlyOn('poppingStateWithRoute' + BOOT_EVENT_NS, () => {
    Dialog.closeIfOpen();
    SideDrawer.closeIfOpen();
  });
  Event.onlyOn('pageRendered' + BOOT_EVENT_NS, identifier => {
    if (identifier !== 'Story') Loading.hide();
    endTrace(Constants.PAGE_RENDER_TRACE_ID, {
      legacyBoot: true,
      hotReload: Utils.isInitialPageRenderComplete()
    });
    PerformanceModule.end(PerformanceModule.MEASURES.page);
    if (!Utils.isInitialPageRenderComplete()) {
      Updates.checkForUpdates({
        withPagination: false
      });
    }
    Utils.setIsInitialPageRenderComplete(true);
    if (identifier !== 'Story') Event.off('pageRendered' + BOOT_EVENT_NS);
  });
  Clipboard.init();
};

// We now have user and workspace data to work with.
exports.initCoreModules = () => {
  _setPageClassesForUser();
  _initMouseEventHandler();
  _initWindowFocusHandler();
  _initResizeHandler();
  _initPopStateHandler();
  Tooltip.init();
  if (Is.loggedInPage()) {
    // Module init - it's ok for this section to be alphabetically sorted.
    Autosize.init();
    ExtensionGuards.init();
    Loading.init();
    Router.init();
    FeaturesUtils.init();

    // Controller init - it's ok for this section to be alphabetically sorted.
    BulkEditorController.init();
    CodeBlockController.init();
    ContextMenuController.init();
    HotReloadController.init();
    NotificationsController.init();
    ReferralsController.init();
    PaymentPlanController.init();
    ShortcutsController.init();
    StoryController.init();
    StoryDialogController.init();
    SystemAlertsController.init();
    UploaderController.init();
    TaskController.init();
    IntegrationsController.init();

    // PageLoadAction.init must come last after the modules have initialized,
    // because the AddNewStory controller might open here.
    PageLoadAction.init();
  }
};
function _initMouseEventHandler() {
  const NS = '.BootMouseEventHandler';
  let timeoutID;
  const body = $('body');
  body.off('mousedown' + NS).off('mouseup' + NS).off('mousemove' + NS);
  body.on('mousedown' + NS, e => {
    Globals.set('mousedownX', e.pageX);
    Globals.set('mousedownY', e.pageY);
  }).on('mouseup' + NS, e => {
    Globals.set('mouseupX', e.pageX);
    Globals.set('mouseupY', e.pageY);
  }).on('mousemove' + NS, e => {
    Globals.set('mouseX', e.pageX);
    Globals.set('mouseY', e.pageY);
    if (Is.mobile()) {
      return false;
    }
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      Event.trigger('mouseMoved', e);
    }, 10);
  });
}
function _initWindowFocusHandler() {
  const NS = '.BootWindowFocusHandler';
  const win = $(window);
  win.off('focus' + NS).off('blur' + NS);
  win.on('focus' + NS, () => {
    Globals.set('windowHasFocus', true);
  }).on('blur' + NS, () => {
    Globals.set('windowHasFocus', false);
  });
  Globals.set('windowHasFocus', true);
}
function _initResizeHandler() {
  const eventName = 'resize.BootResizeHandler';
  $(window).off(eventName).on(eventName, _.throttle(() => {
    Event.trigger('resize');
  }, 100));
}
const onPopState = e => {
  Event.trigger('popstate', e);
};
function _initPopStateHandler() {
  window.removeEventListener('popstate', onPopState);
  window.addEventListener('popstate', onPopState);
}
function _lazyLoadHandheldAssets() {
  if (Is.mobile()) {
    Dom.loadScript(`/static/js/${AssetManifest['lib-mobile.js']}`);
  } else if (Is.tablet()) {
    Dom.loadScript(`/static/js/${AssetManifest['lib-tablet.js']}`);
  }
}
exports.reInit = async callback => {
  exports._initFirstStage();
  await exports._initSecondStage(callback);

  // User might be coming from /settings/team for example, which redirected to
  // /:slug/settings/team, which redirects to /:slug/dashboard.
  // This could also be /tour or any dialog redirect.
  Router.handleSavedRoute();
};
let conditionallyLogIfPageDoesNotLoadId;
const conditionallyLogIfPageDoesNotLoad = _ref => {
  let {
    chPage
  } = _ref;
  if (conditionallyLogIfPageDoesNotLoadId) {
    return;
  }
  conditionallyLogIfPageDoesNotLoadId = setTimeout(() => {
    if (Utils.isInitialPageRenderComplete()) {
      return;
    }
    Monitor.logEvent(Monitor.EVENTS.Boot_PageFailed, {
      initialChPage: chPage,
      currentChPage: Url.getPageFromCurrentPathname(),
      isPageLoaded: false,
      backgrounded: document.hidden
    });
  }, 30 * 1000);
};
exports.init = function () {
  let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.noop;
  PerformanceModule.end(PerformanceModule.MEASURES.waitingForInit);
  PerformanceModule.end(PerformanceModule.MEASURES.html);
  PerformanceModule.start(PerformanceModule.MEASURES.commonInit);
  PerformanceModule.start(PerformanceModule.MEASURES.appShell);
  startTraceIfUnstarted(Constants.PAGE_RENDER_TRACE_ID, Url.getPageFromCurrentPathname(), 'pageLoad');
  ConsolidatedFetch.resetFetchPageSpecificData();
  if (isReinit()) {
    return exports.reInit(callback);
  }
  const chPage = Url.getPageFromCurrentPathname();
  if (isQuotaExceeded()) {
    const {
      key,
      value
    } = getLargestItem();
    Monitor.logEvent(Monitor.EVENTS.Debug_StorageQuotaExceeded, {
      chPage,
      reason: 'localStorageQuotaExceeded',
      localStorageLargestItemKey: key,
      localStorageLargestItemLength: value.length,
      localStorageSizeInBytes: getSizeInBytes()
    });
  }
  conditionallyLogIfPageDoesNotLoad({
    chPage
  });
  exports._initFirstStage();
  _lazyLoadHandheldAssets();
  if (Is.loggedInPage()) {
    ConsolidatedFetch.fetchUserOrgAndApplicationState(exports._initSecondStage.bind(_this, callback));
  } else {
    exports._initSecondStage(callback);
  }
};
const _renderInitialLoading = () => {
  Loading.renderLoadingOverlay(SignupSuccess);
};
exports._initFirstStage = () => {
  exports.initLibrariesImmediately();
  Globals.set('initialHref', window.location.href);
  Globals.set('initialTitle', document.title);

  // This will be initialized when Url.parsePath is called.
  Globals.set('initialPath', null);

  // Used to temporarily hold up update polling by actual changes.
  Globals.set('isSaving', 0);

  // Used to identify tabs in logs
  if (!sessionStorage.getItem('tabId')) sessionStorage.setItem('tabId', nanoid());
  if (isRedirectedFrom('signup')) {
    _renderInitialLoading();
  }
};
exports.getServicesInitializationDelay = () => 0;
exports._initSecondStage = async function () {
  let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.noop;
  await hasInitialized();
  Event.once('pageRendered.boot-conditionallyInitializeServices', () => {
    setTimeout(() => {
      initializeServicesIfNotIntialized();
    }, exports.getServicesInitializationDelay());
  });
  if (!isReinit()) {
    ServiceWorkerUtils.initServiceWorker({
      user: User.getCurrentUser()
    });
  }
  LocalStorage.init();
  exports.initLibraries();
  exports.initCoreModules();
  bootSequence.containerReady();
  if (_.isFunction(callback)) {
    callback();
  }
};
exports._initThirdStage = () => {
  // This can be blank if a user goes to http://app.shortcut.com/orgname/.
  const currentPage = Url.getCurrentPage();
  if (currentPage) {
    const {
      pattern
    } = getMatchForPath({
      path: window.location.pathname
    });
    Monitor.setCurrentPage(currentPage, pattern);
  }
  const user = User.getCurrentUser() || {};
  setThemePreference(user.app_theme);
  PerformanceModule.end(PerformanceModule.MEASURES.commonInit);
  PerformanceModule.start(PerformanceModule.MEASURES.page);
  if (isRedirectedFrom('signup') || isRedirectedFrom('sampleworkspace')) {
    logEvent(EVENTS.First_Landing);
    if (doesUserHaveQuickstart()) {
      // TODO: https://app.shortcut.com/internal/story/185761/figure-out-what-the-hell-is-up-with-the-boot-sequence-and-redirecting-from-signup
      ConsolidatedFetch.fetchConsolidatedDataAndActivity();
      initQuickstart();
    }
  }
  bootSequence.complete();
};
exports.digestAppAssignments = () => {
  window.App ??= {
    Controller: {},
    Model: {}
  };
  window.AppAssignments?.forEach(fn => fn());
  window.AppAssignments = [];
};

// START ALL THE THINGS!!!
exports.initialize = () => {
  // logger is used during initialization, ensure this is available first
  exports.digestAppAssignments();
  bootSequence.begin();
  exports.init();
};
exports.resumeInitialization = () => {
  PerformanceModule.end(PerformanceModule.MEASURES.appShell);
  exports._initThirdStage();
};
exports.initializePage = function () {
  let onInit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : noop;
  let onReInit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : noop;
  if (isReinit()) {
    // This is a hot reload.

    // This Init.reInit function is defined by the page.
    onReInit();
  } else {
    // This callback function is defined by the page when it calls Boot.initialize:
    onInit();
  }
};
export { exports as default };