import Logo from '@clubhouse/assets/png/third-party-logos/honeyBadger_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function HoneyBadger() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.HONEY_BADGER
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "HoneyBadger"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "HoneyBadger Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://www.honeybadger.io/",
          children: "HoneyBadger"
        }), " provides exception and uptime monitoring for your applications."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: ["Check out ", _jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_HONEY_BADGER,
          children: "our instructions"
        }), ' ', "for how to configure this integration in your HoneyBadger account."]
      })]
    })]
  });
}
HoneyBadger.displayName = "HoneyBadger";