export const createKeyCountFn = function () {
  let {
    map = new Map()
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    map: new Map()
  };
  return id => {
    const count = map.get(id) || 0;
    map.set(id, count + 1);
    return count;
  };
};