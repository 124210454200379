import { CompressedLocalForageWrapper } from 'apollo3-cache-persist-compressed';

/**
 * Wrapper around `CompressedLocalForageWrapper` that only sets items if the tab is focused.
 *
 * @note
 * Be aware! It doesn't ensure that a single tab has the focus, but multiple tabs/windows
 * are unlikely to have the focus at the same time.
 *
 * This is probably the dumbest algorithm implementation to ensure a "write leader".
 * If it doesn't work properly, we can iterating towards a more complex algorithm.
 *
 * Next step could be to save the timestamp when the tab is focused and we write it to the IndexedDB,
 * we persiste the cache, we read the timestamp first and compare it with the tab's timestamp,
 * finally we only write if the tab's timestamp is the most recent.
 *
 * The most feature-complete implementation would be to use the BroadcastChannel API to communicate
 * between tabs combined with Web Locks API to ensure that only one tab can write at a time by
 * electing a tab leader. We just need to be careful with the implementation, because we need to
 * elect a tab leader based on the last focused time, but we should be able to take over a current
 * leader if a new tab is focused or if the tab doesn't respond anymore.
 */
export class LastFocusedTabOnlyStorage extends CompressedLocalForageWrapper {
  async setItem() {
    if (document.hasFocus()) {
      await super.setItem(...arguments);
    }
  }
}