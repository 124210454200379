import { SIZE } from './types';
export const THEME_BY_SIZE = {
  [SIZE.LARGE]: {
    fontSize: 34,
    lineHeight: 41 / 34
  },
  [SIZE.MEDIUM]: {
    fontSize: 27,
    lineHeight: 34 / 27
  },
  [SIZE.SMALL]: {
    fontSize: 24,
    lineHeight: 30 / 24
  },
  [SIZE.XSMALL]: {
    fontSize: 20,
    lineHeight: 26 / 20
  },
  [SIZE.XXSMALL]: {
    fontSize: 16,
    lineHeight: 22 / 16
  }
};