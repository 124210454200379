import { useTheme } from '@emotion/react';
import { Badge } from '@clubhouse/shared/components/Badge';
import { Circle } from '@clubhouse/shared/components/Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SIZE = {
  xsmall: 8,
  // this is not a confirmed design
  small: 10,
  medium: 11,
  // this is not a confirmed design
  large: 12
};
export const BadgeLabelContent = _ref => {
  let {
    label,
    size = 'small',
    icon
  } = _ref;
  const theme = useTheme();
  return _jsx(Badge, {
    kind: "outline",
    size: size,
    icon: icon ?? (() => _jsx("div", {
      style: {
        marginRight: 4
      },
      children: _jsx(Circle, {
        width: SIZE[size],
        fill: label.color ?? theme.labelYellow
      })
    })),
    children: label.name
  });
};
BadgeLabelContent.displayName = "BadgeLabelContent";