import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ConfirmDeleteDialog = _ref => {
  let {
    onConfirm,
    onCancel,
    isVisible
  } = _ref;
  return _jsxs(Dialog, {
    ActionComponent: () => _jsxs(_Fragment, {
      children: [_jsx(Button, {
        kind: Button.KIND.SECONDARY,
        onClick: onCancel,
        children: "Cancel"
      }), _jsx(Button, {
        kind: Button.KIND.WARNING,
        onClick: onConfirm,
        children: "Delete"
      })]
    }),
    onClose: onCancel,
    visible: isVisible,
    children: [_jsx(DialogTitle, {
      children: "Are you sure?"
    }), _jsx(DialogText, {
      children: "Do you want delete this Key Result? This action cannot be undone."
    })]
  });
};
ConfirmDeleteDialog.displayName = "ConfirmDeleteDialog";