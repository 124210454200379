import { getSelectionCount, remove as removeBulkSelectEntity, useBulkSelections } from 'data/entity/bulkSelection';
export const useRemoveBulkSelectEntitiesOnFilter = filteredStoriesIds => {
  const {
    entities: bulkSelectEntities
  } = useBulkSelections();
  if (!filteredStoriesIds) return getSelectionCount();
  for (const bulkSelectEntity of bulkSelectEntities) {
    const isNotVisibleInFilteredStories = !filteredStoriesIds.includes(bulkSelectEntity.id);

    // Unselect any bulk selected story that is not visible due to currently set table filters.
    if (isNotVisibleInFilteredStories) removeBulkSelectEntity({
      id: bulkSelectEntity.id
    });
  }
  return getSelectionCount();
};