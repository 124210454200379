import { useCallback, useEffect, useMemo } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
import { usesWrite } from 'utils/tests';
import { DocResults } from '../searchResults-v2/DocResults/DocResults';
import { EpicResults } from '../searchResults-v2/EpicResults/EpicResults';
import { IterationResults } from '../searchResults-v2/IterationResults/IterationResults';
import { ObjectiveResults } from '../searchResults-v2/ObjectiveResults/ObjectiveResults';
import { SavedSearches } from '../searchResults-v2/SavedSearches';
import { StoryResults } from '../searchResults-v2/StoryResults/StoryResults';
import { ResultsTabs } from '../searchResults/ResultsTabs';
import { SEARCH_ENTITY } from '../searchResults/types';
import { useQuickSearch } from '../searchResults/useQuickSearch';
import { useSavedSearches } from '../searchResults/useSavedSearches';
import { SearchResultsContainer } from './RecentEntities';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const EMPTY_ARRAY = [];
export const SearchResults = _ref => {
  let {
    id,
    className,
    resultTypes,
    query,
    resultsProps,
    setQuery,
    onResultClick,
    openModalOnResultClick = true,
    setIsDropdownVisible,
    renderOperatorSuggestions,
    renderOperatorsDrawer,
    hideRecentlyViewedContent,
    onActiveTabChanged,
    onResultsReceived,
    onShowRecentSearchesClick,
    onRecentSearchesApplied
  } = _ref;
  const filteredResultTypes = resultTypes.filter(type => type !== SEARCH_ENTITY.DOC || usesWrite());
  const {
    savedSearches,
    hasSavedSearches,
    onAdd: onAddRecentSearch
  } = useSavedSearches();
  const {
    stories: {
      storiesFetchState,
      searchStoriesActions
    },
    epics: {
      epicsFetchState,
      searchEpicsActions
    },
    objectives: {
      objectivesFetchState,
      searchObjectivesActions
    },
    iterations: {
      iterationsFetchState,
      searchIterationsActions
    },
    docs: {
      docsFetchState,
      fetchMoreDocs
    }
  } = useQuickSearch({
    query,
    onSearch: onAddRecentSearch,
    resultTypes: filteredResultTypes
  });
  const [showRecentSearches, toggleShowRecentSearches] = useToggleState();

  // used for Epics, Milestones & Iterations aka things controlled by our Hot Reload
  const handleResultClick = (entity, entityIndex) => {
    setIsDropdownVisible(false);
    if (onResultClick) {
      onResultClick(entity, entityIndex);
    }
  };
  const handleShowRecentSearchesClick = useCallback(() => {
    toggleShowRecentSearches.on();
    onShowRecentSearchesClick?.();
  }, [onShowRecentSearchesClick, toggleShowRecentSearches]);
  useEffect(() => {
    if ([storiesFetchState, epicsFetchState, objectivesFetchState, iterationsFetchState, docsFetchState].some(fetchState => fetchState.loading)) {
      return;
    }
    onResultsReceived(Object.entries({
      stories: storiesFetchState?.total || 0,
      epics: epicsFetchState?.total || 0,
      objectives: objectivesFetchState?.total || 0,
      iterations: iterationsFetchState?.total || 0,
      docs: docsFetchState?.data?.workspace2?.docsSearch?.pageInfo?.count || 0
    }).reduce((acc, _ref2) => {
      let [key, value] = _ref2;
      return {
        ...acc,
        [key]: value,
        total: acc.total + value
      };
    }, {
      total: 0
    }));
  }, [onResultsReceived, storiesFetchState, epicsFetchState, objectivesFetchState, iterationsFetchState, docsFetchState]);
  const TABS = {
    [SEARCH_ENTITY.STORY]: {
      name: 'Stories',
      noun: 'Story',
      total: storiesFetchState?.total || 0,
      loading: storiesFetchState?.loading,
      content: () => _jsx(StoryResults, {
        fetchProps: storiesFetchState,
        actions: searchStoriesActions,
        onClick: onResultClick,
        openModalOnClick: openModalOnResultClick,
        showCards: true,
        excludeStories: EMPTY_ARRAY
      })
    },
    [SEARCH_ENTITY.EPIC]: {
      name: 'Epics',
      noun: 'Epic',
      total: epicsFetchState?.total || 0,
      loading: epicsFetchState?.loading,
      content: () => _jsx(EpicResults, {
        fetchProps: epicsFetchState,
        actions: searchEpicsActions,
        onClick: handleResultClick,
        openModalOnClick: openModalOnResultClick
      })
    },
    [SEARCH_ENTITY.OBJECTIVE]: {
      name: 'Objectives',
      noun: 'Objective',
      total: objectivesFetchState?.total || 0,
      loading: objectivesFetchState?.loading,
      content: () => _jsx(ObjectiveResults, {
        fetchProps: objectivesFetchState,
        actions: searchObjectivesActions,
        onClick: handleResultClick,
        openModalOnClick: openModalOnResultClick
      })
    },
    [SEARCH_ENTITY.ITERATION]: {
      name: 'Iterations',
      noun: 'Iteration',
      total: iterationsFetchState?.total || 0,
      loading: iterationsFetchState?.loading,
      content: () => _jsx(IterationResults, {
        fetchProps: iterationsFetchState,
        actions: searchIterationsActions,
        onClick: handleResultClick,
        openModalOnClick: openModalOnResultClick
      })
    },
    [SEARCH_ENTITY.DOC]: {
      name: 'Docs',
      noun: 'Doc',
      total: docsFetchState?.data?.workspace2?.docsSearch?.pageInfo?.count || 0,
      loading: docsFetchState?.loading,
      content: () => _jsx(DocResults, {
        fetchProps: docsFetchState,
        actions: {
          fetchMoreDocs
        },
        onClick: handleResultClick,
        openModalOnClick: openModalOnResultClick,
        showCards: true
      })
    }
  };
  const renderTabListOptions = useMemo(() => ({
    onShowRecentSearchesClick: handleShowRecentSearchesClick,
    showResultsCount: true,
    showRecentSearches: hasSavedSearches,
    hideRecentlyViewedContent
  }), [handleShowRecentSearchesClick, hasSavedSearches, hideRecentlyViewedContent]);
  const filteredTabs = filteredResultTypes.map(type => ({
    ...TABS[type],
    key: type
  }));
  return _jsx("div", {
    className: className,
    id: id,
    children: _jsx(ResultsTabs, {
      tabs: filteredTabs,
      showResultsHeader: true,
      onSelect: tab => {
        onActiveTabChanged?.(tab.key);
      },
      initialTab: 0,
      focusPanelOnSelect: false,
      children: _ref3 => {
        let {
          renderTabList,
          renderTabPanel
        } = _ref3;
        return _jsxs(_Fragment, {
          children: [!showRecentSearches ? _jsxs(_Fragment, {
            children: [renderOperatorSuggestions(), renderTabList(renderTabListOptions), _jsx(SearchResultsContainer, {
              ...resultsProps,
              children: renderTabPanel()
            })]
          }) : _jsx("div", {
            ...resultsProps,
            children: _jsx(SavedSearches, {
              savedSearches: savedSearches,
              setQuery: query => {
                setQuery(query);
                onRecentSearchesApplied(query, savedSearches);
              },
              onClose: toggleShowRecentSearches.off
            })
          }), renderOperatorsDrawer()]
        });
      }
    })
  });
};
SearchResults.displayName = "SearchResults";