import EmailModel from 'app/client/core/js/models/email';
import OrganizationModel from 'app/client/core/js/models/organization';
import UserModel from 'app/client/core/js/models/user';
import Is from 'app/client/core/js/modules/is';
import UserModule from 'app/client/core/js/modules/user';
import { useEntity } from './collection';
const SAMPLE_WORKSPACE_ID = '5ce70855-1432-4018-8769-4e14b21dc5bb';
export const fetchUserWithoutRedirect = () => new Promise(resolve => {
  UserModel.fetchCurrentUserWithoutRedirect(() => {
    resolve(UserModule.getCurrentUser());
  });
});

// NOTE: This actually checks if the user is an admin OR an owner
export const isLoggedInUserAdmin = () => {
  return Is.admin(UserModel.getLoggedInUserPermission());
};
export const isLoggedInUserAdminOnly = () => {
  return Is.adminOnly(UserModel.getLoggedInUserPermission());
};
export const isLoggedInUserObserver = () => {
  return Is.readOnly(getLoggedInUserPermission());
};
export const isLoggedInUserOwner = () => {
  return Is.ownerOnly(UserModel.getLoggedInUserPermission());
};
export const isSampleObserver = () => {
  const org = OrganizationModel.getCurrent() || {};
  return UserModel.getRoleOfCurrentUser() === 'observer' && org.id === SAMPLE_WORKSPACE_ID;
};
export const getLoggedInUserPermissionID = () => UserModel.getLoggedInUserPermissionID();
export const getLoggedInUserPermission = id => UserModel.getLoggedInUserPermission(id);
export const getAllLoggedInProfileDetails = () => UserModel.getAllLoggedInProfileDetails();
export const getLoggedInUserProfiles = () => UserModel.getLoggedInUserProfiles();
export const getCurrentUser = () => UserModule.getCurrentUser();
export const currentUserHasNotionToken = () => UserModule.getCurrentUser()?.has_notion_token ?? false;
export const isValidUser = user => UserModel.isValid(user);
export const isReadOnly = () => Is.readOnly(UserModel.getLoggedInUserPermission());
export const isLoggedInUserOrganizationOwner = org => {
  return Is.ownerOnly(getLoggedInUserPermission(org.id));
};
export const getDefaultPermissionFromProfile = profile => UserModel.getDefaultPermissionFromProfile(profile);
export const useCurrentUser = () => {
  const {
    entity: user
  } = useEntity({
    model: UserModel,
    id: UserModule.getCurrentUser()?.id ?? ''
  });
  return user;
};
export const fetchAll = callback => UserModel.fetchAll(callback);
export const isJoyEnabled = () => {
  return UserModule.getCurrentUser()?.joy_enabled ?? false;
};
export const isGDPR = async () => await UserModel.Promises.isGDPR();
export const fetchActiveStoriesForCurrentUser = () => new Promise((resolve, reject) => UserModel.fetchActiveStoriesForCurrentUser(err => {
  if (err) {
    reject(err);
  } else resolve();
}));
export const fetchRecentlyCompletedStoriesForCurrentUser = () => new Promise((resolve, reject) => UserModel.fetchRecentlyCompletedStoriesForCurrentUser(err => {
  if (err) {
    reject(err);
  } else resolve();
}));
export const getDefaultEmailAddress = user => UserModel.getDefaultEmail(user)?.email_address ?? null;
export const getEmailAddressById = id => EmailModel.getById(id)?.email_address ?? null;
export const deleteCurrentUser = async () => await UserModel.Promises.deleteUser();
export const getCurrentUserPrefix = () => UserModule.getCurrentUserPrefix();