import "core-js/modules/es.array.push.js";
import { partialRouteChanged } from 'components/layout/partialRouteEffects';
import { getMatchForPath } from 'utils/navigation/paths';
import { setPageTitle } from 'utils/page';
import { startNavigationTransaction } from 'utils/profiler/sentry';
import { replaceState } from './detangledRouter';
import * as Event from './event';
import Globals from './globals';
import Url from '../modules/url';
const exports = {};
const ROUTE_KEY = 'SavedRouteToOpen';

// For the Proxy polyfill to work, keys can't be dynamically added to the Proxy object after
// it's created. We need to initialize them during creation so they can be assigned later.
const PROXY_INITIALIZER = {
  company: null,
  dashboard: null,
  epic: null,
  epics: null,
  write: null,
  training: null,
  label: null,
  milestone: null,
  milestones: null,
  project: null,
  search: null,
  status: null,
  roadmap: null,
  teams: null
};
const PARTIAL_ROUTES = {
  feedback: true,
  help: true,
  news: true,
  shortcuts: true,
  story: true,
  training: true
};
const PARTIAL_PATHS = [/^(\/[a-zA-Z0-9-]*)?\/settings\/integrations.*/,
// Even though the route may suggested this is inside settings, it's a modal on top of the current page
/^\/feedback$/,
// When accessing Help outside of a workspace (/organizations)
/^\/help$/,
// When accessing Help outside of a workspace (/organizations)
/^\/shortcuts$/ // When accessing Shortcuts out of a workspace (/organizations)
];

// This is only set to true when user is clicking on comment hyperlinks, which updates the hash.
// We want to update the hash/state in that case without causing any routing.
let routerIsDisabled = false;
exports.disableRouter = () => {
  routerIsDisabled = true;
};
exports.enableRouter = () => {
  routerIsDisabled = false;
};
exports.PAGE_TITLES = new Proxy(PROXY_INITIALIZER, {
  get: (titles, pageName) => {
    return pageName in titles ? titles[pageName] : `${_.upperFirst(pageName)} | ${BRAND.NAME}`;
  }
});
exports.addPageTitle = (page, title) => {
  exports.PAGE_TITLES[page] = title;
};
exports.clearAllRoutes = () => {
  Globals.set('Router.Routes', []);
};

/* TODO: Remove temporary `options` fix */
exports.add = function (matching, title, callback) {
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const routes = Globals.setOnlyIfMissing('Router.Routes', []);
  routes.push({
    matching,
    title,
    callback,
    options
  });
};
exports.addRoutesFromControllers = list => {
  list.forEach(addRouteFromController);
};
const addRouteFromController = controller => {
  if (!controller) {
    return null;
  }
  const {
    route,
    title,
    render,
    open,
    ...options
  } = controller;
  exports.add(route, title, render || open, options);
};
exports.pushStateWithSlug = (url, title) => {
  exports.pushState(`${Url.getSlugPath()}${url}`, title);
};
exports.isPartial = function () {
  let page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Url.getPageFromCurrentPathname();
  const path = Url.getCurrentPathname();
  return PARTIAL_ROUTES[page] || PARTIAL_PATHS.some(partialPathRegEx => partialPathRegEx.test(path));
};
exports.partialRouteChanged = () => {
  partialRouteChanged();
};
exports.setInitialPage = pageOverride => {
  const page = pageOverride ?? Url.getPageFromCurrentPathname();
  if (exports.isPartial(page)) {
    // Emit an event to do effects based on the current page and path.
    // One use case is to trigger `StoryDialog.open()` when we want to set the initialPage to
    // `story`, but we can't because this is a partial route. We have to use a `setTimeout` here
    // to schedule the event at the end of the current queue so the dialog is actually visible.
    setTimeout(() => {
      exports.partialRouteChanged();
      Globals.set('partialPage', page);
    });
  } else {
    // We need to reset the "page" value on pop/push state.
    // The only exception is the story URL, which is a modal - not a real route.
    Globals.set('initialPage', page);
    Globals.set('partialPage', null);
  }
};
exports.pushState = (url, title, state) => {
  /*
   * There's a transition happening for partial routes where it navigates back
   * to its background state before doing an actual navigation.
   * We don't want to start a navigation transaction for partial routes, as they
   * are mostly misleading and not useful. We only want to skip the navigation back
   * to the background state, but afterward we want and need to capture the actual
   * navigation from the background state to the new state. In other words,
   *
   * We do want to capture navigation transactions for partial routes, but usually,
   * navigating away from a partial route to another route goes like this:
   *
   * 1. Page A
   * 2. Navigating to Partial Route B
   * 3. Clicking on a link to Page C
   * 4. Closing Partial Route B
   * 5. "Navigating" back to route A (mostly just changing the URL back to the background state)
   * 6. Finally, navigating to Page C
   *
   * We want to exclude the navigation back to the background state
   * and only capture Page A -> Page C navigation, which is already done automatically.
   */
  if (!exports.isPartial(Url.getPageFromCurrentPathname())) {
    startNavigationTransaction({
      pattern: getMatchForPath({
        path: url
      })?.pattern,
      tags: {
        'L.fromHref': window.location.href,
        'L.fromPage': getMatchForPath({
          path: window.location.pathname
        })?.pageName ?? '__PAGE_NAME_NOT_FOUND__'
      }
    });
  }
  if (!Globals.get('isRouting')) {
    if (url === window.location.pathname) {
      exports.replaceState(url, title, state);
    } else {
      // This is pretty odd. We need to pass in the current_title, but the new URL.
      // Otherwise the back/forward buttons work but the title in the browser history
      // displays the title of the page directly after that one. :-?
      window.history.pushState({
        url,
        ...state
      }, document.title, url);
    }
  }
  exports.setInitialPage();
  setPageTitle(title || _getTitleFallback(url));
};
exports.navigateTo = _ref => {
  let {
    url
  } = _ref;
  exports.pushState(url);
  return exports.handleCurrentRoute();
};
function _getTitleFallback(url) {
  const routeName = Url.getPath(url) || '';
  const route = _getRoute(routeName);
  return route ? _getRouteTitle(routeName, route.title) : Globals.get('initialTitle');
}
exports.replaceState = replaceState;
exports.onPopState = e => {
  if (routerIsDisabled) {
    return false;
  }
  const routeName = (() => {
    if (e?.state?.url == null) {
      // Initial page load is has no e.state, so let's set it.
      return Url.getCurrentPathnameWithHash();
    }
    return Url.getPath(e.state && e.state.url);
  })();
  if (routeName) {
    Globals.set('isRouting', true);
    _popState(routeName);
    Globals.set('isRouting', false);
  }
};
exports.init = () => {
  const NS = '.Router';
  Event.onlyOn('popstate' + NS, exports.onPopState);
};
exports.saveRoute = routeName => {
  Globals.set(ROUTE_KEY, Url.sanitizeRoute(routeName));
};
exports.handleSavedRoute = () => {
  const route = Globals.get(ROUTE_KEY);
  Globals.set(ROUTE_KEY, null);
  if (route) {
    _popState(route);
  }
};
exports.hasRouteForCurrentUrl = () => {
  const currentRoute = Url.getCurrentPathnameWithHash();
  const routeName = Url.sanitizeRoute(currentRoute);
  return Boolean(_getRoute(routeName));
};
exports.handleCurrentRoute = () => {
  const currentRoute = Url.getCurrentPathnameWithHash();
  _popState(Url.sanitizeRoute(currentRoute));
};
function _getRoute(routeName) {
  let routeDetails;
  let isMatch = false;
  const routes = Globals.setOnlyIfMissing('Router.Routes', []);
  routes.forEach(route => {
    if (_.isFunction(route.matching)) {
      isMatch = route.matching(routeName);

      // Some route matching fns return a boolean, but most return a URL string
      // that we need to check here.
      if (_.isString(isMatch)) {
        isMatch = routeName === isMatch;
      }
    } else {
      isMatch = route.matching === routeName;
    }
    if (isMatch) {
      routeDetails = {
        fn: route.callback,
        title: _getRouteTitle(routeName, route.title),
        /* TODO: Remove temporary `options` fix */
        options: route.options || {}
      };
      return false;
    }
  });
  return routeDetails;
}
function _getRouteTitle(routeName, title) {
  return _.isFunction(title) ? title(routeName) : title;
}
function _hasMobileModal() {
  const modalRoot = document.querySelector('#modal-root');
  if (!Array.isArray(modalRoot.children) || modalRoot.children.length === 0) return false;
  return modalRoot.children.some(ele => !!ele.firstChild);
}
function _popState(routeName) {
  const route = _getRoute(routeName);
  const hasModalDialog = document.querySelector('.modal-dialog');
  const hasNoActiveModal = !(hasModalDialog || _hasMobileModal());

  // We might not have a route if the page was hot-reloaded.
  if (hasNoActiveModal && (!route || /* TODO: Remove temporary `options` fix */
  // Backdoor override! Some routes should *always* be completely re-loaded.
  route.options.reloadOnPopState)) {
    // leads to loadURL
    Event.trigger('poppingStateWithRouteName', routeName);
    return;
  }

  // closes dialogs
  Event.trigger('poppingStateWithRoute');
  exports.setInitialPage();
  if (!route) {
    setPageTitle(_getTitleFallback(Url.getCurrentPathname()));
    return;
  }
  if (route.title) {
    setPageTitle(route.title);
  }
  if (route.options && route.options.initData) {
    route.options.initData().then(() => {
      route.fn && route.fn(routeName);
    });
  } else {
    route.fn && route.fn(routeName);
  }
}
export { exports as default };