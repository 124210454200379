import { collectionBasicFragment } from '../fragments/collectionBasicFragment';
import { docBasicFragment } from '../fragments/docBasicFragment';
export const GET_DOCS_SEARCH = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetDocsSearch"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "q"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "after"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docsSearch"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "q"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "q"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "after"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "after"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "first"
          },
          "value": {
            "kind": "IntValue",
            "value": "10"
          }
        }],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "arguments": [],
                "directives": [],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DocBasic"
                    },
                    "directives": []
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "collections"
                    },
                    "arguments": [],
                    "directives": [],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "CollectionBasic"
                        },
                        "directives": []
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "hasNextPage"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "endCursor"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "count"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }]
        }
      }]
    }
  }].concat(docBasicFragment.definitions, collectionBasicFragment.definitions),
  "loc": {
    "start": 0,
    "end": 332,
    "source": {
      "body": "\n  query GetDocsSearch($q: String, $after: String) {\n    docsSearch(q: $q, after: $after, first: 10) {\n      edges {\n        node {\n          ...DocBasic\n          collections {\n            ...CollectionBasic\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n        count\n      }\n    }\n  }\n  \n  \n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};