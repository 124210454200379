import Logo from '@clubhouse/assets/png/third-party-logos/dailybot_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function DailyBot() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.DAILYBOT
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "DailyBot"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "DailyBot Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Integrate Shortcut with ", _jsx(ExternalLink, {
          href: "https://www.dailybot.com/",
          children: "DailyBot"
        }), " and automate workflows for your chat today."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.dailybot.com/integrations/shortcut",
          children: "Learn more"
        }), " on how the DailyBot integration works in Shortcut."]
      })]
    })]
  });
}
DailyBot.displayName = "DailyBot";