import "core-js/modules/es.array.push.js";
import * as Event from '../_frontloader/event';
import Is from '../modules/is';
import Constants from '../modules/constants';
import Format from '../modules/format';
import Url from '../modules/url';
import Utils from '../modules/utils';
import LabelModel from '../models/label';
import UserModel from '../models/user';
import AddNewStoryController from './addNewStory';
import ArchiveWarningController from './archiveWarning';
import ColorPickerController from './colorPicker';
import DropdownController from './dropdown';
import InPlaceEditorController from './inPlaceEditor';
import MessageController from './message';
import StoryController from './story';
import { PAGE_NAMES, matchPathForPage } from 'utils/navigation';
const exports = {};
exports.cavemanName = 'Label';
exports.contextMenuItemsForStory = function () {
  const label = Utils.getModelFromContext(this);
  const actions = [];
  if (Is.storiesPage()) {
    actions.push({
      iconLeft: 'fa-filter',
      tooltip: 'Filter page by this Label',
      value: () => {
        const labelElement = LabelModel.getElementInStoryDialog(label);
        if (labelElement.length) {
          Event.trigger('labelFiltered', labelElement[0]);
        }
        return false;
      }
    });
  }
  actions.push({
    iconLeft: 'fa-link',
    tooltip: 'Go to Label page',
    url: LabelModel.getUrl(label)
  });
  if (!Is.observer(UserModel.getLoggedInUserPermission())) actions.push({
    iconLeft: 'fa-trash',
    tooltip: 'Remove this Label',
    value: () => {
      const labelElement = LabelModel.getElementInStoryDialog(label);
      if (labelElement.length > 0) {
        return StoryController.removeLabel.call(labelElement[0]);
      }
      return false;
    }
  });
  return actions;
};
exports.contextMenuItemsForEpic = function () {
  const label = Utils.getModelFromContext(this);
  const actions = [];
  if (Is.storiesPage()) {
    actions.push({
      iconLeft: 'fa-filter',
      tooltip: 'Filter page by this Label',
      value: () => {
        const labelElement = LabelModel.getElementInStoryDialog(label);
        if (labelElement.length) {
          Event.trigger('labelFiltered', labelElement[0]);
        }
        return false;
      }
    });
  }
  actions.push({
    iconLeft: 'fa-link',
    tooltip: 'Go to Label page',
    url: LabelModel.getUrl(label)
  });
  return actions;
};
exports.saveName = function () {
  const label = Utils.getModelFromContext(this);
  const editorElement = $(this).closest('.in-place-editor');
  const newName = editorElement.find('textarea').val().trim();
  if (!newName) {
    MessageController.error('A name is required.');
    return false;
  }
  if (label.name !== newName) {
    LabelModel.updateLabel(label, {
      name: newName
    }, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'The original name has been restored.'
        });
      }
    });
  }
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.openColorPicker = function () {
  const label = Utils.getModelFromContext(this);
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  ColorPickerController.open({
    activeValue: _.includes(Constants.LABEL_COLORS, label.color) ? label.color : LabelModel.getDefaultColor(),
    context: this,
    targetSelector: '#label-' + label.id + '-color-dropdown',
    palette: Constants.LABEL_COLORS,
    onApply: color => {
      LabelModel.updateLabel(label, {
        color
      }, err => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to update color.'
          });
        }
      });
    }
  });
};
exports.toggleArchive = function () {
  const label = Utils.getModelFromContext(this);
  const isArchived = LabelModel.isArchived(label);
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  function responseHandler(err, label) {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to ' + (isArchived ? 'unarchive' : 'archive') + ' <strong>' + Format.sanitizeAndEmojify(label.name) + '</strong>.'
      });
    } else {
      Utils.setArchivedClass({
        archived: !isArchived
      });
    }
  }
  if (isArchived) {
    LabelModel.unArchiveLabel(label, responseHandler);
  } else {
    ArchiveWarningController.label.call(this, label, {
      onApprove: () => {
        LabelModel.archiveLabel(label, responseHandler);
      }
    });
  }
};
exports.deleteLabel = function () {
  const label = Utils.getModelFromContext(this);
  if (window.confirm('Are you sure you want to delete this label?')) {
    LabelModel.deleteLabel(label, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to delete <strong>' + Format.sanitizeAndEmojify(label.name) + '</strong>.'
        });
      } else {
        MessageController.success('Label <strong>' + Format.sanitizeAndEmojify(label.name) + '</strong> deleted.');
        setTimeout(() => {
          Utils.redirect(Url.getSlugPath() + '/settings/labels');
        }, 2000);
      }
    });
  }
  return false;
};
exports.openActionsDropdown = function () {
  const label = Utils.getModelFromContext(this);
  DropdownController.open({
    title: 'Label Actions',
    items: [{
      name: (LabelModel.isArchived(label) ? 'Unarchive' : 'Archive') + ' Label...',
      value: exports.toggleArchive,
      iconLeft: 'fa-archive'
    }, {
      name: 'Delete Label...',
      value: exports.deleteLabel,
      iconLeft: 'fa-trash-o'
    }],
    target: this,
    targetSelector: '#label-page-actions-dropdown',
    width: 200
  });
  return false;
};
exports.createStoryWithLabel = () => {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const match = matchPathForPage(window.location.pathname, {
    pageName: PAGE_NAMES.SETTINGS_LABEL
  }) ?? matchPathForPage(window.location.pathname, {
    pageName: PAGE_NAMES.LABEL
  });
  const label = LabelModel.get({
    id: Number(match?.params?.id)
  });
  AddNewStoryController.updateState({
    labels: [{
      name: label.name
    }]
  });
  AddNewStoryController.render({
    context: 'label'
  });
  return false;
};
export { exports as default };