import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/zendesk_logo.png';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { ApplicationIds, fetchByApplicationId, install, reinstallZendesk, uninstallZendesk, useInstallationByApplicationId } from 'data/entity/installation';
import { fetchAll, getCredentialByInstallationId } from 'data/entity/oauth';
import { isLoggedInUserAdmin, isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { userListRoute } from 'utils/manageUsersController';
import { openManageUsers } from 'utils/settings';
import { ExternalLink } from './components/ExternalLink';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function Zendesk() {
  const readOnly = isReadOnly();
  const admin = isLoggedInUserAdmin();
  useEffect(() => {
    fetchByApplicationId(ApplicationIds.Zendesk);
    fetchAll();
  }, []);
  const installation = useInstallationByApplicationId(ApplicationIds.Zendesk);
  const isLoading = !installation;
  const isEnabled = installation?.uninstalled === false;
  const credential = getCredentialByInstallationId(installation?.id);
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.ZENDESK
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Zendesk"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Zendesk Logo",
        invertOnDarkMode: true
      }), _jsx(Layout.Blurb, {
        children: "With our Zendesk integration, you can create links between stories and support tickets, and also manage them from within Zendesk."
      }), (readOnly || !admin) && _jsxs(Layout.HelpMessage, {
        children: [readOnly ? 'ZendeskObservers cannot make changes to integrations.' : !admin ? 'Only admins can manage this integration.' : null, _jsx("br", {}), "Please", ' ', _jsx("a", {
          href: userListRoute(),
          onClick: e => {
            e.preventDefault();
            openManageUsers();
          },
          children: "ask an owner or an admin"
        }), ' ', "if you want to edit this page."]
      }), _jsx(Layout.SectionTitle, {
        children: "Integration Status"
      }), isLoading ? _jsx(IntegrationToggle.Loading, {}) : _jsx(IntegrationToggle, {
        isReadOnly: !admin,
        isEnabled: isEnabled,
        onChange: async newValue => {
          try {
            if (installation) {
              if (newValue) await reinstallZendesk(installation.id);else await uninstallZendesk(installation.id);
            } else {
              await install(ApplicationIds.Zendesk);
            }
          } catch (err) {
            addToast({
              kind: 'alert',
              timeout: 5000,
              Content: () => _jsxs(ToastText, {
                children: ["Unable to ", newValue ? 'enable' : 'disable', " integration."]
              })
            });
          }
        }
      }), isEnabled && _jsxs(_Fragment, {
        children: [credential ? _jsxs(Layout.Blurb, {
          children: [_jsx("strong", {
            children: "Your"
          }), " authorization expires on ", credential.formatted_expiry, "."]
        }) : _jsxs(Layout.Blurb, {
          children: ["To use this integration, once our app has been installed from the", ' ', _jsx(ExternalLink, {
            href: "https://www.zendesk.com/marketplace/apps/support/146871/shortcut",
            children: "Zendesk Marketplace"
          }), ' ', "you can authenticate yourself from within Zendesk."]
        }), _jsxs(Layout.HelpMessage, {
          children: ["Check out ", _jsx(ExternalLink, {
            href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_ZENDESK,
            children: "our instructions"
          }), ' ', "for how to configure this integration in your Zendesk account."]
        })]
      })]
    })]
  });
}
Zendesk.displayName = "Zendesk";