import { useSearchParams } from 'utils/navigation';
export const useClearableFilters = filters => {
  const {
    params,
    clearSearchParams
  } = useSearchParams(filters);
  let hasClearableFilters = false;
  for (const key in params) {
    if (params[key]?.length) {
      hasClearableFilters = true;
      break;
    }
  }
  return {
    hasClearableFilters,
    clearFilters: clearSearchParams
  };
};