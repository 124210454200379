import { FIELD_ID } from 'components/shared/table/types';
import { TableDate } from 'components/shared/table/base';
import { created } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function CreatedDateFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  return _jsx(TableDate, {
    value: entity.created_at
  });
}
CreatedDateFieldComponent.displayName = "CreatedDateFieldComponent";
export const CreatedDateField = () => ({
  name: FIELD_ID.CREATED_DATE,
  displayName: 'Created On',
  sort: created,
  Component: CreatedDateFieldComponent,
  width: 110
});