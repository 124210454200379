import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMapState } from '@clubhouse/shared/hooks/useMapState';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
import { useLazyStoriesInGroupQuery } from '../shared/useStoriesInGroupQuery';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Context = createContext(null);
const getEmptyResult = () => ({
  stories: [],
  headerAggregations: [],
  headers: [],
  totalSize: null
});
export function GroupStoriesProvider(_ref) {
  let {
    children,
    spaceId
  } = _ref;
  const fetchStories = useLazyStoriesInGroupQuery();
  const groupMap = useMapState();
  const currentDataMap = useMapState();
  const abortControllerRef = useRef(null);
  const slug = useWorkspaceSlug();
  const fetchStoriesInGroup = useCallback(async _ref2 => {
    let {
      groupId,
      notifyOnData
    } = _ref2;
    const group = groupMap.get(groupId);
    if (!group) return null;
    try {
      const newData = await fetchStories({
        variables: {
          slug,
          spaceId,
          input: {
            groupId,
            orderBy: group.orderBy ? [group.orderBy] : undefined,
            limit: group.limit
          }
        }
      });
      if (notifyOnData) {
        currentDataMap.set(groupId, newData);
        group.onDataChange(newData);
      }
      return newData;
    } catch (err) {
      console.debug(`Failed to fetch stories in group "${groupId}"`, err);
      if (err instanceof Error) logError(err);
      if (notifyOnData) {
        currentDataMap.set(groupId, getEmptyResult());
        group.onDataChange(getEmptyResult());
      }
      return null;
    }
  }, [fetchStories, slug, spaceId, groupMap, currentDataMap]);

  // This returns a tuple of the group's latest data and the group metadata
  const getGroup = useCallback(groupId => [currentDataMap.get(groupId) || {
    stories: [],
    headerAggregations: [],
    headers: [],
    totalSize: null
  }, groupMap.get(groupId) || null], [currentDataMap, groupMap]);
  const registerGroup = useCallback(_ref3 => {
    let {
      groupId,
      limit,
      orderBy,
      onDataChange
    } = _ref3;
    groupMap.set(groupId, {
      groupId,
      orderBy,
      limit,
      onDataChange
    });

    // If registerGroup is called multiple times for same group, we only use the latest fetch.
    const abortController = new AbortController();
    fetchStoriesInGroup({
      groupId,
      notifyOnData: false
    }).then(data => {
      if (abortController.signal.aborted) return;
      const newData = data || getEmptyResult();
      currentDataMap.set(groupId, newData);
      onDataChange(newData);
    });
    return () => {
      abortController.abort();
      groupMap.remove(groupId);
      currentDataMap.remove(groupId);
    };
  }, [fetchStoriesInGroup, currentDataMap, groupMap]);
  const updateOptimistically = useCallback(updaterFn => {
    abortControllerRef.current?.abort();
    const snapshot = currentDataMap.clone();
    const newData = updaterFn(currentDataMap.clone());
    groupMap.forEach(_ref4 => {
      let {
        groupId,
        onDataChange
      } = _ref4;
      const newGroupData = newData.get(groupId) || getEmptyResult();
      currentDataMap.set(groupId, newGroupData);
      onDataChange(newGroupData);
    });
    return {
      undo: () => {
        groupMap.forEach(_ref5 => {
          let {
            groupId,
            onDataChange
          } = _ref5;
          const oldGroupData = snapshot.get(groupId) || getEmptyResult();
          currentDataMap.set(groupId, oldGroupData);
          onDataChange(oldGroupData);
        });
      }
    };
  }, [currentDataMap, groupMap]);
  return _jsx(Context.Provider, {
    value: useMemo(() => ({
      registerGroup,
      fetchStoriesInGroup,
      updateOptimistically,
      getGroup,
      abortControllerRef
    }), [registerGroup, updateOptimistically, fetchStoriesInGroup, getGroup]),
    children: children
  });
}
GroupStoriesProvider.displayName = "GroupStoriesProvider";
export function useGroupStories(_ref6) {
  let {
    groupId,
    limit,
    orderBy,
    skip
  } = _ref6;
  const context = useContext(Context);
  if (!context) throw new Error('useGroupStories must be used within a GroupStoriesProvider');
  const [data, setData] = useState(getEmptyResult());
  const {
    registerGroup,
    fetchStoriesInGroup
  } = context;
  useEffect(() => {
    if (skip) return;
    return registerGroup({
      groupId,
      limit,
      orderBy,
      onDataChange: setData
    });
  }, [skip, groupId, limit, registerGroup, orderBy]);
  useDataLayerUpdateEvent(() => fetchStoriesInGroup({
    groupId,
    notifyOnData: true
  }), {
    filter: ['Space', 'WIPLimit'],
    disable: skip
  });
  const fetchStories = useCallback(() => fetchStoriesInGroup({
    groupId,
    notifyOnData: true
  }), [groupId, fetchStoriesInGroup]);
  return {
    data,
    fetchStories
  };
}
export const useAbortControllerRef = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useAbortControllerRef must be used within a GroupStoriesProvider');
  return context.abortControllerRef;
});
export const useGetGroup = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useGetGroup must be used within a GroupStoriesProvider');
  return context.getGroup;
});
export const useOptimisticUpdateMaybe = () => useContextSelector(Context, context => {
  return context?.updateOptimistically || null;
});
export const useOptimisticUpdate = () => useContextSelector(Context, context => {
  if (!context) throw new Error('useOptimisticUpdate must be used within a GroupStoriesProvider');
  return context.updateOptimistically;
});