import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SpaceChangesSavedToast = _ref => {
  let {
    name,
    onClose,
    undoChanges
  } = _ref;
  return _jsxs(Spaced, {
    vertically: true,
    amount: 8,
    children: [_jsxs(ToastText, {
      children: ["Saved updates to ", _jsx("strong", {
        children: name
      })]
    }), _jsx(Spaced, {
      horizontally: true,
      amount: 5,
      children: _jsx(Button, {
        kind: Button.KIND.SECONDARY,
        size: Button.SIZE.SMALL,
        onClick: () => undoChanges().then(() => {
          onClose();
        }),
        children: "Undo"
      })
    })]
  });
};
SpaceChangesSavedToast.displayName = "SpaceChangesSavedToast";
export const useSpaceSaveToasts = () => {
  const {
    addToast,
    removeToast
  } = useToast();
  const showSpaceSaveSuccessToast = useCallback(args => {
    return addToast({
      timeout: 16000,
      kind: 'success',
      Content: _ref2 => {
        let {
          onClose
        } = _ref2;
        return _jsx(SpaceChangesSavedToast, {
          ...args,
          onClose: onClose
        });
      }
    });
  }, [addToast]);
  const showSpaceSaveErrorToast = useCallback(message => {
    return addToast({
      timeout: 3000,
      kind: 'warning',
      Content: () => _jsx(ToastText, {
        children: message
      })
    });
  }, [addToast]);
  return {
    showSpaceSaveErrorToast,
    showSpaceSaveSuccessToast,
    removeToast
  };
};