import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import groupBy from 'lodash/groupBy';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import EpicModel from 'app/client/core/js/models/epic';
import Utils from 'app/client/core/js/modules/utils';
import { useEntities, useEntity, useEntityWithFetch, useOptimizedEntities, useOptimizedEntity } from './collection';
import * as EpicStateData from './epicState';
import { isUsingPoints } from './estimateScale';
import { groupHasOneGroupEntityByGroupIds } from './group';
import * as StoryData from './story';
export const getEpicByGlobalId = globalId => EpicModel.get({
  global_id: globalId
});
export const getById = id => EpicModel.getById(id);
export const getAll = () => EpicModel.all();
export const copyAll = () => EpicModel.copyAll();
export const getEpicProgressPercentages = epic => EpicModel.getProgressPercentages(epic);
export const normalizeEpic = epic => EpicModel.normalize(epic);
export const saveNew = (epic, callback) => EpicModel.saveNew(epic, callback);
export const saveEpicChanges = (epic, changes) => EpicModel.Promises.saveChanges(epic, changes);
export const generateURL = (epic, commentId) => EpicModel.generateURL(epic, commentId);
export const archiveEpic = (epic, callback) => EpicModel.archiveEpic(epic, callback);
export const unarchiveEpic = (epic, callback) => EpicModel.unarchiveEpic(epic, callback);
export const unlinkProductboard = epic => EpicModel.Promises.unlinkProductboard(epic);
export const deleteEpic = (epic, callback) => EpicModel.deleteEpic(epic, callback);
export const isDone = epic => EpicModel.isDone(epic);
export const getTotalCommentCount = epic => EpicModel.getTotalCommentCount(epic.comments);
export const isActiveEpic = epic => Boolean(epic) && !epic.archived;
export const fetchStoriesForEpic = epicId => new Promise(resolve => EpicModel.fetchStoriesForEpics([{
  id: epicId
}], () => resolve()));
export const useEpics = function () {
  let {
    archived
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let {
    entities: epics
  } = useEntities({
    model: EpicModel
  });
  if (archived != null) {
    epics = filterEpicsByArchived(epics, archived);
  }
  return {
    epics
  };
};
export const useEpic = _ref => {
  let {
    id
  } = _ref;
  const {
    entity
  } = useEntity({
    model: EpicModel,
    id
  });
  return entity;
};
export const useEpicWithFetch = _ref2 => {
  let {
    id
  } = _ref2;
  const {
    entity,
    loading
  } = useEntityWithFetch({
    id,
    fetchById: fetchEpic,
    model: EpicModel
  });
  return {
    epic: entity,
    loading
  };
};
export const fetchEpic = id => EpicModel.fetchEpicPromise(id);
const FIELDS_TO_COMPARE = ['last_story_update', 'updated_at'];
export const useOptimizedEpic = id => {
  const epic = useOptimizedEntity(id, {
    model: EpicModel,
    // biome-ignore lint/suspicious/noDoubleEquals: We want null and undefined to compare as the same
    hasChanges: (oldValue, newValue) => FIELDS_TO_COMPARE.some(field => oldValue?.[field] != newValue?.[field])
  });
  return epic;
};
export const filterEpicsByArchived = (epics, archived) => epics.filter(epic => epic.archived === archived);
export const useOptimizedEpics = _ref3 => {
  let {
    fields,
    filter
  } = _ref3;
  return useOptimizedEntities({
    model: EpicModel,
    filter,
    fields
  });
};
export const getCalendarLink = epic => EpicModel.getCalendarLink(epic);
export const getStories = _ref4 => {
  let {
    epic
  } = _ref4;
  return StoryData.all().filter(story => {
    return story.epic_id === epic.id;
  });
};
export const isAnyStoryAssociatedToDifferentGroup = _ref5 => {
  let {
    epic
  } = _ref5;
  if (!epic.group_id) {
    return false;
  }
  return getStories({
    epic
  }).some(story => {
    if (!story.group_id) {
      return false;
    }
    return !story.archived && story.group_id !== epic.group_id;
  });
};
export const isAnyStoryAssociatedToDifferentGroups = epic => {
  if (!epic.group_ids?.length) return false;
  return getStories({
    epic
  }).some(story => {
    if (!story.group_id || story.archived) return false;
    return !epic.group_ids.includes(story.group_id);
  });
};
export const getGroupsFromStories = epic => {
  return [...new Set(getStories({
    epic
  }).map(s => s.group_id).filter(id => !!id))];
};
export const groupEpicsByStateId = epics => {
  return groupBy(epics, 'epic_state_id');
};
export const create = epic => EpicModel.Promises.saveNew(epic);
export const getEpicsByGroupIds = _ref6 => {
  let {
    groupIds,
    epics = getAll(),
    epicStates
  } = _ref6;
  const orderedEpicStates = EpicStateData.sortByPosition(epicStates);
  return Object.entries(groupHasOneGroupEntityByGroupIds({
    set: new Set(groupIds),
    entities: epics
  })).reduce((acc, _ref7) => {
    let [key, epics] = _ref7;
    if (epics.length === 0) {
      return acc;
    }
    const groupedByState = groupEpicsByStateId(epics);
    return {
      ...acc,
      [key]: orderedEpicStates.map(state => ({
        state,
        epics: groupedByState[state.id] || []
      }))
    };
  }, {
    set: [],
    none: []
  });
};
export const getProgressStats = epic => {
  if (isUsingPoints()) {
    return {
      [WORKFLOW_STATE_TYPES.DONE]: epic.num_points_done || 0,
      [WORKFLOW_STATE_TYPES.STARTED]: epic.num_points_started || 0,
      [WORKFLOW_STATE_TYPES.UNSTARTED]: epic.num_points_unstarted || 0,
      [WORKFLOW_STATE_TYPES.BACKLOG]: epic.num_points_backlog || 0
    };
  }
  return {
    [WORKFLOW_STATE_TYPES.DONE]: epic.num_stories_done || 0,
    [WORKFLOW_STATE_TYPES.STARTED]: epic.num_stories_started || 0,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: epic.num_stories_unstarted || 0,
    [WORKFLOW_STATE_TYPES.BACKLOG]: epic.num_stories_backlog || 0
  };
};
export const getFilteredEpic = () => {
  const activeEpics = EpicModel.filter({
    active: true
  });
  return activeEpics.length === 1 ? activeEpics[0].id : undefined;
};
export const currentUserIsFollower = epic => !!EpicModel.currentUserIsFollower(epic);
export const getDeadlineClass = epic => {
  let className = '';
  if (!EpicModel.isDone(epic)) {
    className = Utils.getDeadlineClass(epic);
  }
  return className;
};
export const removeLabel = (epic, label) => EpicModel.Promises.removeLabel(epic, label);
export const saveComment = (publicId, comment) => EpicModel.Promises.saveComment(getById(publicId), comment);
export const saveCommentReply = (publicId, commentId, comment) => EpicModel.Promises.saveReply(getById(publicId), commentId, comment);
export const getEpicCountForWorkflowState = epicStateId => {
  return getAll().filter(epic => epic.epic_state_id === epicStateId).length;
};