import { MutateEpicUnarchiveDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { UnarchiveEpicFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
export const UnarchiveEpicFragment = UnarchiveEpicFragmentFragmentDoc;
const MUTATE_EPIC_UNARCHIVE = MutateEpicUnarchiveDocument;
export function useUnarchiveEpicMutation() {
  const [setUnarchived] = useMutation(MUTATE_EPIC_UNARCHIVE);
  return useCallback(epic => setUnarchived({
    variables: {
      id: epic.id
    },
    optimisticResponse: {
      __typename: 'Mutation',
      epicUpdate: {
        __typename: 'EpicPayload',
        epic: {
          __typename: 'Epic',
          id: epic.id,
          archived: false
        }
      }
    }
  }), [setUnarchived]);
}