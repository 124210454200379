import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { CustomFieldSelectList } from 'components/shared/custom-fields/CustomFieldSelectList';
import { saveCustomFieldValue, useStory } from 'data/entity/story';
import { CustomFieldHeader } from './CustomFieldHeader';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const CustomFieldAttributes = _ref => {
  let {
    storyId
  } = _ref;
  const story = useStory({
    id: storyId
  });
  const {
    addToast
  } = useToast();
  if (!story) return null;
  return _jsxs(_Fragment, {
    children: [_jsx(CustomFieldHeader, {}), _jsx(CustomFieldSelectList, {
      storyType: story.story_type,
      storyCustomFields: story.custom_fields,
      onChange: async (fieldId, valueId) => {
        try {
          await saveCustomFieldValue(story, fieldId, valueId);
        } catch (error) {
          addToast({
            timeout: 6000,
            kind: 'alert',
            Content: () => _jsxs(ToastText, {
              children: ["Uh oh, your organization no longer has access to advanced Custom Fields. Please contact one of your Owners to upgrade your plan.", _jsx("br", {}), _jsxs("small", {
                children: ["Unable to update ", _jsx("b", {
                  children: story.name
                })]
              })]
            }),
            width: 404
          });
        }
      }
    })]
  });
};