export default {
  red23: 'hsl(0, 100%, 23%)',
  red33: 'hsl(0, 90%, 33%)',
  red50: 'hsl(0, 60%, 50%)',
  red77: 'hsl(0, 67%, 77%)',
  red95: 'hsl(0, 77%, 95%)',
  orange38: 'hsl(32, 100%, 38%)',
  orange47: 'hsl(32, 98%, 47%)',
  yellow45: 'hsl(48, 75%, 45%)',
  yellow68: 'hsl(48, 90%, 68%)',
  yellow82: 'hsl(48, 95%, 82%)',
  yellow92: 'hsl(48, 90%, 92%)',
  yellow94: 'hsl(48, 50%, 94%)',
  green23: 'hsl(140, 95%, 23%)',
  green30: 'hsl(140, 90%, 30%)',
  green38: 'hsl(140, 80%, 38%)',
  green67: 'hsl(140, 66%, 67%)',
  green88: 'hsl(140, 71%, 88%)',
  green97: 'hsl(140, 88%, 97%)',
  blue39: 'hsl(218, 87%, 39%)',
  blue64: 'hsl(218, 81%, 64%)',
  blue82: 'hsl(218, 100%, 82%)',
  blue88: 'hsl(218, 50%, 88%)',
  blue97: 'hsl(218, 82%, 97%)',
  purple17: 'hsl(264, 30%, 17%)',
  purple26: 'hsl(264, 30%, 26%)',
  purple29: 'hsl(264, 30%, 29%)',
  purple47: 'hsl(264, 83%, 47%)',
  purple75: 'hsl(264, 91%, 75%)',
  purple93: 'hsl(264, 70%, 93%)',
  purple97: 'hsl(264, 83%, 97%)',
  gray7: '#111',
  gray13: '#222',
  gray20: '#333',
  gray27: '#444',
  gray33: '#555',
  gray40: '#666',
  gray50: '#808080',
  gray60: '#999',
  gray70: '#b3b3b3',
  gray80: '#ccc',
  gray87: '#ddd',
  gray93: '#eee',
  gray95: '#f3f3f3',
  gray97: '#fbfbfc',
  gray98: '#f9f9f9',
  white: '#fff'
};