import { Button } from '@clubhouse/shared/components/Button';
import { PAGE_NAMES, generatePathForPageWithSlug, navigateTo } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const IterationAutomationsCTA = () => {
  return _jsx(Button, {
    kind: Button.KIND.SECONDARY,
    size: Button.SIZE.MEDIUM,
    onClick: () => {
      navigateTo({
        url: generatePathForPageWithSlug(PAGE_NAMES.SETTINGS_AUTOMATIONS)
      });
    },
    children: "\uD83E\uDD16 Set Up Automations"
  });
};
IterationAutomationsCTA.displayName = "IterationAutomationsCTA";