import { getDayOfMonth, today as getToday, getYearAndMonth } from '@clubhouse/shared/utils/date';
export const getIsSelected = (date, startDate, endDate) => date === startDate || date === endDate;
export const getIsFocusTarget = (date, startDate, endDate) => {
  if (date === startDate) return true;
  const dateYearAndMonth = getYearAndMonth(date);
  const hasStartDateThisMonth = !!startDate && getYearAndMonth(startDate) === dateYearAndMonth;
  if (!hasStartDateThisMonth && date === endDate) return true;
  const hasEndDateThisMonth = !!endDate && getYearAndMonth(endDate) === dateYearAndMonth;
  const today = getToday();
  if (!hasStartDateThisMonth && !hasEndDateThisMonth && date === today) return true;
  const isCurrentMonth = getYearAndMonth(today) === dateYearAndMonth;
  if (!hasStartDateThisMonth && !hasEndDateThisMonth && !isCurrentMonth && getDayOfMonth(date) === 1) return true;
  return false;
};