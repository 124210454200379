import { StoryCanonicalCustomFieldValuesFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { PriorityField } from 'components/gql/stories/table/fields/PriorityField';
import { ProductAreaField } from 'components/gql/stories/table/fields/ProductAreaField';
import { SeverityField } from 'components/gql/stories/table/fields/SeverityField';
import { SkillSetField } from 'components/gql/stories/table/fields/SkillSetField';
import { TechnicalAreaField } from 'components/gql/stories/table/fields/TechnicalAreaField';
StoryCanonicalCustomFieldValuesFragmentDoc;
export const customFieldColumnsByCanonicalName = {
  priority: PriorityField,
  productArea: ProductAreaField,
  severity: SeverityField,
  skillSet: SkillSetField,
  technicalArea: TechnicalAreaField
};