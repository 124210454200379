import { useCallback, useState } from 'react';
export const useListState = function () {
  let initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const [list, setList] = useState(initialState);
  const addItem = useCallback(item => {
    setList(prevList => [...prevList, item]);
  }, []);
  const removeItem = useCallback(item => {
    setList(prevList => prevList.filter(i => i !== item));
  }, []);
  const clearList = useCallback(() => {
    setList([]);
  }, []);
  return {
    list,
    addItem,
    removeItem,
    clearList
  };
};