import { useState } from 'react';
import { useRequest } from './useRequest';
export const useRequestTimer = function (request) {
  let {
    minTime = 3000
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [isLoading, setLoading] = useState(false);
  const {
    request: withRequest,
    ...rest
  } = useRequest({
    fn: request
  });
  const withTimer = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return new Promise((resolve, reject) => {
      setLoading(true);
      const then = Date.now();
      withRequest(...args).then(res => {
        const diff = minTime - (Date.now() - then);
        setTimeout(() => {
          setLoading(false);
          resolve(res);
        }, diff);
      }).catch(reject);
    });
  };
  return {
    request: withTimer,
    ...rest,
    isLoading
  };
};