import { useReducer } from 'react';
import { addStory } from 'data/entity/story';
import { useStoryCreatedToastDeprecated } from './useStoryCreatedToast';
const storyInputReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_TITLE':
      return {
        ...state,
        title: action.newTitle ?? ''
      };
    case 'AFTER_CREATE':
      return {
        title: '',
        isCreatedInSuccession: true
      };
    case 'CANCEL':
    default:
      return {
        title: '',
        isCreatedInSuccession: false
      };
  }
};
export const useNewStoryInput = _ref => {
  let {
    hideInput,
    newStoryAttributes,
    isVisible,
    actionContext,
    skipFilters
  } = _ref;
  const [state, dispatch] = useReducer(storyInputReducer, {
    title: '',
    isCreatedInSuccession: false
  });
  const {
    showStoryCreatedToast
  } = useStoryCreatedToastDeprecated({
    showAddAnother: true
  });
  const createStory = () => {
    addStory({
      name: state.title.trim(),
      ...newStoryAttributes
    }, {
      move_to: 'first',
      actionContext: `${actionContext}${state.isCreatedInSuccession ? ' continued' : ''}`,
      skipFilters
    }).then(story => {
      showStoryCreatedToast(story);
    });
    dispatch({
      type: 'AFTER_CREATE'
    });
  };
  const onInputChange = e => {
    dispatch({
      type: 'UPDATE_TITLE',
      newTitle: e.target.value
    });
  };
  const onInputBlur = () => {
    if (!state.title.trim()) {
      dispatch({
        type: 'CANCEL'
      });
    } else {
      createStory();
    }
    hideInput();
  };
  const onInputKeyDown = e => {
    if (!isVisible) return;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (state.title.trim()) {
        createStory();
      }
    }
    if (e.key === 'Escape') {
      dispatch({
        type: 'CANCEL'
      });
      hideInput();
    }
  };
  return {
    onInputChange,
    onInputBlur,
    onInputKeyDown,
    inputValue: state.title
  };
};