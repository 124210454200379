import Is from './is';
import Url from './url';
const exports = {};
const DEFAULT_PATH = '/';
exports.set = (key, value, days) => {
  $.cookie(key, value, {
    expires: days,
    path: _getPath(),
    secure: !Is.development()
  });
};
exports.get = key => {
  return $.cookie(key);
};

// Cookies are set to each org, however they are set to the default path
// if created from the org/company dashboards when not within an org scope.
// We clear those too to be sure we don't keep them around too when removing.
exports.remove = key => {
  const path = _getPath();
  $.removeCookie(key, {
    path: _getPath()
  });
  if (path !== DEFAULT_PATH) {
    $.removeCookie(key, {
      path: DEFAULT_PATH
    });
  }
};
function _getPath() {
  return Url.getSlugPath() || DEFAULT_PATH;
}
export { exports as default };