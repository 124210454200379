import merge from 'lodash/merge';
import { useCallback, useMemo, useRef } from 'react';
import { PAGE_SIZE } from '@clubhouse/shared/components/Table/config';
import { useLatestRef } from '@clubhouse/shared/hooks/useLatestRef';
import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import { useFilters } from 'pages/backlog/components/filters/useFilters';
import { useTableLoadMorePaginationState } from 'utils/useTableLoadMorePaginationState';
import { GROUP_BY_VALUES, useGroupBy } from './groupBy';
import { useBacklogTableQuery } from './useBacklogTableQuery';
export function useBacklogTableDataWithLoadMore(_ref) {
  let {
    sortColumn,
    sortDirection
  } = _ref;
  const queryMetaDataMap = useRef(new WeakMap());
  const groupBy = useGroupBy();
  const filters = useFilters();
  const where = {
    archived: false,
    ...insertObjectIf(filters.query.trim().length > 0, {
      name: filters.query.trim()
    }),
    ...insertObjectIf(filters.storyType.trim().length > 0, {
      type: filters.storyType.trim()
    }),
    ...insertObjectIf(filters.ownerIds.length > 0, {
      owners: filters.ownerIds
    }),
    ...insertObjectIf(filters.requesterIds.length > 0, {
      requester: filters.requesterIds
    }),
    ...insertObjectIf(filters.epicIds.length > 0, {
      epics: filters.epicIds
    }),
    ...insertObjectIf(filters.labelIds.length > 0, {
      labels: filters.labelIds
    }),
    ...insertObjectIf(filters.groupIds.length > 0, {
      teams: filters.groupIds
    }),
    ...insertObjectIf(filters.priorities.length > 0, {
      priority: filters.priorities
    }),
    ...insertObjectIf(filters.severityIds.length > 0, {
      severity: filters.severityIds
    }),
    ...insertObjectIf(filters.productAreaIds.length > 0, {
      productArea: filters.productAreaIds
    }),
    ...insertObjectIf(filters.technicalAreaIds.length > 0, {
      technicalArea: filters.technicalAreaIds
    }),
    ...insertObjectIf(filters.skillSetIds.length > 0, {
      skillSet: filters.skillSetIds
    }),
    ...insertObjectIf((filters.customFields ?? []).length > 0, {
      customFields: filters.customFields
    }),
    workflowState_types: ['backlog']
  };
  const orderBy = [...(!!groupBy && !!GROUP_BY_VALUES[groupBy] ? [{
    value: GROUP_BY_VALUES[groupBy],
    direction: 'ascending'
  }] : []), {
    value: sortColumn,
    direction: sortDirection
  }];
  const numStories = useRef(0);
  const [pagination, setPollingRange] = useTableLoadMorePaginationState({
    loadedItems: numStories,
    pageSize: PAGE_SIZE
  });
  const inputRef = useLatestRef({
    pagination,
    orderBy,
    where
  });
  const {
    error,
    data,
    previousData,
    fetchMore,
    isFetchingMore,
    stopPolling,
    startPolling,
    ...result
  } = useBacklogTableQuery(inputRef.current, undefined);
  if (data) queryMetaDataMap.current.set(data, {
    groupBy
  });
  const {
    pageInfo,
    stories
  } = useMemo(() => {
    return {
      pageInfo: data?.workspace2?.stories?.pageInfo,
      stories: (data?.workspace2?.stories?.edges || [])?.map(edge => edge.node) ?? []
    };
  }, [data]);
  const hasMoreData = useLatestRef(stories.length < (pageInfo?.totalSize ?? 0));
  numStories.current = stories.length;
  const fetchNextPage = useCallback(async () => {
    if (!isFetchingMore && hasMoreData.current) {
      stopPolling();
      await fetchMore({
        input: merge({}, inputRef.current, {
          pagination: {
            offset: numStories.current,
            limit: PAGE_SIZE
          }
        })
      });
      startPolling();
    }
  }, [fetchMore, hasMoreData, inputRef, isFetchingMore, startPolling, stopPolling]);
  return {
    groupBy,
    ...result,
    ...(queryMetaDataMap.current.get(data || previousData) ?? {}),
    stories,
    error,
    isLoading: !error && !data,
    pageInfo,
    isFetchingMore,
    setPollingRange,
    fetchNextPage
  };
}