/* eslint no-fallthrough: off */

import { useEffect, useRef } from 'react';
import { getFocusableChildren, getTabbableChildren, getTabbableChildrenQuickSearch } from '@clubhouse/shared/utils/focus';
const getFirstListItem = listRef => getTabbableChildren(listRef.current)[0] || null;
const getLastListItem = listRef => {
  const items = getTabbableChildren(listRef.current);
  if (!items.length) return null;
  return items[items.length - 1];
};
const getNext = (list, currentEl) => {
  const index = list.indexOf(currentEl);
  if (index < 0) return null;
  if (index === list.length - 1) return list[0];
  return list[index + 1];
};
const getPrev = (list, currentEl) => {
  const index = list.indexOf(currentEl);
  if (index < 0) return null;
  if (index === 0) return list[list.length - 1];
  return list[index - 1];
};
const gotoNext = (list, currentEl, moveFullLength) => {
  const next = getNext(list, currentEl);
  if (!next) return false;
  if (moveFullLength) list[list.length - 1].focus();else next.focus();
  return true;
};
const gotoPrev = (list, currentEl, moveFullLength) => {
  const prev = getPrev(list, currentEl);
  if (!prev) return false;
  if (moveFullLength) list[0].focus();else prev.focus();
  return true;
};
const gotoInActiveList = gotoFn => (listRefs, currentEl, moveFullLength) => {
  const activeListRef = listRefs.find(listRef => listRef.current?.contains(currentEl));
  if (activeListRef) gotoFn(getTabbableChildren(activeListRef.current), currentEl, moveFullLength);
};
const gotoNextInActiveList = gotoInActiveList(gotoNext);
const gotoPrevInActiveList = gotoInActiveList(gotoPrev);
const handleTabInActiveList = (inputEl, dropdownEl, listRefs, currentEl, isShiftKey) => {
  const activeListRef = listRefs.find(listRef => listRef.current?.contains(currentEl));
  let focusTarget = currentEl;
  if (activeListRef) {
    const children = getTabbableChildren(activeListRef.current);
    const childrenInTabOrder = children.filter(el => !el.hasAttribute('data-focusable'));
    const currentIndex = children.indexOf(currentEl);
    if (isShiftKey) {
      focusTarget = childrenInTabOrder.reduce((match, el) => {
        const i = children.indexOf(el);
        return i >= 0 && i < currentIndex ? el : match;
      }, null);
      if (focusTarget) {
        focusTarget.focus();
        return true;
      }
      focusTarget = getFirstListItem(activeListRef) || currentEl;
    } else {
      focusTarget = childrenInTabOrder.reduceRight((match, el) => {
        const i = children.indexOf(el);
        return i >= 0 && i > currentIndex ? el : match;
      }, null);
      if (focusTarget) {
        focusTarget.focus();
        return true;
      }
      focusTarget = getLastListItem(activeListRef) || currentEl;
    }
  }
  if (!focusTarget) return false;
  return (isShiftKey ? gotoPrev : gotoNext)([...(inputEl ? [inputEl] : []), ...getTabbableChildrenQuickSearch(dropdownEl)], focusTarget, false);
};
const focusFirstChild = (ref, stateRef) => {
  if (ref.current) {
    const focusableChildren = getFocusableChildren(ref.current).filter(el => el.hasAttribute('data-focusable'));
    focusableChildren.at(0)?.focus();
  } else {
    stateRef.current.timeoutId = setTimeout(() => {
      focusFirstChild(ref, stateRef);
    });
  }
};
const isPrintableChar = char => /^[ -~]$/i.test(char);
export function useSearchWithResults(_ref) {
  let {
    inputRef,
    dropdownRef,
    onInputKeyDown,
    onInputKeyUp,
    onEnter,
    onAction,
    onClose,
    onTextInput,
    hasFocus
  } = _ref;
  const resultsRef = useRef(null);
  const operatorsRef = useRef(null);
  const suggestionsRef = useRef(null);
  const stateRef = useRef({
    hasFocus: false,
    timeoutId: undefined,
    clickingInput: false
  });

  // We need to reset the focus state when the Quick Search popover is hidden because
  // there's a possibility that the `onBlur` isn't called when clicking on a result.
  useEffect(() => {
    // Only if the value is actually `false`, not on falsy values.
    if (hasFocus === false) {
      stateRef.current.hasFocus = false;
    }
  }, [hasFocus]);

  /* We want to allow focus to move from the last item in the operators suggestions list to the search results items on arrow down,
   * but only when there are search results displayed for current tab.
   */
  const shouldMoveFocusFromSuggestionsToResults = () => {
    return suggestionsRef.current?.lastElementChild?.querySelector('button') === document.activeElement && resultsRef.current?.getElementsByTagName('a').length;
  };
  const onKeyDown = e => {
    if (e.defaultPrevented) return;
    let handled = true;
    clearTimeout(stateRef.current.timeoutId);
    switch (e.key) {
      case 'Escape':
        {
          inputRef.current?.focus();
          onClose?.();
          break;
        }
      case 'Enter':
        {
          if (document.activeElement === inputRef.current) {
            onAction?.();
            focusFirstChild(dropdownRef, stateRef);
          } else handled = false;
          break;
        }
      case 'ArrowDown':
        {
          const tabPanelButtons = Array.from(dropdownRef.current?.querySelector('div.tab-list-container')?.querySelectorAll('button') ?? []);
          const targetIsTabPanelButton = tabPanelButtons.some(buttonEl => buttonEl === e.target);
          if (document.activeElement === inputRef.current) {
            const focusAbleElem = suggestionsRef.current ? suggestionsRef : resultsRef;
            focusFirstChild(focusAbleElem, stateRef);
          } else if (shouldMoveFocusFromSuggestionsToResults()) {
            focusFirstChild(resultsRef, stateRef);
          } else if (!targetIsTabPanelButton && document.activeElement !== inputRef.current) {
            gotoNextInActiveList([resultsRef, suggestionsRef, operatorsRef], document.activeElement, e.altKey);
          }
          break;
        }
      case 'ArrowUp':
        {
          gotoPrevInActiveList([resultsRef, suggestionsRef, operatorsRef], document.activeElement, e.altKey);
          break;
        }
      case 'ArrowLeft':
      case 'ArrowRight':
        {
          if (resultsRef.current?.contains(document.activeElement) || suggestionsRef.current?.contains(document.activeElement)) {
            inputRef.current?.focus();
          } else {
            handled = false;
          }
          break;
        }
      case 'Tab':
        {
          if (!dropdownRef.current) {
            handled = false;
          } else {
            handled = handleTabInActiveList(inputRef.current, dropdownRef.current, [resultsRef, suggestionsRef, operatorsRef], document.activeElement, e.shiftKey);
          }

          /* If currently on 1 of the result tabs & the operator suggestions are not being rendered, 
             then "shift + tab" should move focus to the search input.
             * If the operator suggestions are being render, maintain the default "shift + tab" behavior.
          */
          if (e.shiftKey && !suggestionsRef.current) {
            const tabPanelButtons = Array.from(dropdownRef.current?.querySelector('div#quick-search-tab-list-container[role="tablist"]')?.querySelectorAll('button[role="tab"]') ?? []);
            const targetIsTabPanelButton = tabPanelButtons.some(buttonEl => buttonEl === e.target);
            if (targetIsTabPanelButton) {
              e.preventDefault();
              inputRef.current?.focus();
            }
          }
          break;
        }
      default:
        {
          handled = false;
          if (e.key.length === 1) {
            setTimeout(() => onTextInput?.());
          }
          const isActiveElemInResultsOrSuggestions = resultsRef.current?.contains(document.activeElement) || suggestionsRef.current?.contains(document.activeElement);

          /* Return focus to the search input if a "printable" char is entered
             while focused on either a search result or an operator suggestion.
          */
          if (isActiveElemInResultsOrSuggestions && isPrintableChar(e.key)) {
            inputRef.current?.focus();
          }
          break;
        }
    }
    if (handled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  useEffect(() => {
    const onMouseUp = () => {
      stateRef.current.clickingInput = false;
    };
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);
  const isPartOfSearch = relatedEl => inputRef.current?.contains(document.activeElement) || dropdownRef.current?.contains(document.activeElement) || inputRef.current?.contains(relatedEl) || dropdownRef.current?.contains(relatedEl);
  return {
    getInputProps: () => ({
      ref: inputRef,
      onMouseDown: () => {
        stateRef.current.clickingInput = true;
      },
      onMouseUp: () => {
        if (!stateRef.current.hasFocus && stateRef.current.clickingInput) {
          setTimeout(() => onEnter?.());
          stateRef.current.hasFocus = true;
        }
      },
      onBlur: e => {
        if (stateRef.current.hasFocus && !isPartOfSearch(e.relatedTarget)) {
          stateRef.current.hasFocus = false;
        }
      },
      onKeyDown: e => {
        onInputKeyDown?.(e);
        onKeyDown(e);
      },
      onKeyUp: e => {
        onInputKeyUp?.(e);
      }
    }),
    getDropdownProps: () => ({
      ref: dropdownRef,
      onFocus: () => {
        stateRef.current.hasFocus = true;
      },
      onBlur: e => {
        if (stateRef.current.hasFocus && !isPartOfSearch(e.relatedTarget)) {
          stateRef.current.hasFocus = false;
        }
      },
      onKeyDown
    }),
    getResultsProps: () => ({
      ref: resultsRef
    }),
    getOperatorsProps: () => ({
      ref: operatorsRef
    }),
    getSuggestionsProps: () => ({
      ref: suggestionsRef
    })
  };
}