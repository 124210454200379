import * as Event from '../../../core/js/_frontloader/event';
import ManageBillingController from './manageBilling';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { PaymentPlanTab } from 'components/settings/manage-billing/PaymentPlanTab';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const MANAGE_PLAN_LINK_SELECTOR = '#manage-plan-link';
Event.on('pageRendered.Billing', () => {
  exports.open();
});
exports.open = () => {
  if ($(SettingsPage.PARENT_SELECTOR).length > 0) {
    ManageBillingController.openPane(() => {
      $(MANAGE_PLAN_LINK_SELECTOR).trigger('click');
    });
  } else {
    ManageBillingController.open();
  }
  return false;
};
exports.renderPlanTab = () => {
  const props = {};
  return View.renderComponentDelayed({
    componentKey: 'PaymentPlanTab',
    // cssClass: '',
    component: _jsx(PaymentPlanTab, {
      ...props
    })
  }).html;
};
export { exports as default };