import { pluralize, toNumber } from 'utils/format';
export const formatEstimate = estimate => {
  if (estimate === null) {
    return {
      name: 'Unestimated',
      value: null
    };
  }
  const point = toNumber(estimate);
  return {
    name: pluralize(point, 'point', 'points'),
    value: point
  };
};
export const formatEstimates = estimates => estimates.map(formatEstimate);