import { useMemo } from 'react';
import { useFilters } from 'pages/epics-apollo/components/filters/useFilters';
const getFilteredList = (source, filter) => {
  if (filter.length === 0) return source;
  return source.filter(item => filter.includes(item));
};
const shouldShowEpicStates = (epicStates, filters) => getFilteredList(epicStates.map(node => node.id), filters).length > 0;
export const useColumnConfigs = (_ref, selectedColumn) => {
  let {
    unstarted,
    started,
    done
  } = _ref;
  const {
    stateIds
  } = useFilters();
  const {
    showUnstarted,
    showStarted,
    showDone
  } = useMemo(() => ({
    showUnstarted: shouldShowEpicStates(unstarted, stateIds),
    showStarted: shouldShowEpicStates(started, stateIds),
    showDone: shouldShowEpicStates(done, stateIds)
  }), [stateIds, unstarted, started, done]);
  const numVisibleColumns = [showUnstarted, showStarted, showDone].filter(Boolean).length;
  const notSelectedWidth = numVisibleColumns === 1 ? '100%' : numVisibleColumns === 2 ? '40%' : '25%';
  const selectedWidth = numVisibleColumns === 1 ? '100%' : numVisibleColumns === 2 ? '60%' : '50%';
  return {
    unstarted: {
      show: showUnstarted,
      width: selectedColumn === 'unstarted' ? selectedWidth : notSelectedWidth
    },
    started: {
      show: showStarted,
      width: selectedColumn === 'started' ? selectedWidth : notSelectedWidth
    },
    done: {
      show: showDone,
      width: selectedColumn === 'done' ? selectedWidth : notSelectedWidth
    }
  };
};