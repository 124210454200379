import { useEffect, useState } from 'react';
export function useVisibilityObserver(ref) {
  let {
    enable = true,
    ...options
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [visible, setVisible] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We don't include options because it is only used on initial render
  useEffect(() => {
    if (enable === false) return;
    if (ref.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setVisible(entry.isIntersecting);
        });
      }, options);
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, enable]);
  return visible || enable === false;
}