import { analytics } from 'utils/monitoring/monitors/analytics';
export const openPreferences = () => {
  window.ketch('showPreferences');
};
export const attachConsentPreferences = () => {
  analytics.addSourceMiddleware(_ref => {
    let {
      payload,
      next
    } = _ref;
    payload.obj.context.consent = {
      categoryPreferences: {
        advertising: window.ketchConsent?.advertising,
        analytics: window.ketchConsent?.analytics,
        essential_services: window.ketchConsent?.essential_services,
        personalization: window.ketchConsent?.personalization
      }
    };
    next(payload);
  });
};