import { sortByName } from './utils/sort';
const isValidProfile = profile => Boolean(profile);
export const formatProfile = profile => {
  const {
    id,
    name
  } = profile;
  return {
    id,
    name
  };
};
export const formatProfiles = profiles => profiles.filter(isValidProfile).map(formatProfile).sort(sortByName);