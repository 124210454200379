import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TooltipText, TooltipTitle } from '@clubhouse/shared/components/Tooltip';
import { useSlot, withSlot } from '@clubhouse/shared/hooks';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const _TooltipLayout = _ref => {
  let {
    title,
    text,
    children
  } = _ref;
  const IconTemplate = useSlot({
    children,
    name: 'Icon'
  });
  return _jsxs(Spaced, {
    horizontally: true,
    amount: "space2",
    children: [_jsx(IconTemplate, {}), _jsxs(Spaced, {
      vertically: true,
      amount: "space2",
      children: [_jsx(TooltipTitle, {
        children: title
      }), _jsx(TooltipText, {
        children: text
      })]
    })]
  });
};
_TooltipLayout.displayName = "_TooltipLayout";
export const TooltipLayout = withSlot(_TooltipLayout);