import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
const EMPTY_NAVIGATION_ID = 'empty';
const emptyNavigation = {
  id: EMPTY_NAVIGATION_ID,
  list: []
};
const navigationVar = makeVar(emptyNavigation);
export const useCurrentStoryDialogNavigation = currentStoryId => {
  const {
    list,
    onNext,
    onPrev,
    cleanup
  } = useReactiveVar(navigationVar);
  const [currentIdx, setCurrentIdx] = useState(list.indexOf(currentStoryId));
  const prev = list[currentIdx - 1];
  const next = list[currentIdx + 1];
  const goNext = useCallback(() => {
    onNext?.(currentIdx);
    setCurrentIdx(idx => idx + 1);
  }, [currentIdx, onNext]);
  const goPrevious = useCallback(() => {
    onPrev?.(currentIdx);
    setCurrentIdx(idx => idx - 1);
  }, [currentIdx, onPrev]);
  useEffect(() => () => {
    clearStoryDialogNavigation();
    cleanup?.();
  }, [cleanup]);
  return {
    prev,
    next,
    goNext,
    goPrevious
  };
};
export const getStoryDialogNavigationList = () => navigationVar().list;
export const hasStoryDialogNavigation = () => navigationVar().id !== EMPTY_NAVIGATION_ID;
export const clearStoryDialogNavigation = () => {
  navigationVar(emptyNavigation);
};
export const updateStoryDialogNavigation = newNavigation => {
  const currentNavigation = navigationVar();
  if (currentNavigation.id === newNavigation.id) navigationVar(newNavigation);
};
export const setStoryDialogNavigation = navigation => {
  navigationVar(navigation);
};