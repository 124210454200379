import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
export const statsTotal = stats => Object.values(WORKFLOW_STATE_TYPES).reduce((acc, type) => acc + (stats[type] || 0), 0);
export const statsToPercents = stats => {
  const total = statsTotal(stats);
  return Object.values(WORKFLOW_STATE_TYPES).reduce((acc, type) => {
    const value = stats[type] || 0;
    acc[type] = total > 0 ? value / total * 100 : 0;
    return acc;
  }, {});
};
export const getMeterColor = (theme, kind) => {
  switch (kind) {
    case WORKFLOW_STATE_TYPES.UNSTARTED:
      {
        return theme.progressBarUnstartedColor;
      }
    case WORKFLOW_STATE_TYPES.STARTED:
      {
        return theme.progressBarStartedColor;
      }
    case WORKFLOW_STATE_TYPES.DONE:
      {
        return theme.progressBarCompletedColor;
      }
    default:
      {
        return theme.progressBarBackground;
      }
  }
};
export const nonNegativeStats = stats => Object.entries(stats).reduce((acc, _ref) => {
  let [key, value] = _ref;
  return {
    ...acc,
    [key]: value >= 0 ? value : 0
  };
}, {});
export const getPercentageDone = rawStats => {
  const stats = nonNegativeStats(rawStats);
  const percents = statsToPercents(stats);
  return Math.round(percents[WORKFLOW_STATE_TYPES.DONE]);
};
export const getProgressStatsFromGQL = function (percentDone, percentStarted) {
  let percentUnstarted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Math.max(0, 100 - percentStarted - percentDone);
  return {
    [WORKFLOW_STATE_TYPES.DONE]: percentDone,
    [WORKFLOW_STATE_TYPES.STARTED]: percentStarted,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: percentUnstarted,
    [WORKFLOW_STATE_TYPES.BACKLOG]: 0
  };
};
export const getProgressStats = (stats, usePoints) => {
  if (usePoints) {
    return {
      [WORKFLOW_STATE_TYPES.DONE]: stats.num_points_done,
      [WORKFLOW_STATE_TYPES.STARTED]: stats.num_points_started,
      [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.num_points_unstarted,
      [WORKFLOW_STATE_TYPES.BACKLOG]: stats.num_points_backlog
    };
  }
  return {
    [WORKFLOW_STATE_TYPES.DONE]: stats.num_stories_done,
    [WORKFLOW_STATE_TYPES.STARTED]: stats.num_stories_started,
    [WORKFLOW_STATE_TYPES.UNSTARTED]: stats.num_stories_unstarted,
    [WORKFLOW_STATE_TYPES.BACKLOG]: stats.num_stories_backlog
  };
};
export const getProgressStatsPercent = (stats, usePoints) => {
  return statsToPercents(getProgressStats(stats, usePoints));
};