import { Divider } from './components/Divider';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Content = _ref => {
  let {
    children
  } = _ref;
  return _jsx(_Fragment, {
    children: children
  });
};
Content.Divider = Divider;