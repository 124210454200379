import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { EpicSearchCardAdapter } from './EpicSearchCard';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const EpicResults = _ref => {
  let {
    fetchProps: {
      loading,
      data: epics,
      previousData,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick
  } = _ref;
  if (loading && !hasFetched) {
    return _jsxs(_Fragment, {
      children: [_jsx(SkeletonSearchCard, {
        icon: "Epic",
        kind: "blue"
      }), _jsx(SkeletonSearchCard, {
        icon: "Epic",
        kind: "blue"
      }), _jsx(SkeletonSearchCard, {
        icon: "Epic",
        kind: "blue"
      })]
    });
  }
  const resultsList = loading ? previousData : epics;
  if (hasFetched && !resultsList?.length) {
    return _jsx(NoResults, {
      entityType: "Epics"
    });
  }
  return _jsxs(_Fragment, {
    children: [resultsList?.map((epic, index) => _jsx(EpicSearchCardAdapter, {
      epic: epic,
      onClick: entity => onClick?.(entity, index),
      openModalOnClick: openModalOnClick
    }, epic.id)), next && _jsx(LoadMore, {
      loading: loading,
      loadMore: loadMore
    })]
  });
};