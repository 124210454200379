import Asset from './asset';
import Async from './async';
import Format from './format';
import Utils from './utils';
import { cancelEvent } from '@clubhouse/shared/utils/dom';
const exports = {};
function _configLightbox() {
  window.lightbox.option({
    fadeDuration: 240,
    imageFadeDuration: 80,
    resizeDuration: 0,
    showImageNumberLabel: false,
    wrapAround: true
  });
  const lightboxIsRendered = () => !!document.querySelector('#lightbox');
  Async.waitFor(lightboxIsRendered, () => {
    _blockContextMenuEvent($('#lightbox').find('.lb-prev'));
    _blockContextMenuEvent($('#lightbox').find('.lb-next'));
  });
}

// We reset the event because occasionally this gets called more than once on boot.
// These listeners stick around until the app closes, so they aren't removed programmatically.
const _blockContextMenuEvent = el => el?.off('contextmenu').on('contextmenu', cancelEvent);
exports.loadLibraryIfMissing = () => {
  const url = Asset.getPrefix() + '/lib/lightbox.js';
  const testFn = () => {
    return !!window.lightbox;
  };
  Async.loadScriptAndWaitFor(url, testFn, _configLightbox);
};
exports.isOpen = () => {
  return $('#lightbox:visible').length > 0 || $('[data-perma-id="lightbox"]:visible').length > 0;
};
exports.wrapImages = element => {
  const entity = Utils.getModelFromContext(element, 'Story') || Utils.getModelFromContext(element, 'Epic') || Utils.getModelFromContext(element, 'Milestone') || Utils.getModelFromContext(element, 'Project');
  const selectors = '.comment .text img, ' +
  // Story comments
  '.comment-thread .comment-text img, ' +
  // Threaded Epic comments
  '.description img'; // Descriptions

  $(element).find(selectors).each(function () {
    const me = $(this);

    // Ignore emoji images and any existing img elements wrapped in an <a> tag:
    if (!me.is('.emojione') && !me.parent().is('a') && me.attr('src')) {
      const img = me[0]; // Convert jQuery object to DOM element
      const src = img.getAttribute('src');
      const name = src.split('/').pop();
      const imgParent = img.parentElement;
      const a = document.createElement('a');
      a.setAttribute('href', src);
      a.setAttribute('data-lightbox', `lightbox-${entity.id}`);
      a.setAttribute('target', '_blank');
      a.classList.add('use-lightbox');
      const dataTitleAnchor = document.createElement('a');
      dataTitleAnchor.setAttribute('href', src);
      const icon = document.createElement('span');
      icon.classList.add('fa', 'fa-cloud-download');
      dataTitleAnchor.append(icon, Format.sanitize(name).replace(/%20/g, ' '));
      a.setAttribute('data-title', dataTitleAnchor.outerHTML);
      imgParent.replaceChild(a, img);
      a.appendChild(img);
    }
  });
};
export { exports as default };