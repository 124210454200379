import { EVENTS, logEvent } from 'utils/monitoring';
export const logAddItem = _ref => {
  let {
    teamId,
    totalTeams
  } = _ref;
  return logEvent(EVENTS.Interaction_TeamNavigation_AddTeam, {
    teamId,
    totalTeams
  });
};
export const logRemoveItem = _ref2 => {
  let {
    teamId,
    totalTeams
  } = _ref2;
  return logEvent(EVENTS.Interaction_TeamNavigation_RemoveTeam, {
    teamId,
    totalTeams
  });
};