import { useCallback, useState } from 'react';
export const useCurrentStep = (signupSteps, onFinish) => {
  const [currentStep, setCurrentStep] = useState(0);
  const lastStepIndex = signupSteps.length - 1;
  const canGoBack = currentStep > 0;
  const canGoNext = currentStep < lastStepIndex;
  const isLastStep = currentStep === lastStepIndex;
  const goNext = useCallback(() => {
    if (canGoNext) setCurrentStep(step => Math.min(step + 1, lastStepIndex));else if (isLastStep) onFinish();
  }, [canGoNext, isLastStep, lastStepIndex, onFinish]);
  const goBack = useCallback(() => {
    if (canGoBack) setCurrentStep(step => Math.max(0, step - 1));
  }, [canGoBack]);
  return {
    currentStep,
    goBack,
    goNext,
    canGoNext,
    canGoBack
  };
};