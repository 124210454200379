import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SCALE_DESCRIPTIONS = {
  log: 'Good for seeing everything when you have outliers.',
  linear: 'Good for when you want an accurate sense of scale.'
};
export const ScaleSelect = props => {
  return _jsx(ReportSelectFilter, {
    ...props,
    label: "Scale",
    descriptions: SCALE_DESCRIPTIONS,
    LoadingComponent: _jsx(SkeletonRectangle, {
      width: 22,
      animate: true
    })
  });
};
ScaleSelect.displayName = "ScaleSelect";