import { isMac } from '@clubhouse/shared/utils';
import { hasModifierKey, toShortcutString } from '@clubhouse/shared/utils/keyboard';
import { getCurrentList, getIndentation, getLine } from '../utils';
const build = _ref => {
  let {
    disableAutoLists,
    listItem: _listItem,
    matcher: _matcher,
    additionalEvents,
    negativeMatcher: _negativeMatcher = ''
  } = _ref;
  const matcher = `${_matcher.trimEnd()}(?: )+`;
  const matchBeginning = `^${matcher}`;
  const matchFullLine = `^${matcher}$`;
  const negativeMatcher = `${_negativeMatcher.trimEnd()}(?: )+`;
  const negativeMatcherBeginning = `^${negativeMatcher}`;
  const fn = (textarea, setValue) => {
    const {
      value,
      selectionStart,
      selectionEnd
    } = textarea;
    if (selectionStart === selectionEnd) {
      // No selection:
      const startOfLineBefore = value.substring(0, selectionStart).lastIndexOf('\n');
      if (new RegExp(matchBeginning).test(value.substring(startOfLineBefore + 1))) {
        // Remove list
        const listItem = typeof _listItem === 'function' ? _listItem() : _listItem;
        const n = listItem.length + 1; // +1 for the space after
        const indentation = getIndentation(value.substring(startOfLineBefore + 1));
        const before = value.substring(0, startOfLineBefore);
        const after = value.substring(startOfLineBefore + 1 + n + indentation);
        const newValue = `${before}${startOfLineBefore < 0 ? '' : '\n'}${after}`;
        const selStart = selectionStart - n - indentation;
        const selEnd = selectionEnd - n - indentation;
        setValue(newValue, {
          selectionStart: selStart,
          selectionEnd: selEnd
        });
      } else {
        // Add list
        const listItem = typeof _listItem === 'function' ? _listItem() : _listItem;
        const n = listItem.length + 1; // +1 for the space after
        const before = value.substring(0, startOfLineBefore + 1);
        const after = value.substring(startOfLineBefore + 1);
        const newValue = `${before}${listItem} ${after}`;
        const selStart = selectionStart + n;
        const selEnd = selectionEnd + n;
        setValue(newValue, {
          selectionStart: selStart,
          selectionEnd: selEnd
        });
      }
    } else {
      // Some text is selected:
      const selected = value.substring(selectionStart, selectionEnd);
      const firstNewLine = value.substring(0, selectionStart).lastIndexOf('\n');
      const before = value.substring(0, firstNewLine < 0 ? 0 : firstNewLine + 1);
      let offset = 0;

      // Offset is used to avoid adding a list if the user accidentally selects a new line character (which is easily done)
      if (selected.endsWith('\n')) offset = 1;else if (new RegExp(`\n${_matcher.trimEnd()}$`).test(selected)) {
        const lastLine = selected.substring(selected.lastIndexOf('\n'));
        offset = lastLine.length;
      } else if (new RegExp(`\n${matcher}$`).test(selected)) {
        const lastLine = selected.substring(selected.lastIndexOf('\n'));
        offset = lastLine.length;
      }
      const after = value.substring(selectionEnd - offset);
      const linesWithPrefix = value.substring(firstNewLine < 0 ? 0 : firstNewLine, selectionEnd - offset).split(new RegExp(`\n?${matcher}`));
      if (linesWithPrefix[0] === '') linesWithPrefix.shift();
      const selectedLines = value.substring(before.length, selectionEnd - offset).split('\n');

      // If all lines are already list items, we remove the list. If none or a subset of the lines are list items, make them all list items.
      if (linesWithPrefix.length === selectedLines.length && (linesWithPrefix.length > 1 || !linesWithPrefix[0].startsWith('\n'))) {
        // Remove list
        const newSelectedValue = linesWithPrefix.join('\n');
        const newValue = `${before}${newSelectedValue}${after}`;
        const selEnd = selectionEnd - (selected.length - newSelectedValue.length);
        setValue(newValue, {
          selectionStart,
          selectionEnd: selEnd
        });
      } else {
        // Add list
        let charsAdded = 0;
        let lastLn = getLine(before, firstNewLine).line;
        const newValue = `${before}${selectedLines.reduce((acc, ln, i) => {
          if (new RegExp(matchBeginning).test(ln)) {
            lastLn = ln;
            return `${acc}${i === 0 ? '' : '\n'}${ln}`;
          } else {
            const listItem = typeof _listItem === 'function' ? _listItem(lastLn) : _listItem;
            charsAdded += listItem.length + 1; // +1 for the space after

            lastLn = `${listItem} ${ln}`;
            return `${acc}${i === 0 ? '' : '\n'}${lastLn}`;
          }
        }, '')}${after}`;
        const selEnd = selectionEnd + charsAdded;
        setValue(newValue, {
          selectionStart,
          selectionEnd: selEnd
        });
      }
    }
  };
  const events = {
    ...additionalEvents,
    keydown: (e, textarea, setValue, pressedKeys) => {
      if (disableAutoLists) return;

      // On Enter, if we are in a list, we either continue the list on the next line, or if the current item is an empty list item, stop the list.
      if (e.key === 'Enter' && !hasModifierKey(e)) {
        const {
          value,
          selectionStart,
          selectionEnd
        } = textarea;

        // Currently, if we have text selected, we don't handle the event
        if (selectionStart !== selectionEnd) return;
        const startOfLineBefore = value.substring(0, selectionStart).lastIndexOf('\n');
        const endOfCurrentLine = value.indexOf('\n', startOfLineBefore + 1);
        let line = '';
        if (startOfLineBefore < 0 && endOfCurrentLine < 0) line = value.substring(0, selectionStart); // First line
        else if (selectionStart > startOfLineBefore + 1) line = value.substring(startOfLineBefore + 1, endOfCurrentLine < 0 ? value.length : endOfCurrentLine);else return;
        if (new RegExp(matchFullLine).test(line)) {
          // Empty bullet: Stop list
          e.preventDefault();
          const isAtEnd = selectionEnd === value.length;
          const selStart = startOfLineBefore + 1;
          setValue(`${value.substring(0, selStart)}${value.substring(selectionStart)}`, {
            selectionStart: selStart,
            selectionEnd: selStart,
            scrollPos: isAtEnd ? textarea.scrollHeight : undefined
          });
        } else if (new RegExp(matchBeginning).test(line) && (!_negativeMatcher || !new RegExp(negativeMatcherBeginning).test(line))) {
          // Non-empty bullet: continue list
          e.preventDefault();
          const listItem = typeof _listItem === 'function' ? _listItem(line, {
            text: value,
            cursor: selectionStart,
            matcher: matchBeginning
          }) : _listItem;
          const n = listItem.length + 1; // +1 for the space after

          const isAtEnd = selectionEnd === value.length;
          const indentation = getIndentation(line);
          const selStart = selectionStart + 1 + n + indentation;
          setValue(`${value.substring(0, selectionStart)}\n${''.padStart(indentation, ' ')}${listItem} ${value.substring(selectionEnd)}`, {
            selectionStart: selStart,
            selectionEnd: selStart,
            scrollPos: isAtEnd ? textarea.scrollHeight : undefined
          });
        }
        // On Tab, indent or outdent list item
      } else if (e.key === 'Tab' && !hasModifierKey(e, {
        shiftKey: true
      })) {
        const {
          value,
          selectionStart,
          selectionEnd
        } = textarea;

        // Currently, if text is selected we don't handle this event. In the future it might be nice to indent/outdent all list items in the selection.
        if (selectionStart !== selectionEnd) return;
        const startOfLineBefore = value.substring(0, selectionStart).lastIndexOf('\n');
        const line = value.substring(startOfLineBefore + 1);
        if (new RegExp(matchBeginning).test(line) && (!_negativeMatcher || !new RegExp(negativeMatcherBeginning).test(line))) {
          e.preventDefault();
          if (!e.shiftKey) {
            const selStart = selectionStart + 4;
            setValue(`${value.substring(0, startOfLineBefore + 1)}    ${line}`, {
              selectionStart: selStart,
              selectionEnd: selStart
            });
          } else {
            const indentation = getIndentation(line);
            const after = value.substring(startOfLineBefore + 1);
            const selStart = selectionStart - Math.min(indentation, 4);
            setValue(`${value.substring(0, startOfLineBefore + 1)}${after.substring(Math.min(indentation, 4))}`, {
              selectionStart: selStart,
              selectionEnd: selStart
            });
          }
        }
        // Allow each list type to add additional keydown events if needed:
      } else if (additionalEvents?.keydown) additionalEvents.keydown(e, textarea, setValue, pressedKeys);
    }
  };
  return {
    fn,
    events
  };
};
export const unorderedListCommand = function () {
  let {
    disableAutoLists
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    key: '8',
    name: 'Unordered List',
    icon: 'BulletList',
    modifiers: isMac() ? 'cmd+shift' : 'ctrl+shift',
    ...build({
      disableAutoLists,
      listItem: line => {
        if (!line) return '-';
        const [, symbol] = /^(?: )*([-*+]) /.exec(line) || [];
        if (symbol === '-' || symbol === '*' || symbol === '+') return symbol;
        return '-';
      },
      matcher: `(?: )*[-*+]`,
      negativeMatcher: `(?: )*[-*+] \\[(?:x|\\s)\\]`
    })
  };
};
export const orderedListCommand = function () {
  let {
    disableAutoLists
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    key: '7',
    name: 'Ordered List',
    icon: 'NumberList',
    modifiers: isMac() ? 'cmd+shift' : 'ctrl+shift',
    ...build({
      disableAutoLists,
      listItem: (line, ctx) => {
        if (!line) return '1.';
        if (ctx) {
          const list = getCurrentList(ctx.text, ctx.cursor, ctx.matcher);
          if (list) {
            const lines = list.text.split('\n');
            if (lines.length > 1 && lines.every(line => /^(?: )*1\. /.test(line))) return '1.';
          }
        }
        const [, numStr] = /^(?: )*(\d+)\. /.exec(line) || [];
        const num = Number.parseInt(numStr);
        if (Number.isNaN(num)) return '1.';
        return `${num + 1}.`;
      },
      matcher: `(?: )*\\d+\\.`
    })
  };
};
export const taskListCommand = function () {
  let {
    disableAutoLists
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    key: '9',
    name: 'Task List',
    icon: 'CheckList',
    modifiers: isMac() ? 'cmd+shift' : 'ctrl+shift',
    ...build({
      disableAutoLists,
      listItem: line => {
        if (!line) return '- [ ]';
        const [, symbol] = /^(?: )*(-|\*|\+) \[(?:x|\s)\]/.exec(line) || [];
        if (symbol === '-' || symbol === '*' || symbol === '+') return `${symbol} [ ]`;
        return `- [ ]`;
      },
      matcher: `(?: )*[-*+] \\[(?:x|\\s)\\]`,
      additionalEvents: {
        keydown: (e, textarea, setValue) => {
          if (e.key === 'x') {
            const {
              value,
              selectionStart,
              selectionEnd
            } = textarea;
            const before = value.substring(0, selectionStart);
            const selected = value.substring(selectionStart, selectionEnd);
            const after = value.substring(selectionEnd);
            if (/[-*+] \[(\s|x)*$/i.test(before) && /^(\s|x)*\]/i.test(after)) {
              const open = before.lastIndexOf('[');
              const close = after.indexOf(']') + 1 + before.length + selected.length;
              const checkbox = value.substring(open, close);
              if (checkbox === '[x]') {
                e.preventDefault();
                e.stopPropagation();
                const newValue = `${value.substring(0, open)}[ ]${value.substring(close)}`;
                setValue(newValue, {
                  selectionStart,
                  selectionEnd
                });
              } else if (checkbox === '[ ]') {
                e.preventDefault();
                e.stopPropagation();
                const newValue = `${value.substring(0, open)}[x]${value.substring(close)}`;
                setValue(newValue, {
                  selectionStart,
                  selectionEnd
                });
              }
            } else if (toShortcutString(e) === `${isMac() ? 'cmd' : 'ctrl'}+shift`) {
              const startOfLineBefore = value.substring(0, selectionStart).lastIndexOf('\n');
              const line = value.substring(startOfLineBefore + 1);
              if (/^(?: )*[-*+] \[(?:\s|x)\](?: )+/i.test(line)) {
                const open = before.indexOf('[', startOfLineBefore + 1);
                const close = before.indexOf(']', open) + 1;
                const checkbox = before.substring(open, close);
                if (checkbox === '[x]') {
                  e.preventDefault();
                  e.stopPropagation();
                  const newValue = `${value.substring(0, open)}[ ]${value.substring(close)}`;
                  setValue(newValue, {
                    selectionStart,
                    selectionEnd
                  });
                } else if (checkbox === '[ ]') {
                  e.preventDefault();
                  e.stopPropagation();
                  const newValue = `${value.substring(0, open)}[x]${value.substring(close)}`;
                  setValue(newValue, {
                    selectionStart,
                    selectionEnd
                  });
                }
              }
            }
          }
        }
      }
    })
  };
};