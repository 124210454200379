import Spaceship from '@clubhouse/assets/png/spaceship-min.png';
import { EmptyState } from 'components/shared/EmptyState';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { PageContent } from 'components/shared/PageContent';
import { Init } from 'utils/boot';
import { useSetPageTitle } from 'utils/navigation/useSetPageTitle';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PageNotFoundContent = _ref => {
  let {
    actions,
    className
  } = _ref;
  return _jsx(EmptyState, {
    className: className,
    image: _jsx("img", {
      src: Spaceship,
      alt: "",
      style: {
        height: 300
      }
    }),
    title: "Oops, something went wrong!",
    actions: actions,
    size: EmptyState.Size.Large,
    children: "The page you are looking for was not found."
  });
};
PageNotFoundContent.displayName = "PageNotFoundContent";
export const ContentNotFound = _ref2 => {
  let {
    children,
    className
  } = _ref2;
  const Content = PageNotFoundContent;
  return _jsx(Content, {
    actions: children,
    className: className
  });
};
ContentNotFound.displayName = "ContentNotFound";
export const PageNotFound = () => {
  useSetPageTitle({
    title: '404 | ' + BRAND.NAME
  });
  return _jsx(PageContent, {
    children: _jsx(ContentNotFound, {})
  });
};
PageNotFound.displayName = "PageNotFound";
export const PageNotFoundWithInit = _ref3 => {
  let {
    renderId
  } = _ref3;
  return _jsx(ErrorBoundary, {
    pageName: "404",
    children: _jsx(Init, {
      page: "404",
      pageTitle: "404",
      renderId: renderId,
      children: _jsx(PageNotFound, {})
    })
  });
};
PageNotFoundWithInit.displayName = "PageNotFoundWithInit";