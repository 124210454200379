import { convertProfileStateToPermissionState } from 'components/shared/UserCohortAdapter';
export function convertLegacyPermissionToDLPermission(permission) {
  return {
    __typename: 'Permission',
    id: permission.id,
    publicId: permission.id,
    displayName: permission.name,
    mentionName: permission.mention_name,
    email: permission.email_address ?? null,
    displayIcon: {
      __typename: 'Icon',
      id: '',
      thumbnailUrl: permission.display_icon?.url ?? ''
    },
    state: convertProfileStateToPermissionState(permission.state)
  };
}