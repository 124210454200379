export const ENTITLEMENTS = {
  ACTIVE_SEATS_CREATE: 'active-seats-create',
  LABS: 'labs',
  MEMBERS_CREATE: 'members-create',
  MILESTONE_CREATE: 'milestone-create',
  OBSERVERS_CREATE: 'observers-create',
  REPORTS_PAGE_ADVANCED: 'reports-page-advanced',
  REPORTS_PAGE_BASIC: 'reports-page-basic',
  ROADMAP: 'roadmap',
  ROLE_CREATE_MEMBER: 'role-create-member',
  ROLE_CREATE_OBSERVER: 'role-create-observer',
  TEAMS_CREATE: 'teams-create',
  WORKFLOWS_CREATE: 'workflows-create',
  WORKSPACES_CREATE: 'workspaces-create',
  WRITE: 'write',
  CUSTOM_FIELDS: 'true-custom-fields',
  TRUE_CUSTOM_FIELDS_CREATE: 'true-custom-fields-create',
  DOC_SUGGESTIONS: 'docs-suggestions',
  DOCS_PUBLIC_DOCS: 'docs-public-docs',
  PUBLIC_DOCS_CREATE: 'public-docs-create',
  OVERLAPPING_PRS: 'overlapping-prs',
  AUTOMATIONS: 'automations'
};
export const ENTITLEMENT_VIOLATION_RESOLUTION = {
  [ENTITLEMENTS.ACTIVE_SEATS_CREATE]: 'Reduce active seats',
  [ENTITLEMENTS.MEMBERS_CREATE]: 'Convert members to admins',
  [ENTITLEMENTS.MILESTONE_CREATE]: 'Delete milestones',
  [ENTITLEMENTS.OBSERVERS_CREATE]: 'Convert observers to admins',
  [ENTITLEMENTS.TEAMS_CREATE]: 'Archive teams',
  [ENTITLEMENTS.WORKFLOWS_CREATE]: 'Delete workflows',
  [ENTITLEMENTS.WORKSPACES_CREATE]: 'Disable workspaces',
  [ENTITLEMENTS.TRUE_CUSTOM_FIELDS_CREATE]: 'Delete Custom Fields',
  [ENTITLEMENTS.PUBLIC_DOCS_CREATE]: 'Unpublish public docs'
};