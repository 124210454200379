import Url from './url';
export function development() {
  return process.env.ENV_NAME === 'development' || process.env.ENV_NAME === 'debug';
}
export function staging() {
  return process.env.ENV_NAME === 'staging';
}
export function production() {
  return process.env.ENV_NAME === 'production';
}
export function testEnvironment() {
  return process.env.ENV_NAME === 'test';
}
export function internal() {
  const isInternal = Url.getCurrentSlug() === 'internal';
  if (testEnvironment()) return false;
  return [development(), staging(), production() && isInternal].some(condition => condition === true);
}