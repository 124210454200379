import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { isFigmaLink } from '@clubhouse/shared/utils/figmaLiveEmbed';
import { MIRO_LINK_PATTERN } from '@clubhouse/shared/utils/miroLiveEmbed';
export const getSelection = _ref => {
  let {
    value,
    selectionStart,
    selectionEnd
  } = _ref;
  const before = value.substring(0, selectionStart);
  const selected = value.substring(selectionStart, selectionEnd);
  const after = value.substring(selectionEnd);
  return {
    before,
    selected,
    after,
    selectionStart,
    selectionEnd,
    value
  };
};
export const getLine = (value, cursorIndex) => {
  const startOfLine = value.substring(0, cursorIndex).lastIndexOf('\n') + 1;
  let endOfLine = value.indexOf('\n', startOfLine);
  if (endOfLine < 0) endOfLine = value.length;
  const line = value.substring(startOfLine, endOfLine);
  return {
    line,
    startOfLine,
    endOfLine
  };
};
export const getLines = (value, cursorStart, cursorEnd) => {
  const startOfFirstLine = value.substring(0, cursorStart).lastIndexOf('\n') + 1;
  let endOfLastLine = value.indexOf('\n', cursorEnd);
  if (endOfLastLine < 0) endOfLastLine = value.length;
  const allLines = value.substring(startOfFirstLine, endOfLastLine).split('\n');
  return {
    lines: allLines,
    startOfFirstLine,
    endOfLastLine,
    before: value.substring(0, startOfFirstLine),
    after: value.substring(endOfLastLine)
  };
};
export const isParseableUrl = function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  try {
    new URL(text);
    return true;
  } catch {}
  return false;
};
export const isValidUrl = function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (!/^https?:\/\/[^\n]*$/i.test(text)) return false;
  return isParseableUrl(text);
};
export const isEmbeddableLink = text => {
  if (isFigmaLink(text)) return true;
  if (MIRO_LINK_PATTERN.test(text)) return true;
  return false;
};
export const isStoryLink = text => {
  try {
    const url = new URL(text);
    return url.hostname === window.location.hostname && /\/story\/(?:\d+)\/?/.test(url.pathname);
  } catch {
    return false;
  }
};
export const getStoryReferenceFromUrl = text => {
  try {
    const url = new URL(text);
    if (url.hostname !== window.location.hostname) return null;
    const [, storyId] = /\/story\/(\d+)(?:\/|$|\?|#)/.exec(url.pathname) ?? [];
    if (!storyId) return null;
    return `sc-${storyId}`;
  } catch {
    return null;
  }
};
export const isSupportedEntityLink = text => {
  const url = new URL(text);
  return url.hostname === window.location.hostname && /\/(?:epic|story)\/(?:\d+)\/?/.test(url.pathname);
};
export function findTables(value) {
  const tables = [];
  let currTable = null;
  const lines = value.split('\n');
  let pos = 0;
  for (let i = 0; i < lines.length; ++i) {
    const line = lines[i];
    if (!currTable && /^\|[ -:|]+\|$/.test(line) && i > 0) {
      const prevLine = lines[i - 1];
      if (/^\|.+\|$/.test(prevLine)) {
        currTable = {
          start: pos - prevLine.length - 1,
          end: 0,
          cols: line.split(/[ :-]+/).length - 1
        };
      }
    } else if (currTable && !/^\|/.test(line)) {
      currTable.end = pos;
      tables.push(currTable);
      currTable = null;
    }
    pos += line.length + 1;
  }
  if (currTable) {
    currTable.end = value.length;
    tables.push(currTable);
  }
  return tables;
}
export function findTable(value, cursor) {
  const tables = findTables(value);
  const table = tables.find(table => cursor >= table.start && cursor <= table.end) || null;
  return table;
}
export const getCurrentList = (text, cursor, listItemMatcher) => {
  const re = new RegExp(listItemMatcher);
  let line = getLine(text, cursor);
  if (!re.test(line.line)) return null;
  let listStart = line.startOfLine;
  let listEnd = line.endOfLine;
  while (re.test(line.line) && line.startOfLine >= 0) {
    listStart = line.startOfLine;
    line = getLine(text, line.startOfLine - 1);
    if (listStart === 0) break;
  }
  line = getLine(text, cursor);
  while (re.test(line.line) && line.endOfLine <= text.length) {
    listEnd = line.endOfLine;
    line = getLine(text, line.endOfLine + 1);
    if (line.endOfLine === text.length) break;
  }
  if (listStart === listEnd || listStart < 0 || listEnd < 0) return null;
  const listContent = text.substring(listStart, listEnd);
  return {
    text: listContent,
    listStart,
    listEnd
  };
};
export const getIndentation = line => line.length - line.trimStart().length;
const getCodeBlockSyntax = text => {
  const [, match] = /^(?:[ ]{,3})?(`{3,}|~{3,})/.exec(text) || [];
  return match || null;
};
export const hasOpenCodeBlock = text => {
  const {
    lines
  } = getLines(text, 0, text.length);
  let opener = null;
  for (const line of lines) {
    const codeBlock = getCodeBlockSyntax(line);
    if (!opener && codeBlock) opener = codeBlock;else if (opener && codeBlock?.trim() && codeBlock.startsWith(opener)) opener = null;else if (opener && /^[ ]{4,}/.test(opener) && !codeBlock) opener = null;
  }
  return !!opener;
};
export const hasOpenInlineCode = text => {
  const {
    lines
  } = getLines(text, 0, text.length);
  const currentLine = lines[lines.length - 1];
  let opener = null;
  const matcher = /(`{1,2})/g;
  let match = matcher.exec(currentLine);
  while (match) {
    if (!opener) opener = match[1];else if (opener && match[1] === opener) opener = null;
    match = matcher.exec(currentLine);
  }
  return !!opener;
};