import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportTimeSpent = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return _jsxs("svg", {
    width: width,
    height: width,
    viewBox: "0 0 46 46",
    fill: "none",
    children: [_jsx("path", {
      opacity: "0.2",
      d: "M4.73994 6.66666H10.8799C11.3803 6.66666 11.8602 6.86543 12.214 7.21925C12.5678 7.57307 12.7666 8.05295 12.7666 8.55332V16.0867H2.85327V8.53332C2.85854 8.03642 3.05964 7.56166 3.41288 7.21216C3.76613 6.86265 4.24301 6.66663 4.73994 6.66666Z",
      fill: "#7F7F7F"
    }), _jsx("path", {
      d: "M12.7666 16.0933H2.85327V25.5333H12.7666V16.0933Z",
      fill: "#ADC2DF"
    }), _jsx("path", {
      opacity: "0.4",
      d: "M2.85327 25.54H12.7666V33.06C12.7666 33.5675 12.565 34.0541 12.2062 34.4129C11.8474 34.7718 11.3607 34.9733 10.8533 34.9733H4.7666C4.25916 34.9733 3.77249 34.7718 3.41367 34.4129C3.05485 34.0541 2.85327 33.5675 2.85327 33.06V25.54Z",
      fill: "#FF7F0F"
    }), _jsx("path", {
      opacity: "0.2",
      d: "M29.6668 6.66667H35.8001C36.2936 6.67187 36.7653 6.87021 37.1143 7.21916C37.4632 7.5681 37.6616 8.03988 37.6668 8.53334V16.0867H27.7734V8.53334C27.7787 8.03528 27.9807 7.55951 28.3354 7.20982C28.6901 6.86013 29.1687 6.66488 29.6668 6.66667Z",
      fill: "#7F7F7F"
    }), _jsx("path", {
      d: "M37.6868 16.0933H27.7734V25.5333H37.6868V16.0933Z",
      fill: "#ADC2DF"
    }), _jsx("path", {
      opacity: "0.4",
      d: "M27.7734 25.54H37.6667V33.06C37.6667 33.5675 37.4652 34.0541 37.1063 34.4129C36.7475 34.7718 36.2609 34.9733 35.7534 34.9733H29.6668C29.1593 34.9733 28.6726 34.7718 28.3138 34.4129C27.955 34.0541 27.7534 33.5675 27.7534 33.06V25.54H27.7734Z",
      fill: "#FF7F0F"
    }), _jsx("path", {
      opacity: "0.2",
      d: "M17.1333 6.66667H23.2733C23.7737 6.66667 24.2536 6.86544 24.6074 7.21926C24.9612 7.57308 25.16 8.05296 25.16 8.55333V19.9267H15.2466V8.53333C15.2519 8.03643 15.453 7.56167 15.8063 7.21217C16.1595 6.86267 16.6364 6.66664 17.1333 6.66667Z",
      fill: "#7F7F7F"
    }), _jsx("path", {
      d: "M25.16 19.96H15.2466V29.4H25.16V19.96Z",
      fill: "#ADC2DF"
    }), _jsx("path", {
      opacity: "0.4",
      d: "M15.2466 29.4067H25.16V33.0667C25.16 33.5741 24.9584 34.0608 24.5996 34.4196C24.2407 34.7784 23.7541 34.98 23.2466 34.98H17.16C16.6525 34.98 16.1659 34.7784 15.807 34.4196C15.4482 34.0608 15.2466 33.5741 15.2466 33.0667V29.4067Z",
      fill: "#FF7F0F"
    })]
  });
};
ReportTimeSpent.displayName = "ReportTimeSpent";