import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function Divider() {
  return _jsx("div", {
    children: _jsx("hr", {
      style: {
        height: 0,
        margin: 0,
        padding: 0,
        borderTopStyle: 'solid',
        borderBottomStyle: 'none'
      }
    })
  });
}
Divider.displayName = "Divider";