import Logo from '@clubhouse/assets/png/third-party-logos/miro_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Miro() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.MIRO
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Miro"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Miro Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Turn any link to a public ", _jsx(ExternalLink, {
          href: "https://www.miro.com/",
          children: "Miro"
        }), " board within any markdown text field in Shortcut into an embedded live preview for easier design collaboration."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_MIRO,
          children: "Learn more"
        }), " on how Miro board embeds work in Shortcut."]
      })]
    })]
  });
}
Miro.displayName = "Miro";