import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DoneChip = () => {
  const story = useContext(StoryContext);
  if (!story || !story.completed) return null;
  return _jsx(StoryCardInternal.Chips.DoneChip, {
    completed: story.completed
  });
};
DoneChip.displayName = "DoneChip";