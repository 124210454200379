import "core-js/modules/es.array.push.js";
import { StatusIcon } from '@useshortcut/shapes-ds';
import uniqueId from 'lodash/uniqueId';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import { startCase } from 'utils/nodash';
import EpicModel from './epic';
import OrganizationModel from './organization'; // TODO Fix circular dep
import Collection from '../_frontloader/collection';
import Backend from '../modules/backend';
import Constants from '../modules/constants';
import Log from '../modules/log';
import Utils from '../modules/utils';
import View from '../modules/view';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};

/*

Example EpicState:

{
  'description': ''
  'entity_type': 'epic-state'
  'color': ''
  'name': 'to do'
  'type': 'unstarted' | 'started' | 'done'
  'updated_at': '2018-05-02T22:35:04Z'
  'id': 500042483
  'position': 0
  'created_at': '2018-05-02T22:35:04Z'
}

*/

Collection.create('EpicState', exports);
exports.normalize = epicState => {
  // @amcd 2022-06-03: The comments below are no longer accurate.
  // There is a UI for modifying the epic state name, so we shouldn't be modifying the user provided names.
  // But unclear if we can simply remove this or not...

  // This should already be lowercase, but saving as a
  // different prop as 'name' is already used around
  // the app and will make the transition easier
  epicState.lowercase_name = epicState.name.toLowerCase();
  // This is temporary for fixtured data until we add
  // an interface to add/edit types to change the name
  // or decide to run a migration to convert it over
  epicState.name = startCase(epicState.name);
  _decorateEpicStateTypes(epicState);
};
exports.isValid = epicState => {
  return Utils.hasKeys(epicState, ['id', 'name', 'type', 'position']);
};
function _decorateEpicStateTypes(epicState) {
  const {
    UNSTARTED,
    STARTED,
    DONE
  } = Constants.WORKFLOW_STATE_TYPES;
  switch (epicState.type) {
    case UNSTARTED:
      {
        epicState.icon = 'fa-flag-o';
        epicState.customIcon = 'icon-flag-outlined';
        break;
      }
    case STARTED:
      {
        epicState.icon = 'fa-flag';
        epicState.customIcon = 'icon-flag';
        break;
      }
    case DONE:
      {
        epicState.icon = 'fa-flag-checkered';
        epicState.customIcon = 'icon-flag-checkered';
        break;
      }
  }
}
exports.getStateTypes = () => {
  const {
    UNSTARTED,
    STARTED,
    DONE
  } = Constants.WORKFLOW_STATE_TYPES;
  return [UNSTARTED, STARTED, DONE];
};
exports.getDefaultStateID = function () {
  let org = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : OrganizationModel.getCurrent();
  return _.get(org, 'epic_workflow.default_epic_state_id');
};
exports.getDefaultState = function () {
  let org = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : OrganizationModel.getCurrent();
  return exports.getById(exports.getDefaultStateID(org));
};
exports.getState = epic => {
  return exports.getById(epic.epic_state_id);
};
exports.getStateType = epic => {
  return _.get(exports.getState(epic), 'type');
};
exports.getPosition = epic => {
  return _.get(exports.getState(epic), 'position');
};
exports.getFirstStateByType = type => {
  return _.minBy(exports.filter({
    type
  }), 'position');
};
exports.getFirstUnstarted = () => {
  return exports.getFirstStateByType(Constants.WORKFLOW_STATE_TYPES.UNSTARTED);
};
exports.getFirstStarted = () => {
  return exports.getFirstStateByType(Constants.WORKFLOW_STATE_TYPES.STARTED);
};
exports.getFirstDone = () => {
  return exports.getFirstStateByType(Constants.WORKFLOW_STATE_TYPES.DONE);
};
exports.getAllIdsOfType = type => {
  return _.map(exports.getAllOfType(type), 'id');
};
exports.getAllOfType = type => {
  return _.sortBy(exports.filter({
    type
  }), 'position');
};
exports.isOnlyOneOfType = type => {
  return exports.filter({
    type
  }).length === 1;
};
exports.isSafeToDelete = epicStateId => {
  const epicState = exports.getById(epicStateId);
  return exports.isValid(epicState) && !exports.isOnlyOneOfType(epicState.type) && !EpicModel.hasEpicsInWorkflowState(epicState) && exports.getDefaultStateID() !== epicStateId;
};
let customIcons;
exports.getRenderedCustomIcon = iconName => {
  customIcons = customIcons || {
    'icon-flag-outlined': renderComponentToString(_jsx("span", {
      style: {
        display: 'inline-flex'
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 18,
        children: _jsx(StatusIcon, {
          icon: "Unstarted"
        })
      })
    })),
    'icon-flag': renderComponentToString(_jsx("span", {
      style: {
        display: 'inline-flex'
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 18,
        children: _jsx(StatusIcon, {
          icon: "Started"
        })
      })
    })),
    'icon-flag-checkered': renderComponentToString(_jsx("span", {
      style: {
        display: 'inline-flex'
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 18,
        fill: "var(--iconGreenColor)",
        children: _jsx(StatusIcon, {
          icon: "Done"
        })
      })
    }))
  };
  return customIcons[iconName];
};
exports.getItemsForAutocomplete = selectedId => {
  return exports.getStateTypes().reduce((items, type) => {
    const states = exports.getAllOfType(type);
    if (states.length) {
      if (items.length) {
        items.push({
          hr: true
        });
      }
      items.push({
        html: '<div class="caption">' + _.startCase(type) + '</div>'
      });
      states.forEach(state => {
        items.push({
          customIconLeft: exports.getRenderedCustomIcon(state.customIcon),
          name: state.name,
          value: state,
          className: selectedId === state.id ? 'selected' : '',
          iconRight: 'fa-check'
        });
      });
    }
    return items;
  }, []);
};
exports.setDefault = (id, callback) => {
  Backend.put('/api/private/epic-workflow', {
    data: {
      default_epic_state_id: id
    },
    onComplete: (res, xhr) => {
      // This returns the parent entity wrapping the states that we don't
      // have a concept of on the front end, so we request the org to get a
      // fresh copy of all states and the default id set on the organization
      if (xhr.status === 200) {
        OrganizationModel.fetchCurrent(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.saveNew = (epicState, callback) => {
  Backend.post('/api/private/epic-workflow/states', {
    data: epicState,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'epicCreated');
    }
  });
};
exports.renderUnstartedIcon = function () {
  let width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 16;
  return View.renderComponentDelayed({
    wrapperTag: 'span',
    componentKey: uniqueId(),
    cssStyles: 'position: relative; top: 2px; display: inline-flex; margin-right: 4px',
    component: _jsx(DeprecatedIconAdapter, {
      width: width,
      children: _jsx(StatusIcon, {
        icon: "Unstarted"
      })
    })
  }).html;
};
exports.renderStartedIcon = function () {
  let width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 16;
  return View.renderComponentDelayed({
    wrapperTag: 'span',
    componentKey: uniqueId(),
    cssStyles: 'position: relative; top: 2px; display: inline-flex; margin-right: 4px',
    component: _jsx(DeprecatedIconAdapter, {
      width: width,
      children: _jsx(StatusIcon, {
        icon: "Started"
      })
    })
  }).html;
};
exports.renderDoneIcon = function () {
  let width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 16;
  return View.renderComponentDelayed({
    wrapperTag: 'span',
    componentKey: uniqueId(),
    cssStyles: 'position: relative; top: 2px; display: inline-flex; margin-right: 4px',
    component: _jsx(DeprecatedIconAdapter, {
      fill: "var(--iconGreenColor)",
      width: width,
      children: _jsx(StatusIcon, {
        icon: "Done"
      })
    })
  }).html;
};
exports.saveUpdate = (epicStateId, updates, callback) => {
  Backend.put('/api/private/epic-workflow/states/' + epicStateId, {
    data: updates,
    onComplete: (res, xhr) => {
      // We request all states here on the org entity, as positions
      // on other states can change on top of the "changed" state.
      if (xhr.status === 200) {
        OrganizationModel.fetchCurrent(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteState = (epicState, callback) => {
  Backend.delete('/api/private/epic-workflow/states/' + epicState.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(epicState, res, xhr, callback);
    }
  });
};

// This is temporary for users with existing filters set
// while we transition from fixed epic states to entities.
exports.transformLegacyIds = function () {
  let ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const mapping = {
    0: exports.getFirstUnstarted().id,
    1: exports.getFirstStarted().id,
    2: exports.getFirstDone().id
  };
  const replacements = ids.map(id => {
    const replacement = mapping[id];
    return replacement ? replacement : id;
  });

  // We do a check here instead of within the map function to only make a single call.
  if (_.intersection(Object.keys(mapping).map(Utils.toNumber), ids).length) {
    Log.log('Deprecated Epic States transformed to reference Epic State entity', {
      old: ids,
      new: replacements
    });
  }
  return replacements;
};
export { exports as default };