export function getRandomFloatExclusive(max) {
  let min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Math.random() * (max - min) + min;
}
export function getRandomIntegerExclusive(max) {
  let min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Math.floor(getRandomFloatExclusive(max, min));
}
export function getRandomInteger(max) {
  let min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return getRandomIntegerExclusive(max + 1, min);
}