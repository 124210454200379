import Async from '../modules/async';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import OrganizationModel from './organization';
import PaymentPlan2Model from './paymentPlan2';
import UserModel from './user';
import rearg from 'lodash/rearg';
import BaseUtils from '../_frontloader/baseUtils';
const exports = {};

/*

Invite entity:

{
  created_at: "2015-11-05T03:08:14Z"
  email: "andrew+3@shortcut.com"
  id: "458213e0-c6f8-4331-968f-afb4275690eb"
  used: false
  used_at: null
}

*/

Collection.create('Invite', exports);
exports.normalize = invite => {
  invite.org_id = invite.org_id || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  if (!invite.role) {
    invite.role = 'member';
  }
};
exports.formatDataForRequest = data => {
  const emails = _.compact(_.map(data.emails.split(/[\n,]/), email => {
    return _.trim(email).toLowerCase();
  }));
  const group_ids = _.compact(data.group_ids.split(','));
  return {
    invites: _.map(emails, email => {
      return {
        email,
        role: data.role,
        group_ids
      };
    })
  };
};
exports.allPending = () => {
  return exports.filter({
    used: false
  });
};
exports.allPendingForOrganization = orgID => {
  return exports.filter(invite => {
    return invite.used === false && invite.org_id === orgID || invite.organization && invite.organization.id === orgID;
  });
};
exports.allPendingObserversForOrg = orgID => {
  return exports.filter(invite => {
    return (invite.used === false && invite.org_id === orgID || invite.organization && invite.organization.id === orgID) && invite.role === 'observer';
  });
};
exports.fetchAll = callback => {
  let endpoint = '/api/private/invites';
  const permission = UserModel.getLoggedInUserPermission();
  if (Is.owner(permission)) {
    endpoint = '/api/private/owner/workspaces2/' + permission.organization.id + '/invites';
  }
  Backend.get(endpoint, {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.fetchAllPending = callback => {
  Backend.get('/api/private/user/invites', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.createInvites = function (data, callback) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  callback = _.isFunction(callback) ? callback : _.noop;
  // This API endpoint is hidden, but it exists. Trust the magic that is happening.
  Backend.post('/api/private/invites', _.assign({
    data,
    onComplete: res => {
      // We get back a special-case response that isn't an array of invite objects,
      // so we can't use a standardized response handler here.
      // We also request a new payment plan with the billable users count updated
      if (_.isArray(res)) {
        if (options.rejectErrorsInSuccessfulResponse && res?.[0]?.reason) {
          return callback(res);
        }
        Async.eachInParallelThenWithShortCircuiting([exports.fetchAll, PaymentPlan2Model.fetch], err => {
          if (err) {
            exports.defaultErrorHandler(res, callback);
          } else {
            callback(null, res);
            Event.trigger('invitesUpdated');
          }
        });
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  }, options));
};
exports.deleteInvite = (invite, callback, options) => {
  options = options || {};
  let endpoint = '/api/private/invites/' + invite.id;
  const permission = UserModel.getLoggedInUserPermission(options.organization_id);
  if (Is.owner(permission)) {
    endpoint = '/api/private/owner/workspaces2/' + permission.organization.id + '/invites/' + invite.id;
  }
  Backend.delete(endpoint, _.assign({
    onComplete: async (res, xhr) => {
      // We request a new payment plan with the billable users count updated
      await PaymentPlan2Model.Promises.fetch();
      exports.defaultDeleteHandler(invite, res, xhr, callback);
      Event.trigger('invitesUpdated');
    }
  }, options));
};
exports.resend = (invite, callback, options) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/invites/' + invite.id + '/resend', _.assign({
    onComplete: res => {
      if (res.success) {
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  }, options));
};
exports.accept = (id, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/user/invites/' + id + '/create-permission', {
    onComplete: (res, xhr) => {
      if (xhr.status === 201) {
        exports.remove({
          id
        });
        callback(null, res);
        Event.trigger('invitesUpdated');
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.createFromInviteLink = _ref => {
  let {
    inviteLinkId,
    email
  } = _ref;
  return new Promise((resolve, reject) => {
    Backend.post(`/api/private/invite-links/${inviteLinkId}/create-invite`, _.assign({
      data: {
        email
      },
      onComplete: invite => {
        // We do not want to use the default handler here, because this endpoint doesn't return an ID, which means Collectionize will error.
        if (invite?.entity_type === 'invite') {
          resolve(invite);
        } else {
          reject(invite);
        }
      }
    }));
  });
};
exports.Promises = {};
exports.Promises.fetchAll = () => new Promise(resolve => {
  let endpoint = '/api/private/invites';
  const permission = UserModel.getLoggedInUserPermission();
  if (Is.owner(permission)) {
    endpoint = '/api/private/owner/workspaces2/' + permission.organization.id + '/invites';
  }
  Backend.get(endpoint, {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, resolve);
    }
  });
});
exports.Promises.invite = data => new Promise((resolve, reject) => {
  Backend.post(`/api/private/invites`, {
    data: {
      invites: data
    },
    onComplete: res => {
      // We get back a special-case response that isn't an array of invite objects,
      // so we can't use a standardized response handler here.
      // We also request a new payment plan with the billable users count updated
      if (_.isArray(res)) {
        Async.eachInParallelThenWithShortCircuiting([exports.fetchAll, PaymentPlan2Model.fetch], err => {
          if (err) {
            exports.defaultErrorHandler(res, errorMsg => reject(errorMsg));
          } else {
            resolve(res);
            Event.trigger('invitesUpdated');
          }
        });
      } else {
        exports.defaultErrorHandler(res, errorMsg => reject(errorMsg));
      }
    }
  });
});
exports.Promises.createInvites = BaseUtils.promisify(rearg(exports.createInvites, [0, 2, 1]));
exports.Promises.resend = (invite, options) => new Promise((resolve, reject) => {
  Backend.post('/api/private/invites/' + invite.id + '/resend', _.assign({
    onComplete: res => {
      if (res.success) {
        resolve();
      } else {
        exports.defaultErrorHandler(res, reject);
      }
    }
  }, options));
});
exports.Promises.deleteInvite = (invite, options) => new Promise((resolve, reject) => {
  options = options || {};
  let endpoint = '/api/private/invites/' + invite.id;
  const permission = UserModel.getLoggedInUserPermission(options.organization_id);
  if (Is.owner(permission)) {
    endpoint = '/api/private/owner/workspaces2/' + permission.organization.id + '/invites/' + invite.id;
  }
  Backend.delete(endpoint, _.assign({
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(invite, res, xhr, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }
  }, options));
});
exports.Promises.registerUser = (inviteId, endpoint, data) => new Promise(resolve => Backend.post(`/api/private/invites/${inviteId}/${endpoint}`, {
  data,
  onComplete: res => {
    resolve(res);
  }
}));
export { exports as default };