import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getGlobal, setGlobal, useGlobal } from 'utils/globals';
const DEFAULT_RENDER_ID = uuidv4();
const PROFILE_RENDER_ID_KEY = 'Profile.renderId';
export const useProfileRenderId = () => {
  const getCurrentValue = useCallback(() => getGlobal(PROFILE_RENDER_ID_KEY) ?? DEFAULT_RENDER_ID, []);
  return useGlobal(PROFILE_RENDER_ID_KEY, getCurrentValue);
};
export const bumpProfileRenderId = () => {
  setGlobal(PROFILE_RENDER_ID_KEY, uuidv4());
};