import isElement from 'lodash/isElement';
import * as Event from '../_frontloader/event';
const exports = {};
exports.bind = element => {
  if (isElement(element)) {
    _bindElement(element);
  } else if (element && element.length > 0) {
    element.each(function () {
      _bindElement(this);
    });
  }
};
exports.destroy = element => {
  $(element).removeClass('is-autosize-bound').off('.textareaAutosize');
};
exports.destroyAll = () => {
  $('textarea.is-autosize-bound').off('.textareaAutosize');
};
exports.init = () => {
  Event.onlyOn('pageDestroy.Autosize', () => {
    exports.destroyAll();
  });
};
function _bindElement(element) {
  if (_isBound(element)) {
    return false;
  }
  const style = window.getComputedStyle(element, null);
  const heightOffset = _getHeightOffset(style);
  if (style.maxHeight !== 'none') {
    element.style.maxHeight = style.maxHeight;
  }
  $(element).addClass('is-autosize-bound').on('input.textareaAutosize', () => {
    _resize(element, heightOffset);
  });
  _resize(element, heightOffset);
}
function _isBound(element) {
  return $(element).hasClass('is-autosize-bound');
}
function _getHeightOffset(style) {
  if (style.boxSizing === 'content-box') {
    return -(Number.parseFloat(style.paddingTop) + Number.parseFloat(style.paddingBottom));
  } else {
    return Number.parseFloat(style.borderTopWidth) + Number.parseFloat(style.borderBottomWidth);
  }
}
function _findScrolledParent(element) {
  if (element.scrollTop) {
    return element;
  }
  if (element.parentNode) {
    return _findScrolledParent(element.parentNode);
  }
  return false;
}
function _resize(element, heightOffset) {
  const htmlTop = document.documentElement.scrollTop;
  const bodyTop = document.body.scrollTop;
  const scrolledParent = _findScrolledParent(element);
  const previousScrollTop = scrolledParent ? scrolledParent.scrollTop : 0;
  element.style.height = 'auto';
  // Prevent scrollbar bug
  // Ref: https://help.shortcut.com/agent/tickets/1657
  // element.style.overflowY = 'auto';
  // Comment line above because breaks undo on firefox:
  // https://app.shortcut.com/internal/story/83565/undo-text-keyboard-shortcuts-do-not-work-on-firefox

  const height = element.scrollHeight + heightOffset;
  const maxHeight = element.style.maxHeight ? Number.parseFloat(element.style.maxHeight) : false;
  element.style.overflowY = maxHeight && height > maxHeight ? 'auto' : 'hidden';
  element.style.height = '' + height + 'px';
  document.documentElement.scrollTop = htmlTop;
  document.body.scrollTop = bodyTop;
  if (scrolledParent) {
    scrolledParent.scrollTop = previousScrollTop;
  }
  Event.trigger('textareaAutosized');
}
export { exports as default };