import { useCallback } from 'react';
import { useBaseKeyboardGridNavigation } from '@clubhouse/shared/hooks';
import { focusCell } from '@clubhouse/shared/hooks/useBaseKeyboardGridNavigation/utils';
import { addDays, firstDayOfMonth, firstDayOfWeek, lastDayOfMonth, lastDayOfWeek, sameDayNextMonth, sameDayPreviousMonth } from '@clubhouse/shared/utils/date';
import { getTabbableChildren } from '@clubhouse/shared/utils/focus';
const getElementDate = el => el.getAttribute('data-date');
export function useDatepickerKeyboardNavigation(_ref) {
  let {
    gridRef,
    hidden,
    onSelect,
    onChangeMonth
  } = _ref;
  const onKeyDown = useCallback(_ref2 => {
    let {
      table,
      key,
      element,
      altKey
    } = _ref2;
    const date = getElementDate(element);
    if (!date) return false;
    let gotoDate;
    switch (key) {
      case 'Enter':
      case ' ':
        {
          if (date) {
            onSelect(date);
            return true;
          }
          return false;
        }
      case 'Tab':
        {
          if (element.getAttribute('tabindex') !== '0') {
            const activeEl = getTabbableChildren(table)[0];
            if (activeEl) activeEl.focus();
          }
          return false;
        }
      case 'ArrowUp':
        {
          if (altKey) gotoDate = sameDayPreviousMonth(date);else gotoDate = addDays(date, -7);
          break;
        }
      case 'ArrowDown':
        {
          if (altKey) gotoDate = sameDayNextMonth(date);else gotoDate = addDays(date, 7);
          break;
        }
      case 'ArrowLeft':
        {
          if (altKey) gotoDate = firstDayOfWeek(date);else gotoDate = addDays(date, -1);
          break;
        }
      case 'ArrowRight':
        {
          if (altKey) gotoDate = lastDayOfWeek(date);else gotoDate = addDays(date, 1);
          break;
        }
      case 'Home':
        {
          if (altKey) gotoDate = firstDayOfMonth(date);else gotoDate = firstDayOfWeek(date);
          break;
        }
      case 'End':
        {
          if (altKey) gotoDate = lastDayOfMonth(date);else gotoDate = lastDayOfWeek(date);
          break;
        }
      case 'PageUp':
        {
          gotoDate = sameDayPreviousMonth(date);
          break;
        }
      case 'PageDown':
        {
          gotoDate = sameDayNextMonth(date);
          break;
        }
      default:
        return false;
    }
    const gotoElement = table.querySelector(`[data-date="${gotoDate}"]`);
    if (gotoElement) {
      focusCell(gotoElement);
      return true;
    } else if (gotoDate) {
      const otherTable = onChangeMonth({
        from: date,
        to: gotoDate
      });
      if (otherTable) {
        focusCell(otherTable.querySelector(`[data-date="${gotoDate}"]`));
      }
      return true;
    }
    return false;
  }, [onSelect, onChangeMonth]);
  const {
    getGridProps,
    getGridCellProps,
    ...props
  } = useBaseKeyboardGridNavigation({
    onKeyDown
  });
  return {
    ...props,
    getGridProps: () => {
      const {
        ref,
        ...gridProps
      } = getGridProps();
      return {
        ...gridProps,
        ref: node => {
          if (typeof gridRef === 'function') gridRef(node);else if (gridRef && 'current' in gridRef) gridRef.current = node;
          ref.current = node;
        }
      };
    },
    getGridCellProps: _ref3 => {
      let {
        isSelected,
        isFocusTarget,
        date
      } = _ref3;
      return {
        ...getGridCellProps({
          isFocusTarget: !hidden && isFocusTarget
        }),
        'data-date': date,
        'aria-selected': isSelected
      };
    }
  };
}