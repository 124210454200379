import { useCallback, useRef, useState } from 'react';
export function useAsyncSelect(fetchData) {
  const lastInputValue = useRef();
  const [{
    sections,
    totalItemCount,
    filtered
  }, setData] = useState({
    sections: null,
    totalItemCount: 0,
    filtered: false
  });
  const fetchAndSetData = useCallback(async function () {
    let inputValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    lastInputValue.current = inputValue;
    const data = await fetchData({
      inputValue
    });
    setData({
      ...data,
      filtered: Boolean(inputValue?.trim()?.length)
    });
  }, [fetchData]);
  const refetchSections = useCallback(() => {
    fetchAndSetData(/*lastInputValue.current*/);
  }, [fetchAndSetData]);
  return {
    sections,
    totalItemCount,
    fetchSections: fetchAndSetData,
    filtered,
    refetchSections
  };
}