import { useMemo } from 'react';
import { SelectOptionChecked, SelectOptionDivider } from '@clubhouse/shared/components/Select';
import { GROUP_BY_NONE } from './utils';
export const GROUP_BY = {
  NONE: GROUP_BY_NONE,
  STATE: 'state',
  OWNER: 'owners',
  EPIC: 'epics',
  TEAM: 'team',
  TYPE: 'type',
  WORKFLOW: 'workflow',
  ITERATION: 'iteration',
  SKILLSET: 'skillSet',
  TECHNICAL_AREA: 'technicalArea',
  PRIORITY: 'priority',
  SEVERITY: 'severity',
  PRODUCT_AREA: 'productArea'
};
const getStaticGroupByOptions = () => [{
  value: GROUP_BY.STATE,
  name: 'State',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.OWNER,
  name: 'Owner',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.EPIC,
  name: 'Epic',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.TEAM,
  name: 'Team',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.TYPE,
  name: 'Type',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.WORKFLOW,
  name: 'Workflow',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.ITERATION,
  name: 'Iteration',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.SKILLSET,
  name: 'Skill Set',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.TECHNICAL_AREA,
  name: 'Technical Area',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.PRIORITY,
  name: 'Priority',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.SEVERITY,
  name: 'Severity',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.PRODUCT_AREA,
  name: 'Product Area',
  Component: SelectOptionChecked
}];
export const getGroupByOptions = exclude => {
  const values = [...getStaticGroupByOptions()].filter(item => !exclude || !exclude.includes(item.value)).sort((a, b) => a.name.localeCompare(b.name));
  return [{
    value: GROUP_BY.NONE,
    name: 'None'
  }, {
    Component: SelectOptionDivider,
    key: 'divider'
  }, ...values];
};
export const useGroupByOptions = exclude => {
  return useMemo(() => getGroupByOptions(exclude), [exclude]);
};