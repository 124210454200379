const isValidEmail = value => /^\S+@\S+\.\S+$/.test(value);
const includesMultipleEmails = value => value.split(/[,\s]+/).filter(isValidEmail).length > 1;
export const isTrimmed = function () {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return value.trim().length === value.length;
};
export const isSingleEmailOrEmpty = function () {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  value = value.trim();
  if (includesMultipleEmails(value)) return 'Only add a single email per row';
  if (value === '') return true;
  if (!isValidEmail(value)) return 'This must be a valid email address';
  return true;
};