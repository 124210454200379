import { openHelp } from 'utils/help';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { getSlugPath } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const ReportsHelpFooter = () => _jsxs(_Fragment, {
  children: ['Any questions? ', _jsxs("a", {
    href: KNOWLEDGE_BASE_SLUGS.HELP_HOME,
    target: "_blank",
    rel: "noreferrer",
    children: ["Check out our Help Center documentation", _jsx("span", {
      className: "fa fa-external-link"
    })]
  }), ' or ', _jsx("a", {
    href: `${getSlugPath()}/help`,
    onClick: openHelp,
    children: "get in touch!"
  })]
});