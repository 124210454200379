export const mediaQueries = {
  maxWidth600: '(max-width: 600px)',
  minWidth600: '(min-width: 601px)',
  maxWidth768: '(max-width: 768px)',
  minWidth768: '(min-width: 769px)',
  maxWidth1024: '(max-width: 1024px)',
  minWidth1024: '(min-width: 1025px)',
  maxWidth1440: '(max-width: 1440px)',
  minWidth1440: '(min-width: 1441px)',
  maxWidth1920: '(max-width: 1920px)',
  minWidth1920: '(min-width: 1921px)'
};
export const mixins = {
  maxHeight: height => `(max-height: ${height}px)`,
  minHeight: height => `(min-height: ${height}px)`,
  openSans: "font-family: 'Open Sans', Helvetica, Arial, sans-serif;",
  bold: function (fontSize, lineHeight) {
    return `
        ${this.openSans};
        font-size: ${fontSize};
        font-weight: 700;
        line-height: ${lineHeight};
    `;
  }
};