import { Helmet } from 'react-helmet';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StackOverflowPixel = _ref => {
  let {
    xsp
  } = _ref;
  // We only want to prevent the pixel from rendering when analytics consent is explicitly set to false.
  if (window.ketchConsent?.analytics === false) {
    return null;
  }
  return _jsxs(Helmet, {
    children: [_jsx("script", {
      type: "text/javascript",
      children: `
          var axel = Math.random()+"";
          var a = axel * 10000000000000;
          var div = document.createElement('div');
          div.innerHTML = '<img src="https://pubads.g.doubleclick.net/activity;xsp=${xsp};ord='+ a +'?" width=1 height=1 border=0>';
          document.body.appendChild(div.firstChild);
        `
    }), _jsx("noscript", {
      children: `<img src="https://pubads.g.doubleclick.net/activity;xsp=${xsp};ord=1?" width=1 height=1 border=0>`
    })]
  });
};
StackOverflowPixel.displayName = "StackOverflowPixel";