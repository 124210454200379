import { GitHub } from 'pages/integrations/GitHub';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
const exports = {};
exports.isSaving = false;
exports.title = `GitHub Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/github';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(GitHub, 'github-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };