import { QueryStoriesTableRowsDocument } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { BacklogStoriesFragmentDoc } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { BacklogStoryFragmentDoc } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { WorkspaceHasBacklogStoriesDocument } from "../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { insertIf as insertArrayIf } from '@clubhouse/shared/utils/array';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { BulkEditField } from 'components/gql/bulkSelect/table/fields/BulkSelectField';
import { DeadlineField } from 'components/gql/stories/table/fields/DeadlineField';
import { EpicField } from 'components/gql/stories/table/fields/EpicField';
import { EstimateField } from 'components/gql/stories/table/fields/EstimateField';
import { IterationField } from 'components/gql/stories/table/fields/IterationField';
import { LabelsField } from 'components/gql/stories/table/fields/LabelsField';
import { MoreActionsField } from 'components/gql/stories/table/fields/MoreActionsField';
import { NameField } from 'components/gql/stories/table/fields/NameField';
import { OwnersField } from 'components/gql/stories/table/fields/OwnersField';
import { PositionField } from 'components/gql/stories/table/fields/PositionField';
import { PublicIdField } from 'components/gql/stories/table/fields/PublicIdField';
import { RequesterField } from 'components/gql/stories/table/fields/RequesterField';
import { StoryCreatedOnField } from 'components/gql/stories/table/fields/StoryCreatedOnField';
import { StoryLastUpdatedField } from 'components/gql/stories/table/fields/StoryLastUpdatedField';
import { StoryType } from 'components/gql/stories/table/fields/StoryTypeField';
import { TeamField } from 'components/gql/stories/table/fields/TeamField';
import { WorkflowField } from 'components/gql/stories/table/fields/WorkflowField';
import { customFieldColumnsByCanonicalName } from 'components/gql/stories/table/fields/customFieldColumnsByCanonicalName';
import { useQuery, useQueryWithPolling } from 'gql';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
import { useFetchMore } from 'gql/hooks/useFetchMore';
import { useWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
import { useWorkspaceSlug } from 'utils/navigation';
import { WorkflowStatesField } from './WorkflowStatesField';
const getColumns = _ref => {
  let {
    usesIterations,
    enabledCustomFields
  } = _ref;
  return [PositionField(), BulkEditField(), PublicIdField(), NameField(), StoryType(), TeamField(), OwnersField(), RequesterField(), EstimateField(), DeadlineField(), WorkflowField(), WorkflowStatesField(), EpicField(), ...insertArrayIf(usesIterations, [IterationField()]), LabelsField(), ...enabledCustomFields.map(customField => customFieldColumnsByCanonicalName[customField]()), StoryLastUpdatedField(), StoryCreatedOnField(), MoreActionsField()];
};
const WorkspaceHasBacklogStoriesQuery = WorkspaceHasBacklogStoriesDocument;
export const BacklogStoryFragment = BacklogStoryFragmentDoc;
export const BacklogStoriesFragment = BacklogStoriesFragmentDoc;
export const BacklogPageQuery = QueryStoriesTableRowsDocument;
const useIsEmptyWorkspace = () => {
  const {
    data,
    error,
    refetch
  } = useQuery(WorkspaceHasBacklogStoriesQuery, {
    variables: {
      slug: useWorkspaceSlug()
    }
  });
  const isLoading = !error && !data;
  return {
    isLoading,
    isEmpty: isLoading ? null : data?.workspace2?.hasBacklogStories === false,
    refetch
  };
};
export function useBacklogTableQuery(input, options) {
  const slug = useWorkspaceSlug();
  const currentPermissionId = useWorkspaceCurrentPermissionIdQuery();
  const {
    isLoading: isLoadingEmptyWorkspace,
    isEmpty: isEmptyWorkspace,
    refetch: refetchEmptyWorkspace
  } = useIsEmptyWorkspace();
  const {
    data,
    error,
    refetch,
    updateQuery,
    previousData,
    isInitialDataFromCache,
    fetchMore,
    startPolling,
    stopPolling
  } = useQueryWithPolling(BacklogPageQuery, {
    variables: {
      input,
      slug,
      ...(currentPermissionId ? {
        defaultsInput: {
          currentPermissionId
        }
      } : {})
    },
    skip: !currentPermissionId,
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    refetchWritePolicy: 'merge',
    ...options
  });
  useDataLayerUpdateEvent(() => refetch(), {
    filter: ['Story']
  });
  const dataToRender = data || previousData;
  const usesIterations = dataToRender?.workspace2?.features?.iterations?.enabled ?? false;
  const enabledCustomFieldEdges = dataToRender?.workspace2?.enabledCustomFields?.edges || emptyArray;
  const enabledCustomFields = useMemo(() => enabledCustomFieldEdges.map(_ref2 => {
    let {
      node
    } = _ref2;
    return node.canonicalName;
  }).filter(canonicalName => !!canonicalName), [enabledCustomFieldEdges]);
  const storyEdges = dataToRender?.workspace2?.stories?.edges || emptyArray;
  const stories = useMemo(() => storyEdges.map(_ref3 => {
    let {
      node
    } = _ref3;
    return node;
  }), [storyEdges]);
  const defaults = useMemo(() => ({
    workflowStateId: dataToRender?.workspace2?.backlogStoryDefaults?.workflowState?.publicId,
    workflowId: dataToRender?.workspace2?.backlogStoryDefaults?.workflowState?.workflow?.publicId,
    /* Typecasting necessary because for some reason this is `any` */
    teamId: dataToRender?.workspace2?.backlogStoryDefaults?.team?.publicId
  }), [dataToRender]);
  const isLoading = isLoadingEmptyWorkspace || !error && !dataToRender;
  const isStale = !data && !!previousData;
  const hasNoBacklogStoriesInWorkspace = !isLoadingEmptyWorkspace && isEmptyWorkspace;
  const columns = useMemo(() => getColumns({
    usesIterations,
    enabledCustomFields
  }), [usesIterations, enabledCustomFields]);
  const fetchMoreData = useFetchMore(fetchMore);
  return {
    columns,
    defaults,
    stories,
    pageInfo: dataToRender?.workspace2?.stories?.pageInfo,
    isLoading,
    isStale,
    error,
    refetch: useCallback(function () {
      refetchEmptyWorkspace();
      refetch(...arguments);
    }, [refetch, refetchEmptyWorkspace]),
    updateQuery,
    isInitialDataFromCache,
    data,
    previousData,
    hasNoBacklogStoriesInWorkspace,
    startPolling,
    stopPolling,
    ...fetchMoreData
  };
}