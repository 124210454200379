import * as Collapsible from '@radix-ui/react-collapsible';
import { useSlot, withSlot } from '@clubhouse/shared/hooks';
import { useAppState } from 'utils/appState';
import { logEvent } from 'utils/monitoring';
import { CollapseButton } from './CollapseButton';
import { DetailSectionContent } from './DetailSectionContent';
import { DetailSectionHeader } from './DetailSectionHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const _CollapsibleDetailSection = _ref => {
  let {
    children,
    buttonText,
    appStateKey,
    segment
  } = _ref;
  const [isOpen, setIsOpen] = useAppState({
    appStateKey,
    default: false
  });
  const handleOpenChange = isOpenNext => {
    if (segment) {
      const {
        event,
        eventSource,
        selectionNoun
      } = segment;
      const selection = `${isOpenNext ? 'expand' : 'collapse'}-${selectionNoun}`;
      logEvent(event, {
        selection,
        'event-source': eventSource
      });
    }
    setIsOpen(isOpenNext);
  };
  const TitleSlot = useSlot({
    children,
    name: 'Title'
  });
  const ContentSlot = useSlot({
    children,
    name: 'Content'
  });
  return _jsxs(Collapsible.Root, {
    open: isOpen,
    onOpenChange: handleOpenChange,
    children: [_jsx(DetailSectionHeader, {
      isCollapsed: !isOpen,
      left: _jsx(TitleSlot, {}),
      right: _jsx(Collapsible.Trigger, {
        asChild: true,
        children: _jsx(CollapseButton, {
          isOpen: isOpen,
          text: buttonText
        })
      })
    }), _jsx(Collapsible.Content, {
      children: _jsx(DetailSectionContent, {
        children: _jsx(ContentSlot, {})
      })
    })]
  });
};
_CollapsibleDetailSection.displayName = "_CollapsibleDetailSection";
export const CollapsibleDetailSection = withSlot(_CollapsibleDetailSection);