import { useRef } from 'react';
import { insertIf } from '../utils/object';
import { useKeyboardListNavigation } from './useKeyboardListNavigation';
export function useRadioGroup(_ref) {
  let {
    onSelect
  } = _ref;
  const radiogroupRef = useRef(null);
  const activeRef = useRef(null);
  const groupKeyboardProps = useKeyboardListNavigation({
    listRef: radiogroupRef,
    activeRef
  });
  return {
    getRadioGroupProps: _ref2 => {
      let {
        label
      } = _ref2;
      return {
        ...groupKeyboardProps,
        ref: radiogroupRef,
        role: 'radiogroup',
        ...insertIf(!!label, {
          'aria-label': label
        })
      };
    },
    getRadioProps: _ref3 => {
      let {
        checked,
        value,
        label,
        disabled
      } = _ref3;
      const onFocus = () => {
        if (!checked) onSelect(value);
      };
      const onClick = e => {
        e.preventDefault();
        if (!checked) onSelect(value);
      };
      return {
        ref: checked ? activeRef : undefined,
        role: 'radio',
        tabIndex: checked ? 0 : -1,
        'aria-checked': checked,
        'aria-disabled': disabled,
        ...insertIf(!!label, {
          'aria-label': label
        }),
        onFocus,
        onClick
      };
    }
  };
}