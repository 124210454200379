import { DropdownMenu } from '@clubhouse/shared/components/DropdownMenu';
import { capitalize } from '@clubhouse/shared/utils';
import { CheckboxItem, FilterLabel, SecondaryText, SelectContainer } from '../reports-v2/ReportSelectUtils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BurndownSelect = _ref => {
  let {
    onChange,
    value
  } = _ref;
  return _jsxs(SelectContainer, {
    children: [_jsx(FilterLabel, {
      children: "Type"
    }), _jsxs(DropdownMenu, {
      children: [_jsx(DropdownMenu.Trigger, {
        asChild: true,
        children: _jsx(DropdownMenu.ToggleButton, {
          children: capitalize(value)
        })
      }), _jsx(DropdownMenu.Portal, {
        children: _jsxs(DropdownMenu.Content, {
          align: "start",
          children: [_jsxs(CheckboxItem, {
            onSelect: () => onChange('daily'),
            checked: value === 'daily',
            children: ["Daily", _jsx(SecondaryText, {
              children: "Activity is aggregated at the end of each day."
            })]
          }), _jsxs(CheckboxItem, {
            onSelect: () => onChange('detailed'),
            checked: value === 'detailed',
            children: ["Detailed", _jsx(SecondaryText, {
              children: "Activity is shown when it occurs throughout the day."
            })]
          })]
        })
      })]
    })]
  });
};
BurndownSelect.displayName = "BurndownSelect";