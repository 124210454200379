import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ExternalLink(_ref) {
  let {
    children,
    href
  } = _ref;
  return _jsxs("a", {
    href: href,
    target: "_blank",
    rel: "noreferrer",
    children: [children, " ", _jsx("span", {
      className: "fa fa-external-link-square"
    })]
  });
}
ExternalLink.displayName = "ExternalLink";