import { useEffect, useState } from 'react';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, useToast } from '@clubhouse/shared/components/Toast';
import { useCancelablePromises } from '@clubhouse/shared/hooks';
import { isErr } from '@clubhouse/shared/utils/result';
import { BootState, useBootService } from 'utils/bootStateMachine';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { PAGE_NAMES, getIdForPage } from 'utils/navigation';
import { useReplaceWithCurrentBackgroundState } from 'utils/navigation/useReplaceWithCurrentBackgroundState';
import { closeCurrentStoryDialog, getActivityId, openOrFetchAndOpen, showLoadingStoryDialog } from 'utils/storyDialog';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OpenStoryDialogOnce = () => {
  const id = getIdForPage(PAGE_NAMES.STORY, window.location.pathname);
  const [state] = useBootService();
  const [hasRenderedLoadingState, setHasRenderedLoadingState] = useState(false);
  const {
    addToast
  } = useToast();
  const {
    makeCancelable
  } = useCancelablePromises();
  const replaceWithCurrentBackgroundLocation = useReplaceWithCurrentBackgroundState();
  const isFetched = useConsolidatedDataFetched();
  const [hasOpened, setHasOpened] = useState(false);
  useEffect(() => {
    if (id && !hasRenderedLoadingState && !hasOpened && state.matches(BootState.RenderingContainer)) {
      setHasRenderedLoadingState(true);
      showLoadingStoryDialog();
    }
  }, [hasOpened, hasRenderedLoadingState, id, state]);
  useEffect(() => {
    (async () => {
      if (id && !hasOpened && isFetched) {
        setHasOpened(true);
        try {
          const result = await makeCancelable(openOrFetchAndOpen(Number.parseInt(id), getActivityId(window.location.hash)));
          if (isErr(result)) {
            closeCurrentStoryDialog();
            addToast({
              Content: () => _jsxs(Spaced, {
                vertically: true,
                amount: 8,
                children: [_jsxs(ToastTitle, {
                  children: ["Story ", _jsxs("em", {
                    children: ["#", id]
                  }), " not found."]
                }), _jsx(ToastText, {
                  children: "This story was either deleted or you don't have access to it."
                })]
              }),
              timeout: 10_000,
              kind: 'warning'
            });
            replaceWithCurrentBackgroundLocation();
          }
        } catch (e) {}
      }
    })();
  }, [addToast, hasOpened, id, isFetched, makeCancelable, replaceWithCurrentBackgroundLocation]);
  return null;
};