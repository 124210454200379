import 'focus-visible';
import { createRoot } from 'react-dom/client';
import { Layout } from 'components/layout/Layout';
import { initializeMonitoring } from 'utils/monitoring';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
initializeMonitoring({
  debug: Boolean(process.env.DEBUG),
  env: process.env.ENV_NAME ?? null,
  deployId: process.env.DEPLOY_ID ?? undefined
});
const root = createRoot(document.getElementById('app-root'));
root.render(_jsx(Layout, {}));