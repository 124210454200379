import { COLORS } from '@clubhouse/shared/colors';
import { BadgeLabel } from 'components/shared/BadgeLabel';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const TableNameWithLabelsAndBlockersLabels = _ref => {
  let {
    labels
  } = _ref;
  const labelsDisplay = labels.map(label => _jsx(BadgeLabel, {
    label: {
      color: label.color ?? COLORS.YELLOW45,
      name: label.name
    }
  }, label.id));
  return _jsx(_Fragment, {
    children: labelsDisplay
  });
};