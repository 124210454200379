import { EpicContextMenuDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { ArchiveEpicContextMenuItem } from 'components/gql/epics/actions/ArchiveEpicAction';
import { DeleteEpicContextMenuItem } from 'components/gql/epics/actions/DeleteEpicAction';
import { getHref } from 'components/gql/epics/links';
import { useQuery } from 'gql';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging } from './useContextMenuWithLogging';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const EpicContextMenuQuery = EpicContextMenuDocument;
export const EpicContextMenu = _ref => {
  let {
    id
  } = _ref;
  const {
    data,
    error
  } = useQuery(EpicContextMenuQuery, {
    variables: {
      id
    }
  });
  if (!data && !error) return _jsx(ContextMenuLoading, {
    template: `0-000-00`
  });
  if (!data?.node) return null;
  if (data.node.__typename !== 'Epic') return null;
  const epic = data.node;
  return _jsxs(ContextMenu, {
    children: [_jsx(OpenInNewTabContextMenuItem, {
      url: getHref(epic.publicId)
    }), _jsx(ContextMenuDivider, {}), _jsx(PinContextMenuItem, {
      id: epic.id,
      entityType: Nouns.Epic,
      loggingContext: {
        typename: 'Epic'
      }
    }), _jsx(CopyContextMenuItem, {
      icon: "Link",
      value: getHref(epic.publicId, true),
      label: "Link"
    }), _jsx(CopyContextMenuItem, {
      value: String(epic.publicId),
      label: "ID"
    }), _jsx(ContextMenuDivider, {}), _jsx(ArchiveEpicContextMenuItem, {
      epic: epic
    }), _jsx(DeleteEpicContextMenuItem, {
      epic: epic
    })]
  });
};
EpicContextMenu.displayName = "EpicContextMenu";
export const useEpicContextMenu = epicGlobalId => {
  const {
    props
  } = useContextMenuWithLogging(() => _jsx(EpicContextMenu, {
    id: epicGlobalId
  }), {
    entityType: 'Epic'
  });
  return props;
};