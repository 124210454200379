import noop from 'lodash/noop';
import { useCallback, useEffect, useRef } from 'react';
import { SearchResultsContainer } from 'components/quickSearch/RecentEntities';
import iconDoc from 'pages/write/icons/doc-outline.svg';
import { usesWrite } from 'utils/tests';
import iconEpic from '../shared/assets/images/icon-flag-checkered.svg';
import iconStory from '../shared/assets/images/icon-story.svg';
import { DocResults } from './DocResults';
import { EpicResults } from './EpicResults';
import { ResultsTabs } from './ResultsTabs';
import { StoryResults } from './StoryResults';
import { SEARCH_ENTITY } from './types';
import { useQuickSearch } from './useQuickSearch';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SearchResults = _ref => {
  let {
    onResultClick,
    onTabChange = noop,
    onSearchComplete = noop,
    query,
    inputRef,
    excludeStories = [],
    resultTypes
  } = _ref;
  const wrapperRef = useRef(null);
  const focusedItem = useRef(-1);
  const filteredResultTypes = resultTypes.filter(type => type !== SEARCH_ENTITY.DOC || usesWrite());
  useEffect(() => {
    if (!inputRef || !inputRef.current) return;
    const onInputFocus = () => focusedItem.current = -1;
    const inputEl = inputRef.current;
    inputEl.addEventListener('focus', onInputFocus);
    return () => {
      inputEl.removeEventListener('focus', onInputFocus);
    };
  }, [inputRef]);
  const {
    stories: {
      storiesFetchState,
      searchStoriesActions
    },
    epics: {
      epicsFetchState,
      searchEpicsActions
    },
    docs: {
      docsFetchState,
      fetchMoreDocs
    }
  } = useQuickSearch({
    query,
    onSearchComplete,
    resultTypes: filteredResultTypes,
    useQuickSearchV1: true
  });

  // Sets focus to an element in the dropdown list based on an index
  const setNewFocus = useCallback(increment => {
    if (!wrapperRef.current) return;
    const focusables = wrapperRef.current.querySelectorAll('[data-focusable]');
    let newFocused = focusedItem.current + increment;
    if (newFocused >= focusables.length) {
      newFocused = 0;
    } else if (newFocused < 0) {
      newFocused = focusables.length - 1;
    }
    focusedItem.current = newFocused;
    if (focusables[newFocused]) {
      focusables[newFocused].focus();
    }
  }, []);

  //Watches for KEY up and down to focus elements in the dropdown list
  useEffect(() => {
    const handleKeyDown = e => {
      switch (e.key) {
        case 'ArrowDown':
          {
            e.preventDefault();
            setNewFocus(1);
            break;
          }
        case 'ArrowUp':
          {
            e.preventDefault();
            setNewFocus(-1);
            break;
          }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setNewFocus]);
  const TABS = {
    [SEARCH_ENTITY.STORY]: {
      name: 'Stories',
      total: storiesFetchState?.total || 0,
      icon: iconStory,
      content: () => _jsx(StoryResults, {
        fetchProps: storiesFetchState,
        actions: searchStoriesActions,
        onClick: onResultClick,
        openModalOnClick: false,
        showCards: false,
        excludeStories: excludeStories
      })
    },
    [SEARCH_ENTITY.EPIC]: {
      name: 'Epics',
      total: epicsFetchState?.total || 0,
      icon: iconEpic,
      content: () => _jsx(EpicResults, {
        fetchProps: epicsFetchState,
        actions: searchEpicsActions,
        onClick: onResultClick,
        openModalOnClick: false
      })
    },
    [SEARCH_ENTITY.DOC]: {
      name: 'Docs',
      total: docsFetchState?.data?.docsSearch?.pageInfo?.count || 0,
      icon: iconDoc,
      content: () => _jsx(DocResults, {
        fetchProps: docsFetchState,
        actions: {
          fetchMoreDocs
        },
        onClick: onResultClick,
        showCards: false
      })
    }
  };
  return _jsx("div", {
    ref: wrapperRef,
    children: _jsx(ResultsTabs, {
      tabs: filteredResultTypes.map(type => ({
        ...TABS[type],
        key: type
      })),
      onSelect: tab => onTabChange(tab.key),
      disableEnhancedKeyboardNav: true,
      children: _ref2 => {
        let {
          renderTabList,
          renderTabPanel
        } = _ref2;
        return _jsxs(_Fragment, {
          children: [renderTabList(), query && _jsx(SearchResultsContainer, {
            children: renderTabPanel()
          })]
        });
      }
    })
  });
};
SearchResults.displayName = "SearchResults";