import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import BaseUtils from '../_frontloader/baseUtils';
import Format from '../modules/format';
import StoryModel from './story';
import Url from '../modules/url';
import Utils from '../modules/utils';
import { IterationStatus } from '@clubhouse/shared/types';
import { simpleCompleteTask } from 'utils/quickstart';
const exports = {};

/*
Example Iteration
  {
    "entity_type": "iteration",
    "name": "The title",
    "id": 55986,
    "created_at": "2019-04-25T16:21:29Z",
    "updated_at": "2019-04-25T16:21:29Z",
    "start_date": "2019-03-14",
    "end_date": "2019-04-24",
    "labels": [],
    "follower_ids": [
      "5ca22b31-97e3-4ef4-a142-6096a9885a95"
    ],
    "mention_ids": [],
    "status": 'unstarted' | 'started' | 'done'
    "description": "",
    "comments": []
  }
 */

exports.STATUS_VALUES = {
  UNSTARTED: IterationStatus.UNSTARTED,
  STARTED: IterationStatus.STARTED,
  DONE: IterationStatus.DONE
};
Collection.create('Iteration', exports);
exports.normalize = iteration => {
  iteration.formatted_name = Format.sanitizeAndEmojify(iteration.name);

  // Make a Set of group IDs to improve performance when checking
  iteration._group_ids_set = new Set(iteration.group_ids || []);
  return iteration;
};
exports.fetch = _ref => {
  let {
    id
  } = _ref;
  return new Promise((resolve, reject) => {
    Backend.get(`/api/private/iterations/${id}`, {
      onComplete: res => {
        exports.defaultGetHandler(res, (err, iteration) => {
          if (err) {
            return reject(err);
          }
          return resolve(iteration);
        });
      }
    });
  });
};
exports.fetchAll = callback => {
  exports.fetchAllByUrl('/api/private/iterations', callback);
};
exports.fetchAllByUrl = (url, callback) => {
  Backend.get(url, {
    onComplete: res => {
      exports.defaultFetchSomeHandler(res, callback);
    }
  });
};
exports.fetchStoriesForAllIterations = callback => {
  const iteration_ids = exports.all().map(i => i.id);
  return exports.fetchStoriesForIterations({
    iteration_ids
  }, callback);
};
exports.fetchStoriesForIterations = (_ref2, callback) => {
  let {
    iteration_ids
  } = _ref2;
  return StoryModel.searchStories({
    iteration_ids
  }, callback);
};
exports.getFromUrl = url => {
  const path = Url.parsePath(url);
  return exports.getById(path.iteration);
};
exports.saveNew = (iteration, callback) => {
  Backend.post('/api/private/iterations', {
    data: iteration,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'iterationCreated');
      exports.trigger('iterationCreated', iteration);
      simpleCompleteTask('create-iteration');
    }
  });
};
exports.saveChanges = (iteration, changes, callback) => {
  Backend.put('/api/private/iterations/' + iteration.id, {
    data: changes,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'iterationChangesSaved');
    }
  });
};
exports.deleteIteration = (iteration, callback) => {
  Backend.delete('/api/private/iterations/' + iteration.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(iteration, res, xhr, callback);
      exports.trigger('iterationDeleted', iteration);
    }
  });
};
exports.getAllByStatus = status => {
  return exports.filter(item => item.status === status);
};
exports.getAllSortedBy = function () {
  let orderBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'start_date';
  let direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'desc';
  const iterations = exports.all();
  if (orderBy === 'start_date' || orderBy === 'end_date') {
    return _.orderBy(iterations, [iteration => new Date(iteration[orderBy]), iteration => new Date(iteration.created_at)], direction);
  } else {
    return _.orderBy(iterations, orderBy, direction);
  }
};
exports.generateURL = iteration => {
  return `${Url.getSlugPath()}/iteration/${iteration.id}/${Utils.slugify(iteration.name, {
    limit: 120
  })}`;
};
exports.addStoryDataToIterations = () => {
  const groupedStories = _.groupBy(StoryModel.filter('iteration_id'), 'iteration_id');
  exports.each(iteration => {
    addStoryDataToIteration(iteration, groupedStories[iteration.id]);
  });
};
exports.addStoryDataToSingleIteration = iterationId => {
  addStoryDataToIteration(exports.getById(iterationId), StoryModel.filter({
    iteration_id: iterationId
  }));
};
function addStoryDataToIteration(iteration) {
  let stories = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // Filter out archived stories as they don't count toward statistics
  iteration.stories = stories.filter(s => !s.archived);
  iteration.num_stories = iteration.stories.length;
  iteration.num_points = _.reduce(iteration.stories, (total, story) => {
    return story.estimate ? total + story.estimate : total;
  }, 0);
}
exports.setActiveIterationsForSpace = data => {
  const iterationIds = data.Iteration;
  const allSelected = iterationIds === '*' || !iterationIds;
  exports.each(iteration => {
    iteration.active = allSelected || _.includes(iterationIds, iteration.id);
  });
};
exports.isAssignedToGroup = (_ref3, group_id) => {
  let {
    _group_ids_set
  } = _ref3;
  return _group_ids_set.has(group_id);
};
exports.isDone = iteration => {
  return iteration.status === IterationStatus.DONE;
};
exports.Promises = {
  fetchStoriesForIterations: BaseUtils.promisify(exports.fetchStoriesForIterations),
  saveNew: BaseUtils.promisify(exports.saveNew),
  saveChanges: BaseUtils.promisify(exports.saveChanges),
  deleteIteration: BaseUtils.promisify(exports.deleteIteration)
};
export { exports as default };