import { Spaced } from '../Spaced';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ButtonSet = _ref => {
  let {
    children
  } = _ref;
  return _jsx(Spaced, {
    horizontally: true,
    inline: true,
    amount: 12,
    children: children
  });
};
ButtonSet.displayName = "ButtonSet";