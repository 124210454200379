import { WorkspaceFeaturesDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import { WorkspaceFeaturesListFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql/hooks';
import { EVENT_TYPES, useEventListener } from 'utils/collectionizeToApolloMessageBus';
import { useWorkspaceSlug } from 'utils/navigation';
const WorkspaceFeaturesListFragment = WorkspaceFeaturesListFragmentDoc;
const WorkspaceFeaturesQuery = WorkspaceFeaturesDocument;
export function useWorkspaceFeatures() {
  const {
    data,
    previousData,
    loading,
    error,
    refetch
  } = useQuery(WorkspaceFeaturesQuery, {
    variables: {
      slug: useWorkspaceSlug()
    },
    fetchPolicy: 'cache-first'
  });
  useEventListener(EVENT_TYPES.FEATURE_UPDATED, refetch);
  const dataToRender = loading && !data ? previousData : data;
  const workspace2 = dataToRender?.workspace2;
  const features = workspace2?.features;
  if (!workspace2 || !features) return [{
    projects: false,
    iterations: false,
    docs: false,
    objectives: false,
    estimateScale: false
  }, {
    loading: !error,
    error: !!error
  }];
  return [Object.keys(features).reduce((acc, key) => {
    acc[key] = features[key].enabled;
    return acc;
  }, {
    estimateScale: workspace2.canPointStories
  }), {
    loading: false,
    error: false
  }];
}
export function useWorkspaceFeature(feature) {
  const [features, {
    loading
  }] = useWorkspaceFeatures();
  return loading ? null : features[feature];
}