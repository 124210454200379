import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import ShortcutLoading from '@clubhouse/assets/gif/shortcut-loading.gif';
import dottyErrorIllustration from '@clubhouse/assets/png/DotError.png';
import { THEME_NAMES, ThemeContextProvider } from '@clubhouse/shared/utils/theme';
import { GlobalErrorProvider } from 'components/signup/useGlobalError';
import { SignupProvider } from './SignupContext';
import { SignupPageContent } from './SignupPageContent';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const SignupPage = () => {
  return _jsxs(_Fragment, {
    children: [_jsxs(Helmet, {
      children: [_jsx("link", {
        rel: "prefetch",
        href: ShortcutLoading
      }), _jsx("link", {
        rel: "prefetch",
        href: dottyErrorIllustration
      })]
    }), _jsx(ThemeContextProvider, {
      themeName: THEME_NAMES.LIGHT,
      children: _jsx(GlobalErrorProvider, {
        children: _jsx(Router, {
          children: _jsx(SignupProvider, {
            children: _jsx(SignupPageContent, {})
          })
        })
      })
    })]
  });
};