import { createSearchParamConfigs } from 'components/gql/utils/createSearchParamConfigs';
export const EpicsPageFilterConfig = createSearchParamConfigs({
  query: {
    paramName: 'query',
    paramType: 'string',
    defaultValue: ''
  },
  projectIds: {
    paramName: 'project_ids',
    paramType: 'string[]'
  },
  ownerIds: {
    paramName: 'owner_ids',
    paramType: 'string[]'
  },
  milestoneIds: {
    paramName: 'milestone_ids',
    paramType: 'string[]'
  },
  objectiveIds: {
    paramName: 'objective_ids',
    paramType: 'string[]'
  },
  groupIds: {
    paramName: 'group_ids',
    paramType: 'string[]'
  },
  stateIds: {
    paramName: 'state_ids',
    paramType: 'string[]'
  },
  labelIds: {
    paramName: 'label_ids',
    paramType: 'string[]'
  },
  showArchived: {
    paramName: 'show_archived',
    paramType: 'boolean',
    defaultValue: false
  },
  showAssociated: {
    paramName: 'show_associated',
    paramType: 'boolean',
    defaultValue: true
  }
});