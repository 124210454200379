import { useEffect, useRef } from 'react';
import { PAGE_NAMES, useSetBackgroundPage } from 'utils/navigation';
export const SetStoriesPageAsBackgroundPage = () => {
  const setBackgroundPage = useSetBackgroundPage();
  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current) {
      return;
    }
    hasRun.current = true;
    setBackgroundPage({
      pageName: PAGE_NAMES.STORIES
    });
  }, [setBackgroundPage]);
  return null;
};