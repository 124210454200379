const exports = {};
exports.toggle = function () {
  const parent = $(this).closest('.radio-list');
  if (parent) {
    parent.find('.radio-list-item.active').removeClass('active').find('.radio').removeClass('fa-circle').addClass('fa-circle-o');
    $(this).addClass('active').find('.radio').removeClass('fa-circle-o').addClass('fa-circle');
    parent.find('.save-changes.disabled').removeClass('disabled').removeAttr('disabled');
  }
  return false;
};
export { exports as default };