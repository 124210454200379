import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { useLatest } from 'react-use';
export const useHasValueChanged = _ref => {
  let {
    value,
    onValueChange
  } = _ref;
  const valueRef = useRef(value);
  const areValuesEqual = isEqual(value, valueRef.current);
  const onValueChangeRef = useLatest(onValueChange);
  useEffect(() => {
    if (!areValuesEqual) {
      onValueChangeRef.current?.(value);
    }
  }, [areValuesEqual, onValueChangeRef, value]);
  valueRef.current = value;
  return !areValuesEqual;
};