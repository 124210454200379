import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { MilestoneSearchCardAdapter } from './ObjectiveSearchCard';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ObjectiveResults = _ref => {
  let {
    fetchProps: {
      loading,
      data: milestones,
      previousData,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick
  } = _ref;
  if (loading && !hasFetched) {
    return _jsxs(_Fragment, {
      children: [_jsx(SkeletonSearchCard, {
        icon: "Objectives",
        kind: "blue"
      }), _jsx(SkeletonSearchCard, {
        icon: "Objectives",
        kind: "blue"
      }), _jsx(SkeletonSearchCard, {
        icon: "Objectives",
        kind: "blue"
      })]
    });
  }
  const resultsList = loading ? previousData : milestones;
  if (hasFetched && !resultsList.length) {
    return _jsx(NoResults, {
      entityType: "Objectives"
    });
  }
  return _jsxs(_Fragment, {
    children: [resultsList.map((milestone, index) => _jsx(MilestoneSearchCardAdapter, {
      onClick: entity => onClick?.(entity, index),
      openModalOnClick: openModalOnClick,
      milestone: milestone
    }, milestone.id)), next && _jsx(LoadMore, {
      loading: loading,
      loadMore: loadMore
    })]
  });
};