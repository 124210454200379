import { Section } from './components/Section';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Sidebar = _ref => {
  let {
    children
  } = _ref;
  return _jsx(_Fragment, {
    children: children
  });
};
Sidebar.Section = Section;