import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const errorMapper = messageFromServer => ({
  'User is already a member of this organization.': {
    title: 'User already belongs to the workspace.',
    message: _jsx("a", {
      href: "/login",
      children: "Click here to log in."
    })
  }
})[messageFromServer] || {
  message: messageFromServer
};