import { Button } from '@clubhouse/shared/components/Button';
import { EmptyState } from '@clubhouse/shared/components/EmptyState';
import { getDisplayName } from 'data/typeConfigs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NoEntitiesEmptyState = _ref => {
  let {
    onCreate,
    createEntityDisplay,
    containingEntityDisplay
  } = _ref;
  return _jsx(EmptyState, {
    body: `There are no ${getDisplayName({
      type: createEntityDisplay,
      isSingular: false
    })} for this ${getDisplayName({
      type: containingEntityDisplay
    })}.`,
    onClickCTA: onCreate,
    cta: `Create ${getDisplayName({
      type: createEntityDisplay
    })}`,
    ctaKind: Button.KIND.TERTIARY
  });
};
NoEntitiesEmptyState.displayName = "NoEntitiesEmptyState";