import "core-js/modules/es.array.push.js";
import { groupBy } from '@clubhouse/shared/utils';
import { config as epicStateConfig } from './epicState';
import { config as objectiveConfig } from './objective';
import { config as teamConfig } from './team';
const CONFIGS = {
  objective_position: objectiveConfig,
  epicState_position: epicStateConfig,
  team_name: teamConfig
};
export const createGroups = (key, items) => {
  const config = CONFIGS[key];
  const groupMap = groupBy(items, config.getKey);
  const groups = [];
  for (const [group, groupItems] of groupMap.entries()) {
    groups.push({
      key: group,
      items: groupItems,
      header: config.getHeader(groupItems[0])
    });
  }
  return groups;
};