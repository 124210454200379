import "core-js/modules/es.array.push.js";
import _ from 'lodash';
import Globals from './globals';
const LISTENERS_KEY = 'EventListeners';
export const getListeners = () => {
  return Globals.setOnlyIfMissing(LISTENERS_KEY, []);
};
export const clearListeners = () => {
  return Globals.set(LISTENERS_KEY, []);
};

/**
 * Register an event listener
 *
 * @param eventNames - a space-separated string of event names. Event names are of the form [name].[namespace], like resize.Stories or something.
 * @param fn - a callback function to call when the event is triggered.
 */
export const on = (eventNames, fn) => {
  const listeners = getListeners();
  const events = eventNames.split(' ');
  events.forEach(eventName => {
    if (eventName) {
      const [name, namespace] = eventName.split('.');
      listeners.push({
        name,
        fn,
        namespace
      });
    }
  });
};
export const trigger = (eventName, data) => {
  const listeners = getListeners();
  if (eventName && listeners) {
    const [name] = eventName.split('.');
    [...listeners].forEach(listener => {
      // Listener might be null on popstate. Depends on if the dialog
      // has added or removed any event listeners during open/close.
      if (listener && listener.name === name) {
        listener.fn(data);
      }
    });
  }
};
export const off = (eventNames, fn) => {
  const listeners = getListeners();
  const events = eventNames.split(' ');
  events.forEach(eventName => {
    if (eventName) {
      const [name, namespace] = eventName.split('.');
      _.remove(listeners, listener => {
        if (!listener) {
          return false;
        }
        if (fn && listener.fn !== fn) {
          return false;
        }
        if (namespace && name) {
          return namespace === listener.namespace && name === listener.name;
        }
        if (namespace) {
          return namespace === listener.namespace;
        }
        if (name) {
          return name === listener.name;
        }
      });
    }
  });
};
export const once = (eventNames, fn) => {
  on(eventNames, data => {
    fn(data);
    setTimeout(() => {
      off(eventNames);
    }, 0);
  });
};
export const onlyOn = (eventNames, fn) => {
  off(eventNames);
  on(eventNames, fn);
};