import * as TB from '@radix-ui/react-toolbar';
import { useRef, useState } from 'react';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { SizedEditorIcon, SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { toShortcutLabel } from '@clubhouse/shared/utils/keyboard';
import { DEFAULT_MODIFIER } from '../commands';
import { ToolbarButton } from './ToolbarButton';
import { Autocompletes } from './autocompletes';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function OverflowMenu(_ref) {
  let {
    commands,
    items,
    onClick,
    onInsert
  } = _ref;
  const moreActionsRef = useRef(null);
  const [activePicker, setActivePicker] = useState(null);
  const ActiveAutocompleteMenu = activePicker ? Autocompletes[activePicker]?.Menu : null;
  return _jsxs(_Fragment, {
    children: [_jsxs(MoreActions, {
      isModal: false,
      children: [_jsx(MoreActions.Trigger, {
        asChild: true,
        children: _jsx(TB.Button, {
          asChild: true,
          children: _jsx(ToolbarButton, {
            ref: moreActionsRef,
            children: _jsx(SizedIcon, {
              icon: "More",
              size: 16,
              label: "More actions"
            })
          })
        })
      }), _jsx(MoreActions.Menu, {
        children: items.map((key, i) => {
          if (key.startsWith('divider') && i > 0) return _jsx(MoreActions.Divider, {}, `divider-${i}`);
          const cmd = commands[key];
          const autocomplete = Autocompletes[key];
          if (cmd) return _jsx(MoreActions.Item, {
            icon: cmd.icon ? _jsx(SizedEditorIcon, {
              icon: cmd.icon,
              size: 16
            }) : cmd.icon,
            aside: cmd.key ? `${toShortcutLabel(cmd.modifiers || DEFAULT_MODIFIER)} ${cmd.key.toUpperCase()}` : null,
            onClick: () => onClick(cmd),
            children: cmd.name
          }, key);
          if (autocomplete) return _jsx(MoreActions.Item, {
            icon: _jsx(SizedEditorIcon, {
              icon: autocomplete.icon,
              size: 16
            }),
            onClick: () => setActivePicker(key),
            children: autocomplete.label
          }, key);
          return null;
        })
      })]
    }), ActiveAutocompleteMenu ? _jsx(ActiveAutocompleteMenu, {
      triggerRef: moreActionsRef,
      onSelect: value => {
        onInsert(value);
        setActivePicker(null);
      },
      onClose: () => setActivePicker(null)
    }) : null]
  });
}