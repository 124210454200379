import { VerifyEmailPage } from 'pages/verify-email/VerifyEmailPage';
import View from '../../../core/js/modules/view';
import * as Event from '../../../core/js/_frontloader/event';
const exports = {};
exports.render = () => {
  View.renderComponent({
    component: VerifyEmailPage,
    mountNode: document.querySelector('#content')
  });
  Event.trigger('pageRendered', 'VerifyEmail');
};
exports.init = () => {
  exports.render();
};
export { exports as default };