import View from 'app/client/core/js/modules/view';
import { LambdaTest } from 'pages/integrations/LambdaTest';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
const exports = {};
exports.title = `LambdaTest Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/lambdatest';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(LambdaTest, 'lambdatest-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };