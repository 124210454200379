import { isParseableUrl } from 'components/shared/MarkdownEditor/utils';
export const isInUrl = (text, index) => {
  let endOfWord = text.length,
    startOfWord = 0;
  for (startOfWord = index; startOfWord > 0; --startOfWord) {
    if (/\s/.test(text.charAt(startOfWord - 1))) break;
  }
  for (endOfWord = index; endOfWord < text.length; ++endOfWord) {
    if (/\s/.test(text.charAt(endOfWord))) break;
  }
  const word = text.substring(startOfWord, endOfWord);
  const isUrl = /^https?:\/\//.test(word) && isParseableUrl(word);
  return isUrl;
};
export const ensureFullMatch = (expected, text, index) => {
  const substr = text.substring(index);
  const [match] = substr.match(/^@[a-z0-9-_.]*[a-z0-9-_]/i) || [];
  return expected === match;
};