const exports = {};
const NS = '.DragToScroll';
exports.init = (element, context) => {
  exports.element = element;
  let cursorX = 0,
    mouseDown = false;
  $(element).on('mousedown' + NS, e => {
    mouseDown = true;
    cursorX = e.pageX;
    $(context).disableSelection();
    $(document).one('mouseup' + NS, () => {
      mouseDown = false;
      $(context).enableSelection();
    });
  }).on('mousemove' + NS, function (e) {
    if (mouseDown && e.target === this) {
      $(context).scrollLeft($(context).scrollLeft() + (cursorX - e.pageX));
      cursorX = e.pageX;
    }
  });
};
exports.destroy = () => {
  $(exports.element).off('mousedown' + NS).off('mousemove' + NS);
};
export { exports as default };