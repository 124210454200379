import { useCallback, useMemo } from 'react';
import { GroupedOptionsSelect, useFetchGroupedOptions } from '@clubhouse/shared/components/GroupedOptionsSelect';
import { Divider, Nothing } from '@clubhouse/shared/components/Select';
import { logError } from 'utils/monitoring';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const staticItems = [Nothing(), Divider('after-none')];
export const GroupedOptionsFieldSingleSelection = _ref => {
  let {
    selectedEntity,
    required,
    onChange,
    fetchOptions,
    getSection,
    ...props
  } = _ref;
  const {
    unit
  } = props;
  const selectedValues = useMemo(() => selectedEntity ? [selectedEntity.id] : [], [selectedEntity]);
  const handleChange = useCallback((_ref2, _ref3) => {
    let [selectedId] = _ref2;
    let [selectedItem] = _ref3;
    onChange(selectedId ?? null, selectedItem ?? null);
  }, [onChange]);
  const targetComponentProps = useMemo(() => ({
    selectedEntity
  }), [selectedEntity]);
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions,
    selectedValues,
    getSection,
    staticItems: required ? undefined : staticItems,
    onError: logError,
    maxSelected: 1
  });
  return _jsx(GroupedOptionsSelect, {
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    selectedValues: selectedValues,
    label: `Update ${unit.singular}`,
    maxSelected: 1,
    onChange: handleChange,
    targetComponentProps: targetComponentProps,
    ...props
  });
};
GroupedOptionsFieldSingleSelection.displayName = "GroupedOptionsFieldSingleSelection";