import { useCallback } from 'react';
import { causify } from '@clubhouse/shared/utils/causify';
import { useSignupData } from 'pages/signup/SignupContext';
import { submitSignupToAPI } from 'pages/signup/utils/signup';
export const useSubmitSignup = () => {
  const {
    signup
  } = useSignupData();
  return useCallback(async signupData => {
    if (signup) {
      try {
        const {
          id: signupId,
          auto_join_allowed: autoJoinChecked,
          email: userEmail,
          is_oauth: isOauth
        } = signup;
        await submitSignupToAPI({
          signupId: String(signupId),
          autoJoinChecked,
          userEmail,
          signupData,
          isOauth
        });
      } catch (err) {
        throw new Error('We were unable to create your account.', {
          cause: causify(err)
        });
      }
    }
  }, [signup]);
};