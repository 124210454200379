import { useEffect, useRef } from 'react';
const updateCursor = ele => {
  ele.style.cursor = 'grabbing';
  ele.style.userSelect = 'none';
};
const resetCursor = ele => {
  ele.style.cursor = 'grab';
  ele.style.removeProperty('user-select');
};
const isDraggable = el => {
  if (el instanceof Element) return !!(el.closest('[draggable]') || el.matches('[draggable]'));
  return false;
};
const handleMouseDown = e => {
  // We don't want to trigger drag to scroll when moving a draggable element e.g: Story cards.
  const isDraggableChild = isDraggable(e.target);
  const node = e.currentTarget;
  // We only want to trigger drag when the user is using their main mouse button (usually the left)
  const isMainButton = e.button === 0;
  if (!node || !(node instanceof HTMLElement) || isDraggableChild || !isMainButton) return;
  const dir = node.getAttribute('data-drag-to-scroll-dir');
  const startPos = {
    left: node.scrollLeft,
    top: node.scrollTop,
    x: e.clientX,
    y: e.clientY
  };
  const handleMouseMove = e => {
    const dx = dir !== 'vertical' ? e.clientX - startPos.x : 0;
    const dy = dir !== 'horizontal' ? e.clientY - startPos.y : 0;
    node.scrollTop = startPos.top - dy;
    node.scrollLeft = startPos.left - dx;
    updateCursor(node);
  };
  document.addEventListener('mousemove', handleMouseMove, {
    passive: true
  });
  document.addEventListener('mouseup', () => {
    document.removeEventListener('mousemove', handleMouseMove);
    resetCursor(node);
  }, {
    once: true
  });
};
const handleTouchStart = e => {
  // We don't want to trigger drag to scroll when moving a draggable element e.g: Story cards.
  const isDraggableChild = isDraggable(e.target);
  const node = e.currentTarget;
  if (!node || !(node instanceof HTMLElement) || isDraggableChild) return;
  const dir = node.getAttribute('data-drag-to-scroll-dir');
  const touch = e.touches[0];
  const startPos = {
    left: node.scrollLeft,
    top: node.scrollTop,
    x: touch.clientX,
    y: touch.clientY
  };
  const handleTouchMove = e => {
    const touch = e.touches[0];
    const dx = dir !== 'vertical' ? touch.clientX - startPos.x : 0;
    const dy = dir !== 'horizontal' ? touch.clientY - startPos.y : 0;
    node.scrollTop = startPos.top - dy;
    node.scrollLeft = startPos.left - dx;
    updateCursor(node);
  };
  document.addEventListener('touchmove', handleTouchMove, {
    passive: true
  });
  document.addEventListener('touchend', () => {
    document.removeEventListener('touchmove', handleTouchMove);
    resetCursor(node);
  }, {
    once: true
  });
};

// Modified version of https://phuoc.ng/collection/react-drag-drop/scroll-by-dragging/
export const useDragToScroll = function () {
  let dir = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'both';
  const ref = useRef(null);
  useEffect(() => {
    const node = ref.current;
    if (!node) return;
    node.setAttribute('data-drag-to-scroll-dir', dir);
    node.addEventListener('mousedown', handleMouseDown, {
      passive: true
    });
    node.addEventListener('touchstart', handleTouchStart, {
      passive: true
    });
    return () => {
      node.removeAttribute('data-drag-to-scroll-dir');
      node.removeEventListener('mousedown', handleMouseDown);
      node.removeEventListener('touchstart', handleTouchStart);
    };
  }, [dir]);
  return ref;
};