export const VERBS = {
  BLOCK: 'blocks',
  DUPLICATE: 'duplicates',
  RELATE: 'relates to'
};
export const RELATIONSHIPS = {
  'relates to': {
    name: 'relates to',
    verb: VERBS.RELATE,
    type: 'subject',
    adjective: 'related',
    icon: 'exchange'
  },
  blocks: {
    name: 'blocks',
    verb: VERBS.BLOCK,
    type: 'subject',
    adjective: 'blocked',
    icon: 'exclamation-triangle'
  },
  'is blocked by': {
    name: 'is blocked by',
    verb: VERBS.BLOCK,
    type: 'object',
    adjective: 'blocking',
    icon: 'square'
  },
  duplicates: {
    name: 'relates to',
    verb: VERBS.DUPLICATE,
    type: 'subject',
    adjective: 'duplicated',
    icon: 'files-o'
  },
  'is duplicated by': {
    name: 'is duplicated by',
    verb: VERBS.DUPLICATE,
    type: 'object',
    adjective: 'duplicating',
    icon: 'files-o'
  }
};