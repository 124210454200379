import { MutateEpicPositionAndEpicStateDocument } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicColumnReorderFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { QueryEpicsColumnDataDocument } from '@clubhouse/datalayer/types';
import { insertIf } from '@clubhouse/shared/utils/object';
import { moveWithinArray } from '@clubhouse/shared/utils/reorder';
import { getPositionChange } from 'components/shared/hooks/useOnChangePriority';
import { useMutation } from 'gql';
export const EpicColumnReorderFragment = EpicColumnReorderFragmentFragmentDoc;
export const MUTATE_EPIC_POSITION_AND_EPIC_STATE = MutateEpicPositionAndEpicStateDocument;
const getUpdatedState = (previousQueryResult, edges, pageInfo) => ({
  ...previousQueryResult,
  workspace2: {
    ...previousQueryResult.workspace2,
    epics: {
      ...previousQueryResult.workspace2?.epics,
      edges,
      pageInfo: {
        ...previousQueryResult.workspace2?.epics?.pageInfo,
        ...pageInfo
      }
    }
  }
});
export function useEpicColumnReordering(_ref) {
  let {
    sortDirection,
    columnData,
    epicStateData
  } = _ref;
  const [updatePositionAndEpicState] = useMutation(MUTATE_EPIC_POSITION_AND_EPIC_STATE);
  const reorderAndMove = useCallback(_ref2 => {
    let {
      epicId,
      fromState,
      toState,
      fromIndex,
      toIndex
    } = _ref2;
    if (fromState === toState && fromIndex === toIndex) return;
    if (fromState === toState) {
      const {
        epics,
        updateQuery
      } = columnData[toState];
      const changes = getPositionChange({
        oldIndex: fromIndex,
        newIndex: toIndex,
        entityList: epics,
        sortDirection
      });
      updatePositionAndEpicState({
        variables: {
          id: epicId,
          input: {
            ...insertIf(!!changes.before_id, {
              before: String(changes.before_id)
            }),
            ...insertIf(!!changes.after_id, {
              after: String(changes.after_id)
            })
          }
        },
        cancelQueries: [QueryEpicsColumnDataDocument]
      });
      updateQuery(previousQueryResult => {
        if (!previousQueryResult?.workspace2?.epics) return previousQueryResult;
        const edges = moveWithinArray([...previousQueryResult.workspace2.epics.edges], fromIndex, toIndex);
        return getUpdatedState(previousQueryResult, edges);
      });
    } else {
      const {
        epics: toStateEpics,
        updateQuery: toStateUpdateQuery
      } = columnData[toState];
      const {
        epics: fromStateEpics,
        updateQuery: fromStateUpdateQuery
      } = columnData[fromState];
      const newState = epicStateData[toState][0];
      const epic = {
        ...fromStateEpics[fromIndex]
      };
      epic.epicState = newState || epic.epicState;
      updatePositionAndEpicState({
        variables: {
          id: epicId,
          input: {
            ...insertIf(!!newState, {
              epicState: newState?.id
            }),
            ...insertIf(toStateEpics.length > 0 && toIndex >= toStateEpics.length, {
              after: toStateEpics[toStateEpics.length - 1]?.id
            }),
            ...insertIf(toStateEpics.length > 0 && toIndex < toStateEpics.length, {
              before: toStateEpics[toIndex]?.id
            })
          }
        }
      });
      fromStateUpdateQuery(previousQueryResult => {
        if (!previousQueryResult?.workspace2?.epics) return previousQueryResult;
        const edges = [...previousQueryResult.workspace2.epics.edges];
        edges.splice(fromIndex, 1);
        const pageInfo = {
          ...previousQueryResult.workspace2.epics.pageInfo
        };
        return getUpdatedState(previousQueryResult, edges, {
          ...pageInfo,
          totalSize: pageInfo.totalSize - 1
        });
      });
      toStateUpdateQuery(previousQueryResult => {
        if (!previousQueryResult.workspace2?.epics) return previousQueryResult;
        const edgesBefore = [...previousQueryResult.workspace2.epics.edges];
        const edges = [...edgesBefore.slice(0, toIndex), {
          __typename: 'EpicEdge',
          node: epic
        }, ...edgesBefore.slice(toIndex)];
        const pageInfo = {
          ...previousQueryResult.workspace2.epics.pageInfo
        };
        return getUpdatedState(previousQueryResult, edges, {
          ...pageInfo,
          totalSize: pageInfo.totalSize + 1
        });
      });
    }
  }, [updatePositionAndEpicState, sortDirection, columnData, epicStateData]);
  return reorderAndMove;
}